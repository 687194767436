import React, { useState } from 'react';
import { FormControl as MUIFormControl, TextField as MUITextField, FormControlLabel, Switch } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import { useMutation } from 'react-admin';
import FormConfirmationDialog from '../FormConfirmationDialog';

const useStyles = makeStyles(() => ({
  addressEditDialog: {
    width: '30rem',
  },
  addressInput: {
    marginBottom: '0.5rem',
  },
  switch: { marginTop: 10 },
}));

export const AddressEditDialog = (props) => {
  const { service, address } = props;

  const classes = useStyles();

  const [mutateAddress, { loading: loadingMutateAddress }] = useMutation();

  const defaultIsPrimary = address?.isPrimary;
  const [isPrimary, setIsPrimary] = useState(defaultIsPrimary);

  if (!props.service) {
    return null;
  }

  // eslint-disable-next-line consistent-return
  const setAddress = (addressData) => {
    if (!addressData) {
      if (props.onCancel) {
        props.onCancel();
      }
      return null;
    }
    mutateAddress(
      {
        type: address?.id ? 'update' : 'create',
        resource: service,
        payload: {
          id: address?.id,
          data: {
            address: addressData.address,
            isPrimary,
            lat: addressData.lat,
            long: addressData.long,
            placeId: addressData.placeId,
          },
        },
      },
      {
        onSuccess: props.onSuccess,
        onFailure: props.onFailure,
      },
    );
  };

  return (
    <FormConfirmationDialog open={props.open} onCancel={props.onCancel} onConfirm={setAddress} title="Edit address">
      <MUIFormControl className={classes.addressEditDialog}>
        <MUITextField autoFocus disabled margin="dense" name="id" label="ID" variant="standard" defaultValue={props.address?.id} fullWidth className={classes.addressInput} />

        <MUITextField
          autoFocus
          disabled={loadingMutateAddress}
          margin="dense"
          name="address"
          label="ADDRESS"
          variant="standard"
          defaultValue={address?.address}
          fullWidth
          className={classes.addressInput}
        />

        <MUITextField autoFocus disabled={loadingMutateAddress} margin="dense" name="lat" label="LATTITUDE" variant="standard" defaultValue={address?.lat} fullWidth className={classes.addressInput} />

        <MUITextField
          autoFocus
          disabled={loadingMutateAddress}
          margin="dense"
          name="long"
          label="LONGITUDE"
          variant="standard"
          defaultValue={address?.long}
          fullWidth
          className={classes.addressInput}
        />

        <MUITextField
          autoFocus
          disabled={loadingMutateAddress}
          margin="dense"
          name="placeId"
          label="PLACE ID"
          variant="standard"
          defaultValue={address?.placeId}
          fullWidth
          className={classes.addressInput}
        />

        <FormControlLabel className={classes.switch} control={<Switch checked={isPrimary} onChange={() => setIsPrimary(!isPrimary)} name="isPrimary" color="primary" />} label="is Primary" />
      </MUIFormControl>
    </FormConfirmationDialog>
  );
};
