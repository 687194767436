import React, { useMemo, useRef, useEffect, useState, useCallback } from 'react';
import { map, orderBy } from 'lodash';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField as MUITextField,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
  FormControl,
  MenuItem,
  TablePagination,
  TableContainer,
} from '@material-ui/core';

import LinkIcon from '@material-ui/icons/Link';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/styles';
import { useDataProvider, Title, Loading, useNotify, useMutation, Link, useRedirect, SelectInput } from 'react-admin';
import { Breadcrumbs } from '../components/Breadcrumbs';
import ValidationLockByUser from '../components/ValidationLockByUser';
import { useDebounce } from '../utils/common';
import { AutocompleteInput, Create, ReferenceInput, required, SimpleForm, TextInput, NumberInput } from 'react-admin';
import { InputLabel } from '@material-ui/core';
import EntityLink from '../components/EntityLink';

const useStyles = makeStyles(() => ({
  label: {
    fontWeight: 'bold',
    marginRight: 8,
    display: 'inline-block',
    verticalAlign: 'top',
  },
  verticalAlignTop: {
    verticalAlign: 'top',
  },
  recentQcLog: {
    fontSize: 'x-small',
    margin: 0,
  },
  iframe: {
    width: '100%',
    height: '70vh',
  },
  generateBtn: {
    height: 45,
    marginLeft: 10,
  },
  actions: {
    marginTop: 20,
    borderBottom: '1px solid #ddd',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
  },
  centerAlign: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  spacingRight: {
    marginRight: 10,
  },
  button: {
    marginLeft: 20,
    textTransform: 'inherit',
    marginBottom: 15,
  },
  dialog: {
    width: 500,
  },
  spacingBottom: {
    marginBottom: 10,
  },
  validationStatus: {
    fontSize: 16,
    fontWeight: 'bold',
    border: '1px solid #ff5722',
    padding: 6,
    backgroundColor: '#ff5722',
    color: 'white',
  },
  logContainer: {
    padding: '8px 16px',
  },
  link: {
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  logsAccordion: {
    marginTop: 20,
  },
  logsAccordionDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  spacingTop: {
    marginTop: 30,
    fontSize: '0.75rem',
  },
  table: {
    width: 'max-content',
  },
  tableCell: {
    maxWidth: 500,
  },
  filterSection: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
    flexWrap: 'wrap',
    rowGap: '10px',
  },
  spacingLeft: {
    marginLeft: 10,
    width: 140,
  },
  autoComplete: {
    width: 220,
    marginLeft: 10,
    '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
      paddingTop: 10,
    },
  },
  tableRow: {
    cursor: 'pointer',
  },
  readMore: {
    color: '#ff5722',
  },
  switch: {
    width: 'max-content',
    marginLeft: 5,
  },
  month: {
    minWidth: 140,
  },
}));

const IS_COMPLETED = [
  { value: 'all', label: 'All' },
  { value: 'false', label: 'Pending' },
  { value: 'true', label: 'Done' },
];

const MONTHS = [
  { value: '', label: 'Any' },
  { value: '1', label: 'January' },
  { value: '2', label: 'February' },
  { value: '3', label: 'March' },
  { value: '4', label: 'April' },
  { value: '5', label: 'May' },
  { value: '6', label: 'June' },
  { value: '7', label: 'July' },
  { value: '8', label: 'August' },
  { value: '9', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },
];
const PER_PAGE = 10;
const TASK_QUEUE_HEADERS = [
  'Task Id',
  'Status',
  'Locked By',
  'Completed By',
  'Recent QC Log',
  'Source Link',
  'Prod. Id',
  'Custom Title',
  'Producer',
  'Producer Country',
  'Perf. Year',
  'Perf. Month',
  'taskTag',
];
const DATA_QC_TASK_TYPES = [{ id: 'productionsByProducerGeography', name: 'Productions By Producer Geography' }];

const RenderCell = ({ value, dataKey, showReadMore }) => {
  const classes = useStyles();
  switch (dataKey) {
    case 'prodId':
      return (
        <TableCell>
          <Link to={`/productions/${value}/show`} onClick={(e) => e.stopPropagation()}>
            {value}
          </Link>
        </TableCell>
      );
    case 'producer':
      return value?.id ? (
        <TableCell>
          <Link to={`/organizations/${value.id}/show`} onClick={(e) => e.stopPropagation()}>
            {value.name}
          </Link>
        </TableCell>
      ) : (
        <TableCell></TableCell>
      );
    case 'sourceLink':
      return value ? (
        <TableCell>
          <a href={value} onClick={(e) => e.stopPropagation()} target="_blank" rel="noopener noreferrer">
            <LinkIcon />
          </a>
        </TableCell>
      ) : (
        <TableCell></TableCell>
      );
    case 'recentlyCompletedBy':
      return value?.length ? (
        <TableCell>
          <pre className={classes.recentQcLog}>{`taskId       : ${value[0].task.id}
taskType     : ${value[0].task.taskType}
completed by : ${value[0].user.id} ${value[0].user.email.split}
completed at : ${value[0].completedAt.slice(0, 10)}`}</pre>
        </TableCell>
      ) : (
        <TableCell></TableCell>
      );
    case 'status':
      return <TableCell>{value ? 'Done' : 'Pending'}</TableCell>;
    case 'lockedBy':
    case 'completedBy':
      return <TableCell>{value?.split('@')[0]}</TableCell>;
    default:
      return <TableCell>{value}</TableCell>;
  }
};

const RenderTableRow = ({ task, showLockedUser }) => {
  const classes = useStyles();
  const lockedByQueue = showLockedUser(task);

  let {
    criteria: { countryId, year, month },
    taskTag,
    recentlyCompletedBy,
  } = task.metaInfo;

  let { id: prodId, name: customTitle, contributions, tickets } = task.production || {
    id: task.entityId,
    name: `!No Data`,
    contributions: [],
    tickets: [],
  };

  let country = `CountryId: ${countryId}`;
  for (let c of contributions) {
    if (c.organization?.country?.id === countryId) {
      country = c.organization.country.name;
      break;
    }
  }

  let producer = contributions.find((c) => c.contributionType === 'producer')?.organization;

  let sourceLink = null;
  if (tickets?.length) {
    for (let t of tickets) {
      sourceLink = t.website || t.box_office_link;
      if (sourceLink) {
        break;
      }
    }
  }

  let completedBy = task.completedBy?.id ? task.completedBy.email : '-';

  let rowData = {
    taskId: task.id,
    status: task.isCompleted,
    lockedBy: lockedByQueue,
    completedBy,
    recentlyCompletedBy,
    sourceLink,
    prodId,
    customTitle,
    producer,
    country,
    perfYear: year,
    perfMonth: month,
    taskTag,
  };

  return (
    <TableRow
      key={task.id}
      className={classes.tableRow}
      onClick={() => {
        window.location.href = `#/task_queues/data_qc/${task.id}/show`;
      }}
    >
      {map(rowData, (value, key) => (
        <RenderCell key={key} value={value} dataKey={key} showReadMore />
      ))}
    </TableRow>
  );
};

const DataQCTaskList = () => {
  const classes = useStyles();
  const history = useHistory();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();

  // Get search params
  const searchParams = new URLSearchParams(history.location.search);

  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(parseInt(searchParams?.get('page'), 0) - 1 || 0);
  const [tasks, setTasks] = useState({ data: [], total: 0 });
  const [allValidationLocks, setAllValidationLocks] = useState([]);

  const [users, setUsers] = useState([]);
  const [lockedBy, setLockedBy] = useState(searchParams?.get('locked_by') ? { id: searchParams.get('locked_by'), email: searchParams.get('locked_by') } : '');
  const [completedBy, setCompletedBy] = useState(searchParams?.get('completed_by') ? { id: searchParams.get('completed_by'), email: searchParams.get('completed_by') } : '');
  const [isCompleted, setIsCompleted] = useState(IS_COMPLETED.find((status) => status.value === searchParams?.get('status')) ?? IS_COMPLETED[1]);

  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState('');

  const [producers, setProducers] = useState([]);
  const [producer, setProducer] = useState('');

  const [query, setQuery] = useState('');
  const [selectedProductionId, setSelectedProductionId] = useState(searchParams?.get('production_id') ?? '');
  const debouncedSelectedProductionId = useDebounce(selectedProductionId, 500);
  const [month, setMonth] = useState(MONTHS.find((status) => status.value === searchParams?.get('month')) ?? MONTHS[0]);
  const [year, setYear] = useState(isNaN(searchParams?.get('year')) ? '' : searchParams?.get('year'));
  const debounceYear = useDebounce(year, 500);
  const allIds = useMemo(() => map(tasks, 'id'), [tasks]);

  useEffect(() => {
    searchParams.set('page', page + 1);
    searchParams.set('status', isCompleted?.value);
    searchParams.set('locked_by', lockedBy?.id || '');
    searchParams.set('completed_by', completedBy?.id || '');
    searchParams.set('production_id', debouncedSelectedProductionId);
    searchParams.set('country', country?.id || '');
    searchParams.set('producer_id', producer?.id || '');
    searchParams.set('year', debounceYear);
    searchParams.set('month', month?.value);
    const newSearch = searchParams.toString();
    history.push({ search: newSearch });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompleted, debouncedSelectedProductionId, page, lockedBy, completedBy, country, producer, month, debounceYear]);

  useEffect(() => {
    if (allIds.length) {
      dataProvider
        .getList('validations/locks', {
          filter: { lockIdentifier: allIds.join(','), lockGroup: 'task_queue', validation_status: '' },
          pagination: {},
          sort: {},
        })
        .then(({ data }) => {
          setAllValidationLocks(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [dataProvider, allIds]);

  useEffect(() => {
    setIsLoading(true);
    dataProvider
      .getList('task_queues', {
        filter: {
          task_type: 'data_qc',
          ...(isCompleted?.value && isCompleted.value !== 'all' ? { is_completed: isCompleted.value } : {}),
          production_id: debouncedSelectedProductionId,
          locked_by: lockedBy?.id,
          completed_by: completedBy?.id,
          ...(producer?.id ? { task_specific_filters: JSON.stringify({ producer_id: producer.id }) } : {}),
          metaInfoFilters: JSON.stringify({
            ...(month?.value && { 'criteria.month': month?.value }),
            ...(debounceYear && { 'criteria.year': debounceYear }),
            ...(country?.id && { 'criteria.countryId': country.id }),
          }),
        },
        pagination: { perPage: PER_PAGE, page: page + 1 },
        sort: { field: 'id', order: 'DESC' },
      })
      .then((res) => {
        setTasks(res);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [dataProvider, page, isCompleted, lockedBy, completedBy, debouncedSelectedProductionId, country, producer, month, debounceYear]);

  useEffect(() => {
    dataProvider
      .getList('users', {
        filter: { group_id: 3, query },
        pagination: {},
        sort: {},
      })
      .then((res) => {
        setUsers(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dataProvider, query]);

  useEffect(() => {
    dataProvider
      .getList('organizations', {
        filter: {
          ...(country?.id && { country_id: country.id }),
          query,
        },
        pagination: {},
        sort: {},
      })
      .then((res) => {
        setProducers(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dataProvider, country, query]);

  useEffect(() => {
    dataProvider
      .getList('countries', {
        filter: { query },
        pagination: {},
        sort: {},
      })
      .then((res) => {
        setCountries(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dataProvider, query]);

  const showLockedUser = useCallback(
    (data) => {
      if (allValidationLocks.length) {
        const hasValidationLock = allValidationLocks.find((validationLock) => Number(validationLock.lockIdentifier) === Number(data.id));
        return `${hasValidationLock?.user?.email || '-'}`;
      }
      return '-';
    },
    [allValidationLocks],
  );

  return (
    <div>
      <FormControl size="small" className={classes.filterSection}>
        <MUITextField value={isCompleted} select onChange={(e) => setIsCompleted(e?.target?.value)} label="Status" variant="filled" size="small">
          {IS_COMPLETED.map((option) => (
            <MenuItem key={option.value} value={option}>
              {option.label}
            </MenuItem>
          ))}
        </MUITextField>
        <Autocomplete
          options={users}
          autoComplete
          value={lockedBy}
          onChange={(event, user) => {
            setLockedBy(user);
            setPage(0);
          }}
          onInputChange={(event, input) => {
            setQuery(input);
          }}
          renderInput={(params) => <MUITextField {...params} className={classes.autoComplete} fullWidth variant="filled" label="Locked by" />}
          renderOption={(option) => option?.email}
          getOptionLabel={(option) => option?.email}
        />
        <Autocomplete
          options={users}
          autoComplete
          value={completedBy}
          onChange={(event, user) => {
            setCompletedBy(user);
            setPage(0);
          }}
          onInputChange={(event, input) => {
            setQuery(input);
          }}
          renderInput={(params) => <MUITextField {...params} className={classes.autoComplete} fullWidth variant="filled" label="Completed by" />}
          renderOption={(option) => option?.email}
          getOptionLabel={(option) => option?.email}
        />
        <MUITextField
          size="small"
          className={classes.spacingLeft}
          label="Prod. ID"
          variant="filled"
          value={selectedProductionId}
          onChange={(e) => {
            setSelectedProductionId(e?.target?.value);
            setPage(0);
          }}
        />
        <Autocomplete
          options={countries}
          autoComplete
          value={country}
          onChange={(event, country) => {
            setCountry(country);
            setPage(0);
          }}
          onInputChange={(event, input) => {
            setQuery(input);
          }}
          renderInput={(params) => <MUITextField {...params} className={classes.autoComplete} fullWidth variant="filled" label="Producer Country" />}
          renderOption={(option) => option?.name}
          getOptionLabel={(option) => option?.name}
        />
        <Autocomplete
          options={producers}
          autoComplete
          value={producer}
          onChange={(event, org) => {
            setProducer(org);
            setPage(0);
          }}
          onInputChange={(event, input) => {
            setQuery(input);
          }}
          renderInput={(params) => <MUITextField {...params} className={classes.autoComplete} fullWidth variant="filled" label="Producer Org" />}
          renderOption={(option) => option?.name}
          getOptionLabel={(option) => option?.name}
        />
        <MUITextField
          size="small"
          className={classes.spacingLeft}
          label="Perf. year"
          variant="filled"
          value={year}
          onChange={(e) => {
            setYear(e?.target?.value);
            setPage(0);
          }}
        />
        <MUITextField
          value={month}
          className={classes.spacingLeft}
          select
          onChange={(e) => {
            setMonth(e?.target?.value);
            setPage(0);
          }}
          label="Perf. month"
          variant="filled"
          size="small"
        >
          {MONTHS.map((option) => (
            <MenuItem key={option.value} value={option}>
              {option.label}
            </MenuItem>
          ))}
        </MUITextField>
        <Button variant="contained" className={classes.generateBtn} color="primary" onClick={() => redirect('/task_queues/data_qc/create')}>
          Generate Tasks
        </Button>
      </FormControl>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {tasks?.total > page * PER_PAGE ? (
            <>
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>{TASK_QUEUE_HEADERS.map((column) => (column ? <TableCell key={column}>{column}</TableCell> : <TableCell key={column}>~</TableCell>))}</TableRow>
                  </TableHead>
                  <TableBody>
                    {tasks?.data?.map((task) => (
                      <RenderTableRow key={task.id} task={task} showLockedUser={showLockedUser} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination rowsPerPageOptions={[PER_PAGE]} rowsPerPage={PER_PAGE} onChangePage={(e, currentPage) => setPage(currentPage)} page={page} count={tasks?.total} component="div" />
            </>
          ) : (
            <Typography>No results</Typography>
          )}
        </>
      )}
    </div>
  );
};

const DataQCTaskShow = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [isLoading, setIsLoading] = useState(false);
  const [taskData, setTaskData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [comments, setComments] = useState('');
  const [entityURL, setEntityURL] = useState('');
  const entityRef = useRef();

  useEffect(() => {
    setIsLoading(true);
    if (props?.id) {
      dataProvider.getOne('task_queues', { id: props?.id }).then((res) => {
        setTaskData(res?.data);
        if (res?.data?.production?.id) {
          setEntityURL(`${window.location.origin}/#/productions/${res?.data.production.id}/show`);
        }
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.id]);

  const [handleComplete] = useMutation(
    {
      type: 'create',
      resource: `task_queues/actions`,
      payload: {
        data: {
          actionType: !taskData?.isCompleted ? 'complete' : 'reset',
          id: taskData?.id,
          comments,
        },
      },
    },
    {
      onSuccess: () => {
        notify('Task completed', 'info');
        window.location.reload();
      },
      onFailure: (err) => {
        notify(err?.message, 'error');
      },
    },
  );

  if (isLoading) {
    return <Loading />;
  }

  const loadIframe = (elementId) => {
    const iframe = document.getElementById(elementId);
    const doc = iframe.contentDocument;
    doc.head.innerHTML = `${doc.head.innerHTML}<style>.MuiDrawer-docked { display: none;} .MuiInputLabel-formControl { position: initial !important; } .MuiAppBar-root { display: none; } .layout > div:first-child { margin-top: 0; }</style>`;
  };

  return (
    <div>
      <Title title={<Breadcrumbs {...props} />} />
      <div className={classes.actions}>
        <div className={classes.centerAlign}>
          <Typography variant="caption" className={classes.label}>
            Status
          </Typography>
          <Typography className={classes.validationStatus}>{taskData?.isCompleted ? 'Done' : 'Pending'}</Typography>
        </div>
        <div className={classes.centerAlign}>
          <div className={classes.centerAlign}>
            <Typography variant="caption" className={classes.label}>
              Locked by
            </Typography>
            <ValidationLockByUser {...props} data={{ id: taskData?.id }} lockGroupType="task_queue" />
          </div>
          <div>
            <Button variant="contained" size="small" className={classes.button} onClick={() => setIsOpen(true)}>
              {!taskData?.isCompleted ? 'Mark as complete' : 'Reset'}
            </Button>
          </div>
        </div>
      </div>

      <h1 className={classes.spacingTop} style={{ marginTop: 8 }}>
        Preview of production: <EntityLink entityType="productions" entity={taskData?.production} />
      </h1>
      <iframe id="sourceIframe" className={classes.iframe} title="source" ref={entityRef} src={entityURL} onLoad={() => loadIframe('sourceIframe')} />

      <h1 className={classes.spacingTop}>Related entities that are pending for validation:</h1>
      <div style={{ height: 424, overflowY: 'auto' }}>
        {taskData.pendingRelatedEntities?.length ? (
          <div style={{ maxHeight: 300, overflow: 'auto' }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>EntityType</TableCell>
                    <TableCell>Entity</TableCell>
                    <TableCell>Parent Entity</TableCell>
                    <TableCell>Validation</TableCell>
                    <TableCell>CreatedAt</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {taskData.pendingRelatedEntities?.map((row) => (
                    <TableRow key={`${row.entityType}-${row.entityId}`}>
                      <TableCell>{row.entityType}</TableCell>
                      <TableCell>
                        {row.entityType === 'media' ? (
                          <EntityLink newTab entityType={row.entityType} entity={{ id: row.entityId, title: row.name }} />
                        ) : row.entityType === 'productions' ? (
                          <EntityLink newTab entityType={row.entityType} entity={taskData.production} />
                        ) : (
                          <EntityLink newTab entityType={row.entityType} entity={{ id: row.entityId, name: row.name }} />
                        )}
                      </TableCell>
                      <TableCell>
                        {row.parentEntityType && row.parentEntityId ? (
                          <>
                            {row.parentEntityType}
                            {' -> '}
                            <EntityLink newTab entityType={row.parentEntityType} entity={{ id: row.parentEntityId, name: row.parentEntityName }} />
                          </>
                        ) : (
                          '-'
                        )}
                      </TableCell>
                      <TableCell>{'PENDING'}</TableCell>
                      <TableCell>{row.createdAt}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <Typography variant="caption">No pending items</Typography>
        )}
      </div>

      <h1 className={classes.spacingTop}>Comments:</h1>
      {taskData?.logs?.length ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>User</TableCell>
                <TableCell>Comment</TableCell>
                <TableCell>Timestamp</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderBy(taskData?.logs, 'updatedAt', 'desc')?.map((log) => (
                <TableRow key={log.id}>
                  <TableCell>
                    <EntityLink entityType="users" entity={log?.updatedBy} />
                  </TableCell>
                  <TableCell>
                    <pre style={{ width: 500, margin: 0 }}>{log?.comments}</pre>
                  </TableCell>
                  <TableCell>{log?.updatedAt}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="caption">No Comments</Typography>
      )}

      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          className: classes.dialog,
        }}
      >
        <DialogTitle>{!taskData?.isCompleted ? 'Mark as complete' : 'Reset task'}</DialogTitle>
        <DialogContent>
          {!taskData?.isCompleted && taskData.pendingRelatedEntities?.length ? (
            <Typography variant="subtitle2">
              <span style={{ color: 'red', fontWeight: 'bold' }}>Important: </span>
              {"By marking this task complete, Your name will be attached to the APPROVAL of the following entities. So please make sure you've checked them all."}
              <div style={{ maxHeight: 114, overflowY: 'auto' }}>
                <pre>
                  {taskData.pendingRelatedEntities?.map((row) => (
                    <>
                      {row.entityType}{' '}
                      {row.entityType === 'media' ? (
                        <EntityLink newTab entityType={row.entityType} entity={{ id: row.entityId, title: row.name }} />
                      ) : row.entityType === 'productions' ? (
                        <EntityLink newTab entityType={row.entityType} entity={taskData.production} />
                      ) : (
                        <EntityLink newTab entityType={row.entityType} entity={{ id: row.entityId, name: row.name }} />
                      )}
                      {'\n'}
                    </>
                  ))}
                </pre>
              </div>
            </Typography>
          ) : (
            ''
          )}
          <hr />
          <MUITextField label="Comments*" variant="filled" color="primary" multiline fullWidth rows={4} value={comments} onChange={(e) => setComments(e.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleComplete} disabled={!comments}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const useEditStyles = makeStyles({
  variants: {
    '& p': {
      display: 'none',
    },
    '& li': {
      border: 'none',
    },
    '& label': {
      top: -10,
    },
  },
  roles: {
    '& p': {
      display: 'none',
    },
    '& li': {
      overflowY: 'scroll',
    },
  },
  rolesWrapper: {
    width: 260,
    height: 90,
  },
  withVariants: {
    width: 285,
    height: 300,
  },
  dialogLink: {
    opacity: 0.7,
    cursor: 'pointer',
    '&:hover': {
      background: 0,
      opacity: 1,
    },
  },
  addCreatorBtn: {
    minWidth: 150,
    marginLeft: 15,
  },
  rowLayout: {
    display: 'flex',
    alignItems: 'center',
  },
  group: {
    display: 'flex',
    gap: '15px',
    width: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  standardField: {
    width: 256,
  },
});

const DataQCTaskCreate = (props) => {
  const classes = useEditStyles();
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
    redirect(`/task_queues/data_qc`);
  };

  const transform = (values) => {
    let { taskType, taskTag, countryId, year, month } = values;
    return {
      actionType: 'populate-data-qc-task-queue',
      metaInfo: {
        taskType,
        criteria: { countryId, year, month },
        taskTag,
      },
    };
  };

  return (
    <Create {...props} transform={transform} onSuccess={onSuccess}>
      <SimpleForm>
        <SelectInput source="taskType" style={{ width: 400 }} choices={DATA_QC_TASK_TYPES} validate={required()} />

        <InputLabel>Task Criteria</InputLabel>
        <ReferenceInput label="Producer Country" source="countryId" reference="countries" filterToQuery={(searchText) => ({ query: searchText })} validate={required()}>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>

        <div className={classes.group}>
          <NumberInput type="number" source="year" label="Perf. Year" validate={required()} />
          <NumberInput min={1} max={12} type="number" source="month" label="Perf. Month" validate={required()} />
        </div>

        <InputLabel>Optional</InputLabel>
        <TextInput label="Tag name" source="taskTag" />
      </SimpleForm>
    </Create>
  );
};

export { DataQCTaskList, DataQCTaskShow, DataQCTaskCreate };
