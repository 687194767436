import { useMemo } from 'react';
import { useQueryWithStore } from 'react-admin';

export default function useLanguageQuery() {
  const { data: apiLanguages } = useQueryWithStore({
    type: 'getList',
    resource: 'languages',
    payload: {
      pagination: {},
      sort: {},
    },
  });

  const languages = useMemo(() => (apiLanguages || []).map((lng) => ({ id: lng.slug, name: lng.name })), [apiLanguages]);
  return languages;
}
