import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ArrowRight from '@material-ui/icons/ArrowForwardIos';
import { Link } from 'react-admin';
import { getEntityName, getEntityIcon } from '../utils/entities';

export const Breadcrumbs = (props) => {
  const ref = props.reference ?? props.resource;
  const settings = useSelector((store) => store.admin.resources[ref])?.props;
  const label = settings?.options?.label ?? 'noname';
  const { record } = props;
  const iconRef = getEntityIcon(ref);
  const icon = !iconRef
    ? null
    : React.cloneElement(iconRef, {
        style: {
          margin: '0px 8px -4px 0px',
        },
      });

  useEffect(() => {
    document.title = !record ? label : `${label} - ${getEntityName(record, settings.name)}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!record) {
    return (
      <Link to={`/${settings.name}`} style={{ color: '#005500', textDecoration: 'none', display: 'flex', alignItems: 'center', fontWeight: 'bolder' }}>
        {icon}
        {label}
      </Link>
    );
  }

  return (
    <div>
      <Link to={`/${settings.name}`} style={{ color: '#339933', textDecoration: 'underline' }}>
        {icon}
        {label}
      </Link>

      <ArrowRight style={{ color: '#339933', fontSize: '12px', margin: '0px 8px' }} />

      <span>{getEntityName(record, settings.name)}</span>
    </div>
  );
};
