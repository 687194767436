import React, { useEffect } from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import DashboardWidgetMetrics from './DashboardWidgetMetrics';

const DataDashboard = () => {
  useEffect(() => {
    document.title = 'Data Dashboard';
  }, []);

  return (
    <div>
      <div container style={{ width: '100%' }}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h4" align="center">
              Data Dashboard
            </Typography>
          </CardContent>
        </Card>
      </div>
      <DashboardWidgetMetrics />
    </div>
  );
};

export default DataDashboard;
