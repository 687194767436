import React from 'react';
import { Typography } from '@material-ui/core';

const DisplayBoolean = ({title, data}) => {
  if (data === undefined || data === null) {
    return ''
  }
  return (
    <Typography variant="body2" gutterBottom>
      <span style={{ fontWeight: 'bold' }}>{title}</span> : {`${data?'Yes':'No'}`}
    </Typography>
  )  
};

export default DisplayBoolean;
