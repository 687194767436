import React from 'react';
import {
  List,
  TabbedShowLayout,
  Filter,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  Tab,
  Toolbar,
  SaveButton,
  DeleteButton,
  FunctionField,
  ArrayField,
  ChipField,
  SingleFieldList,
  ReferenceManyField,
  Pagination,
  SelectInput,
} from 'react-admin';
import { Link, withStyles } from '@material-ui/core';
import CustomDateField from '../components/customFields/CustomDateField';
import ValidityShow from '../components/ValidityShow';
import { VALIDATION_STATUSES } from '../constants/validations';
import { Breadcrumbs } from '../components/Breadcrumbs';

const GEONAMES_BASE_URL = `https://www.geonames.org/`;

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const CustomToolbar = withStyles(toolbarStyles)((props) => (
  <Toolbar {...props}>
    <SaveButton />
    <DeleteButton undoable={false} />
  </Toolbar>
));

const CountriesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="query" alwaysOn />
    <SelectInput label="Validation status" source="validation_status" choices={VALIDATION_STATUSES} alwaysOn />
  </Filter>
);

const CitiesFilter = ({ is_iso, ...props }) => (
  <Filter {...props}>
    <ReferenceInput label="Country" source="country_id" reference="countries" filterToQuery={(searchText) => ({ query: searchText })} filter={is_iso && { is_iso }} alwaysOn>
      <AutocompleteInput optionText={(country) => `${country?.name} (ISO code ${country?.isoCountryCode ? country.isoCountryCode.toUpperCase() : 'Unknown/Invalid'})`} />
    </ReferenceInput>
    <TextInput label="Search" source="query" alwaysOn />
  </Filter>
);

export const GeonamesUrlField = (props) => (
  <FunctionField
    {...props}
    render={(city) =>
      city?.geoname_id ? (
        <Link href={`${GEONAMES_BASE_URL}${city.geoname_id}/${city.slug}.html`} target="_blank">
          #{city.geoname_id} {city.name}
        </Link>
      ) : (
        'Not found/Potentially invalid'
      )
    }
  />
);
GeonamesUrlField.defaultProps = {
  label: 'Geonames DB URL',
};

export const CountriesShow = (props) => (
  <ValidityShow
    {...props}
    title={<Breadcrumbs {...props} />}
    additionalValidationCondition={{
      property: 'isoCountryCode',
      invalidLabel: 'Unknown country (no ISO code)',
      validLabel: 'Valid country (has ISO code)',
    }}
  >
    <TabbedShowLayout>
      <Tab label="Country info">
        <TextField source="id" label="ID" />
        <TextField source="name" />
        <TextField source="countryCode" />
        <TextField source="isoCountryCode" label="ISO 2-letter country code" emptyText="(ISO not assigned/Invalid)" />
        <ArrayField source="regions">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
        <CustomDateField source="createdAt" />
        <CustomDateField source="updatedAt" emptyText="(Never)" />
        <TextField source="region.name" label="Region Name" />
      </Tab>
      <Tab label="Areas"></Tab>
      <Tab label="Cities">
        <ReferenceManyField label="Cities" reference="countries/cities" target="country_id" pagination={<Pagination />}>
          <Datagrid rowClick="show">
            <TextField source="id" label="ID" />
            <TextField source="name" />
            <GeonamesUrlField />
            <ArrayField source="areas">
              <SingleFieldList>
                <ChipField clickable={false} source="name" />
              </SingleFieldList>
            </ArrayField>
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </ValidityShow>
);

export const CitiesList = (props) => (
  <List exporter={false} perPage={25} {...props} title={<Breadcrumbs {...props} />} bulkActionButtons={false} filters={<CitiesFilter />}>
    <Datagrid rowClick="show">
      <TextField source="id" label="ID" />
      <TextField source="name" />
      <GeonamesUrlField />
      <ArrayField source="areas">
        <SingleFieldList>
          <ChipField clickable={false} source="name" />
        </SingleFieldList>
      </ArrayField>
      <TextField label="Validation status" source="validationStatus.name" />
    </Datagrid>
  </List>
);

export const CitiesShow = (props) => (
  <ValidityShow
    {...props}
    additionalValidationCondition={{
      property: 'geoname_id',
      invalidLabel: 'Unknown geoname',
      validLabel: 'Valid geoname',
      redirectUrl: (record) => record?.geoname_id && `${GEONAMES_BASE_URL}${record.geoname_id}/${record.slug}.html`,
    }}
    title={<Breadcrumbs {...props} />}
  >
    <TabbedShowLayout>
      <Tab label="Basic info">
        <TextField source="id" label="ID" />
        <TextField source="name" />
        <GeonamesUrlField />
        <ArrayField source="areas">
          <SingleFieldList link={false}>
            <ChipField clickable={false} source="name" />
          </SingleFieldList>
        </ArrayField>
        <FunctionField
          label="Country"
          render={(city) =>
            city?.country?.id ? (
              <Link href={`#/countries/${city.country.id}/show/2`}>
                #{city.country.id} {city.country.name}
              </Link>
            ) : (
              'Not found/Potentially invalid'
            )
          }
        />
        <TextField label="Validation status" source="validationStatus.name" />
      </Tab>
      <Tab label="Aliases">
        <ReferenceManyField label="Aliases" reference="countries/cities/altnames" target="city_id" pagination={<Pagination />}>
          <Datagrid rowClick={false}>
            <TextField source="id" label="ID" />
            <TextField source="name" />
            <GeonamesUrlField />
            <TextField source="feature_code" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Translations">
        <ReferenceManyField label="Translations" reference="countries/cities/translations" target="city_id" pagination={<Pagination />}>
          <Datagrid rowClick={false}>
            <TextField source="id" label="ID" />
            <TextField source="name" />
            <TextField source="language.name" label="Language" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </ValidityShow>
);

export const CityAltnamesList = (props) => (
  <List exporter={false} perPage={25} {...props} title={<Breadcrumbs {...props} />} bulkActionButtons={false} filters={<CitiesFilter is_iso />}>
    <Datagrid rowClick={false}>
      <TextField source="id" label="ID" />
      <TextField source="name" />
      <GeonamesUrlField />
      <TextField source="feature_code" />
      <FunctionField
        label="City"
        render={(geoname) =>
          geoname?.city?.id ? (
            <Link href={`/#/countries/cities/${geoname.city.id}/show/2`}>
              #{geoname.city.id} {geoname.city.name}
            </Link>
          ) : (
            'Not linked'
          )
        }
      />
    </Datagrid>
  </List>
);

export const CountriesEdit = (props) => (
  <Edit {...props} title={<Breadcrumbs {...props} />} toolbar={<CustomToolbar />}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="region.name" label="Region Name" />
    </SimpleForm>
  </Edit>
);

export const CountriesList = (props) => (
  <List exporter={false} {...props} filters={<CountriesFilter />} title={<Breadcrumbs {...props} />} bulkActionButtons={false} perPage={25}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" label="Country Name" />
      <ArrayField source="regions">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
      <TextField label="Validation status" source="validationStatus.name" />
    </Datagrid>
  </List>
);

export default CountriesList;
