import React from 'react';
import { Show, List, TabbedShowLayout, Filter, TextInput, Datagrid, TextField, Tab, SelectInput } from 'react-admin';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { AUTH_TYPES } from '../constants/authTypes';
import CustomDateField from '../components/customFields/CustomDateField';

const AuthLogFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="query" alwaysOn />
    <SelectInput label="Auth Type" source="type" choices={AUTH_TYPES} optionText="name" optionValue="id" alwaysOn />
  </Filter>
);

export const AuthLogShow = (props) => (
  <Show {...props} title={<Breadcrumbs {...props} />}>
    <TabbedShowLayout>
      <Tab label="Auth info">
        <TextField source="name" />
        <CustomDateField source="createdAt" />
        <CustomDateField source="updatedAt" />
        <TextField source="region.name" label="Region Name" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const AuthLogList = (props) => (
  <List exporter={false} {...props} title={<Breadcrumbs {...props} />} sort={{ field: 'id', order: 'DESC' }} filters={<AuthLogFilter />} bulkActionButtons={false} perPage={25}>
    <Datagrid rowClick={() => false}>
      <TextField source="id" />
      <CustomDateField source="timestamp" />
      <TextField source="ip" label="IP" />
      <TextField source="type" label="Type" />
      <TextField source="email" label="E-mail" />
      <TextField source="status" label="Status" />
      <TextField source="application" label="Application" />
    </Datagrid>
  </List>
);

export default AuthLogList;
