import React from 'react';
import { Datagrid, List, TextField, Filter, TextInput, FunctionField } from 'react-admin';
import { Breadcrumbs } from '../components/Breadcrumbs';
import CustomDateField from '../components/customFields/CustomDateField';
import EntityLink from '../components/EntityLink';

const ActivitiesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Entity type" source="entity_type" alwaysOn />
    <TextInput label="Entity ID" source="entity_id" alwaysOn />
  </Filter>
);

export const EntityValidationActivities = (props) => (
  <List exporter={false} {...props} bulkActionButtons={false} title={<Breadcrumbs {...props} />} filters={<ActivitiesFilter />} sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <TextField source="id" sortable={false} />
      <FunctionField label="User" render={(record) => <EntityLink entity={record?.user} entityType="users" />} />
      <CustomDateField source="createdAt" sortable={false} label="Created date" />
      <TextField source="validationStatus.name" sortable={false} label="Validation Status" />
      <TextField source="reason" label="Reason" />
    </Datagrid>
  </List>
);

export default EntityValidationActivities;
