import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { dateParserUTC } from '../../utils/dateParser';

const CustomDateField = ({ source, record = {}, emptyText = '', timeHidden }) => <span>{dateParserUTC(get(record, source, ''), emptyText, timeHidden)}</span>;

CustomDateField.propTypes = {
  emptyText: PropTypes.string,
  timeHidden: PropTypes.bool,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

CustomDateField.defaultProps = {
  timeHidden: false,
};

export default CustomDateField;
