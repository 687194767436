export const PRODUCTION_VALIDATION_STATUSES = {
  PENDING: 1,
  PROCESSING: 2,
  APPROVED: 3,
  REJECTED: 4,
  APPROVED_HIDDEN: 5,
  ESCALATED: 6,
};

export const PRODUCTION_VALIDATION_CHECKLIST = {
  escalate: ['Cannot determine accuracy of information', 'Unable to understand information', 'Unable to understand use case/structure of information'],
  approve: ['Information validated from company website', 'Information validated from social media', 'Information validated from newsletter', 'Information validated from multiple sources'],
  reject: [
    'Information incorrect as per company website',
    'Information incorrect as per social media',
    'Information incorrect as per newsletter',
    'Information incorrect as per multiple sources',
    'Fixed the data point',
    'Could not fix the data point',
    'Missing programme information. Add musical works presented.',
  ],
  rejectInAdmin: [
    'Information incorrect as per company website',
    'Information incorrect as per social media',
    'Information incorrect as per newsletter',
    'Information incorrect as per multiple sources',
    'Fixed the data point',
    'Could not fix the data point',
    'Missing programme information. Add musical works presented.',
  ],
  validate: ['Information validated from company website', 'Information validated from social media', 'Information validated from newsletter', 'Information validated from multiple sources'],
};

export const VALIDATION_STATUSES = [
  {
    id: PRODUCTION_VALIDATION_STATUSES.PENDING,
    label: 'Pending',
  },
  {
    id: PRODUCTION_VALIDATION_STATUSES.APPROVED,
    label: 'Approved',
  },
  {
    id: PRODUCTION_VALIDATION_STATUSES.REJECTED,
    label: 'Rejected',
  },
];

export const CHANGE_REQUEST_VALIDATION_STATUSES = [
  {
    id: 'pending_validation',
    label: 'Pending',
  },
  {
    id: 'approved',
    label: 'Approved',
  },
  {
    id: 'new',
    label: 'Draft',
  },
  {
    id: 'rejected',
    label: 'Rejected',
  },
];
