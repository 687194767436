import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { orderBy, keyBy } from 'lodash';
import { Typography, Dialog, DialogActions, DialogContent, DialogTitle, TextField as MUITextField, Accordion, AccordionSummary, AccordionDetails, Button, Divider } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import {
  List,
  Filter,
  Datagrid,
  TextField,
  FunctionField,
  useDataProvider,
  SelectInput,
  Title,
  Loading,
  useNotify,
  useMutation,
  ReferenceInput,
  AutocompleteInput,
  ListContextProvider,
  Pagination,
  Confirm,
} from 'react-admin';
import CustomDateField from '../components/customFields/CustomDateField';
import { Breadcrumbs } from '../components/Breadcrumbs';
import EntityLink from '../components/EntityLink';
import ValidationLockByUser from '../components/ValidationLockByUser';
import ExternalReferencesTab from '../components/ExternalReferences';
import { ENTITIES } from '../constants/entities';

const useStyles = makeStyles(() => ({
  actions: {
    marginTop: 20,
    borderBottom: '1px solid #ddd',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
  },
  centerAlign: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  spacingRight: {
    marginRight: 10,
  },
  button: {
    marginLeft: 20,
    textTransform: 'inherit',
    marginBottom: 15,
  },
  dialog: {
    width: 500,
  },
  spacingBottom: {
    marginBottom: 10,
  },
  validationStatus: {
    fontSize: 16,
    fontWeight: 'bold',
    border: '1px solid #ff5722',
    padding: 6,
    backgroundColor: '#ff5722',
    color: 'white',
  },
  logContainer: {
    padding: '8px 16px',
  },
  link: {
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  logsAccordion: {
    marginTop: 20,
  },
  logsAccordionDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const QueueFilters = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Status"
      source="is_completed"
      choices={[
        { id: 'false', name: 'Pending' },
        { id: 'true', name: 'Done' },
      ]}
      alwaysOn
    />
    <ReferenceInput label="Locked by" source="locked_by" reference="users" perPage={25} filter={{ group_id: 3 }} filterToQuery={(searchText) => ({ query: searchText })} alwaysOn>
      <AutocompleteInput optionText="email" />
    </ReferenceInput>
    <ReferenceInput label="Task completed by" source="completed_by" reference="users" perPage={25} filter={{ group_id: 3 }} filterToQuery={(searchText) => ({ query: searchText })} alwaysOn>
      <AutocompleteInput optionText="email" />
    </ReferenceInput>
    <SelectInput
      label="Link Purpose"
      source="link_ref_source"
      choices={[
        { id: 'ads_pipeline', name: 'Artist Enrichment' },
      ]}
      alwaysOn
    />
  </Filter>
);

export const SearchAndShowResults = (props) => {
  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResult] = useState({});
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const dataProvider = useDataProvider();

  const { url, taskId, handleConfirmation } = props;

  const listContext = {
    data: keyBy(searchResults?.data, 'id'),
    ids: searchResults?.data?.map(({ id }) => id),
    total: searchResults?.total,
    currentSort: {},
    basePath: props.basePath,
    resource: props.resource,
    selectedIds: [],
    hasList: false,
    page,
    setPage,
    perPage,
    setPerPage,
  };

  useEffect(() => {
    dataProvider
      .getList('search', {
        filter: { query: searchQuery, show_invalid: true, entity_type: ['works', 'profiles'] },
        pagination: { perPage, page },
        sort: {},
      })
      .then((res) => {
        setSearchResult(res);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage]);

  return (
    <div className={classes.search} style={{ marginTop: 20, marginBottom: 20 }}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          setPage(1);
          setPerPage(10);

          dataProvider
            .getList('search', {
              filter: { query: searchQuery, show_invalid: true, entity_type: ['works', 'profiles'] },
              pagination: { perPage, page },
              sort: {},
            })
            .then((res) => {
              setSearchResult(res);
            });
        }}
      >
        <MUITextField
          placeholder="Search keyword…"
          variant="filled"
          style={{ width: 400 }}
          onChange={(event) => {
            setSearchQuery(event.target.value);
          }}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
        />
      </form>

      <div style={{ marginTop: 15 }}>
        <ListContextProvider value={listContext}>
          <Datagrid
            rowClick={(key) => {
              const record = listContext.data[key];
              window.open(`/#/${record.entity_type}/${record.id}/show`, '_blank');
            }}
          >
            <TextField source="id" />
            <TextField source="entity_type" />
            <TextField source="entity" />
            <FunctionField
              label="Action"
              render={(record) => (
                <Button
                  onClick={() => handleConfirmation({ url, actionType: 'enrich_existing', taskId, entityTypeName: record.entity_type, entityId: record.id })}
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  Accept
                </Button>
              )}
            />
          </Datagrid>
          <Pagination />
        </ListContextProvider>
      </div>
    </div>
  );
};

export const KnowledgePanelTaskQueueShow = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [isLoading, setIsLoading] = useState(false);
  const [taskQueueData, setTaskQueueData] = useState({});
  const [taskComments, setTaskComments] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [comments, setComments] = useState('');
  const [isLogExpanded, setLogsExpanded] = useState(false);
  const [dialogIsOpen, setDialogOpen] = useState(false);
  const confirmationContent = `This would create a new entity in OB with the provided link. Are you sure you want to proceed?`;
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleConfirm = ({ url, actionType, entityTypeId, taskId}) => {
    handleConfirmation({ url, actionType, entityTypeId, taskId});
    setDialogOpen(false);
  };

  const handleClick = () => {
    setDialogOpen(true);
  };

  useEffect(() => {
    setIsLoading(true);
    if (props?.id) {
      dataProvider
        .getList('task_queues', {
          filter: {
            id: props?.id,
            task_type: 'external_reference_validations',
          },
          pagination: {},
          sort: {},
        })
        .then(({ data }) => {
          setTaskQueueData(data?.[0]);
          setTaskComments(JSON.parse(data?.[0]?.comments));
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.id]);

  const [handleComplete] = useMutation(
    {
      type: 'create',
      resource: `task_queues/actions`,
      payload: {
        data: {
          actionType: !taskQueueData?.isCompleted ? 'complete' : 'reset',
          id: taskQueueData?.id,
          comments,
        },
      },
    },
    {
      onSuccess: () => {
        notify('Task completed', 'info');
        window.location.reload();
      },
      onFailure: (err) => {
        notify(err?.message, 'error');
      },
    },
  );

  const [mutate] = useMutation();

  const handleConfirmation = ({ url, actionType, taskId, entityTypeId, entityId, entityTypeName }) => {
    if (!actionType) {
      notify('Action type is required', 'error');
      return;
    }
    if (!url) {
      notify('Link is required', 'error');
      return;
    }

    if (entityTypeName === 'works') {
      // eslint-disable-next-line no-param-reassign
      entityTypeId = ENTITIES.WORK;
    } else if (entityTypeName === 'profiles') {
      // eslint-disable-next-line no-param-reassign
      entityTypeId = ENTITIES.PROFILE;
    }

    mutate(
      {
        type: 'create',
        resource: 'system/entity_external_references/actions',
        payload: {
          data: {
            url,
            action_type: actionType,
            task_id: taskId,
            target_entity_type_id: entityTypeId,
            target_entity_id: entityId,
          },
        },
      },
      {
        onSuccess: () => {
          notify('External Reference Action Updated', 'info');
          setIsLoading(false);

          if (props.onCLose) {
            props.onCLose();
          }
        },
        onFailure: (err) => {
          setIsLoading(false);
          notify(err?.message, 'error');
        },
      },
    );
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <Title title={<Breadcrumbs {...props} />} />
      <div className={classes.actions}>
        <div className={classes.centerAlign}>
          <Typography variant="caption" className={classes.spacingRight}>
            Status
          </Typography>
          <Typography className={classes.validationStatus}>{taskQueueData?.isCompleted ? 'Done' : 'Pending'}</Typography>
        </div>
        <div className={classes.centerAlign}>
          <div className={classes.centerAlign}>
            <Typography variant="caption" className={classes.spacingRight}>
              Locked by
            </Typography>
            <ValidationLockByUser {...props} data={{ id: taskQueueData?.id }} lockGroupType="task_queue" />
          </div>
          <div>
            <Button variant="contained" size="small" className={classes.button} onClick={() => setIsOpen(true)}>
              {!taskQueueData?.isCompleted ? 'Mark as complete' : 'Reset'}
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.centerAlign} style={{ marginTop: 10 }}>
        <Typography variant="caption" className={classes.spacingRight}>
          Id
        </Typography>
        <Typography>{taskQueueData?.id}</Typography>
      </div>
      <div className={classes.centerAlign}>
        <Typography variant="caption" className={classes.spacingRight}>
          Link
        </Typography>
        <Typography>
          <a href={taskComments.link}>{taskComments.link}</a>
        </Typography>
      </div>
      <div className={classes.centerAlign}>
        <Typography variant="caption" className={classes.spacingRight}>
          Task added date
        </Typography>
        <Typography>{taskQueueData?.createdAt}</Typography>
      </div>
      <div className={classes.centerAlign}>
        <Typography variant="caption" className={classes.spacingRight}>
          Task updated date
        </Typography>
        <Typography>{taskQueueData?.updatedAt}</Typography>
      </div>

      <Divider component="br" />
      <div style={{ textAlign: 'center' }} className={classes.spacingBottom}>
        <Typography variant="h8" className={classes.spacingRight}>
          System Suggestions
        </Typography>
        <div style={{ marginTop: 20 }}>
          <ExternalReferencesTab link={taskComments.link} displayEntity={1} {...props} />
        </div>
      </div>

      <Divider component="br" />
      <div style={{ textAlign: 'center' }} className={classes.spacingBottom}>
        <Typography variant="h8" className={classes.spacingRight}>
          Search for OB entities
        </Typography>
      </div>
      <SearchAndShowResults url={taskComments.link} taskId={taskQueueData.id} handleConfirmation={handleConfirmation} {...props} />

      <Divider component="br" />
      <div style={{ textAlign: 'center', marginBottom: 20 }}>
        <Typography variant="h8" className={classes.spacingRight}>
          Entity Creation
        </Typography>

        <div style={{ marginTop: 40 }}>
          <Button onClick={handleClick} variant="contained" color="primary" size="large" className={classes.button}>
            No matches! Request AI to Create New {taskComments?.entityType?.name}
          </Button>
          <Confirm
            isOpen={dialogIsOpen}
            title="Confirmation"
            content={confirmationContent}
            onConfirm={() => handleConfirm({ url: taskComments.link, actionType: 'create_new', entityTypeId: taskComments?.entityType?.id, taskId: taskQueueData.id })}
            onClose={handleDialogClose}
          />
        </div>
      </div>
      {taskQueueData?.logs?.length ? (
        <Accordion
          onChange={(e, isExpanded) => {
            setLogsExpanded(isExpanded);
          }}
          expanded={isLogExpanded}
          className={classes.logsAccordion}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.bold}>
            Logs
          </AccordionSummary>
          <AccordionDetails className={classes.logsAccordionDetails}>
            {orderBy(taskQueueData?.logs, 'updatedAt', 'desc')?.map((log) => (
              <li key={log.id} className={classes.logContainer}>
                <Typography variant="caption" className={classes.spacingRight}>
                  Task updated by <EntityLink entity={log?.updatedBy} entityType="users" /> {log?.updatedAt}. Comments: {log?.comments}
                </Typography>
              </li>
            ))}
          </AccordionDetails>
        </Accordion>
      ) : null}
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          className: classes.dialog,
        }}
      >
        <DialogTitle>
          <div>
            {!taskQueueData?.isCompleted ? 'Mark as complete' : 'Reset task'}
            <Typography variant="subtitle2">End score {taskQueueData?.endScore}</Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          <MUITextField label="Comments*" variant="filled" color="primary" multiline fullWidth rows={4} value={comments} onChange={(e) => setComments(e.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleComplete} disabled={!comments}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const KnowledgePanelTaskQueueList = (props) => {
  const dataProvider = useDataProvider();
  const [allValidationLocks, setAllValidationLocks] = useState([]);
  const allData = useSelector((store) => store?.admin?.resources?.['task_queues/external_reference_validations'])?.data;
  const allIds = useMemo(() => Object.keys(allData), [allData]);

  useEffect(() => {
    if (allIds.length) {
      dataProvider
        .getList('validations/locks', {
          filter: { lockIdentifier: allIds.join(','), lockGroup: 'task_queue', validation_status: '' },
          pagination: {},
          sort: {},
        })
        .then(({ data }) => {
          setAllValidationLocks(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allIds]);

  const showLockedUser = useCallback(
    (data) => {
      if (allValidationLocks.length) {
        const hasValidationLock = allValidationLocks.find((validationLock) => Number(validationLock.lockIdentifier) === Number(data.id));
        return `${hasValidationLock?.user?.email || 'Not locked'}`;
      }
      return 'Not locked';
    },
    [allValidationLocks],
  );

  return (
    <List
      {...props}
      title={<Breadcrumbs {...props} />}
      filters={<QueueFilters />}
      filter={{ task_type: 'external_reference_validations' }}
      filterDefaultValues={{ is_completed: false }}
      bulkActionButtons={false}
      sort={{ field: 'priority_score', order: 'DESC' }}
      exporter={false}
    >
      <Datagrid rowClick="show">
        <TextField source="id" />
        <FunctionField label="Link" render={(record) => <a href={JSON.parse(record.comments).link}>{JSON.parse(record.comments).link}</a>} sortable={false} />
        <FunctionField label="Locked By" render={(record) => showLockedUser(record)} sortable={false} />
        <FunctionField source="Status" render={(record) => (record?.isCompleted ? 'Done' : 'Pending')} sortable={false} />
        <CustomDateField label="Task added date" source="createdAt" timeHidden sortBy="created_at" />
        <CustomDateField label="Task updated date" source="updatedAt" timeHidden sortBy="updated_at" />
      </Datagrid>
    </List>
  );
};

export default KnowledgePanelTaskQueueList;
