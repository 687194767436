import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import orderBy from 'lodash/orderBy';
import { Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, TextField as MUITextField, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import { List, Filter, Datagrid, TextField, FunctionField, useDataProvider, SelectInput, Title, Loading, useNotify, useMutation, ReferenceInput, AutocompleteInput, useRedirect } from 'react-admin';
import CustomDateField from '../components/customFields/CustomDateField';
import { Breadcrumbs } from '../components/Breadcrumbs';
import EntityLink from '../components/EntityLink';
import ValidationLockByUser from '../components/ValidationLockByUser';

const useStyles = makeStyles(() => ({
  actions: {
    marginTop: 20,
    borderBottom: '1px solid #ddd',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
  },
  centerAlign: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  spacingRight: {
    marginRight: 10,
  },
  button: {
    marginLeft: 20,
    textTransform: 'inherit',
    marginBottom: 15,
  },
  dialog: {
    width: 500,
  },
  spacingBottom: {
    marginBottom: 10,
  },
  validationStatus: {
    fontSize: 16,
    fontWeight: 'bold',
    border: '1px solid #ff5722',
    padding: 6,
    backgroundColor: '#ff5722',
    color: 'white',
  },
  logContainer: {
    padding: '8px 16px',
  },
  link: {
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  logsAccordion: {
    marginTop: 20,
  },
  logsAccordionDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  marginTop20: {
    marginTop: 20,
  },
}));

const MissingProgrameeQueueFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Status"
      source="is_completed"
      choices={[
        { id: 'false', name: 'Pending' },
        { id: 'true', name: 'Done' },
      ]}
      alwaysOn
    />
    {props?.filterValues?.is_completed !== 'true' && <SelectInput label="All hidden tasks" source="show_hidden" choices={[{ id: 'true', name: 'All hidden tasks' }]} alwaysOn />}
    <ReferenceInput label="Locked by" source="locked_by" reference="users" perPage={25} filter={{ group_id: 3 }} filterToQuery={(searchText) => ({ query: searchText })} alwaysOn>
      <AutocompleteInput optionText="email" />
    </ReferenceInput>
    <ReferenceInput label="Task completed by" source="completed_by" reference="users" perPage={25} filter={{ group_id: 3 }} filterToQuery={(searchText) => ({ query: searchText })} alwaysOn>
      <AutocompleteInput optionText="email" />
    </ReferenceInput>
  </Filter>
);

export const MissingProgrameeQueueShow = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [isLoading, setIsLoading] = useState(false);
  const [taskQueueData, setTaskQueueData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isHideTill, setIsHideTill] = useState(false);
  const [comments, setComments] = useState('');
  const [isLogExpanded, setLogsExpanded] = useState(false);
  const redirect = useRedirect();

  useEffect(() => {
    setIsLoading(true);
    if (props?.id) {
      dataProvider.getList('task_queues', { filter: { id: props?.id, task_type: 'missing_program' }, pagination: {}, sort: {} }).then(({ data }) => {
        setTaskQueueData(data?.[0]);
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.id]);

  const [handleComplete] = useMutation(
    {
      type: 'create',
      resource: `task_queues/actions`,
      payload: {
        data: {
          actionType: !taskQueueData?.isCompleted ? 'complete' : 'reset',
          id: taskQueueData?.id,
          comments,
        },
      },
    },
    {
      onSuccess: () => {
        notify('Task completed', 'info');
        window.location.reload();
      },
      onFailure: (err) => {
        notify(err?.message, 'error');
      },
    },
  );

  const [handleHideTill] = useMutation(
    {
      type: 'create',
      resource: `task_queues/actions`,
      payload: {
        data: {
          actionType: 'hide',
          id: taskQueueData?.id,
          hiddenTill: comments,
        },
      },
    },
    {
      onSuccess: () => {
        redirect(`task_queues/missing_program`);
      },
      onFailure: (err) => {
        notify(err?.message, 'error');
      },
    },
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <Title title={<Breadcrumbs {...props} />} />
      <div className={classes.actions}>
        <div className={classes.centerAlign}>
          <Typography variant="caption" className={classes.spacingRight}>
            Status
          </Typography>
          <Typography className={classes.validationStatus}>{taskQueueData?.isCompleted ? 'Done' : 'Pending'}</Typography>
        </div>
        <div className={classes.centerAlign}>
          <div className={classes.centerAlign}>
            <Typography variant="caption" className={classes.spacingRight}>
              Locked by
            </Typography>
            <ValidationLockByUser {...props} data={{ id: taskQueueData?.id }} lockGroupType="task_queue" />
          </div>
          <div>
            <Button variant="contained" size="small" className={classes.button} onClick={() => setIsHideTill(true)}>
              Hide Till
            </Button>
          </div>
          <div>
            <Button variant="contained" size="small" className={classes.button} onClick={() => setIsOpen(true)}>
              {!taskQueueData?.isCompleted ? 'Mark as complete' : 'Reset'}
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.centerAlign} style={{ marginTop: 10 }}>
        <Typography variant="caption" className={classes.spacingRight}>
          Id
        </Typography>
        <Typography>{taskQueueData?.id}</Typography>
      </div>
      <div className={classes.spacingBottom}>
        <Typography variant="caption" className={classes.spacingRight}>
          Production
        </Typography>
        <EntityLink entity={taskQueueData?.production} entityType="productions" />
      </div>
      <div className={classes.spacingBottom}>
        <Typography variant="caption" className={classes.spacingRight}>
          Comments
        </Typography>
        <Typography>{taskQueueData?.comments}</Typography>
      </div>
      <div className={classes.spacingBottom}>
        <Typography variant="caption" className={classes.spacingRight}>
          Start score
        </Typography>
        <Typography variant="caption">{taskQueueData?.startScore}</Typography>
      </div>
      <div className={classes.centerAlign}>
        <Typography variant="caption" className={classes.spacingRight}>
          Task added date
        </Typography>
        <Typography>{taskQueueData?.createdAt}</Typography>
      </div>
      <div className={classes.centerAlign}>
        <Typography variant="caption" className={classes.spacingRight}>
          Task updated date
        </Typography>
        <Typography>{taskQueueData?.updatedAt}</Typography>
      </div>
      <div className={classes.marginTop20}>
        <Typography variant="h6" gutterBottom>
          Guidelines for the validation
        </Typography>
        <Typography variant="body2" gutterBottom>
          This task queue contains productions that require the addition of complete Musical work programme details and correction of Cast&Crew. The following conditions apply:
        </Typography>
        <Typography variant="body2" gutterBottom>
          1. Previously published production which has been edited and the “Programme not/partially announced” flag has been set
        </Typography>
        <Typography variant="body2" gutterBottom>
          2. Newly published production with a “Programme not/partially announced” flag will appear in a task queue 2 weeks before the first performance date.
        </Typography>
        <Typography variant="body2">Tasks are sorted in the following order:</Typography>
        <Typography variant="body2">
          <li>Sorted by the ascending order of the first performance date. So the oldest production will appear on top.</li>
        </Typography>
        <Typography variant="body">
          What to do with this task queue? <br />
        </Typography>
        <Typography variant="body2" gutterBottom style={{ marginLeft: 20 }}>
          Lock a task to yourself. <br />
          Open Edit production. <br />
          Research information online, and add as much reliable details as possible. <br />
          <li>
            If you know that the programme will be announced later at a certain date, mark this task as “postponed” to a certain date. Enter the date and the task will reappear in a queue on this
            date.
          </li>
          <li>
            If you can not find complete Musical work programme details, keep a flag "Programme not announced/partially announced" and mark the task ticket as complete. Production visibility status
            will NOT be set to “HIDE”, but we will send emails to associated contributors whose contact details we have, to notify that production is incomplete and the programme of performed musical
            works needs to be added.
          </li>
          <li>
            If you can find partial mentions of Musical works from the programme, add as many details as possible, including Cast&Crew but also keep a flag "Programme not announced/partially
            announced" and mark the task ticket as complete.
          </li>
          <li>
            If you can find the full programme of Musical works, update production and Cast&Crew so that no contributors remain under the "Programme not announced" tab and remove the "Programme not
            announced / partially announced" checkmark. Then mark the task ticket as complete.
          </li>
          <li>Leave a comment as you see fit.</li>
        </Typography>
      </div>
      {taskQueueData?.logs?.length ? (
        <Accordion
          onChange={(e, isExpanded) => {
            setLogsExpanded(isExpanded);
          }}
          expanded={isLogExpanded}
          className={classes.logsAccordion}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.bold}>
            Logs
          </AccordionSummary>
          <AccordionDetails className={classes.logsAccordionDetails}>
            {orderBy(taskQueueData?.logs, 'updatedAt', 'desc')?.map((log) => (
              <li key={log.id} className={classes.logContainer}>
                <Typography variant="caption" className={classes.spacingRight}>
                  Task updated by <EntityLink entity={log?.updatedBy} entityType="users" /> {log?.updatedAt}. Comments: {log?.comments}
                </Typography>
              </li>
            ))}
          </AccordionDetails>
        </Accordion>
      ) : null}
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          className: classes.dialog,
        }}
      >
        <DialogTitle>
          <div>
            {!taskQueueData?.isCompleted ? 'Mark as complete' : 'Reset task'}
            <Typography variant="subtitle2">End score {taskQueueData?.endScore}</Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          <MUITextField label="Comments*" variant="filled" color="primary" multiline fullWidth rows={4} value={comments} onChange={(e) => setComments(e.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleComplete} disabled={!comments}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isHideTill}
        onClose={() => setIsHideTill(false)}
        PaperProps={{
          className: classes.dialog,
        }}
      >
        <DialogTitle>
          <div>Hide till</div>
        </DialogTitle>
        <DialogContent>
          <MUITextField label="Hide till" variant="filled" size="small" type="date" onChange={(e) => setComments(e?.target?.value)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsHideTill(false)}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleHideTill} disabled={!comments}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const MissingProgrameeQueueList = (props) => {
  const dataProvider = useDataProvider();
  const [allValidationLocks, setAllValidationLocks] = useState([]);
  const allData = useSelector((store) => store?.admin?.resources?.['task_queues/missing_program'])?.data;
  const allIds = useMemo(() => Object.keys(allData), [allData]);

  useEffect(() => {
    if (allIds.length) {
      dataProvider
        .getList('validations/locks', {
          filter: { lockIdentifier: allIds.join(','), lockGroup: 'task_queue' },
          pagination: {},
          sort: {},
        })
        .then(({ data }) => {
          setAllValidationLocks(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allIds]);

  const showLockedUser = useCallback(
    (data) => {
      if (allValidationLocks.length) {
        const hasValidationLock = allValidationLocks.find((validationLock) => Number(validationLock.lockIdentifier) === Number(data.id));
        return `${hasValidationLock?.user?.email || 'Not locked'}`;
      }
      return 'Not locked';
    },
    [allValidationLocks],
  );

  return (
    <List
      {...props}
      title={<Breadcrumbs {...props} />}
      filters={<MissingProgrameeQueueFilter />}
      filter={{ task_type: 'missing_program' }}
      filterDefaultValues={{ is_completed: false }}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      exporter={false}
    >
      <Datagrid rowClick="show">
        <TextField source="id" />
        <FunctionField label="Production" render={(record) => <EntityLink entity={record?.production} entityType="productions" />} sortable={false} />
        <FunctionField label="Locked By" render={(record) => showLockedUser(record)} sortable={false} />
        <FunctionField source="Status" render={(record) => (record?.isCompleted ? 'Done' : 'Pending')} sortable={false} />
        <CustomDateField label="Task added date" source="createdAt" timeHidden sortBy="created_at" />
        <CustomDateField label="Task updated date" source="updatedAt" timeHidden sortBy="updated_at" />
      </Datagrid>
    </List>
  );
};

export default MissingProgrameeQueueList;
