import React, { useCallback, useEffect, useState } from 'react';
import {
  ArrayField,
  ArrayInput,
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  CheckboxGroupInput,
  Confirm,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  Filter,
  FormDataConsumer,
  FunctionField,
  Link,
  List,
  ReferenceField,
  ReferenceInput,
  SaveButton,
  SelectInput,
  Show,
  SimpleForm,
  SimpleFormIterator,
  SimpleShowLayout,
  TextField,
  TextInput,
  Toolbar,
  useDataProvider,
  useMutation,
  useNotify,
  useRefresh,
} from 'react-admin';
import { Button, Checkbox, FormControl, FormControlLabel, Input, MenuItem, Radio, RadioGroup, Select, TextField as MUITextField, Typography, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import * as dayjs from 'dayjs';
import { isEmpty } from 'lodash';

import CustomDateField from '../components/customFields/CustomDateField';
import CustomDateTimeInput from '../components/customFields/CustomDateTimeInput';
import { SubscriptionsListColors } from '../constants/stylingConstants';
import { dateParserUTC, getTotalDaysInMonths } from '../utils/dateParser';
import { SelectCouponMode } from './CouponsModel';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { productCurrency, SUBSCRIPTION_SOURCES, SUBSCRIPTION_STATUS, SUBSCRIPTION_STATUSES, TRIAL_OPTIONS, PRODUCT_IDS_BY_PROFILE_TYPE, PRODUCT_IDS } from '../constants/subscription';
import DateRange from '../components/DateRange';
import apiClient from '../utils/api';
import ButtonWithConfirmation from '../components/ButtonWithConfirmation';
import { AGENT_TYPE_ID, AOS_TYPE_ID } from '../constants/profiles';

const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    marginLeft: 10,
  },
  field: {
    width: 256,
  },
  freeTrial: {
    width: 256,
    marginBottom: 20,
  },
  freeTrialDate: {
    marginRight: 11,
    width: 145,
  },
  freeTrialMonth: {
    width: 100,
  },
  addonItem: {
    '& li section': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  addonItemPrice: {
    marginLeft: 10,
  },
  radioGroup: { minWidth: 'max-content' },
  row: { display: 'flex' },
  spacingRight: { marginRight: 10 },
  oneTimeCharge: {
    marginRight: 10,
    color: 'white',
  },
  productField: { width: '256px' },
  plan: { display: 'flex', alignItems: 'center', justifyContent: 'flex-start' },
  price: {
    marginBottom: '20px',
    paddingLeft: '20px',
    whiteSpace: 'nowrap',
  },
  showLayout: {
    '& .MuiFormControl-marginDense': {
      flexDirection: 'row',
      alignItems: 'center',
    },

    '& label': { minWidth: 130 },
    '& input': { width: 275 },
    '& .MuiFilledInput-root': { minWidth: 300 },
  },
  addon: {
    '& label': { display: 'none' },
    '& ul': { marginTop: 0 },
    '& input': { width: '100%' },
  },
}));

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const PRODUCT_REFERENCES = {
  1: 'organizations',
  2: 'organizations',
  3: 'organizations',
  4: 'artists',
  5: 'artists',
  6: 'artists',
  10: 'agencies',
  11: 'agencies',
  14: 'agencies',
  56: 'pro',
  57: 'pro',
  61: 'organizations',
  63: 'organizations',
  64: 'organizations',
  65: 'organizations',
  66: 'organizations',
};

const CANCEL_REASON_CODES = {
  product_unsatisfactory: 'Product Unsatisfactory',
  service_unsatisfactory: 'Service Unsatisfactory',
  order_change: 'Order Change',
  other: 'Other',
};

const CustomToolbar = withStyles(toolbarStyles)((props) => {
  const classes = useStyles();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const onSuccess = () => refresh();
  const onFailure = () => refresh();
  const [trialEndDate, setTrialEndDate] = useState('');
  const [priceDetails, setPriceDetails] = useState(null);
  const [reactivateSubscription, setReactivateSubscription] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [cancelOption, setCancelOption] = useState(false);
  const [cancelReasonCode, setCancelReasonCode] = useState(null);
  const [cancelReasonDescription, setCancelReasonDescription] = useState(null);
  const { subscription, editSubscriptionData } = props;
  const product = editSubscriptionData?.product ? editSubscriptionData?.product : subscription?.record?.subscription?.product;

  const [cancel] = useMutation(
    {
      type: 'update',
      target: 'user_id',
      resource: 'users/subscriptions',
      payload: { id: props?.record?.id, data: { status: cancelOption, cancelReasonCode, cancelReasonDescription } },
    },
    { onSuccess, onFailure },
  );

  const getPriceEstimate = (subId) => {
    apiClient
      .post(`users/subscriptions/${subId}/estimate`, {
        subscription: { status: 'active' },
        product: { id: product?.id },
        couponCode: editSubscriptionData?.couponCode,
        coupon: editSubscriptionData?.coupon,
      })
      .then((response) => {
        setPriceDetails(response?.data);
      });
  };

  const onChangeCancelOption = (e) => {
    setCancelOption(e.target.value);
  };

  const onChangeSubOption = (e) => {
    setSubscriptionStatus(e.target.value);
  };

  const onChangeCancelReasonCode = (e) => {
    setCancelReasonCode(e.target.value);
  };

  const onChangeCancelReasonDescription = (e) => {
    setCancelReasonDescription(e.target.value);
  };

  const onChangeReactivateSubscription = (e) => {
    setTrialEndDate(null);
    setReactivateSubscription(e.target.value);
  };

  useEffect(() => {
    if (props?.record.autoCollection) {
      dataProvider
        .getList('users/paymentmethods', {
          pagination: { perPage: 1 },
          sort: { field: 'id' },
          filter: { user_id: props?.record?.user?.id },
        })
        .then((res) => {
          setPaymentMethod(res?.data);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [reactivate] = useMutation(
    {
      type: 'update',
      target: 'user_id',
      resource: 'users/subscriptions',
      payload: {
        id: props?.record?.id,
        data: {
          ...subscription,
          status: subscriptionStatus,
          ...(trialEndDate ? { trialEndAt: new Date(trialEndDate).toISOString(), autoCollection: true } : {}),
          ...(product ? { subscription: { product: { id: product?.id } } } : {}),
          ...(editSubscriptionData?.couponCode ? { couponCode: editSubscriptionData?.couponCode } : {}),
          ...(editSubscriptionData?.coupon ? { coupon: editSubscriptionData?.coupon } : {}),
          ...(reactivateSubscription === 'invoiceNow' ? { invoiceNow: true } : { invoiceNow: false }),
        },
      },
    },
    {
      onSuccess,
      onFailure,
    },
  );

  const [removeCancellation] = useMutation(
    {
      type: 'update',
      target: 'user_id',
      resource: 'users/subscriptions',
      payload: {
        id: props?.record?.id,
        data: {
          removeScheduledCancellation: true,
          ...(props?.record?.status === SUBSCRIPTION_STATUS.IN_TRIAL ? { status: props.record.status, trialEndAt: props.record.trialEndAt } : {}),
        },
      },
    },
    {
      onSuccess,
      onFailure,
    },
  );

  return (
    <Toolbar {...props}>
      <div className={classes.row}>
        <SaveButton className={classes.spacingRight} {...props} disabled={props?.record?.status === SUBSCRIPTION_STATUS.CANCELLED || props?.isSaveDisabled} />
        {props?.record?.id && (
          <>
            <Button
              className={classes.oneTimeCharge}
              variant="contained"
              color="primary"
              component={Link}
              to={{
                pathname: '/invoices/onetime/create',
                state: { record: { userSubscription: { id: props?.record?.id }, buyer: { user: { id: props?.record?.user?.id } } } },
              }}
            >
              One-time charge
            </Button>
            <ButtonWithConfirmation
              classButtonWrapper={classes.spacingRight}
              variant="contained"
              color="primary"
              size="medium"
              content={
                <div>
                  <Typography variant="body1" display="block" gutterBottom>
                    Do you really want to reactivate a subscription? If confirmed, the user will be charged for the upcoming billing period with the credit card on file.
                  </Typography>
                  <FormControl>
                    <RadioGroup row aria-label="cancel subscription" name="row-radio-buttons-group">
                      <FormControlLabel
                        value={SUBSCRIPTION_STATUS.ACTIVE}
                        control={<Radio checked={subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE} onChange={onChangeSubOption} />}
                        label={
                          priceDetails
                            ? `Reactivate subscription (${productCurrency(priceDetails?.currentInvoice?.total?.currency)}${priceDetails?.currentInvoice?.total?.amount})`
                            : 'Reactivate subscription'
                        }
                      />
                      <FormControlLabel
                        value={SUBSCRIPTION_STATUS.IN_TRIAL}
                        control={<Radio checked={subscriptionStatus === SUBSCRIPTION_STATUS.IN_TRIAL} onChange={onChangeSubOption} />}
                        label="Reactivate In Trial"
                      />
                    </RadioGroup>
                  </FormControl>
                  {subscriptionStatus === SUBSCRIPTION_STATUS.IN_TRIAL && (
                    <>
                      <Typography variant="body2" display="block" gutterBottom>
                        (Specifying an end date here will ensure that the subscription is moved to the “trial” state and a charge is made when the trial ends.)
                      </Typography>
                      <input
                        type="date"
                        value={trialEndDate}
                        onChange={(e) => {
                          setReactivateSubscription(null);
                          setTrialEndDate(e.target.value);
                        }}
                      />
                    </>
                  )}
                  {subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE && (
                    <div>
                      <FormControlLabel value="invoiceNow" control={<Radio checked={reactivateSubscription === 'invoiceNow'} onChange={onChangeReactivateSubscription} />} label="Invoice now" />
                      <FormControlLabel
                        value="unbilledCharges"
                        control={<Radio checked={reactivateSubscription === 'unbilledCharges'} onChange={onChangeReactivateSubscription} />}
                        label="Unbilled charges"
                      />
                    </div>
                  )}
                </div>
              }
              title="Reactivate subscription"
              label="Reactivate"
              handleConfirm={reactivate}
              disabled={(props?.record.autoCollection && paymentMethod?.length === 0) || [SUBSCRIPTION_STATUS.ACTIVE, SUBSCRIPTION_STATUS.IN_TRIAL].includes(props?.record.status)}
              onClick={() => getPriceEstimate(props?.record?.id)}
              confirmDisabled={!reactivateSubscription && !trialEndDate}
            />
            <ButtonWithConfirmation
              classButtonWrapper={classes.spacingRight}
              variant="contained"
              color="primary"
              size="medium"
              content={
                <div>
                  <FormControl component="fieldset">
                    <RadioGroup row aria-label="cancel subscription" name="row-radio-buttons-group">
                      <FormControlLabel value="cancelled" control={<Radio checked={cancelOption === 'cancelled'} onChange={onChangeCancelOption} />} label="Cancel immediately" />
                      <FormControlLabel
                        value="non_renewing"
                        control={<Radio checked={cancelOption === 'non_renewing'} onChange={onChangeCancelOption} />}
                        label="Cancel at the end of the term (Schedule cancellation)"
                      />
                    </RadioGroup>
                    <br />
                    <Typography>Cancellation reason</Typography>
                    <Select onChange={onChangeCancelReasonCode}>
                      {Object.keys(CANCEL_REASON_CODES).map((key) => (
                        <MenuItem value={key}>{CANCEL_REASON_CODES[key]}</MenuItem>
                      ))}
                    </Select>
                    {cancelReasonCode === 'other' && <Typography>Cancellation reason description</Typography>}
                    {cancelReasonCode === 'other' && <Input onChange={onChangeCancelReasonDescription} />}
                  </FormControl>
                </div>
              }
              title="Cancel subscription"
              label="Cancel subscription"
              handleConfirm={cancel}
              confirmDisabled={!cancelOption}
              disabled={![SUBSCRIPTION_STATUS.ACTIVE, SUBSCRIPTION_STATUS.IN_TRIAL].includes(props?.record.status)}
            />
            <ButtonWithConfirmation
              classButtonWrapper={classes.spacingRight}
              variant="contained"
              color="primary"
              size="medium"
              content=""
              title="Remove cancellation"
              label="Remove cancellation"
              handleConfirm={removeCancellation}
              disabled={props.record.renewAtx}
            />
          </>
        )}
      </div>
      {[3, 14].includes(props?.record?.subscription?.product?.id) && props?.record?.status === SUBSCRIPTION_STATUS.CANCELLED && <DeleteButton undoable={false} />}
    </Toolbar>
  );
});

const UserSubscriptionsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="query" alwaysOn />
    <ReferenceInput label="Subscription" source="subscription_id" reference="subscriptions" allowEmpty alwaysOn>
      <SelectInput optionText="description" />
    </ReferenceInput>
    <SelectInput label="Subscription status" source="status" choices={SUBSCRIPTION_STATUSES} optionText="name" optionValue="id" alwaysOn />
    <DateRange startDateSource="subscription_start_date_from" endDateSource="subscription_start_date_to" label="Subscription start" alwaysOn />
    <DateRange startDateSource="subscription_end_date_from" endDateSource="subscription_end_date_to" label="Subscription end" alwaysOn />
    <DateRange startDateSource="current_term_start_from" endDateSource="current_term_start_to" label="Subscription current term start" alwaysOn />
    <DateRange startDateSource="cancelled_at_from" endDateSource="cancelled_at_to" label="Subscription cancelled" alwaysOn />
  </Filter>
);

export const UserSubscriptionsShow = (props) => (
  <Show {...props} title={<Breadcrumbs {...props} />}>
    <SimpleShowLayout>
      <TextField source="subscription.description" />
      <TextField source="profile.name" label="Profile" />

      <ReferenceField label="User" reference="users" source="user.id" link="show">
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField label="Agency" reference="agencies" source="agency.id" link="show">
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField label="Organization" reference="organizations" source="organization.id" link="show">
        <TextField source="name" />
      </ReferenceField>

      <FunctionField label="Coupon code" render={(item) => (item?.couponCode ? <Link to={`/coupons/codes/${item.couponCode?.id}/show`}>{item.couponCode.code}</Link> : null)} />

      <FunctionField
        label="Coupon"
        render={(item) =>
          item?.coupon ? (
            <Link to={`/coupons/${item.coupon?.id}/show`}>
              {item.coupon.name} ({item.coupon.discount_type === 'percentage' ? `${item.coupon.discount_percentage} %` : `€${item.coupon.discount_amount / 100}`})
            </Link>
          ) : null
        }
      />

      <ArrayField source="addons">
        <Datagrid>
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="slug" />
          <TextField source="charge_type" />
          <FunctionField label="Price" render={(addon) => `€${parseFloat(addon?.price?.amount).toFixed(2)}`} />
        </Datagrid>
      </ArrayField>

      <TextField source="status" />
      <TextField source="source" />
      <TextField source="sourceDescription" />

      <TextField source="cancelReasonCode" label="Cancellation reason" />

      <TextField source="cancelReasonDescription" label="Cancellation reason (description)" />

      <BooleanField source="isExpired" label="Expired" />

      <BooleanField source="autoCollection" label="Auto-collection" />

      <TextField source="paymentType" />

      <CustomDateField source="startAt" />

      <CustomDateField source="trialEndAt" />

      <CustomDateField source="renewAt" />

      <TextField source="comment" label="Comment" />

      <TextField source="endDate" />

      <TextField source="invoiceNotes" />
    </SimpleShowLayout>
  </Show>
);

export const renderProfile = (profile) => {
  const profileType = profile?.profileType?.name ? ` - ${profile?.profileType?.name}` : '';
  const profileName = profile.name ? `${profile.name}` : 'Profile without name';
  const professionName = profile?.profession?.name ? ` - ${profile?.profession?.name}` : '';

  return `(${profile.id}${profileType})  - ${profileName}${professionName}`;
};

export const renderUser = (user) => {
  const userName = user.name ? `${user.name}` : 'User without name';

  return user ? `(${user.id}) - ${user.email} - ${userName}` : 'Empty value';
};

export const UserSubscriptionsCreate = (props) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const products = useSelector((store) => store.admin.resources.products.data);
  const addonsStore = useSelector((store) => store.admin.resources.addons.data);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [vatPrice, setVatPrice] = useState(null);
  const [isLoadingVat, setIsLoadingVat] = useState(false);
  const [confirmationData, setConfirmationData] = useState({ invoiceLater: false });
  const [trialData, setTrialData] = useState({ dateType: 'days', value: '' });
  const [trialOptions, setTrialOptions] = useState(TRIAL_OPTIONS.DAYS);
  const profiles = useSelector((store) => store.admin.resources.profiles.data);
  const [sourceDescriptions, setSourceDescriptions] = useState([]);

  useEffect(() => {
    dataProvider.getList('users/subscriptions/sourceDescriptions', { filter: {}, pagination: {}, sort: {} }).then((res) => {
      setSourceDescriptions(res?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedUser && selectedProduct && selectedUser?.billingAddresses.length) {
      setIsLoadingVat(true);
      dataProvider
        .create('users/subscriptions/estimate', {
          data: {
            user: { id: selectedUser.id },
            product: { id: selectedProduct },
            addons: selectedAddons,
            coupon: { id: selectedCoupon },
          },
        })
        .then((res) => {
          setIsLoadingVat(false);
          setVatPrice(res.data);
        })
        .catch((error) => {
          setIsLoadingVat(false);
          notify(error.message, 'error');
        });
    }
  }, [selectedUser, selectedProduct, dataProvider, notify, selectedAddons, selectedCoupon]);

  const getUserData = useCallback(
    (userId) => {
      dataProvider.getOne('users', { id: userId }).then(({ data }) => {
        setSelectedUser(data);
      });
    },
    [dataProvider],
  );

  useEffect(() => {
    const userId = props?.location?.state?.record?.user.id;
    if (userId) {
      getUserData(userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserData, props?.location?.state?.record?.user.id]);

  const handlerChangeUser = (userId) => {
    getUserData(userId);
  };

  const handlerChangeProfile = (profileId) => {
    setSelectedProfile(profiles[profileId]);
  };

  const checkAddons = (addonsArray) => {
    const arrayDifference = (a, b) => a.length === b.length && !a.every((val, index) => val === b[index]);

    if (addonsArray.length !== selectedAddons.length) {
      setSelectedAddons(addonsArray);
    }

    if (arrayDifference(addonsArray, selectedAddons)) {
      setSelectedAddons(addonsArray);
    }
  };

  return (
    <Create {...props}>
      <SubscriptionForm confirmationData={confirmationData} trialData={trialData}>
        <FormDataConsumer>
          {({ formData }) => (
            <>
              {selectedUser && selectedUser?.billingAddresses.length === 0 && (
                <Typography variant="h6">
                  Billing country is not set.
                  <Link to={`/users/${formData?.user?.id}/1`} className={classes.link} onClick={(e) => e.stopPropagation()}>
                    Please add billing country
                  </Link>
                </Typography>
              )}
            </>
          )}
        </FormDataConsumer>

        <ReferenceInput
          label="Users"
          source="user.id"
          reference="users"
          filterToQuery={(searchText) => ({ query: searchText })}
          sort={{ field: 'email', order: 'ASC' }}
          allowEmpty
          onChange={handlerChangeUser}
        >
          <AutocompleteInput optionText={(user) => renderUser(user)} matchSuggestion={() => true} />
        </ReferenceInput>

        <FormDataConsumer>
          {({ formData }) => (
            <ReferenceInput
              className={classes.field}
              label="Profile"
              source="profile.id"
              reference="profiles"
              filter={{ access_user_id: formData?.user?.id }}
              filterToQuery={(searchText) => ({ query: searchText })}
              sort={{ field: 'relevance', order: 'DESC' }}
              onChange={handlerChangeProfile}
            >
              <AutocompleteInput optionText={(profile) => renderProfile(profile)} matchSuggestion={() => true} />
            </ReferenceInput>
          )}
        </FormDataConsumer>

        <div className={classes.plan}>
          <ReferenceInput
            className={classes.productField}
            label="Subscription"
            source="product.id"
            reference="products"
            filter={{
              subscription_id: selectedProfile?.profileType?.id ? PRODUCT_IDS_BY_PROFILE_TYPE?.[selectedProfile?.profileType?.id] : Object.values(PRODUCT_IDS),
              product_type: ['subscription', 'excluded_subscription'],
            }}
            onChange={setSelectedProduct}
            perPage={50}
          >
            <AutocompleteInput className={classes.field} optionText="internalName" />
          </ReferenceInput>
          <FormDataConsumer>
            {({ formData: { product } }) => (
              <>
                {product && (
                  <>
                    <div className={classes.price}>
                      <div>Plan price:</div>
                      <div>{`${products[product?.id].priceExclVat.amount} €`}</div>
                    </div>
                    {(vatPrice || isLoadingVat) && (
                      <div className={classes.price}>
                        <div>Full price:</div>
                        <div>{isLoadingVat ? 'Calculating full price...' : `${vatPrice.currentInvoice.total.amount} €`}</div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </FormDataConsumer>
        </div>

        <SelectInput source="source" choices={SUBSCRIPTION_SOURCES} label="Subscription source" onChange={(e) => setConfirmationData({ ...confirmationData, source: e?.target?.value })} />
        <FormDataConsumer>
          {({ formData }) =>
            ['Special Offer', 'Other'].includes(formData?.source) && (
              <Autocomplete
                freeSolo
                options={sourceDescriptions}
                onChange={(e, value) => setConfirmationData({ ...confirmationData, sourceDescription: value?.name })}
                renderInput={(params) => (
                  <MUITextField
                    {...params}
                    style={{ width: 256, marginBottom: 25 }}
                    variant="filled"
                    label="Source description"
                    onChange={(e) => setConfirmationData({ ...confirmationData, sourceDescription: e?.target?.value })}
                  />
                )}
                getOptionLabel={(option) => option?.name}
                size="small"
              />
            )
          }
        </FormDataConsumer>

        {selectedProfile && selectedProfile?.profileType?.id === AOS_TYPE_ID && (
          <ReferenceInput
            label="Organization"
            source="organization.id"
            reference="organizations"
            resettable
            filter={{ id: selectedProfile?.organizations?.length > 0 ? selectedProfile.organizations.map((item) => item.organization.id).join(', ') : '-1' }}
            filterToQuery={(searchText) => ({ query: searchText })}
          >
            <AutocompleteInput />
          </ReferenceInput>
        )}

        {selectedProfile && selectedProfile?.profileType?.id === AGENT_TYPE_ID && (
          <ReferenceInput
            label="Agency"
            source="agency.id"
            reference="agencies"
            resettable
            filter={{ id: selectedProfile?.agencies?.length > 0 ? selectedProfile.agencies.map((item) => item.id).join(', ') : '-1' }}
            filterToQuery={(searchText) => ({ query: searchText })}
          >
            <AutocompleteInput />
          </ReferenceInput>
        )}

        <div className={classes.freeTrial}>
          <MUITextField
            className={classes.freeTrialDate}
            select
            variant="filled"
            size="small"
            label="Trial end date"
            value={trialData.value}
            onChange={(e) => setTrialData({ ...trialData, value: e.target.value })}
          >
            {trialOptions.map((o) => (
              <MenuItem value={o}>{o}</MenuItem>
            ))}
          </MUITextField>
          <MUITextField
            className={classes.freeTrialMonth}
            select
            variant="filled"
            size="small"
            label="Select"
            value={trialData.dateType}
            onChange={(e) => {
              const { value } = e.target;
              const options = value === 'days' ? TRIAL_OPTIONS.DAYS : TRIAL_OPTIONS.MONTHS;

              setTrialData({ value: 0, dateType: value });
              setTrialOptions(options);
            }}
          >
            <MenuItem value="days">Days</MenuItem>
            <MenuItem value="months">Months</MenuItem>
          </MUITextField>
        </div>

        <SelectCouponMode />

        <FormDataConsumer>
          {({ formData }) =>
            formData.coupon_mode === 'direct' && (
              <ReferenceInput label="Coupon" source="coupon.id" reference="coupons" resettable filterToQuery={(searchText) => ({ query: searchText })} alwaysOn onChange={setSelectedCoupon}>
                <AutocompleteInput optionText="name" />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>

        <FormDataConsumer>{({ formData }) => (formData.coupon_mode === 'code' ? <TextInput className={classes.field} label="Code" resettable source="couponCode.code" /> : null)}</FormDataConsumer>

        {/* <BooleanInput source="autoCollection" defaultValue /> */}

        <CustomDateTimeInput source="startAt" label="Start at" />

        <FormDataConsumer>
          {({ formData: { product, addons } }) => {
            if (addons && Array.isArray(addons)) {
              checkAddons(addons);
            }
            return (
              product && (
                <ArrayInput source="addons" className={classes.addonItem}>
                  <SimpleFormIterator>
                    <ReferenceInput label="Addon" source="id" reference="addons" resettable filterToQuery={(searchText) => ({ query: searchText })} sort={{ field: 'name', order: 'ASC' }}>
                      <AutocompleteInput optionText="name" />
                    </ReferenceInput>
                    <FormDataConsumer>
                      {(formItemData) => {
                        const itemValue = formItemData?.id && addons[formItemData && formItemData?.id[7]];
                        const addon = addonsStore[itemValue?.id];
                        return addon && <span className={classes.addonItemPrice}> {`Price: ${addon.price / 100}€`} </span>;
                      }}
                    </FormDataConsumer>
                  </SimpleFormIterator>
                </ArrayInput>
              )
            );
          }}
        </FormDataConsumer>

        {/** TODO Remove ids and replace with params on subscriptions */}
        <FormDataConsumer>
          {({ formData }) =>
            ![1, 3].includes(formData?.subscription?.id) && (
              <CheckboxGroupInput source="invoiceNow" label="" choices={[{ id: 'invoiceNow', name: 'Do not invoice now (In case of Bank transfer or adding billing info VAT)' }]} />
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) => {
            const startAt = formData.startAt ? new Date(formData.startAt) : new Date();
            const withoutRenewalDate = !formData.paymentType || formData.paymentType === 'free';
            const invoiceLater = formData?.invoiceNow && formData?.invoiceNow.some((item) => item === 'invoiceNow');

            if (invoiceLater !== confirmationData.invoiceLater || formData.paymentType !== confirmationData.paymentType || vatPrice !== confirmationData.vatPrice) {
              setConfirmationData({
                ...confirmationData,
                startAt,
                withoutRenewalDate,
                invoiceLater,
                paymentType: formData.paymentType,
                vatPrice,
                source: formData.source,
              });
            }
          }}
        </FormDataConsumer>

        <TextInput source="invoiceNotes" label="Invoice notes" resettable />
      </SubscriptionForm>
    </Create>
  );
};

export const UserSubscriptionsList = (props) => (
  <List title={<Breadcrumbs {...props} />} filters={<UserSubscriptionsFilter />} bulkActionButtons={false} {...props} perPage={25} exporter={false}>
    <Datagrid
      rowClick="show"
      rowStyle={(record) => ({
        backgroundColor: SubscriptionsListColors[record?.status === 'in_trial' && !record?.renewAt ? 'cancelled' : record?.status] || SubscriptionsListColors['fbadmin-default'],
      })}
    >
      <TextField source="id" label="ID" />
      <TextField source="paymentType" label="Payment Type" />
      <TextField source="subscription.description" label="Subscription" />
      <TextField source="user.name" label="User" />
      <TextField source="profile.name" label="Profile" />
      <FunctionField
        label="Coupon"
        render={(item) =>
          item?.coupon ? `${item.coupon.name} (${item.coupon.discount_type === 'percentage' ? `${item.coupon.discount_percentage} %` : `€${item.coupon.discount_amount / 100}`})` : null
        }
      />
      <TextField source="status" label="Status" />
      <BooleanField source="isExpired" label="Expired" />
      <FunctionField label="Start at" render={(sub) => <span>{dateParserUTC(sub?.startAt || sub?.renewAt, 'Not set', false)}</span>} />
      <CustomDateField source="renewAt" />
      <CustomDateField source="endDate" />
      <CustomDateField source="currentTermStart" />
      <CustomDateField source="cancelledAt" />
    </Datagrid>
  </List>
);

const SubscriptionForm = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [priceDetails, setPriceDetails] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const { confirmationData, trialData, editSubscriptionData, record } = props;
  const product = editSubscriptionData?.product ? editSubscriptionData?.product : subscriptionData?.subscription?.product;
  const saveProps = { ...props };
  delete saveProps.confirmationData;
  const { startAt, withoutRenewalDate, invoiceLater, vatPrice, sourceDescription } = confirmationData || {};
  const coupons = useSelector((store) => store.admin.resources.coupons?.data);
  const promotionalCredits = priceDetails?.currentInvoice?.discountType?.find((dis) => dis.mode === 'promotional_credits')?.amount || 0;
  const prorationAmount = priceDetails?.currentInvoice?.discountType.find((dis) => ['refundable', 'credit_note_estimate'].includes(dis.mode))?.amount || 0;
  const couponAmount = priceDetails?.currentInvoice?.discountType.find((dis) => ['document_level_coupon', 'item_level_coupon'].includes(dis.mode))?.amount || 0;

  const getPriceEstimate = (subId) => {
    setLoading(true);
    if (subId) {
      apiClient
        .post(`users/subscriptions/${subId}/estimate`, {
          subscription: { status: 'active' },
          product: { id: product?.id },
          couponCode: editSubscriptionData?.couponCode,
          coupon: editSubscriptionData?.coupon,
          ...(editSubscriptionData?.changeSubOption === 'end_of_term' ? { endOfTerm: true } : {}),
          prorate: editSubscriptionData?.isProRatedApplied,
        })
        .then((response) => {
          setLoading(false);
          setPriceDetails(response?.data);
        });
    }
  };

  const renderSubscriptionInfo = () => {
    if (subscriptionData?.status === SUBSCRIPTION_STATUS.IN_TRIAL) {
      return (
        <div>
          <div>There will be no immediate charges.</div>
          <div>
            An invoice for {productCurrency(product?.priceExclVat?.currency)} {product?.priceExclVat?.amount} will be generated upon activation on{' '}
            {dateParserUTC(subscriptionData?.trialEndAt, '', true)}.
          </div>
        </div>
      );
    }

    if (subscriptionData?.status === SUBSCRIPTION_STATUS.ACTIVE && product?.id !== props?.record?.subscription?.product?.id && editSubscriptionData?.changeSubOption !== 'end_of_term') {
      if (isLoading) return <div>Loading...</div>;
      return (
        <div>
          <div>
            An invoice for {productCurrency(priceDetails?.currentInvoice?.total?.currency)} {priceDetails?.currentInvoice?.total?.amount} will be generated{' '}
            {editSubscriptionData?.changeSubOption === 'end_of_term' ? ` on next renewal (${dateParserUTC(subscriptionData?.renewAt, '', true)})` : 'immediately'}.
          </div>
          {prorationAmount > 0 && <div>Adjustment credits worth EUR {prorationAmount} will be created and adjusted against due invoice(s).</div>}
          <div>Plan name: {product?.internalName}</div>
          {(editSubscriptionData?.couponCode?.code || editSubscriptionData?.coupon?.id) && (
            <div>
              Coupon: {editSubscriptionData?.couponCode?.code} || {coupons?.[editSubscriptionData?.coupon?.id]?.name}
            </div>
          )}
          <div>
            <div style={{ fontWeight: 'bold' }}>Amount</div>
            <div>
              Subscription: {productCurrency(priceDetails?.currentInvoice?.total?.currency)} {priceDetails?.currentInvoice?.total?.amount}
            </div>
            {promotionalCredits ? (
              <div>
                Promotional credits: {productCurrency(priceDetails?.currentInvoice?.total?.currency)} {promotionalCredits}
              </div>
            ) : null}
            {prorationAmount ? (
              <div>
                Prorated credits: {productCurrency(priceDetails?.currentInvoice?.total?.currency)} {prorationAmount}
              </div>
            ) : null}
            {couponAmount ? (
              <div>
                Discount: {productCurrency(priceDetails?.currentInvoice?.total?.currency)} {couponAmount}
              </div>
            ) : null}
          </div>
          <div>
            Total:{' '}
            <span style={{ textDecoration: 'line-through', marginRight: 10 }}>
              {productCurrency(priceDetails?.currentInvoice?.total?.currency)} {product?.priceExclVat?.amount}
            </span>
            <span>
              {productCurrency(priceDetails?.currentInvoice?.total?.currency)} {product?.priceExclVat?.amount - promotionalCredits - prorationAmount - couponAmount}
            </span>
          </div>
        </div>
      );
    }

    if (subscriptionData?.status === SUBSCRIPTION_STATUS.ACTIVE) {
      return (
        <div>
          <div>There will be no immediate charges.</div>
          <div>
            An invoice for {productCurrency(priceDetails?.nextInvoice?.total?.currency)} {priceDetails?.nextInvoice?.total?.amount} will be generated on{' '}
            {dateParserUTC(subscriptionData?.renewAt, '', true)}.
          </div>
        </div>
      );
    }

    if (subscriptionData?.status === SUBSCRIPTION_STATUS.CANCELLED) {
      return (
        <div>
          <div>Subscription status will be changed from cancelled to active / trial</div>
          <div>
            {editSubscriptionData?.invoiceNow ? (
              <div>
                <div>Invoice now</div>
                <div>
                  (i) An invoice for {productCurrency(product?.priceExclVat?.currency)} {product?.priceExclVat?.amount} amount will be generated immediately
                </div>
                <div>(ii) This preview does not include taxes. Taxes will be added when the invoice is raised.</div>
              </div>
            ) : (
              <div>
                <div>Unbilled charges</div>
                <div>
                  (i) A charge of {productCurrency(product?.priceExclVat?.currency)} {product?.priceExclVat?.amount} amount will be added to the unbilled charges
                </div>
                <div>(ii) This preview does not include taxes. Taxes will be added when the invoice is raised.</div>
              </div>
            )}
          </div>
        </div>
      );
    }

    return (
      <div>
        <div>Subscription start date: {dateParserUTC(subscriptionData?.startAt || startAt)}</div>
        {subscriptionData?.trialEndAt ? <div>Trial end date: {dateParserUTC(subscriptionData?.trialEndAt, '', true)}</div> : null}
        {!withoutRenewalDate && vatPrice?.next_billing_at ? <div>Renewal date: {vatPrice?.next_billing_at}</div> : ''}
        {!isEmpty(vatPrice) && (
          <>
            <div>
              VAT: {productCurrency(vatPrice?.currentInvoice?.vatAmount?.currency)} {vatPrice?.currentInvoice?.vatAmount?.amount}
            </div>
            <div>
              Total price: {productCurrency(vatPrice?.currentInvoice?.total?.currency)} {vatPrice?.currentInvoice?.total?.amount}
            </div>
          </>
        )}
        {invoiceLater && <div>The charges will be added to unbilled charges. Click on Invoice now in the payments section to invoice the charges.</div>}
      </div>
    );
  };

  return (
    <>
      <Confirm
        isOpen={isOpen}
        title={editSubscriptionData?.isEdit ? 'Update subscription' : 'Create subscription'}
        content={renderSubscriptionInfo()}
        onConfirm={() => {
          setIsOpen(false);
          return saveProps.save(subscriptionData, `/users/${saveProps?.record?.user?.id}/show/3`);
        }}
        onClose={() => {
          setIsOpen(false);
        }}
      />

      <SimpleForm
        {...saveProps}
        toolbar={<CustomToolbar {...props} isSaveDisabled={confirmationData?.startAt && !confirmationData?.source} subscription={subscriptionData} editSubscriptionData={editSubscriptionData} />}
        save={(formRecord) => {
          const trialEndAt =
            trialData?.dateType === 'months'
              ? dateParserUTC(dayjs().add(dayjs.duration({ days: getTotalDaysInMonths(trialData?.value) })))
              : dateParserUTC(dayjs().add(dayjs.duration({ days: trialData?.value })));
          setIsOpen(true);
          getPriceEstimate(formRecord?.id);
          return setSubscriptionData({
            ...formRecord,
            ...(trialData?.value ? { trialEndAt, autoCollection: true } : {}),
            ...(editSubscriptionData?.isProRatedApplied ? { prorate: true } : {}),
            ...(editSubscriptionData?.changeSubOption === 'immediately' && editSubscriptionData?.isEdit ? { invoiceNow: true } : { invoiceNow: false }),
            changeOption: editSubscriptionData?.changeSubOption,
            ...(product ? { subscription: { product: { id: product?.id } } } : {}),
            ...(startAt ? { invoiceNow: !invoiceLater } : {}),
            ...(editSubscriptionData?.isEdit ? { invoiceNow: editSubscriptionData?.invoiceNow } : {}),
            ...(formRecord.product?.id ? { subscription: { product: { id: formRecord.product?.id } } } : {}),
            ...(record.status === 'in_trial' || !!trialData ? {} : { trialEndAt: null }),
            ...(sourceDescription ? { sourceDescription } : {}),
          });
        }}
      />
    </>
  );
};

const getProfileType = (subscription) => {
  const profileTypeSubscriptions = {
    1: [5, 6],
    2: [3, 4],
    3: [1, 2],
  };
  return Object.keys(profileTypeSubscriptions).find((item) => profileTypeSubscriptions[item].includes(subscription.id));
};

export const UserSubscriptionsEdit = (props) => {
  const classes = useStyles();
  const subscription = useSelector((store) => store.admin.resources['users/subscriptions']?.data?.[props.id]);
  const products = useSelector((store) => store.admin.resources.products?.data);
  const [changeSubOption, setChangeSubOption] = useState('immediately');
  const [isProRatedApplied, setIsProRatedApplied] = useState(true);
  const [invoiceOption, setInvoiceOption] = useState(true);
  const [editSubscriptionData, setEditSubscriptionData] = useState({
    isEdit: true,
    invoiceNow: true,
    changeSubOption: 'immediately',
    isProRatedApplied: true,
  });

  return (
    <Edit {...props} title={<Breadcrumbs {...props} />} target="user_id" className={classes.showLayout}>
      <SubscriptionForm editSubscriptionData={editSubscriptionData}>
        <TextField source="status" label="Subscription status" />
        <FunctionField
          label="Plan change"
          render={(item) => (
            <ReferenceInput
              label=""
              source="subscription.product.id"
              reference="products"
              record={item}
              filter={{ profile_type_id: item?.profile?.profileType.id, product_type: ['subscription', 'excluded_subscription'] }}
              onChange={(e) => {
                const productId = e?.target?.value;
                setEditSubscriptionData({ ...editSubscriptionData, product: products[productId] });
              }}
            >
              <SelectInput optionText="internalName" helperText="" />
            </ReferenceInput>
          )}
        />
        <TextField source="source" />
        <TextField source="sourceDescription" label="Source description" />
        <TextField source="user.name" label="User" />
        <FunctionField
          label="Profile"
          render={() => (
            <div style={{ display: 'flex' }}>
              <TextInput source="profile" format={(profile) => (profile ? `${profile.name} - ${profile.id}` : 'No profile')} disabled label="" />
            </div>
          )}
        />
        <FunctionField
          label="Organization"
          render={() => (
            <FormDataConsumer>
              {({ formData: { product } }) =>
                (product && ['organizations', 'pro'].includes(PRODUCT_REFERENCES[product.id])) || subscription?.organization?.id ? (
                  <ReferenceInput label="Organization" source="organization.id" reference="organizations" resettable filterToQuery={(searchText) => ({ query: searchText })}>
                    <AutocompleteInput disabled={subscription?.organization?.id} />
                  </ReferenceInput>
                ) : null
              }
            </FormDataConsumer>
          )}
        />

        <FunctionField
          label="Agency"
          render={() => (
            <FormDataConsumer>
              {({ formData: { product } }) =>
                (product && ['agencies', 'pro'].includes(PRODUCT_REFERENCES[product.id])) || subscription?.agency?.id ? (
                  <ReferenceInput label="Agency" source="agency.id" reference="agencies" resettable filterToQuery={(searchText) => ({ query: searchText })}>
                    <AutocompleteInput disabled={subscription?.agency?.id} />
                  </ReferenceInput>
                ) : null
              }
            </FormDataConsumer>
          )}
        />

        <FunctionField
          label="Assign subscription to"
          render={() => (
            <FormDataConsumer>
              {({ formData }) => {
                const filter = (searchText) =>
                  formData.profile
                    ? { query: searchText, profile_type_id: getProfileType(formData?.subscription), exclude_id: formData?.profile?.id }
                    : { query: searchText, exclude_id: formData?.profile?.id };
                return (
                  <ReferenceInput label="Assign subscription to" source="assignToProfile.id" reference="profiles" filter={{ access_user_id: formData?.user?.id }} filterToQuery={filter}>
                    <AutocompleteInput optionText={(record) => `${record?.name} - ${record.id}` || `(empty, #ID: ${record?.id || ''})`} />
                  </ReferenceInput>
                );
              }}
            </FormDataConsumer>
          )}
        />
        <FunctionField
          label="Add coupon"
          render={() => (
            <div style={{ display: 'flex' }}>
              <SelectCouponMode />
              <FormDataConsumer>
                {({ formData }) =>
                  formData.coupon_mode === 'direct' ? (
                    <ReferenceInput
                      label="Coupon"
                      source="coupon.id"
                      reference="coupons"
                      resettable
                      filterToQuery={(searchText) => ({ query: searchText })}
                      alwaysOn
                      onChange={(couponId) => {
                        setEditSubscriptionData({ ...editSubscriptionData, coupon: { id: couponId }, couponCode: null });
                      }}
                    >
                      <AutocompleteInput optionText="name" />
                    </ReferenceInput>
                  ) : null
                }
              </FormDataConsumer>
              <FormDataConsumer>
                {({ formData }) =>
                  formData.coupon_mode === 'code' ? (
                    <TextInput
                      label="Code"
                      resettable
                      source="couponCode.code"
                      onChange={(e) => {
                        const couponCode = e?.target?.value;
                        setEditSubscriptionData({ ...editSubscriptionData, couponCode: { code: couponCode }, coupon: null });
                      }}
                    />
                  ) : null
                }
              </FormDataConsumer>
            </div>
          )}
        />
        <FunctionField
          label="Addons"
          render={() => (
            <ArrayInput source="addons" className={classes.addon}>
              <SimpleFormIterator>
                <ReferenceInput label="" source="id" reference="addons" resettable filterToQuery={(searchText) => ({ query: searchText })} sort={{ field: 'name', order: 'ASC' }}>
                  <AutocompleteInput optionText="name" />
                </ReferenceInput>
              </SimpleFormIterator>
            </ArrayInput>
          )}
        />
        <FunctionField
          label="Trial end date"
          render={() => <FormDataConsumer>{({ formData }) => (formData.status === SUBSCRIPTION_STATUS.IN_TRIAL ? <CustomDateTimeInput source="trialEndAt" /> : null)}</FormDataConsumer>}
        />
        <FunctionField label="Renew at date" render={() => <CustomDateTimeInput source="renewAt" />} />
        <FunctionField label="End date" render={() => <CustomDateTimeInput source="endDate" />} />
        <FunctionField label="Current term start at" render={() => <CustomDateTimeInput source="activatedAt" />} />
        <FunctionField label="Invoice notes" render={() => <TextInput source="invoiceNotes" label="Invoice notes" />} />
        <FormDataConsumer>{({ formData }) => <BooleanInput source="autoCollection" defaultValue disabled={formData.status === SUBSCRIPTION_STATUS.IN_TRIAL} />}</FormDataConsumer>

        {subscription?.status !== SUBSCRIPTION_STATUS.CANCELLED && (
          <>
            <div>Apply Changes</div>
            <RadioGroup row aria-label="cancel subscription">
              <div className={classes.radioGroup}>
                <FormControlLabel
                  value="immediately"
                  control={
                    <Radio
                      checked={changeSubOption === 'immediately'}
                      onChange={(e) => {
                        setChangeSubOption(e?.target?.value);
                        setEditSubscriptionData({ ...editSubscriptionData, changeSubOption: e?.target?.value });
                      }}
                    />
                  }
                  label="Immediately"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={changeSubOption === 'end_of_term'}
                      checked={isProRatedApplied}
                      onChange={(e) => {
                        setIsProRatedApplied(e?.target?.checked);
                        setEditSubscriptionData({ ...editSubscriptionData, isProRatedApplied: e?.target?.checked });
                      }}
                    />
                  }
                  label="Apply prorated credits and charges"
                />
              </div>
              <FormControlLabel
                value="end_of_term"
                control={
                  <Radio
                    checked={changeSubOption === 'end_of_term'}
                    onChange={(e) => {
                      setChangeSubOption(e?.target?.value);
                      setIsProRatedApplied(false);
                      setEditSubscriptionData({ ...editSubscriptionData, changeSubOption: e?.target?.value, isProRatedApplied: false });
                    }}
                  />
                }
                label="On Next Renewal"
              />
            </RadioGroup>
          </>
        )}

        <div style={{ marginTop: 20 }}>
          <div>Invoicing options</div>
          <RadioGroup row aria-label="Invoice now" className={classes.radioGroup}>
            <FormControlLabel
              value="invoice_now"
              control={
                <Radio
                  disabled={changeSubOption === 'end_of_term'}
                  checked={invoiceOption}
                  onChange={() => {
                    setInvoiceOption(true);
                    setEditSubscriptionData({ ...editSubscriptionData, invoiceNow: true });
                  }}
                />
              }
              label="Invoice now"
            />
            <FormControlLabel
              value="end_of_term"
              control={
                <Radio
                  disabled={changeSubOption === 'end_of_term'}
                  checked={!invoiceOption}
                  onChange={() => {
                    setInvoiceOption(false);
                    setEditSubscriptionData({ ...editSubscriptionData, invoiceNow: false });
                  }}
                />
              }
              label="Add to unbilled charges"
            />
          </RadioGroup>
        </div>
      </SubscriptionForm>
    </Edit>
  );
};

export default UserSubscriptionsList;
