/* eslint-disable no-underscore-dangle,no-unused-expressions,no-unused-vars */
// Determines if there are new files to upload
const determineUploadFieldNames = (params, uploadFields = []) => {
  if (!params.data) return [];

  // Check if the field names are mentioned in the uploadFields
  // and verify there are new files being added
  const requestUploadFieldNames = [];
  Object.keys(params.data).forEach((field) => {
    let fieldData = params.data[field];
    // if (uploadFields.includes(field)) {
    fieldData = !Array.isArray(fieldData) ? [fieldData] : fieldData;
    // eslint-disable-next-line no-unused-expressions
    fieldData.filter((f) => f && f.rawFile instanceof File).length > 0 && requestUploadFieldNames.push(field);
    // }
  });
  // Return an array of field names where new files are added
  return requestUploadFieldNames;
};

// Handles file uploading for CREATE and UPDATE types
const getFileUploadFormData = (type, resource, params, uploadFieldNames) => {
  const { data } = params;
  const formData = new FormData();

  uploadFieldNames.forEach((fieldName) => {
    let fieldData = params.data[fieldName];
    fieldData = !Array.isArray(fieldData) ? [fieldData] : fieldData;
    const existingFileIds = [];

    fieldData.forEach((item) => {
      item.rawFile instanceof File ? formData.append(`${item.title}`, item.rawFile) : existingFileIds.push(item.id || item._id);

      data[fieldName] = { file: `@${item.title}` };
    });

    // data[fieldName] = [...existingFileIds];
  });
  formData.append('data', JSON.stringify(data));

  return formData;
};

const addUploadFeature = (requestHandler) => (type, service, params, uploadFields = []) => {
  const uploadFieldNames = determineUploadFieldNames(params, uploadFields);

  if (uploadFieldNames.length > 0) {
    const formData = getFileUploadFormData(type, service, params, uploadFieldNames);
    return requestHandler(type, service, { data: formData });
  }

  return requestHandler(type, service, params);
};

export default addUploadFeature;
