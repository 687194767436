/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, cloneElement, Children, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Typography, Card, ListItemText, Popper, Fade, Paper } from '@material-ui/core';
import classnames from 'classnames';
import { get, map } from 'lodash';
import ClickAwayListener from 'material-ui/internal/ClickAwayListener';
import { useResourceDefinition, useShowContext, ShowContextProvider, ResourceContextProvider, useCheckMinimumRequiredProps, useShowController } from 'ra-core';
import ShowActions from 'ra-ui-materialui/lib/detail/ShowActions';
import TitleForRecord from 'ra-ui-materialui/lib/layout/TitleForRecord';
import { MuiThemeProvider } from 'material-ui/styles';
import { Block as BlockIcon, VerifiedUser as VerifiedUserIcon, Help as HelpIcon, Edit as EditIcon } from '@material-ui/icons';
import { useRedirect, useDataProvider } from 'react-admin';
import { VALIDATION_STATUSES_IDS } from '../constants/validations';
import { getEntityName, getEntityIcon } from '../utils/entities';
import EntityActions from './EntityActions';
import ChangeValidationStatus from './ChangeValidationStatus';

const useStyles = makeStyles(
  {
    main: {
      display: 'flex',
    },
    noActions: {
      marginTop: '1em',
    },
    card: {
      flex: '1 1 auto',
    },
    validationStateBar: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '0.5rem',
      justifyContent: 'space-between',
    },
    validationContainer: {
      display: 'flex',
    },
    validationHeader: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      cursor: 'pointer',
      margin: '0 0.5rem',
    },
    validationLabel: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    entityActions: {
      display: 'flex',
      alignItems: 'center',
      padding: 10,
    },
    spacing: { padding: 10 },
    helperText: { fontSize: 12, alignItems: 'center', display: 'flex' },
    icon: { fontSize: 12, marginRight: 2 },
    header: { display: 'flex', alignItems: 'center' },
    validationView: {
      padding: 10,
      width: 250,
    },
    button: { textTransform: 'inherit', padding: 4, textAlign: 'center', width: '100%' },
    listItem: { padding: '2px 4px' },
    listItemText: { '& span': { fontSize: 14, textDecoration: 'underline' } },
    validationTitle: { fontSize: 14, fontWeight: 500 },
    capitalizeText: {
      '&:first-letter': {
        textTransform: 'uppercase',
      },
    },
    list: { padding: 0 },
    validationIcon: { fontSize: 18 },
  },
  { name: 'RaShow' },
);

const EntityTitle = (props) => {
  const ref = props.reference ?? props.resource;
  const settings = useSelector((store) => store.admin.resources[ref])?.props;

  const label = getEntityName(props?.record, settings.name, true);
  const iconRef = getEntityIcon(ref);
  const icon =
    iconRef &&
    React.cloneElement(iconRef, {
      style: {
        margin: '0px 8px -4px 0px',
      },
    });

  return (
    <div style={{ maxWidth: '50%' }}>
      <Typography component="h6" variant="h6">
        {icon} {label}
      </Typography>
    </div>
  );
};

const ValidationView = (props) => {
  const dataProvider = useDataProvider();
  const classes = useStyles(props);
  const redirect = useRedirect();
  const [selectAnchorEl, setSelectAnchorEl] = useState(null);

  const { profile, entityQueueValidationCount, userPerformanceCount, redMaskCount, changeRequestCount, pendingValidationCount, resource, record, disableEditValidation } = props;
  const isProfile = resource === 'profiles';
  const isProduction = resource === 'productions';
  const isOrganization = resource === 'organizations';
  const isAgency = resource === 'agencies';
  const isWork = resource === 'works';
  const [employeeIds, setEmployeesIds] = useState({
    agents: [],
    organizationEmployees: get(profile, 'organizations.[0].employee.id') ? [get(profile, 'organizations.[0].employee.id')] : [],
  });

  useEffect(() => {
    if (isAgency || get(profile, 'agencies.[0].id')) {
      dataProvider
        .getList('agencies/agents', {
          filter: { agency_id: isAgency ? record?.id : [], all: true, profile_id: get(profile, 'agencies.[0].id') ? record?.id : [] },
          pagination: { perPage: 1000, page: 1 },
          sort: {},
        })
        .then((res) => {
          const ids = map(res?.data, 'id');
          setEmployeesIds({ ...employeeIds, agents: ids });
        });
    }

    if (isOrganization) {
      dataProvider
        .getList(`organizations/${record?.id}/employees`, {
          filter: { validity: 0, validation_status: [] },
          pagination: { perPage: 100, page: 1 },
          sort: {},
        })
        .then((res) => {
          const ids = map(res?.data, 'id');
          setEmployeesIds({ ...employeeIds, organizationEmployees: ids });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProvider, record?.id]);

  const redirectToValidations = (filter) => redirect(`/validations?filter=${JSON.stringify(filter)}`);

  return (
    <div className={classes.validationView}>
      {!entityQueueValidationCount && !profile?.pendingValidations?.length && !disableEditValidation ? (
        <>
          <Typography className={classes.validationTitle}>Change validation status</Typography>
          <ChangeValidationStatus {...props} onCLose={props.onCLose} selectAnchorEl={selectAnchorEl} setSelectAnchorEl={setSelectAnchorEl} />
        </>
      ) : null}
      {pendingValidationCount ? <Typography className={classes.validationTitle}>Pending validations</Typography> : null}
      <List exporter={false} className={classes.list}>
        {get(profile, 'pendingValidations', [])?.map((validation) => (
          <ListItem className={classes.listItem} button onClick={() => redirectToValidations({ id: validation.ids })}>
            <ListItemText className={classes.listItemText}>
              {validation.ids.length} <span className={classes.capitalizeText}>{validation.entityType} entities</span>
            </ListItemText>
          </ListItem>
        ))}
        {entityQueueValidationCount > 0 ? (
          <ListItem
            className={classes.listItem}
            button
            onClick={() => redirect(`/validations/?filter=${JSON.stringify({ entity_id: record?.id, status_id: VALIDATION_STATUSES_IDS.PENDING, entity_type: resource })}`)}
          >
            <ListItemText className={classes.listItemText}>
              {entityQueueValidationCount} <span className={classes.capitalizeText}>{resource} entities</span>
            </ListItemText>
          </ListItem>
        ) : null}
        {userPerformanceCount > 0 ? (
          <ListItem className={classes.listItem} button onClick={() => redirect(`/validations/performances?filter=${JSON.stringify({ profile_id: record?.id, validation_status: 'pending' })}`)}>
            <ListItemText className={classes.listItemText}>{userPerformanceCount} User production requests</ListItemText>
          </ListItem>
        ) : null}
        {redMaskCount > 0 ? (
          <ListItem className={classes.listItem} button onClick={() => redirect(`/validations/redmask-requests?profile_id=${record?.id}&validation_status_id=${VALIDATION_STATUSES_IDS.PENDING}`)}>
            <ListItemText className={classes.listItemText}>{redMaskCount} Request red masks</ListItemText>
          </ListItem>
        ) : null}
        {changeRequestCount > 0 ? (
          <ListItem className={classes.listItem} button onClick={() => redirect(`/validations/change-requests?profile_id=${record?.id}&status=pending_validation`)}>
            <ListItemText className={classes.listItemText}>{changeRequestCount} Change requests</ListItemText>
          </ListItem>
        ) : null}
      </List>
      {pendingValidationCount ? <hr /> : null}
      <Typography className={classes.validationTitle}>All validations</Typography>
      <List exporter={false} className={classes.list}>
        {isProfile && (
          <>
            <ListItem className={classes.listItem} button onClick={() => redirect(`/validations/performances?filter=${JSON.stringify({ profile_id: record?.id })}`)}>
              <ListItemText className={classes.listItemText}>User production requests</ListItemText>
            </ListItem>
            <ListItem className={classes.listItem} button onClick={() => redirect(`/validations/redmask-requests?profile_id=${record?.id}&status_id=all`)}>
              <ListItemText className={classes.listItemText}>Redmask requests</ListItemText>
            </ListItem>
            {isProfile && (
              <ListItem className={classes.listItem} button onClick={() => redirect(`/productions/redMaskRequests?filter=${JSON.stringify({ requested_by_profile_id: record?.id })}`)}>
                <ListItemText className={classes.listItemText}>Artist red masks</ListItemText>
              </ListItem>
            )}
            <ListItem className={classes.listItem} button onClick={() => redirect(`/validations/change-requests?profile_id=${record?.id}&status_id=all`)}>
              <ListItemText className={classes.listItemText}>Change requests</ListItemText>
            </ListItem>
          </>
        )}
        {isProduction && (
          <>
            <ListItem className={classes.listItem} button onClick={() => redirect(`/validations/production-validations?production_id=${record?.id}&status_id=all`)}>
              <ListItemText className={classes.listItemText}>Production validations</ListItemText>
            </ListItem>
            <ListItem className={classes.listItem} button onClick={() => redirect(`/validations/redmask-requests?production_id=${record?.id}&status_id=all`)}>
              <ListItemText className={classes.listItemText}>Redmask requests</ListItemText>
            </ListItem>
            <ListItem className={classes.listItem} button onClick={() => redirect(`/productions/redMaskRequests?filter=${JSON.stringify({ production_id: record?.id })}`)}>
              <ListItemText className={classes.listItemText}>Artist red masks</ListItemText>
            </ListItem>
            <ListItem className={classes.listItem} button onClick={() => redirect(`/validations/change-requests?production_id=${record?.id}&status_id=all`)}>
              <ListItemText className={classes.listItemText}>Change requests</ListItemText>
            </ListItem>
          </>
        )}
        {employeeIds?.agents?.length ? (
          <ListItem className={classes.listItem} button onClick={() => redirectToValidations({ entity_id: employeeIds?.agents, entity_type: 'agents' })}>
            <ListItemText className={classes.listItemText}>Agents validations</ListItemText>
          </ListItem>
        ) : null}
        {employeeIds?.organizationEmployees?.length ? (
          <ListItem className={classes.listItem} button onClick={() => redirectToValidations({ entity_id: employeeIds?.organizationEmployees, entity_type: 'organizationEmployees' })}>
            <ListItemText className={classes.listItemText}>Organization employees validations</ListItemText>
          </ListItem>
        ) : null}
        {isWork && (
          <>
            <ListItem className={classes.listItem} button onClick={() => redirectToValidations({ entity_id: map(record?.roles, 'id'), entity_type: 'workRoles' })}>
              <ListItemText className={classes.listItemText}>Work roles validations</ListItemText>
            </ListItem>
            <ListItem className={classes.listItem} button onClick={() => redirectToValidations({ entity_id: record?.workType?.id, entity_type: 'workTypes' })}>
              <ListItemText className={classes.listItemText}>Work type validations</ListItemText>
            </ListItem>
          </>
        )}
        <ListItem className={classes.listItem} button onClick={() => redirectToValidations({ entity_id: record?.id, entity_type: resource })}>
          <ListItemText className={classes.listItemText}>Entities validations</ListItemText>
        </ListItem>
        {!disableEditValidation && (
          <>
            <hr />
            <ListItem className={classes.listItem} button onClick={() => redirect(`/validations/activities/?filter=${JSON.stringify({ entity_type: resource, entity_id: record?.id })}`)}>
              <ListItemText className={classes.listItemText}>View activities</ListItemText>
            </ListItem>
          </>
        )}
      </List>
    </div>
  );
};

export const ShowView = (props) => {
  const { actions, aside, children, classes: classesOverride, className, component: Content, title, additionalValidationCondition, hideValidity, disableEditValidation, ...rest } = props;

  const classes = useStyles(props);
  const dataProvider = useDataProvider();

  const { basePath, hasList, record, resource, version } = useShowContext(props);
  const { hasEdit } = useResourceDefinition(props);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [userPerformanceCount, setUserPerformanceCount] = useState(0);
  const [changeRequestCount, setChangeRequestCount] = useState(0);
  const [redMaskCount, setRedMaskCount] = useState(0);
  const [entityQueueValidationCount, setEntityQueueValidationCount] = useState(0);
  const [profile, setProfile] = useState([]);
  const isProfile = resource === 'profiles';

  const pendingEntityQueueValidationCount = get(profile, 'pendingValidations', [])
    .map((validation) => validation.ids)
    .flat().length;
  const pendingValidationCount = userPerformanceCount + changeRequestCount + redMaskCount + entityQueueValidationCount + pendingEntityQueueValidationCount;

  const finalActions = typeof actions === 'undefined' && hasEdit ? <ShowActions /> : actions;

  useEffect(() => {
    if (!isProfile) {
      dataProvider.getList('validations', { filter: { entity_id: props?.id, status_id: VALIDATION_STATUSES_IDS.PENDING, entity_type: resource }, pagination: {}, sort: {} }).then((res) => {
        setEntityQueueValidationCount(res?.total);
      });
    }

    if (isProfile) {
      dataProvider.getOne('profiles', { id: props?.id }).then((res) => {
        setProfile(res?.data);
      });
    }

    if (props?.showAllValidations) {
      dataProvider.getList('users/performances', { filter: { profile_id: props?.id, validation_status: 'pending' }, pagination: {}, sort: {} }).then((res) => {
        setUserPerformanceCount(res?.total);
      });

      dataProvider.getList('change_requests', { filter: { entity_type_id: 4, profile_id: props?.id, status: 'pending_validation' }, pagination: {}, sort: {} }).then((res) => {
        setChangeRequestCount(res?.total);
      });

      dataProvider
        .getList('productions', {
          filter: {
            added_by: 'artist',
            individual_id: props?.id,
            has_pending_redmask_requests: true,
            validation_reason: 'red_mask_queue',
          },
          pagination: {},
          sort: {},
        })
        .then((res) => {
          setRedMaskCount(res?.total);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!children) {
    return null;
  }

  return (
    <div className={classnames('show-page', classes.root, className)} {...sanitizeRestProps(rest)}>
      <TitleForRecord {...props} />
      {finalActions &&
        cloneElement(finalActions, {
          basePath,
          data: record,
          hasList,
          hasEdit,
          resource,
          ...finalActions.props,
        })}

      <div
        className={classnames(classes.main, {
          [classes.noActions]: !finalActions,
        })}
      >
        <Content className={classes.card}>
          <MuiThemeProvider>
            <div className={classnames(classes.validationStateBar, { [classes.spacing]: hideValidity })}>
              <EntityTitle {...props} />
              <div className={classes.entityActions}>
                <EntityActions {...props} />
                {!hideValidity && (
                  <div className={classes.validationContainer}>
                    <div
                      className={classes.validationHeader}
                      onClick={(e) => {
                        setOpen(true);
                        setAnchorEl(e.currentTarget);
                      }}
                    >
                      <div className={classes.validationLabel}>
                        {!rest?.record?.validationStatus?.id ? (
                          <HelpIcon className={classes.validationIcon} />
                        ) : [VALIDATION_STATUSES_IDS.PENDING, VALIDATION_STATUSES_IDS.PROCESSING].includes(rest?.record?.validationStatus?.id) ? (
                          <BlockIcon className={classes.validationIcon} />
                        ) : (
                          <VerifiedUserIcon className={classes.validationIcon} />
                        )}
                        <Typography>
                          {!rest?.record?.validationStatus?.id ? 'Unknown validation state' : rest?.record?.validationStatus?.name} {pendingValidationCount ? `(${pendingValidationCount})` : null}
                        </Typography>
                      </div>
                      <div className={classes.helperText}>
                        (<EditIcon className={classes.icon} />
                        validation status)
                      </div>
                    </div>
                    {additionalValidationCondition && (
                      <div
                        className={classes.validationHeader}
                        onClick={
                          additionalValidationCondition.redirectUrl &&
                          (() => {
                            const destinationUrl =
                              typeof additionalValidationCondition.redirectUrl === 'function' ? additionalValidationCondition.redirectUrl(rest?.record) : additionalValidationCondition.redirectUrl;
                            if (destinationUrl) {
                              window.open(destinationUrl, '_blank');
                            }
                          })
                        }
                      >
                        <div className={classes.validationLabel}>
                          {rest?.record && rest?.record[additionalValidationCondition.property] == null ? (
                            <HelpIcon />
                          ) : rest?.record && rest?.record[additionalValidationCondition.property] ? (
                            <VerifiedUserIcon />
                          ) : (
                            <BlockIcon />
                          )}
                          <Typography>
                            {rest?.record && rest?.record[additionalValidationCondition.property] == null
                              ? additionalValidationCondition?.unknownLabel || additionalValidationCondition?.invalidLabel
                              : rest?.record && rest?.record[additionalValidationCondition.property]
                                ? additionalValidationCondition?.validLabel
                                : additionalValidationCondition?.invalidLabel}
                          </Typography>
                        </div>
                      </div>
                    )}
                    <Popper open={open} anchorEl={anchorEl} placement="bottom" transition style={{ zIndex: 1 }}>
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={100}>
                          <ClickAwayListener onClickAway={() => setOpen(false)}>
                            <Paper>
                              <ValidationView
                                {...props}
                                onCLose={() => setOpen(false)}
                                userPerformanceCount={userPerformanceCount}
                                redMaskCount={redMaskCount}
                                changeRequestCount={changeRequestCount}
                                entityQueueValidationCount={entityQueueValidationCount}
                                profile={profile}
                                pendingValidationCount={pendingValidationCount}
                                disableEditValidation={disableEditValidation}
                              />
                            </Paper>
                          </ClickAwayListener>
                        </Fade>
                      )}
                    </Popper>
                  </div>
                )}
              </div>
            </div>
          </MuiThemeProvider>

          {record &&
            cloneElement(Children.only(children), {
              resource,
              basePath,
              record,
              version,
            })}
        </Content>
        {aside &&
          cloneElement(aside, {
            resource,
            basePath,
            record,
            version,
          })}
      </div>
    </div>
  );
};

ShowView.propTypes = {
  actions: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  aside: PropTypes.element,
  basePath: PropTypes.string,
  children: PropTypes.element,
  classes: PropTypes.object,
  className: PropTypes.string,
  defaultTitle: PropTypes.any,
  hasEdit: PropTypes.bool,
  hasList: PropTypes.bool,
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  record: PropTypes.object,
  resource: PropTypes.string,
  title: PropTypes.any,
  version: PropTypes.node,
  additionalValidationCondition: PropTypes.shape({
    property: PropTypes.string,
    unknownLabel: PropTypes.string,
    invalidLabel: PropTypes.string,
    validLabel: PropTypes.string,
    redirectUrl: PropTypes.oneOfType(PropTypes.string, PropTypes.func),
  }),
};

ShowView.defaultProps = {
  classes: {},
  component: Card,
};

const sanitizeRestProps = ({
  basePath = null,
  defaultTitle = null,
  hasCreate = null,
  hasEdit = null,
  hasList = null,
  hasShow = null,
  history = null,
  id = null,
  loaded = null,
  loading = null,
  location = null,
  match = null,
  options = null,
  refetch = null,
  permissions = null,
  ...rest
}) => rest;

export const ValidityShow = (props) => {
  useCheckMinimumRequiredProps('Show', ['children'], props);
  const controllerProps = useShowController(props);
  const body = (
    <ShowContextProvider value={controllerProps}>
      <ShowView {...props} {...controllerProps} />
    </ShowContextProvider>
  );

  return props.resource ? <ResourceContextProvider value={props.resource}>{body}</ResourceContextProvider> : body;
};

ValidityShow.propTypes = {
  actions: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  aside: PropTypes.element,
  children: PropTypes.element,
  classes: PropTypes.object,
  className: PropTypes.string,
  hasCreate: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasList: PropTypes.bool,
  hasShow: PropTypes.bool,
  id: PropTypes.any.isRequired,
  resource: PropTypes.string,
  title: PropTypes.node,
};

export default ValidityShow;
