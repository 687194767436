import React, { useState, useEffect } from 'react';
import { Loading } from 'react-admin';
import { head } from 'lodash';
import * as dayjs from 'dayjs';
import { makeStyles, FormControl, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { getGiftSubscriptionMetrics } from '../utils/metrics';
import apiClient from '../utils/api';

const styles = () => ({
  filters: {
    display: 'flex',
  },
  selectSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    marginRight: 10,
    fontWeight: 'bold',
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 10,
  },
  section: {
    width: '28%',
    padding: '20px 16px 50px 16px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    background: 'white',
    margin: 10,
    borderRadius: 4,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  spacingTop: {
    marginTop: 10,
  },
  select: {
    width: 300,
  },
  date: { marginLeft: 10 },
});

export const GiftSubscriptionMetricsModelShow = () => {
  const classes = makeStyles(styles)();
  const history = useHistory();

  const searchParams = new URLSearchParams(history.location.search);
  const defaultDateRange = { from_date: searchParams?.get('from_date'), to_date: searchParams?.get('to_date') };
  const [isLoading, setIsLoading] = useState(false);
  const [metrics, setMetrics] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState({
    from: defaultDateRange?.from_date || dayjs().add(-6, 'day').format('YYYY-MM-DD'),
    to: defaultDateRange?.to_date || dayjs().format('YYYY-MM-DD'),
  });

  const getGiftSubscriptionMetricsDetails = () => {
    setIsLoading(true);
    apiClient
      .get('users/referralStats', {
        params: {
          date_from: selectedDateRange.from,
          date_to: selectedDateRange.to,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setMetrics(getGiftSubscriptionMetrics(head(res?.data?.data)));
      });
  };

  useEffect(() => {
    document.title = 'Gift subscription metrics';
  }, []);

  useEffect(() => {
    searchParams.set('from_date', selectedDateRange?.from);
    searchParams.set('to_date', selectedDateRange?.to);
    const newSearch = searchParams.toString();
    history.push({ search: newSearch });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange]);

  useEffect(() => {
    getGiftSubscriptionMetricsDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange]);

  return (
    <div>
      <div className={classes.filters}>
        <FormControl className={classes.selectSection} size="small">
          <TextField
            className={classes.date}
            value={selectedDateRange.from}
            onChange={(e) => setSelectedDateRange({ ...selectedDateRange, from: e?.target?.value })}
            type="date"
            label="From"
            variant="filled"
            size="small"
          />
          <TextField
            className={classes.date}
            value={selectedDateRange.to}
            onChange={(e) => setSelectedDateRange({ ...selectedDateRange, to: e?.target?.value })}
            type="date"
            label="To"
            variant="filled"
            size="small"
          />
        </FormControl>
      </div>
      {!isLoading ? (
        <div className={classes.content}>
          {metrics.map((m) => (
            <div className={classes.section}>
              <div className={classes.sectionTitle}>{m.label}</div>
              <div className={classes.spacingTop}>{m.value}</div>
            </div>
          ))}
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default GiftSubscriptionMetricsModelShow;
