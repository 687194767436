import React from 'react';
import { Show, List, EmailField, Filter, TextInput, Datagrid, TextField, SimpleShowLayout } from 'react-admin';
import { SubscriptionsListColors } from '../constants/stylingConstants';

const SubscriptionsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="query" alwaysOn />
  </Filter>
);

export const SubscriptionsShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="profileType.name" />
      <TextField source="description" />
    </SimpleShowLayout>
  </Show>
);

export const SubscriptionsList = (props) => (
  <List exporter={false} filters={<SubscriptionsFilter />} {...props}>
    <Datagrid
      rowClick="show"
      rowStyle={(record) => ({
        backgroundColor: SubscriptionsListColors[record?.status] || SubscriptionsListColors['fbadmin-default'],
      })}
    >
      <TextField source="name" />
      <TextField source="profileType.name" />
      <EmailField source="description" />
    </Datagrid>
  </List>
);

export default SubscriptionsList;
