import React from 'react';
import { TableCell } from '@material-ui/core';
import DisplayTable from './DisplayTable';
import DisplayText from './DisplayText';
import apiClient from '../../utils/api';
import { cloneDiff } from './utils';

const getData = async (productionId) => {
  const descriptions = (await  apiClient.get(`productions/${productionId}/documents`, {
    params: {
      document_type: 'description',
      page: 1,
      limit: 10,
    },
  }))?.data?.data;

  const synopsis = (await apiClient.get(`productions/${productionId}/synopsis`, {
      params: {
        page: 1,
        limit: 10,
    },
  }))?.data?.data;

  return {
    descriptions,
    synopsis,
  };
};

export const parseProductionSynopsis = props => {
  const { production, changeRequest } = props;

  return new Promise(async (resolve, reject) => {
    const a = changeRequest ? await getData(production.id) : { descriptions: [], synopsis: []};
    const b = changeRequest ? await getData(changeRequest.revisionId) : await getData(production.id);

    const data = {
      descriptions: cloneDiff(a.descriptions, b.descriptions),
      synopsis: cloneDiff(a.synopsis, b.synopsis),
    };

    resolve(data);
  });
}

const DescriptionRow = (props) => {
  return (<>
    <TableCell>
        <DisplayText path="" changes={props.changes} select="content" />
    </TableCell>
    <TableCell>
      <DisplayText path="" changes={props.changes} select="language.name" />
    </TableCell>
    <TableCell>
      <DisplayText path="" changes={props.changes} select="updatedAt" />
    </TableCell>
  </>)
}

const SynopsisRow = props => {
  return (<>
    <TableCell>
        <DisplayText path="" changes={props.changes} select="description" />
    </TableCell>
    <TableCell>
        <DisplayText path="" changes={props.changes} select="language.name" />
    </TableCell>
    <TableCell>
        <DisplayText path="" changes={props.changes} select="updatedAt" />
    </TableCell>
  </>)
}

export const ProductionSynopsis = (props) => {
  return (
    <div>
      <DisplayTable {...props} changes={props.changes.descriptions} title="Production description" header={['Description	', 'Language', 'Last modified']}>
        <DescriptionRow />
      </DisplayTable>

      <DisplayTable {...props} changes={props.changes.synopsis} title="Production synopsis" header={['Synopsis', 'Language', 'Last modified']}>
        <SynopsisRow />
      </DisplayTable>
    </div>
  );
}
