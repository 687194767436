import React, { useState, useCallback } from 'react';
import {
  Edit,
  AutocompleteInput,
  List,
  Filter,
  TextInput,
  Datagrid,
  TextField,
  SimpleShowLayout,
  SimpleForm,
  Toolbar,
  SaveButton,
  Create,
  ReferenceInput,
  SelectInput,
  FunctionField,
  ReferenceField,
  TabbedShowLayout,
  Tab,
  TabbedShowLayoutTabs,
  ReferenceManyField,
  Pagination,
  Link,
  TopToolbar,
  EditButton,
  ArrayInput,
  SimpleFormIterator,
  ArrayField,
  SingleFieldList,
  TabbedForm,
  FormTab,
  useUpdate,
  useNotify,
  useRefresh,
  CREATE,
  DateField,
} from 'react-admin';
import { Alert } from '@material-ui/lab';
import { useRouteMatch } from 'react-router-dom';
import { Chip } from '@material-ui/core';
import { PublicLink } from '../components/PublicLink';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { VALIDATION_STATUSES, ENTITY_VALIDATION_CATEGORY } from '../constants/validations';
import ValidityShow from '../components/ValidityShow';
import CustomShowLayout from '../components/CustomShowLayout';
import EntityLink from '../components/EntityLink';
import { ProductionsGrid } from './ProductionsModel';
import { PARENT_PROFESSIONS } from '../constants/profession';
import dataProvider from '../components/dataProviderComponent';
import { getComposerNamesFromCreators } from '../utils/composer';
import EntityTranslation from '../components/translation/EntityTranslation';

const TABS = {
  INFO: 'info',
  TRANSLATIONS: 'translation',
};

const ProfessionsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="query" alwaysOn />
    <TextInput label="Profession ID" source="id" alwaysOn />
    <ReferenceInput
      label="Parent Profession"
      source="parent_profession_id"
      reference="professions"
      filterToQuery={(searchText) => ({ query: searchText })}
      filter={{ slug: PARENT_PROFESSIONS }}
      sort={{ field: 'profile_count', order: 'DESC' }}
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="Tag" source="tag_id" reference="tags" filter={{ category: `${ENTITY_VALIDATION_CATEGORY.professions}, ${ENTITY_VALIDATION_CATEGORY.AVAILABILITY}` }} alwaysOn allowEmpty>
      <SelectInput optionText={({ name, category }) => `${name} (${category})`} />
    </ReferenceInput>
    <SelectInput label="Validation status" source="validation_status" choices={VALIDATION_STATUSES} alwaysOn />
  </Filter>
);

export const ProfessionsShow = (props) => (
  <ValidityShow {...props} title={<Breadcrumbs {...props} />} actions={<ProfessionsShowActions />}>
    <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" {...props} />}>
      <Tab label="Profession Info">
        <CustomShowLayout {...props} isStats>
          {{
            column1: (
              <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="name" />
                <FunctionField label="Tags" render={(record) => record?.tags?.map((tag) => tag?.name)?.join(', ')} />

                <ReferenceField link="show" label="User" reference="users" source="user_id" sortable={false}>
                  <FunctionField emptyText="Unknown" render={(record) => <EntityLink entity={record} entityType="users" />} />
                </ReferenceField>

                <TextField label="Available For" source="applicableFor" />
              </SimpleShowLayout>
            ),
            column2: (
              <SimpleShowLayout>
                <ReferenceField link="show" label="Parent Profession" reference="professions" source="parentProfession.id" sortable={false}>
                  <FunctionField emptyText="Unknown" render={(record) => <EntityLink entity={record} entityType="professions" />} />
                </ReferenceField>

                <ReferenceManyField label="Children" reference="professions" target="parent_profession_id" perPage={5} sortable={false} sort={{ field: 'profile_count', order: 'DESC' }}>
                  <Datagrid rowClick={(id, path, record) => `/professions/${record?.id}/show`}>
                    <FunctionField emptyText="Unknown" render={(record) => <EntityLink entity={record} entityType="professions" />} />
                    <TextField source="productionCount" sortable={false} />
                    <TextField source="profileCount" sortable={false} />
                  </Datagrid>
                </ReferenceManyField>

                <ReferenceManyField label="Instruments" reference="instruments" target="profession_id" perPage={5} sortable={false}>
                  <Datagrid rowClick={(id, path, record) => `/professions/${record?.id}/show`}>
                    <FunctionField emptyText="Unknown" render={(record) => <EntityLink entity={record} entityType="instruments" />} />
                  </Datagrid>
                </ReferenceManyField>

                <ReferenceManyField label="Organization Employees" reference="organizations/employees" target="profession_id" perPage={5} sortable={false}>
                  <Datagrid rowClick={(id, path, record) => `/organizations/${record?.organization?.id}/show`}>
                    <FunctionField emptyText="Unknown" render={(record) => <EntityLink entity={record?.organization} entityType="organizations" />} />
                  </Datagrid>
                </ReferenceManyField>
              </SimpleShowLayout>
            ),
          }}
        </CustomShowLayout>
      </Tab>
      <Tab label="Profession Translations">
        <EntityTranslation list={{ url: `professions/${props?.id}/translations` }} update={{ url: `professions/${props?.id}/translations` }} />
      </Tab>
      <Tab label="Children">
        <ReferenceManyField
          label="List of professions that are child of profession"
          source="id"
          reference="professions"
          link="show"
          target="parent_profession_id"
          perPage={25}
          pagination={<Pagination />}
          sort={{ field: 'profile_count', order: 'DESC' }}
        >
          <Datagrid rowClick={(id, path, record) => `/professions/${record?.id}/show`}>
            <FunctionField emptyText="Unknown" render={(record) => <EntityLink entity={record} entityType="professions" />} />
            <TextField source="productionCount" sortable={false} />
            <TextField source="profileCount" sortable={false} />
            <FunctionField label="Tags" render={(record) => record?.tags?.map((tag) => tag?.name)?.join(', ')} />
            <TextField source="validation_status" sortable={false} />
          </Datagrid>
        </ReferenceManyField>
      </Tab>

      <Tab label="Siblings">
        <ReferenceManyField
          label="List of professions that share profession"
          source="parentProfession.id"
          reference="professions"
          link="show"
          target="parent_profession_id"
          perPage={25}
          pagination={<Pagination />}
          sort={{ field: 'profile_count', order: 'DESC' }}
        >
          <Datagrid rowClick={(id, path, record) => `/professions/${record?.id}/show`}>
            <FunctionField emptyText="Unknown" render={(record) => <EntityLink entity={record} entityType="professions" />} />
            <TextField source="productionCount" sortable={false} />
            <TextField source="profileCount" sortable={false} />
            <FunctionField label="Tags" render={(record) => record?.tags?.map((tag) => tag?.name)?.join(', ')} />
            <TextField source="validation_status" sortable={false} />
          </Datagrid>
        </ReferenceManyField>
      </Tab>

      <Tab label="Instruments">
        <ReferenceManyField label="List of instruments associated with profession" source="id" reference="instruments" link="show" target="profession_id" perPage={25} pagination={<Pagination />}>
          <Datagrid rowClick={(id, path, record) => `/instruments/${record?.id}/show`}>
            <FunctionField emptyText="Unknown" render={(record) => <EntityLink entity={record} entityType="instruments" />} />
            <ArrayField source="name_variants">
              <SingleFieldList linkType={false}>
                <FunctionField render={(record) => <Chip label={`${record}`} />} />
              </SingleFieldList>
            </ArrayField>
            <TextField source="validationStatus.name" sortable={false} />
          </Datagrid>
        </ReferenceManyField>
      </Tab>

      <Tab label="Musical Works">
        <ReferenceManyField
          label="List of musical works where profession is used"
          reference="works"
          filter={{ as_edit: true, profession_id: props?.id }}
          {...props}
          perPage={25}
          pagination={<Pagination />}
        >
          <Datagrid rowClick="show" {...props}>
            <TextField source="id" />
            <FunctionField
              render={(record) => {
                const composerNames = getComposerNamesFromCreators(record?.creators);
                return composerNames;
              }}
              label="Composers"
            />
            <TextField source="name" />
            <TextField source="original_name" />
            <TextField source="workType.name" label="Work type" />
            <TextField source="stats.productionCount" sortBy="production_count" label="Production count" />
            <TextField label="Validation status" source="validationStatus.name" />
            <DateField source="createdAt" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Productions">
        <ReferenceManyField
          label="List of productions where profession is used"
          source="id"
          reference="productions"
          link="show"
          target="profession_id"
          perPage={10}
          pagination={<Pagination />}
          filter={{ as_edit: true, published: 'both' }}
          sort={{ field: 'year', order: 'DESC' }}
        >
          <ProductionsGrid {...props} />
        </ReferenceManyField>

        <Link to={`/productions/?filter=${JSON.stringify({ profession_id: props?.id })}`} style={{ display: 'block', margin: 20 }}>
          View All Productions
        </Link>
      </Tab>

      <Tab label="Profiles">
        <ReferenceManyField label="List of profiles with profession" source="id" reference="profiles" target="profession_id" perPage={25} pagination={<Pagination />}>
          <Datagrid rowClick={(id, path, record) => `/profiles/${record?.id}/show`}>
            <TextField source="id" />
            <TextField source="name" sortable={false} />
            <FunctionField label="Professions" render={(record) => renderProfessionList(record?.professions)} />
          </Datagrid>
        </ReferenceManyField>

        <Link to={`/profiles/?filter=${JSON.stringify({ profession_id: props?.id })}`} style={{ display: 'block', margin: 20 }}>
          View All Profiles
        </Link>
      </Tab>

      <Tab label="Organization Employees">
        <ReferenceManyField label="List of profiles with profession" source="id" reference="organizations/employees" target="profession_id" perPage={25} pagination={<Pagination />}>
          <Datagrid rowClick={(id, path, record) => `/organization/${record?.organization?.id}/show`}>
            <TextField source="id" />

            <TextField label="Created" source="createdAt" />

            <FunctionField
              label="Organization"
              render={(employee) => {
                return <EntityLink entity={employee?.organization} entityType="organizations" />;
              }}
            />

            <FunctionField
              label="Contact name"
              render={(contact) => (
                <div>
                  {contact?.firstName} {contact?.lastName}
                </div>
              )}
            />

            <FunctionField
              label="User / Profile"
              render={({ profile }) => (
                <>
                  <EntityLink entity={profile?.user} entityType="users" />
                  <EntityLink entity={profile} entityType="profiles" />
                </>
              )}
            />

            <TextField source="validationStatus.name" sortable={false} />
          </Datagrid>
        </ReferenceManyField>

        <Link to={`/profiles/?filter=${JSON.stringify({ profession_id: props?.id })}`} style={{ display: 'block', margin: 20 }}>
          View All Profiles
        </Link>
      </Tab>
    </TabbedShowLayout>
  </ValidityShow>
);

export const renderProfessionList = (professions) => {
  if (professions?.length === 0) {
    return 'None';
  }

  return professions
    ?.map((pp) =>
      [
        pp?.profession?.name,
        pp?.isHidden || pp?.isPrimary ? ' (' : '',
        pp?.isHidden ? 'hidden' : '',
        pp?.isHidden && pp?.isPrimary ? ',' : '',
        pp?.isPrimary ? 'primary' : '',
        pp?.isHidden || pp?.isPrimary ? ')' : '',
      ].join(''),
    )
    .join(', ');
};

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const ProfessionsCreate = (props) => {
  const transform = (values) => ({
    ...values,
    tags: values?.tags?.map((entry) => ({ id: entry?.tags?.id })),
  });

  return (
    <Create title={<Breadcrumbs {...props} />} transform={transform} {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <TextInput source="name" />

        <ReferenceInput
          label="Parent Profession"
          source="parentProfession.id"
          reference="professions"
          filterToQuery={(searchText) => ({ query: searchText })}
          sort={{ field: 'name', order: 'ASC' }}
          filter={{ slug: PARENT_PROFESSIONS }}
          alwaysOn
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>

        <ArrayInput label="Tags" source="tags">
          <SimpleFormIterator inline>
            <ReferenceInput label="Tags" source="tags.id" reference="tags" filter={{ category: ENTITY_VALIDATION_CATEGORY.professions }}>
              <AutocompleteInput optionText="name" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

const useSaveHandlers = () => {
  const notify = useNotify();
  const refresh = useRefresh();

  const onFailure = (error) => {
    notify(`${error.message}`, 'warning');
    refresh();
  };

  const onSuccess = () => {
    notify('ra.notification.updated', 'info', {
      smart_count: 1,
    });
    refresh();
  };

  return { onFailure, onSuccess };
};

const createProfessionTranslation = (data) =>
  dataProvider(CREATE, `professions/${data?.data?.[0]?.profession?.id}/translations`, {
    data,
  });

const SaveTranslations = ({ tab, ...props }) => {
  const { initial } = props;
  const professionId = initial.id;
  const [update] = useUpdate('professions', professionId);
  const { onSuccess, onFailure } = useSaveHandlers();

  const handleSave = useCallback(
    (values) => {
      const { __versions: newVersions, ...valuesData } = values;
      const filteredVersions = Object.keys(newVersions || {})?.filter((lang) => newVersions[lang]?.name);

      if (tab === TABS.TRANSLATIONS) {
        Promise.all(
          filteredVersions?.map((lang) => {
            const record = newVersions[lang];
            return createProfessionTranslation({
              data: [
                {
                  name: record?.name,
                  profession: {
                    id: professionId,
                  },
                  language: { id: parseInt(lang, 10) },
                  name_variants: record?.name_variants,
                },
              ],
            });
          }),
        ).then(onSuccess, onFailure);
      } else {
        delete valuesData?.availability;

        update(
          {
            payload: {
              data: {
                ...valuesData,
                tags: valuesData?.tags?.map((tag) => ({ id: tag?.id })),
              },
            },
          },
          {
            onSuccess,
            onFailure,
          },
        );
      }
    },
    [tab, onSuccess, onFailure, professionId, update],
  );

  // set onSave props instead of handleSubmitWithRedirect
  return <SaveButton {...props} onSave={handleSave} />;
};

const CustomEditToolbar = ({ tab, ...props }) => {
  const [record] = useState(props.record);

  return (
    <Toolbar {...props}>
      <SaveTranslations initial={record} tab={tab} />
    </Toolbar>
  );
};

export const ProfessionsEdit = (props) => {
  let initialTab = TABS.INFO;
  const match = useRouteMatch('/professions/:id/:tab');
  if (match && match.params) {
    initialTab = Object.values(TABS)[match.params.tab];
  }
  const [tab, setTab] = useState(initialTab);

  return (
    <Edit {...props} title={<Breadcrumbs {...props} />}>
      <TabbedForm toolbar={<CustomEditToolbar {...props} tab={tab} />}>
        <FormTab label="Profession Info" onClick={() => setTab(TABS.INFO)}>
          <Alert variant="filled" severity="info" style={{ backgroundColor: 'grey', width: '90%' }}>
            To see updates made to this entity on Operabase associated productions/profiles will require overnight indexing.
          </Alert>
          <TextInput source="name" />
          <ReferenceInput
            label="Parent Profession"
            source="parentProfession.id"
            reference="professions"
            filterToQuery={(searchText) => ({ query: searchText })}
            filter={{ slug: PARENT_PROFESSIONS }}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <AutocompleteInput optionText="name" resettable />
          </ReferenceInput>

          <ArrayInput label="Tags" source="tags">
            <SimpleFormIterator inline>
              <ReferenceInput label="Tags" source="id" reference="tags" filter={{ category: `${ENTITY_VALIDATION_CATEGORY.professions}, ${ENTITY_VALIDATION_CATEGORY.AVAILABILITY}` }}>
                <AutocompleteInput optionText={({ name, category }) => `${name} (${category})`} />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="Profession Translations" onClick={() => setTab(TABS.TRANSLATIONS)}>
          <Alert variant="filled" severity="info" style={{ backgroundColor: 'grey', width: '90%' }}>
            To see updates made to this entity on Operabase associated productions/profiles will require overnight indexing.
          </Alert>
          <EntityTranslation list={{ url: `professions/${props?.id}/translations` }} update={{ url: `professions/${props?.id}/translations` }} />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const ProfessionsShowActions = (props) => {
  const { basePath, data } = props;

  return (
    <TopToolbar style={{ alignItems: 'center' }}>
      <PublicLink {...props} />
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );
};

export const ProfessionsList = (props) => (
  <List exporter={false} {...props} title={<Breadcrumbs {...props} />} filters={<ProfessionsFilter />} bulkActionButtons={false} perPage={25} sort={{ field: 'profile_count', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" sortable={false} />
      <ReferenceField link="show" label="Parent" reference="professions" source="parentProfession.id" sortable={false}>
        <FunctionField emptyText="Unknown" render={(record) => <EntityLink entity={record} entityType="professions" />} />
      </ReferenceField>
      <TextField source="productionCount" sortBy="production_count" />
      <TextField source="profileCount" sortBy="profile_count" />
      <ArrayField label="Tags" source="tags">
        <SingleFieldList linkType={false}>
          <FunctionField render={(record) => <Chip label={`${record.name}`} />} />
        </SingleFieldList>
      </ArrayField>
      <TextField source="validationStatus.name" sortable={false} />
    </Datagrid>
  </List>
);

export default ProfessionsList;
