import React from 'react';
import DisplayText from './DisplayText';
import apiClient from '../../utils/api';
import { cloneDiff } from './utils';

const getData = async (productionId) => {
  const tickets = (await apiClient.get(`productions/${productionId}/tickets`, {
    params: {
      page: 1,
      limit: 10,
    },
  }))?.data?.data;

  return tickets;
};

export const parseProductionTickets = (props) => {
  const { production, changeRequest } = props;

  return new Promise(async (resolve, reject) => {
    const a = changeRequest ? await getData(production.id) : [];
    const b = changeRequest ? await getData(changeRequest.revisionId) : await getData(production.id);

    const data = cloneDiff(a, b);

    resolve(data);
  });
};

export const ProductionTickets = (props) => {
  return (<div>
    <DisplayText title="Production webpage" changes={props.changes} path="" select="website" />
    <DisplayText title="Box-Office webpage" changes={props.changes} path="" select="boxOfficeWebsite" />
    <DisplayText title="Ticket sale date" changes={props.changes} path="" select="bookingStartDate" />
    <DisplayText title="Ticket sale time" changes={props.changes} path="" select="bookingStartTime" />
    <DisplayText title="Minimum ticket price" changes={props.changes} path="" select="minTicketPrice" />
    <DisplayText title="Maximum ticket price" changes={props.changes} path="" select="maxTicketPrice" />
    <DisplayText title="Currency" changes={props.changes} path="" select="currency.currency" />
  </div>)
};
