export const AUTH_TYPES = [
  {
    id: 'accessKey',
    name: 'Access Key',
  },
  {
    id: 'user',
    name: 'User',
  },
];
