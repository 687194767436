import React from 'react';
import { TableCell } from '@material-ui/core';
import DisplayTable from './DisplayTable';
import apiClient from '../../utils/api';
import DisplayText from './DisplayText';
import { cloneDiff } from './utils';

const getData = async (productionId) => {
  return (
    await apiClient.get(`productions/${productionId}/documents`, {
      params: {
        document_type: 'booklet',
        page: 1,
        limit: 10,
      },
    })
  )?.data?.data;
};

export const parseProductionBooklet = (props) => {
  const { production, changeRequest } = props;

  return new Promise(async (resolve, reject) => {
    const a = changeRequest ? await getData(production.id) : [];
    const b = changeRequest ? await getData(changeRequest.revisionId) : await getData(production.id);

    const data = cloneDiff(a, b);

    resolve(data);
  });
};

const BookletRow = (props) => {
  return (
    <>
      <TableCell>
        <DisplayText path="" changes={props.changes} select="title" />
      </TableCell>
      <TableCell>
        <DisplayText path="" changes={props.changes} select="language.name" />
      </TableCell>
      <TableCell>
        <DisplayText path="" changes={props.changes} select="updatedAt" />
      </TableCell>
      <TableCell>
        <DisplayText path="" changes={props.changes} select="content" isURL filePath="file.original" />
      </TableCell>
      <TableCell>
        <DisplayText path="" changes={props.changes} select="screenShot.original" isURL filePath="screenShot.original" />
      </TableCell>
    </>
  );
};

export const ProductionBooklet = (props) => {
  return (
    <div>
      <DisplayTable {...props} changes={props.changes} title="Production booklet" header={['Booklet title', 'Language', 'Last modified', 'Booklet URL', 'Screenshot']}>
        <BookletRow />
      </DisplayTable>
    </div>
  );
};
