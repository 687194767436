import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  useDataProvider,
  List,
  Filter,
  SelectInput,
  Datagrid,
  TextField,
  FunctionField,
  TextInput,
  Title,
  useUpdate,
  useNotify,
  AutocompleteInput,
  ReferenceInput,
  DateInput,
  DateField,
  ReferenceField,
} from 'react-admin';
import { Button, makeStyles, Typography, Dialog, DialogActions, DialogContent, DialogTitle, TextField as MUITextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { isNumber, map, head, find, get } from 'lodash';
import { VALIDATION_STATUSES } from '../constants/validations';
import EntityLink from '../components/EntityLink';
import { UserName } from './ProfilesModel';
import ValidationLockByUser from '../components/ValidationLockByUser';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { getHoursFromCurrentTime } from '../utils/dateParser';

const useStyles = makeStyles(() => ({
  infoField: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  title: {
    fontWeight: 'bold',
    marginRight: 5,
  },
  validationSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    float: 'right',
    fontSize: 16,
  },
  validationStatus: {
    fontWeight: 'bold',
    border: '1px solid #ff5722',
    padding: 6,
    backgroundColor: '#ff5722',
    color: 'white',
    textAlign: 'center',
  },
  validationLockSection: {
    marginBottom: '20px',
  },
  spacingLeft: { marginLeft: 10 },
  footerActions: {
    padding: '15px 0',
  },
}));

const PerformancesValidationsFilters = (props) => (
  <Filter {...props}>
    <SelectInput label="Validation status" source="validation_status" choices={VALIDATION_STATUSES} alwaysOn />
    <TextInput source="user_id" label="User ID" alwaysOn />
    <TextInput source="profile_id" label="Profile ID" alwaysOn />
    <ReferenceInput
      label="Who validated last"
      source="last_validated_by_user_id"
      reference="users"
      perPage={25}
      filter={{ group_id: 3 }}
      filterToQuery={(searchText) => ({ query: searchText })}
      alwaysOn
    >
      <AutocompleteInput optionText="email" />
    </ReferenceInput>
    <DateInput source="last_validated_at_from" label="When validated last from" alwaysOn />
    <DateInput source="last_validated_at_to" label="When validated last to" alwaysOn />
  </Filter>
);

const renderPendingTimeHours = (row) => {
  const pendingHours = getHoursFromCurrentTime(row.createdAt);
  let days = Math.floor(pendingHours / 24);
  days = days > 0 ? `${days}d` : '';
  let hours = pendingHours % 24;
  hours = hours > 0 ? (hours = `${hours}h`) : '';

  if (days === 0 && hours === 0) {
    return '';
  }

  return `${days} ${hours}`;
};

export const PerformancesValidationList = (props) => {
  const dataProvider = useDataProvider();
  const [allValidationLocks, setAllValidationLocks] = useState([]);
  const allData = useSelector((store) => store.admin.resources['validations/performances'])?.data;
  const allIds = useMemo(() => Object.keys(allData), [allData]);

  useEffect(() => {
    if (allIds.length) {
      dataProvider
        .getList('validations/locks', { filter: { lockIdentifier: allIds.join(','), lockGroup: 'performances', validation_status: '' }, pagination: {}, sort: {} })
        .then(({ data }) => {
          setAllValidationLocks(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allIds]);

  const pendingValidationStatus = find(VALIDATION_STATUSES, (status) => status?.id === 'pending')?.id;

  const showLockedUser = useCallback(
    (data) => {
      if (allValidationLocks.length) {
        const hasValidationLock = allValidationLocks.find((validationLock) => Number(validationLock.lockIdentifier) === Number(data.id));
        return `${hasValidationLock?.user?.email || 'Not Locked'}`;
      }
      return 'Not Locked';
    },
    [allValidationLocks],
  );
  return (
    <List
      title={<Breadcrumbs {...props} />}
      filters={<PerformancesValidationsFilters />}
      filterDefaultValues={{ validation_status: pendingValidationStatus }}
      bulkActionButtons={false}
      exporter={false}
      {...props}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid rowClick="show" key={props.id}>
        <TextField source="id" sortable={false} />
        <TextField source="performanceName" sortable={false} />
        <FunctionField label="User" render={(record) => <EntityLink entity={record?.user} entityType="users" />} sortable={false} />
        <FunctionField label="Profile" render={(record) => <EntityLink entity={record?.profile} entityType="profiles" />} sortable={false} />
        <TextField source="source" sortable={false} />
        <TextField source="validationStatus.name" label="Validation status" sortable={false} />
        <FunctionField label="Locked By" render={(record) => showLockedUser(record)} sortable={false} />
        <FunctionField label="Pending Time" render={renderPendingTimeHours} source="createdAt" />
        <DateField source="lastValidatedAt" label="When validated last" />
        <ReferenceField link="show" source="lastValidatedByUser.id" reference="users" label="Who validated last" sortable={false}>
          <UserName />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

export const PerformancesValidationShow = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const [performance, setPerformance] = useState({});
  const dataProvider = useDataProvider();
  const [isOpenAcceptPopup, setOpenAcceptPopup] = useState(false);
  const [selectedProduction, setSelectedProduction] = useState(null);
  const [productionURL, setProductionURL] = useState('');
  const [productions, setProductions] = useState([]);
  const userId = get(performance, 'user.id');
  const [updatePerformance] = useUpdate(`users/${userId}/performances`);

  const handlePerformanceAction = (isAccept) => {
    updatePerformance(
      {
        payload: {
          id: props?.id,
          data: {
            validationStatus: { id: isAccept ? 3 : 4 },
            ...(selectedProduction?.id || productionURL ? { production: { id: selectedProduction?.id, url: productionURL } } : {}),
          },
        },
      },
      {
        onSuccess: () => window.location.reload(),
        onFailure: (err) => notify(err.message, 'error'),
      },
    );
  };

  const getProductions = (query) => {
    const isNumberQuery = isNumber(parseInt(query, 0));
    dataProvider
      .getList('productions', {
        filter: { query: !isNumberQuery ? query : '', ...(isNumberQuery && query ? { id: parseInt(query, 0) } : {}), as_edit: true, show_hidden: true },
        pagination: { limit: 10 },
        sort: {},
      })
      .then((res) => {
        setProductions(res?.data);
      });
  };

  useEffect(() => {
    dataProvider.getList('users/performances', { filter: { id: props?.id }, pagination: {}, sort: {} }).then((res) => {
      setPerformance(head(res?.data));
    });

    getProductions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ marginTop: 20 }}>
      <Title title={<Breadcrumbs {...props} />} />
      <div>
        <div className={classes.validationSection}>
          <div className={classes.validationStatus}>Status: {performance?.validationStatus?.name}</div>
          <div className={classes.infoField} style={{ marginTop: 10 }}>
            <Typography variant="body2" className={classes.title}>
              Pending time:
            </Typography>
            {renderPendingTimeHours(performance)}
          </div>
          <div className={classes.validationLockSection}>
            <ValidationLockByUser {...props} data={{ id: props.id }} lockGroupType="performances" />
          </div>
        </div>
      </div>
      <div className={classes.infoField}>
        <Typography variant="body2" className={classes.title}>
          Profile:
        </Typography>
        <div>
          <EntityLink entity={performance?.profile} entityType="profiles" />
        </div>
      </div>
      <div className={classes.infoField}>
        <Typography variant="body2" className={classes.title}>
          User:
        </Typography>
        <div>
          <EntityLink entity={performance?.user} entityType="users" />
        </div>
      </div>
      <div className={classes.infoField}>
        <Typography variant="body2" className={classes.title}>
          Evidence:
        </Typography>
        <div>
          {map(get(performance, 'links', []), (link) => (
            <>
              <a href={link.url} target="_blank" rel="noopener noreferrer">
                {link?.url}
              </a>
              &nbsp;&nbsp;
            </>
          ))}
        </div>
      </div>
      <div>
        <Typography variant="h6" gutterBottom>
          Production information
        </Typography>
        <div className={classes.infoField}>
          <Typography variant="body2" className={classes.title}>
            Performance name:
          </Typography>
          <Typography variant="body2" component="div">
            {performance?.performanceName}
          </Typography>
        </div>
        <div className={classes.infoField}>
          <Typography variant="body2" className={classes.title}>
            Source:
          </Typography>
          <Typography variant="body2" component="div">
            {performance?.source}
          </Typography>
        </div>
        <div className={classes.infoField}>
          <Typography variant="body2" className={classes.title}>
            Role:
          </Typography>
          <Typography>{performance?.role}</Typography>
        </div>
        <div className={classes.infoField}>
          <Typography variant="body2" className={classes.title}>
            Producer name:
          </Typography>
          <Typography variant="body2" component="div">
            {performance?.producerName}
          </Typography>
        </div>
        <div className={classes.infoField}>
          <Typography variant="body2" className={classes.title}>
            Performance dates:
          </Typography>
          <Typography variant="body2" component="div">
            {performance?.fromDate} {performance?.toDate ? ` - ${performance?.toDate}` : ''}
          </Typography>
        </div>
        <div className={classes.infoField}>
          <Typography variant="body2" className={classes.title}>
            Venue name:
          </Typography>
          <Typography variant="body2" component="div">
            {performance?.venueName}
          </Typography>
        </div>
        <div className={classes.infoField}>
          <Typography variant="body2" className={classes.title}>
            Comments:
          </Typography>
          <Typography>{performance?.comments}</Typography>
        </div>
        <div className={classes.infoField}>
          <Typography variant="body2" className={classes.title}>
            Created on:
          </Typography>
          <Typography>{performance?.createdAt}</Typography>
        </div>
        <div className={classes.infoField}>
          <Typography variant="body2" className={classes.title}>
            Last updated on :
          </Typography>
          <Typography>{performance?.updatedAt}</Typography>
        </div>
        <div className={classes.infoField}>
          <Typography variant="body2" className={classes.title}>
            Last updated by :
          </Typography>
          <Typography>
            <EntityLink entity={performance?.lastUpdatedBy} entityType="users" />
          </Typography>
        </div>
        <div className={classes.infoField}>
          <Typography variant="body2" className={classes.title}>
            Production ID :
          </Typography>
          <Typography>{performance?.production?.id}</Typography>
        </div>
        <div className={classes.infoField}>
          <Typography variant="body2" className={classes.title}>
            Production URL :
          </Typography>
          <Typography>
            {performance?.production?.id && (
              <a href={`${process.env.REACT_APP_OPERABASE_URL}/productions/${performance?.production?.id}`} rel="noopener noreferrer" target="_blank">
                {`${process.env.REACT_APP_OPERABASE_URL}/productions/${performance?.production?.id}`}
              </a>
            )}
          </Typography>
        </div>
      </div>
      <div className={classes.footerActions}>
        <Button disabled={performance?.validationStatus?.slug === 'approved'} variant="contained" color="secondary" onClick={() => setOpenAcceptPopup(true)}>
          Accept
        </Button>
        <Button disabled={performance?.validationStatus?.slug === 'rejected'} className={classes.spacingLeft} color="primary" variant="contained" onClick={() => handlePerformanceAction(false)}>
          Reject
        </Button>
        <div style={{ marginTop: 20 }}>
          <Typography variant="h6" gutterBottom>
            Guidelines for the validation
          </Typography>
          <Typography variant="body2" gutterBottom>
            1. Check if an existing production exists in Operabase where the artist has performed
          </Typography>
          <Typography variant="body2" gutterBottom>
            2. If an existing production does not exist, check for the evidence provided and add the information as required
          </Typography>
          <Typography variant="body2" gutterBottom>
            3. Accept
          </Typography>
          <Typography variant="body2" gutterBottom style={{ marginLeft: 10 }}>
            a. Accept only if you have added the artist to the cast of a production
          </Typography>
          <Typography variant="body2" gutterBottom style={{ marginLeft: 10 }}>
            b. Provide a production id (you will find this in Admin under Production info) OR provide production URL (Copy + Paste the production page on Operabase where the artist name is visible)
          </Typography>
          <Typography variant="body2" gutterBottom>
            4. Reject
          </Typography>
          <Typography variant="body2" gutterBottom style={{ marginLeft: 10 }}>
            a. Reject if the information is spam or does not provide enough information
          </Typography>
        </div>
      </div>
      <Dialog
        PaperProps={{
          className: classes.dialog,
        }}
        open={isOpenAcceptPopup}
        onClose={() => setOpenAcceptPopup(false)}
      >
        <DialogTitle style={{ paddingBottom: 0 }}>{`Accept performance ${performance?.performanceName}`}</DialogTitle>
        <DialogContent>
          <div>
            <Typography variant="body2" gutterBottom style={{ marginBottom: 15 }}>
              Provide valid production id or url of the production to accept the validation.
            </Typography>
            <Autocomplete
              options={productions}
              autoComplete
              value={selectedProduction}
              onChange={(event, entity) => setSelectedProduction(entity)}
              onInputChange={(event, input) => getProductions(input)}
              renderInput={(params) => <MUITextField {...params} variant="filled" fullWidth label="Search production name / ID" />}
              renderOption={(option) => `${option?.name} (${option?.id})`}
              getOptionLabel={(option) => `${option?.name} (${option?.id})`}
              filterOptions={(option) => option}
            />
            <MUITextField style={{ marginTop: 10 }} value={productionURL} onChange={(e) => setProductionURL(e.target.value)} fullWidth variant="filled" placeholder="Enter production URL" />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAcceptPopup(false)}>Cancel</Button>
          <Button disabled={!selectedProduction?.id && !productionURL} variant="contained" color="primary" onClick={() => handlePerformanceAction(true)}>
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
