import React from 'react';
import { ReferenceField } from 'react-admin';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  intoSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '5px 20px',
    marginTop: 8,
    marginBottom: 4,
  },
  infoTitle: {
    width: 400,
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    fontSize: 18,
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.54)',
    position: 'relative',
    padding: 0,
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
  },
  infoData: {
    width: '100%',
  },
}));

const ReferenceFieldWithPlaceholder = (props) => {
  const classes = useStyles();

  if (!get(props.record, props.source))
    return (
      <div className={classes.intoSection}>
        <label className={classes.infoTitle}>
          <span>{props?.label}</span>
        </label>
        <div className={classes.infoData}>{props?.emptyText || ''}</div>
      </div>
    );
  return (
    <div className={classes.intoSection}>
      <label className={classes.infoTitle}>
        <span>{props?.label}</span>
      </label>
      <div className={classes.infoData}>
        <ReferenceField {...props} />
      </div>
    </div>
  );
};

export default ReferenceFieldWithPlaceholder;
