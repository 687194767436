/* eslint-disable consistent-return */
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_GET_PERMISSIONS, AUTH_CHECK } from 'react-admin';
import storage from '../../utils/storage';
const api = process.env.REACT_APP_API_URL;

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    let loginResult = {};
    const { username, password } = params;
    const loginRequest = new Request(`${api}/auth`, {
      method: 'POST',
      body: JSON.stringify({
        type: 'user',
        user: {
          email: username,
          password,
        },
        isTrustedDevice: true,
        application: 'admin',
      }),
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });

    return fetch(loginRequest)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((result) => {
        loginResult = result;
        const permissionsRequest = new Request(`${api}/auth/permissions?user_id=${loginResult?.user?.id}`, {
          method: 'GET',
          headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${result.token}`,
          }),
        });
        return fetch(permissionsRequest);
      })
      .then((permissionsResult) => permissionsResult.json())
      .then((permissionsList) => {
        const adminPermissions = permissionsList.data.find((item) => item.identifier === 'applications.admin');
        const permissionIdentifiers = permissionsList.data.map((i) => i.identifier);
        if (adminPermissions) {
          storage.setUserPermissions(permissionIdentifiers);
          storage.setUser(loginResult.user.email);
          storage.setUserId(loginResult.user.id);
          storage.setToken(loginResult.token);
          storage.setRefreshToken(loginResult.refreshToken);
        } else {
          throw new Error('Access Denied');
        }
      });
  }

  if (type === AUTH_LOGOUT) {
    if (!storage.getNextPath()) {
      storage.setNextPath(window.location.hash.replace('#', ''));
    }
    if (storage.getToken()) {
      const request = new Request(`${api}/auth/token`, {
        method: 'DELETE',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storage.getToken()}`,
        }),
      });
      return fetch(request)
        .catch(() => Promise.reject())
        .then((response) => {
          if (!response) {
            storage.clearOnLogout();
            return Promise.reject();
          }
          if (response.status === 401) {
            storage.clearOnLogout();
            return Promise.resolve();
          }
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
          }
        })
        .then(() => {
          storage.clearOnLogout();
          return Promise.resolve();
        });
    }
    storage.clearOnLogout();
    return Promise.resolve();
  }

  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status > 300) {
      storage.clearOnLogout();
      return Promise.reject();
    }
    return Promise.resolve();
  }

  if (type === AUTH_CHECK) {
    if (!storage.getToken() && !storage.getRefreshToken() && !storage.getUser()) {
      return Promise.reject();
    }
    return Promise.resolve();
  }

  if (type === AUTH_GET_PERMISSIONS) {
    const permissions = storage.getUserPermissions();
    return permissions ? Promise.resolve(permissions) : Promise.reject();
  }
  return Promise.resolve();
};
