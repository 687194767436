export const paymentWalletsDescriptions = (pm) => {
  switch (pm.wallet_type) {
    case 'paypal/express_checkout':
      return 'PayPal (Express checkout)';
    case 'apple_pay':
      return `${pm.card_type?.toUpperCase() || ''} card (via Apple Pay)`.trim();
    case 'google_pay':
      return `${pm.card_type?.toUpperCase() || ''} card (via Google Pay)`.trim();
    case 'card':
      return `${pm.card_type?.toUpperCase() || ''} card`.trim();
    case 'sepa/direct_debit':
      return `SEPA Direct Debit`;
    default:
      return pm.wallet_type;
  }
};

export const paymentMethodShortDescription = (pm) => `${paymentWalletsDescriptions(pm)} ${pm.payment_method_reference || ''}`;
