import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { VerifiedUser } from '@material-ui/icons';
import { Link } from 'react-admin';
import CityValidationActions from './CityValidationActions';
import { getEntityIcon, getEntityName } from '../utils/entities';

const useStyles = makeStyles(
  (theme) => ({
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      marginRight: 10,
      padding: 4,
      border: '1px solid #F99',
      background: '#FCFCFC',
      borderRadius: 4,
      lineHeight: '28px',
      whiteSpace: 'nowrap',
      fontSize: '12px',
    },
  }),
  { name: 'MakeLink' },
);

const genericLinks = [
  'productions',
  'languages',
  'countries',
  'performances',
  'profiles',
  'venues',
  'agencies',
  'works',
  'validations',
  'countries/cities',
  'competitions',
  'organizations',
  'professions',
  'instruments',
  'instruments/roles',
];

const EntityLink = (props) => {
  const { entity, entityType, view, showType = false, newTab, emptyText } = props;
  const classes = useStyles();
  // const [anchorEl, setAnchorEl] = useState(null);
  // const [open, setOpen] = useState(false);

  // const handleClick = (event) => {
  //   setAnchorEl(event.target);
  //   setOpen(true);
  // };

  if (!entity || !entity.id) {
    return <span>{emptyText ?? `${entityType} missing`}</span>;
  }

  const iconRef = getEntityIcon(entityType);
  const icon = !iconRef ? null : React.cloneElement(iconRef, { style: { margin: '0px 2px -4px 0px', height: 16 } });
  let link;

  if (genericLinks.includes(entityType)) {
    link = (
      <Link to={`/${entityType}/${entity.id}/show`} className={classes.link} onClick={(e) => e.stopPropagation()} target={newTab ? '_blank' : ''}>
        {icon}
        {`${getEntityName(entity, entityType, showType)} (id: ${entity.id}${entity?.email ? `, email: ${entity.email}` : ''})`}
      </Link>
    );
  } else {
    switch (entityType) {
      case 'works/roles': {
        link = (
          <Link to={`/works/${entity?.work?.id}/show/2`} className={classes.link} onClick={(e) => e.stopPropagation()} target={newTab ? '_blank' : ''}>
            {icon}
            {`${getEntityName(entity, entityType)} (id: ${entity.id})`}
          </Link>
        );
        break;
      }
      case 'media':
        if (entity?.thumbnail?.urls.small || entity?.file?.urls.small) {
          link = (
            <Link to={`/media/${entity?.id}/show`} onClick={(e) => e.stopPropagation()} target={newTab ? '_blank' : ''}>
              <img src={entity?.thumbnail?.urls.small ?? entity?.file?.urls.small} alt="Thumbnail" />
            </Link>
          );
        } else {
          link = (
            <Link className={classes.link} to={`/media/${entity?.id}/show`} onClick={(e) => e.stopPropagation()} target={newTab ? '_blank' : ''}>
              {icon}
              {entity?.title} (id: {entity?.id})
            </Link>
          );
        }
        break;
      case 'organizationEmployees':
        link = (
          <div>
            <Link to={`/profiles/${entity?.diff?.profile_id}/show`} className={classes.link} onClick={(e) => e.stopPropagation()} target={newTab ? '_blank' : ''}>
              {`[Employee profile ${entity?.diff?.profile_id}]`}
            </Link>
            <Link to={`/organizations/${entity?.diff?.organization_id}/show`} className={classes.link} onClick={(e) => e.stopPropagation()} target={newTab ? '_blank' : ''}>
              {`[OrgId ${entity?.diff?.organization_id}]`}
            </Link>
          </div>
        );
        break;
      case 'organizationContacts':
      case 'organizationEmpContacts':
        link = (
          <span>
            {entity?.diff?.name} ({entity?.diff?.value})&nbsp;
            <Link to={`/organizations/${entity?.diff?.organization_id}/show`} className={classes.link} onClick={(e) => e.stopPropagation()} target={newTab ? '_blank' : ''}>
              [OrgId: {entity?.diff?.organization_id}]
            </Link>
          </span>
        );
        break;
      case 'workRoles':
        if (entity?.diff?.work?.id) {
          link = (
            <Link to={`/works/${entity?.diff?.work?.id}/show/2`} className={classes.link} onClick={(e) => e.stopPropagation()} target={newTab ? '_blank' : ''}>
              {`Work: ${entity?.diff?.work?.name || entity?.diff?.work?.id}, Role: ${entity?.diff?.name || entity?.reference || entity?.name} (id: ${entity.id})`}
            </Link>
          );
        } else if (entity?.id) {
          link = (
            <Link to={`/works/roles/${entity?.id}/show`} className={classes.link} onClick={(e) => e.stopPropagation()} target={newTab ? '_blank' : ''}>
              {`${entity?.name}`} (id: {entity?.id})
            </Link>
          );
        } else {
          link = (
            <span>
              Role: {entity?.diff?.name || entity?.reference || entity?.name} (id: {entity.id})
            </span>
          );
        }
        break;
      case 'agents':
        link = (
          <Link to={`/profiles/${entity?.diff?.profile_id}/show`} className={classes.link} onClick={(e) => e.stopPropagation()} target={newTab ? '_blank' : ''}>
            {`${entity?.diff?.profile_name || entity?.reference} (id: ${entity?.diff?.profile_id})`}
          </Link>
        );
        break;
      case 'cities':
        if (view === 'list') {
          link = (
            <Link to={`/countries/cities/${entity?.id}/show`} className={classes.link} onClick={(e) => e.stopPropagation()} target={newTab ? '_blank' : ''}>
              {`${entity?.name} (id: ${entity?.id})`}
            </Link>
          );
        } else {
          link = <CityValidationActions entity={entity} />;
        }
        break;
      case 'workTypes':
        link = (
          <Link to={`/workTypes/${entity?.id}/show`} className={classes.link} onClick={(e) => e.stopPropagation()} target={newTab ? '_blank' : ''}>
            {icon}
            {`${entity?.diff?.name || entity?.reference || entity?.name} (id: ${entity?.id})`}
          </Link>
        );
        break;
      case 'stagingTypes':
        link = (
          <Link to={`/stagingTypes/${entity?.id}/show`} className={classes.link} onClick={(e) => e.stopPropagation()} target={newTab ? '_blank' : ''}>
            {icon}
            {`${entity?.diff?.name || entity?.reference || entity?.name} (id: ${entity?.id})`}
          </Link>
        );
        break;
      case 'professions':
        link = (
          <Link to={`/professions/${entity?.diff?.id || entity?.id}/show`} className={classes.link} onClick={(e) => e.stopPropagation()} target={newTab ? '_blank' : ''}>
            {icon}
            {entity?.diff?.name} (id: {entity?.diff?.id || entity?.id})
          </Link>
        );
        break;
      case 'organizationTypes':
        link = (
          <Link to={`/organizationTypes/${entity?.id}/show`} className={classes.link} onClick={(e) => e.stopPropagation()} target={newTab ? '_blank' : ''}>
            {icon}
            {`${entity?.diff?.name} (id: ${entity?.id})`}
          </Link>
        );
        break;
      case 'performanceTypes':
        link = <span>{entity?.diff?.name}</span>;
        break;
      case 'users':
        link = (
          <Link to={`/users/${entity?.id}/show`} className={classes.link} onClick={(e) => e.stopPropagation()} target={newTab ? '_blank' : ''}>
            {icon}
            {`${entity?.name || entity?.id}`} ({entity?.email}
            {entity?.isVerified ? <VerifiedUser style={{ margin: '0px -2px -4px 0px', height: 16, color: '#7777FF' }} /> : ''})
          </Link>
        );
        break;
      case 'instrumentRoles':
        link = (
          <Link to={`/instruments/roles/${entity?.id}/show`} className={classes.link} onClick={(e) => e.stopPropagation()} target={newTab ? '_blank' : ''}>
            {`${entity?.name}`} ({entity?.id}
          </Link>
        );
        break;
      case 'profiles':
        link = (
          <Link to={`/profiles/${entity?.id}/show`} className={classes.link} onClick={(e) => e.stopPropagation()} target={newTab ? '_blank' : ''}>
            {`${entity?.name}`} ({entity?.id}
          </Link>
        );
        break;
      default:
        link = <span>{`Entity not supported: ${entityType} ${view}`}</span>;
        break;
    }
  }

  return link;

  // return (
  //   <span onMouseEnter={(e) => handleClick(e)} onMouseLeave={() => setOpen(false)}>
  //     {link}
  //     <LinkPreview entityType={entityType} entityId={entity.id} open={open} anchorEl={anchorEl} />
  //   </span>
  // );
};

export default EntityLink;
