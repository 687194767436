import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as dayjs from 'dayjs';

const useStyles = makeStyles(() => ({
  tooltipContainer: {
    minWidth: 'min-content',
    backgroundColor: '#fff',
    border: '1px solid #e3e3e3',
    boxShadow: '2px 2px 6px -4px #999',
    borderRadius: 5,
    fontSize: 11,
    fontColor: '#373d3f',
  },
  title: {
    background: '#eceff1',
    borderBottom: '1px solid #ddd',
    padding: 6,
    marginBottom: 4,
  },
  tooltipContent: {
    padding: '6px 12px',
  },
  tooltipItem: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: 2,
  },
  dot: {
    height: 9,
    width: 9,
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: 5,
  },
}));

const CustomTooltip = ({ active, payload, label }) => {
  const classes = useStyles();
  const isDate = label?.split('-')?.length === 3;
  const date = dayjs(label).isValid() ? dayjs(label).format('DD MMM YYYY (dddd)') : label;

  if (active && payload && payload.length) {
    return (
      <div className={classes.tooltipContainer}>
        {isDate && <div className={classes.title}>{date}</div>}
        <div className={classes.tooltipContent}>
          {payload.map((pld) => (
            <div className={classes.tooltipItem}>
              <span style={{ backgroundColor: pld.fill }} className={classes.dot}></span>
              {pld.name}: &nbsp;<span style={{ fontWeight: 500 }}>{pld.value}</span>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
