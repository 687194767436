import React from 'react';

import {
  Create,
  Datagrid,
  Edit,
  Filter,
  List,
  SaveButton,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  EditButton,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  RichTextField,
  FormDataConsumer,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { withStyles } from '@material-ui/core';
import ValidityShow from '../components/ValidityShow';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { PublicLink } from '../components/PublicLink';
import { DeleteWithConfirmButtonNullable } from '../components/DeleteWithConfirmButtonNullable';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const CustomToolbar = withStyles(toolbarStyles)((props) => (
  <Toolbar {...props}>
    <SaveButton />
    <DeleteWithConfirmButtonNullable undoable={false} />
  </Toolbar>
));

const CompetitionFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Competition ID" source="id" alwaysOn />
    <TextInput label="Search" source="query" alwaysOn />
  </Filter>
);

const CompetitionhowActions = (props) => {
  const { basePath, data } = props;
  return (
    <TopToolbar>
      <PublicLink {...props} />
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );
};

export const CompetitionShow = (props) => (
  <ValidityShow {...props} title={<Breadcrumbs {...props} />} actions={<CompetitionhowActions />} hideValidity>
    <TabbedShowLayout>
      <Tab label="Competition">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="country.name" />
        <TextField source="city.name" />
        <RichTextField source="description" />
      </Tab>
    </TabbedShowLayout>
  </ValidityShow>
);

const EditForm = (props) => (
  <SimpleForm {...props}>
    <TextInput source="name" label="Name" />
    <ReferenceInput label="Country" source="country.id" reference="countries" filterToQuery={(searchText) => ({ query: searchText, limit: 300 })} sort={{ field: 'name', order: 'ASC' }}>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <FormDataConsumer source="country.id">
      {({ formData }) => (
        <ReferenceInput
          label="City"
          source="city.id"
          reference="countries/cities"
          filter={{ country_id: formData?.country?.id }}
          filterToQuery={(searchText) => ({ query: searchText, limit: 300 })}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      )}
    </FormDataConsumer>
    <RichTextInput source="description" validation={{ required: true }} />
  </SimpleForm>
);

export const CompetitionEdit = (props) => (
  <Edit {...props} title={<Breadcrumbs {...props} />}>
    <EditForm {...props} />
  </Edit>
);

export const CompetitionCreate = (props) => (
  <Create {...props} toolbar={<CustomToolbar />} title={<Breadcrumbs {...props} />} actions={<CompetitionhowActions />}>
    <EditForm />
  </Create>
);

export const CompetitionList = (props) => (
  <List exporter={false} {...props} filters={<CompetitionFilter />} title={<Breadcrumbs {...props} />} bulkActionButtons={false} sort={{ field: 'name', order: 'ASC' }} perPage={25}>
    <Datagrid rowClick="show">
      <TextField source="id" sortable={false} />
      <TextField source="name" sortable={false} />
      <TextField source="country.name" sortable={false} />
      <TextField source="city.name" sortable={false} />
      <RichTextField source="description" sortable={false} />
    </Datagrid>
  </List>
);

export default CompetitionList;
