import React from 'react';
import { Datagrid, ReferenceManyField, TextField } from 'react-admin';

export const entityErrorHandlers = {
  organizations: (err, data) => {
    if (!data.entity?.diff?.country_id) {
      return { message: `Country not set for organization. Please add it`, entityType: 'organizations', entityId: data.entity?.id };
    }

    return null;
  },
};

const EntityReferenceInfo = (props) => {
  const entityType = props?.record?.entity?.entityType;
  switch (entityType) {
    case 'organizationsEmployees':
      if (!props?.record?.entity?.diff?.organization_id) {
        return null;
      }
      return (
        <ReferenceManyField {...props} reference="organizations" source="entity.diff.organization_id" target="id">
          <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="name" label="Organization Name" sortable={false} />
            <TextField source="country.name" label="Country" sortable={false} />
            <TextField source="city.name" label="City" sortable={false} />
            <TextField source="address" label="Address" component="pre" sortable={false} />
          </Datagrid>
        </ReferenceManyField>
      );

    case 'profiles':
      if (props?.record?.entity?.diff?.organizations) {
        props.record.entity.diff.organizationIds = props?.record?.entity?.diff?.organizations?.map((organization) => organization.id);
      }
      if (props?.record?.entity?.diff?.agencies) {
        props.record.entity.diff.agencyIds = props.record.entity.diff.agencies.map((agency) => agency.id);
      }
      return (
        <>
          {props?.record?.entity?.diff?.organizationIds?.length ? (
            <ReferenceManyField {...props} reference="organization" source="entity.diff.organizationIds" target="id">
              <Datagrid rowClick="show">
                <TextField source="id" />
                <TextField source="name" label="Organization Name" sortable={false} />
                <TextField source="country.name" label="Country" sortable={false} />
                <TextField source="city.name" label="City" sortable={false} />
                <TextField source="address" label="Address" component="pre" sortable={false} />
              </Datagrid>
            </ReferenceManyField>
          ) : null}
          {props?.record?.entity?.diff?.agencyIds?.length ? (
            <ReferenceManyField {...props} reference="agencies" source="entity.diff.agencyIds" target="id">
              <Datagrid rowClick="show">
                <TextField source="id" />
                <TextField source="name" label="Agency Name" sortable={false} />
                <TextField source="country.name" label="Country" sortable={false} />
                <TextField source="city.name" label="City" sortable={false} />
                <TextField source="address" label="Address" component="pre" sortable={false} />
              </Datagrid>
            </ReferenceManyField>
          ) : null}
        </>
      );

    default:
      return null;
  }
};

export default EntityReferenceInfo;
