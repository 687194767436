import React, { useEffect } from 'react';
import { Layout, useRedirect, useAuthProvider } from 'react-admin';
import { makeStyles } from '@material-ui/styles';
import customMenu from './Menu';
import customAppBar from './AppBar';
import storage from '../utils/storage';

const useStyles = makeStyles({
  root: {
    minWidth: 'unset',
  },
  content: {
    boxSizing: 'border-box',
    width: 'calc(100% - 240px)',
  },
  notFoundContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f4f4f4',
    textAlign: 'center',
  },
  notFoundTitle: {
    fontSize: 96,
    fontWeight: 'bold',
    color: '#ff6f61',
  },
  notFoundMessage: {
    fontSize: 24,
    color: '#333',
  },
});

const CustomLayout = (props) => {
  const classes = useStyles();
  const redirect = useRedirect();
  const authProvider = useAuthProvider();
  const user = storage.getUser();
  if (storage.getNextPath() && user) {
    const url = storage.getNextPath();
    storage.clearNextPath();
    redirect(url);
  }
  useEffect(() => {
    authProvider('AUTH_CHECK', {}).catch(() => {
      window.location.href = '/#/login';
    });
  }, [authProvider]);

  if (!user) {
    return (
      <div className={classes.notFoundContainer}>
        <h1 className={classes.notFoundTitle}>404</h1>
        <p className={classes.notFoundMessage}>Oops! The page you are looking for does not exist.</p>
      </div>
    );
  }

  return <Layout {...props} classes={classes} menu={customMenu} appBar={customAppBar} />;
};

export default CustomLayout;
