import React, { useEffect, useState } from 'react';
import { keyBy, map } from 'lodash';
import { Datagrid, TextField, Pagination, useDataProvider, ListContextProvider, Link, FunctionField, Loading } from 'react-admin';
import { Dialog, DialogContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import LaunchIcon from '@material-ui/icons/Launch';
import { makeStyles } from '@material-ui/styles';
import { getChartConfig } from '../utils/userMetrics';

const useStyles = makeStyles({
  button: {
    textTransform: 'initial',
  },
  dialog: {
    minWidth: 700,
    height: 460,
    padding: 10,
    '& .MuiDialogContent-root': { padding: 0 },
    '& .MuiDialogContent-root:first-child': { padding: 0 },
  },
  container: {
    marginTop: 10,
  },
  closeIcon: {
    position: 'absolute',
    right: 10,
    top: 10,
    cursor: 'pointer',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
  },
  launchIcon: {
    fontSize: 14,
    marginLeft: 5,
  },
  title: {
    fontWeight: 600,
    padding: 5,
  },
});

export default function ChartDataModal({ open, onClose, chartData = {} }) {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const { title, filter, resource, hideDialog, dataFields } = getChartConfig(chartData);

  useEffect(() => {
    setIsLoading(true);
    if (chartData?.activeLabel && !hideDialog) {
      dataProvider.getList(resource, { filter, pagination: { page, perPage }, sort: {} }).then((res) => {
        setData(res);
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, page, perPage]);

  const listContext = {
    data: keyBy(data?.data, 'id'),
    ids: data?.data?.map(({ id }) => id),
    total: data.total,
    currentSort: {},
    basePath: resource,
    resource,
    selectedIds: [],
    hasList: true,
    page,
    setPage,
    perPage,
    setPerPage,
  };

  const handleClose = () => {
    setPage(1);
    setPerPage(10);
    setData([]);
    setIsLoading(false);
    onClose();
  };

  if (hideDialog) return null;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        className: classes.dialog,
        sx: { padding: 0 },
      }}
    >
      <DialogContent>
        <div className={classes.title}>{title}</div>
        <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        <div className={classes.container}>
          {isLoading ? (
            <Loading />
          ) : (
            <ListContextProvider value={listContext}>
              <Datagrid>
                <FunctionField
                  source="id"
                  render={(record) => (
                    <Link to={`/${resource}/${record.id}/show`} className={classes.link} target="_blank" rel="noopener noreferrer">
                      {record.id}
                      <LaunchIcon className={classes.launchIcon} />
                    </Link>
                  )}
                />
                {map(dataFields, (field) => {
                  if (field.isFunctionField) {
                    return <FunctionField render={field?.render} label={field.label} sortable={false} />;
                  }

                  return <TextField source={field.key} label={field.label} sortable={false} />;
                })}
              </Datagrid>
              <Pagination />
            </ListContextProvider>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
