import React from 'react';
import { TableCell, Grid } from '@material-ui/core';
import apiClient from '../../utils/api';
import { cloneDiff } from './utils';
import DisplayTable from './DisplayTable';
import DisplayMedia from './DisplayMedia';

export function getMediaAttributeForPoster(media) {
  if (!media) {
    return undefined;
  }

  const { attributes = [] } = media;
  return attributes.find((mediaTag) => mediaTag.slug === 'poster');
}

const getData = async (productionId) => {
  return (await apiClient.get(`productions/${productionId}/media`, {
    params: {
      media_type_id: 1,
      privacy_attribute_id: '1,2,3,4,5,6,7,8',
      show_invalid: true,
      page: 1,
      limit: 1000,
    },
  }))?.data?.data;
};

export const parseProductionImages = (props) => {
  const { production, changeRequest } = props;

  return new Promise(async (resolve, reject) => {
    const a = changeRequest ? await getData(production.id) : [];
    const b = changeRequest ? await getData(changeRequest.revisionId) : await getData(production.id);

    const data = cloneDiff(a,b).filter((i) => !isNaN(parseInt(i.path)));

    resolve(data);
  });
}

export const MediaRow = (props) => {
  return (
    <>
      <TableCell>
        <DisplayMedia path="" changes={props.changes} />
      </TableCell>
    </>
  );
};

export const ProductionImages = (props) => (
  <>
    <Grid container>
      <Grid item xs={12}>
        <DisplayTable {...props} changes={props.changes}>
          <MediaRow />
        </DisplayTable>
      </Grid>
    </Grid>
  </>
);
