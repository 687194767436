import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getEntityIcon, getEntityName } from '../utils/entities';

const useStyles = makeStyles(
  (theme) => ({
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      marginRight: 10,
      padding: 4,
      border: '1px solid #999',
      background: '#FAFAFA',
      borderRadius: 4,
      lineHeight: '32px',
      whiteSpace: 'nowrap',
    },
  }),
  { name: 'MakeLink' },
);

// const genericLinks = ['productions', 'languages', 'countries', 'performances', 'profiles', 'venues', 'agencies', 'works', 'validations'];

const EntityFrontbaseLink = ({ entity, entityType }) => {
  const classes = useStyles();
  const iconRef = getEntityIcon(entityType);
  const icon = !iconRef ? null : React.cloneElement(iconRef, { style: { margin: '0px 2px -4px 0px', height: 16 } });

  switch (entityType) {
    case 'profiles':
      return (
        <a href={`${process.env.REACT_APP_OPERABASE_URL}/artists/${entity.id}`} rel="noopener noreferrer" target="_blank" className={classes.link}>
          {icon}
          {`[Profile] ${getEntityName(entity, entityType)} (id: ${entity.id})`}
        </a>
      );
    case 'organizations':
      return (
        <a href={`${process.env.REACT_APP_OPERABASE_URL}/o${entity.id}`} rel="noopener noreferrer" target="_blank" className={classes.link}>
          {icon}
          {`[Organization] ${getEntityName(entity, entityType)} (id: ${entity.id})`}
        </a>
      );
    case 'productions':
      return (
        <a href={`${process.env.REACT_APP_OPERABASE_URL}/productions/${entity.id}`} rel="noopener noreferrer" target="_blank" className={classes.link}>
          {icon}
          {`[Production] ${getEntityName(entity, entityType)} (id: ${entity.id})`}
        </a>
      );

    default:
      return <span>{`Operabase link is not available for: ${entityType}`}</span>;
  }
};

export default EntityFrontbaseLink;
