import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { useRedirect } from 'react-admin';

import { MERGE_ALLOWED_ENTITIES, NON_OPERATIONAL_ENTITIES } from '../constants/entities';
import MarkAsNonOperational from './MarkAsNonOperational';
import ShareProfileRegistrationLink from './ShareProfileRegistrationLink';
import InternalNotes from './InternalNotes';
import MarkAsWhiteListed from './MarkAsWhiteListed';
import useUserPermissions from '../utils/useUserPermissions';

const useStyles = makeStyles(() => ({
  actions: {
    marginRight: 10,
  },
  button: {
    textTransform: 'inherit',
  },
}));

export default function EntityActions(props) {
  const classes = useStyles();
  const redirect = useRedirect();
  const { permissions } = useUserPermissions();
  const { resource, id } = props;
  const hasWhitelistPermission = permissions?.length && permissions.includes('whitelist.create.all');

  const mergeEntity = MERGE_ALLOWED_ENTITIES.find((entity) => entity?.reference === resource);
  const nonOperationalEntity = NON_OPERATIONAL_ENTITIES.find((entity) => entity === resource);
  const isProfile = resource === 'profiles';
  const isShowNotes = ['productions', 'profiles', 'venues', 'agencies', 'works', 'competitions', 'organizations'].includes(resource);
  const isOrg = resource === 'organizations';

  return (
    <div className={classes.actions} id="entityActions">
      {nonOperationalEntity && <MarkAsNonOperational {...props} type={nonOperationalEntity} />}
      {(isProfile || isOrg) && hasWhitelistPermission ? <MarkAsWhiteListed {...props} /> : null}
      {isProfile && <ShareProfileRegistrationLink {...props} />}
      {isShowNotes && (
        <div style={{ marginRight: 10, display: 'inline-flex' }}>
          <InternalNotes entityId={props.id} entityType={resource} />
        </div>
      )}
      {mergeEntity && (
        <Button
          size="small"
          variant="contained"
          color="grey"
          onClick={() => {
            redirect(`/validations/merge/create?entity_type=${mergeEntity?.id}&source_id=${id}`);
          }}
          className={classes.button}
        >
          Merge
        </Button>
      )}
    </div>
  );
}
