export const VALIDATION_STATUSES_IDS = {
  PENDING: 1,
  PROCESSING: 2,
  VALID: 3,
  INVALID: 4,
  PRIVATE: 5,
};

export const VALIDATION_STATUSES = [
  {
    id: 'pending',
    name: 'Pending',
  },
  {
    id: 'processing',
    name: 'Processing',
  },
  {
    id: 'approved',
    name: 'Approved',
  },
  {
    id: 'rejected',
    name: 'Rejected',
  },
  {
    id: 'approved-hidden',
    name: 'Approved (hidden)',
  },
];

export const VALIDATION_STATUSES_WITH_IDS = [
  {
    id: 1,
    name: 'Pending',
  },
  {
    id: 2,
    name: 'Processing',
  },
  {
    id: 3,
    name: 'Approved',
  },
  {
    id: 4,
    name: 'Rejected',
  },
  {
    id: 5,
    name: 'Approved (hidden)',
  },
];

export const PRODUCTION_VALIDATION_SECTIONS_IDS = {
  PRODUCTION_INFORMATION: 'PRODUCTION_INFORMATION',
  PRODUCTION_POSTER: 'PRODUCTION_POSTER',
  ENSEMBLES_INFORMATION: 'ENSEMBLES_INFORMATION',
  TICKETS_SALE_DATES: 'TICKETS_SALE_DATES',
  DATES_TIME: 'DATES_TIME',
  CAST_CREW: 'CAST_CREW',
  PRODUCTION_VIDEOS: 'PRODUCTION_VIDEOS',
  PRODUCTION_IMAGES: 'PRODUCTION_IMAGES',
  REVIEWS: 'REVIEWS',
  PRODUCTION_SYNOPSIS: 'PRODUCTION_SYNOPSIS',
  PRODUCTION_BOOKLET: 'PRODUCTION_BOOKLET',
};

export const PRODUCTION_VALIDATION_SECTIONS = [
  {
    key: PRODUCTION_VALIDATION_SECTIONS_IDS.PRODUCTION_INFORMATION,
    name: 'Production information',
  },
  {
    key: PRODUCTION_VALIDATION_SECTIONS_IDS.PRODUCTION_POSTER,
    name: 'Production poster & trailer',
  },
  {
    key: PRODUCTION_VALIDATION_SECTIONS_IDS.TICKETS_SALE_DATES,
    name: 'Tickets & sale dates',
  },
  {
    key: PRODUCTION_VALIDATION_SECTIONS_IDS.DATES_TIME,
    name: 'Dates & times of performances, livestreams and video-on-demand',
  },
  {
    key: PRODUCTION_VALIDATION_SECTIONS_IDS.CAST_CREW,
    name: 'Cast & Crew',
  },
  {
    key: PRODUCTION_VALIDATION_SECTIONS_IDS.PRODUCTION_VIDEOS,
    name: 'Production videos',
  },
  {
    key: PRODUCTION_VALIDATION_SECTIONS_IDS.PRODUCTION_IMAGES,
    name: 'Production images',
  },
  {
    key: PRODUCTION_VALIDATION_SECTIONS_IDS.REVIEWS,
    name: 'Reviews',
  },
  {
    key: PRODUCTION_VALIDATION_SECTIONS_IDS.PRODUCTION_SYNOPSIS,
    name: 'Production synopsis',
  },
  {
    key: PRODUCTION_VALIDATION_SECTIONS_IDS.PRODUCTION_BOOKLET,
    name: 'Production booklet',
  },
];

export const VALIDATION_DASHBOARD_CONFIG = {
  profile: {
    label: 'profiles',
    order: 1,
  },
  organizationEmployee: {
    label: 'organizationEmployees',
    order: 2,
  },
  agent: {
    label: 'agents',
    order: 3,
  },
  organization: {
    label: 'organizations',
    order: 4,
  },
  agency: {
    label: 'agencies',
    order: 6,
  },
  work: {
    label: 'works',
    order: 7,
  },
  workcomposer: {
    label: 'workComposers',
    order: 8,
  },
  workrole: {
    label: 'workRoles',
    order: 9,
  },
  librettist: {
    label: 'librettists',
    order: 10,
  },
  venue: {
    label: 'venues',
    order: 11,
  },
  media: {
    label: 'media',
    order: 12,
  },
  city: {
    label: 'City',
    order: 14,
  },
  production: {
    label: 'Productions',
    order: 15,
  },
  redmask_request_pending: {
    label: 'Redmask request',
    order: 16,
  },
  change_request_pending: {
    label: 'Change request',
    order: 17,
  },
};

export const ENTITY_DUPLICATES_VIEWS = [
  {
    name: 'Profiles',
    id: 'v_profile_duplicates',
  },
  {
    name: 'Organizations',
    id: 'v_organization_duplicates',
  },
  {
    name: 'Agencies',
    id: 'v_agency_duplicates',
  },
  {
    name: 'Works',
    id: 'v_work_duplicates',
  },
  {
    name: 'Productions',
    id: 'v_production_duplicates',
  },
  {
    name: 'Venues',
    id: 'v_venue_duplicates',
  },
  {
    name: 'Work roles',
    id: 'v_workrole_duplicates',
  },
  {
    name: 'Cities',
    id: 'v_city_duplicates',
  },
];

export const ENTITY_VALIDATION_CATEGORY = {
  professions: 'registration-cohort',
  AVAILABILITY: 'availability',
};
