
import React, { useMemo, useState, useEffect } from 'react';
import { Loading, useDataProvider, Title, useNotify } from 'react-admin';
import cn from 'classnames';
import * as dayjs from 'dayjs';
import { makeStyles, FormControl, TextField, Button } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { AgGridReact } from 'ag-grid-react';
import { useHistory } from 'react-router-dom';

import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';

import { getDuplicateEntityMetrics } from '../utils/metrics';
import { Breadcrumbs } from './Breadcrumbs';

const styles = () => ({
  filters: {
    display: 'flex',
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selectSection: {
    display: 'flex',
    flexDirection: 'row',
  },
  spacingLeft: { marginLeft: 10 },
  tooltip: {
    backgroundColor: 'white',
    padding: '3px 10px',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    borderRadius: 4,
  },
  dataGridContainer: {
    '& .ag-header-group-text': {
      textTransform: 'capitalize',
    },
    '& .ag-header-cell-label': {
      overflowWrap: 'break-word',
      whiteSpace: 'normal',
    },
  },
  button: {
    color: '#ff5722',
  },
  icon: {
    fontSize: 20,
    paddingRight: 5,
  },
});

const CustomTooltip = (props) => {
  const classes = makeStyles(styles)();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = useMemo(() => props.api.getDisplayedRowAtIndex(props.rowIndex).data, []);

  return (
    <div className={classes.tooltip}>
      <p>
        <span>Name: </span> {data?.user?.name}
      </p>
      <p>
        <span>Email: </span> {data?.user?.email}
      </p>
    </div>
  );
};

export const DuplicateQueueMetrics = (props) => {
  const classes = makeStyles(styles)();
  const history = useHistory();
  const notify = useNotify();

  const dataProvider = useDataProvider();
  const [gridApi, setGridApi] = useState(null); // eslint-disable-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [metrics, setMetrics] = useState([]);

  const searchParams = new URLSearchParams(history.location.search);
  const defaultDateRange = { from_date: searchParams?.get('from_date'), to_date: searchParams?.get('to_date') };
  const [selectedDateRange, setSelectedDateRange] = useState({
    from: defaultDateRange?.from_date || dayjs().add(-6, 'day').format('YYYY-MM-DD'),
    to: defaultDateRange?.to_date || dayjs().format('YYYY-MM-DD'),
  });

  const getDuplicateEntityMetricsDetails = () => {
    setIsLoading(true);
    dataProvider
      .getList('metrics/entity-merge-metrics', { filter: { from_date: selectedDateRange.from, to_date: selectedDateRange.to }, pagination: {}, sort: {} })
      .then((res) => {
        setIsLoading(false);
        const data = res?.data;
        setMetrics(data);
      })
      .catch((err) => {
        setIsLoading(false);
        notify(err?.message, 'warning');
      });
  };

  useEffect(() => {
    document.title = 'Duplicate queue metrics';
  }, []);

  useEffect(() => {
    searchParams.set('from_date', selectedDateRange?.from);
    searchParams.set('to_date', selectedDateRange?.to);
    const newSearch = searchParams.toString();
    history.push({ search: newSearch });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange]);

  useEffect(() => {
    getDuplicateEntityMetricsDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange]);

  const { columns, rows } = getDuplicateEntityMetrics(metrics);

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      minWidth: 100,
      tooltipComponent: CustomTooltip,
      cellStyle: { paddingLeft: 0, borderRight: '1px solid #babfc7' },
      align: 'middle',
    }),
    [],
  );

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onDownload = () => {
    const params = {
      fileName: `entity_validation_from_${selectedDateRange.from}_to_${selectedDateRange.to}`,
    };
    gridApi.exportDataAsCsv(params);
  };

  return (
    <div>
      <Title title={<Breadcrumbs {...props} />} />
      <div className={classes.filters}>
        <FormControl className={classes.selectSection} size="small">
          <TextField
            disabled={isLoading}
            value={selectedDateRange.from}
            onChange={(e) => setSelectedDateRange({ ...selectedDateRange, from: e?.target?.value })}
            type="date"
            label="From"
            variant="filled"
            size="small"
          />
          <TextField
            disabled={isLoading}
            className={classes.spacingLeft}
            value={selectedDateRange.to}
            onChange={(e) => setSelectedDateRange({ ...selectedDateRange, to: e?.target?.value })}
            type="date"
            label="To"
            variant="filled"
            size="small"
          />
        </FormControl>
        <Button disabled={isLoading} size="small" className={classes.button} onClick={() => onDownload()}>
          <GetAppIcon className={classes.icon} /> Export
        </Button>
      </div>
      {!isLoading ? (
        <div className={cn('ag-theme-alpine', classes.dataGridContainer)} style={{ height: '83vh', width: '100%' }}>
          <AgGridReact columnDefs={columns} rowData={rows} defaultColDef={defaultColDef} tooltipShowDelay={0} tooltipHideDelay={2000} onGridReady={onGridReady} />
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default DuplicateQueueMetrics;
