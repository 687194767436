import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { map, omit, orderBy, reduce } from 'lodash';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField as MUITextField,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
  FormControl,
  MenuItem,
  TablePagination,
  TableContainer,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel as MUIFormLabel,
} from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/styles';
import { useDataProvider, Title, Loading, useNotify, useMutation, Link } from 'react-admin';
import { Breadcrumbs } from '../components/Breadcrumbs';
import ValidationLockByUser from '../components/ValidationLockByUser';
import { useDebounce } from '../utils/common';
import EntityLink from '../components/EntityLink';

const IS_COMPLETED = [
  { value: '', label: 'All' },
  { value: 'false', label: 'Pending' },
  { value: 'true', label: 'Done' },
];
const INCLUDE_IN_EXPORT_CHOICES = [
  { value: 'include', label: 'Include' },
  { value: 'exclude', label: 'Exclude' },
  { value: 'need_help', label: 'Need Help' },
];
const TITLE_CHOICES = [
  { value: 'custom_only', label: 'Custom Title' },
  { value: 'custom_and_mw', label: 'Custom Title : Musical Works' },
  { value: 'mw_only', label: 'Musical Works' },
];
const TAG_CHOICES = [
  { value: 'WA', label: 'WA' },
  // { value: 'UA', label: 'UA' },
  // { value: 'P', label: 'P' },
  { value: 'C', label: 'C' },
  { value: '', label: '! no tag !' },
];
const MAGAZINE_MONTHS = [
  { value: '', label: '~ select ~' },
  { value: '0', label: 'January' },
  { value: '1', label: 'February' },
  { value: '2', label: 'March' },
  { value: '3', label: 'April' },
  { value: '4', label: 'May' },
  { value: '5', label: 'June' },
  { value: '6', label: 'July' },
  { value: '7', label: 'August' },
  { value: '8', label: 'September' },
  { value: '9', label: 'October' },
  { value: '10', label: 'November' },
  { value: '11', label: 'December' },
];
const INCLUDE_CAST = [
  { value: '', label: 'Both' },
  { value: 'true', label: 'With Cast' },
  { value: 'false', label: 'Without Cast' },
];
const PER_PAGE = 10;

const useStyles = makeStyles(() => ({
  actions: {
    marginTop: 20,
    borderBottom: '1px solid #ddd',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
  },
  recentQcLog: {
    fontSize: 'x-small',
    margin: 0,
  },
  centerAlign: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  spacingRight: {
    marginRight: 10,
  },
  button: {
    marginLeft: 20,
    textTransform: 'inherit',
    marginBottom: 15,
  },
  dialog: {
    width: 500,
  },
  spacingBottom: {
    marginBottom: 10,
  },
  validationStatus: {
    fontSize: 16,
    fontWeight: 'bold',
    border: '1px solid #ff5722',
    padding: 6,
    backgroundColor: '#ff5722',
    color: 'white',
  },
  logContainer: {
    padding: '8px 16px',
  },
  link: {
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  logsAccordion: {
    marginTop: 20,
  },
  logsAccordionDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  spacingTop: {
    marginTop: 30,
    fontSize: '0.75rem',
  },
  table: {
    width: 'max-content',
  },
  tableCell: {
    maxWidth: 500,
  },
  filterSection: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
    flexWrap: 'wrap',
    rowGap: '10px',
  },
  spacingLeft: {
    marginLeft: 10,
    width: 140,
  },
  autoComplete: {
    width: 220,
    marginLeft: 10,
    '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': {
      paddingTop: 10,
    },
  },
  tableRow: {
    cursor: 'pointer',
  },
  readMore: {
    color: '#ff5722',
  },
  switch: {
    width: 'max-content',
    marginLeft: 5,
  },
  month: {
    minWidth: 140,
  },
  include: {
    padding: 5,
    backgroundColor: '#4caf50',
  },
  exclude: {
    padding: 5,
    backgroundColor: '#f44336',
    color: 'white',
  },
  need_help: {
    padding: 5,
    backgroundColor: '#ffeb3b',
  },
  title_choices_group: {
    display: 'block',
  },
  title_choices_group_item: {
    display: 'block',
  },
}));

const RenderCell = ({ value, dataKey, showReadMore }) => {
  const classes = useStyles();

  switch (dataKey) {
    case 'SourceLink':
      return value ? (
        <TableCell>
          <a href={value} onClick={(e) => e.stopPropagation()} target="_blank" rel="noopener noreferrer">
            <LinkIcon />
          </a>
        </TableCell>
      ) : (
        <TableCell></TableCell>
      );
    case 'prodId':
      return (
        <TableCell>
          <Link to={`/productions/${value}/show`} onClick={(e) => e.stopPropagation()}>
            {value}
          </Link>
        </TableCell>
      );
    case 'recentlyCompletedBy':
      return value?.length ? (
        <TableCell>
          <pre className={classes.recentQcLog}>{`taskId       : ${value[0].task.id}
taskType     : ${value[0].task.taskType}
completed by : ${value[0].user.id} ${value[0].user.email}
completed at : ${value[0].completedAt.slice(0, 10)}`}</pre>
        </TableCell>
      ) : (
        <TableCell></TableCell>
      );
    case 'venueId':
      return (
        <TableCell>
          <Link to={`/venues/${value}/show`} onClick={(e) => e.stopPropagation()}>
            {value}
          </Link>
        </TableCell>
      );
    case 'status':
      return <TableCell>{value ? 'Done' : 'Pending'}</TableCell>;
    case 'createdAt':
    case 'updatedAt':
      return <TableCell>{value?.split('T')?.[0]}</TableCell>;
    case 'Titel':
    case 'Besetzung':
      return showReadMore ? (
        <TableCell>
          <span title={value}>{value?.substring(0, 40)}</span>
          <span className={classes.readMore}>{value?.length > value?.substring(0, 40)?.length ? '... read more' : ''}</span>
        </TableCell>
      ) : (
        <TableCell>{value}</TableCell>
      );
    case 'includeCast':
      return <TableCell>{value ? 'With Cast' : 'Without Cast'}</TableCell>;
    case 'includeInExport':
      return (
        <TableCell>
          <span className={value && value !== '-' ? classes[value] : null}>{value}</span>
        </TableCell>
      );
    case 'lockedBy':
    case 'completedBy':
      return <TableCell>{value?.split('@')[0]}</TableCell>;
    default:
      return <TableCell>{value}</TableCell>;
  }
};

const RenderTableRow = ({ queue, showLockedUser, magazineDataHeaders }) => {
  const classes = useStyles();
  const lockedByQueue = showLockedUser(queue);

  let { recentlyCompletedBy } = queue.metaInfo;
  let completedBy = queue.completedBy?.id ? queue.completedBy.email : '-';

  if (!queue?.magazineData?.length) {
    const headers = reduce(
      magazineDataHeaders,
      (acc, header) => ({
        ...acc,
        ...(header !== '' ? { [header]: '' } : { '~': '' }),
      }),
      {},
    );

    const magazineData = {
      taskId: queue.id,
      status: queue.isCompleted,
      lockedBy: lockedByQueue,
      completedBy,
      recentlyCompletedBy,
      includeInExport: queue?.metaInfo?.includeInExport ?? '-',
      ...headers,
      ...(queue?.metaInfo?.customTitle ? { CustomTitle: queue?.metaInfo?.customTitle } : {}),
      includeCast: queue?.metaInfo?.includeCast,
      year: queue?.metaInfo?.magazineYear,
      month: queue?.metaInfo?.magazineMonthName,
      createdAt: queue?.createdAt,
    };

    return (
      <TableRow
        key={queue.id}
        className={classes.tableRow}
        onClick={() => {
          window.location.href = `#/task_queues/magazine_export/${queue.id}/show`;
        }}
      >
        {map(magazineData, (value, key) => (
          <RenderCell key={key} value={value} dataKey={key} showReadMore />
        ))}
      </TableRow>
    );
  }

  return queue?.magazineData?.map((data) => {
    const rowData = {
      taskId: queue.id,
      status: queue.isCompleted,
      lockedBy: lockedByQueue,
      completedBy,
      recentlyCompletedBy,
      includeInExport: queue?.metaInfo?.includeInExport ?? '-',
      ...omit(data, 'AdminLink'),
      ...(queue?.metaInfo?.customTitle ? { CustomTitle: queue?.metaInfo?.customTitle } : {}),
      includeCast: queue?.metaInfo?.includeCast,
      year: queue?.metaInfo?.magazineYear,
      month: queue?.metaInfo?.magazineMonthName,
      createdAt: queue?.createdAt,
    };

    return (
      <TableRow
        key={queue.id}
        className={classes.tableRow}
        onClick={() => {
          window.location.href = `#/task_queues/magazine_export/${queue.id}/show`;
        }}
      >
        {map(rowData, (value, key) => (
          <RenderCell key={key} value={value} dataKey={key} showReadMore />
        ))}
      </TableRow>
    );
  });
};

export const MagazineExportQueueShow = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [isLoading, setIsLoading] = useState(false);
  const [taskQueueData, setTaskQueueData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [comments, setComments] = useState('');
  const [includeInExport, setIncludeInExport] = useState('');
  const [customTitle, setCustomTitle] = useState('');
  const [selectedTitle, setSelectedTitle] = useState('');
  const [selectedTag, setSelectedTag] = useState('');

  useEffect(() => {
    setIsLoading(true);
    if (props?.id) {
      dataProvider.getOne('task_queues', { id: props?.id }).then((res) => {
        setTaskQueueData(res?.data);

        let metaInfo = res?.data?.metaInfo;
        let magData = res?.data?.magazineData?.[0];

        if (metaInfo?.hasOwnProperty('includeInExport')) {
          setIncludeInExport(metaInfo.includeInExport);
        }

        if (metaInfo?.hasOwnProperty('selectedTitle')) {
          setSelectedTitle(metaInfo.selectedTitle);
        } else {
          setSelectedTitle('mw_only');
        }

        if (metaInfo?.hasOwnProperty('selectedTag')) {
          setSelectedTag(metaInfo.selectedTag);
        } else if (magData?.['']) {
          setSelectedTag(magData['']);
        }

        if (magData) {
          // To preview the selected title
          let magCustomTitle = magData.CustomTitle;
          let magTitel = magData.Titel;
          let currentSelectedTitle = metaInfo?.selectedTitle ?? 'mw_only';
          setCustomTitle(
            currentSelectedTitle === 'custom_only'
              ? magCustomTitle
              : currentSelectedTitle === 'custom_and_mw'
              ? `${magCustomTitle ? magCustomTitle + ' : ' : ''}${magTitel}`
              : currentSelectedTitle === 'mw_only'
              ? magTitel
              : 'not selected',
          );
        }

        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.id]);

  const handleIncludeInExport = (e) => setIncludeInExport(e.target.value);

  const handleTitleSelection = (e) => {
    let currentSelectedTitle = e.target.value;
    setSelectedTitle(currentSelectedTitle);

    // To preview the selected title
    let magData = taskQueueData.magazineData?.[0];
    if (magData && currentSelectedTitle) {
      let magCustomTitle = magData.CustomTitle;
      let magTitel = magData.Titel;
      setCustomTitle(
        currentSelectedTitle === 'custom_only'
          ? magCustomTitle
          : currentSelectedTitle === 'custom_and_mw'
          ? `${magCustomTitle ? magCustomTitle + ' : ' : ''}${magTitel}`
          : currentSelectedTitle === 'mw_only'
          ? magTitel
          : 'not selected',
      );
    }
  };

  const handleTagSelection = (e) => setSelectedTag(e.target.value);

  const [handleComplete] = useMutation(
    {
      type: 'create',
      resource: `task_queues/actions`,
      payload: {
        data: {
          actionType: !taskQueueData?.isCompleted ? 'complete' : 'reset',
          id: taskQueueData?.id,
          comments,
          ...(!taskQueueData?.isCompleted && taskQueueData.magazineData?.length
            ? {
                metaInfo: {
                  includeInExport,
                  selectedTitle,
                  selectedTag,
                },
              }
            : {}),
        },
      },
    },
    {
      onSuccess: () => {
        notify('Task completed', 'info');
        window.location.reload();
      },
      onFailure: (err) => {
        notify(err?.message, 'error');
      },
    },
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <Title title={<Breadcrumbs {...props} />} />
      <div className={classes.actions}>
        <div className={classes.centerAlign}>
          <Typography variant="caption" className={classes.spacingRight}>
            Status
          </Typography>
          <Typography className={classes.validationStatus}>{taskQueueData?.isCompleted ? 'Done' : 'Pending'}</Typography>
        </div>
        <div className={classes.centerAlign}>
          <div className={classes.centerAlign}>
            <Typography variant="caption" className={classes.spacingRight}>
              Locked by
            </Typography>
            <ValidationLockByUser {...props} data={{ id: taskQueueData?.id }} lockGroupType="task_queue" />
          </div>
          <div>
            <Button variant="contained" size="small" className={classes.button} onClick={() => setIsOpen(true)}>
              {!taskQueueData?.isCompleted ? 'Mark as complete' : 'Reset'}
            </Button>
          </div>
        </div>
      </div>

      <h1 className={classes.spacingTop}>Task Meta</h1>
      <pre>
        {`
taskId          : ${taskQueueData?.id}
magazineMonth   : ${taskQueueData?.metaInfo?.magazineMonthName}, ${taskQueueData?.metaInfo?.magazineYear}
includeCast     : ${taskQueueData?.metaInfo?.includeCast ? 'Yes' : 'No'}
includeInExport : ${includeInExport || '?'}
selectedTitle   : ${TITLE_CHOICES.find((choice) => choice.value === selectedTitle)?.label ?? ''}
                = ${customTitle}
selectedTag     : ${selectedTag}
      `.trim()}
      </pre>

      <span>{'production -> '}</span>
      <EntityLink newTab entityType="productions" entity={taskQueueData.production} />
      <h1 className={classes.spacingTop}>Magazine data</h1>
      {taskQueueData?.magazineData?.length ? (
        taskQueueData.magazineData.map((row) => (
          <div style={{ height: 470, overflowY: 'auto' }}>
            <TableContainer>
              <Table>
                {map(row, (value, key) => (
                  <TableRow>
                    <TableCell variant="head">{key || '~'}</TableCell>
                    <RenderCell value={value} dataKey={key} key={key} />
                  </TableRow>
                ))}
              </Table>
            </TableContainer>
          </div>
        ))
      ) : (
        <span>Not valid for magazine. Please continue with production QC and then mark this complete.</span>
      )}

      <h1 className={classes.spacingTop}>Comments:</h1>
      {taskQueueData?.logs?.length ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>User</TableCell>
                <TableCell>Comment</TableCell>
                <TableCell>Timestamp</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderBy(taskQueueData?.logs, 'updatedAt', 'desc')?.map((log) => (
                <TableRow key={log.id}>
                  <TableCell>
                    <EntityLink entityType="users" entity={log?.updatedBy} />
                  </TableCell>
                  <TableCell>
                    <pre style={{ maxHeight: 92, overflowY: 'auto', width: 500, margin: 0 }}>{log?.comments}</pre>
                  </TableCell>
                  <TableCell>{log?.updatedAt}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="caption">No Comments</Typography>
      )}

      <h1 className={classes.spacingTop}>Related entities that are pending for validation:</h1>
      <div style={{ height: 424, overflowY: 'auto' }}>
        {taskQueueData.pendingRelatedEntities?.length ? (
          <div style={{ maxHeight: 300, overflow: 'auto' }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>EntityType</TableCell>
                    <TableCell>Entity</TableCell>
                    <TableCell>Parent Entity</TableCell>
                    <TableCell>Validation</TableCell>
                    <TableCell>CreatedAt</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {taskQueueData.pendingRelatedEntities?.map((row) => (
                    <TableRow key={`${row.entityType}-${row.entityId}`}>
                      <TableCell>{row.entityType}</TableCell>
                      <TableCell>
                        {row.entityType === 'media' ? (
                          <EntityLink newTab entityType={row.entityType} entity={{ id: row.entityId, title: row.name }} />
                        ) : row.entityType === 'productions' ? (
                          <EntityLink newTab entityType={row.entityType} entity={taskQueueData.production} />
                        ) : (
                          <EntityLink newTab entityType={row.entityType} entity={{ id: row.entityId, name: row.name }} />
                        )}
                      </TableCell>
                      <TableCell>
                        {row.parentEntityType && row.parentEntityId ? (
                          <>
                            {row.parentEntityType}
                            {' -> '}
                            <EntityLink newTab entityType={row.parentEntityType} entity={{ id: row.parentEntityId, name: row.parentEntityName }} />
                          </>
                        ) : (
                          '-'
                        )}
                      </TableCell>
                      <TableCell>{'PENDING'}</TableCell>
                      <TableCell>{row.createdAt}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <Typography variant="caption">No pending items</Typography>
        )}
      </div>

      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          className: classes.dialog,
        }}
      >
        <DialogTitle>{!taskQueueData?.isCompleted ? 'Mark as complete' : 'Reset task'}</DialogTitle>
        <DialogContent>
          {!taskQueueData?.isCompleted && taskQueueData.pendingRelatedEntities?.length ? (
            <Typography variant="subtitle2">
              <span style={{ color: 'red', fontWeight: 'bold' }}>Important: </span>
              {"By marking this task complete, Your name will be attached to the APPROVAL of the following entities. So please make sure you've checked them all."}
              <div style={{ maxHeight: 114, overflowY: 'auto' }}>
                <pre>
                  {taskQueueData.pendingRelatedEntities?.map((row) => (
                    <>
                      {row.entityType}{' '}
                      {row.entityType === 'media' ? (
                        <EntityLink newTab entityType={row.entityType} entity={{ id: row.entityId, title: row.name }} />
                      ) : row.entityType === 'productions' ? (
                        <EntityLink newTab entityType={row.entityType} entity={taskQueueData.production} />
                      ) : (
                        <EntityLink newTab entityType={row.entityType} entity={{ id: row.entityId, name: row.name }} />
                      )}
                      {'\n'}
                    </>
                  ))}
                </pre>
              </div>
            </Typography>
          ) : (
            ''
          )}
          <hr />
          {!taskQueueData?.isCompleted && taskQueueData.magazineData?.length ? (
            <div>
              <MUIFormLabel component="legend" required className={classes.spacingTop}>
                Select Title
              </MUIFormLabel>
              <RadioGroup className={classes.title_choices_group} row aria-label="Select Title" name="row-radio-buttons-group">
                {TITLE_CHOICES.map((choice) => (
                  <FormControlLabel
                    className={classes.title_choices_group_item}
                    key={choice.value}
                    value={choice.value}
                    checked={choice.value === selectedTitle}
                    control={<Radio required />}
                    label={choice.label}
                    onChange={handleTitleSelection}
                  />
                ))}
              </RadioGroup>

              <MUIFormLabel component="legend" className={classes.spacingTop}>
                Selected Title:
              </MUIFormLabel>
              <pre style={{ whiteSpace: 'break-spaces' }}>{`${customTitle || '! please select a valid choice from above, title cannot be empty'}`.trim()}</pre>

              {taskQueueData.magazineData[0][''] === 'WA' ? (
                <div>
                  <MUIFormLabel component="legend" required className={classes.spacingTop}>
                    Tag
                  </MUIFormLabel>
                  <RadioGroup row aria-label="Tag" name="row-radio-buttons-group">
                    {TAG_CHOICES.map((choice) => (
                      <FormControlLabel
                        className={choice.value}
                        key={choice.value}
                        value={choice.value}
                        checked={choice.value === selectedTag}
                        control={<Radio required />}
                        label={choice.label}
                        onChange={handleTagSelection}
                      />
                    ))}
                  </RadioGroup>
                </div>
              ) : (
                ''
              )}

              <MUIFormLabel component="legend" required className={classes.spacingTop}>
                In Export
              </MUIFormLabel>
              <RadioGroup row aria-label="In Export" name="row-radio-buttons-group">
                {INCLUDE_IN_EXPORT_CHOICES.map((choice) => (
                  <FormControlLabel
                    className={choice.value}
                    key={choice.value}
                    value={choice.value}
                    checked={choice.value === includeInExport}
                    control={<Radio required />}
                    label={choice.label}
                    onChange={handleIncludeInExport}
                  />
                ))}
              </RadioGroup>
            </div>
          ) : (
            ''
          )}
          <MUIFormLabel component="legend" required className={classes.spacingTop}>
            Comments
          </MUIFormLabel>
          <MUITextField style={{ marginTop: 10 }} required variant="filled" color="primary" multiline fullWidth rows={4} value={comments} onChange={(e) => setComments(e.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleComplete}
            disabled={(taskQueueData.magazineData?.length && (!comments || !includeInExport || !selectedTitle || !customTitle)) || !comments}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const MagazineExportQueueList = () => {
  const classes = useStyles();
  const history = useHistory();
  const dataProvider = useDataProvider();

  const searchParams = new URLSearchParams(history.location.search);

  const [allValidationLocks, setAllValidationLocks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [magazineQueueData, setMagazineQueueData] = useState({
    data: [],
    total: 0,
  });
  const [users, setUsers] = useState([]);

  const [isCompleted, setIsCompleted] = useState(IS_COMPLETED.find((status) => status.value === searchParams?.get('status')) ?? IS_COMPLETED[0]);
  const [page, setPage] = useState(parseInt(searchParams?.get('page'), 0) - 1 || 0);
  const [selectedProductionId, setSelectedProductionId] = useState(searchParams?.get('production_id') ?? '');
  const [lockedBy, setLockedBy] = useState(searchParams?.get('locked_by') ? { id: searchParams.get('locked_by'), email: searchParams.get('locked_by') } : '');
  const [completedBy, setCompletedBy] = useState(searchParams?.get('completed_by') ? { id: searchParams.get('completed_by'), email: searchParams.get('completed_by') } : '');
  const [query, setQuery] = useState(searchParams?.get('production_id') ?? '');
  const [includeCast, setIncludeCast] = useState(INCLUDE_CAST.find((status) => status.value === searchParams?.get('include_cast')) ?? INCLUDE_CAST[0]);
  const [includeInExport, setIncludeInExport] = useState(INCLUDE_IN_EXPORT_CHOICES.find((status) => status.value === searchParams?.get('include_in_export')) ?? { value: '' });
  const [magazineMonth, setMagazineMonth] = useState(MAGAZINE_MONTHS.find((status) => status.value === searchParams?.get('magazine_month')) ?? MAGAZINE_MONTHS[0]);
  const [magazineYear, setMagazineYear] = useState(isNaN(searchParams?.get('magazine_year')) ? '' : searchParams?.get('magazine_year'));

  const allIds = useMemo(() => map(magazineQueueData, 'id'), [magazineQueueData]);
  const debouncedSelectedProductionId = useDebounce(selectedProductionId, 500);
  const debounceMagazineYear = useDebounce(magazineYear, 500);
  const magazineDataHeaders = useMemo(() => {
    const magazine = magazineQueueData?.data?.find((data) => data?.magazineData?.length);
    return Object.keys(omit(magazine?.magazineData?.[0] || {}, 'AdminLink'));
  }, [magazineQueueData.data]);
  const [listEmptyMessage, setListEmptyMessage] = useState('No results');

  useEffect(() => {
    if (allIds.length) {
      dataProvider
        .getList('validations/locks', {
          filter: { lockIdentifier: allIds.join(','), lockGroup: 'task_queue', validation_status: '' },
          pagination: {},
          sort: {},
        })
        .then(({ data }) => {
          setAllValidationLocks(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allIds]);

  useEffect(() => {
    setIsLoading(true);
    if (!debouncedSelectedProductionId && !(debounceMagazineYear && magazineMonth?.value)) {
      setListEmptyMessage('Please filter by (Year & Month) or Production ID along with other filters');
      setIsLoading(false);
      return;
    }
    dataProvider
      .getList('task_queues', {
        filter: {
          task_type: 'magazine_export',
          ...(isCompleted?.value !== '' ? { is_completed: isCompleted.value } : {}),
          production_id: debouncedSelectedProductionId,
          locked_by: lockedBy?.id,
          completed_by: completedBy?.id,
          metaInfoFilters: JSON.stringify({
            ...(includeCast?.value !== '' && { includeCast: includeCast?.value }),
            ...(magazineMonth?.value && { magazineMonth: magazineMonth?.value }),
            ...(debounceMagazineYear && { magazineYear: debounceMagazineYear }),
            ...(includeInExport?.value && { includeInExport: includeInExport?.value }),
          }),
        },
        pagination: { perPage: PER_PAGE, page: page + 1 },
        sort: { field: 'id', order: 'DESC' },
      })
      .then((res) => {
        setMagazineQueueData(res);
        if (!res?.data?.length) {
          setListEmptyMessage('No results');
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [dataProvider, page, isCompleted, debouncedSelectedProductionId, lockedBy, completedBy, includeCast, magazineMonth, debounceMagazineYear, setListEmptyMessage, includeInExport]);

  useEffect(() => {
    dataProvider
      .getList('users', {
        filter: {
          group_id: 3,
          query,
        },
        pagination: {},
        sort: {},
      })
      .then((res) => {
        setUsers(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dataProvider, query]);

  useEffect(() => {
    searchParams.set('page', page + 1);
    searchParams.set('status', isCompleted?.value);
    searchParams.set('locked_by', lockedBy?.id || '');
    searchParams.set('completed_by', completedBy?.id || '');
    searchParams.set('production_id', debouncedSelectedProductionId);
    searchParams.set('include_cast', includeCast?.value);
    searchParams.set('include_in_export', includeInExport?.value);
    searchParams.set('magazine_year', debounceMagazineYear);
    searchParams.set('magazine_month', magazineMonth?.value);
    const newSearch = searchParams.toString();
    history.push({ search: newSearch });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompleted, debouncedSelectedProductionId, page, lockedBy, completedBy, includeCast, magazineMonth, debounceMagazineYear, includeInExport]);

  const showLockedUser = useCallback(
    (data) => {
      if (allValidationLocks.length) {
        const hasValidationLock = allValidationLocks.find((validationLock) => Number(validationLock.lockIdentifier) === Number(data.id));
        return `${hasValidationLock?.user?.email || '-'}`;
      }
      return '-';
    },
    [allValidationLocks],
  );

  return (
    <div>
      <FormControl size="small" className={classes.filterSection}>
        <MUITextField value={isCompleted} select onChange={(e) => setIsCompleted(e?.target?.value)} label="Status" variant="filled" size="small">
          {IS_COMPLETED.map((option) => (
            <MenuItem key={option.value} value={option}>
              {option.label}
            </MenuItem>
          ))}
        </MUITextField>
        <Autocomplete
          options={users}
          autoComplete
          value={lockedBy}
          onChange={(event, user) => {
            setLockedBy(user);
            setPage(0);
          }}
          onInputChange={(event, input) => {
            setQuery(input);
          }}
          renderInput={(params) => <MUITextField {...params} className={classes.autoComplete} fullWidth variant="filled" label="Locked by" />}
          renderOption={(option) => option?.email}
          getOptionLabel={(option) => option?.email}
        />
        <Autocomplete
          options={users}
          autoComplete
          value={completedBy}
          onChange={(event, user) => {
            setCompletedBy(user);
            setPage(0);
          }}
          onInputChange={(event, input) => {
            setQuery(input);
          }}
          renderInput={(params) => <MUITextField {...params} className={classes.autoComplete} fullWidth variant="filled" label="Completed by" />}
          renderOption={(option) => option?.email}
          getOptionLabel={(option) => option?.email}
        />
        <MUITextField
          size="small"
          className={classes.spacingLeft}
          label="Production ID"
          variant="filled"
          value={selectedProductionId}
          onChange={(e) => {
            setSelectedProductionId(e?.target?.value);
            setPage(0);
          }}
        />
        <MUITextField className={classes.spacingLeft} value={includeInExport} select onChange={(e) => setIncludeInExport(e?.target?.value)} label="In Export" variant="filled" size="small">
          {[{ value: '', label: 'All' }, ...INCLUDE_IN_EXPORT_CHOICES].map((option) => (
            <MenuItem key={option.value} value={option}>
              {option.label}
            </MenuItem>
          ))}
        </MUITextField>
        <MUITextField className={classes.spacingLeft} value={includeCast} select onChange={(e) => setIncludeCast(e?.target?.value)} label="Cast" variant="filled" size="small">
          {INCLUDE_CAST.map((option) => (
            <MenuItem key={option.value} value={option}>
              {option.label}
            </MenuItem>
          ))}
        </MUITextField>
        <MUITextField
          size="small"
          className={classes.spacingLeft}
          label="Magazine year"
          variant="filled"
          value={magazineYear}
          onChange={(e) => {
            setMagazineYear(e?.target?.value);
            setPage(0);
          }}
        />
        <MUITextField
          value={magazineMonth}
          className={classes.spacingLeft}
          select
          onChange={(e) => {
            setMagazineMonth(e?.target?.value);
            setPage(0);
          }}
          label="Magazine month"
          variant="filled"
          size="small"
        >
          {MAGAZINE_MONTHS.map((option) => (
            <MenuItem key={option.value} value={option}>
              {option.label}
            </MenuItem>
          ))}
        </MUITextField>
      </FormControl>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {magazineQueueData?.total > page * PER_PAGE ? (
            <>
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {[
                        'Task Id',
                        'Status',
                        'Locked By',
                        'Completed By',
                        'Recent QC Log',
                        'In Export',
                        ...magazineDataHeaders,
                        'Include cast',
                        'Mag. Year',
                        'Mag. Month',
                        'Created at',
                      ]?.map((column) => (column ? <TableCell key={column}>{column}</TableCell> : <TableCell key={column}>~</TableCell>))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {magazineQueueData?.data?.map((queue) => (
                      <RenderTableRow key={queue.id} queue={queue} showLockedUser={showLockedUser} magazineDataHeaders={magazineDataHeaders} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[PER_PAGE]}
                rowsPerPage={PER_PAGE}
                onChangePage={(e, currentPage) => setPage(currentPage)}
                page={page}
                count={magazineQueueData?.total}
                component="div"
              />
            </>
          ) : (
            <Typography>{listEmptyMessage}</Typography>
          )}
        </>
      )}
    </div>
  );
};

export default MagazineExportQueueList;
