import React from 'react';
import {
  List,
  Filter,
  Datagrid,
  TextField,
  SelectInput,
  ArrayField,
  SingleFieldList,
  FunctionField,
  ChipField,
} from 'react-admin';
import { Chip } from '@material-ui/core';
import CustomDateField from '../components/customFields/CustomDateField';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { getComposerFromCreators } from '../utils/composer';

const FILTER_OPTIONS = [
  { name: 'Yes', value: true },
  { name: 'No', value: false },
];

const ProductionsFilter = (props) => (
  <Filter {...props}>
    <SelectInput label="Live stream" source="has_live_stream" choices={FILTER_OPTIONS} optionText="name" optionValue="value" allowEmpty />
    <SelectInput label="Tickets" source="has_tickets" choices={FILTER_OPTIONS} optionText="name" optionValue="value" allowEmpty />
    <SelectInput label="VOD" source="has_vod" choices={FILTER_OPTIONS} optionText="name" optionValue="value" allowEmpty />
    <SelectInput label="Rental" source="has_active_rental" choices={FILTER_OPTIONS} optionText="name" optionValue="value" allowEmpty />
    <SelectInput label="Poster" source="has_poster" choices={FILTER_OPTIONS} optionText="name" optionValue="value" allowEmpty />
  </Filter>
);

const rowClick = (id, basePath, record) => `/productions/${record?.id}/show`;

export const ProductionsCastAndCrewList = (props) => (
  <List exporter={false} {...props} title={<Breadcrumbs {...props} />} filters={<ProductionsFilter />} sort={{ field: 'numbers.artistWithAccessCount', order: 'DESC' }} perPage={25} bulkActionButtons={false}>
    <Datagrid rowClick={rowClick}>
      <TextField source="id" />
      <ArrayField source="works" label="Works" sortable={false}>
        <SingleFieldList linkType={false}>
          <FunctionField
            render={(record) => {
              const composerName = getComposerFromCreators(record?.creators)?.profile?.name;
              return <Chip label={`${record?.original_name} - ${composerName}`} />;
            }}
            label="Work original name"
          />
        </SingleFieldList>
      </ArrayField>
      <TextField source="name" label="Custom name" emptyText="(No custom name)" sortable={false} />
      <FunctionField source="created_at" render={(record) => <CustomDateField record={record} source="createdAt" label="Created at" />} />
      <TextField source="numbers.artistWithAccessCount" label="Artist" />
      <TextField source="numbers.imageCount" label="Image" />
      <TextField source="numbers.reviewCount" label="Review" />
      <TextField source="numbers.videoCount" label="Video" />

      <ArrayField source="contributions" label="Producer" sortable={false}>
        <SingleFieldList linkType={false}>
          <FunctionField
            render={(record) => {
              if (record?.contributionType === 'producer') {
                if (record?.organization) return <Chip label={`${record?.organization?.name} - ${record?.organization?.organizationType.name}`} />;
                if (record?.profile) return <Chip label={`${record?.profile?.name}`} />;
              }
            }}
          />
        </SingleFieldList>
      </ArrayField>

      <ArrayField source="contributions" label="Co-Producers" sortable={false}>
        <SingleFieldList linkType={false}>
          <FunctionField
            render={(record) => {
              if (record?.contributionType === 'co-producer') {
                if (record?.organization) return <Chip label={`${record?.organization?.name} - ${record?.organization?.organizationType.name}`} />;
                if (record?.profile) return <Chip label={`${record?.profile?.name}`} />;
              }
            }}
          />
        </SingleFieldList>
      </ArrayField>

      <ArrayField source="venues" label="Venues" sortable={false}>
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
        </SingleFieldList>
      </ArrayField>
    </Datagrid>
  </List>
);

export default ProductionsCastAndCrewList;
