import React from 'react';
import { useMutation, Show, List, Filter, ReferenceField, SelectInput, Datagrid, TextField, SimpleShowLayout } from 'react-admin';
import { Button, ButtonGroup } from '@material-ui/core';
import { Breadcrumbs } from '../components/Breadcrumbs';

const SupportMessagesFilters = (props) => {
  const data = [
    { id: 'pending', name: 'Pending' },
    { id: 'processing', name: 'Processing' },
    { id: 'resolved', name: 'Resolved' },
    { id: 'cancelled', name: 'Cancelled' },
  ];

  return (
    <Filter {...props}>
      <SelectInput emptyText="All" source="status" label="Status" choices={data} alwaysOn />
    </Filter>
  );
};

export const SupportMessagesShow = (props) => (
  <Show {...props} title={<Breadcrumbs {...props} />} actions={<SupportMessagesActions />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField link="show" label="User" source="user.id" reference="users" sortable={false}>
        <TextField source="name" emptyText="- none -" />
      </ReferenceField>
      <TextField source="email" label="Email" sortable={false} />
      <TextField source="status" label="Status" sortable={false} />
      <TextField source="subject" label="Subject" sortable={false} emptyText="- Empty subject -" />
      <TextField source="message" label="Message" sortable={false} emptyText="- Empty message -" />
    </SimpleShowLayout>
  </Show>
);

export const SupportMessagesList = (props) => (
  <List exporter={false} title={<Breadcrumbs {...props} />} filters={<SupportMessagesFilters />} filterDefaultValues={{ status: 'pending' }} bulkActionButtons={false} {...props} sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <ReferenceField link="show" label="User" source="user.id" reference="users" sortable={false}>
        <TextField source="name" emptyText="- none -" />
      </ReferenceField>
      <TextField source="email" label="Email" sortable={false} />
      <TextField source="status" label="Status" sortable={false} />
      <TextField source="subject" label="Subject" sortable={false} emptyText="- Empty subject -" />
    </Datagrid>
  </List>
);

export const SupportMessagesActions = (props) => {
  const { data } = props;
  const [makePending] = useMutation({
    type: 'update',
    resource: 'users/supportrequests',
    payload: { id: data?.id, data: { status: 'pending' } },
  });
  const [makeProcessing] = useMutation({
    type: 'update',
    resource: 'users/supportrequests',
    payload: { id: data?.id, data: { status: 'processing' } },
  });
  const [makeResolved] = useMutation({
    type: 'update',
    resource: 'users/supportrequests',
    payload: { id: data?.id, data: { status: 'resolved' } },
  });
  const [makeCanceled] = useMutation({
    type: 'update',
    resource: 'users/supportrequests',
    payload: { id: data?.id, data: { status: 'cancelled' } },
  });

  if (!data || !data?.id) {
    return null;
  }

  return (
    <ButtonGroup>
      <Button onClick={makePending} disabled={data.status === 'pending'}>
        Postpone
      </Button>
      <Button onClick={makeProcessing} disabled={data.status === 'processing'}>
        Mark as processing
      </Button>
      <Button onClick={makeResolved} disabled={data.status === 'resolved'}>
        Resolve
      </Button>
      <Button onClick={makeCanceled} disabled={data.status === 'cancelled'}>
        Cancel
      </Button>
    </ButtonGroup>
  );
};

export default SupportMessagesList;
