import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import { Link } from 'react-admin';

const useStyles = makeStyles({
  spacingLeft: { marginLeft: 5 },
  banner: {
    textAlign: 'center',
    backgroundColor: '#c9e0f2',
    width: '100%',
    padding: '5px 0',
    fontWeight: 'bold',
  },
});

export const Suspension = ({ suspension, type, list = false }) => {
  const classes = useStyles();
  const getEntitySuspension = () => {
    switch (type) {
      case 'agencies':
        return suspension?.operationalAgency;
      case 'organizations':
        return suspension?.operationalOrganization;
      default:
        return null;
    }
  };
  const suspensionData = getEntitySuspension();

  if (list && !isEmpty(suspension)) {
    return suspensionData ? <span>Non Op. - {suspension?.suspension_type === 2 ? 'Redirected' : 'Rebranded'}</span> : 'Non operational';
  }

  return (
    <>
      {!isEmpty(suspension) && (
        <div className={classes.banner}>
          {suspensionData ? (
            <>
              Non operational - {suspension?.suspension_type === 2 ? 'Redirected to' : 'Rebranded as'}
              &nbsp;
              <Link to={`/${type}/${suspensionData?.id}/show`}>{suspensionData?.name}</Link>
            </>
          ) : (
            'Non operational'
          )}
        </div>
      )}
    </>
  );
};
