import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Switch, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useNotify, useRefresh, useDataProvider } from 'react-admin';

const useStyles = makeStyles(() => ({
  dialog: { width: 400 },
  switch: { marginTop: 10 },
}));

const WorkTypeForm = ({ open, onClose, entityId, forEntity, formData }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const classes = useStyles();

  const [selectedWorkType, setSelectedWorkType] = useState([]);
  const [workTypesOptions, setWorkTypesOptions] = useState([]);
  const [isPrimary, setPrimary] = useState(formData?.isPrimary);

  const getWorkTypes = (query) => {
    dataProvider
      .getList('works/types', {
        filter: { query },
        pagination: { limit: 25, page: 1 },
        sort: {},
      })
      .then(({ data }) => {
        setWorkTypesOptions(data);
      });
  };

  const handleSubmitWorkType = () => {
    dataProvider
      .create('works/types', {
        data: {
          id: selectedWorkType?.id || formData?.id,
          name: selectedWorkType?.name || formData?.name,
          forEntity,
          forEntityId: entityId,
          isPrimary,
        },
      })
      .then(() => {
        if (formData?.id) {
          notify(`${formData?.name} updated`);
        } else {
          notify(`${selectedWorkType?.name} added`);
        }
        setSelectedWorkType(null);
        onClose();
        refresh();
      });
  };

  useEffect(() => {
    getWorkTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setSelectedWorkType(null);
    onClose();
  };

  useEffect(() => {
    setPrimary(formData?.isPrimary);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.isPrimary]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        className: classes.dialog,
      }}
    >
      <DialogTitle id="alert-dialog-title">{formData?.id ? 'Edit work type' : 'Add work type'}</DialogTitle>
      <DialogContent>
        {!formData?.id && (
          <Autocomplete
            style={{ minWidth: 250 }}
            options={workTypesOptions}
            autoComplete
            value={selectedWorkType}
            onChange={(event, workType) => {
              setSelectedWorkType(workType);
            }}
            onInputChange={(event, input) => {
              getWorkTypes(input);
            }}
            renderInput={(params) => <TextField {...params} variant="filled" fullWidth label="Work type" />}
            renderOption={(option) => option?.name}
            getOptionLabel={(option) => option?.name}
            filterOptions={(option) => option}
          />
        )}
        <FormControlLabel className={classes.switch} control={<Switch checked={isPrimary} onChange={() => setPrimary(!isPrimary)} name="isPrimary" color="primary" />} label="is Primary" />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button style={{ backgroundColor: 'red', color: 'white' }} variant="contained" onClick={handleSubmitWorkType} disabled={!selectedWorkType?.id && !formData?.id}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WorkTypeForm;
