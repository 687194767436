import React from 'react';

import { List, Datagrid, TextField, SimpleShowLayout, Show, FunctionField, Filter, SelectInput } from 'react-admin';

import CustomDateField from '../components/customFields/CustomDateField';
import DateRange from '../components/DateRange';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { CREDIT_NOTE_STATUSES } from '../constants/finance';

const CreditNotesFilter = (props) => (
  <Filter {...props}>
    <SelectInput label="Invoice status" source="statuses" choices={CREDIT_NOTE_STATUSES} alwaysOn />
    <DateRange startDateSource="issue_date_from" endDateSource="issue_date_to" label="Issue date" alwaysOn />
  </Filter>
);

export const CreditNotesDataGrid = () => (
  <Datagrid rowClick="show">
    <TextField label="Credit note #" source="number" />
    <TextField source="status" />
    <TextField source="external_number" />
    <FunctionField label="Amount" render={(creditNote) => `€${parseFloat(creditNote.total_amount.amount).toFixed(2)}`} />
    <FunctionField label="VAT Amount" render={(creditNote) => `€${parseFloat(creditNote.total_amount.amount - creditNote.total_amount_incl_vat.amount).toFixed(2)}`} />
    <CustomDateField source="issue_date" timeHidden />
    <CustomDateField label="Payment expected date" source="due_date" timeHidden />
  </Datagrid>
);

export const CreditNotesShow = (props) => (
  <Show {...props} title={<Breadcrumbs {...props} />}>
    <SimpleShowLayout>
      <TextField label="Credit note #" source="number" />
      <TextField source="status" />
      <TextField source="external_number" />
      <FunctionField label="Amount" render={(creditNote) => `€${parseFloat(creditNote.total_amount.amount).toFixed(2)}`} />
      <FunctionField label="VAT Amount" render={(creditNote) => `€${parseFloat(creditNote.total_amount.amount - creditNote.total_amount_incl_vat.amount).toFixed(2)}`} />
      <CustomDateField source="issue_date" />
      <CustomDateField label="Payment expected date" source="due_date" />
    </SimpleShowLayout>
  </Show>
);

export const CreditNotesList = (props) => (
  <List exporter={false} {...props} title={<Breadcrumbs {...props} />} perPage={25} filters={<CreditNotesFilter />}>
    <CreditNotesDataGrid />
  </List>
);
