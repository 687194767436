export const DOMAIN_STATUSES = [
  { id: 'blacklisted', name: 'Blacklisted' },
  { id: 'whitelisted', name: 'Whitelisted' },
  { id: 'review-skipped', name: 'Review-Skipped' },
  { id: 'review-pending', name: 'Review-Pending' }
];

export const DOMAIN_STATUSES_IDS = {
  BLACKLISTED: "blacklisted",
  WHITELISTED: "whitelisted",
  REVIEWPENDING: "review-pending",
  REVIEWSKIPPED: "review-skipped"
};
