import React from 'react';
import { FormDataConsumer, ReferenceInput, AutocompleteInput, required } from 'react-admin';
import lodash from 'lodash';
import { useForm } from 'react-final-form';

const CountryAndCity = ({ formData, countryField, cityField, prefix, isCountryRequired, ...rest }) => {
  const form = useForm();
  let countrySource = `${prefix ? `${prefix}.` : ''}country.id`;
  let citySource = `${prefix ? `${prefix}.` : ''}city.id`;
  if (countryField) {
    countrySource = `${countryField}.id`;
  }
  if (cityField) {
    citySource = `${cityField}.id`;
  }

  const handlerCountryChange = () => {
    if (cityField) {
      form.change(cityField, { id: null });
    }
  };

  return (
    <>
      <ReferenceInput
        label="Country"
        source={countrySource}
        reference="countries"
        filterToQuery={(searchText) => ({ query: searchText })}
        sort={{ field: 'name', order: 'ASC' }}
        onChange={handlerCountryChange}
        validate={isCountryRequired ? required() : false}
        {...rest}
      >
        <AutocompleteInput optionText="name" resettable />
      </ReferenceInput>
      <div>
        <ReferenceInput
          label="City"
          source={citySource}
          reference="countries/cities"
          disabled={!lodash.get(formData, countrySource)}
          filter={{ country_id: lodash.get(formData, countrySource) }}
          filterToQuery={(searchText) => ({ query: searchText })}
          sort={{ field: 'name', order: 'ASC' }}
          {...rest}
        >
          <AutocompleteInput optionText={(option) => `${option.name} ${option.areas ? `(${option.areas.map((area) => area.name).join(', ')})` : ''}`} matchSuggestion={() => true} resettable />
        </ReferenceInput>
      </div>
    </>
  );
};

const CountryAndCityInput = ({ prefix, countryField, cityField, isCountryRequired = false }) => (
  <FormDataConsumer>
    {(formDataProps) => <CountryAndCity {...formDataProps} prefix={prefix} isCountryRequired={isCountryRequired} countryField={countryField} cityField={cityField} />}
  </FormDataConsumer>
);

export default CountryAndCityInput;
