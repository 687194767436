/* eslint-disable no-param-reassign,no-case-declarations */
import { stringify } from 'query-string';
import {
  GET_LIST, // --> list via. querystring   eg ::     GET /profiles?id=1,2,3&query=test
  GET_ONE, //  --> get a single resource     GET /profiles/{resource_id}
  CREATE, // -> post a single resource     POST /profiles
  UPDATE, // update/replace a single resource     PUT /profiles/{resource_id}
  DELETE, // delete a single resource       /profiles/{resource_id}
  GET_MANY,
  GET_MANY_REFERENCE,
  UPDATE_MANY,
} from 'react-admin';
import axios from 'axios';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import storage from '../../utils/storage';
import { PUBLIC_ROUTES } from '../../constants/routes';
import { TASK_QUEUES } from '../../constants/taskQueues';

const INDEX = GET_LIST;
const GET = GET_ONE;
const apiUrl = process.env.REACT_APP_API_URL;

// creating instance for axios
const axiosInstance = axios.create({
  baseURL: apiUrl,
  validateStatus: (status) => status >= 200 && status < 300,
});

const requestHandler = (request) => request;

const handleSuccess = (response, type, params, service) => {
  switch (type) {
    case INDEX:
      return {
        data: response.data,
        total: parseInt(response.total, 10),
      };

    case CREATE:
      return { data: { ...response, ...params.data, id: response.id } };

    case GET_MANY:
      return { data: response.data };

    case GET_MANY_REFERENCE:
      if (response.data?.length > 0 && !response.data[0].id && response.data[0].identifier) {
        response.data = response.data.map((d) => {
          d.id = d.identifier;
          delete d.identifier;
          return d;
        });
      }
      response.data = mapResponse(service, params, response.data);

      return { data: response.data, total: response.total };

    case UPDATE:
      if (!response.id && response.identifier) {
        response.id = response.identifier;
        delete response.identifier;
      }
      return { data: response };

    default:
      return { data: response };
  }
};

const generateRefreshToken = (refreshToken, token) => {
  const data = {
    refreshToken,
    token,
  };
  return new Promise((resolve, reject) => {
    axios({
      url: `${apiUrl}/auth/refreshtoken`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(data),
    })
      .then((res) => resolve(res.data))
      .catch((err) => {
        // clear local storage on error
        storage.clearOnLogout();
        // redirect to login page

        if (!Object.values(PUBLIC_ROUTES).includes(window.location.hash)) {
          window.location.href = '/#login';
        }

        return reject(err);
      });
  });
};

export const handleError = async (error) => {
  const originalRequest = error.config;
  if (error.response && error.response.status === 401) {
    try {
      const refreshToken = storage.getRefreshToken();
      const token = storage.getToken();
      // get newAuthToken by calling refreshToken
      const responseOfRefreshToken = await generateRefreshToken(refreshToken, token);
      const newAccessToken = get(responseOfRefreshToken, 'token', null);
      const newRefreshToken = get(responseOfRefreshToken, 'refreshToken', null);
      // create newRequest for the failed request
      if (newAccessToken) {
        const newOriginalRequest = {
          ...originalRequest,
          headers: {
            Authorization: `Bearer ${newAccessToken}`,
          },
        };
        // set token & refresh token to local storage
        storage.setToken(newAccessToken);
        storage.setRefreshToken(newRefreshToken);
        return axios(newOriginalRequest);
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  if (error?.response?.status) {
    const dataMessage = error.response.data.message;
    const errorMessage = typeof dataMessage === 'string' ? dataMessage : JSON.stringify(dataMessage);
    error.message = `[ERROR] (${error.response.status}) - ${errorMessage}`;
  }

  return Promise.reject(error);
};

// request & response interceptors
axiosInstance.interceptors.request.use((request) => requestHandler(request));
axiosInstance.interceptors.response.use(null, handleError);

const fetchApi = async (url, options, type, params, service) => {
  try {
    const res = await axiosInstance(url, options);
    return handleSuccess(res.data, type, params, service);
  } catch (error) {
    return Promise.reject(error);
  }
};

const mapService = (type, service, params) => {
  if (service === 'users/groups') {
    if (params.target === 'user_id') {
      service = `users/${params.id}/groups`;
    }

    if (type === DELETE && params.previousData.group) {
      service = `users/${params.previousData.user.id}/groups`;
    }

    if (type === CREATE && params.data.user) {
      service = `users/${params.data.user.id}/groups`;
    }
  }

  if (service === 'instruments/roles' && type === CREATE) {
    service = `instruments/${params?.data?.instrument?.id}/roles`;
  }

  if (service === 'users/welcome') {
    if (type === CREATE && params.user_id) {
      service = `users/${params.user_id}/welcome`;
    }
  }

  if (service === 'users/cards') {
    if (type === DELETE && params.previousData.userId) {
      service = `users/${params.previousData.userId}/cards`;
    }
  }

  if (service === 'users/permissions') {
    if (params.target === 'user_id') {
      service = `users/${params.id}/permissions`;
    }
  }

  if (service === 'users/settings') {
    if (params.target === 'user_id') {
      service = `users/${params.id}/settings`;
    }
  }

  if (service === 'agencies/offices') {
    if (params.target === 'agency_id') {
      service = `agencies/${params.id}/offices`;
    }
  }

  if (service === 'agencies/artists') {
    if (params.target === 'agency_id') {
      service = `agencies/${params.id}/artists`;
    }
  }

  if (service === 'organizations/employees') {
    if (type === DELETE && params?.previousData?.organization?.id) {
      service = `organizations/${params.previousData.organization.id}/employees`;
    }
    if (params?.target === 'organization_id') {
      service = `organizations/${params.id}/employees`;
    }
    if (params?.data?.ra_target === 'organization_id') {
      service = `organizations/${params.data.organization.id}/employees`;
    }
  }

  if (service === 'organizations/contacts') {
    if (type === DELETE && params?.previousData?.organization?.id) {
      service = `organizations/${params.previousData.organization.id}/contacts`;
    }
    if (params.previousData?.organization_id) {
      service = `organizations/${params.previousData.organization_id}/contacts`;
    }
    if (params.target === 'organization_id') {
      service = `organizations/${params.id}/contacts`;
    }
  }

  if (service === 'organizations/addresses') {
    if (type === DELETE && params?.previousData?.organization?.id) {
      service = `organizations/${params.previousData.organization.id}/addresses`;
    }
    if (params.previousData?.organization_id) {
      service = `organizations/${params.previousData.organization_id}/addresses`;
    }
    if (params.target === 'organization_id') {
      service = `organizations/${params.id}/addresses`;
    }
  }

  if (service === 'productions/actions' || service === 'scrape-productions/actions') {
    if (type === CREATE && params.data.production) {
      service = `productions/${params.data.production.id}/actions`;
    }
  }

  if (service === 'productions/productionPerformances' || service === 'scrape-productions/productionPerformances') {
    if (params.target === 'production_id') {
      service = `productions/${params.id}/productionPerformances`;
    }
  }

  if (['cuetv/customers/all', 'cuetv/customers/operabase'].includes(service)) {
    if (service === 'cuetv/customers/operabase' && params.filter) {
      params.filter.is_operabase_user = true;
    }

    service = 'cuetv/customers';
  }

  if (service === 'invoices/creditNotes') {
    if (type === CREATE && params.invoiceId) {
      service = `invoices/${params.invoiceId}/creditNotes`;
    }
  }

  if (service === 'metrics/dbviews' || service === 'validations/duplicates-entities') {
    const viewId = params?.filter?.view_id;
    if (viewId) {
      service = `metrics/dbviews/${viewId}`;
    }
  }

  if (service === 'validations/performances') {
    service = 'users/performances';
  }

  if (service === 'productions/castandcrew' || service === 'scrape-productions/castandcrew') {
    service = 'productions';
  }

  if (service === 'productions/events' || service === 'scrape-productions/events') {
    if (params.target === 'production_id') {
      service = `productions/${params.id}/events`;
    }
  }

  if (service === 'productions/tickets' || service === 'scrape-productions/tickets') {
    if (params.target === 'production_id') {
      service = `productions/${params.id}/tickets`;
    }
  }

  if (service === 'users/emailPreferences') {
    if (params.target === 'user_id') {
      service = `users/${params.id}/emailPreferences`;
    }
  }

  if (service === 'workTypes') {
    service = `works/types`;
  }

  if (service === 'stagingTypes') {
    service = `works/stagingTypes`;
  }

  if (service === 'whitelist/entities') {
    if (type === CREATE) {
      service = 'whitelist/requests';
    }
  }

  if (service === 'organizationTypes') {
    service = 'organizations/types';
  }

  if (TASK_QUEUES.includes(service)) {
    // iterate through params.filter and rename the keys matching the metaInfoFilter_ prefix
    if (params.filter) {
      Object.keys(params?.filter).forEach((key) => {
        if (key.startsWith('metaInfoFilter_')) {
          if (params.filter.metaInfoFilters === undefined) {
            params.filter.metaInfoFilters = {};
          }

          params.filter.metaInfoFilters[key.replace('metaInfoFilter_', '')] = params.filter[key];
          delete params.filter[key];
        }

        if (key.startsWith('task_specific_')) {
          if (params.filter.task_specific_filters === undefined) {
            params.filter.task_specific_filters = {};
          }

          params.filter.task_specific_filters[key.replace('task_specific_', '')] = params.filter[key];
          delete params.filter[key];
        }
      });

      if (Object.keys(params.filter?.metaInfoFilters || {})?.length > 0) {
        params.filter.metaInfoFilters = JSON.stringify(params.filter.metaInfoFilters);
      }

      if (Object.keys(params.filter?.task_specific_filters || {})?.length > 0) {
        params.filter.task_specific_filters = JSON.stringify(params.filter.task_specific_filters);
      }
    }
    service = 'task_queues';
    if (!isEmpty(params.data)) {
      service = 'task_queues/actions';
    }
  }

  if (service === 'scrape-productions') {
    service = 'productions';
  }

  return service;
};

const mapResponse = (service, params, data) => {
  if (params.id && service === `organizations/${params.id}/contacts`) {
    data = data.map((item) => {
      item.organization_id = params.id;
      return item;
    });
  }
  return data;
};

/**
 * Maps react-admin queries to my REST API
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} service Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a data response
 */
const call = async (type, service, params) => {
  let url = '';
  let query = null;
  const options = {};

  options.headers = {
    Authorization: `Bearer ${storage.getToken()}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  service = mapService(type, service, params);

  switch (type) {
    case INDEX:
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const sortParam = order === 'DESC' ? `-${field}` : field;
      query = {
        sort: sortParam,
        page,
        limit: perPage,
        aggregation_type: params.aggregation_type,
        validation_status: 'pending,processing,approved,rejected,approved-hidden',
      };

      if (params.filter) {
        // How do we handle multi value select ?
        Object.keys(params.filter).forEach((key) => {
          if (!['query', 'aggregation_query'].includes(key)) {
            const search = params.filter[key];
            if (typeof search === 'string' && search.includes('&')) {
              const parsedParams = JSON.parse(`{"${decodeURI(`${key}=${search}`).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`);
              query = { ...query, ...parsedParams };
              return;
            }
          }
          query[key] = params.filter[key];
        });
      }
      if (!isEmpty(params.sort)) {
        query.sort = [(params.sort.order === 'DESC' ? '-' : '') + params.sort.field];
      }
      if (service === 'works') {
        query.exclude_concerts = false;
      }
      url = `${apiUrl}/${service}?${stringify(query, {
        arrayFormat: 'comma',
      })}`;
      break;

    case GET:
      query = get(params, 'query', {});
      url = `${apiUrl}/${service}/${params.id}`;
      if (!isEmpty(query)) {
        url += `?${stringify(query, { skipNull: true })}`;
      }
      break;

    case GET_MANY:
      const id = params.ids || params.id;
      const q = {
        limit: Array.isArray(id) ? id.length : id ? id.split(',').length : null,
        id: Array.isArray(id) ? id.join(',') : id,
        validation_status: 'pending,processing,approved,rejected,approved-hidden',
      };
      if (service === 'profiles' || service === 'agencies') {
        q.limit = get(params, 'pagination.perPage', 100) || 100;
      }
      url = `${apiUrl}/${service}?${stringify(q, { arrayFormat: 'comma' })}`;
      break;

    case GET_MANY_REFERENCE:
      query = {
        validation_status: 'pending,processing,approved,rejected,approved-hidden',
      };
      if (params.filter) {
        Object.keys(params.filter).forEach((key) => {
          query[key] = params.filter[key] instanceof Array ? params.filter[key].join() : params.filter[key];
        });
      }

      // add the pagination
      if (params.pagination) {
        const { pagination } = params;
        query.limit = pagination.perPage;
        query.page = pagination.page;
      }

      // add the sort order
      if ((!params.query || params.query.length === '') && params.sort && params.sort.field) {
        query.sort = [];
        query.sort.push((params.sort.order === 'DESC' ? '-' : '') + params.sort.field);
      }

      // add the target query
      if (params.target) {
        query[params.target] = [params.id];
      }

      url = `${apiUrl}/${service}?${stringify(query)}`;
      break;

    case CREATE:
      url = `${apiUrl}/${service}`;
      options.method = 'POST';
      options.data = params.data;
      if (service === 'users/subscriptions') {
        if (options.data.organizations && options.data.organizations.id) {
          options.data.organization = { id: params.data.organizations.id };
        }
        if (options.data.agencies && options.data.agencies.id) {
          options.data.agency = { id: params.data.agencies.id };
        }
      }

      break;

    case UPDATE:
      query = get(params, 'query', {});
      if (params.data instanceof FormData) {
        url = `${apiUrl}/${service}/${JSON.parse(params.data.get('data')).id}`;
      } else {
        url = `${apiUrl}/${service}/${params.id}`;
      }
      if (!isEmpty(query)) {
        url += `?${stringify(query, { skipNull: true })}`;
      }
      delete params.query;
      options.method = 'PUT';
      options.data = params.data;
      break;

    case UPDATE_MANY:
      return Promise.all(
        params.ids.map((id) => {
          url = `${apiUrl}/${service}/${id}`;
          options.method = params?.data?.method || 'PUT';
          delete params.data.method;
          options.data = JSON.stringify(params.data);
          return fetchApi(url, options, type, params);
        }),
      ).then(
        (responses) => ({ data: responses.map(({ data }) => data.id) }),
        (error) => Promise.reject(error),
      );

    case DELETE:
      url = `${apiUrl}/${service}/${params.id}`;
      options.method = 'DELETE';
      break;

    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }

  const data = await fetchApi(url, options, type, params, service);

  if (type === GET && !params.lang && data.data) {
    data.data = await mapTranslations(data.data, service);
  }

  return data;
};

const mapTranslations = async (data, service) => {
  if (service !== 'works') {
    return data;
  }

  if (data.composers) {
    data.composer_ids = data.composers.map((composer) => composer.id);
  }

  return data;
};

export default call;
