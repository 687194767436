import React, { useEffect, useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  DateInput,
  SelectInput,
  BooleanInput,
  SimpleShowLayout,
  Show,
  FunctionField,
  Link,
  NumberInput,
  TextInput,
  TopToolbar,
  useDataProvider,
  useNotify,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { Card, CardText } from 'material-ui/Card';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import SubHeaderDatagrid from '../components/SubHeaderDatagrid';
import CustomDateField from '../components/customFields/CustomDateField';
import { Breadcrumbs } from '../components/Breadcrumbs';

const subscriptionTypeOptions = [
  { id: 'monthly', name: 'Monthly' },
  { id: 'yearly', name: 'Yearly' },
  { id: 'null', name: 'Followers & TVOD' },
  { id: 'custom', name: 'Custom' },
];

const subscriptionStatusOptions = [
  { id: 'free_trial', name: 'Free trial' },
  { id: 'cancelled', name: 'Cancelled' },
  { id: 'enabled', name: 'Enabled' },
  { id: 'refunded', name: 'Refunded' },
  { id: 'disabled', name: 'Disabled' },
  { id: 'paused', name: 'Paused' },
  { id: 'expired', name: 'Expired' },
];

const userSubscriptionStatusOptions = [
  { id: 'active', name: 'Active' },
  { id: 'non_renewing', name: 'Non-renewing' },
  { id: 'cancelled', name: 'Cancelled' },
];

// const subscriptionExpiryOperatorOptions = [
//   { id: '!', name: 'C-Subscription end date does not exist, O-Subscription end date exists (≠C)' },
//   { id: 'gt', name: 'C-Subscription end date > O-Subscription end date exists (C > O)' },
//   { id: 'lt', name: 'C-Subscription end date < O-Subscription end date exists (C < O)' },
//   { id: 'eq', name: 'C-Subscription end date = O-Subscription end date exists (C = O)' }
// ];

const useSubheaderStyles = makeStyles((theme) => ({
  user: {
    backgroundColor: theme.palette.primary.main,
  },
  cuetv: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const UserCueTVCustomersFilter = (props) => (
  <Filter {...props}>
    <NumberInput label="User ID" source="id" alwaysOn />
    <TextInput label="Email" source="email" alwaysOn />

    <ReferenceInput label="Profile type" source="profile_type_id" reference="profiles/types" alwaysOn allowEmpty>
      <SelectInput alwaysOn allowEmpty />
    </ReferenceInput>

    <SelectInput label="Operabase PRO subscription" choices={userSubscriptionStatusOptions} source="operabase_subscription_status" alwaysOn={true} style={{ width: '16rem' }} />

    <DateInput source="payment_date_from" allowEmpty alwaysOn />
    <DateInput source="payment_date_to" allowEmpty alwaysOn />

    <BooleanInput label="Has paid invoices" source="has_paid_invoices" alwaysOn />
    <BooleanInput label="Has CueTV subscription" source="has_cuetv_subscription" alwaysOn allowEmpty />

    {/* <SelectInput label="CueTV expiry operator" choices={subscriptionExpiryOperatorOptions} alwaysOn source="cuetv_subscription_expiry_operator" style={{ width: '15rem' }} /> */}
  </Filter>
);

const useStyles = makeStyles({
  toolbar: {
    paddingTop: '0px',
  },
  card: {
    width: '15rem',
  },
});

const UserCueTVCustomersToolbar = () => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState();
  const [failed, setFailed] = useState();
  const notify = useNotify();

  useEffect(() => {
    if (!total && !loading && !failed) {
      setLoading(true);
      dataProvider
        .getOne('users/cuetv/customers', { id: 'total' })
        .then((data) => setTotal(data?.data.total))
        .catch((err) => {
          notify(err.message, 'error');
          setFailed(true);
        });
    }
  }, [total, loading, failed, dataProvider, notify]);

  return (
    <TopToolbar className={classes.toolbar}>
      <MuiThemeProvider>
        <Card className={classes.card}>
          <CardText>
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <Typography variant="body2" color="textPrimary" component="p">
                  Operabase PRO subscriptions
                </Typography>
              </Grid>

              <Grid item xs>
                <Typography variant="body2" color="textPrimary" component="p" align="right">
                  {total ? total.operabaseSubscriptions : 'Loading...'}
                </Typography>
              </Grid>

              <Grid item xs={9}>
                <Typography variant="body2" color="textPrimary" component="p">
                  CueTV Subscriptions
                </Typography>
              </Grid>

              <Grid item xs>
                <Typography variant="body2" color="textPrimary" component="p" align="right">
                  {total ? total.cuetvSubscriptions : 'Loading...'}
                </Typography>
              </Grid>
            </Grid>
          </CardText>
        </Card>
      </MuiThemeProvider>
    </TopToolbar>
  );
};

const CueTVCustomersFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Country" source="country_id" reference="countries" filterToQuery={(searchText) => ({ query: searchText })} alwaysOn allowEmpty>
      <AutocompleteInput optionText={(country) => (country && country.name ? `(${country.id}) - ${country.name}` : 'All')} matchSuggestion={() => true} />
    </ReferenceInput>

    <ReferenceInput label="Signup device" source="signup_device" reference="cuetv/customers/aggregations" filter={{ aggregation_type: 'signup_device' }} alwaysOn allowEmpty>
      <SelectInput alwaysOn allowEmpty />
    </ReferenceInput>

    <SelectInput source="subscription_type" label="Subscription type" choices={subscriptionTypeOptions} alwaysOn allowEmpty />
    <SelectInput source="subscription_status" label="Subscription status" choices={subscriptionStatusOptions} alwaysOn allowEmpty />

    <BooleanInput label="Operabase user" source="is_operabase_user" alwaysOn />
    <BooleanInput label="Has Operabase subscription" source="has_operabase_subscription" alwaysOn />
  </Filter>
);

const CueTVSubscriptionsFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Country" source="country_id" reference="countries" filterToQuery={(searchText) => ({ query: searchText })} alwaysOn allowEmpty>
      <AutocompleteInput optionText={(country) => (country && country.name ? `(${country.id}) - ${country.name}` : 'All')} matchSuggestion={() => true} />
    </ReferenceInput>

    <DateInput source="subscription_created_from" allowEmpty />
    <DateInput source="subscription_created_to" allowEmpty />

    <SelectInput source="subscription_type" label="Subscription type" choices={subscriptionTypeOptions} alwaysOn allowEmpty />

    <SelectInput source="subscription_status" label="Subscription status" choices={subscriptionStatusOptions} alwaysOn allowEmpty />

    <BooleanInput label="Operabase user" source="is_operabase_user" alwaysOn />
  </Filter>
);

const UserLink = (props) => (props.customer.user ? <Link to={{ pathname: `/users/${props.customer.user.id}/show` }}>{props.customer.email}</Link> : props.customer.email);

export const UserCueTVCustomersList = (props) => {
  const classes = useSubheaderStyles();
  return (
    <List
      {...props}
      title={<Breadcrumbs {...props} />}
      bulkActionButtons={false}
      sort={{ field: 'cuetvCustomer.account_created_at', order: 'DESC' }}
      perPage={25}
      filters={<UserCueTVCustomersFilter />}
      actions={<UserCueTVCustomersToolbar />}
      exporter={false}
    >
      <SubHeaderDatagrid
        rowClick="show"
        subheaderMappings={[
          {
            label: 'Operabase',
            headerClassName: classes.user,
            fields: ['id', 'name', 'email', 'userSubscription.start_date', 'userSubscription.renew_date', 'lastInvoicePaymentDate'],
          },
          {
            label: 'CueTV',
            headerClassName: classes.cuetv,
            fields: [
              'cuetvCustomer.account_created_at',
              'cuetvCustomer.signup_device',
              'cuetvCustomer.country_reference',
              'cuetvCustomer.cuetvSubscription.start_date',
              'cuetvCustomer.cuetvSubscription.renew_date',
            ],
          },
        ]}
      >
        <TextField label="User ID" source="id" />
        <FunctionField label="Name (CueTV name)" render={(user) => `${user.name || '#Empty'} (${user.cuetvCustomer?.name || '#Empty'})`} source="name" />
        <FunctionField label="Email" render={(user) => <Link to={{ pathname: `/users/${user.id}/show` }}>{user.email}</Link>} source="email" />
        <CustomDateField label="Subscription start date" source="userSubscription.start_date" timeHidden emptyText="N/A" />
        <CustomDateField label="Subscription end date" source="userSubscription.renew_date" timeHidden emptyText="N/A" />
        <CustomDateField label="Last invoice payment date" source="lastInvoicePaymentDate" timeHidden />

        <CustomDateField label="Created date" source="cuetvCustomer.account_created_at" timeHidden />
        <TextField label="Signup device" source="cuetvCustomer.signup_device" />
        <TextField label="Country" source="cuetvCustomer.country_reference" />
        <CustomDateField label="Subscription start date" source="cuetvCustomer.cuetvSubscription.start_date" timeHidden emptyText="N/A" />
        <CustomDateField label="Subscription end date" source="cuetvCustomer.cuetvSubscription.renew_date" timeHidden emptyText="N/A" />
      </SubHeaderDatagrid>
    </List>
  );
};

export const UserCueTVCustomersShow = (props) => (
  <Show {...props} title={<Breadcrumbs {...props} />}>
    <SimpleShowLayout>
      <TextField label="User ID" source="id" />
      <FunctionField label="O-name (C-name)" render={(user) => `${user.name || '#Empty'} (${user.cuetvCustomer?.name || '#Empty'})`} />
      <FunctionField label="Email" render={(user) => <Link to={{ pathname: `/users/${user.id}/show` }}>{user.email}</Link>} />

      <CustomDateField label="O-Last invoice payment date" source="lastInvoicePaymentDate" />

      <TextField label="Action" source="cuetvCustomer.last_action" />
      <TextField label="Action property" source="cuetvCustomer.last_action_type" />

      <TextField label="Campaign" source="cuetvCustomer.campaign" />
      <TextField label="Referrer" source="cuetvCustomer.referrer" />

      <TextField label="Signup device" source="cuetvCustomer.signup_device" />
      <TextField label="Last login device" source="cuetvCustomer.last_login_device" />
      <TextField label="Country reference" source="cuetvCustomer.country_reference" />
      <TextField label="State reference" source="cuetvCustomer.state_reference" />
      <TextField label="City reference" source="cuetvCustomer.city_reference" />
      <BooleanField label="Marketing opt in" source="cuetvCustomer.marketing_opt_in" />
      <CustomDateField label="Last event at" source="cuetvCustomer.account_last_event_at" />
      <CustomDateField label="Account created at" source="cuetvCustomer.account_created_at" />
    </SimpleShowLayout>
  </Show>
);

export const CueTVCustomersList = (props) => (
  <List exporter={false} {...props} title={<Breadcrumbs {...props} />} bulkActionButtons={false} sort={{ field: 'date', order: 'DESC' }} perPage={25} filters={<CueTVCustomersFilter />}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <FunctionField label="Email" render={(customer) => <UserLink customer={customer} />} />
      <TextField label="Signup device" source="signup_device" />
      <TextField label="Country reference" source="country_reference" />
      <CustomDateField label="Account created" source="account_created_at" />
      <CustomDateField label="C-subscription start date" source="cuetvSubscription.start_date" emptyText="N/A" />
      <CustomDateField label="C-subscription end date" source="cuetvSubscription.renew_date" emptyText="N/A" />
      <CustomDateField label="O-subscription start date" source="userSubscription.start_date" emptyText="N/A" />
      <CustomDateField label="O-subscription end date" source="userSubscription.renew_date" emptyText="N/A" />
    </Datagrid>
  </List>
);

export const CueTVCustomersShow = (props) => (
  <Show {...props} title={<Breadcrumbs {...props} />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <FunctionField label="Email" render={(customer) => <UserLink customer={customer} />} />

      <TextField label="Action" source="last_action" />
      <TextField label="Action property" source="last_action_type" />

      <TextField source="campaign" />
      <TextField source="referrer" />

      <TextField source="signup_device" />
      <TextField source="last_login_device" />
      <TextField source="country_reference" />
      <TextField source="state_reference" />
      <TextField source="city_reference" />
      <BooleanField source="marketing_opt_in" />
      <CustomDateField source="account_last_event_at" />
      <CustomDateField source="account_created_at" />
    </SimpleShowLayout>
  </Show>
);

export const CueTVSubscriptionsList = (props) => (
  <List exporter={false} {...props} title={<Breadcrumbs {...props} />} bulkActionButtons={false} sort={{ field: 'date', order: 'DESC' }} perPage={25} filters={<CueTVSubscriptionsFilter />}>
    <Datagrid rowClick="show">
      <TextField source="id" />

      <TextField label="Customer name" source="cuetvCustomer.name" />
      <FunctionField label="Email" render={(subscription) => <UserLink customer={subscription.cuetvCustomer} />} />
      <TextField label="Customer country" source="cuetvCustomer.country.name" />

      <TextField source="product_type" />
      <TextField source="subscription_type" />
      <TextField source="subscription_status" />
      <CustomDateField source="subscription_start_date" />
      <CustomDateField source="subscription_renew_date" />
    </Datagrid>
  </List>
);

export const CueTVSubscriptionsShow = (props) => (
  <Show {...props} title={<Breadcrumbs {...props} />}>
    <SimpleShowLayout>
      <TextField source="id" />

      <FunctionField
        label="Customer name"
        render={(subscription) => (subscription.cuetvCustomer ? <Link to={`/cuetv/customers/all/${subscription.cuetvCustomer.id}/show`}>{subscription.cuetvCustomer.name}</Link> : 'Empty')}
      />
      <FunctionField label="Email" render={(subscription) => <UserLink customer={subscription.cuetvCustomer} />} />
      <TextField label="Customer country" source="cuetvCustomer.country.name" />

      <TextField source="product_type" />
      <TextField source="subscription_type" />
      <TextField source="subscription_status" />
      <CustomDateField source="subscription_start_date" />
      <CustomDateField source="subscription_renew_date" />
    </SimpleShowLayout>
  </Show>
);

export default CueTVCustomersList;
