export function searchToObject(search) {
  const pairs = search.substring(1).split('&');
  const obj = {};
  let pair;
  let i;

  // eslint-disable-next-line no-restricted-syntax
  for (i in pairs) {
    // eslint-disable-next-line no-continue
    if (pairs[i] === '') continue;

    pair = pairs[i].split('=');
    obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
  }

  return obj;
}
