import { get } from 'lodash';
import { PROFILE_TYPE_IDS } from './profileTypes';

export const SUBSCRIPTION_STATUS = {
  NON_RENEWING: 'non_renewing',
  CANCELLED: 'cancelled',
  ACTIVE: 'active',
  FUTURE: 'future',
  DRAFT: 'draft',
  IN_TRIAL: 'in_trial',
};

export const SUBSCRIPTION_STATUSES = [
  {
    id: SUBSCRIPTION_STATUS.ACTIVE,
    name: 'Active',
  },
  {
    id: SUBSCRIPTION_STATUS.CANCELLED,
    name: 'Cancelled',
  },
  {
    id: SUBSCRIPTION_STATUS.FUTURE,
    name: 'Future',
  },
  {
    id: SUBSCRIPTION_STATUS.NON_RENEWING,
    name: 'Non renewing',
  },
  {
    id: SUBSCRIPTION_STATUS.IN_TRIAL,
    name: 'In trial',
  },
];

export const TRIAL_OPTIONS = {
  DAYS: [0, 7, 14],
  MONTHS: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
};

export const currencySymbols = {
  AED: 'د.إ',
  AFN: '؋',
  ALL: 'L',
  AMD: '֏',
  ANG: 'ƒ',
  AOA: 'Kz',
  ARS: '$',
  AUD: '$',
  AWG: 'ƒ',
  AZN: '₼',
  BAM: 'KM',
  BBD: '$',
  BDT: '৳',
  BGN: 'лв',
  BHD: '.د.ب',
  BIF: 'FBu',
  BMD: '$',
  BND: '$',
  BOB: '$b',
  BRL: 'R$',
  BSD: '$',
  BTC: '฿',
  BTN: 'Nu.',
  BWP: 'P',
  BYR: 'Br',
  BYN: 'Br',
  BZD: 'BZ$',
  CAD: '$',
  CDF: 'FC',
  CHF: 'CHF',
  CLP: '$',
  CNY: '¥',
  COP: '$',
  CRC: '₡',
  CUC: '$',
  CUP: '₱',
  CVE: '$',
  CZK: 'Kč',
  DJF: 'Fdj',
  DKK: 'kr',
  DOP: 'RD$',
  DZD: 'دج',
  EEK: 'kr',
  EGP: '£',
  ERN: 'Nfk',
  ETB: 'Br',
  ETH: 'Ξ',
  EUR: '€',
  FJD: '$',
  FKP: '£',
  GBP: '£',
  GEL: '₾',
  GGP: '£',
  GHC: '₵',
  GHS: 'GH₵',
  GIP: '£',
  GMD: 'D',
  GNF: 'FG',
  GTQ: 'Q',
  GYD: '$',
  HKD: '$',
  HNL: 'L',
  HRK: 'kn',
  HTG: 'G',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  IMP: '£',
  INR: '₹',
  IQD: 'ع.د',
  IRR: '﷼',
  ISK: 'kr',
  JEP: '£',
  JMD: 'J$',
  JOD: 'JD',
  JPY: '¥',
  KES: 'KSh',
  KGS: 'лв',
  KHR: '៛',
  KMF: 'CF',
  KPW: '₩',
  KRW: '₩',
  KWD: 'KD',
  KYD: '$',
  KZT: 'лв',
  LAK: '₭',
  LBP: '£',
  LKR: '₨',
  LRD: '$',
  LSL: 'M',
  LTC: 'Ł',
  LTL: 'Lt',
  LVL: 'Ls',
  LYD: 'LD',
  MAD: 'MAD',
  MDL: 'lei',
  MGA: 'Ar',
  MKD: 'ден',
  MMK: 'K',
  MNT: '₮',
  MOP: 'MOP$',
  MRO: 'UM',
  MRU: 'UM',
  MUR: '₨',
  MVR: 'Rf',
  MWK: 'MK',
  MXN: '$',
  MYR: 'RM',
  MZN: 'MT',
  NAD: '$',
  NGN: '₦',
  NIO: 'C$',
  NOK: 'kr',
  NPR: '₨',
  NZD: '$',
  OMR: '﷼',
  PAB: 'B/.',
  PEN: 'S/.',
  PGK: 'K',
  PHP: '₱',
  PKR: '₨',
  PLN: 'zł',
  PYG: 'Gs',
  QAR: '﷼',
  RMB: '￥',
  RON: 'lei',
  RSD: 'Дин.',
  RUB: '₽',
  RWF: 'R₣',
  SAR: '﷼',
  SBD: '$',
  SCR: '₨',
  SDG: 'ج.س.',
  SEK: 'kr',
  SGD: '$',
  SHP: '£',
  SLL: 'Le',
  SOS: 'S',
  SRD: '$',
  SSP: '£',
  STD: 'Db',
  STN: 'Db',
  SVC: '$',
  SYP: '£',
  SZL: 'E',
  THB: '฿',
  TJS: 'SM',
  TMT: 'T',
  TND: 'د.ت',
  TOP: 'T$',
  TRL: '₤',
  TRY: '₺',
  TTD: 'TT$',
  TVD: '$',
  TWD: 'NT$',
  TZS: 'TSh',
  UAH: '₴',
  UGX: 'USh',
  USD: '$',
  UYU: '$U',
  UZS: 'лв',
  VEF: 'Bs',
  VND: '₫',
  VUV: 'VT',
  WST: 'WS$',
  XAF: 'FCFA',
  XBT: 'Ƀ',
  XCD: '$',
  XOF: 'CFA',
  XPF: '₣',
  YER: '﷼',
  ZAR: 'R',
  ZWD: 'Z$',
};

export const productCurrency = (currency) => get(currencySymbols, currency);

export const SUBSCRIPTION_SOURCES = [
  { id: 'Agency Roster', name: 'Agency roster (Artist subscriptions)' },
  { id: 'Manual Subscription', name: 'Manual subscription (Artist drop off)' },
  { id: 'Special Offer', name: 'Special offer' },
  { id: 'Hubspot Deal', name: 'Hubspot deal' },
  { id: 'Customer Support', name: 'Customer support' },
  { id: 'Other', name: 'Other' },
];

export const PRODUCT_IDS = {
  AOS_PRO_MONTHLY: 2,
  AOS_PRO_ANNUALLY: 50,
  CASTING_TOOL: 53,
  PRO_AGENT: 4,
  AGENCY_ROSTER_FREE: 52,
  ARTIST_PRO_MONTHLY: 5,
  ARTIST_PRO_ANNUALLY: 6,
  PRO_PRO: 51,
};

export const PRODUCT_IDS_BY_PROFILE_TYPE = {
  [PROFILE_TYPE_IDS.ARTIST]: [PRODUCT_IDS.ARTIST_PRO_ANNUALLY, PRODUCT_IDS.ARTIST_PRO_MONTHLY, PRODUCT_IDS.CASTING_TOOL],
  [PROFILE_TYPE_IDS.AGENT]: [PRODUCT_IDS.PRO_AGENT, PRODUCT_IDS.AGENCY_ROSTER_FREE],
  [PROFILE_TYPE_IDS.AOS]: [PRODUCT_IDS.AOS_PRO_MONTHLY, PRODUCT_IDS.AOS_PRO_ANNUALLY, PRODUCT_IDS.PRO_PRO],
  [PROFILE_TYPE_IDS.AUDIENCE]: [PRODUCT_IDS.CASTING_TOOL],
  [PROFILE_TYPE_IDS.PRO]: [PRODUCT_IDS.CASTING_TOOL],
  [PROFILE_TYPE_IDS.CASTING]: [PRODUCT_IDS.CASTING_TOOL],
  [PROFILE_TYPE_IDS.ACADEMICS]: [PRODUCT_IDS.CASTING_TOOL],
  [PROFILE_TYPE_IDS.JOURNALIST_MEDIA]: [PRODUCT_IDS.CASTING_TOOL],
  [PROFILE_TYPE_IDS.OTHER]: [PRODUCT_IDS.CASTING_TOOL],
  [PROFILE_TYPE_IDS.LEGACY]: [PRODUCT_IDS.CASTING_TOOL],
};
