import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DateTimeInput } from 'ra-ui-materialui';
import { dateParserCurrent } from '../../utils/dateParser';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    width: '100%',
    padding: '0 12px',
  },
  display: {
    position: 'absolute',
    left: 10,
    bottom: 5,
    width: '100%',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.9rem',
  },
  input: {
    width: '100%',
    border: '0',
    height: '1.1876em',
    margin: '0',
    display: 'block',
    padding: '6px 0 7px',
    background: 'none',
    boxSizing: 'content-box',
    animationDuration: '10ms',
    paddingTop: '23px',
    paddingBottom: '6px',
    color: 'transparent',
    outline: 'none',
  },
}));

const InputComponent = ({ defaultValue, inputRef, ...props }) => {
  const classes = useStyles();
  const [value, setValue] = useState(() => props.value || defaultValue);

  const handleChange = (event) => {
    setValue(event.target.value);
    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.display}>{dateParserCurrent(value, 'YYYY-MM-DD HH:MM')}</div>
      <input ref={inputRef} {...props} onChange={handleChange} value={value} className={classes.input} />
    </div>
  );
};

const CustomDateTimeInput = (props) => (
  <DateTimeInput
    {...props}
    options={{
      InputProps: {
        inputComponent: InputComponent,
      },
    }}
  />
);
export default CustomDateTimeInput;
