import { Button, ButtonGroup } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { List, Datagrid, TextField, SimpleShowLayout, Show, FunctionField, useMutation, Confirm, useNotify, useDataProvider } from 'react-admin';
import { Breadcrumbs } from '../components/Breadcrumbs';
import CustomDateField from '../components/customFields/CustomDateField';

export const UnbilledChargesDataGrid = () => {
  const notify = useNotify();
  const [invoiceNowChargeId, setInvoiceNowChargeId] = useState();
  const dataProvider = useDataProvider();
  const [estimatedInfo, setEstimatedInfo] = useState({});
  const estimatedRef = useRef({});

  const [invoiceNow, { loading }] = useMutation(
    {
      type: 'create',
      resource: 'users/subscriptions/unbilledCharges/invoice',
      payload: { data: { id: invoiceNowChargeId } },
    },
    {
      onSuccess: () => notify('Invoice created', 'info'),
      onFailure: (err) => notify(err.message, 'error'),
    },
  );

  const requestEstimate = (id, item) => {
    estimatedRef.current = { ...estimatedRef.current, [id]: { loading: true } };
    setEstimatedInfo({ ...estimatedRef.current, [id]: { loading: true } });
    dataProvider
      .create('users/subscriptions/estimate', {
        data: {
          user: { id: item.user.id },
          product: { id: item.subscription.product.id },
        },
      })
      .then((res) => {
        estimatedRef.current = { ...estimatedRef.current, [id]: { ...res.data, loading: false } };
        setEstimatedInfo({ ...estimatedRef.current });
      })
      .catch((error) => {
        notify(error.message, 'error');
      });
  };

  return (
    <>
      <Confirm
        isOpen={!!invoiceNowChargeId}
        title="Invoice now"
        content="Create invoice for unbilled charges"
        onClose={() => setInvoiceNowChargeId(null)}
        onConfirm={() => {
          invoiceNow();
          setInvoiceNowChargeId(null);
        }}
      />

      <Datagrid rowClick="show">
        <TextField label="Charge ID" source="id" />
        <TextField source="description" />
        <TextField source="pricing_model" />
        <FunctionField
          label="Amount"
          render={(unbilledCharge) => {
            const amount = parseFloat(unbilledCharge?.amount?.amount);
            if (amount == null) {
              return '(Empty)';
            }
            const discountAmount = parseFloat(unbilledCharge?.discount_amount?.amount);
            if (discountAmount) {
              return `€${(amount - discountAmount).toFixed(2)} (€${amount.toFixed(2)}x${unbilledCharge?.quantity || 1} - Discount €${discountAmount.toFixed(2)})`;
            }
            return `€${amount.toFixed(2)}`;
          }}
        />
        <FunctionField label="Unit amount" render={(unbilledCharge) => `€${parseFloat(unbilledCharge?.unit_amount?.amount).toFixed(2)}`} />
        <FunctionField
          // label="Estimated value amount"
          render={(unbilledCharge) => {
            if (!estimatedInfo[unbilledCharge.id] && !estimatedRef.current[unbilledCharge.id]) {
              requestEstimate(unbilledCharge.id, unbilledCharge.userSubscription);
            }
            return null;
            // Hiding field until estimates are fixed. They are invalid atm.
            // return !estimatedInfo[unbilledCharge.id] || estimatedInfo[unbilledCharge.id].loading ? 'calculating...' : `€${estimatedInfo[unbilledCharge.id].currentInvoice.total.amount}`;
          }}
        />

        <FunctionField
          label="Tax amount"
          render={(unbilledCharge) =>
            !estimatedInfo[unbilledCharge.id] || estimatedInfo[unbilledCharge.id].loading ? 'calculating...' : `€${estimatedInfo[unbilledCharge.id].currentInvoice.vatAmount.amount}`
          }
        />
        <CustomDateField source="date_from" timeHidden />
        <CustomDateField source="date_to" timeHidden />
        <FunctionField
          label="Actions"
          render={(unbilledCharge) => (
            <>
              <ButtonGroup variant="text" disabled={loading}>
                <Button
                  color="primary"
                  onClick={(e) => {
                    setInvoiceNowChargeId(unbilledCharge.id);
                    e.stopPropagation();
                  }}
                >
                  Invoice now
                </Button>
              </ButtonGroup>
            </>
          )}
        />
      </Datagrid>
    </>
  );
};

export const UnbilledChargesShow = (props) => (
  <Show {...props} title={<Breadcrumbs {...props} />}>
    <SimpleShowLayout>
      <TextField label="Charge ID" source="id" />
      <TextField source="description" />
      <TextField source="pricing_model" />
      <FunctionField label="Amount" render={(unbilledCharge) => `€${parseFloat(unbilledCharge?.amount?.amount).toFixed(2)}`} />
      <FunctionField label="Unit amount" render={(unbilledCharge) => `€${parseFloat(unbilledCharge?.unit_amount?.amount).toFixed(2)}`} />
      <CustomDateField source="date_from" timeHidden />
      <CustomDateField source="date_to" timeHidden />
    </SimpleShowLayout>
  </Show>
);

export const UnbilledChargesList = (props) => (
  <List exporter={false} {...props} title={<Breadcrumbs {...props} />} sort={{ field: 'id', order: 'DESC' }} bulkActionButtons={false}>
    <UnbilledChargesDataGrid />
  </List>
);
