import React, { useState, useEffect } from 'react';
import * as dayjs from 'dayjs';
import classNames from 'classnames';
import { FormControl, TextField, MenuItem, FormControlLabel, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Title, Loading, useNotify } from 'react-admin';
import { get, map, orderBy, reduce, mapKeys, mapValues } from 'lodash';
import { useHistory } from 'react-router-dom';
import { ArrowDropUp } from '@material-ui/icons';
import { Breadcrumbs } from './Breadcrumbs';
import ComposeChart from './charts/ComposeChart';
import BarChart from './charts/BarChart';
import UserMetricsTable from './UserMetricsTable';
import ChartDataModal from './ChartDataModal';
import ArtistFunnelModal from './ArtistFunnelModal';
import { getChartData, getUserMetrics } from '../utils/userMetrics';
import apiClient from '../utils/api';

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
const advanced = require('dayjs/plugin/advancedFormat');

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',

    '&>div': {
      width: 500,
      minWidth: 300,
      minHeight: 350,
      maxHeight: 350,
      margin: '10px',
      backgroundColor: '#fff',
      borderRadius: 4,
      boxShadow: '0px 1px 22px -12px #607d8b',
      padding: '0 20px',
      resize: 'both',
      overflow: 'hidden',
    },

    '& :nth-child(1)': { order: 1 },
    '& :nth-child(2)': { order: 2 },
    '& :nth-child(3)': { order: 3 },
    '& :nth-child(n+4)': { order: 5 },
    '& :last-child': { order: 4 },
  },
  tabs: {
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    borderRadius: 4,
  },
  filterSection: {
    display: 'flex',
    flexDirection: 'row',
  },
  spacingLeft: { marginLeft: 10, minWidth: 140 },
  chartsFullWidth: {
    '&>div': {
      width: '100%',
    },
  },
  chartHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  arrowPositive: {
    fontSize: 48,
    fill: '#77CC77',
  },
  totalSection: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 'bold',
    color: 'rgb(29, 155, 240)',
  },
}));

const METRICS_TABS = [
  { label: 'Registration', value: 'registration' },
  { label: 'Subscription', value: 'subscription' },
  { label: 'Artist', value: 'artist' },
  { label: 'AOS', value: 'aos' },
  { label: 'Agency', value: 'agency' },
  { label: 'Academia, Journalist and Casting', value: 'academia_journalist_casting' },
  { label: 'All metrics', value: 'all_metrics' },
];

const METRICS_SHOW = {
  registration: 'metrics,registrations',
  subscription: 'metrics,newUsers,trialExpiry,cohort,cancellation',
  artist: 'metrics,registrations,newUsers,trialExpiry,artistMetrics,cohort',
  aos: 'metrics,registrations,newUsers,trialExpiry,artistMetrics,cohort',
  agency: 'metrics,registrations,newUsers,trialExpiry,artistMetrics,cohort',
  academia_journalist_casting: 'metrics,registrations,newUsers,trialExpiry,artistMetrics,cohort',
  all_metrics: 'metrics,registrations,newUsers,trialExpiry,artistMetrics,cohort,cancellation',
};

const DURATIONS = [
  { label: 'Yesterday', value: 'yesterday', fromDate: dayjs().add(-1, 'day').format('YYYY-MM-DD'), toDate: dayjs().add(-1, 'day').format('YYYY-MM-DD') },
  { label: 'Last 7 days', value: 'last_7_days', fromDate: dayjs().add(-6, 'day').format('YYYY-MM-DD'), toDate: dayjs().format('YYYY-MM-DD') },
  { label: 'Last 30 days', value: 'last_30_days', fromDate: dayjs().add(-30, 'day').format('YYYY-MM-DD'), toDate: dayjs().format('YYYY-MM-DD') },
  { label: 'Last 90 days', value: 'last_90_days', fromDate: dayjs().add(-90, 'day').format('YYYY-MM-DD'), toDate: dayjs().format('YYYY-MM-DD') },
  { label: 'This month', value: 'this_month', fromDate: dayjs().startOf('month').format('YYYY-MM-DD'), toDate: dayjs().format('YYYY-MM-DD') },
  { label: 'Last month', value: 'last_month', fromDate: dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'), toDate: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD') },
];

const FREQUENCIES = [
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
];

const DATA_COLORS = {
  Freemium: '#F9CE1D',
  Premium: '#4CAF50',
  Churned: '#FF4560',
};

const UserMetrics = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const notify = useNotify();

  const searchParams = new URLSearchParams(history.location.search);
  const defaultTab = METRICS_TABS.find((tab) => tab.value === searchParams?.get('metrics'));
  const defaultDuration = DURATIONS.find((duration) => duration.value === searchParams?.get('duration'));
  const defaultFrequency = FREQUENCIES.find((frequency) => frequency.value === searchParams?.get('frequency'));
  const defaultDateRange = { from_date: searchParams?.get('from_date'), to_date: searchParams?.get('to_date') };
  const defaultIsCumulative = searchParams?.get('is_cumulative') === 'true';

  const [tab, setTab] = useState(defaultTab || METRICS_TABS[0]);
  const [data, setData] = useState([]);
  const [artistFunnelData, setArtistFunnelData] = useState([]);
  const [metrics, setMetrics] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [duration, setDuration] = useState(defaultDuration || DURATIONS[1]);
  const [selectedDateRange, setSelectedDateRange] = useState({ from_date: defaultDateRange?.from_date || duration?.fromDate, to_date: defaultDateRange?.to_date || duration?.toDate });
  const [frequency, setFrequency] = useState(defaultFrequency || FREQUENCIES[0]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [activeChartData, setActiveChartData] = useState({});
  const [isCumulative, setIsCumulative] = useState(defaultIsCumulative);
  const hideCount = dayjs(selectedDateRange.to_date).diff(selectedDateRange.from_date, 'day') > 30 && frequency.value === 'daily';
  const [artistSubscriptionData, setArtistSubscriptionData] = useState([]);
  const [isOpenArtistFunnelModal, setIsOpenArtistFunnelModal] = useState(false);

  useEffect(() => {
    document.title = 'Users metrics';
  }, []);

  useEffect(() => {
    setLoading(true);
    apiClient
      .get('metrics/user-metrics', {
        params: { from_date: selectedDateRange?.from_date, to_date: selectedDateRange?.to_date, show: METRICS_SHOW[tab?.value], metrics: tab?.value !== 'all_metrics' ? 'time-series' : '' },
      })
      .then((res) => {
        setLoading(false);
        const regData =
          tab?.value !== 'all_metrics'
            ? mapValues(res?.data, (value) => {
              if (value.registration_steps) {
                // eslint-disable-next-line no-param-reassign
                value.registration_steps = mapKeys(value.registration_steps, (stepValue, stepKey) => stepKey.replace(/\./g, '_'));
              }
              return value;
            })
            : res?.data;

        const chartData = tab?.value !== 'all_metrics' ? getChartData({ data: regData, frequency: frequency?.value, isCumulative }) : res?.data;
        setData(chartData);
      })
      .catch((err) => {
        setLoading(false);
        notify(err?.response?.data?.message, 'warning');
      });

    if ([METRICS_TABS[2]?.value, METRICS_TABS[3]?.value, METRICS_TABS[4]?.value, METRICS_TABS[5]?.value].includes(tab?.value)) {
      apiClient
        .get('metrics/artist-funnel-metrics', {
          params: { from_date: selectedDateRange?.from_date, to_date: selectedDateRange?.to_date },
        })
        .then((res) => {
          const chartData = getChartData({ data: res?.data, frequency: frequency?.value, isCumulative });
          setArtistFunnelData(chartData);
        })
        .catch((err) => {
          notify(err?.response?.data?.message, 'warning');
        });
    }

    if (tab?.value === METRICS_TABS[2]?.value) {
      apiClient
        .get('metrics/dashboard-metrics', { params: { from_date: duration?.fromDate, to_date: duration?.toDate } })
        .then((res) => {
          setLoading(false);
          const chartData = orderBy(
            map(res?.data, (value, key) => ({ name: key, ...value })),
            'name',
            'asc',
          );
          const updatedData = chartData?.map((item) => ({
            name: item?.name,
            artist_subscription_metrics: {
              ...item?.artist_subscription_metrics,
              churned: item?.artist_subscription_metrics?.churned * -1,
            },
          }));
          setArtistSubscriptionData(updatedData);
        })
        .catch((err) => {
          setLoading(false);
          notify(err?.response?.data?.message, 'warning');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, frequency, selectedDateRange, isCumulative]);

  useEffect(() => {
    if (duration) {
      setSelectedDateRange({ from_date: duration?.fromDate, to_date: duration?.toDate });
    }
  }, [duration]);

  useEffect(() => {
    if (data) {
      const metricsData = getUserMetrics();
      setMetrics(metricsData);
    }
  }, [data]);

  useEffect(() => {
    searchParams.set('duration', duration?.value);
    searchParams.set('frequency', frequency?.value);
    searchParams.set('metrics', tab?.value);
    searchParams.set('from_date', selectedDateRange?.from_date);
    searchParams.set('to_date', selectedDateRange?.to_date);
    searchParams.set('is_cumulative', isCumulative);
    const newSearch = searchParams.toString();
    history.push({ search: newSearch });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration, frequency, tab, selectedDateRange, isCumulative]);

  const handleBarClick = (chartData, title) => {
    if (!chartData) return;

    if (title === 'Artist funnel') {
      setIsOpenArtistFunnelModal(true);
    } else {
      setIsOpenModal(true);
    }
    setActiveChartData({ ...chartData, title });
  };

  const renderTab = () => {
    if (tab?.value === METRICS_TABS[1]?.value) {
      return (
        <div className={classNames(classes.container, { [classes.chartsFullWidth]: duration?.value !== 'last_7_days' })}>
          {metrics?.subscriptionMetrics?.map((value) => (
            <div>
              <h4>{value?.title}</h4>
              <BarChart data={data} dataKeys={value?.dataKeys} hideCount={hideCount} onClick={(cData) => handleBarClick(cData, value?.title)} />
            </div>
          ))}
        </div>
      );
    }

    if (tab?.value === METRICS_TABS[2]?.value) {
      const total = artistSubscriptionData?.[artistSubscriptionData?.length - 1]?.artist_subscription_metrics?.total;
      const dataKeys = [
        {
          key: 'artist_subscription_metrics.freemium',
          name: 'Freemium',
        },
        {
          key: 'artist_subscription_metrics.premium',
          name: 'Premium',
        },
        {
          key: 'artist_subscription_metrics.churned',
          name: 'Churned',
        },
      ];
      const childKeys = map(dataKeys, 'key');
      const totalDisplayOnChart = reduce(
        childKeys,
        (acc, childKey) => {
          const count = reduce(artistSubscriptionData, (a, item) => a + get(item, childKey), 0);
          return acc + count;
        },
        0,
      );

      return (
        <div className={classNames(classes.container, { [classes.chartsFullWidth]: duration?.value !== 'last_7_days' })}>
          {metrics?.artistFunnelMetrics?.map((value) => (
            <div>
              <h4>{value?.title}</h4>
              {value?.composeChart ? (
                <ComposeChart
                  isMultiAxis={value?.isMultiAxis}
                  dataKeys={value?.dataKeys || []}
                  data={artistFunnelData}
                  lines={value?.lines}
                  showLineLabel
                  onClick={(cData) => handleBarClick(cData, value?.title)}
                />
              ) : (
                <BarChart data={artistFunnelData} dataKeys={value?.dataKeys} hideCount={hideCount} onClick={(cData) => handleBarClick(cData, value?.title)} />
              )}
            </div>
          ))}
          <div>
            <div className={classes.chartHeader}>
              <h4>Artist subscription</h4>
              {total > 0 ? (
                <p className={classes.totalSection}>
                  Total: {total} <ArrowDropUp className={classes.arrowPositive} />
                  <div className={classes.caption}>+{totalDisplayOnChart}</div>
                </p>
              ) : null}
            </div>
            <ComposeChart
              data={artistSubscriptionData}
              dataKeys={dataKeys}
              lines={[
                {
                  name: 'Total',
                  key: 'artist_subscription_metrics.total',
                  fill: '#ff7300',
                  stroke: '#ff7300',
                },
              ]}
              dataColors={DATA_COLORS}
              margin={{ bottom: 10 }}
              isMultiAxis
            />
          </div>
        </div>
      );
    }

    if (tab?.value === METRICS_TABS[3]?.value) {
      return (
        <div className={classNames(classes.container, { [classes.chartsFullWidth]: duration?.value !== 'last_7_days' })}>
          {metrics?.aosFunnelMetrics?.map((value) => (
            <div>
              <h4>{value?.title}</h4>
              {value?.composeChart ? (
                <ComposeChart
                  isMultiAxis={value?.isMultiAxis}
                  dataKeys={value?.dataKeys || []}
                  data={artistFunnelData}
                  lines={value?.lines}
                  showLineLabel
                  onClick={(cData) => handleBarClick(cData, value?.title)}
                />
              ) : (
                <BarChart data={artistFunnelData} dataKeys={value?.dataKeys} hideCount={hideCount} onClick={(cData) => handleBarClick(cData, value?.title)} />
              )}
            </div>
          ))}
        </div>
      );
    }

    if (tab?.value === METRICS_TABS[4]?.value) {
      return (
        <div className={classNames(classes.container, { [classes.chartsFullWidth]: duration?.value !== 'last_7_days' })}>
          {metrics?.agencyFunnelMetrics?.map((value) => (
            <div>
              <h4>{value?.title}</h4>
              {value?.composeChart ? (
                <ComposeChart
                  isMultiAxis={value?.isMultiAxis}
                  dataKeys={value?.dataKeys || []}
                  data={artistFunnelData}
                  lines={value?.lines}
                  showLineLabel
                  onClick={(cData) => handleBarClick(cData, value?.title)}
                />
              ) : (
                <BarChart data={artistFunnelData} dataKeys={value?.dataKeys} hideCount={hideCount} onClick={(cData) => handleBarClick(cData, value?.title)} />
              )}
            </div>
          ))}
        </div>
      );
    }

    if (tab?.value === METRICS_TABS[5]?.value) {
      return (
        <div className={classNames(classes.container, { [classes.chartsFullWidth]: duration?.value !== 'last_7_days' })}>
          {metrics?.academiaFunnelMetrics?.map((value) => (
            <div>
              <h4>{value?.title}</h4>
              {value?.composeChart ? (
                <ComposeChart
                  isMultiAxis={value?.isMultiAxis}
                  dataKeys={value?.dataKeys || []}
                  data={artistFunnelData}
                  lines={value?.lines}
                  showLineLabel
                  onClick={(cData) => handleBarClick(cData, value?.title)}
                />
              ) : (
                <BarChart data={artistFunnelData} dataKeys={value?.dataKeys} hideCount={hideCount} onClick={(cData) => handleBarClick(cData, value?.title)} />
              )}
            </div>
          ))}
        </div>
      );
    }

    if (tab?.value === METRICS_TABS[6]?.value) {
      return <UserMetricsTable data={data} />;
    }

    return (
      <div className={classNames(classes.container, { [classes.chartsFullWidth]: duration?.value !== 'last_7_days' })}>
        {metrics?.registrationMetrics?.map((value) => {
          if (!value.dynamicKeys) {
            return value
          }
          const locationMetrics = data.map(d => d[value.keyPrefix])
          if (locationMetrics.length === 0) {
            return value
          }
          const locationMetricMap = locationMetrics.reduce((acc, item) => {
            Object.keys(item).forEach(key => {
              if (!acc[key]) {
                acc[key] = 0
              }
              acc[key] += item[key]
            })
            return acc
          }, {})
          const topNMetrics = Object.entries(locationMetricMap)
            .sort(([, a], [, b]) => b - a)
            .slice(0, 10)
            .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

          value.lines = Object.keys(topNMetrics).filter(x => locationMetricMap[x] > 0).map(key => ({ name: key, key: `${value.keyPrefix}['${key}']` }))
          return value
        }).map((value) => (
          <div>
            <h4>{value?.title}</h4>
            {value?.composeChart ? (
              <ComposeChart isMultiAxis={value?.isMultiAxis} dataKeys={value?.dataKeys || []} data={data} lines={value?.lines} showLineLabel onClick={(cData) => handleBarClick(cData, value?.title)} />
            ) : (
              <BarChart data={data} dataKeys={value?.dataKeys} hideCount={hideCount} onClick={(cData) => handleBarClick(cData, value?.title)} />
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <Title title={<Breadcrumbs {...props} />} />
      <div>
        <FormControl className={classes.filterSection} size="small">
          <TextField className={classes.spacingLeft} value={tab} select onChange={(e) => setTab(e?.target?.value)} label="Metrics" variant="filled" size="small">
            {METRICS_TABS.map((option) => (
              <MenuItem key={option.value} value={option}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField className={classes.spacingLeft} value={duration} select onChange={(e) => setDuration(e?.target?.value)} label="Duration" variant="filled" size="small">
            {DURATIONS.map((option) => (
              <MenuItem key={option.value} value={option}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField className={classes.spacingLeft} value={frequency} select onChange={(e) => setFrequency(e?.target?.value)} label="Frequency" variant="filled" size="small">
            {FREQUENCIES.map((option) => (
              <MenuItem key={option.value} value={option}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <FormControlLabel
            className={classes.spacingLeft}
            control={<Switch checked={isCumulative} onChange={() => setIsCumulative(!isCumulative)} name="Cumulative" color="primary" />}
            label="Cumulative"
          />
          <TextField
            className={classes.spacingLeft}
            value={selectedDateRange.from_date}
            onChange={(e) => {
              setSelectedDateRange({ ...selectedDateRange, from_date: e?.target?.value });
              setDuration(null);
            }}
            type="date"
            label="From"
            variant="filled"
            size="small"
          />
          <TextField
            className={classes.spacingLeft}
            value={selectedDateRange.to_date}
            onChange={(e) => {
              setSelectedDateRange({ ...selectedDateRange, to_date: e?.target?.value });
              setDuration(null);
            }}
            type="date"
            label="To"
            variant="filled"
            size="small"
          />
        </FormControl>
      </div>
      {!isLoading ? renderTab() : <Loading />}
      <ChartDataModal open={isOpenModal} onClose={() => setIsOpenModal(false)} chartData={activeChartData} />
      <ArtistFunnelModal open={isOpenArtistFunnelModal} onClose={() => setIsOpenArtistFunnelModal(false)} chartData={activeChartData} />
    </div>
  );
};

export default UserMetrics;
