export const getAccessStatus = (employee) => {
  const { has_access, invitation } = employee;
  if (has_access) {
    switch (invitation) {
      case 'unavailable':
        return 'Unavailable';

      case 'accepted':
        return `Accepted`;

      case 'pending':
        return 'Pending';

      default:
        return `Access not shared`;
    }
  }
  return 'No access';
};
