import React, { useMemo, useState, useEffect } from 'react';
import { Loading, useDataProvider, Title, useNotify } from 'react-admin';
import cn from 'classnames';
import { makeStyles, FormControl, TextField, MenuItem } from '@material-ui/core';
import { AgGridReact } from 'ag-grid-react';
import { useHistory } from 'react-router-dom';

import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';

import { Breadcrumbs } from '../components/Breadcrumbs';
import { DATA_POINT_METRICS_GROUP } from '../constants/metrics';

const styles = () => ({
  filters: {
    display: 'flex',
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selectSection: {
    display: 'flex',
    flexDirection: 'row',
  },
  spacingLeft: { marginLeft: 10 },
  tooltip: {
    backgroundColor: 'white',
    padding: '3px 10px',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    borderRadius: 4,
  },
  dataGridContainer: {
    '& .ag-header-group-text': {
      textTransform: 'capitalize',
    },
    '& .ag-header-cell-label': {
      justifyContent: 'center',
    },
  },
  button: {
    color: '#ff5722',
  },
  icon: {
    fontSize: 20,
    paddingRight: 5,
  },
  select: {
    marginLeft: 10,
    width: 130,
  },
});

const CustomTooltip = (props) => {
  const classes = makeStyles(styles)();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = useMemo(() => props.api.getDisplayedRowAtIndex(props.rowIndex).data, []);

  return (
    <div className={classes.tooltip}>
      <p>{data?.action_user?.email}</p>
    </div>
  );
};

export const DatapointMetaList = (props) => {
  const classes = makeStyles(styles)();
  const history = useHistory();
  const notify = useNotify();

  const dataProvider = useDataProvider();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const searchParams = new URLSearchParams(history.location.search);
  const defaultMetricsGroup = searchParams?.get('metric_group');
  const [metricsGroup, setMetricsGroup] = useState(defaultMetricsGroup || '');

  const resource = 'system/datapoint_meta';

  const fetchData = () => {
    setIsLoading(true);
    dataProvider
      .getList(resource, { filter: { metric_group: metricsGroup }, pagination: {}, sort: {} })
      .then((res) => {
        setIsLoading(false);
        setData(res?.data);
      })
      .catch((err) => {
        setIsLoading(false);
        notify(err?.message, 'warning');
        setData([]);
      });
  };

  useEffect(() => {
    document.title = 'Datapoint Weightage';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    searchParams.set('metric_group', metricsGroup);
    const newSearch = searchParams.toString();
    history.push({ search: newSearch });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricsGroup]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricsGroup]);

  const columns = [
    {
      headerName: 'Key',
      field: 'key',
    },
    {
      headerName: 'Data Point',
      field: 'label',
    },
    {
      headerName: 'Metric Group',
      field: 'metric_group',
    },
    {
      headerName: 'Weightage',
      field: 'weightage',
    },
  ];

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      minWidth: 100,
      tooltipComponent: CustomTooltip,
      cellStyle: { 'padding-left': 0, 'border-right': '1px solid #babfc7' },
      align: 'middle',
    }),
    [],
  );

  return (
    <div>
      <Title title={<Breadcrumbs {...props} />} />
      <div className={classes.filters}>
        <FormControl className={classes.selectSection} size="small">
          <TextField
            style={{ width: 200 }}
            disabled={isLoading}
            className={classes.select}
            select
            size="small"
            variant="filled"
            label="Metrics group"
            defaultValue="production"
            value={metricsGroup}
            onChange={(e) => setMetricsGroup(e.target.value)}
          >
            {[{ id: '', name: 'All' }, ...DATA_POINT_METRICS_GROUP, { id: 'validation', name: 'Validation' }].map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </div>
      {!isLoading ? (
        <div className={cn('ag-theme-alpine', classes.dataGridContainer)} style={{ height: '85vh', paddingLeft: 10 }}>
          <AgGridReact columnDefs={columns} rowData={data} defaultColDef={defaultColDef} tooltipShowDelay={0} tooltipHideDelay={2000} />
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default DatapointMetaList;
