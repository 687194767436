export const WALLET_TYPES = {
  card: 'card',
  applePay: 'apple_pay',
  googlePay: 'google_pay',
  sepaDirectDebit: 'sepa/direct_debit',
  paypalEx: 'paypal/express_checkout',
  check: 'check',
  cash: 'cash',
  bankTransfer: 'bank_transfer',
  other: 'other',
};
