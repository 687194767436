import React from 'react';
import { get } from 'lodash';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { DoubleArrowSharp } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  label: {
    fontWeight: 'bold',
    marginRight: 8,
  },
  element: {
    padding: '2px 6px',
    margin: 0,
    border: '2px solid #FFF',
    borderRadius: 4,
  },
  from: {
    border: `1px solid #FFAAAA`,
    background: '#FFAAAA',
    color: '#440000',
  },
  to: {
    border: `1px solid #AAFFAA`,
    background: '#AAFFAA',
    color: '#004400',
  },
  arrow: {
    background: '#000',
    color: '#FFF',
    height: 23,
    margin: '0 -1px -6px -2px',
  },
}));

const clickElement = (data) => {
  navigator.clipboard.writeText(data);
};

const DisplayEntry = (props) => {
  const classes = useStyles();
  const { left, right } = props.change;
  const select = (val) => (props.select ? get(val, props.select) : val);

  if (select(left) && select(right)) {
    return (
      <>
        <span className={classNames(classes.element, classes.from)} onClick={(e) => clickElement(select(left))}>{`${select(left)}`}</span>
        <DoubleArrowSharp className={classes.arrow} />
        <span className={classNames(classes.element, classes.to)} onClick={(e) => clickElement(select(right))}>{`${select(right)}`}</span>
      </>
    );
  }

  if (select(right)) {
    if (Array.isArray(right)) {
      return right.map((r) => {
        return (
          <span className={classNames(classes.element, classes.to)} onClick={(e) => clickElement(select(r))}>
            {select(r)}
          </span>
        );
      });
    }

    if (props?.isURL) {
      return (
        <a href={get(right, props.filePath)} download target="_blank" rel="noopener noreferrer">
          {select(right)}
        </a>
      );
    }

    return (
      <span className={classNames(classes.element, classes.to)} onClick={(e) => clickElement(select(right))}>
        {select(right)}
      </span>
    );
  }

  if (select(left)) {
    return <span className={classNames(classes.element, classes.from)} onClick={(e) => clickElement(select(left))}>{`${select(left)}`}</span>;
  }

  return <></>;
};

const DisplayText = (props) => {
  const classes = useStyles();
  const { title, path, select } = props;

  const changes = props.changes.filter((item) => {
    return item.path.startsWith(path);
  });

  if (changes.length === 0) {
    return <></>;
  }

  const fields = [];

  changes.forEach((change, index) => {
    if (change.left || change.right) {
      fields.push(<DisplayEntry key={`${index}`} change={change} select={select} isURL={props.isURL} filePath={props.filePath} />);
    }
  });

  if (fields.length === 0) {
    return <></>;
  }

  return (
    <Typography variant="body2" gutterBottom>
      {title ? <label className={classes.label}>{title}:</label> : <></>}
      {fields}
    </Typography>
  );
};

export default DisplayText;
