import React from 'react';
import { NumberInput } from 'react-admin';

const LatLngInput = ({ disabled }) => (
  <span>
    <NumberInput name="lat" label="Latitude" disabled={disabled} />
    &nbsp;
    <NumberInput name="lon" label="Longitude" disabled={disabled} />
  </span>
);

export default LatLngInput;
