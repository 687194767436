export const REVIEW_ENTITIES = {
  WORK: 1,
  WORK_COMPOSER: 2,
  PRODUCTION: 3,
  PRODUCTION_DATE: 4,
  PRODUCTION_CAST: 5,
  PROFILE: 6,
  WORK_ROLE: 7,
  VENUE: 8,
  ORGANIZATION: 9,
};
