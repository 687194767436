import React from 'react';

import { Show, List, TabbedShowLayout, Filter, TextInput, Datagrid, TextField, Edit, SimpleForm, ReferenceInput, SelectInput, Tab, Toolbar, SaveButton, DeleteButton } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { withStyles } from '@material-ui/core';
import CustomDateField from '../components/customFields/CustomDateField';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const CustomToolbar = withStyles(toolbarStyles)((props) => (
  <Toolbar {...props}>
    <SaveButton />
    <DeleteButton undoable={false} />
  </Toolbar>
));

const PerformancesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="query" alwaysOn />
  </Filter>
);

const PerformanceshowName = ({ record }) => {
  return <span>Performances: {record ? `${record.name} ` : ''}</span>;
};

export const PerformancesShow = (props) => {
  return (
    <Show {...props} title={<PerformanceshowName />}>
      <TabbedShowLayout>
        <Tab label="Performance Info">
          <TextField source="id" />
          <TextField source="city.name" />
          <TextField source="profile.name" />
          <TextField source="profession.name" />
          <TextField source="producer.name" />
          <TextField source="conductor.name" />
          <TextField source="work.name" />
          <TextField source="venue.name" />
          <TextField source="date.date" />
          <CustomDateField source="createdAt" />
          <CustomDateField source="updatedAt" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const PerformancesEdit = (props) => (
  <Edit {...props} title={<PerformanceshowName />} toolbar={<CustomToolbar />}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="profileType.name" label="profile type" />
      <TextInput source="summary" />
      <RichTextInput source="description" />
      <ReferenceInput label="user" source="user.id" reference="users">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export const PerformancesList = (props) => (
  <List exporter={false} {...props} filters={<PerformancesFilter />} bulkActionButtons={false} sort={{ field: 'date', order: 'DESC' }} perPage={25}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="city.name" />
      <TextField source="profile.name" />
      <TextField source="profession.name" />
      <TextField source="producer.name" />
      <TextField source="conductor.name" />
      <TextField source="work.name" />
      <TextField source="venue.name" />
      <CustomDateField source="date.date" />
    </Datagrid>
  </List>
);

export default PerformancesList;
