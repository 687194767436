import { uniq, map, reduce, get, orderBy, union, keys, capitalize, join, split, omit } from 'lodash';

const getValidationType = (type, state) => {
  let identifier;
  let label;

  if (type === 'validationRequest') {
    if (state === 'created') {
      identifier = 'productionActionCreated';
      label = 'Production created';
    }
    if (state === 'approved') {
      identifier = 'productionActionAccepted';
      label = 'Production accepted';
    }
    if (state === 'rejected') {
      identifier = 'productionActionRejected';
      label = 'Production rejected';
    }
  }

  if (type === 'changeRequest') {
    if (state === 'created') {
      identifier = 'changeRequestCreated';
      label = 'Change request created';
    }
    if (state === 'approved') {
      identifier = 'changeRequestAccepted';
      label = 'Change request accepted';
    }
    if (state === 'rejected') {
      identifier = 'changeRequestRejected';
      label = 'Change request rejected';
    }
  }

  if (type === 'redMaskRequest') {
    if (state === 'created') {
      identifier = 'redMaskRequestCreated';
      label = 'Red mask request created';
    }
    if (state === 'approved') {
      identifier = 'redMaskRequestAccepted';
      label = 'Red mask request accepted';
    }
    if (state === 'rejected') {
      identifier = 'redMaskRequestRejected';
      label = 'Red mask request rejected';
    }
  }

  if (type === 'userProductionRequest') {
    if (state === 'created') {
      identifier = 'redMaskRequestCreated';
      label = 'User production request created';
    }
    if (state === 'approved') {
      identifier = 'userProductionRequestAccepted';
      label = 'User production request accepted';
    }
    if (state === 'rejected') {
      identifier = 'userProductionRequestRejected';
      label = 'User production request rejected';
    }
  }

  return { identifier, label };
};

export const getEmailMetrics = ({ data, dataKey }) => {
  const commonMetrics = [
    {
      label: 'Total',
      value: data?.[`${dataKey}_total`],
    },
    {
      label: 'Registered with email',
      value: data?.[`${dataKey}_reg_with_email`],
    },
    {
      label: 'Not registered with email',
      value: data?.[`${dataKey}_nreg_with_email`],
    },
    {
      label: 'Changed last 7 days',
      value: data?.[`${dataKey}_changed_last_7`],
    },
    {
      label: 'Changed last 30 days',
      value: data?.[`${dataKey}_changed_last_30`],
    },
  ];

  const daysMetrics = [
    {
      label: 'Sent',
      value_reg7: data?.[`metrics_reg7_${dataKey}_sent`],
      value_nreg7: data?.[`metrics_nreg7_${dataKey}_sent`],
      value_reg30: data?.[`metrics_reg30_${dataKey}_sent`],
      value_nreg30: data?.[`metrics_nreg30_${dataKey}_sent`],
    },
    {
      label: 'Delivered',
      value_reg7: data?.[`metrics_reg7_${dataKey}_delivered`],
      value_nreg7: data?.[`metrics_nreg7_${dataKey}_delivered`],
      value_reg30: data?.[`metrics_reg30_${dataKey}_delivered`],
      value_nreg30: data?.[`metrics_nreg30_${dataKey}_delivered`],
    },
    {
      label: 'Open',
      value_reg7: data?.[`metrics_reg7_${dataKey}_open`],
      value_nreg7: data?.[`metrics_nreg7_${dataKey}_open`],
      value_reg30: data?.[`metrics_reg30_${dataKey}_open`],
      value_nreg30: data?.[`metrics_nreg30_${dataKey}_open`],
    },
    {
      label: 'Click',
      value_reg7: data?.[`metrics_reg7_${dataKey}_click`],
      value_nreg7: data?.[`metrics_nreg7_${dataKey}_click`],
      value_reg30: data?.[`metrics_reg30_${dataKey}_click`],
      value_nreg30: data?.[`metrics_nreg30_${dataKey}_click`],
    },
    {
      label: 'Bounced',
      value_reg7: data?.[`metrics_reg7_${dataKey}_bounced`],
      value_nreg7: data?.[`metrics_nreg7_${dataKey}_bounced`],
      value_reg30: data?.[`metrics_reg30_${dataKey}_bounced`],
      value_nreg30: data?.[`metrics_nreg30_${dataKey}_bounced`],
    },
    {
      label: 'Unsubscribed',
      value_reg7: data?.[`metrics_reg7_${dataKey}_unsubscribed`],
      value_nreg7: data?.[`metrics_nreg7_${dataKey}_unsubscribed`],
      value_reg30: data?.[`metrics_reg30_${dataKey}_unsubscribed`],
      value_nreg30: data?.[`metrics_nreg30_${dataKey}_unsubscribed`],
    },
  ];

  return {
    commonMetrics,
    daysMetrics,
  };
};

export const PRO_ARTIST_METRICS = {
  totalRegistrations: 'New total registration',
  artistRegistrations: 'New artist Registrations ',
  trialSubscriptions: 'New trial subscriptions',
  activatedTrialSubscriptions: 'Trial to active subscriptions ',
  cancelledTrialSubscriptions: 'Trial to cancel subscriptions ',
  cancelledActiveSubscriptions: 'Active to cancel subscriptions',
  reactivatedActive: 'Reactivated subscription to active',
  reactivatedTrial: 'Reactivated subscription to trial',
};

export const getGiftSubscriptionMetrics = (data) => {
  const metrics = [
    {
      label: 'Total unique users who gifted subscription',
      value: data?.numberOfUsersGiftedSubscriptions,
    },
    {
      label: 'Total gift subscriptions created (Email or social media)',
      value: data?.totalReferralsCreated,
    },
    {
      label: 'Total new users registered with gift',
      value: data?.numberOfReceiverRegistered,
    },
    {
      label: 'Total claimed -  gift subscriptions',
      value: `${data?.numberOfAcceptedReferrals} (${((data?.numberOfAcceptedReferrals / data?.numberOfReceiverRegistered) * 100).toFixed(2)})`,
    },
    {
      label: 'Total receiver’s triggered referral',
      value: data?.numberOfReceiverTriggeredReferrals,
    },
    {
      label: 'Total credits earned',
      value: data?.claimedBonus,
    },
  ];

  return metrics;
};

export const getEntityValidationMetrics = (data) => {
  const getCellValue = (params) => {
    const parentColumn = params?.column?.originalParent?.colGroupDef?.headerName;
    const field = params?.colDef?.field;

    return params?.data?.[parentColumn]?.[field];
  };
  const columns = map(uniq(map(data, 'entity_type')), (column) => ({
    headerName: column,
    children: [
      {
        field: 'total_validated',
        headerName: 'Total validated',
        valueGetter: (params) => getCellValue(params),
        cellStyle: { textAlign: 'center' },
      },
      {
        field: 'score',
        valueGetter: (params) => getCellValue(params),
        cellStyle: { textAlign: 'center' },
      },
      {
        field: 'created',
        valueGetter: (params) => getCellValue(params),
        cellStyle: { textAlign: 'center' },
      },
      { field: 'approved', valueGetter: (params) => getCellValue(params), cellStyle: { textAlign: 'center' } },
      { field: 'approved-hidden', headerName: 'Approved (Hidden)', valueGetter: (params) => getCellValue(params), cellStyle: { textAlign: 'center' } },
      { field: 'rejected', valueGetter: (params) => getCellValue(params), cellStyle: { textAlign: 'center' } },
    ],
  }));

  const rows = orderBy(
    data.reduce((acc, row) => {
      const { action_user, entity_type, status, score } = row;
      const userId = action_user?.id;

      if (!acc[userId]) {
        acc[userId] = {
          user: action_user,
          [entity_type]: {
            [status]: 1,
            score,
            total_validated: status === 'created' ? 0 : 1,
          },
          overall_score: score,
        };
      } else {
        const {
          [entity_type]: entity = {},
          overall_score: currentOverallScore
        } = acc[userId];

        const { 
          [status]: currentStatusCount = 0,
          score: currentEntityScore = 0,
          total_validated: currentTotalValidated = 0, 
        } = entity;

        acc[userId] = {
          ...acc[userId],
          overall_score: currentOverallScore + score,
          [entity_type]: {
            ...entity,
            [status]: currentStatusCount + 1,
            score: currentEntityScore + score,
            total_validated: currentTotalValidated + (status === 'created' ? 0 : 1),
          },
        };
      }

      return acc;
    }, {}),
    (item) => item?.overall_score > 0 && item?.overall_score,
    'desc',
  );

  // Set empty values to empty string
  for (let row of rows) {
    let { user, overall_score, ...rest } = row;
    row.overall_score = overall_score || '';
    for ( let entity of Object.values(rest) ) {
      let { score, total_validated } = entity;
      if (score === 0) {
        entity.score = '';
      }
      if (total_validated === 0) {
        entity.total_validated = '';
      }
    }
  }

  return {
    columns: [
      { field: 'user', pinned: 'left', cellStyle: { textAlign: 'left', paddingLeft: 15 }, valueGetter: (params) => params?.data?.user?.name || params?.data?.user?.email, tooltipField: 'user' },
      { field: 'overall_score', headerName: 'Overall Score', pinned: 'left', cellStyle: { textAlign: 'center' }, width: 60, valueGetter: (params) => params?.data?.overall_score },
      ...columns,
    ],
    rows: Object.values(rows),
  };
};

export const getDataPointMetrics = (resource, data, metricsGroup) => {
  const columns =
    resource === 'metrics/datapoint-metrics'
      ? [
          {
            field: 'action_user',
            headerName: 'User',
            pinned: 'left',
            valueGetter: (params) =>
              params?.data?.action_user?.first_name || params?.data?.action_user?.last_name
                ? `${params?.data?.action_user?.first_name} ${params?.data?.action_user?.last_name}`
                : params?.data?.action_user?.email,
            cellStyle: { textAlign: 'left', paddingLeft: 15 },
            tooltipField: 'action_user',
          },
          { field: 'create', cellStyle: { textAlign: 'center', padding: 0 } },
          { field: 'update', cellStyle: { textAlign: 'center', padding: 0 } },
          { field: 'delete', cellStyle: { textAlign: 'center', padding: 0 } },
          metricsGroup === 'production' && { field: 'llm_reviewed_datapoints', headerName: 'Reviewed(LLM)', cellStyle: { textAlign: 'center', padding: 0 } },
          { field: 'total', cellStyle: { textAlign: 'center', padding: 0 } },
          { field: 'score', cellStyle: { textAlign: 'center', padding: 0 } },
        ]
      : [
          {
            field: 'action_user',
            headerName: 'User',
            pinned: 'left',
            valueGetter: (params) => `${params?.data?.action_user?.first_name} ${params?.data?.action_user?.last_name}`,
            cellStyle: { textAlign: 'left', paddingLeft: 15 },
            tooltipField: 'action_user',
          },
          { field: 'create', cellStyle: { textAlign: 'center', padding: 0 } },
          { field: 'update', cellStyle: { textAlign: 'center', padding: 0 } },
        ];

  return {
    columns: columns.filter(Boolean),
    rows: data,
  };
};

export const getProductionValidationMetrics = (data) => {
  const columns = [
    {
      field: 'action_user',
      headerName: 'User',
      pinned: 'left',
      valueGetter: (params) => params?.data?.action_user?.name,
      cellStyle: { textAlign: 'left', paddingLeft: 15 },
      tooltipField: 'action_user',
    },
    {
      headerName: 'Production',
      children: [
        { field: 'productionActionCreated', headerName: 'Created', cellStyle: { textAlign: 'center', padding: 0 } },
        { field: 'productionActionAccepted', headerName: 'Accepted', cellStyle: { textAlign: 'center', padding: 0 } },
        { field: 'productionActionRejected', headerName: 'Rejected', cellStyle: { textAlign: 'center', padding: 0 } },
      ],
    },
    {
      headerName: 'Change request',
      children: [
        { field: 'changeRequestCreated', headerName: 'Created', cellStyle: { textAlign: 'center', padding: 0 } },
        { field: 'changeRequestAccepted', headerName: 'Accepted', cellStyle: { textAlign: 'center', padding: 0 } },
        { field: 'changeRequestRejected', headerName: 'Rejected', cellStyle: { textAlign: 'center', padding: 0 } },
      ],
    },
    {
      headerName: 'Red mask request',
      children: [
        { field: 'redMaskRequestCreated', headerName: 'Created', cellStyle: { textAlign: 'center', padding: 0 } },
        { field: 'redMaskRequestAccepted', headerName: 'Accepted', cellStyle: { textAlign: 'center', padding: 0 } },
        { field: 'redMaskRequestRejected', headerName: 'Rejected', cellStyle: { textAlign: 'center', padding: 0 } },
      ],
    },
  ];

  const rows = reduce(
    data,
    (acc, row) => {
      const { action_user, ticket_type, state } = row;
      const userId = action_user?.id;
      const { identifier } = getValidationType(ticket_type, state);

      if (!acc[userId]) {
        return {
          ...acc,
          [userId]: {
            [identifier]: 1,
            action_user,
            total: 1,
          },
        };
      }

      return {
        ...acc,
        [userId]: {
          ...acc[userId],
          [identifier]: get(acc, `${userId}.${identifier}`, 0) ? get(acc, `${userId}.${identifier}`, 0) + 1 : 1,
          total: acc[userId]?.total + 1,
        },
      };
    },
    {},
  );

  return {
    columns,
    rows: orderBy(Object.values(rows), ['total'], ['desc']),
  };
};

export const getTaskQueueMetrics = (data) => {
  const uniqueMetricKeys = union(...map(data, (item) => keys(get(item, 'metrics', {}))));
  const getCellValue = (params) => {
    const parentColumn = params?.column?.originalParent?.colGroupDef?.headerKey;
    const field = params?.colDef?.field;

    return params?.data?.metrics?.[parentColumn]?.[field];
  };

  const columns = [
    {
      field: 'action_user',
      headerName: 'User',
      pinned: 'left',
      valueGetter: (params) => `${params?.data?.user?.first_name} ${params?.data?.user?.last_name}`,
      cellStyle: { textAlign: 'left', paddingLeft: 15 },
      tooltipField: 'user',
    },
    ...map(uniqueMetricKeys, (column) => ({
      headerName: capitalize(join(split(column, '_'), ' ')),
      headerKey: column,
      children: [
        {
          field: 'complete',
          headerName: 'Complete',
          valueGetter: (params) => getCellValue(params),
          cellStyle: { textAlign: 'center' },
        },
        {
          field: 'update',
          valueGetter: (params) => getCellValue(params),
          cellStyle: { textAlign: 'center' },
        },
        {
          field: 'score',
          valueGetter: (params) => getCellValue(params),
          cellStyle: { textAlign: 'center' },
        },
      ],
    })),
  ];

  return {
    columns,
    rows: data,
  };
};

export const getScrapeMetrics = (data) => {
  const dates = Object.keys(data);
  const dataKeys = Object.keys(omit(data[dates[0]], 'creation_date') || {});

  const columns = [
    {
      field: 'creation_date',
      headerName: 'Creation date',
      pinned: 'left',
      valueGetter: (params) => params?.data?.creation_date,
      cellStyle: { textAlign: 'left', paddingLeft: 15 },
    },
    ...map(dataKeys, (dataKey) => ({
      headerName: capitalize(join(split(dataKey, '_'), ' ')),
      field: dataKey,
      ...(dataKey !== 'entity_type' && { flex: 1, minWidth: 150 }),
      ...(dataKey === 'entity_type' && { minWidth: 300 }),
      cellStyle: { textAlign: 'center', 'padding-left': 0, 'border-right': '1px solid #babfc7' },
      align: 'middle',
    })),
  ];

  return {
    columns,
    rows: Object.values(data || []),
  };
};

export const getDuplicateEntityMetrics = (data) => {
  const getCellValue = (params) => {
    const parentColumn = params?.column?.originalParent?.colGroupDef?.headerName;
    const field = params?.colDef?.field;

    return params?.data?.[parentColumn]?.[field];
  };
  const columns = map(uniq(map(data, 'entity_type')), (column) => ({
    headerName: column,
    children: [
      {
        field: 'merge',
        valueGetter: (params) => getCellValue(params),
        cellStyle: { textAlign: 'center' },
      },
      { field: 'doNotMerge', valueGetter: (params) => getCellValue(params), cellStyle: { textAlign: 'center' } },
    ],
  }));
  const rows = data?.reduce((acc, row) => {
    const { action_user, entity_type, action } = row;
    const userId = action_user?.id;

    if (!acc[userId]) {
      acc[userId] = {
        user: action_user,
        [entity_type]: {
          ...(action === 'merge' ? { merge: 1 } : {}),
          ...(action === 'doNotMerge' ? { doNotMerge: 1 } : {}),
        },
      };
    } else {
      const entity = acc[userId][entity_type] || {};

      acc[userId] = {
        ...acc[userId],
        [entity_type]: {
          ...entity,
          ...(action === 'merge' ? { merge: get(entity, 'merge', 0) + 1 } : {}),
          ...(action === 'doNotMerge' ? { doNotMerge: get(entity, 'doNotMerge', 0) + 1 } : {}),
        },
      };
    }

    return acc;
  }, {});

  return {
    columns: [
      { field: 'user', pinned: 'left', cellStyle: { textAlign: 'left', paddingLeft: 15 }, valueGetter: (params) => params?.data?.user?.name || params?.data?.user?.email, tooltipField: 'user' },
      ...columns,
    ],
    rows: Object.values(rows),
  };
};

export const getAdsMetrics = (data) => {
  const dataWithDates = map(data, (item, key) => ({ date: key, ...item }));

  const dates = Object.keys(data);
  const dataKeys = Object.keys(data[dates[0]] || {});

  const columns = [
    {
      field: 'date',
      headerName: 'Date',
      pinned: 'left',
      valueGetter: (params) => params?.data?.date,
      cellStyle: { textAlign: 'left', paddingLeft: 15 },
    },
    ...map(dataKeys, (dataKey) => ({
      headerName: capitalize(join(split(dataKey, '_'), ' ')),
      field: dataKey,
      cellStyle: { textAlign: 'center' },
    })),
  ];

  return {
    columns,
    rows: Object.values(dataWithDates || []),
  };
};

export const getFingerprintMetrics = () => [
  {
    title: 'Hard Wall Conversions',
    lines: [
      {
        name: 'Login',
        key: 'login_conversions_hard_wall',
      },
      {
        name: 'Register',
        key: 'register_conversions_hard_wall',
      },
      {
        name: 'Total Unique Non Logged In',
        key: 'unique_hard_wall_pop_up_events',
      },
      {
        name: 'Total Unique Logged In',
        key: 'unique_hard_wall_user_pop_up_events',
      }
    ],
    isModal: true,
    resource: 'fingerprints',
    filter: {
      details: 'conversions',
      conversion_type: 'register_hard_wall,login_hard_wall',
    },
  },
  {
    title: 'Soft Wall Conversions',
    lines: [
      {
        name: 'Login',
        key: 'login_conversions_soft_wall',
      },
      {
        name: 'Register',
        key: 'register_conversions_soft_wall',
      },
      {
        name: 'Total Unique Non Logged In',
        key: 'unique_soft_wall_pop_up_events',
      },
      {
        name: 'Total Unique Logged In',
        key: 'unique_soft_wall_user_pop_up_events',
      }
    ],
    isModal: true,
    resource: 'fingerprints',
    filter: {
      details: 'conversions',
      conversion_type: 'register_soft_wall,login_soft_wall',
    },
  },
  {
    title: 'Hard Wall',
    lines: [
      {
        name: 'Non Logged In',
        key: 'unique_hard_wall_pop_up_events',
      },
      {
        name: 'Logged In',
        key: 'unique_hard_wall_user_pop_up_events',
      },
    ],
    isModal: true,
    resource: 'insights',
    filter: {
      view_name: 'hardwall_events_per_day_per_user',
    },
  },
  {
    title: 'Soft Wall',
    lines: [
      {
        name: 'Non Logged In',
        key: 'unique_soft_wall_pop_up_events',
      },
      {
        name: 'Logged In',
        key: 'unique_soft_wall_user_pop_up_events',
      },
      {
        name: 'Ignore Count Non Logged In',
        key: 'ignore_wall_pop_up_events_today',
      },
      {
        name: 'Ignore Count Logged In',
        key: 'ignore_wall_pop_up_user_events_today',
      }
    ],
    isModal: true,
    resource: 'insights',
    filter: {
      view_name: 'softwall_events_per_day_per_user',
    },
  },
  {
    title: 'Wall conversions profile type',
    lines: [
      {
        name: 'Artist',
        key: 'wall_conversions_profile_type_artist',
      },
      {
        name: 'AOS',
        key: 'wall_conversions_profile_type_aos',
      },
      {
        name: 'Audience',
        key: 'wall_conversions_profile_type_audience',
      },
      {
        name: 'Agency',
        key: 'wall_conversions_profile_type_agency',
      },
      {
        name: 'Casting',
        key: 'wall_conversions_profile_type_casting',
      },
      {
        name: 'Journalist',
        key: 'wall_conversions_profile_type_journalist',
      },
      {
        name: 'Academia',
        key: 'wall_conversions_profile_type_academia',
      },
    ],
  },
  {
    title: 'Wall conversions subscription',
    lines: [
      {
        name: 'Artist freemium',
        key: 'wall_conversions_subscription_artist_freemium',
      },
      {
        name: 'Artist premium',
        key: 'wall_conversions_subscription_artist_premium',
      },
      {
        name: 'AOS premium',
        key: 'wall_conversions_subscription_aos_premium',
      },
      {
        name: 'Agency free',
        key: 'wall_conversions_subscription_agency_free',
      },
      {
        name: 'Agency premium',
        key: 'wall_conversions_subscription_agency_premium',
      },
      {
        name: 'Casting tool',
        key: 'wall_conversions_subscription_casting_tool',
      },
    ],
  },
  {
    title: 'Login conversions',
    lines: [
      {
        name: 'Hard wall',
        key: 'login_conversions_hard_wall',
      },
      {
        name: 'Soft wall',
        key: 'login_conversions_soft_wall',
      },
      {
        name: 'Forced Login',
        key: 'login_returning_user_hard_wall',
      },
      {
        name: 'Total',
        key: 'login_conversions_today',
      },
    ],
  },
  {
    title: 'Register conversions',
    lines: [
      {
        name: 'Hard wall',
        key: 'register_conversions_hard_wall',
      },
      {
        name: 'Soft wall',
        key: 'register_conversions_soft_wall',
      },
      {
        name: 'Forced Register',
        key: 'register_returning_user_hard_wall',
      },
      {
        name: 'Total',
        key: 'registration_conversions_today',
      },
    ],
  },
  {
    title: 'Unique popup events',
    lines: [
      {
        name: 'Hard wall',
        key: 'unique_hard_wall_pop_up_events',
      },
      {
        name: 'Pay wall',
        key: 'unique_pay_wall_pop_up_events',
      },
      {
        name: 'Soft wall',
        key: 'unique_soft_wall_pop_up_events',
      },
      {
        name: 'Total',
        key: 'unique_wall_pop_up_events_today',
      }
    ],
  },
  {
    title: 'Casting tool events',
    lines: [
      { name: 'Total Events', key: 'casting_tool_today' },
      { name: 'Unique', key: 'visitors_casting_tool_today' }
    ],
    isModal: true,
    resource: 'insights',
    filter: {
      view_name: 'casting_tool_events_per_day',
    },
  },
  {
    title: 'Casting tool Artist Page events',
    lines: [
      { name: 'Total Events', key: 'casting_artist_page_today' },
      { name: 'Unique', key: 'casting_artist_page_by_users_today' }
    ],
    isModal: true,
    resource: 'insights',
    filter: {
      view_name: 'casting_artist_page_by_users_today',
    },
  },
  // {
  //   title: 'Conversions',
  //   lines: [{ name: 'Total', key: 'conversions_today' }],
  // },
  // {
  //   title: 'Casting tool events',
  //   lines: [{ name: 'Total', key: 'casting_tool_today' }],
  // },
  // {
  //   title: 'Visitor Casting tool events',
  //   lines: [{ name: 'Total', key: 'visitors_casting_tool_today' }],
  // },
  // {
  //   title: 'Unique visitor events',
  //   lines: [
  //     {
  //       name: 'Logged in',
  //       key: 'unique_visitors_logged_in_today',
  //     },
  //     {
  //       name: 'Non logged in',
  //       key: 'unique_visitors_non_logged_in_today',
  //     },
  //     {
  //       name: 'Total',
  //       key: 'unique_visitors_today',
  //     },
  //   ],
  // },
  // {
  //   title: 'Total events',
  //   lines: [
  //     {
  //       name: 'Logged in',
  //       key: 'logged_in_events_today',
  //     },
  //     {
  //       name: 'Non logged in',
  //       key: 'non_logged_in_events_today',
  //     },
  //     {
  //       name: 'Total',
  //       key: 'events_today',
  //     },
  //   ],
  // },
  // {
  //   title: 'Listing page past production count',
  //   lines: [
  //     {
  //       name: 'Logged today',
  //       key: 'listing_page_past_production_count_logged_today',
  //     },
  //     {
  //       name: 'Non logged today',
  //       key: 'listing_page_past_production_count_non_logged_today',
  //     },
  //     {
  //       name: 'Total',
  //       key: 'listing_page_past_production_count_today',
  //     },
  //   ],
  // },
  // {
  //   title: 'Listing page production count',
  //   lines: [
  //     {
  //       name: 'Logged today',
  //       key: 'listing_page_production_count_logged_today',
  //     },
  //     {
  //       name: 'Non logged today',
  //       key: 'listing_page_production_count_non_logged_today',
  //     },
  //     {
  //       name: 'Total',
  //       key: 'listing_page_production_count_today',
  //     },
  //   ],
  // },
  // {
  //   title: 'Stub page past production count',
  //   lines: [
  //     {
  //       name: 'Logged today',
  //       key: 'stub_page_past_production_count_logged_today',
  //     },
  //     {
  //       name: 'Non logged today',
  //       key: 'stub_page_past_production_count_non_logged_today',
  //     },
  //     {
  //       name: 'Total',
  //       key: 'stub_page_past_production_count_today',
  //     },
  //   ],
  // },
  // {
  //   title: 'Stub page production count',
  //   lines: [
  //     {
  //       name: 'Logged today',
  //       key: 'stub_page_production_count_logged_today',
  //     },
  //     {
  //       name: 'Non logged today',
  //       key: 'stub_page_production_count_non_logged_today',
  //     },
  //     {
  //       name: 'Total',
  //       key: 'stub_page_production_count_today',
  //     },
  //   ],
  // },
  // {
  //   title: 'Unique visitors',
  //   lines: [{ name: 'Total', key: 'total_unique_visitors' }],
  // },
  // {
  //   title: 'Listing page past production count',
  //   lines: [{ name: 'Total', key: 'total_listing_page_past_production_count' }],
  // },
  // {
  //   title: 'Stub page past production count',
  //   lines: [{ name: 'Total', key: 'total_stub_page_past_production_count' }],
  // },
  // {
  //   title: 'Total Casting tool count',
  //   lines: [{ name: 'Total', key: 'total_casting_tool_count' }],
  // },
];
