import React from 'react';
import { Datagrid, List, DateField, TextField, TextInput, Edit, Create, SimpleForm, SelectInput, Show, SimpleShowLayout, Filter,  useNotify, useRefresh, useUnselectAll, useDataProvider, UPDATE} from 'react-admin';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { DOMAIN_STATUSES, DOMAIN_STATUSES_IDS } from '../constants/domains';
import { Button, ButtonGroup,  } from '@material-ui/core';


const DomainValidationButtons = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll();
  const { selectedIds } = props;

  const validate = (status) => {
    Promise.all(
      selectedIds.map(async (id) => {
        const data = await dataProvider(UPDATE, 'domains', { id, data: { status: status } });
        return data;
      }),
    ).then(
      (responses) => {
        Promise.resolve(responses);
        unselectAll(props.resource);
        refresh();
      },
      (error) => {
        refresh();
        notify(error.message);
        unselectAll(props.resource);
        Promise.reject(error);
      },
    );
  };

  return (
    <ButtonGroup>
      <Button onClick={() => validate(DOMAIN_STATUSES_IDS.BLACKLISTED)}>Blacklisted</Button>
      <Button onClick={() => validate(DOMAIN_STATUSES_IDS.WHITELISTED)}>Whitelisted</Button>
      <Button onClick={() => validate(DOMAIN_STATUSES_IDS.REVIEWSKIPPED)}>Review-Skipped</Button>
      <Button onClick={() => validate(DOMAIN_STATUSES_IDS.REVIEWPENDING)}>Review-Pending</Button>
    </ButtonGroup>
  );
};

const MultipleDomainValidationButtons = (props) => <DomainValidationButtons {...props} />;

export const DomainsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="query" alwaysOn />
    <SelectInput label="Status" source="status" choices={DOMAIN_STATUSES} alwaysOn emptyText="All" />
  </Filter>
);

export const DomainsList = (props) => (
  <List exporter={false} {...props} bulkActionButtons={<MultipleDomainValidationButtons />} title={<Breadcrumbs {...props}/>} filters={<DomainsFilter />}>
    <Datagrid rowClick="show">
      <TextField source="id" sortable={false} />
      <TextField source="domain" sortable={false} />
      <TextField source="status" sortable={false} />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

export const DomainsCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="domain" />
      <SelectInput choices={DOMAIN_STATUSES} source="status" />
    </SimpleForm>
  </Create>
);

export const DomainsShow = (props) => (
  <Show {...props} title={<Breadcrumbs {...props} />}>
    <SimpleShowLayout>
      <TextField source="id" sortable={false} />
      <TextField source="domain" sortable={false} />
      <TextField source="status" sortable={false} />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </SimpleShowLayout>
  </Show>
);

export const DomainsEdit = (props) => (
  <Edit {...props} title={<Breadcrumbs {...props} />}>
    <SimpleForm>
      <TextInput source="domain" />
      <SelectInput choices={DOMAIN_STATUSES} source="status" />
    </SimpleForm>
  </Edit>
);

export default DomainsList;
