/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { orderBy, head, filter, map } from 'lodash';
import { useDataProvider, useNotify } from 'react-admin';
import { Button, Dialog, DialogContent, Divider, IconButton, TextField, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import NoteIcon from '@material-ui/icons/Note';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import EntityLink from './EntityLink';
import { dateParserTime } from '../utils/dateParser';
import storage from '../utils/storage';

const useStyles = makeStyles({
  button: {
    textTransform: 'initial',
  },
  dialog: {
    minWidth: 700,
    height: 500,
    '& .MuiDialogContent-root': { padding: 0 },
    '& .MuiDialogContent-root:first-child': { padding: 0 },
  },
  closeIcon: {
    position: 'absolute',
    right: 10,
    top: 10,
    cursor: 'pointer',
  },
  container: { display: 'flex', height: '100%', overflow: 'hidden' },
  noteSection: { width: '25%' },
  noteHeader: { display: 'flex', position: 'relative', alignItems: 'center', padding: 15 },
  addIcon: { position: 'absolute', right: 5, cursor: 'pointer', fontSize: 20, padding: 5 },
  noteTitle: { fontSize: 16, fontWeight: 'bold' },
  noteTotal: { marginLeft: 10 },
  note: {
    padding: '10px 15px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    '&:last-child': { borderBottom: 'none' },
    '&:hover': { backgroundColor: '#d9e8ee', cursor: 'pointer' },
  },
  noteSelected: { backgroundColor: '#d9e8ee' },
  noteMessage: { fontSize: 14, fontWeight: 'bold' },
  noteList: { overflowY: 'scroll', maxHeight: 450 },
  noteDate: { fontSize: 14, lineHeight: 2 },
  noteOwner: { fontSize: 14 },
  messageSection: { width: '75%' },
  noteActions: { height: 49, borderBottom: '1px solid rgba(0, 0, 0, 0.12)', display: 'flex', alignItems: 'center' },
  noteActionTime: { width: '80%', textAlign: 'center', fontWeight: 500, fontSize: 14 },
  deleteIcon: { color: 'red', cursor: 'pointer', marginLeft: 5, padding: 0 },
  textMessage: { padding: 10 },
  preMessage: { fontSize: 14, color: 'black', fontFamily: 'inherit', maxHeight: 420, overflowY: 'scroll', whiteSpace: 'pre-wrap' },
  noteCount: { marginLeft: 5, color: '#ff5722', fontWeight: 'bold' },
});

const InternalNotes = (props) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState([]);
  const [message, setMessage] = useState(null);

  const { entityId, entityType } = props;
  const userId = storage.getUserId();
  const isEditAllowed = parseInt(userId, 0) === selectedNote?.author?.id;

  useEffect(() => {
    dataProvider
      .getList('system/internal_notes', {
        filter: { entity_id: entityId, entity_type: entityType },
        pagination: { perPage: 100, page: 1 },
        sort: {},
      })
      .then((res) => {
        const data = orderBy(res?.data, 'id', 'desc');
        setNotes(data);
        setSelectedNote(head(data));
        setMessage(head(data)?.message);
      });
  }, [dataProvider, entityId, entityType]);

  const handleAddNote = () => {
    setNotes([{ message: '', author: { id: parseInt(userId, 0) } }, ...notes]);
    setSelectedNote({ message: '', author: { id: parseInt(userId, 0) } });
    setMessage('');
  };

  const saveNote = () => {
    if (!isEditAllowed || selectedNote?.message === message) return;

    if (selectedNote?.id) {
      dataProvider
        .update('system/internal_notes', {
          id: selectedNote?.id,
          data: {
            message,
          },
        })
        .then((res) => {
          const updatedNotes = map(notes, (note) => {
            if (note?.id === selectedNote?.id) return res?.data;

            return note;
          });
          setNotes(updatedNotes);
          setSelectedNote(res?.data);
          setMessage(res?.data?.message);
        });
    } else {
      dataProvider
        .create('system/internal_notes', {
          data: {
            entity_type: entityType,
            entity_id: entityId,
            message,
          },
        })
        .then((res) => {
          const filterNotes = filter(notes, (note) => note?.id);
          setNotes([res?.data, ...filterNotes]);
          setSelectedNote(res?.data);
          setMessage(res?.data?.message);
        });
    }
  };

  // let delay;
  useEffect(() => {
    const delay = setTimeout(() => {
      if (message) saveNote();
    }, 1000);

    return () => clearTimeout(delay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const handleDeleteNote = () => {
    if (selectedNote?.id) {
      dataProvider
        .delete('system/internal_notes', { id: selectedNote?.id })
        .then(() => {
          notify('Deleted successfully', 'success');
          const filterNotes = filter(notes, (note) => note?.id !== selectedNote?.id);
          setNotes(filterNotes);
          setSelectedNote(head(filterNotes));
          setMessage(head(filterNotes)?.message);
        })
        .catch((err) => {
          notify(err.message, 'error');
        });
    } else {
      const filterNotes = filter(notes, (note) => note?.id);
      setNotes(filterNotes);
      setSelectedNote(head(filterNotes));
      setMessage(head(filterNotes)?.message);
    }
  };

  return (
    <div>
      <Button variant="contained" color="grey" size="small" className={classes.button} startIcon={<NoteIcon />} onClick={() => setOpen(true)}>
        Notes {notes?.length > 0 ? <span className={classes.noteCount}>{notes?.length}</span> : null}
      </Button>
      <Dialog
        PaperProps={{
          className: classes.dialog,
          sx: { padding: 0 },
        }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogContent>
          <CloseIcon className={classes.closeIcon} onClick={() => setOpen(false)} />
          <div className={classes.container}>
            <div className={classes.noteSection}>
              <div className={classes.noteHeader}>
                <div className={classes.noteTitle}>
                  Notes
                  <span className={classes.noteTotal}>{notes?.length}</span>
                </div>
                <IconButton aria-label="add" onClick={() => handleAddNote()} className={classes.addIcon} disabled={head(notes) && !head(notes)?.id}>
                  <AddCircleOutlineIcon />
                </IconButton>
              </div>
              <div className={classes.noteList}>
                {notes.map((note) => {
                  const shortDescription = note?.message?.substring(0, 16);

                  return (
                    <div
                      className={cn(classes.note, {
                        [classes.noteSelected]: note?.id === selectedNote?.id,
                      })}
                      onClick={() => {
                        setSelectedNote(note);
                        setMessage(note?.message);
                      }}
                    >
                      <div className={classes.noteMessage}>{note?.message?.length > 16 ? `${shortDescription}...` : note?.message}</div>
                      <div className={classes.noteDate}>{dateParserTime(note?.updated_at)}</div>
                      <div className={classes.noteOwner}>{note?.author?.name}</div>
                    </div>
                  );
                })}
              </div>
            </div>
            <Divider orientation="vertical" flexItem />
            <div className={classes.messageSection}>
              <div className={classes.noteActions}>
                {selectedNote && (
                  <Tooltip title={!isEditAllowed ? 'Only original author can delete the note' : 'Delete'}>
                    <span>
                      <IconButton aria-label="delete" className={classes.deleteIcon} onClick={() => handleDeleteNote()} disabled={!isEditAllowed}>
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
                <span className={classes.noteActionTime}>{selectedNote?.author?.email && <EntityLink entityType="users" entity={selectedNote?.author} />}</span>
              </div>
              <div className={classes.textMessage}>
                {isEditAllowed ? (
                  <TextField label="Message" fullWidth multiline rows={21} value={message} variant="standard" onChange={(e) => setMessage(e?.target?.value)} />
                ) : (
                  <Tooltip title="Only original author can edit the note">
                    <pre className={classes.preMessage}>{selectedNote?.message}</pre>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default InternalNotes;
