import React from 'react';
import { Tooltip, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Datagrid, FunctionField, List, DateField, TextField, TextInput, SelectInput, Show, SimpleShowLayout, Filter } from 'react-admin';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { VALIDATION_STATUSES_IDS } from '../constants/validations';
import EntityLink from '../components/EntityLink';

const useStyles = makeStyles(() => ({
  tooltipButton: {
    display: 'flex',
  },
  tooltip: {
    backgroundColor: 'black',
    maxWidth: 500,
    color: 'white',
    '& a': {
      color: '#0072E5',
    },
  },
  tooltipList: {
    paddingLeft: 20,
    fontSize: 13,
  },
}));

const validationStatusOptions = [
  { id: VALIDATION_STATUSES_IDS.PENDING, name: 'Pending' },
  { id: VALIDATION_STATUSES_IDS.VALID, name: 'Approved' },
  { id: VALIDATION_STATUSES_IDS.INVALID, name: 'Rejected' },
];

const isRequestedByUserOptions = [
  { id: 'true', name: 'Requested by user' },
  { id: 'false', name: 'Not requested by user' },
];

export const RedMaskRequestFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Production Id" source="production_id" alwaysOn />
    <SelectInput label="Is Requested By User" source="is_requested_by_user" choices={isRequestedByUserOptions} alwaysOn emptyText="All" />
    <TextInput label="User Id" source="requested_by_user_id" alwaysOn />
    <TextInput label="Profile Id" source="requested_by_profile_id" alwaysOn />
    <SelectInput label="Request Status" source="validation_status_id" choices={validationStatusOptions} alwaysOn emptyText="All" />
    <SelectInput label="Production Validation" source="production_validation_status_id" choices={validationStatusOptions} alwaysOn emptyText="All" />
  </Filter>
);

export const RedMaskRequestList = (props) => {
  const classes = useStyles();

  return (
    <List exporter={false} {...props} title={<Breadcrumbs {...props} />} sort={{ field: 'id', order: 'DESC' }} bulkActionButtons={false} filters={<RedMaskRequestFilter />}>
      <>
        <Tooltip
          interactive
          arrow
          classes={{ tooltip: classes.tooltip }}
          title={
            <ol className={classes.tooltipList}>
              <li>All red mask request tickets (pending, approved or rejected // validated or non-validated productions) will be displayed here. You can filter by validation status</li>
              <li>
                If an artist requested red mask to a non - validated production and the validation status is pending, please go to <a href="/#/validations/redmask-requests">this link</a>
              </li>
            </ol>
          }
        >
          <Button className={classes.tooltipButton}>► Hover here for more info About Artist Red masks in Data:</Button>
        </Tooltip>
        <Datagrid rowClick="show">
          <TextField source="id" />
          <FunctionField label="Production" render={(request) => <EntityLink entity={request?.production} entityType="productions" />} />
          <FunctionField label="Requested By User" render={(request) => <EntityLink entity={request?.requestedByUser} entityType="users" emptyText="Not Requested by User" />} />
          <FunctionField label="Requested By Profile" render={(request) => <EntityLink entity={request?.requestedByProfile} entityType="profiles" />} />
          <TextField source="validationStatus.name" sortable={false} label="Request Status" />
          <TextField source="acknowledged" sortable={false} label="Request Acknowledged" />
          <DateField source="created_at" label="Request Created At" />
          <DateField source="updated_at" label="Request Updated At" />
          <TextField source="production.validationStatus.name" sortable={false} label="Production Validation" />
          <TextField source="production.validation_reason" sortable={false} label="Production Validation Queue" />
        </Datagrid>
      </>
    </List>
  );
};

export const RedMaskRequestShow = (props) => (
  <Show {...props} title={<Breadcrumbs {...props} />}>
    <SimpleShowLayout>
      <TextField source="id" sortable={false} />
      <FunctionField label="Production" render={(request) => <EntityLink entity={request?.production} entityType="productions" />} />
      <FunctionField label="User" render={(request) => <EntityLink entity={request?.requestedByUser} entityType="users" />} />
      <FunctionField label="Profile" render={(request) => <EntityLink entity={request?.requestedByProfile} entityType="profiles" />} />
      <TextField source="validationStatus.name" sortable={false} />
      <TextField source="acknowledged" sortable={false} />
      <TextField source="comments" sortable={false} />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </SimpleShowLayout>
  </Show>
);

export default RedMaskRequestList;
