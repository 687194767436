import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { makeStyles } from '@material-ui/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useDataProvider, useNotify, Confirm } from 'react-admin';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControlLabel, RadioGroup, TextField, Radio } from '@material-ui/core';

const useStyles = makeStyles({
  button: {
    marginRight: 10,
    textTransform: 'inherit',
  },
  dialog: { width: 500 },
});

const SUSPENSION_TYPES = {
  REGULAR: '1',
  ACQUISITION: '2',
  REBRABDING: '3',
};

export const renderEntity = (entity) => `(${entity.id}) - ${entity.name}`;

const MarkAsNonOperational = (props) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { type, resource } = props;
  const [isMarkAsNonOperational, setMarkAsNonOperational] = useState(false);
  const [isMarkAsOperational, setMarkAsOperational] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [reason, setReason] = useState('');
  const [suspensionType, setSuspensionType] = useState(SUSPENSION_TYPES.REGULAR);
  const isSuspension = !isEmpty(props?.record?.suspension);

  const handleClose = () => {
    setMarkAsNonOperational(false);
  };

  const handleSubmit = () => {
    dataProvider
      .update(resource, {
        id: props?.id,
        data: {
          suspension: {
            suspension_type: suspensionType,
            reason,
            ...(type === 'organizations' &&
              selectedEntity && {
                operationalOrganization: {
                  id: selectedEntity.id,
                },
              }),
            ...(type === 'agencies' &&
              selectedEntity && {
                operationalAgency: {
                  id: selectedEntity.id,
                },
              }),
          },
        },
      })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        notify(err.message, 'error');
      });
  };

  const handleMarkAsOperational = () => {
    dataProvider
      .update(resource, { id: props?.id, data: { suspension: null } })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        notify(err.message, 'error');
      });
  };

  const getEntity = (query) => {
    dataProvider
      .getList(resource, {
        filter: { query, operational_status: 'operational' },
        pagination: { limit: 25, page: 1 },
        sort: {},
      })
      .then(({ data }) => {
        setOptions(data);
      });
  };

  useEffect(() => {
    getEntity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (suspensionType === SUSPENSION_TYPES.REGULAR) {
      setSelectedEntity(null);
    }
  }, [suspensionType]);

  const onChangeSuspensionType = (e) => setSuspensionType(e.target.value);

  return (
    <>
      {!isSuspension && (
        <Button size="small" variant="contained" color="grey" className={classes.button} onClick={() => setMarkAsNonOperational(true)}>
          Mark as non operational
        </Button>
      )}
      {isSuspension && (
        <Button size="small" variant="contained" color="grey" className={classes.button} onClick={() => setMarkAsOperational(true)}>
          Mark as operational
        </Button>
      )}
      <Confirm
        isOpen={isMarkAsOperational}
        title="Mark as operational"
        content="Are you sure you want to Mark as operational"
        onConfirm={handleMarkAsOperational}
        onClose={() => setMarkAsOperational(false)}
      />
      <Dialog
        PaperProps={{
          className: classes.dialog,
        }}
        open={isMarkAsNonOperational}
        onClose={handleClose}
      >
        <DialogTitle>Mark as non operational</DialogTitle>
        <DialogContent>
          <RadioGroup row>
            <FormControlLabel value={SUSPENSION_TYPES.REGULAR} control={<Radio checked={suspensionType === SUSPENSION_TYPES.REGULAR} onChange={onChangeSuspensionType} />} label="Non operational" />
            <FormControlLabel
              value={SUSPENSION_TYPES.ACQUISITION}
              control={<Radio checked={suspensionType === SUSPENSION_TYPES.ACQUISITION} onChange={onChangeSuspensionType} />}
              label="Add a redirect to another entity"
            />
            <FormControlLabel value={SUSPENSION_TYPES.REBRABDING} control={<Radio checked={suspensionType === SUSPENSION_TYPES.REBRABDING} onChange={onChangeSuspensionType} />} label="Rebranding" />
          </RadioGroup>
          <Autocomplete
            options={options}
            autoComplete
            value={selectedEntity}
            onChange={(event, entity) => {
              setSelectedEntity(entity);
            }}
            onInputChange={(event, input) => {
              getEntity(input);
            }}
            renderInput={(params) => <TextField {...params} fullWidth label={`Search ${type}`} />}
            renderOption={(option) => renderEntity(option)}
            getOptionLabel={(option) => renderEntity(option)}
            disabled={suspensionType === SUSPENSION_TYPES.REGULAR}
          />
          <TextField margin="dense" label="Reason" fullWidth type="text" value={reason} onChange={(e) => setReason(e.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={suspensionType !== SUSPENSION_TYPES.REGULAR ? !reason || !selectedEntity : !reason} variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MarkAsNonOperational;
