import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as dayjs from 'dayjs';
import keyBy from 'lodash/keyBy';
import { ListContextProvider, Datagrid, Loading, TextField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, TextField as MUITextField } from '@material-ui/core';
// import apiClient from '../../../utils/api';
import axios from 'axios';
// import blitzerApiClient from '../../../utils/blitzerApi';

// const BLITZER_API_URL = "https://dev-blitzer-event-api.truelinked.net/"
// const BLITZER_SECRET = 'secret'

const useStyles = makeStyles({
  selectSection: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  spacingLeft: {
    marginLeft: 10,
  },
});

const FingerprintActivityLogs = (props) => {
  const [data, setData] = useState([]);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const searchParams = new URLSearchParams(history.location.search);
  const defaultDateRange = { from_date: searchParams?.get('from_date'), to_date: searchParams?.get('to_date') };
  const [selectedDateRange, setSelectedDateRange] = useState({
    from: defaultDateRange?.from_date || dayjs().add(-1, 'day').format('YYYY-MM-DD'),
    to: defaultDateRange?.to_date || dayjs().format('YYYY-MM-DD'),
  });
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_BLITZER_API_URL}fingerprint/logs`, {
      params: {
        from: selectedDateRange.from,
        to: selectedDateRange.to,
      },
      headers: {
        'Content-Type': 'application/json',
        'x-access-key': `${process.env.REACT_APP_BLITZER_API_SECRET}`
      }
    }).then((res) => {
      setData(res?.data?.logs);
      setLoading(false);
    });
  }, [selectedDateRange]);

  useEffect(() => {
    searchParams.set('from_date', selectedDateRange?.from);
    searchParams.set('to_date', selectedDateRange?.to);
    const newSearch = searchParams.toString();
    history.push({ search: newSearch });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange]);

  const listContext = {
    data: keyBy(data, (item) => `${item?._id}`),
    ids: data?.map((item) => `${item?._id}`),
    total: data?.total || 0,
    basePath: props.basePath,
    resource: props.resource,
    selectedIds: [],
    currentSort: {},
    hasList: true,
  };

  return (
    <>
      <FormControl className={classes.selectSection} size="small">
        <MUITextField
          disabled={loading}
          value={selectedDateRange.from}
          onChange={(e) => setSelectedDateRange({ ...selectedDateRange, from: e?.target?.value })}
          type="date"
          label="From"
          variant="filled"
          size="small"
        />
        <MUITextField
          className={classes.spacingLeft}
          value={selectedDateRange.to}
          onChange={(e) => setSelectedDateRange({ ...selectedDateRange, to: e?.target?.value })}
          type="date"
          label="To"
          variant="filled"
          size="small"
          disabled={loading}
        />
      </FormControl>
      {!loading ? (
        <ListContextProvider value={listContext}>
          <Datagrid>
            <TextField source="_id" label="Run ID" sortable={false} />
            <TextField source="calculationType" label="Calculation type" sortable={false} />
            <TextField source="createdAt" label="Created at" sortable={true} />
            <TextField source="status" label="Status" sortable={false} />
            <TextField source="data" label="Data" sortable={false} />
          </Datagrid>
        </ListContextProvider>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default FingerprintActivityLogs;
