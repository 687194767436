import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-admin';

const useStyles = makeStyles(
  (theme) => ({
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      marginRight: 10,
    },
  }),
  { name: 'MakeLink' },
);

const RegistrationLink = ({ record }) => {
  const classes = useStyles();

  return (
    <Link to={`/users/registrations/${record.id}/show`} className={classes.link}>
      {`Registration #${record?.id}`}
    </Link>
  );
};

export default RegistrationLink;
