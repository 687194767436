import React, { useEffect } from 'react';
// import { useGetList } from 'react-admin';
import { Grid, Card, CardContent, Typography } from '@material-ui/core';
// import { map } from 'lodash';
// import { VALIDATION_STATUSES_IDS } from '../../constants/validations';

// const useStyles = makeStyles(() => ({
//   entityInfo: { marginLeft: 10 },
// }));

const UserDashboard = () => {
  // const classes = useStyles();
  // const [selectedDayFilter, setSelectedDayFilter] = useState(DAYS_FILTER[0]);
  // const { data, loading } = useGetList('validations/entities/types', { page: 1, perPage: 100 }, {});

  useEffect(() => {
    document.title = 'User dashboard';
  }, []);

  //   if (loading) {
  //     return 'Loading user dashboard';
  //   }

  return (
    <div>
      <Grid container spacing={2} direction="row" justify="flex-start" alignItems="flex-start">
        <Grid item xs={12} sm={12} md={12}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h4" align="center">
                User Dashboard
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        {/* {map(data, (entity, key) => (
          <Grid item xs={6} sm={3} md={2} key={key}>
            <Card
              variant="outlined"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                window.location.href = `#/validations?filter=${JSON.stringify({ entity_type: entity?.id, status_id: VALIDATION_STATUSES_IDS.PENDING })}`;
              }}
            >
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  {entity?.name}
                </Typography>
                <Grid container className={classes.entityInfo}>
                  <Grid container alignItems="center">
                    <Typography variant="h6">{entity?.count}</Typography>
                  </Grid>
                  <Typography variant="body2" style={{ marginTop: -8 }}>
                    Total
                  </Typography>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))} */}
      </Grid>
    </div>
  );
};

export default UserDashboard;
