import React, { useState } from 'react';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { InputBase, Typography, makeStyles } from '@material-ui/core/';
import { AppBar, UserMenu, MenuItemLink, useRedirect } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import SearchIcon from '@material-ui/icons/Search';

const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.3),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.4),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(9),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(8),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 400,
    },
  },
});

const MyUserMenu = (props) => (
  <UserMenu {...props}>
    <MenuItemLink to="/configuration" primaryText="Configuration" leftIcon={<SettingsIcon />} />
  </UserMenu>
);
const PrimarySearchAppBar = (props) => {
  const classes = makeStyles(styles)();
  const [searchQuery, setSearchQuery] = useState();
  const redirect = useRedirect();

  return (
    <AppBar {...props} userMenu={<MyUserMenu />}>
      <Typography color="inherit" id="react-admin-title" />

      <div className={classes.grow} />
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            const obj = { query: searchQuery, show_invalid: true };
            redirect(`/search?filter=${JSON.stringify(obj)}`);
          }}
        >
          <InputBase
            {...props}
            placeholder="Search…"
            onChange={(event) => {
              setSearchQuery(event.target.value);
            }}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
          />
        </form>
      </div>
      <div className={classes.grow} />
    </AppBar>
  );
};

export default PrimarySearchAppBar;
