import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { DashboardMenuItem, MenuItemLink, Responsive } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import SubMenu from './subMenu';
import { BUILD_NUMBER, RELEASE_DATE } from '../build.manifest.json';
import useUserPermissions from '../utils/useUserPermissions';
import { getEntityIcon } from '../utils/entities';

const styles = {
  infoItem: {
    paddingLeft: 16,
    marginTop: 20,
    fontSize: '0.875rem',
    color: 'rgba(0, 0, 0, 0.54)',
  },
};

const Menu = ({ onMenuClick, open, logout, classes }) => {
  const [menu, setMenu] = useState(false);
  const { isAdmin, permissions } = useUserPermissions();
  const hasFingerPrintPermission = permissions?.length && permissions.includes('applications.frontbase.fingerprint');

  const handleMenu = (currentMenu) => {
    if (menu === currentMenu) {
      setMenu(false);
    } else {
      setMenu(currentMenu);
    }
  };

  return (
    <div>
      <DashboardMenuItem onClick={onMenuClick} />
      <SubMenu handleToggle={() => handleMenu('metrics')} isOpen={menu === 'metrics'} sidebarIsOpen={open} name="Metrics">
        <MenuItemLink to="/metrics/artist-ads-metrics" primaryText="Ads requests" onClick={onMenuClick} leftIcon={getEntityIcon('metrics/artist-ads-metrics')} />
        <MenuItemLink to="/metrics/casting-tool-usage" primaryText="Casting tool usage" onClick={onMenuClick} leftIcon={getEntityIcon('metrics/casting-tool-usage')} />
        <MenuItemLink to="/metrics/datapoint-metrics" primaryText="Datapoint Metrics" onClick={onMenuClick} leftIcon={getEntityIcon('metrics/datapoint-metrics')} />
        <MenuItemLink to="/metrics/datapoint-meta" primaryText="Datapoint Weightage" onClick={onMenuClick} leftIcon={getEntityIcon('metrics/datapoint-metrics')} />
        <MenuItemLink to="/metrics/entity-validation-metrics" primaryText="Entity Validations" onClick={onMenuClick} leftIcon={getEntityIcon('metrics/entity-validation-metrics')} />
        <MenuItemLink to="/metrics/entity-merge-metrics" primaryText="Duplicate queue metrics" onClick={onMenuClick} leftIcon={getEntityIcon('metrics/entity-merge-metrics')} />
        <MenuItemLink to="/metrics/email-metrics" primaryText="Email" onClick={onMenuClick} leftIcon={getEntityIcon('metrics/email-metrics')} />
        <MenuItemLink to="/metrics/fingerprint" primaryText="Fingerprint" onClick={onMenuClick} leftIcon={getEntityIcon('metrics/fingerprint')} />
        <MenuItemLink to="/metrics/gift-subscription-metrics" primaryText="Gift subscription" onClick={onMenuClick} leftIcon={getEntityIcon('metrics/gift-subscription-metrics')} />
        <MenuItemLink to="/metrics/notification-metrics" primaryText="Notifications" onClick={onMenuClick} leftIcon={getEntityIcon('metrics/notification-metrics')} />
        <MenuItemLink to="/metrics/pro-artist-metrics" primaryText="Pro artist" onClick={onMenuClick} leftIcon={getEntityIcon('metrics/pro-artist-metrics')} />
        <MenuItemLink to="/metrics/production-action-metrics" primaryText="Production Action" onClick={onMenuClick} leftIcon={getEntityIcon('metrics/production-action-metrics')} />
        <MenuItemLink to="/metrics/scrape-requests" primaryText="Scrape requests" onClick={onMenuClick} leftIcon={getEntityIcon('metrics/scrape-requests')} />
        <MenuItemLink to="/metrics/task-queue-metrics" primaryText="Task queue" onClick={onMenuClick} leftIcon={getEntityIcon('metrics/task-queue-metrics')} />
        <MenuItemLink to="/metrics/users" primaryText="Users" onClick={onMenuClick} leftIcon={getEntityIcon('metrics/users')} />
      </SubMenu>
      <SubMenu handleToggle={() => handleMenu('data')} isOpen={menu === 'data'} sidebarIsOpen={open} name="Data">
        <MenuItemLink to="/countries/cities/altnames" primaryText="All geonames" onClick={onMenuClick} leftIcon={getEntityIcon('countries/cities/altnames')} exact />
        <MenuItemLink to="/productions/redMaskRequests" primaryText="Artist Red Masks" onClick={onMenuClick} leftIcon={getEntityIcon('redmaskrequests')} />
        <MenuItemLink to="/competitions" primaryText="Competitions" onClick={onMenuClick} leftIcon={getEntityIcon('competitions')} />
        <MenuItemLink to="/countries" primaryText="Countries" onClick={onMenuClick} leftIcon={getEntityIcon('countries')} exact />
        <MenuItemLink to="/countries/cities" primaryText="Cities" onClick={onMenuClick} leftIcon={getEntityIcon('countries/cities')} exact />
        <MenuItemLink to="/metrics/dbviews" primaryText="Data Views" onClick={onMenuClick} leftIcon={getEntityIcon('metrics/dbviews')} />
        <MenuItemLink to="/data/dashboard" primaryText="Dashboard" onClick={onMenuClick} leftIcon={getEntityIcon('data/dashboard')} />
        {/* <MenuItemLink to="/export" primaryText="Export" onClick={onMenuClick} leftIcon={getEntityIcon('export')} /> */}
        <MenuItemLink to="/instruments" primaryText="Instruments" onClick={onMenuClick} leftIcon={getEntityIcon('instruments')} />
        <MenuItemLink to="/languages" primaryText="Languages" onClick={onMenuClick} leftIcon={getEntityIcon('languages')} />
        <MenuItemLink to="/media" primaryText="Media" onClick={onMenuClick} leftIcon={getEntityIcon('media')} />
        <MenuItemLink to="/validations/merge" primaryText="Merge" onClick={onMenuClick} leftIcon={getEntityIcon('validations/merge')} />
        <MenuItemLink to="/organizationTypes" primaryText="Organization types" onClick={onMenuClick} leftIcon={getEntityIcon('organizationTypes')} />
        <MenuItemLink to="/organizations/departments" primaryText="Organizations departments" onClick={onMenuClick} leftIcon={getEntityIcon('organizations/departments')} />
        <MenuItemLink to="/professions" primaryText="Professions" onClick={onMenuClick} leftIcon={getEntityIcon('professions')} />
        <MenuItemLink to="/productions" primaryText="Productions" onClick={onMenuClick} leftIcon={getEntityIcon('productions')} exact />
        <MenuItemLink to="/productions/castandcrew" primaryText="Productions Cast & Crew" onClick={onMenuClick} leftIcon={getEntityIcon('productions/castandcrew')} exact />
        <MenuItemLink to="/reviews" primaryText="Reviews" onClick={onMenuClick} leftIcon={getEntityIcon('reviews')} />
        <MenuItemLink to="/stagingTypes" primaryText="Staging types" onClick={onMenuClick} leftIcon={getEntityIcon('stagingTypes')} />
        <MenuItemLink to="/venues" primaryText="Venues" onClick={onMenuClick} leftIcon={getEntityIcon('venues')} />
        <MenuItemLink to="/whitelist/requests" primaryText="Whitelist requests" onClick={onMenuClick} leftIcon={getEntityIcon('whitelist/requests')} />
        <MenuItemLink to="/works" primaryText="Works" onClick={onMenuClick} leftIcon={getEntityIcon('works')} />
        <MenuItemLink to="/workTypes" primaryText="Works types" onClick={onMenuClick} leftIcon={getEntityIcon('workTypes')} />
      </SubMenu>
      <SubMenu handleToggle={() => handleMenu('profiles')} isOpen={menu === 'profiles'} sidebarIsOpen={open} name="Profiles">
        <MenuItemLink to="/agencies" primaryText="Agencies" onClick={onMenuClick} leftIcon={getEntityIcon('agencies')} />
        <MenuItemLink to="/organizations" primaryText="Organizations" onClick={onMenuClick} leftIcon={getEntityIcon('organizations')} />
        <MenuItemLink to="/profiles" primaryText="Profiles" onClick={onMenuClick} leftIcon={getEntityIcon('profiles')} />
      </SubMenu>
      <SubMenu handleToggle={() => handleMenu('cuetv')} isOpen={menu === 'cuetv'} sidebarIsOpen={open} name="CueTV">
        <MenuItemLink to="/cuetv/customers/all" primaryText="All users" onClick={onMenuClick} leftIcon={getEntityIcon('cuetv/customers/all')} />
        <MenuItemLink to="/cuetv/customers/operabase" primaryText="Operabase users" onClick={onMenuClick} leftIcon={getEntityIcon('cuetv/customers/operabase')} />
        <MenuItemLink to="/users/cuetv/customers" primaryText="Operabase PRO users" onClick={onMenuClick} leftIcon={getEntityIcon('users/cuetv/customers')} />
        <MenuItemLink to="/cuetv/subscriptions" primaryText="Subscriptions" onClick={onMenuClick} leftIcon={getEntityIcon('cuetv/subscriptions')} />
      </SubMenu>
      <SubMenu handleToggle={() => handleMenu('finance')} isOpen={menu === 'finance'} sidebarIsOpen={open} name="Finance">
        <MenuItemLink to="/addons" primaryText="Addons" onClick={onMenuClick} leftIcon={getEntityIcon('addons')} />
        <MenuItemLink to="/coupons" primaryText="Coupons" onClick={onMenuClick} leftIcon={getEntityIcon('coupons')} />
        <MenuItemLink to="/invoices/creditNotes" primaryText="Credit notes" onClick={onMenuClick} leftIcon={getEntityIcon('invoices/creditNotes')} />
        <MenuItemLink to="/invoices" primaryText="Invoices" onClick={onMenuClick} leftIcon={getEntityIcon('invoices')} />
        <MenuItemLink to="/orders" primaryText="Orders" onClick={onMenuClick} leftIcon={getEntityIcon('orders')} />
        <MenuItemLink to="/payout/export" primaryText="Payout export" onClick={onMenuClick} leftIcon={getEntityIcon('payout/export')} />
        <MenuItemLink to="/products" primaryText="Products" onClick={onMenuClick} leftIcon={getEntityIcon('products')} />
        <MenuItemLink to="/users/subscriptions/unbilledCharges" primaryText="Unbilled charges" onClick={onMenuClick} leftIcon={getEntityIcon('users/subscriptions/unbilledCharges')} />
      </SubMenu>
      <SubMenu handleToggle={() => handleMenu('users')} isOpen={menu === 'users'} sidebarIsOpen={open} name="Users">
        <MenuItemLink to="/users" primaryText="Users" onClick={onMenuClick} leftIcon={getEntityIcon('users')} />
        <MenuItemLink to="/users/registrations" primaryText="Registrations" onClick={onMenuClick} leftIcon={getEntityIcon('users/registrations')} />
        <MenuItemLink to="/users/subscriptions" primaryText="Subscriptions" onClick={onMenuClick} leftIcon={getEntityIcon('users/subscriptions')} />
        <MenuItemLink to="/profiles/profileaccessrequests" primaryText="Profile access requests" onClick={onMenuClick} leftIcon={getEntityIcon('profiles/profileaccessrequests')} />
        <MenuItemLink to="/auth/logs" primaryText="Logs" onClick={onMenuClick} leftIcon={getEntityIcon('auth/logs')} />
        <MenuItemLink to="/mails" primaryText="Mails" onClick={onMenuClick} leftIcon={getEntityIcon('mails')} />
        <MenuItemLink to="/users/mergecustomer" primaryText="Merge Customer" onClick={onMenuClick} leftIcon={getEntityIcon('users/mergecustomer')} />
        {isAdmin && permissions && permissions.includes('userStats.get.all') && (
          <MenuItemLink to="/users/stats" primaryText="Payment info" onClick={onMenuClick} leftIcon={getEntityIcon('users/stats')} />
        )}
        <MenuItemLink to="/profiles/claims" primaryText="Profile claims" onClick={onMenuClick} leftIcon={getEntityIcon('profiles/claims')} />
        <MenuItemLink to="/users/supportrequests" primaryText="Support Requests" onClick={onMenuClick} leftIcon={getEntityIcon('users/supportrequests')} />
      </SubMenu>
      <SubMenu handleToggle={() => handleMenu('validations')} isOpen={menu === 'validations'} sidebarIsOpen={open} name="Validations">
        <MenuItemLink to="/validations/change-requests" primaryText="Change Requests" onClick={onMenuClick} leftIcon={getEntityIcon('validations/change-requests')} />
        <MenuItemLink to="/validations" primaryText="Entity Queue" onClick={onMenuClick} leftIcon={getEntityIcon('validations')} />
        <MenuItemLink to="/validations/duplicates-entities" primaryText="Duplicates Queue" onClick={onMenuClick} leftIcon={getEntityIcon('validations/duplicates-entities')} />
        <MenuItemLink to="/validations/dashboard" primaryText="Dashboard" onClick={onMenuClick} leftIcon={getEntityIcon('validations/dashboard')} />
        <MenuItemLink to="/validations/performances" primaryText="User Production Requests" onClick={onMenuClick} leftIcon={getEntityIcon('validations/performances')} />
        <MenuItemLink to="/validations/production-validations" primaryText="Production Validations" onClick={onMenuClick} leftIcon={getEntityIcon('validations/production-validations')} />
        <MenuItemLink to="/validations/redmask-requests" primaryText="Redmask Requests" onClick={onMenuClick} leftIcon={getEntityIcon('validations/redmask-requests')} />
      </SubMenu>
      <SubMenu handleToggle={() => handleMenu('taskQueue')} isOpen={menu === 'taskQueue'} sidebarIsOpen={open} name="Task Queue">
        <MenuItemLink to="/task_queues/agency_artist_enrichment" primaryText="Agency Artists Enrich" onClick={onMenuClick} leftIcon={getEntityIcon('task_queues/agency_artist_enrichment')} />
        <MenuItemLink to="/curationlist" primaryText="Artists Whitelisted" onClick={onMenuClick} leftIcon={getEntityIcon('curationlist')} />
        <MenuItemLink to="/task_queues/artistFreeTrials" primaryText="Artists with new subscription" onClick={onMenuClick} leftIcon={getEntityIcon('task_queues/artistFreeTrials')} />
        <MenuItemLink to="/task_queues/artistRenewals" primaryText="Artists renewals" onClick={onMenuClick} leftIcon={getEntityIcon('task_queues/artistRenewals')} />
        <MenuItemLink
          to="/task_queues/artistsWithoutProfileAccess"
          primaryText="Artists Without Profile Access"
          onClick={onMenuClick}
          leftIcon={getEntityIcon('task_queues/artistsWithoutProfileAccess')}
        />
        <MenuItemLink to="/task_queues/concertVarious" primaryText="Concert Various" onClick={onMenuClick} leftIcon={getEntityIcon('task_queues/concertVarious')} />
        <MenuItemLink to="/task_queues/external_reference_validations" primaryText="Knowledge Panel" onClick={onMenuClick} leftIcon={getEntityIcon('task_queues/external_reference_validations')} />
        <MenuItemLink to="/task_queues/magazine_export" primaryText="Magazine Export" onClick={onMenuClick} leftIcon={getEntityIcon('task_queues/magazine_export')} />
        <MenuItemLink to="/task_queues/data_qc" primaryText="Data QC: performance" onClick={onMenuClick} leftIcon={getEntityIcon('task_queues/data_qc')} />
        <MenuItemLink to="/task_queues/missingCanonical" primaryText="Missing Canonical" onClick={onMenuClick} leftIcon={getEntityIcon('task_queues/missingCanonical')} />
        <MenuItemLink to="/task_queues/missingProducer" primaryText="Missing Producer" onClick={onMenuClick} leftIcon={getEntityIcon('task_queues/missingProducer')} />
        <MenuItemLink to="/task_queues/missingSeason" primaryText="Missing Seasons" onClick={onMenuClick} leftIcon={getEntityIcon('task_queues/missingSeason')} />
        <MenuItemLink to="/task_queues/missing_program" primaryText="Missing programme" onClick={onMenuClick} leftIcon={getEntityIcon('task_queues/missing_program')} />
        <MenuItemLink to="/task_queues/multipleCast" primaryText="Multiple Cast" onClick={onMenuClick} leftIcon={getEntityIcon('task_queues/multipleCast')} />
        <MenuItemLink to="/task_queues/wrong_profession" primaryText="Wrong Profession" onClick={onMenuClick} leftIcon={getEntityIcon('task_queues/wrong_profession')} />
      </SubMenu>
      {hasFingerPrintPermission && (
        <SubMenu handleToggle={() => handleMenu('fingerprint')} isOpen={menu === 'fingerprint'} sidebarIsOpen={open} name="Fingerprint">
          <MenuItemLink to="/fingerprint/calculations" primaryText="Calculations" onClick={onMenuClick} leftIcon={getEntityIcon('validations/convert')} />
        </SubMenu>
      )}
      <SubMenu handleToggle={() => handleMenu('insights')} isOpen={menu === 'insights'} sidebarIsOpen={open} name="Insights">
        <MenuItemLink to="/insights" primaryText="Insights" onClick={onMenuClick} leftIcon={getEntityIcon('validations/convert')} />
      </SubMenu>
      <SubMenu handleToggle={() => handleMenu('developers')} isOpen={menu === 'developers'} sidebarIsOpen={open} name="Developers">
        <MenuItemLink to="/domains" primaryText="Domains" onClick={onMenuClick} leftIcon={getEntityIcon('domains')} />
        <MenuItemLink to="/system/data_inconsistencies" primaryText="Data Inconsistencies" onClick={onMenuClick} leftIcon={getEntityIcon('system/data_inconsistencies')} />
        <MenuItemLink to="/scrape_requests" primaryText="Scrape Request" onClick={onMenuClick} leftIcon={getEntityIcon('validations/convert')} />
      </SubMenu>
      <SubMenu handleToggle={() => handleMenu('others')} isOpen={menu === 'others'} sidebarIsOpen={open} name="Others">
        <MenuItemLink to="/testimonials" primaryText="Testimonials" onClick={onMenuClick} leftIcon={getEntityIcon('testimonials')} />
      </SubMenu>
      <div className={classes.infoItem}>Version: {BUILD_NUMBER}</div>
      <div className={classes.infoItem}>Released On: {RELEASE_DATE}</div>
      <Responsive small={logout} medium={null} />
    </div>
  );
};

Menu.propTypes = {
  onMenuClick: PropTypes.func,
  logout: PropTypes.object,
};

const mapStateToProps = (state) => ({
  open: state.admin.ui.sidebarOpen,
  theme: state.theme,
});

const enhance = compose(withRouter, withStyles(styles), connect(mapStateToProps, {}));

export default enhance(Menu);
