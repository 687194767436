import React from 'react';
import { Show, List, ReferenceInput, AutocompleteInput, Filter, TextInput, Datagrid, TextField, SimpleShowLayout, FunctionField, Link, SelectInput, DateField, Create, SimpleForm } from 'react-admin';
import { size, head } from 'lodash';
import { Breadcrumbs } from '../components/Breadcrumbs';
import CustomDateField from '../components/customFields/CustomDateField';
import { WHITELIST_ENTITIES_STATUS, WHITELIST_ENTITIES } from '../constants/entities';
import storage from '../utils/storage';

const WhitelistRequestsFilter = (props) => (
  <Filter {...props}>
    <SelectInput source="entity_type" choices={WHITELIST_ENTITIES} alwaysOn />
    <SelectInput label="Is whitelisted" source="new_value" choices={WHITELIST_ENTITIES_STATUS} alwaysOn />
  </Filter>
);

export const WhitelistRequestsCreate = (props) => {
  const userId = storage.getUserId();

  const renderProfile = (entity) => {
    const profession = size(entity?.professions) ? `(${head(entity?.professions)?.profession?.name})` : '';

    return `(${entity.id}) - ${entity.name} (${entity?.profileType?.slug})${profession}`;
  };

  const validateForm = (values) => {
    const errors = {};

    if (!values.entity_id) {
      errors.entity_id = 'Profile is required';
    }

    if (!values.change_reason) {
      errors.change_reason = 'Change reason is required';
    }

    return errors;
  };

  const transform = (data) => ({
    ...data,
    new_value: true,
    requested_by: userId,
    entity_type: 'artist',
  });

  return (
    <Create title="Create whitelist request" {...props} transform={transform}>
      <SimpleForm submitOnEnter={false} validate={validateForm}>
        <ReferenceInput label="Profiles" source="entity_id" reference="profiles" filterToQuery={(searchText) => ({ query: searchText })}>
          <AutocompleteInput optionText={renderProfile} />
        </ReferenceInput>
        <TextInput source="change_reason" />
      </SimpleForm>
    </Create>
  );
};

export const WhitelistRequestsShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="ID" />
      <FunctionField
        label="Profile ID"
        render={(record) =>
          record?.whitelistedEntity?.entity_type === 'artist' ? (
            <Link onClick={(e) => e.stopPropagation()} to={`/profiles/${record?.whitelistedEntity?.entity_id}/show`}>
              {record?.whitelistedEntity?.entity_id}
            </Link>
          ) : null
        }
      />
      <FunctionField
        label="Organization ID"
        render={(record) =>
          record?.whitelistedEntity?.entity_type === 'organization' ? (
            <Link onClick={(e) => e.stopPropagation()} to={`/organizations/${record?.whitelistedEntity?.entity_id}/show`}>
              {record?.whitelistedEntity?.entity_id}
            </Link>
          ) : null
        }
      />
      <FunctionField
        label="Requested by"
        render={(record) => (
          <Link onClick={(e) => e.stopPropagation()} to={`/users/${record?.requested_by}/show`}>
            {record?.requested_by}
          </Link>
        )}
      />
      <TextField source="new_value" label="Is whitelisted" />
      <DateField source="updated_at" showTime />
      <DateField source="created_at" showTime />
      <DateField source="deleted_at" showTime />
      <FunctionField
        label="Decision by"
        render={(record) => (
          <Link onClick={(e) => e.stopPropagation()} to={`/users/${record?.decision_by}/show`}>
            {record?.decision_by}
          </Link>
        )}
      />
      <TextField source="decision_status" />
      <TextField source="decision_reason" />
    </SimpleShowLayout>
  </Show>
);

export const WhitelistRequestsList = (props) => (
  <List
    exporter={false}
    title={<Breadcrumbs {...props} />}
    filters={<WhitelistRequestsFilter />}
    bulkActionButtons={false}
    filterDefaultValues={{ decision_status: 'pending,approved,rejected', entity_type: 'artist' }}
    {...props}
  >
    <Datagrid rowClick="show">
      <TextField source="id" sortable={false} />
      <FunctionField
        label="Profile ID"
        render={(record) =>
          record?.whitelistedEntity?.entity_type === 'artist' ? (
            <Link onClick={(e) => e.stopPropagation()} to={`/profiles/${record?.whitelistedEntity?.entity_id}/show`}>
              {record?.whitelistedEntity?.entity_id}
            </Link>
          ) : null
        }
      />
      <FunctionField
        label="Organization ID"
        render={(record) =>
          record?.whitelistedEntity?.entity_type === 'organization' ? (
            <Link onClick={(e) => e.stopPropagation()} to={`/organizations/${record?.whitelistedEntity?.entity_id}/show`}>
              {record?.whitelistedEntity?.entity_id}
            </Link>
          ) : null
        }
      />
      <FunctionField
        label="Requested by"
        render={(record) => (
          <Link onClick={(e) => e.stopPropagation()} to={`/users/${record?.requested_by}/show`}>
            {record?.requested_by}
          </Link>
        )}
      />
      <TextField source="change_reason" sortable={false} />
      <TextField source="new_value" label="Is whitelisted" sortable={false} />
      <CustomDateField source="updated_at" sortable={false} />
    </Datagrid>
  </List>
);

export default WhitelistRequestsList;
