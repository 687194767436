export const CREATOR_PROFESSIONS = [
  { id: 'composer', name: 'Composer' },
  { id: 'librettist', name: 'Librettist' },
];

export const PROFESSIONS = {
  SINGER: 1,
  INSTRUMENTALIST: 3,
};

export const WORK_ROLE_TYPES = {
  CAST: 'cast',
  CREW: 'crew',
  OTHER: 'other',
  INSTRUMENTALIST: 'instrumentalist',
  OTHER_INSTRUMENTALIST: 'other_instrumentalist',
};

export const WORK_ROLES_SECTIONS = [
  {
    heading: 'Character Roles',
    type: WORK_ROLE_TYPES.CAST,
    reference: 'works/roles',
    target: 'work_id',
    sort: { field: 'roleOrder', order: 'ASC' },
    filter: { include_production_count: true, work_role_type_id: 1, is_standard: true },
    columnsValues: {
      firstColumn: 'original_name',
      secondColumn: 'workRoleType.name',
    },
    columnsHeadings: {
      firstColumn: 'Role name',
      thirdColumn: 'Role Professions',
      fifthColumn: 'Production No',
      sixthColumn: 'Actions',
    },
  },
  {
    heading: 'Other Character Roles',
    type: WORK_ROLE_TYPES.OTHER,
    reference: 'works/roles',
    target: 'work_id',
    sort: { field: 'roleOrder', order: 'ASC' },
    filter: { include_production_count: true, work_role_type_id: 1, is_standard: false },
    columnsValues: {
      firstColumn: 'original_name',
      secondColumn: 'workRoleType.name',
    },
    columnsHeadings: {
      firstColumn: 'Role name',
      secondColumn: 'Role type',
      thirdColumn: 'Role Professions',
      fifthColumn: 'Production No',
      sixthColumn: 'Actions',
    },
  },
  {
    heading: 'Crew',
    type: WORK_ROLE_TYPES.CREW,
    reference: 'productions/aggregations',
    filter: { aggregation_type: 'profession_id', validation_status: 'approved' },
    columnsHeadings: {
      firstColumn: 'Crew Professions',
      secondColumn: 'Production No',
    },
    columnsValues: {
      firstColumn: 'name',
      secondColumn: 'total',
    },
  },
  {
    id: PROFESSIONS.INSTRUMENTALIST,
    heading: 'Instrumentals',
    type: WORK_ROLE_TYPES.INSTRUMENTALIST,
    reference: 'works/roles',
    target: 'work_id',
    sort: { field: 'roleOrder', order: 'ASC' },
    filter: { include_production_count: true, work_role_type_id: 3, is_standard: true },
    columnsValues: {
      firstColumn: 'instrumentRole.name',
      secondColumn: 'instrumentRole.instrument.name',
    },
    columnsHeadings: {
      firstColumn: 'Instrument Name',
      secondColumn: 'Instrument Type',
      thirdColumn: 'Instrument Professions',
      fourthColumn: 'Old Name',
      fifthColumn: 'Production No',
    },
  },
  {
    id: PROFESSIONS.INSTRUMENTALIST,
    heading: 'Other Instrumentals',
    type: WORK_ROLE_TYPES.OTHER_INSTRUMENTALIST,
    reference: 'works/roles',
    sort: { field: 'roleOrder', order: 'ASC' },
    filter: { include_production_count: true, work_role_type_id: 3, is_standard: false },
    columnsValues: {
      firstColumn: 'instrumentRole.name',
      secondColumn: 'instrumentRole.instrument.name',
    },
    columnsHeadings: {
      firstColumn: 'Instrument Name',
      secondColumn: 'Instrument Type',
      thirdColumn: 'Instrument Professions',
      fourthColumn: 'Old Name',
      fifthColumn: 'Production No',
    },
  },
];
