import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';

import axios from 'axios';
// import apiClient from '../../../utils/api';
import blitzerApiClient from '../../../utils/blitzerApi';

const fieldConfig = {
  _id: 'ID',
  type: 'Type',
  parameter: 'Parameter',
  parameterType: 'Parameter Type',
  hardWallPercent: 'Hard Wall Percent',
  dynamicUseThreshold: 'Dynamic Soft Wall Count',
  softWallPageViewCount: 'Dynamic Page View Count',
  softWallIgnoreCount: 'Soft Wall Ignore Count',
  softWallThreshold: 'SW Threshold',
  hardWallThreshold: 'HW Threshold',
};

// const BLITZER_API_URL = "https://dev-blitzer-event-api.truelinked.net/"
// const BLITZER_SECRET = 'secret'

const FingerprintCalculate = () => {
  const [data, setData] = useState([]);
  const [dataRefresh, setDataRefresh] = useState(false);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BLITZER_API_URL}fingerprint/calculations`, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-key': `${process.env.REACT_APP_BLITZER_API_SECRET}`
      }
    }).then((res) => {
      setData(res.data?.calculation);
      setDataRefresh(false);
    });
  }, [dataRefresh]);

  const handleSubmit = (index) => {
    if (data[index].isNew) {
      axios.post(`${process.env.REACT_APP_BLITZER_API_URL}fingerprint/calculations`, data[index], {
        headers: {
          'Content-Type': 'application/json',
          'x-access-key': `${process.env.REACT_APP_BLITZER_API_SECRET}`
        }}
      ).then((res) => {
        setDataRefresh(true);
      });
    } else {
      axios.put(`${process.env.REACT_APP_BLITZER_API_URL}fingerprint/calculations/${data[index]['_id']}`, data[index], {
        headers: {
          'Content-Type': 'application/json',
          'x-access-key': process.env.REACT_APP_BLITZER_API_SECRET
        }}
      ).then((res) => {
        setDataRefresh(true);
      });
    }
  };

  const handleChange = (event, index) => {
    const newData = [...data];
    newData[index][event.target.name] = event.target.value;
    setData(newData);
  };

  const initiateCalculation = async (endpoint) => {
    try {
      await blitzerApiClient.get(endpoint);
      setDataRefresh(true);
    } catch (error) {
      // Handle error
      console.error('Error initiating calculation:', error);
    }
  };

  const handleAddRow = () => {
    setData([
      ...data,
      {
        type: '',
        parameter: '',
        parameterType: '',
        hardWallPercent: '',
        dynamicUseThreshold: '',
        softWallPageViewCount: '',
        softWallIgnoreCount: '',
        softWallThreshold: '',
        hardWallThreshold: '',
        isEditing: false,
        isNew: true,
      },
    ]);
  };

  const handleEditRow = (index) => {
    const newData = [...data];
    newData[index].isEditing = true;
    newData[index].isNew = false;
    setData(newData);
  };

  const handleUpdateRow = (index) => {
    const newData = [...data];
    newData[index].isEditing = false;
    setData(newData);
    handleSubmit(index);
  };

  const renderTextField = (name, value, onChange, index) => (
    <TextField name={name} value={value} onChange={(event) => onChange(event, index)} disabled={name === '_id' || (!data[index].isNew && !data[index].isEditing)} />
  );

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container direction="row" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button type="button" onClick={handleAddRow} variant="contained" size="small" style={{ textTransform: 'inherit' }}>
              Add row
            </Button>
            <div>
              <Button type="button" onClick={() => initiateCalculation('fingerprint/startCalculation')} variant="contained" size="small" style={{ marginLeft: 10, textTransform: 'inherit' }}>
                Initiate main calculation
              </Button>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table component={Paper}>
              <TableHead>
                <TableRow>
                  {Object.keys(fieldConfig).map((key) => (
                    <TableCell key={key}>{fieldConfig[key]}</TableCell>
                  ))}
                  <TableCell>Edit</TableCell>
                  <TableCell>Update</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item, index) => (
                  <TableRow key={index}>
                    {Object.keys(fieldConfig).map((key) => (
                      <TableCell key={key}>{renderTextField(key, item[key], handleChange, index)}</TableCell>
                    ))}
                    <TableCell>
                      <Button onClick={() => handleEditRow(index)}>Edit</Button>
                    </TableCell>
                    <TableCell>{item._id ? <Button onClick={() => handleUpdateRow(index)}>Update</Button> : <Button onClick={() => handleSubmit(index)}>Add</Button>}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Button style={{ marginTop: 10, textTransform: 'inherit' }} type="button" variant="contained" size="small" onClick={handleAddRow}>
        Add row
      </Button>
    </form>
  );
};

export default FingerprintCalculate;
