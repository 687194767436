import React, { useState, useEffect } from 'react';
import * as dayjs from 'dayjs';
import { Loading, useNotify } from 'react-admin';
import { map, orderBy } from 'lodash';
import { useHistory } from 'react-router-dom';
import { makeStyles, FormControl, TextField } from '@material-ui/core';
import ComposeChart from './charts/ComposeChart';
import FingerprintDataModal from './Fingerprint/FingerprintDataModal';
import { getFingerprintMetrics } from '../utils/metrics';
import apiClient from '../utils/api';

const styles = () => ({
  dashboardContainer: {
    margin: 10,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    width: '100%',
    padding: '10px 0',
  },
  filterSection: {
    display: 'flex',
    flexDirection: 'row',
  },
  spacingLeft: { marginLeft: 10, minWidth: 140 },
  charts: {
    marginTop: 20,
    display: 'flex',
    flexWrap: 'wrap',

    '&>div': {
      width: 500,
      minWidth: 300,
      minHeight: 350,
      maxHeight: 350,
      margin: '10px',
      backgroundColor: '#fff',
      borderRadius: 4,
      boxShadow: '0px 1px 22px -12px #607d8b',
      padding: '0 20px',
      resize: 'both',
      overflow: 'hidden',
    },
  },
});

const FingerprintMetrics = () => {
  const classes = makeStyles(styles)();
  const history = useHistory();
  const notify = useNotify();

  const searchParams = new URLSearchParams(history.location.search);
  const defaultDateRange = { from_date: searchParams?.get('from_date'), to_date: searchParams?.get('to_date') };
  const [selectedDateRange, setSelectedDateRange] = useState({
    from_date: defaultDateRange?.from_date || dayjs().add(-6, 'day').format('YYYY-MM-DD'),
    to_date: defaultDateRange?.to_date || dayjs().format('YYYY-MM-DD'),
  });
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [metrics, setMetrics] = useState({});
  const [open, setOpen] = useState(false);
  const [activeChartData, setActiveChartData] = useState({});

  const handleBarClick = (chartData, metric) => {
    if (metric?.isModal) {
      setOpen(true);
      setActiveChartData({ ...chartData, title: metric?.title, resource: metric?.resource, filter: metric?.filter });
    }

    return null;
  };

  useEffect(() => {
    setLoading(true);
    apiClient
      .get('metrics/fingerprint-metrics', { params: { from_date: selectedDateRange?.from_date, to_date: selectedDateRange?.to_date } })
      .then((res) => {
        setLoading(false);
        const chartData = orderBy(
          map(res?.data, (value, key) => ({ name: key, ...value })),
          'name',
          'asc',
        );
        setData(chartData);
        const metricsDetails = getFingerprintMetrics(chartData);
        setMetrics(metricsDetails);
      })
      .catch((err) => {
        setData({});
        setMetrics({});
        setLoading(false);
        notify(err?.response?.data?.message, 'warning');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange]);

  useEffect(() => {
    searchParams.set('from_date', selectedDateRange?.from_date);
    searchParams.set('to_date', selectedDateRange?.to_date);
    const newSearch = searchParams.toString();
    history.push({ search: newSearch });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange]);

  return (
    <div className={classes.dashboardContainer}>
      <div className={classes.filterSection}>
        <FormControl className={classes.filterSection} size="small">
          <TextField
            disabled={loading}
            value={selectedDateRange.from_date}
            onChange={(e) => {
              setSelectedDateRange({ ...selectedDateRange, from_date: e?.target?.value });
            }}
            type="date"
            label="From"
            variant="filled"
            size="small"
          />
          <TextField
            disabled={loading}
            className={classes.spacingLeft}
            value={selectedDateRange.to_date}
            onChange={(e) => {
              setSelectedDateRange({ ...selectedDateRange, to_date: e?.target?.value });
            }}
            type="date"
            label="To"
            variant="filled"
            size="small"
          />
        </FormControl>
      </div>
      {!loading ? (
        <div className={classes.charts}>
          {map(metrics, (metric) => (
            <div>
              <h4>{metric?.title}</h4>
              <ComposeChart data={data} lines={metric?.lines} margin={{ bottom: 10 }} onClick={(cData) => handleBarClick(cData, metric)} />
            </div>
          ))}
        </div>
      ) : (
        <Loading />
      )}
      <FingerprintDataModal open={open} onClose={() => setOpen(false)} chartData={activeChartData} />
    </div>
  );
};

export default FingerprintMetrics;
