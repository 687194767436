import React, { useState, useEffect, useMemo } from 'react';
import * as dayjs from 'dayjs';
import { map, get, capitalize, join, split } from 'lodash';
import { Title, Loading, useNotify } from 'react-admin';
import cn from 'classnames';
import { makeStyles, FormControl, MenuItem, TextField, Table, TableCell, TableBody, TableRow, Paper, Button } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useHistory } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';

import { Breadcrumbs } from '../components/Breadcrumbs';
import { TASK_QUEUE_METRICS_TYPE } from '../constants/metrics';
import apiClient from '../utils/api';
import { getTaskQueueMetrics } from '../utils/metrics';

const styles = () => ({
  filters: {
    display: 'flex',
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filterSection: {
    display: 'flex',
    flexDirection: 'row',
  },
  spacingLeft: { marginLeft: 10, minWidth: 140 },
  dataGridContainer: {
    '& .ag-header-group-text': {
      textTransform: 'capitalize',
    },
    '& .ag-header-cell-label': {
      justifyContent: 'center',
    },
    '& .ag-header-group-cell-label': {
      justifyContent: 'center',
    },
  },
  tooltip: {
    backgroundColor: 'white',
    padding: '3px 10px',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    borderRadius: 4,
  },
  button: {
    color: '#ff5722',
  },
  icon: {
    fontSize: 20,
    paddingRight: 5,
  },
});

const CustomTooltip = (props) => {
  const classes = makeStyles(styles)();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = useMemo(() => props.api.getDisplayedRowAtIndex(props.rowIndex).data, []);

  return (
    <div className={classes.tooltip}>
      <p>{data?.user?.email}</p>
    </div>
  );
};

export const TaskQueueMetricsModelShow = (props) => {
  const classes = makeStyles(styles)();
  const history = useHistory();
  const notify = useNotify();

  const searchParams = new URLSearchParams(history.location.search);
  const defaultMetricsType = searchParams?.get('metrics_type');
  const defaultDateRange = { from_date: searchParams?.get('from_date'), to_date: searchParams?.get('to_date') };

  const [isLoading, setIsLoading] = useState(false);
  const [gridApi, setGridApi] = useState(null); // eslint-disable-line no-unused-vars
  const [metricsType, setMetricsType] = useState(defaultMetricsType || TASK_QUEUE_METRICS_TYPE[0]?.id);
  const [selectedDateRange, setSelectedDateRange] = useState({
    from_date: defaultDateRange?.from_date || dayjs().add(-6, 'day').format('YYYY-MM-DD'),
    to_date: defaultDateRange?.to_date || dayjs().format('YYYY-MM-DD'),
  });
  const [data, setData] = useState([]);

  const getTaskQueueMetricsData = () => {
    setIsLoading(true);
    apiClient
      .get('metrics/task-queue-metrics', {
        params: { from_date: selectedDateRange?.from_date, to_date: selectedDateRange?.to_date, metrics_type: metricsType },
      })
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        notify(err?.response?.data?.message, 'warning');
      });
  };

  useEffect(() => {
    document.title = 'Task queue metrics';
  }, []);

  useEffect(() => {
    searchParams.set('metrics_type', metricsType);
    searchParams.set('from_date', selectedDateRange?.from_date);
    searchParams.set('to_date', selectedDateRange?.to_date);
    const newSearch = searchParams.toString();
    history.push({ search: newSearch });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metricsType, selectedDateRange]);

  useEffect(() => {
    getTaskQueueMetricsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange, metricsType]);

  const { columns, rows } = getTaskQueueMetrics(data, metricsType);

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      minWidth: 100,
      tooltipComponent: CustomTooltip,
      cellStyle: { 'padding-left': 0, 'border-right': '1px solid #babfc7' },
      align: 'middle',
    }),
    [],
  );

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onDownload = () => {
    const params = {
      fileName: `task_queue_metrics_from_${selectedDateRange.from}_to_${selectedDateRange.to}`,
    };
    gridApi.exportDataAsCsv(params);
  };

  const renderTaskQueue = () => {
    if (metricsType === 'common') {
      return (
        <Table component={Paper}>
          <TableBody>
            {map(get(data, 'task-queue-metrics'), (item, key) => (
              <TableRow>
                <TableCell>{capitalize(join(split(key, '_'), ' '))}</TableCell>
                <TableCell>{item}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    }

    return (
      <div className={cn('ag-theme-alpine', classes.dataGridContainer)} style={{ height: '83vh', width: '100%' }}>
        <AgGridReact columnDefs={columns} rowData={rows} defaultColDef={defaultColDef} tooltipShowDelay={0} tooltipHideDelay={2000} onGridReady={onGridReady} />
      </div>
    );
  };

  return (
    <div>
      <Title title={<Breadcrumbs {...props} />} />
      <div className={classes.filters}>
        <FormControl className={classes.filterSection} size="small">
          <TextField
            disabled={isLoading}
            className={classes.spacingLeft}
            value={metricsType}
            select
            onChange={(e) => setMetricsType(e?.target?.value)}
            label="Metrics type"
            variant="filled"
            size="small"
          >
            {TASK_QUEUE_METRICS_TYPE.map((option) => (
              <MenuItem key={option.id} value={option?.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            disabled={isLoading}
            className={classes.spacingLeft}
            value={selectedDateRange.from_date}
            onChange={(e) => {
              setSelectedDateRange({ ...selectedDateRange, from_date: e?.target?.value });
            }}
            type="date"
            label="From"
            variant="filled"
            size="small"
          />
          <TextField
            disabled={isLoading}
            className={classes.spacingLeft}
            value={selectedDateRange.to_date}
            onChange={(e) => {
              setSelectedDateRange({ ...selectedDateRange, to_date: e?.target?.value });
            }}
            type="date"
            label="To"
            variant="filled"
            size="small"
          />
        </FormControl>
        {metricsType !== 'common' && (
          <Button disabled={isLoading} size="small" className={classes.button} onClick={() => onDownload()}>
            <GetAppIcon className={classes.icon} /> Export
          </Button>
        )}
      </div>
      {!isLoading ? renderTaskQueue() : <Loading />}
    </div>
  );
};

export default TaskQueueMetricsModelShow;
