import React from 'react';
import { Datagrid, List, Show, TextField, SimpleShowLayout, FunctionField, TextInput, Edit, Create, SimpleForm } from 'react-admin';
import { Breadcrumbs } from '../components/Breadcrumbs';

export const AddonsList = (props) => (
  <List exporter={false} {...props} bulkActionButtons={false} title={<Breadcrumbs {...props} />}>
    <Datagrid rowClick="show">
      <TextField source="id" sortable={false} />
      <TextField source="external_id" sortable={false} />
      <TextField source="name" sortable={false} />
      <TextField source="invoice_name" sortable={false} />

      <TextField source="status" sortable={false} />
      <TextField source="charge_type" sortable={false} />

      <TextField source="price" sortable={false} />
      <TextField source="currency_code" />
      <TextField source="pricing_model" />
    </Datagrid>
  </List>
);

export const AddonsShow = (props) => (
  <Show {...props} title={<Breadcrumbs {...props} />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="external_id" />
      <TextField source="name" />
      <TextField source="invoice_name" />
      <TextField source="status" />
      <TextField source="charge_type" />

      <FunctionField label="Price" render={(item) => (item?.price != null ? `€${parseFloat(item.price / 100).toFixed(2)}` : 'N/A')} />

      <TextField source="currency_code" />
      <TextField source="pricing_model" />

      <FunctionField label="Period" render={(addon) => (addon?.period ? `${addon.period} ${addon.period_unit}` : 'N/A')} />

      <TextField source="enabled_in_portal" />
      <TextField source="is_shippable" />
      <TextField source="taxable" />
      <TextField source="show_description_in_invoices" />
      <TextField source="show_description_in_quotes" />

      <TextField source="resource_version" />
    </SimpleShowLayout>
  </Show>
);

export const AddonsCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="external_id" placeholder="Leave empty to auto-generate" />
      <TextInput source="name" />
      <TextInput source="invoice_name" />
      <TextInput source="status" />
      <TextInput source="charge_type" />

      <TextInput source="price" label="Price, €" />

      <TextInput source="currency_code" />
      <TextInput source="pricing_model" />

      <TextInput source="period" />
      <TextInput source="period_unit" />

      <TextInput source="enabled_in_portal" />
      <TextInput source="is_shippable" />
      <TextInput source="taxable" />
      <TextInput source="show_description_in_invoices" />
      <TextInput source="show_description_in_quotes" />

      <TextInput source="resource_version" />
    </SimpleForm>
  </Create>
);

export const AddonsEdit = (props) => (
  <Edit {...props} title={<Breadcrumbs {...props} />}>
    <SimpleForm>
      <TextInput source="external_id" disabled />
      <TextInput source="name" />
      <TextInput source="invoice_name" />
      <TextInput source="status" />
      <TextInput source="charge_type" />

      <TextInput source="price" label="Price, €" />

      <TextInput source="currency_code" />
      <TextInput source="pricing_model" />

      <TextInput source="period" />
      <TextInput source="period_unit" />

      <TextInput source="enabled_in_portal" />
      <TextInput source="is_shippable" />
      <TextInput source="taxable" />
      <TextInput source="show_description_in_invoices" />
      <TextInput source="show_description_in_quotes" />

      <TextInput source="resource_version" />
    </SimpleForm>
  </Edit>
);

export default AddonsList;
