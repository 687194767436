import React, { useState, useEffect } from 'react';
import { useForm } from 'react-final-form';
import { AutocompleteInput, FormDataConsumer, ReferenceInput } from 'react-admin';
import head from 'lodash/head';
import { Button, Checkbox, FormControlLabel } from '@material-ui/core';

const Profession = ({ formData, ...rest }) => {
  const form = useForm();
  const [professionInputs, setProfessionsInputs] = useState(formData?.workRoleProfessions?.length ? formData?.workRoleProfessions : [{ id: 0 }]);
  const primaryProfessionIndex = formData?.workRoleProfessions?.findIndex(({ isPrimary }) => isPrimary) ?? 0;
  const [primaryProfession, setprimaryProfession] = useState(primaryProfessionIndex);

  const handleAddSelectInput = () => {
    const newId = professionInputs?.length + 1;
    setProfessionsInputs([...professionInputs, { id: newId }]);
  };

  const handleRemove = (selectedIndex) => {
    const newProfessionsInputs = professionInputs?.filter((_, index) => index !== selectedIndex);
    setProfessionsInputs(newProfessionsInputs);

    if (formData?.workRoleProfessions?.length) {
      const newWorkRoleProfessions = formData?.workRoleProfessions?.filter((roleProfession, index) => roleProfession && index !== selectedIndex);
      form.change('workRoleProfessions', newWorkRoleProfessions);
    }
  };

  useEffect(() => {
    if (form?.getState()?.modified?.['workRoleType.id'] && head(professionInputs)?.id !== 0) {
      form.change('workRoleProfessions', null);
      setProfessionsInputs([{ id: 0 }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form?.getState()?.modified]);

  useEffect(() => {
    form.change('primaryProfession', primaryProfession);
  }, [form, primaryProfession]);

  useEffect(() => {
    const pProfession = formData?.workRoleProfessions?.findIndex(({ isPrimary }) => isPrimary);
    form.change('primaryProfession', pProfession === -1 || !pProfession ? 0 : pProfession);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {professionInputs?.map((_, index) => (
        <div style={{ borderBottom: '1px solid', display: 'flex', alignItems: 'center' }} key={index}>
          <div style={{ display: 'flex' }}>
            <ReferenceInput
              label="Professions"
              source={`workRoleProfessions.${index}.profession.id`}
              reference="professions"
              {...rest}
              filter={{ availability: 'create_profile,update_profile,update_production,create_crew_profile', limit: 50 }}
              sort={{ field: 'profile_count' }}
              filterToQuery={(searchText) => ({ query: searchText })}
            >
              <AutocompleteInput source="name" />
            </ReferenceInput>
            <FormControlLabel
              style={{ marginLeft: 20 }}
              control={
                <Checkbox
                  color="primary"
                  source={`workRoleProfessions.${index}.isPrimary`}
                  checked={index === primaryProfession}
                  onChange={(e) => {
                    if (e?.target?.checked) {
                      setprimaryProfession(index);
                    }
                  }}
                />
              }
              label="Primary profession"
            />
          </div>
          <div style={{ marginLeft: 20 }}>
            <Button onClick={() => handleRemove(index)}>Remove</Button>
          </div>
        </div>
      ))}
      <Button style={{ marginTop: 10 }} variant="outlined" onClick={handleAddSelectInput}>
        Add profession
      </Button>
    </div>
  );
};

const ProfessionInput = (props) => <FormDataConsumer>{(formDataProps) => <Profession {...formDataProps} {...props} />}</FormDataConsumer>;

export default ProfessionInput;
