export const PROFILE_TYPE_IDS = {
  ARTIST: 1,
  AGENT: 2,
  AOS: 3,
  AUDIENCE: 4,
  PRO: 5,
  CASTING: 6,
  ACADEMICS: 7,
  JOURNALIST_MEDIA: 8,
  OTHER: 9,
  LEGACY: 10,
};

export const PROFILE_TYPES = [
  {
    id: PROFILE_TYPE_IDS.ARTIST,
    name: 'Artist',
  },
  {
    id: PROFILE_TYPE_IDS.AGENT,
    name: 'Agents',
  },
  {
    id: PROFILE_TYPE_IDS.AOS,
    name: 'Arts Organisation Professional',
  },
  {
    id: PROFILE_TYPE_IDS.AUDIENCE,
    name: 'Audience',
  },
  {
    id: PROFILE_TYPE_IDS.PRO,
    name: 'PRO',
  },
  {
    id: PROFILE_TYPE_IDS.CASTING,
    name: 'Casting',
  },
  {
    id: PROFILE_TYPE_IDS.ACADEMICS,
    name: 'Academics',
  },
  {
    id: PROFILE_TYPE_IDS.JOURNALIST_MEDIA,
    name: 'Journalist & Media',
  },
  {
    id: PROFILE_TYPE_IDS.OTHER,
    name: 'Other',
  },
  {
    id: PROFILE_TYPE_IDS.LEGACY,
    name: 'AOS (Legacy)',
  },
];
