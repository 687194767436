import React from 'react';
import { DateInput } from 'react-admin';

const DateRange = ({ label, startDateSource, endDateSource }) => (
  <>
    <DateInput source={startDateSource} label={`${label} from`} helperText="" />
    &nbsp;
    <DateInput source={endDateSource} label={`${label} to`} helperText="" />
  </>
);
export default DateRange;
