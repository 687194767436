import React, { useEffect, useState } from 'react';
import { Datagrid, DatagridBody } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-final-form';
import { values, flatten } from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import RootRef from '@material-ui/core/RootRef';
import { TableCell, TableRow } from '@material-ui/core';

const useStyles = makeStyles({
  row: {
    minHeight: 42,
  },
});

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: 'rgb(235,235,235)',
  }),
});

const reorder = (result, startIndex, endIndex) => {
  const reordered = Array.from(result);
  const [removedElement] = reordered.splice(startIndex - 1, 1);
  reordered.splice(endIndex - 1, 0, removedElement);
  return reordered;
};

const SortableDatagrid = (props) => <Datagrid {...props} body={<SortableDatagridBody tabIndex={props?.tabIndex} nonStandardizedRoles={props?.nonStandardizedRoles} />} />;

const SortableDatagridBody = (props) => {
  const [data, setData] = useState({});
  const [ids, setIds] = useState([]);

  const form = useForm();
  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newIds = reorder(ids, result.source.index, result.destination.index);
    setIds(newIds);

    const newRoles = [];
    newIds.forEach((newid) => {
      newRoles.push(data[newid]);
    });

    form.change('roles', newRoles);

    const groupedData = {};
    // eslint-disable-next-line no-unused-expressions
    form.getState()?.values?.allRoles?.forEach((item) => {
      const roleId = item.workRoleType.id;
      if (!groupedData[roleId]) {
        groupedData[roleId] = [];
      }

      groupedData[roleId].push(item);
    });

    const groupedDataArray = values(groupedData);
    groupedDataArray[props?.tabIndex] = newRoles;
    form.change('allRoles', flatten(groupedDataArray));
  };
  useEffect(() => {
    if (props.data) {
      setData(props.data);
      setIds(props.ids);
      form.change('nonStandardizedRoles', props?.nonStandardizedRoles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.ids]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="vertical">
        {(provided, snapshot) => (
          <RootRef rootRef={provided.innerRef}>
            <>
              <DatagridBody {...props} data={data} ids={ids} row={<SortableDatagridRow ids={ids} provided={provided} snapshot={snapshot} />} />
              {provided.placeholder}
            </>
          </RootRef>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const SortableDatagridRow = ({ record, resource, id, ids, children, basePath }) => {
  const classes = useStyles();

  const index = ids.indexOf(id) + 1;
  return (
    <Draggable st key={id} draggableId={`${id}`} index={index}>
      {(provided, snapshot) => (
        <TableRow
          className={classes.row}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          innerRef={provided.innerRef}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          {/* data columns based on children */}
          {React.Children.map(children, (field) => (
            <TableCell key={`${id}-${field.props.source}`}>
              {React.cloneElement(field, {
                record,
                basePath,
                resource,
              })}
            </TableCell>
          ))}
        </TableRow>
      )}
    </Draggable>
  );
};

export default SortableDatagrid;
