import React from 'react';
import { TableCell } from '@material-ui/core';
import DisplayTable from './DisplayTable';
import DisplayText from './DisplayText';
import apiClient from '../../utils/api';
import { cloneDiff } from './utils';

const getData = async (productionId) => {
  const casts = (
    await apiClient.get(`productions/${productionId}`, {
      params: {
        as_edit: true,
        limit: 10000,
      },
    })
  )?.data;

  return casts?.contributions;
};

export const parseProductionCast = (props) => {
  const { production, changeRequest } = props;

  return new Promise(async (resolve, reject) => {
    const a = changeRequest ? await getData(production.id) : [];
    const b = changeRequest ? await getData(changeRequest.revisionId) : await getData(production.id);

    const data = cloneDiff(a, b);

    resolve(data);
  });
};

const CastAndCrewRow = (props) => (
  <>
    <TableCell>
      <DisplayText path="" changes={props.changes} select="organization.name" />
    </TableCell>

    <TableCell>
      <DisplayText path="" changes={props.changes} select="profile.name" />
    </TableCell>

    <TableCell>
      <DisplayText path="" changes={props.changes} select="workRole.original_name" />
    </TableCell>

    <TableCell>
      <DisplayText path="" changes={props.changes} select="workRoleReference" />
    </TableCell>

    <TableCell>
      <DisplayText path="" changes={props.changes} select="profession.name" />
    </TableCell>

    <TableCell>
      <DisplayText path="" changes={props.changes} select="contributionType" />
    </TableCell>
  </>
);

export const ProductionCast = (props) => (
  <DisplayTable {...props} changes={props.changes} header={['Organization', 'Artist', 'Role', 'Reference', 'Profession', 'contributionType']}>
    <CastAndCrewRow />
  </DisplayTable>
);
