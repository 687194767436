import React, { useState } from 'react';
import { FormControl, TextField, Divider } from '@material-ui/core';
import * as dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import apiClient from '../utils/api';

const API_URL = process.env.REACT_APP_API_URL;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  failed: {
    color: 'red',
  },
  dateSection: {
    display: 'flex',
    flexDirection: 'row',
  },
  date: { marginLeft: 10 },
}));

export const PayoutExport = () => {
  const classes = useStyles();
  const [[loading, downloadError], setLoading] = React.useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState({ from: dayjs().add(-7, 'day').format('YYYY-MM-DD'), to: dayjs().format('YYYY-MM-DD') });

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Payout export
          </Typography>

          <FormControl variant="filled" className={classes.formControl}>
            <FormControl className={classes.dateSection} size="small">
              <TextField
                value={selectedDateRange.from}
                onChange={(e) => setSelectedDateRange({ ...selectedDateRange, from: e?.target?.value })}
                type="date"
                label="From"
                variant="filled"
                size="small"
              />
              <TextField
                className={classes.date}
                value={selectedDateRange.to}
                onChange={(e) => setSelectedDateRange({ ...selectedDateRange, to: e?.target?.value })}
                type="date"
                label="To"
                variant="filled"
                size="small"
              />
            </FormControl>
          </FormControl>
          {downloadError && (
            <Typography className={classes.failed} component="h2">
              {downloadError.message}
            </Typography>
          )}
        </CardContent>
        <CardActions>
          <Button variant="contained" disabled={loading} onClick={() => downloadPayout(selectedDateRange, setLoading)}>
            {loading ? 'Loading...' : 'Download'}
          </Button>
        </CardActions>
      </Card>

      <Divider component="br" />
    </>
  );
};

function convertToCSV(arr) {
  const keys = Object.keys(arr[0]);

  if (!keys.includes('credit_note_id')) {
    keys.push('credit_note_id');
  }

  const array = [keys].concat(arr);

  const ansArr = array
    .map((it) => {
      it.customer_email = it?.customer_email?.customer_email || '';
      return Object.values(it).join(';');
    })
    .join('\n');

  return ansArr;
}

async function downloadPayout(dateRange, setLoading) {
  setLoading([true, null]);

  try {
    const EXPORT_URL = `/system/payout_export/?payout_from=${dateRange?.from}&payout_to=${dateRange?.to}`;
    const feedData = (await apiClient.get(`${API_URL}/${EXPORT_URL}`)).data;

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = window.URL.createObjectURL(new Blob([convertToCSV(feedData.data)], { type: 'text/html' }));
    a.setAttribute('download', `payout_${dateRange?.from}-${dateRange?.to}.csv`);
    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
    setLoading([false, null]);
  } catch (err) {
    setLoading([false, err]);
  }
}
