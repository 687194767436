import { makeStyles } from '@material-ui/styles';
import React, { useCallback } from 'react';

import {
  Show,
  List,
  TabbedShowLayout,
  Filter,
  TextInput,
  Datagrid,
  TextField,
  Tab,
  useUpdate,
  useNotify,
  useRedirect,
  Edit,
  SimpleForm,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  useQueryWithStore,
  FunctionField,
  SaveButton,
  DeleteButton,
  SelectField,
  Toolbar,
  ReferenceField,
  BooleanInput,
} from 'react-admin';
import CustomDateField from '../components/customFields/CustomDateField';
import ProfileLink from '../components/profileLink';
import SubscriptionLink from '../components/subscriptionLink';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { REGISTRATION_LINK_TEMPLATE, REGISTRATION_STEPS, REGISTRATION_TIMES, REGISTRATION_PROFILE_TYPES, REGISTRATION_CONTACT, REGISTRATION_COHORT_TYPES } from '../constants/registration';
import DateRange from '../components/DateRange';
import useLanguageQuery from '../utils/useLanguageQuery';

const useStyles = makeStyles({
  infoTitle: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: '1rem',
    fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Arial,sans-serif',
    fontWeight: 400,
    lineHeight: 1,

    transform: 'translate(0, 1.5px) scale(0.75);',
    transformOrigin: 'top left',
  },
  infoData: {
    padding: '8px 0 4px',
    fontSize: '0.875rem',
    fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Arial,sans-serif',
    fontWeight: 400,
    lineHeight: 1,
  },
  infoContainer: {
    marginTop: 8,
    marginBottom: 4,
    TextField,
  },
  profileInfo: {
    display: 'flex',
    flexDirection: 'row',
  },
  subscription: {
    border: '1px solid rgba(0,0,0,0.1)',
    padding: 8,
    margin: 2,
  },
  subscriptions: {
    display: 'flex',
    flexDirection: 'row',
  },
  infoBlock: {
    marginLeft: 10,
  },
});

const UserRegistrationFilter = (props) => (
  <Filter {...props}>
    <TextInput label="User ID" source="user_id" alwaysOn />
    <TextInput label="Search" source="query" alwaysOn />
    <SelectInput label="Registration step" source="step" choices={REGISTRATION_STEPS} alwaysOn />
    <SelectInput label="Profile type" source="profile_type_id" choices={REGISTRATION_PROFILE_TYPES} alwaysOn />
    <DateRange startDateSource="completed_date_from" endDateSource="completed_date_to" label="Completed at" alwaysOn />
    <DateRange startDateSource="created_at_from" endDateSource="created_at_to" label="Created at" alwaysOn />
    <BooleanInput label="Is Gifted" source="is_gifted" alwaysOn />
  </Filter>
);

const RegistrationProfileInfo = (props) => {
  const { record } = props;
  const classes = useStyles();

  const { data: profile } = useQueryWithStore({
    type: 'getOne',
    resource: 'profiles',
    payload: {
      id: record?.profile?.id,
    },
  });

  const { data: subscriptions } = useQueryWithStore({
    type: 'getList',
    resource: 'users/subscriptions',
    payload: {
      filter: { profile_id: profile?.id },
      pagination: { page: 1, perPage: 1000 },
      sort: {},
    },
  });

  const StyledInfo = useCallback(
    ({ title, data }) => (
      <div className={classes.infoContainer}>
        <div className={classes.infoTitle}>{title}</div>
        <div className={classes.infoData}>{data}</div>
      </div>
    ),
    [classes.infoContainer, classes.infoData, classes.infoTitle],
  );
  if (!profile) return <h4>No profile info</h4>;

  return (
    <div className={classes.profileInfo}>
      <div>
        <div className={classes.title}>Profile information</div>
        <ProfileLink record={profile} />
        <StyledInfo title="Profile first name" data={profile?.firstName} />
        <StyledInfo title="Profile last name" data={profile?.lastName} />
        <StyledInfo title="Profile Type" data={profile?.profileType?.name} />
        <StyledInfo title="Profession" data={`${profile?.profession?.name}`} />
      </div>
      <div className={classes.infoBlock}>
        <div className={classes.title}>Subscription information</div>
        <div className={classes.subscriptions}>
          {(subscriptions || []).length !== 0 ? (
            subscriptions.map((sub) => (
              <div className={classes.subscription}>
                {/* <StyledInfo
                  title="Subscription"
                  data={sub?.subscription?.description}
                /> */}
                <SubscriptionLink record={sub} />
                <StyledInfo title="Subscription status" data={sub?.status} />
                <StyledInfo title="Subscription payment type" data={sub?.paymentType} />
                <StyledInfo title="Subscription start at" data={sub?.startAt ? new Date(sub?.startAt).toLocaleString() : 'not set'} />
                <StyledInfo title="Subscription renew at" data={sub?.renewAt ? new Date(sub?.renewAt).toLocaleString() : 'not set'} />
                <StyledInfo title="Subscription enddate at" data={sub?.endDate ? new Date(sub?.endDate).toLocaleString() : 'not set'} />
              </div>
            ))
          ) : (
            <h5>No subscriptions</h5>
          )}
        </div>
      </div>
    </div>
  );
};

export const UserRegistrationShow = (props) => {
  const languages = useLanguageQuery();

  return (
    <Show {...props} title={<Breadcrumbs {...props} />}>
      <TabbedShowLayout>
        <Tab label="Registration Info">
          <TextField source="id" label="Id" />
          <ReferenceField source="user.id" reference="users" link="show" label="Created by user" emptyText="Empty">
            <TextField source="name" />
          </ReferenceField>
          <CustomDateField source="createdAt" emptyText="Empty" />
          <CustomDateField source="updatedAt" emptyText="Empty" />
          <TextField source="firstname" label="First Name" emptyText="Empty" />
          <TextField source="lastname" label="Last Name" emptyText="Empty" />
          <TextField source="email" label="E-mail" emptyText="Empty" />
          <FunctionField
            label="Registration type"
            render={(record) => <div>{record?.profile_type_id ? REGISTRATION_PROFILE_TYPES.find((t) => t.id === record?.profile_type_id)?.name : REGISTRATION_COHORT_TYPES[record.flow_id]}</div>}
          />
          <FunctionField label="Registration link" render={(record) => REGISTRATION_LINK_TEMPLATE(record?.id)} emptyText="Empty" />
          <TextField source="step" label="Step" emptyText="Empty" />
          <TextField source="plan" label="Subscription plan" emptyText="Empty" />
          <TextField source="communication" label="Prefered mode of communication" emptyText="Empty" />
          <SelectField choices={languages} source="language" label="Prefered language" emptyText="Empty" />
          <TextField source="time" label="Prefered time" emptyText="Empty" />
          <TextField source="phone_number" label="Phone number" emptyText="Empty" />
          <TextField source="whatsapp" label="Whatsapp" emptyText="Empty" />
          <TextField source="location" label="Location" emptyText="Empty" />
          <TextField source="website_type" label="Website type" emptyText="Empty" />
          <FunctionField label="Organization name" render={(reg) => (reg?.organization_name ? reg?.organization_name : reg?.organization?.name)} />
          <FunctionField label="Agency name" render={(reg) => (reg?.agency_name ? reg?.agency_name : reg?.agency?.name)} />
          <TextField source="job_title" label="Title" />
          <TextField source="profile_description" label="Description" />
          <TextField source="magazine_name" label="Magazine name" />
          <TextField source="university_name" label="University name" />
          <TextField source="website_url" label="Website url" />
          <TextField source="note" label="Note" />
        </Tab>
        <Tab label="Profile information">
          <RegistrationProfileInfo />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const SaveRegistration = (props) => {
  const [update] = useUpdate('users/registrations');
  const redirectTo = useRedirect();
  const notify = useNotify();
  const { basePath } = props;
  const handleSave = useCallback(
    (values, redirect) => {
      update(
        {
          payload: {
            id: props?.record.id,
            data: {
              ...values,
              organization: values?.organization?.id ? values?.organization : null,
              organization_id: values?.organization?.id || null,
            },
            previousData: props?.record,
          },
        },
        {
          onSuccess: ({ data: newRecord }) => {
            notify('ra.notification.updated', 'info', {
              smart_count: 1,
            });
            redirectTo(redirect, basePath, newRecord.id, newRecord);
          },
        },
      );
    },
    [update, props, notify, redirectTo, basePath],
  );
  // set onSave props instead of handleSubmitWithRedirect
  return <SaveButton {...props} onSave={handleSave} />;
};

const PostCreateToolbar = (props) => (
  <Toolbar {...props}>
    <SaveRegistration submitOnEnter />
    <DeleteButton style={{ marginLeft: 'auto' }} />
  </Toolbar>
);

export const UserRegistrationEdit = (props) => (
  <Edit {...props} title={<Breadcrumbs {...props} />}>
    <SimpleForm toolbar={<PostCreateToolbar />}>
      <CustomDateField source="createdAt" />
      <CustomDateField source="updatedAt" />
      <TextField source="website_type" label="Website type" />
      <SelectField choices={REGISTRATION_STEPS} source="step" label="Step" />
      <TextInput source="firstname" label="Firstname" />
      <TextInput source="lastname" label="Lastname" />
      <TextInput source="email" label="E-mail" />
      <TextInput source="location" label="Location" />
      <TextInput source="phone_number" label="Phone number" />
      <TextInput source="whatsapp" label="Whatsapp" />
      <SelectInput choices={REGISTRATION_CONTACT} source="communication" label="Prefered mode of communication" allowEmpty />
      <SelectInput choices={REGISTRATION_TIMES} source="time" label="Prefered time of communication" allowEmpty />
      <ReferenceInput label="Organization" source="organization.id" reference="organizations" filterToQuery={(searchText) => ({ query: searchText })} allowEmpty>
        <AutocompleteInput allowEmpty optionText="name" emptyValue={null} emptyText="[REMOVE ORGANIZATION]" />
      </ReferenceInput>
      <ReferenceInput label="Agency" source="agency.id" reference="agencies" filterToQuery={(searchText) => ({ query: searchText })} allowEmpty>
        <AutocompleteInput allowEmpty optionText="name" emptyText="[REMOVE AGENCY]" />
      </ReferenceInput>
      <BooleanInput source="is_hidden" />
    </SimpleForm>
  </Edit>
);

export const UserRegistrationList = (props) => (
  <List exporter={false} {...props} title={<Breadcrumbs {...props} />} sort={{ field: 'id', order: 'DESC' }} filters={<UserRegistrationFilter />} bulkActionButtons={false} perPage={25}>
    <Datagrid rowClick="show">
      <TextField label="Registration ID" source="id" />
      <CustomDateField label="Registration completed date" source="completed_date" />
      <TextField source="email" label="User (email)" />
      <FunctionField
        label="Registration type"
        render={(record) => <div>{record?.profile_type_id ? REGISTRATION_PROFILE_TYPES.find((t) => t.id === record?.profile_type_id)?.name : REGISTRATION_COHORT_TYPES[record.flow_id]}</div>}
      />
      <SelectField source="step" choices={REGISTRATION_STEPS} label="Registration step" emptyText="[LEGACY \ NOT UPDATED VALUE]" />
      <TextField source="website_type" label="Source" />
    </Datagrid>
  </List>
);

export default UserRegistrationList;
