import React from 'react';

import { List, Filter, Datagrid, TextField, ReferenceField, BooleanInput, TopToolbar, EditButton, useRedirect } from 'react-admin';

import { Breadcrumbs } from '../components/Breadcrumbs';
import { getEntityIcon } from '../utils/entities';

const EntitySearchFilter = (props) => (
  <Filter {...props}>
    <BooleanInput source="show_invalid" alwaysOn />
  </Filter>
);

export const ReferenceEntitySearchEntity = (props) => {
  const { record } = props;
  let reference = null;
  switch (record?.tagType.slug) {
    case 'organization':
      reference = 'organizations';
      break;
    case 'production':
      reference = 'productions';
      break;
    case 'profile':
      reference = 'profiles';
      break;
    case 'venue':
      reference = 'venues';
      break;
    case 'work':
      reference = 'works';
      break;
    default:
      return <div>N/A</div>;
  }
  return (
    <ReferenceField link="show" source="tagId" label="Profile" reference={reference} {...props}>
      <TextField source="name" emptyText="Empty" />
    </ReferenceField>
  );
};

export const EntitySearchShowActions = (props) => {
  const { basePath, data } = props;
  return (
    <TopToolbar>
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );
};

const EntityIcon = (props) => {
  const iconRef = getEntityIcon(props?.record.entity_type);
  const icon = !iconRef
    ? null
    : React.cloneElement(iconRef, {
        style: {
          margin: '0px 8px -4px 0px',
        },
      });
  return icon;
};

const EntityRow = (props) => (
  <div>
    <EntityIcon {...props} />
    <TextField {...props} source="entity" label="Name" sortable={false} />
  </div>
);

export const EntitySearchList = (props) => {
  const redirect = useRedirect();

  return (
    <List {...props} title={<Breadcrumbs {...props} />} exporter={false} filters={<EntitySearchFilter />}>
      <Datagrid
        rowClick={(id, type, row) => {
          redirect(`/${row.entity_type}/${row.id}/show`);
        }}
      >
        <TextField source="id" sortable={false} />
        <EntityRow {...props} />
        <TextField source="entity_type" label="Type" sortable={false} />
        <TextField source="validationStatus.name" label="Validation Status" sortable={false} />
      </Datagrid>
    </List>
  );
};

export default EntitySearchList;
