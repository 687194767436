/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { take, map, orderBy } from 'lodash';
import { Loading } from 'react-admin';
import { FormControl, TextField, Table, TableHead, TableCell, TableRow, TableBody, Dialog, DialogContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core/styles';
import EntityLink from './EntityLink';
import apiClient from '../utils/api';

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
const advanced = require('dayjs/plugin/advancedFormat');

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

const useStyles = makeStyles(() => ({
  filterSection: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  spacingLeft: {
    marginLeft: 10,
  },
  alginCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  pointer: {
    cursor: 'pointer',
  },
  dialog: {
    minWidth: 470,
    height: 'auto',
    '& .MuiDialogContent-root': { padding: 20 },
  },
  closeIcon: {
    position: 'absolute',
    right: 10,
    top: 15,
    cursor: 'pointer',
  },
  container: { display: 'flex', height: '100%', marginBottom: 20 },
}));

const CastingToolUsage = () => {
  const classes = useStyles();
  const history = useHistory();

  const searchParams = new URLSearchParams(history.location.search);
  const defaultDateRange = { from_date: searchParams?.get('from_date'), to_date: searchParams?.get('to_date') };
  const [selectedDateRange, setSelectedDateRange] = useState({
    from_date: defaultDateRange?.from_date || dayjs().add(-6, 'day').format('YYYY-MM-DD'),
    to_date: defaultDateRange?.to_date || dayjs().format('YYYY-MM-DD'),
  });
  const [metrics, setMetrics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOpenProfileModal, setIsOpenProfileModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  useEffect(() => {
    document.title = 'Casting tool usage';
  }, []);

  useEffect(() => {
    setLoading(true);
    apiClient
      .get('metrics/casting-tool-usage', {
        params: { from_date: selectedDateRange?.from_date, to_date: selectedDateRange?.to_date },
      })
      .then((res) => {
        setMetrics(orderBy(res.data, 'total_count', 'asc'));
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange?.from_date, selectedDateRange?.to_date]);

  useEffect(() => {
    searchParams.set('from_date', selectedDateRange?.from_date);
    searchParams.set('to_date', selectedDateRange?.to_date);
    const newSearch = searchParams.toString();
    history.push({ search: newSearch });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange]);

  return (
    <div>
      <FormControl className={classes.filterSection} size="small">
        <TextField
          value={selectedDateRange.from_date}
          onChange={(e) => {
            setSelectedDateRange({ ...selectedDateRange, from_date: e?.target?.value });
          }}
          type="date"
          label="From"
          variant="filled"
          size="small"
        />
        <TextField
          className={classes.spacingLeft}
          value={selectedDateRange.to_date}
          onChange={(e) => {
            setSelectedDateRange({ ...selectedDateRange, to_date: e?.target?.value });
          }}
          type="date"
          label="To"
          variant="filled"
          size="small"
        />
      </FormControl>
      <div>
        {!loading ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Organization</TableCell>
                <TableCell>Casting tool visits done</TableCell>
                <TableCell>No of unique people at AOS doing casting tool searches</TableCell>
                <TableCell>Casting Artist page visits done</TableCell>
                <TableCell>No of unique people at AOS doing casting tool artist searches</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {metrics.map((m) => (
                <TableRow key={m.organization_id}>
                  <TableCell>
                    <EntityLink entityType="organizations" entity={m?.aos} />
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://app.hubspot.com/contacts/${window?.location?.hostname === 'admin.truelinked.net' ? '6012578' : '20261276'}/objects/0-2/views/all/list?query=${encodeURIComponent(
                        m?.aos?.name,
                      )}`}
                      title={`Search ${m?.aos?.name} in Hubspot`}
                    >
                      <svg viewBox="0 0 242.04 257" height={24} fill="#ff7a59">
                        <path d="M162.79,101.25V82a14.84,14.84,0,0,0,8.56-13.38v-.45a14.87,14.87,0,0,0-14.83-14.83h-.45a14.87,14.87,0,0,0-14.83,14.83v.45A14.84,14.84,0,0,0,149.8,82v19.29a42.06,42.06,0,0,0-20,8.8L76.86,68.86a16.89,16.89,0,1,0-7.91,10.29L121,119.67a42.18,42.18,0,0,0,.65,47.56l-15.84,15.84a13.6,13.6,0,0,0-3.93-.64,13.75,13.75,0,1,0,13.75,13.75,13.56,13.56,0,0,0-.64-3.93l15.67-15.67a42.25,42.25,0,1,0,32.1-75.33m-6.49,63.42A21.68,21.68,0,1,1,178,143a21.68,21.68,0,0,1-21.68,21.68"></path>
                      </svg>
                    </a>
                  </TableCell>
                  <TableCell>{m?.total_casting_search ?? 0}</TableCell>
                  <TableCell>
                    {take(m?.casting_profiles, 5)?.map((profile, index) => (
                      <EntityLink key={`${profile?.id}-${index}`} entityType="profiles" entity={profile} />
                    ))}
                    {m?.profiles?.length > 5 && (
                      <p>
                        <span
                          className={classes.pointer}
                          onClick={() => {
                            setIsOpenProfileModal(true);
                            setSelectedRow(m);
                          }}
                        >
                          + {m?.casting_profiles?.length - 5} more
                        </span>
                      </p>
                    )}
                  </TableCell>
                  <TableCell>{m?.total_casting_artist_search ?? 0}</TableCell>
                  <TableCell>
                    {take(m?.casting_artist_profiles, 5)?.map((profile, index) => (
                      <EntityLink key={`${profile?.id}-${index}`} entityType="profiles" entity={profile} />
                    ))}
                    {m?.profiles?.length > 5 && (
                      <p>
                        <span
                          className={classes.pointer}
                          onClick={() => {
                            setIsOpenProfileModal(true);
                            setSelectedRow(m);
                          }}
                        >
                          + {m?.casting_artist_profiles?.length - 5} more
                        </span>
                      </p>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Loading />
        )}
      </div>
      <Dialog
        open={isOpenProfileModal}
        onClose={() => setIsOpenProfileModal(false)}
        PaperProps={{
          className: classes.dialog,
        }}
      >
        <DialogContent>
          <CloseIcon className={classes.closeIcon} onClick={() => setIsOpenProfileModal(false)} />
          <div className={classes.container}>
            <div>
              <div>No of unique people at AOS doing casting tool searches</div>
              <div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Profile name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {map(selectedRow?.profiles, (profile, index) => (
                      <TableRow key={`${profile?.id}-${index}`}>
                        <TableCell>
                          <EntityLink entityType="profiles" entity={{ id: profile?.id, name: profile?.name }} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CastingToolUsage;
