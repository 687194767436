import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useDataProvider } from 'react-admin';
import { makeStyles, Grid, Typography, Button, TextField } from '@material-ui/core';
import { MergeType, FastForward } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InProgressModal from '../components/InProgressModal';

const styles = () => ({
  withMargin: { marginTop: 25 },
  mergeTitle: {
    margin: 14,
  },
  mergeIcon: {
    margin: 4,
    transform: 'rotate(90deg)',
  },
  mergeIcon2: {
    margin: 4,
  },
});

export const renderUser = (user) => `(${user.id})  - ${user.name} (${user.email})`;

export const MergeCustomerShow = () => {
  const classes = makeStyles(styles)();
  const dataProvider = useDataProvider();
  const [sourceUser, setSourceUser] = useState(null);
  const [targetUser, setTargetUser] = useState(null);
  const [options, setOptions] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const handleConfirm = () => {
    setLoading(true);
    dataProvider
      .create(`users/${sourceUser?.id}/mergecustomer`, { data: { id: targetUser?.id } })
      .then(() => {
        setError(null);
        setLoading(false);
        window.location.href = `#/users/${targetUser?.id}/show`;
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  };

  const getUsers = (query) => {
    dataProvider
      .getList('users', {
        // eslint-disable-next-line no-restricted-globals
        filter: { query: isNaN(query) ? query : '', ...(!isNaN(query) && query ? { id: [parseInt(query, 0)] } : {}) },
        pagination: { limit: 25, page: 1 },
        sort: {},
      })
      .then(({ data }) => {
        setOptions(data);
      });
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={3}>
          <Typography variant="heading6" gutterBottom>
            Merge customer
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.withMargin}>
        <Grid item xs={2}>
          <Grid container className={classes.mergeTitle}>
            <Grid>
              <Typography variant="subtitle1">Merge</Typography>
            </Grid>
            <Grid>
              <MergeType className={classes.mergeIcon} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            style={{ width: 500 }}
            options={options}
            autoComplete
            value={sourceUser}
            onChange={(event, user) => {
              setSourceUser(user);
            }}
            onInputChange={(event, input) => {
              getUsers(input);
            }}
            renderInput={(params) => <TextField {...params} variant="filled" fullWidth label="Source user (User ID / Name)" />}
            renderOption={(option) => renderUser(option)}
            getOptionLabel={(option) => renderUser(option)}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.withMargin}>
        <Grid item xs={2}>
          <Grid container className={classes.mergeTitle}>
            <Grid>
              <Typography variant="subtitle1">Into</Typography>
            </Grid>
            <Grid>
              <FastForward className={classes.mergeIcon2} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            style={{ width: 500 }}
            options={options}
            autoComplete
            value={targetUser}
            onChange={(event, user) => {
              setTargetUser(user);
            }}
            onInputChange={(event, input) => {
              getUsers(input);
            }}
            renderInput={(params) => <TextField {...params} variant="filled" fullWidth label="Target user (User ID / Name)" />}
            renderOption={(option) => renderUser(option)}
            getOptionLabel={(option) => renderUser(option)}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.withMargin} direction="column">
        <Grid item>
          <h4>Things that will be merged</h4>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2" gutterBottom>
            All subscriptions linked to the user
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            All invoices linked to the user
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            All credit notes linked to the user
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            Billing information will not be merged. Please update billing details manually.
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            Profile access needs to be assigned to the user manually
          </Typography>
        </Grid>
      </Grid>
      {!isEmpty(error) && (
        <div>
          <Typography variant="body2" color="error">
            {!isEmpty(error?.toString()) ? error?.toString() : 'Error on merging the customer. Please try again.'}
          </Typography>
        </div>
      )}
      <Grid container className={classes.withMargin}>
        <Button disabled={!sourceUser?.id || !targetUser?.id} variant="contained" color="secondary" onClick={handleConfirm}>
          <MergeType /> Merge
        </Button>
      </Grid>
      <InProgressModal isOpen={isLoading} message="Loading..." />
    </div>
  );
};

export default MergeCustomerShow;
