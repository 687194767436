import React, { useState, useEffect, useCallback } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, TextField } from '@material-ui/core';

const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    autoCompleteStyle: {
      width: '200px',
    },
    buttonStyle: {
      marginLeft: '20px',
      textTransform: 'initial',
      minWidth: 'max-content',
      height: 30,
    },
    textInput: {
      '& label': {
        fontSize: 14,
      },
    },
  }),
  { name: 'MakeLink' },
);

const ValidationLockByUser = ({ data, lockGroupType }) => {
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [lockedBy, setLockedBy] = useState(null);
  const [allUserOptions, setAllUserOptions] = useState([]);
  const notify = useNotify();

  const getEntity = useCallback(
    (query) => {
      dataProvider
        .getList('users', { filter: { group_id: 3, lockGroup: lockGroupType, validation_status: '', query: query }, pagination: {}, sort: {} })
        .then(({ data }) => {
          setAllUserOptions(data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [dataProvider, lockGroupType],
  );

  const getLockedStatus = useCallback(() => {
    if (data.id) {
      dataProvider
        .getList('validations/locks', { filter: { lockIdentifier: data.id, lockGroup: lockGroupType, validation_status: '' }, pagination: {}, sort: {} })
        .then(({ data }) => {
          const selectedData = {
            id: data?.[0]?.user?.id,
            email: data?.[0]?.user?.email,
            name: `${data?.[0]?.user?.firstName} ${data?.[0]?.user?.lastName}`,
            lockId: data?.[0]?.id,
            lockGroup: data?.[0]?.lockGroup,
          };
          if (data?.[0]?.user?.id) {
            setSelectedEntity(selectedData);
            setLockedBy(selectedData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [data.id, dataProvider, lockGroupType]);

  useEffect(() => {
    getEntity();
    getLockedStatus();
  }, [getEntity, getLockedStatus]);

  const userLockHandler = () => {
    if (lockedBy?.lockId && selectedEntity?.id === 0) {
      dataProvider
        .delete(`validations/locks`, { id: lockedBy?.lockId })
        .then((res) => {
          notify(`Success Fully Deleted`, {
            autoHideDuration: 30000,
            type: 'success',
          });
          setSelectedEntity(null);
          setLockedBy(null);
        })
        .catch((err) => {
          notify(`Error : ${err}`, {
            autoHideDuration: 30000,
            type: 'error',
          });
        });
    } else {
      dataProvider
        .create('validations/locks', {
          data: {
            lockGroup: lockGroupType,
            lockIdentifier: data.id,
            ...(selectedEntity?.id && {
              user: {
                id: selectedEntity.id,
              },
            }),
          },
        })
        .then(({ data }) => {
          if (data) {
            setSelectedEntity(selectedEntity);
          }
          notify(`Success : ${'Added lock'}`, {
            autoHideDuration: 30000,
            type: 'success',
          });
          getLockedStatus();
        })
        .catch((err) => {
          notify(`Error : ${err}`, {
            autoHideDuration: 30000,
            type: 'error',
          });
        });
    }
  };
  const unAssignedObject = {
    label: 'Unlock',
    id: 0,
    name: 'Unlock',
    email: 'Unlock',
  };

  const extraOptionDropDown = lockedBy ? [unAssignedObject] : [];

  const renderButtonText = () => {
    if (selectedEntity?.lockId) {
      return 'Locked';
    }
    if (selectedEntity?.id === 0) {
      return 'Remove Lock';
    }
    if (!selectedEntity) {
      return 'Lock Self';
    }
    return 'Lock';
  };

  const renderEntity = (entity) => `(${entity.name}) - ${entity.email}`;
  return (
    <div className={classes.root}>
      <Autocomplete
        className={classes.autoCompleteStyle}
        options={extraOptionDropDown.concat(
          allUserOptions.map((option) => {
            return { name: option?.name, label: option?.name, id: option?.id, email: option?.email };
          }),
        )}
        autoComplete
        value={selectedEntity}
        onChange={(event, entity) => {
          setSelectedEntity(entity);
        }}
        onInputChange={(event, input) => {
          getEntity(input);
        }}
        renderInput={(params) => <TextField {...params} fullWidth label="Select User" className={classes.textInput} />}
        renderOption={(option) => renderEntity(option)}
        getOptionLabel={(option) => renderEntity(option)}
      />
      <Button size="small" className={classes.buttonStyle} variant="contained" color={`${selectedEntity?.lockId ? 'secondary' : 'primary'}`} onClick={userLockHandler}>
        {renderButtonText()}
      </Button>
    </div>
  );
};

export default ValidationLockByUser;
