import React, { useState, useEffect, cloneElement, Children } from 'react';

import { Labeled, useDataProvider } from 'react-admin';

export const Shadowy = ({ ...props }) => {
  const dataProvider = useDataProvider();

  const [loading, setLoading] = useState(false);
  const [record, setRecord] = useState(null);
  const label = Children.only(props.children)?.props?.label || 'Unknown Label';


  useEffect(() => {
    setLoading(true);

    dataProvider
      .getList(props.resource, {
        filter: { id: props.record.id },
        pagination: { perPage: 1, page: 1 },
        sort: { field: 'id', order: 'DESC' },
      })
      .then((res) => {
        setLoading(false);

        setRecord(res.data[0]);
      });
  }, [dataProvider, props.resource, props.record.id]);

  if (loading) {
    return <div>Loading</div>;
  }

  const newChildren = cloneElement(Children.only(props.children), {
    ...props,
    record,
  });

  return <Labeled label={label}>{newChildren}</Labeled>;
};
