import React from 'react';
import { Show, List, Filter, TextInput, Datagrid, TextField, TabbedShowLayout, Tab, Edit, SimpleForm, Toolbar, BooleanInput, SaveButton, DeleteButton } from 'react-admin';
import { withStyles } from '@material-ui/core';
import { Breadcrumbs } from '../components/Breadcrumbs';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const CustomToolbar = withStyles(toolbarStyles)((props) => (
  <Toolbar {...props}>
    <SaveButton />
    <DeleteButton undoable={false} />
  </Toolbar>
));

const ProfileClaimsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="query" alwaysOn />
  </Filter>
);

export const ProfileClaimsShow = (props) => {
  return (
    <Show {...props} title={<Breadcrumbs {...props} />}>
      <TabbedShowLayout>
        <Tab label="Profile claim info">
          <TextField source="name" />
          <TextField source="email" />
          <TextField source="phone_number" label="User phone number" />
          <TextField source="timezone" label="User timezone" />
          <TextField source="preferred_time" label="User preferred time" />
          <TextField source="user.name" label="User name" />
          <TextField source="user.email" label="User email" />
          <TextField source="originalProfile.name" label="Original profile name" />
          <TextField source="originalProfile.id" label="Original profile id" />
          <TextField source="originalProfile.profileType.name" label="Original profile type" />
          <TextField source="verifiedByUser.name" label="Verified by user: " />
          <TextField source="is_owner_verified" label="Is verified" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const ProfileClaimsEdit = (props) => (
  <Edit {...props} title={<Breadcrumbs {...props} />} toolbar={<CustomToolbar />}>
    <SimpleForm>
      <TextField source="name" />
      <TextField source="email" />
      <TextField source="phone_number" label="User phone number" />
      <TextField source="timezone" label="User timezone" />
      <TextField source="preferred_time" label="User preferred time" />
      <TextField source="user.name" label="User name" />
      <TextField source="user.email" label="User email" />
      <TextField source="originalProfile.name" label="Original profile name" />
      <TextField source="originalProfile.id" label="Original profile id" />
      <TextField source="originalProfile.profileType.name" label="Original profile type" />
      <TextField source="verifiedByUser.name" label="Verified by user: " />
      <BooleanInput source="is_owner_verified" label="Is verified" />
      <BooleanInput source="merge" label="Merge" />
    </SimpleForm>
  </Edit>
);

export const ProfileClaimsList = (props) => (
  <List exporter={false} {...props} title={<Breadcrumbs {...props} />} filters={<ProfileClaimsFilter />} bulkActionButtons={false} sort={{ field: 'id', order: 'ASC' }}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="email" />
      <TextField source="user.name" label="User name" />
      <TextField source="phone_number" label="Phone number" />
      <TextField source="originalProfile.name" label="Original profile" />
      <TextField source="originalProfile.id" label="Original profile id" />
      <TextField source="is_owner_verified" label="Is verified" />
    </Datagrid>
  </List>
);

export default ProfileClaimsList;
