/* eslint-disable react/jsx-boolean-value */
import React, { useCallback } from 'react';
import { Show, List, Filter, TextInput, Datagrid, TextField, SimpleShowLayout, SimpleForm, Toolbar, SaveButton, ReferenceField, Edit, SelectInput } from 'react-admin';
import { useForm } from 'react-final-form';
import { PROFILE_ACCESS_REQUEST_STATUS } from '../constants/profileAccessRequest';
import { Breadcrumbs } from '../components/Breadcrumbs';

const ActionButton = ({ handleSubmitWithRedirect, ...props }) => {
  const form = useForm();

  const handleClick = useCallback(() => {
    form.change('status', props.variant);

    handleSubmitWithRedirect('show');
  }, [form, handleSubmitWithRedirect, props.variant]);

  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};

const ProfileAccessRequestsToolbar = (props) => (
  <Toolbar {...props}>
    <ActionButton label="Accept request" redirect="show" variant="accepted" submitOnEnter={true} />
    <ActionButton label="Reject request" redirect="accepted" submitOnEnter={true} variant="rejected" />
  </Toolbar>
);

const ProfileAccessRequestsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="query" alwaysOn />
    <SelectInput label="Status" source="status" choices={PROFILE_ACCESS_REQUEST_STATUS} alwaysOn />
  </Filter>
);

export const ProfileAccessRequestsShow = (props) => (
  <Show {...props} title={<Breadcrumbs {...props} />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField label="User" reference="users" source="sender.user.id" link="show">
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField label="Sender Profile" reference="profiles" source="sender.profile.id" link="show">
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField label="Sender Profile agency" reference="agencies" source="agency.id" link="show">
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField label="Accessed Profile" reference="profiles" source="profile.id" link="show">
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField label="Accessed Profile's owner" reference="users" source="profile.user.id" link="show">
        <TextField source="name" />
      </ReferenceField>

      <TextField source="link_token" label="Link token" />
      <TextField source="sender.email" />

      <TextField source="sender.phone_number" />

      <TextField source="sender.preferred_time" />

      <TextField source="sender.timezone" />

      <TextField source="status" />
    </SimpleShowLayout>
  </Show>
);

export const ProfileAccessRequestsList = (props) => (
  <List title={<Breadcrumbs {...props} />} filters={<ProfileAccessRequestsFilter />} bulkActionButtons={false} {...props} perPage={25} exporter={false}>
    <Datagrid rowClick="show">
      <TextField source="id" label="ID" />
      <TextField source="sender.user.name" label="Sender user" />
      <TextField source="sender.profile.name" label="Sender profile" />
      <TextField source="profile.name" label="Profile requested" />
      <TextField source="agency.name" label="Sender agency" />
      <TextField source="sender.preferred_time" label="Preferred time" />
      <TextField source="sender.timezone" label="Time zone" />
      <TextField source="sender.email" label="Sender email" />
      <TextField source="status" />
    </Datagrid>
  </List>
);

export const ProfileAccessRequestsEdit = (props) => (
  <Edit {...props} title={<Breadcrumbs {...props} />}>
    <SimpleForm toolbar={<ProfileAccessRequestsToolbar />}>
      <TextField source="id" />
      <TextField source="profile.name" label="Profile" />

      <ReferenceField label="User" reference="users" source="sender.user.id" link="show">
        <TextField source="sender.user.name" />
      </ReferenceField>

      <ReferenceField label="Sender Profile" reference="profiles" source="sender.profile.id" link="show">
        <TextField source="sender.profile.name" />
      </ReferenceField>

      <TextField source="sender.email" />

      <TextField source="sender.phone_number" />

      <TextField source="sender.preferred_time" />

      <TextField source="sender.timezone" />
    </SimpleForm>
  </Edit>
);

export default ProfileAccessRequestsList;
