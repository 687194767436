import React from 'react';

import { List, TabbedShowLayout, Filter, TextInput, Datagrid, TextField, Edit, SimpleForm, Tab, Toolbar, SaveButton, DeleteButton, SelectInput } from 'react-admin';

import { withStyles } from '@material-ui/core';
import CustomDateField from '../components/customFields/CustomDateField';
import ValidityShow from '../components/ValidityShow';
import { VALIDATION_STATUSES } from '../constants/validations';
import { Breadcrumbs } from '../components/Breadcrumbs';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const CustomToolbar = withStyles(toolbarStyles)((props) => (
  <Toolbar {...props}>
    <SaveButton />
    <DeleteButton undoable={false} />
  </Toolbar>
));

const LanguagesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="query" alwaysOn />
    <SelectInput label="Validation status" source="validation_status" choices={VALIDATION_STATUSES} alwaysOn />
  </Filter>
);

export const LanguagesShow = (props) => {
  return (
    <ValidityShow {...props} title={<Breadcrumbs {...props} />}>
      <TabbedShowLayout>
        <Tab label="Language info">
          <TextField source="name" />
          <CustomDateField source="createdAt" />
          <CustomDateField source="updatedAt" />
          <TextField source="region.name" label="Region Name" />
        </Tab>
      </TabbedShowLayout>
    </ValidityShow>
  );
};

export const LanguagesEdit = (props) => (
  <Edit {...props} title={<Breadcrumbs {...props} />} toolbar={<CustomToolbar />}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="region.name" label="Region Name" />
    </SimpleForm>
  </Edit>
);

export const LanguagesList = (props) => (
  <List exporter={false} {...props} title={<Breadcrumbs {...props} />} filters={<LanguagesFilter />} bulkActionButtons={false} perPage={1000}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" label="Language Name" />
      <TextField source="region.name" label="Region Name" />
      <TextField label="Validation status" source="validationStatus.name" />
    </Datagrid>
  </List>
);

export default LanguagesList;
