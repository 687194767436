import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, MenuItem, Select } from '@material-ui/core';
import { useMutation, useNotify, useRefresh } from 'react-admin';
import { VALIDATION_STATUSES_WITH_IDS } from '../constants/validations';

const useStyles = makeStyles(() => ({
  container: {
    padding: '10px 5px 0',
  },
  button: {
    textTransform: 'inherit',
    margin: '15px 0px',
  },
  icon: {
    fontSize: 16,
    marginRight: 2,
  },
  dialog: { width: 350 },
}));

export default function ChangeValidationStatus(props) {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  let { resource } = props;
  const { id, record, selectAnchorEl } = props;
  const currentValidationStatusId = record?.validationStatus?.id;
  if (resource === 'countries/cities') {
    resource = 'cities';
  }

  const [reason, setReason] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [validationStatusId, setValidationStatusId] = useState(currentValidationStatusId);

  const [updateValidationStatus] = useMutation(
    {
      type: 'create',
      resource: 'validations/activities',
      payload: {
        data: {
          entityType: resource,
          entityId: id,
          validationStatus: {
            id: validationStatusId,
          },
          reason,
        },
      },
    },
    {
      onSuccess: () => {
        notify('Validation Status updated', 'info');
        setReason(null);
        refresh();
        setLoading(false);

        if (props.onCLose) {
          props.onCLose();
        }
      },
      onFailure: (err) => {
        setLoading(false);
        notify(err?.message, 'error');
      },
    },
  );

  return (
    <div className={classes.container}>
      <Select
        fullWidth
        margin="dense"
        variant="filled"
        size="small"
        label="Validation Status"
        value={validationStatusId}
        onChange={(e) => setValidationStatusId(e.target.value)}
        MenuProps={{ disablePortal: true, anchorEl: this, anchorReference: selectAnchorEl }}
        onClick={(e) => props.setSelectAnchorEl(e?.currentTarget)}
      >
        {VALIDATION_STATUSES_WITH_IDS.map((status) => (
          <MenuItem value={status?.id}>{status?.name}</MenuItem>
        ))}
      </Select>
      <TextField size="small" margin="dense" label="Reason" fullWidth type="text" value={reason} onChange={(e) => setReason(e.target.value)} />
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={() => {
          setLoading(true);
          updateValidationStatus();
        }}
        disabled={!reason || isLoading}
      >
        {!isLoading ? 'Submit' : 'Loading...'}
      </Button>
    </div>
  );
}
