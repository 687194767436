import './App.css';

import React from 'react';
import { Admin, Resource, Login } from 'react-admin';
import { Route } from 'react-router-dom';
import customLayout from './components/Layout';
import customTheme from './components/Theme';
// Providers
import authProvider from './components/logincomponent';
import dataProvider from './components/dataProviderComponent';
import addUploadFeature from './components/dataProviderComponent/addUploadFeature';

// Models
import { AuthLogList, AuthLogShow } from './models/AuthModel';
import { AgenciesList, AgenciesShow, AgentCreate, AgencyCreate, AgencyEdit } from './models/AgenciesModel';
import { CitiesList, CitiesShow, CityAltnamesList, CountriesList, CountriesShow } from './models/CountriesModel';
import { CompetitionList, CompetitionShow, CompetitionEdit, CompetitionCreate } from './models/CompetitionModel';
import { InvoicesCreate, InvoicesCreateOneTimeCharge, InvoicesList, InvoicesShow } from './models/InvoicesModel';
import { LanguagesList, LanguagesShow } from './models/LanguagesModel';
import { OrdersList, OrdersShow } from './models/OrdersModel';
import { MediaList, MediaShow, MediaEdit } from './models/MediaModel';
import { MailsList, MailsShow } from './models/MailsModel';
import { PerformancesList, PerformancesShow } from './models/PerformancesModel';
import { ProductsList, ProductsShow } from './models/ProductsModel';
import { ProductionsList, ProductionsShow } from './models/ProductionsModel';
import { ProfilesList, ProfilesShow, ProfilesEdit, ProfilesCreate } from './models/ProfilesModel';
import { SubscriptionsList, SubscriptionsShow } from './models/SubcriptionsModel';
import { ReviewList, ReviewShow, ReviewEdit } from './models/ReviewModel';
import { UserList, UserShow, UserEdit, UserCreate, UserGroupShow, UserPermissionShow, UserGroupsCreate, ProfileAccessCreate } from './models/UsersModel';
import { UserSubscriptionsList, UserSubscriptionsShow, UserSubscriptionsCreate, UserSubscriptionsEdit } from './models/UserSubcriptionsModel';
import { UserRegistrationEdit, UserRegistrationList, UserRegistrationShow } from './models/UserRegistrationsModel';
import { UserPaymentMethodsList, UserPaymentMethodShow } from './models/UserPaymentMethodsModel';
import { UserTransactionsList, UserTransactionsShow } from './models/TransactionsModel';
import { ProfileAccessRequestsShow, ProfileAccessRequestsList, ProfileAccessRequestsEdit } from './models/ProfileAccessRequestsModel';
import { VenueCreate, VenueEdit, VenuesList, VenuesShow } from './models/VenuesModel';
import { ProfileClaimsList, ProfileClaimsShow, ProfileClaimsEdit } from './models/ProfileClaimsModel';
import { WorkRolesList, WorkRolesShow, WorkRolesEdit, WorkRolesCreate } from './models/WorkRolesModel';
import { WorksList, WorksShow, WorksEdit, WorksCreate } from './models/WorksModel';
// import { ExportView } from './models/ExportModel';
import { UserStatsList } from './models/UserStatsModel';
import { Dashboard } from './components/dashboard/index';
import NotFound from './models/ErrorModel';
import ValidationsList, { ValidationShow } from './models/ValidationsModel';
import SupportMessagesList, { SupportMessagesShow } from './models/SupportMessagesModel';
import CueTVCustomersList, { CueTVCustomersShow, CueTVSubscriptionsList, CueTVSubscriptionsShow, UserCueTVCustomersList, UserCueTVCustomersShow } from './models/CueTVModel';
import { CreditNotesList, CreditNotesShow } from './models/CreditNotesModel';
import { UnbilledChargesList, UnbilledChargesShow } from './models/UnbilledChargesModel';
import CouponsList, { CouponCodesCreate, CouponCodesEdit, CouponCodesShow, CouponsCreate, CouponsEdit, CouponSetsCreate, CouponSetsShow, CouponsShow } from './models/CouponsModel';
import { AddonsCreate, AddonsEdit, AddonsList, AddonsShow } from './models/AddonsModel';
import { MergeEntitiesList, MergeEntitiesCreate } from './models/MergeEntitiesModel';
import { ProductionValidationShow, ProductionValidationList } from './models/ProductionValidationModel';
import { EntitySearchList } from './models/EntitySearchModel';
import { MetricDBViewList } from './models/MetricDBViewModel';
import { EmailMetricsModelShow } from './models/EmailMetricsModel';
import { ProArtistMetricsModelShow } from './models/ProArtistMetricsModel';
import { NotificationMetricsModelShow } from './models/NotificationMetrics';
import { GiftSubscriptionMetricsModelShow } from './models/GiftSubscriptionMetrics';
import { MergeCustomerShow } from './models/MergeCustomerModel';
import ValidationDashboard from './components/dashboard/ValidationDashboard';
import DataDashboard from './components/dashboard/DataDashboard';
import UserDashboard from './components/dashboard/UserDashboard';
import { PerformancesValidationList, PerformancesValidationShow } from './models/PerformancesValidation';
import { DataInconsistenciesList } from './models/DataInconsistenciesModal';
import { PayoutExport } from './models/PayoutExport';
import { ProductionsCastAndCrewList } from './models/ProductionsCastAndCrewModel';
import { WorksTypesList, WorksTypesShow, WorksTypesCreate } from './models/WorksTypesModal';
import { OrganizationTypesList, OrganizationTypesCreate, OrganizationTypesShow } from './models/OrganizationTypesModal';
import { StagingTypesList, StagingTypesShow, StagingTypesCreate, StagingTypesEdit } from './models/StagingTypesModal';
import DocumentationLinks from './components/DocumentationLinks';
import EntityValidationMetricsList from './models/EntityValidationModal';
import DatapointMetricsList from './models/DatapointMetricsModal';
import DatapointMetaList from './models/DatapointMetaModal';
import { DomainsList, DomainsCreate, DomainsEdit, DomainsShow } from './models/DomainsModal';
import { RedMaskRequestList, RedMaskRequestShow } from './models/RedMaskRequestModel';
import UsersMetrics from './components/UsersMetrics';
import Testimonials from './components/Testimonials';
import EntityValidationActivities from './models/EntityValidationActivities';
import FingerprintMetrics from './components/FingerprintMetrics';
import { OrganizationsList, OrganizationsShow, OrganizationCreate, OrganizationEdit, OrganizationEmployeeCreate, OrganizationEmployeeEdit } from './models/OrganizationsModal';
import CastingToolUsage from './components/CastingToolUsage';
import Fingerprint from './models/Fingerprint';
import { CurationList, CurationShow, CurationEdit } from './models/CurationModel';
import { WhitelistRequestsList, WhitelistRequestsCreate, WhitelistRequestsShow } from './models/WhitelistRequestsModel';
import { EntityDuplicatesList } from './models/EntityDuplicatesModel';
import { InstrumentsList, InstrumentsShow, InstrumentsCreate, InstrumentsEdit } from './models/InstrumentsModel';
import { InstrumentRolesList, InstrumentRolesShow, InstrumentRolesCreate, InstrumentRolesEdit } from './models/InstrumentRolesModel';
import { ProfessionsList, ProfessionsShow, ProfessionsCreate, ProfessionsEdit } from './models/ProfessionsModel';
import { ArtistFreeTrialsQueueList, ArtistFreeTrialsQueueShow } from './models/ArtistFreeTrialsQueueModal';
import { ArtistRenewalsQueueList, ArtistRenewalsQueueShow } from './models/ArtistRenewalsQueueModal';
import { MissingSeasonQueueList, MissingSeasonQueueShow } from './models/MissingSeasonQueueModal';
import { MissingProducerQueueList, MissingProducerQueueShow } from './models/MissingProducerQueueModal';
import { ScrapeRequestList, ScrapeRequestShow, ScrapeRequestCreate } from './models/ScrapeRequestModel';
import { TaskQueueMetricsModelShow } from './models/TaskQueueMetricsModel';
import { MissingCanonicalQueueList, MissingCanonicalQueueShow } from './models/MissingCanonicalQueueModal';
import { MultipleCastQueueList, MultipleCastQueueShow } from './models/MultipleCastQueueModal';
import { ArtistWithoutProfileAccessQueueList, ArtistWithoutProfileAccessQueueShow } from './models/ArtistWithoutProfileAccessQueueModal';
import { ArtistWithWrongProfessionQueueList, ArtistWithWrongProfessionQueueShow } from './models/ArtistWithWrongProfessionQueueModal';
import ScrapeMetrics from './components/ScrapeMetrics';
import { OrganizationDepartmentsList, OrganizationDepartmentsShow } from './models/OrganizationDepartmentsModel';
import ConcertVariousQueueList, { ConcertVariousQueueShow } from './models/ConcertVariousQueueModal';
import DuplicateQueueMetrics from './components/DuplicateQueueMetrics';
import AdsMetrics from './components/AdsMetrics';
import MagazineExportQueueList, { MagazineExportQueueShow } from './models/MagazineExportModel';
import { DataQCTaskList, DataQCTaskShow, DataQCTaskCreate } from './models/DataQCModel';
import MissingProgrameeQueueList, { MissingProgrameeQueueShow } from './models/MissingProgrameeQueueModal';
import Insights from './models/Insights';
import AgencyArtistEnrichList, { AgencyArtistEnrichShow } from './models/AgencyArtistEnrichmentModel';
import KnowledgePanelTaskQueueList, { KnowledgePanelTaskQueueShow } from './models/KnowledgePanelTaskQueueModel';

const MyLoginPage = () => <Login backgroundImage="background.jpg" />;

const enhancedDataProvider = addUploadFeature(dataProvider);

const App = () => (
  <Admin
    theme={customTheme}
    layout={customLayout}
    catchAll={NotFound}
    authProvider={authProvider}
    loginPage={MyLoginPage}
    dashboard={Dashboard}
    dataProvider={enhancedDataProvider}
    customRoutes={[
      // <Route path="/export" component={ExportView} />,
      <Route path="/payout/export" component={PayoutExport} />,
      <Route path="/links" component={DocumentationLinks} noLayout />,
      <Route path="/testimonials" component={Testimonials} />,
    ]}
  >
    <Resource name="profiles/profileaccesses" create={ProfileAccessCreate} />
    <Resource name="auth/logs" list={AuthLogList} show={AuthLogShow} options={{ label: 'Authentication Logs' }} />
    <Resource name="auth/forgotPassword" />
    <Resource name="profiles/claims" list={ProfileClaimsList} show={ProfileClaimsShow} edit={ProfileClaimsEdit} options={{ label: 'Profile claims' }} />
    <Resource name="agencies/agents" create={AgentCreate} options={{ label: 'Agency Agents' }} />
    <Resource name="agencies/artists" options={{ label: 'Agency Artists' }} />
    <Resource name="agencies/offices" options={{ label: 'Agency Offices' }} />
    <Resource name="agencies/access_requests" options={{ label: 'Agency access requests' }} />
    <Resource name="agencies" list={AgenciesList} show={AgenciesShow} create={AgencyCreate} edit={AgencyEdit} options={{ label: 'Agencies' }} />
    <Resource name="organizations/employees" create={OrganizationEmployeeCreate} edit={OrganizationEmployeeEdit} options={{ label: 'Organization Employees' }} />
    <Resource name="organizations/contacts" options={{ label: 'Organizations Contacts' }} />
    <Resource name="organizations/addresses" options={{ label: 'Organizations Addresses' }} />
    <Resource name="competitions" list={CompetitionList} show={CompetitionShow} edit={CompetitionEdit} create={CompetitionCreate} options={{ label: 'Competitions' }} />
    <Resource name="instruments/roles" list={InstrumentRolesList} show={InstrumentRolesShow} create={InstrumentRolesCreate} edit={InstrumentRolesEdit} options={{ label: 'Instrument Roles' }} />
    <Resource name="instruments" list={InstrumentsList} show={InstrumentsShow} create={InstrumentsCreate} edit={InstrumentsEdit} options={{ label: 'Instruments' }} />
    <Resource name="productions/ensembles" options={{ label: 'Ensemble productions' }} />
    <Resource name="countries/cities/altnames" list={CityAltnamesList} options={{ label: 'Geonames' }} />
    <Resource name="countries/cities/translations" />
    <Resource name="countries/cities" list={CitiesList} show={CitiesShow} options={{ label: 'Cities' }} />
    <Resource name="countries" list={CountriesList} show={CountriesShow} options={{ label: 'Countries' }} />
    <Resource name="mails/logs" options={{ label: 'Mail Logs (status history)' }} />
    <Resource name="mails" list={MailsList} show={MailsShow} options={{ label: 'Mail Logs' }} />
    <Resource name="media" list={MediaList} show={MediaShow} edit={MediaEdit} options={{ label: 'Media' }} />
    <Resource name="languages" list={LanguagesList} show={LanguagesShow} options={{ label: 'Languages' }} />
    <Resource name="performances" list={PerformancesList} show={PerformancesShow} options={{ label: 'Performances' }} />
    <Resource name="productions/documents" />
    <Resource name="productions/actions" />
    <Resource name="productions/productionPerformances" />
    <Resource name="productions/events" />
    <Resource name="productions/tickets" />
    <Resource name="productions/castandcrew" list={ProductionsCastAndCrewList} options={{ label: 'Productions cast and crew' }} />
    <Resource name="productions/redMaskRequests" list={RedMaskRequestList} show={RedMaskRequestShow} options={{ label: 'Artist Red Masks' }} />
    <Resource name="scrape-productions" show={ProductionsShow} options={{ label: 'Scrape Productions' }} />
    <Resource name="productions" show={ProductionsShow} list={ProductionsList} options={{ label: 'Productions' }} />
    <Resource name="profiles/profileaccessrequests" show={ProfileAccessRequestsShow} list={ProfileAccessRequestsList} edit={ProfileAccessRequestsEdit} options={{ label: 'Profile access requests' }} />
    <Resource name="products" show={ProductsShow} list={ProductsList} options={{ label: 'Products' }} />
    <Resource name="profiles" list={ProfilesList} show={ProfilesShow} edit={ProfilesEdit} create={ProfilesCreate} options={{ label: 'Profiles' }} />
    <Resource name="profiles/types" />
    <Resource name="reviews" list={ReviewList} show={ReviewShow} edit={ReviewEdit} options={{ label: 'Reviews' }} />
    <Resource name="subscriptions" show={SubscriptionsShow} list={SubscriptionsList} options={{ label: 'Subscriptions' }} />
    <Resource name="users/subscriptions/unbilledCharges" show={UnbilledChargesShow} list={UnbilledChargesList} options={{ label: 'Unbilled charges' }} />
    <Resource name="users/subscriptions" show={UserSubscriptionsShow} list={UserSubscriptionsList} create={UserSubscriptionsCreate} edit={UserSubscriptionsEdit} options={{ label: 'Subscriptions' }} />
    <Resource name="users/supportrequests" list={SupportMessagesList} show={SupportMessagesShow} options={{ label: 'Support Requests' }} />
    <Resource name="users/paymentmethods" show={UserPaymentMethodShow} list={UserPaymentMethodsList} options={{ label: 'Payment Methods' }} />
    <Resource name="users/payments" show={UserTransactionsShow} list={UserTransactionsList} options={{ label: 'Payment transactions' }} />
    <Resource name="users/groups" show={UserGroupShow} create={UserGroupsCreate} options={{ label: 'Groups' }} />
    <Resource name="users/permissions" show={UserPermissionShow} options={{ label: 'Permissions' }} />
    <Resource name="users/registrations" show={UserRegistrationShow} list={UserRegistrationList} edit={UserRegistrationEdit} options={{ label: 'Registrations' }} />
    <Resource name="users/cuetv/customers" show={UserCueTVCustomersShow} list={UserCueTVCustomersList} options={{ label: 'CueTV Operabase Pro Users' }} />
    <Resource name="users/paymentMethods" />
    <Resource name="users/settings" />
    <Resource name="users/stats" list={UserStatsList} />
    <Resource name="users/mergecustomer" list={MergeCustomerShow} options={{ label: 'Merge Customers' }} />
    <Resource name="users/dashboard" list={UserDashboard} options={{ label: 'Data Dashboard' }} />
    <Resource name="users/emailPreferences" />
    <Resource name="users/subscriptions/sourceDescriptions" />
    <Resource name="users" list={UserList} edit={UserEdit} show={UserShow} create={UserCreate} options={{ label: 'Users' }} />
    <Resource name="venues" show={VenuesShow} list={VenuesList} create={VenueCreate} edit={VenueEdit} options={{ label: 'Venues' }} />
    <Resource name="groups" options={{ label: 'Groups' }} />
    <Resource name="workTypes" list={WorksTypesList} show={WorksTypesShow} create={WorksTypesCreate} options={{ label: 'Works types' }} />
    <Resource name="organizationTypes" list={OrganizationTypesList} show={OrganizationTypesShow} create={OrganizationTypesCreate} options={{ label: 'Organization types' }} />
    <Resource name="organizations/types" />
    <Resource name="stagingTypes" list={StagingTypesList} show={StagingTypesShow} create={StagingTypesCreate} edit={StagingTypesEdit} options={{ label: 'Staging types' }} />
    <Resource name="works/roles" show={WorkRolesShow} list={WorkRolesList} edit={WorkRolesEdit} create={WorkRolesCreate} options={{ label: 'Work Roles' }} />
    <Resource name="works/composers" />
    <Resource name="works/creators" />
    <Resource name="works/styles" />
    <Resource name="works/types" />
    <Resource name="works/stagingTypes" />
    <Resource name="works/roles/types" />
    <Resource name="productions/aggregations" edit={WorksEdit} options={{ label: 'Crew' }} />
    <Resource name="works" show={WorksShow} list={WorksList} edit={WorksEdit} create={WorksCreate} options={{ label: 'Works' }} />
    <Resource name="invoices/creditNotes" list={CreditNotesList} show={CreditNotesShow} options={{ label: 'Credit Notes' }} />
    <Resource name="invoices/oneTime" create={InvoicesCreateOneTimeCharge} />
    <Resource name="invoices" list={InvoicesList} show={InvoicesShow} create={InvoicesCreate} options={{ label: 'Invoices' }} />
    <Resource name="orders" list={OrdersList} show={OrdersShow} options={{ label: 'Orders' }} />
    <Resource name="action_user_types" />
    <Resource name="professions" list={ProfessionsList} show={ProfessionsShow} create={ProfessionsCreate} edit={ProfessionsEdit} options={{ label: 'Professions' }} />
    <Resource name="validations/locks" />
    <Resource name="validations/production-validations" list={ProductionValidationList} show={ProductionValidationShow} options={{ label: 'Production Validations' }} />
    <Resource name="validations/redmask-requests" list={ProductionValidationList} show={ProductionValidationShow} options={{ label: 'Redmask requests' }} />
    <Resource name="validations/change-requests" list={ProductionValidationList} show={ProductionValidationShow} options={{ label: 'Change requests' }} />
    <Resource name="validations/entities/types" />
    <Resource name="validations/statuses" />
    <Resource name="validations/merge" list={MergeEntitiesList} create={MergeEntitiesCreate} options={{ label: 'Merge' }} />
    <Resource name="validations/dashboard" list={ValidationDashboard} options={{ label: 'Validation dashboard' }} />
    <Resource name="validations/performances" list={PerformancesValidationList} show={PerformancesValidationShow} options={{ label: 'User Production Requests' }} />
    <Resource name="validations/activities" list={EntityValidationActivities} options={{ label: 'Entity validation activities' }} />
    <Resource name="validations/duplicates-entities" options={{ label: 'Duplicate queue' }} list={EntityDuplicatesList} />
    <Resource name="validations" list={ValidationsList} show={ValidationShow} options={{ label: 'Entity queue' }} />
    <Resource name="activities" />

    <Resource name="cuetv/customers/aggregations" />
    <Resource name="cuetv/customers/operabase" list={CueTVCustomersList} show={CueTVCustomersShow} options={{ label: 'CueTV Operabase Users' }} />
    <Resource name="cuetv/customers/all" list={CueTVCustomersList} show={CueTVCustomersShow} options={{ label: 'CueTV All Users' }} />
    <Resource name="cuetv/subscriptions" list={CueTVSubscriptionsList} show={CueTVSubscriptionsShow} options={{ label: 'CueTV Subscriptions' }} />

    <Resource name="coupons/sets" show={CouponSetsShow} create={CouponSetsCreate} />
    <Resource name="coupons/codes" show={CouponCodesShow} create={CouponCodesCreate} edit={CouponCodesEdit} />
    <Resource name="coupons" list={CouponsList} show={CouponsShow} create={CouponsCreate} edit={CouponsEdit} options={{ label: 'Coupons' }} />

    <Resource name="addons" list={AddonsList} show={AddonsShow} create={AddonsCreate} edit={AddonsEdit} options={{ label: 'Addons' }} />
    <Resource name="search" list={EntitySearchList} options={{ label: 'Search' }} />
    <Resource name="venues/stages" />

    <Resource name="metrics/dbviews" list={MetricDBViewList} options={{ label: 'Data Views' }} />
    <Resource name="metrics/full_metrics" />
    <Resource name="metrics/datapoint-metrics" list={DatapointMetricsList} options={{ label: 'Datapoint Metrics' }} />
    <Resource name="metrics/datapoint-meta" list={DatapointMetaList} options={{ label: 'Datapoint Meta' }} />
    <Resource name="metrics/production-action-metrics" list={DatapointMetricsList} options={{ label: 'Production Action Metrics' }} />
    <Resource name="metrics/email-metrics" list={EmailMetricsModelShow} options={{ label: 'Email Metrics' }} />
    <Resource name="metrics/pro-artist-metrics" list={ProArtistMetricsModelShow} options={{ label: 'Pro Artist Metrics' }} />
    <Resource name="metrics/notification-metrics" list={NotificationMetricsModelShow} options={{ label: 'Notifications Metrics' }} />
    <Resource name="metrics/gift-subscription-metrics" list={GiftSubscriptionMetricsModelShow} options={{ label: 'Gift subscription Metrics' }} />
    <Resource name="metrics/task-queue-metrics" list={TaskQueueMetricsModelShow} options={{ label: 'Task Queue Metrics' }} />
    <Resource name="data/dashboard" list={DataDashboard} options={{ label: 'Data Dashboard' }} />
    <Resource name="system/data_inconsistencies" list={DataInconsistenciesList} options={{ label: 'Data inconsistencies' }} />
    <Resource name="system/db_entities" />
    <Resource name="metrics/entity-validation-metrics" list={EntityValidationMetricsList} options={{ label: 'Entity Validation Metrics' }} />
    <Resource name="domains" list={DomainsList} create={DomainsCreate} edit={DomainsEdit} show={DomainsShow} options={{ label: 'Domains' }} />
    <Resource name="metrics/users" list={UsersMetrics} options={{ label: 'Users metrics' }} />
    <Resource name="metrics/fingerprint" list={FingerprintMetrics} options={{ label: 'Fingerprint Metrics' }} />
    <Resource name="organizations/departments" list={OrganizationDepartmentsList} show={OrganizationDepartmentsShow} options={{ label: 'Organizations Departments' }} />
    <Resource name="organizations" list={OrganizationsList} show={OrganizationsShow} create={OrganizationCreate} edit={OrganizationEdit} options={{ label: 'Organizations' }} />
    <Resource name="metrics/casting-tool-usage" list={CastingToolUsage} options={{ label: 'Casting tool usage' }} />
    <Resource name="fingerprint/calculations" list={Fingerprint} options={{ label: 'FingerPrint' }} />
    <Resource name="insights" list={Insights} options={{ label: 'Insights' }} />
    <Resource name="data/show-hide-logs" />
    <Resource name="curationlist" options={{ label: 'Curationlist' }} list={CurationList} show={CurationShow} edit={CurationEdit} />
    <Resource name="whitelist/requests" options={{ label: 'Whitelist requests' }} list={WhitelistRequestsList} show={WhitelistRequestsShow} create={WhitelistRequestsCreate} />
    <Resource name="task_queues/artistFreeTrials" options={{ label: 'Artists with new subscription' }} list={ArtistFreeTrialsQueueList} show={ArtistFreeTrialsQueueShow} />
    <Resource name="task_queues/artistRenewals" options={{ label: 'Artist Renewals' }} list={ArtistRenewalsQueueList} show={ArtistRenewalsQueueShow} />
    <Resource
      name="task_queues/artistsWithoutProfileAccess"
      options={{ label: 'Artists Without Profile Access' }}
      list={ArtistWithoutProfileAccessQueueList}
      show={ArtistWithoutProfileAccessQueueShow}
    />
    <Resource name="task_queues/missingSeason" options={{ label: 'Missing Season' }} list={MissingSeasonQueueList} show={MissingSeasonQueueShow} />
    <Resource name="task_queues/missingProducer" options={{ label: 'Missing Producer' }} list={MissingProducerQueueList} show={MissingProducerQueueShow} />
    <Resource name="scrape_requests" list={ScrapeRequestList} show={ScrapeRequestShow} create={ScrapeRequestCreate} options={{ label: 'Scrape Request' }} />
    <Resource name="task_queues/missingCanonical" options={{ label: 'Missing Canonical' }} list={MissingCanonicalQueueList} show={MissingCanonicalQueueShow} />
    <Resource name="task_queues/multipleCast" options={{ label: 'Multiple Cast' }} list={MultipleCastQueueList} show={MultipleCastQueueShow} />
    <Resource name="task_queues/concertVarious" options={{ label: 'Concert Various' }} list={ConcertVariousQueueList} show={ConcertVariousQueueShow} />
    <Resource name="tags" />
    <Resource name="metrics/scrape-requests" list={ScrapeMetrics} options={{ label: 'Scrape Metrics' }} />
    <Resource name="metrics/entity-merge-metrics" list={DuplicateQueueMetrics} options={{ label: 'Duplicate queue metrics' }} />
    <Resource name="metrics/artist-ads-metrics" list={AdsMetrics} options={{ label: 'Ads Metrics' }} />
    <Resource name="task_queues/magazine_export" list={MagazineExportQueueList} show={MagazineExportQueueShow} options={{ label: 'Magazine Export' }} />
    <Resource name="task_queues/data_qc" list={DataQCTaskList} show={DataQCTaskShow} create={DataQCTaskCreate} options={{ label: 'Data QC: performance' }} />
    <Resource name="task_queues/agency_artist_enrichment" list={AgencyArtistEnrichList} show={AgencyArtistEnrichShow} options={{ label: 'Agency Artists Enrichment' }} />
    <Resource name="task_queues/missing_program" list={MissingProgrameeQueueList} show={MissingProgrameeQueueShow} options={{ label: 'Missing Programme' }} />
    <Resource name="task_queues/wrong_profession" list={ArtistWithWrongProfessionQueueList} show={ArtistWithWrongProfessionQueueShow} options={{ label: 'Artists with Wrong Profession' }} />
    <Resource name="task_queues/external_reference_validations" list={KnowledgePanelTaskQueueList} show={KnowledgePanelTaskQueueShow} options={{ label: 'Knowledge Panel' }} />
    <Resource name="system/entity_external_references" />
  </Admin>
);

export default App;
