import { createMuiTheme } from '@material-ui/core/styles';
import deepOrange from '@material-ui/core/colors/deepOrange';
import lightGreen from '@material-ui/core/colors/lightGreen';
import red from '@material-ui/core/colors/red';

const Theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: deepOrange,
    secondary: lightGreen,
    error: red,
    mode: 'dark',
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
  },
  overrides: {
    MuiTabs: {
      root: {
        '& .MuiTab-root': {
          minWidth: 'max-content',
          maxWidth: 'max-content',
          fontSize: 13,
        },
      },
    },
    MuiTable: {
      root: {
        '& .MuiTableCell-root': {
          padding: '6px 8px 6px 8px',
        },
      },
    },
  },
});

export default Theme;
