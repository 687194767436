import React, { useState } from 'react';
import {
  List,
  ArrayField,
  Filter,
  SelectInput,
  TextInput,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  SimpleShowLayout,
  Edit,
  FunctionField,
  ReferenceManyField,
  Pagination,
  DateField,
} from 'react-admin';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Breadcrumbs } from '../components/Breadcrumbs';
import ValidityShow from '../components/ValidityShow';
import { ProductionsGrid } from './ProductionsModel';
import { VALIDATION_STATUSES } from '../constants/validations';
import { getComposerNamesFromCreators } from '../utils/composer';
import EntityTranslation from '../components/translation/EntityTranslation';

const useStyles = makeStyles({
  dialog: { width: '70vw', maxWidth: '70vw' },
});

const WorksTypeFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="query" alwaysOn />
    <SelectInput label="Validation status" source="validation_status" choices={VALIDATION_STATUSES} alwaysOn />
  </Filter>
);

export const WorksTypesShow = (props) => (
  <ValidityShow {...props} title={<Breadcrumbs {...props} />}>
    <SimpleShowLayout>
      <TextField source="name" />
      <ArrayField source="relatedWorkTypes">
        <Datagrid rowClick={(id, basePath, record) => `/workTypes/${record?.id}/show`}>
          <TextField source="id" />
          <TextField source="name" />
        </Datagrid>
      </ArrayField>
      <hr />
      <Typography variant="subtitle1">Work type translation</Typography>
      <EntityTranslation list={{ url: `works/types/${props?.id}/translations` }} update={{ url: `works/types/${props?.id}/translations` }} />
    </SimpleShowLayout>
  </ValidityShow>
);

export const WorksTypesList = (props) => {
  const classes = useStyles();
  const [selectedWorkType, setSelectedWorkType] = useState(null);
  const [isProductionModalOpen, setIsProductionModalOpen] = useState(false);
  const [isWorkModalOpen, setIsWorkModalOpen] = useState(false);

  return (
    <>
      <List exporter={false} title={<Breadcrumbs {...props} />} filterDefaultValues={{ validation_status: 'approved' }} filters={<WorksTypeFilter />} bulkActionButtons={false} {...props} perPage={25}>
        <Datagrid rowClick="show">
          <TextField source="id" />
          <TextField source="name" sortable={false} />
          <FunctionField render={(record) => record?.relatedWorkTypes?.map((h) => h.name).join(' , ')} label="Related work types" />
          <TextField source="validationStatus.name" label="Validation Status" sortable={false} />
          <FunctionField
            label="Actions"
            render={(record) => (
              <div style={{ minWidth: 'max-content' }}>
                <Button
                  style={{ textTransform: 'inherit', marginRight: 15 }}
                  size="small"
                  variant="contained"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedWorkType(record);
                    setIsWorkModalOpen(true);
                  }}
                >
                  View works
                </Button>
                <Button
                  style={{ textTransform: 'inherit' }}
                  size="small"
                  variant="contained"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedWorkType(record);
                    setIsProductionModalOpen(true);
                  }}
                >
                  View productions
                </Button>
              </div>
            )}
          />
        </Datagrid>
      </List>
      <Dialog
        PaperProps={{
          className: classes.dialog,
        }}
        fullWidth
        open={isProductionModalOpen}
        onClose={() => setIsProductionModalOpen(false)}
      >
        <DialogTitle style={{ padding: '16px 24px 8px' }}>Productions associated with {selectedWorkType?.name}</DialogTitle>
        <DialogContent>
          <ReferenceManyField
            {...props}
            label="Productions"
            reference="productions"
            sort={{ field: 'updated_at', order: 'DESC' }}
            pagination={<Pagination />}
            perPage={10}
            filter={{ work_type_id: selectedWorkType?.id, published: 'both' }}
          >
            <ProductionsGrid {...props} hideMask />
          </ReferenceManyField>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsProductionModalOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        PaperProps={{
          className: classes.dialog,
        }}
        fullWidth
        open={isWorkModalOpen}
        onClose={() => setIsWorkModalOpen(false)}
      >
        <DialogTitle style={{ padding: '16px 24px 8px' }}>Works associated with {selectedWorkType?.name}</DialogTitle>
        <DialogContent>
          <ReferenceManyField
            {...props}
            label="Works"
            reference="works"
            sort={{ field: 'production_count', order: 'DESC' }}
            pagination={<Pagination />}
            perPage={10}
            filter={{ work_type_id: selectedWorkType?.id, exclude_concerts: false }}
          >
            <Datagrid rowClick="show">
              <TextField source="id" />
              <FunctionField
                render={(record) => {
                  const composerNames = getComposerNamesFromCreators(record?.creators);
                  return composerNames;
                }}
                label="Composers"
              />
              <TextField source="name" />
              <TextField source="original_name" />
              <TextField source="workType.name" label="Work type" />
              <TextField source="stats.productionCount" sortBy="production_count" label="Production count" />
              <TextField label="Validation status" source="validationStatus.name" />
              <DateField source="createdAt" />
            </Datagrid>
          </ReferenceManyField>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsWorkModalOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const WorksTypesCreate = (props) => (
  <Create title="Create work type" {...props}>
    <SimpleForm submitOnEnter={false}>
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);

export const WorksTypesEdit = (props) => (
  <Edit title="Edit work type" {...props}>
    <SimpleForm submitOnEnter={false}>
      <TextInput source="name" />s
    </SimpleForm>
  </Edit>
);

export default WorksTypesList;
