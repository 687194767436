export const SubscriptionsListColors = {
  active: 'white',
  hold: 'lightYellow',
  cancelled: 'rgba(255,0,0,0.2)',
  canceled: 'rgba(255,0,0,0.2)',
  draft: 'lightcyan',
  'fbadmin-default': 'rgba(255,0,0,0.2)',
  in_trial: 'rgba(255,252,187,0.2)',
};

export const InvoicesListColors = {
  'booked-cancelled': 'rgba(0,0,0,0.05)',
  'booked-paid': '',
  'booked-nonpaid': 'rgba(255,0,0,0.2)',
  'booked-pendingpayment': 'lightYellow',
  'booked-declined': '',
  'undefined-nonpaid': '',
  'draft-nonpaid': '',
  'fbadmin-default': 'white',
};
