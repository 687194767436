import React, { useRef, useEffect, useState } from 'react';
import { isString } from 'lodash';
import {
  Create,
  List,
  Datagrid,
  TextField,
  FunctionField,
  Filter,
  TextInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  Toolbar,
  required,
  useNotify,
  useRedirect,
  useDataProvider,
} from 'react-admin';
import { useSelector } from 'react-redux';
import { Grid, makeStyles, Typography, Dialog, Button, Slide, IconButton, Toolbar as MUIToolbar, AppBar, FormControl, Select, InputLabel, MenuItem } from '@material-ui/core';
import { MergeType, FastForward, Close as CloseIcon, SwapVert as SwapVertIcon } from '@material-ui/icons';
import { useForm } from 'react-final-form';
import CustomDateField from '../components/customFields/CustomDateField';
import CustomDateTimeInput from '../components/customFields/CustomDateTimeInput';
import { MERGE_ENTITIES, MERGE_ALLOWED_ENTITIES } from '../constants/entities';
import EntityLink from '../components/EntityLink';
import { Breadcrumbs } from '../components/Breadcrumbs';
import WorkRoleProfessionModal from './WorkRoleProfessionModal';

const styles = () => ({
  mergeTitle: {
    margin: 14,
  },
  mergeIcon: {
    margin: 4,
    transform: 'rotate(90deg)',
  },
  mergeIcon2: {
    margin: 4,
  },
  spaceLeft: { marginLeft: 10 },
  previewSection: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 50,
  },
  iframe: {
    width: '49.5vw',
    height: '92vh',
    '&header': {
      display: 'none',
    },
  },
  mergeInput: { width: 350 },
  swapContainer: { display: 'flex', justifyContent: 'center', marginBottom: 10 },
  swapIcon: { cursor: 'pointer' },
  buttonIcon: {
    fontSize: 18,
    marginRight: 8,
  },
  flexItem: {
    display: 'flex',
    alignItems: 'center',
  },
  infoText: {
    marginLeft: 20,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export const MergeEntitiesFilter = (props) => (
  <Filter {...props}>
    <CustomDateTimeInput label="Merge date (from)" source="created_at_from" alwaysOn />
    <CustomDateTimeInput label="Merge date (to)" source="created_at_to" alwaysOn />
    <TextInput label="Requested by user ID" source="requested_by_user_id" alwaysOn />
    <SelectInput label="Entity Type" source="entity_type" choices={MERGE_ALLOWED_ENTITIES} alwaysOn />
    <TextInput label="Source entity ID" source="source_entity_id" alwaysOn />
    <TextInput label="Target entity ID" source="target_entity_id" alwaysOn />
  </Filter>
);

export const Entity = (props) => <EntityLink entity={props.record?.target} entityType={props.record?.entityType} />;
const getRequestedBy = (record) => {
  const user = record?.requestedByUser;

  if (user) {
    const { name = '', id = '', email = '' } = user;
    return `${name} (${id} - ${email})`.trim();
  }

  return record?.requestedByCommand || '-';
};

export const MergeEntitiesList = (props) => (
  <List exporter={false} title={<Breadcrumbs {...props} />} bulkActionButtons={false} filters={<MergeEntitiesFilter />} {...props} sort={{ field: 'id', order: 'DESC' }} perPage={25}>
    <Datagrid>
      <TextField source="id" label="ID" />
      <CustomDateField timeHidden source="createdAt" label="Merge date" sortable={false} />

      <TextField source="entityType" label="Entity" sortable={false} />
      <FunctionField label="Losing" render={(record) => <span style={{ textDecoration: 'line-through', color: '#999999' }}>{record.sourceId}</span>} />
      <TextField source="targetId" label="Winning" sortable={false} />
      <Entity />
      <FunctionField label="Merged By" render={(record) => getRequestedBy(record)} />
    </Datagrid>
  </List>
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MergeEntityActions = ({ formValues, entities = [] }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const classes = makeStyles(styles)();
  const sourceRef = useRef();
  const targetRef = useRef();
  const [isMerging, setIsMerging] = useState(false);
  const [showWorkRoleProfessionModal, setShowWorkRoleProfessionModal] = useState(false);

  const mergeEntity = MERGE_ENTITIES[formValues?.entityType];
  const source = useSelector((store) => store.admin.resources[mergeEntity?.reference])?.data?.[formValues?.sourceId];
  const target = useSelector((store) => store.admin.resources[mergeEntity?.reference])?.data?.[formValues?.targetId];
  const sourcePageId = mergeEntity?.pageId(source);
  const targetPageId = mergeEntity?.pageId(target);
  const sourcePageQueryParams = mergeEntity?.queryParams ? mergeEntity?.queryParams(source) : '';
  const targetPageQueryParams = mergeEntity?.queryParams ? mergeEntity?.queryParams(target) : '';

  const sURL = `${window.location.origin}/#/${mergeEntity?.pageURL}/${sourcePageId}/show?${sourcePageQueryParams}`;
  const tURL = `${window.location.origin}/#/${mergeEntity?.pageURL}/${targetPageId}/show?${targetPageQueryParams}`;
  const [isOpen, setOpen] = useState(false);
  let isDisabledPreview = !formValues?.entityType || !formValues?.sourceId || !formValues?.targetId;

  if (
    [MERGE_ENTITIES.workRoles?.id, MERGE_ENTITIES.instrumentRoles?.id, MERGE_ENTITIES.professions?.id, MERGE_ENTITIES.instruments?.id].includes(formValues?.entityType) &&
    !formValues?.targetLanguageId
  ) {
    isDisabledPreview = true;
  }

  const [sourceURL, setSourceURL] = useState('');
  const [targetURL, setTargetURL] = useState('');
  const [currentDiff, setCurrentDiff] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (formValues?.entityType && formValues?.sourceId && formValues?.targetId) {
      setSourceURL(`${window.location.origin}/#/${mergeEntity?.pageURL}/${sourcePageId}/show?${sourcePageQueryParams}`);
      setTargetURL(`${window.location.origin}/#/${mergeEntity?.pageURL}/${targetPageId}/show?${targetPageQueryParams}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, sourcePageId, targetPageId]);

  useEffect(() => {
    const interval = setInterval(() => {
      const modifiedSourceURL = sourceRef?.current?.contentWindow?.location?.href;
      const modifiedTargetURL = targetRef?.current?.contentWindow?.location?.href;
      const sourceURLDiff = modifiedSourceURL ? modifiedSourceURL.substring(sURL.length, modifiedSourceURL.length) : null;
      const targetURLDiff = modifiedTargetURL ? modifiedTargetURL.substring(tURL.length, modifiedTargetURL.length) : null;

      if (isString(sourceURLDiff) && sourceURLDiff !== currentDiff) {
        setCurrentDiff(sourceURLDiff);
        setTargetURL(`${tURL}${sourceURLDiff}`);
      } else if (isString(targetURLDiff) && targetURLDiff !== currentDiff) {
        setCurrentDiff(targetURLDiff);
        setSourceURL(`${sURL}${targetURLDiff}`);
      }
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceURL, targetURL]);

  const loadIframe = (elementId) => {
    const iframe = document.getElementById(elementId);
    const doc = iframe.contentDocument;
    doc.body.contentEditable = true;
    doc.head.innerHTML = `${doc.head.innerHTML}<style>.MuiDrawer-docked { display: none;} .MuiInputLabel-formControl { position: initial !important; } .MuiAppBar-root { display: none; } .layout > div:first-child { margin-top: 0; }</style>`;
  };

  const mergeEntities = (metaData = {}) => {
    setIsMerging(true);
    dataProvider
      .create('validations/merge', { data: { ...formValues, ...metaData } })
      .then(() => {
        setIsMerging(false);
        if (formValues?.entityType === MERGE_ENTITIES.productions.id) {
          // eslint-disable-next-line no-alert
          window.confirm('Go production edit immediately and verify the data is proper');
        } else {
          notify('Merged successfully');
        }

        redirect('/validations/merge');
      })
      .catch((error) => {
        setIsMerging(false);
        notify(error?.message);
      });
  };

  const handleMerge = () => {
    const sourceEntity = entities?.find((entity) => entity?.id === formValues?.sourceId);
    const targetEntity = entities?.find((entity) => entity?.id === formValues?.targetId);

    if (formValues?.entityType === MERGE_ENTITIES.workRoles.id && (sourceEntity?.workRoleProfessions?.length > 0 || targetEntity?.workRoleProfessions?.length > 0)) {
      setShowWorkRoleProfessionModal(true);
      return;
    }

    if (entities?.length > 0 && formValues?.entityType === MERGE_ENTITIES.productions.id) {
      if (sourceEntity?.addedBy?.slug === 'organization' && targetEntity?.addedBy?.slug === 'artist') {
        // eslint-disable-next-line no-alert
        window.confirm('AOS production cannot be merged with Artist production. Please switch the production ids.');
      } else {
        mergeEntities();
      }
    } else {
      mergeEntities();
    }
  };

  const handleWorkRoleMerge = (event, _source, _target, selectedProfessions, primaryProfession) => {
    const apiData = {
      metaInfo: { workRoleProfessions: selectedProfessions?.map((selProfession) => ({ ...selProfession, isPrimary: selProfession?.id === primaryProfession })) },
    };

    mergeEntities(apiData);
  };

  return (
    <Toolbar>
      <Button variant="contained" disabled={isDisabledPreview} onClick={() => setOpen(true)} className={classes.spaceLeft} color="secondary">
        Preview
      </Button>
      <Dialog fullScreen onClose={handleClose} open={isOpen} TransitionComponent={Transition}>
        <AppBar color="secondary" sx={{ position: 'relative' }}>
          <MUIToolbar variant="dense" className={classes.toolbar}>
            <div className={classes.flexItem}>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Preview {`(Source ${formValues?.sourceId} <> Target ${formValues?.targetId})`}
              </Typography>
            </div>
            <Button variant="contained" color="primary" onClick={handleMerge} className={classes.spaceLeft} disabled={isMerging}>
              <MergeType className={classes.buttonIcon} /> Merge
            </Button>
          </MUIToolbar>
        </AppBar>
        <div className={classes.previewSection}>
          <div>
            <iframe id="sourceIframe" className={classes.iframe} title="source" ref={sourceRef} src={sourceURL} onLoad={() => loadIframe('sourceIframe')} />
          </div>
          <div>
            <iframe id="targetIframe" className={classes.iframe} title="target" ref={targetRef} src={targetURL} onLoad={() => loadIframe('targetIframe')} />
          </div>
        </div>
      </Dialog>
      {showWorkRoleProfessionModal && (
        <WorkRoleProfessionModal
          setShowWorkRoleProfessionModal={showWorkRoleProfessionModal}
          onSubmit={handleWorkRoleMerge}
          source={entities?.find((entity) => entity?.id === formValues?.sourceId)}
          target={entities?.find((entity) => entity?.id === formValues?.targetId)}
        />
      )}
    </Toolbar>
  );
};

const MergeSourceReference = ({ onChangeForm, searchParams, setEntities }) => {
  const dataProvider = useDataProvider();
  const form = useForm();
  const search = new URLSearchParams(searchParams);
  const classes = makeStyles(styles)();
  const entityType = search.get('entity_type');
  const sourceId = parseInt(search.get('source_id'), 0);
  const targetId = parseInt(search.get('target_id'), 0);
  const [selectedEntityType, setSelectedEntityType] = useState(entityType || MERGE_ENTITIES.organizations.id);
  const [selectedSourceId, setSelectedSourceId] = useState(sourceId || null);
  const [selectedTargetId, setSelectedTargetId] = useState(targetId || null);
  const [languages, setLanguages] = useState([]);
  const [targetLanguageId, setTargetLanguageId] = useState(null);

  useEffect(() => {
    dataProvider.getList('languages', { filter: {}, pagination: {}, sort: {} }).then(({ data }) => {
      setLanguages(data);
    });
  }, [dataProvider]);

  useEffect(() => {
    onChangeForm(form.getState()?.values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.getState()?.values]);

  const swapMergeIds = () => {
    const swapSourceId = form.getState()?.values?.sourceId;
    const swapTargetId = form.getState()?.values?.targetId;
    setSelectedSourceId(swapSourceId);
    setSelectedTargetId(swapTargetId);
    form.change('targetId', swapSourceId);
    form.change('sourceId', swapTargetId);
    onChangeForm({ entityType: selectedEntityType, sourceId: swapSourceId, targetId: swapTargetId });
  };

  useEffect(() => {
    if (entityType || selectedEntityType) {
      dataProvider.getList(MERGE_ENTITIES[entityType || selectedEntityType]?.reference, { filter: { id: [selectedSourceId, selectedTargetId] }, pagination: {}, sort: {} }).then((res) => {
        setEntities(res?.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProvider, selectedSourceId, selectedTargetId]);

  return (
    <div>
      <Grid container style={{ width: '100%' }}>
        <Grid item xs={1}>
          <Grid container className={classes.mergeTitle}>
            <Grid>
              <Typography variant="subtitle1">Merge</Typography>
            </Grid>
            <Grid>
              <MergeType className={classes.mergeIcon} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <SelectInput
            label="Entity Type"
            source="entityType"
            choices={MERGE_ALLOWED_ENTITIES}
            optionText="name"
            optionValue="id"
            initialValue={selectedEntityType}
            validate={required()}
            onChange={(event) => {
              form.change('targetId', null);
              form.change('sourceId', null);
              setSelectedEntityType(event.target.value);
            }}
            alwaysOn
          />
        </Grid>
        <Grid item xs={8} className={classes.flexItem}>
          <ReferenceInput
            className={classes.mergeInput}
            label={`Source ${MERGE_ENTITIES[selectedEntityType]?.name} (Search ID / Name)`}
            filter={{ limit: 50 }}
            source="sourceId"
            initialValue={selectedSourceId}
            reference={MERGE_ENTITIES[selectedEntityType]?.reference}
            shouldRenderSuggestions={false}
            sort={{ order: 'ASC', field: 'id' }}
            // eslint-disable-next-line no-restricted-globals
            filterToQuery={(query) => ({ query: isNaN(query) ? query : '', ...(!isNaN(query) && query ? { id: [parseInt(query, 0)] } : {}) })}
            validate={[required()]}
            onChange={(sId) => setSelectedSourceId(sId)}
          >
            <AutocompleteInput optionText={(entity) => (entity ? MERGE_ENTITIES[selectedEntityType]?.renderOption(entity) : null)} />
          </ReferenceInput>
          {selectedEntityType === MERGE_ENTITIES.organizations?.id && (
            <div className={classes.infoText}>Warning: All information which already exist in the target organisation will be lost in the source organisation after merge.</div>
          )}
          {[MERGE_ENTITIES.workRoles?.id, MERGE_ENTITIES.instrumentRoles?.id, MERGE_ENTITIES.professions?.id, MERGE_ENTITIES.instruments?.id].includes(selectedEntityType) && (
            <div>
              <FormControl style={{ minWidth: 200, maxWidth: 250 }} size="small">
                <InputLabel id="demo-select-small-label" className={classes.error}>
                  Select Target Language
                </InputLabel>
                <Select
                  value={targetLanguageId}
                  onChange={(e) => {
                    form.change('targetLanguageId', e?.target?.value?.id);
                    setTargetLanguageId(e?.target?.value);
                  }}
                  autoWidth
                  required
                >
                  {languages.map((item, index) => (
                    <MenuItem key={`${item?.id}-${index}`} value={item}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
        </Grid>
      </Grid>
      <Grid container style={{ width: '100%' }}>
        <Grid item xs={3}></Grid>
        <Grid item xs={3} className={classes.swapContainer}>
          <SwapVertIcon className={classes.swapIcon} onClick={swapMergeIds} />
        </Grid>
      </Grid>
      <Grid container style={{ width: '100%' }}>
        <Grid item xs={1}>
          {MERGE_ENTITIES[selectedEntityType] && (
            <Grid container className={classes.mergeTitle}>
              <Grid>
                <Typography variant="subtitle1">Into</Typography>
              </Grid>
              <Grid>
                <FastForward className={classes.mergeIcon2} />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={6}>
          <ReferenceInput
            className={classes.mergeInput}
            label={`Target ${MERGE_ENTITIES[selectedEntityType]?.name} (Search ID / Name)`}
            filter={{ limit: 50 }}
            source="targetId"
            initialValue={selectedTargetId}
            reference={MERGE_ENTITIES[selectedEntityType]?.reference}
            shouldRenderSuggestions={false}
            sort={{ order: 'ASC', field: 'id' }}
            // eslint-disable-next-line no-restricted-globals
            filterToQuery={(query) => ({ query: isNaN(query) ? query : '', ...(!isNaN(query) && query ? { id: [parseInt(query, 0)] } : {}) })}
            validate={[required()]}
            onChange={(tId) => setSelectedTargetId(tId)}
          >
            <AutocompleteInput optionText={(entity) => (entity ? MERGE_ENTITIES[selectedEntityType]?.renderOption(entity) : null)} />
          </ReferenceInput>
        </Grid>
      </Grid>
    </div>
  );
};

export const MergeEntitiesCreate = (props) => {
  const [formValues, setFormValues] = useState({});
  const [entities, setEntities] = useState({});

  const onChangeForm = (values) => {
    setFormValues(values);
  };

  return (
    <Create {...props}>
      <SimpleForm toolbar={<MergeEntityActions formValues={formValues} entities={entities} {...props} />}>
        <MergeSourceReference onChangeForm={onChangeForm} searchParams={props.location.search} setEntities={setEntities} />
      </SimpleForm>
    </Create>
  );
};
