/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { includes } from 'lodash';
import { GET_LIST } from 'react-admin';
import dataProvider from '../components/dataProviderComponent';
import storage from './storage';

const initialData = {
  languages: [],
  selectedLanguages: [],
  englishLanguage: {},
  error: null,
};

export const LanguagesProviderContext = createContext(initialData);

const LanguagesProvider = (props) => {
  // const storageLanguages = storage.getLanguagesList();
  const [stateLanguages, setStateLanguages] = useState(initialData.languages);
  const [statePreferredLanguages, setStatePreferredLanguages] = useState(initialData.languages);
  const storageSelectedLanguages = storage.getSelectedLanguagesList();
  const getEnglishLanguage = (list) => (Array.isArray(list) ? list.find((language) => language.slug === 'en') : {});
  const storageEnglishLanguage = getEnglishLanguage(stateLanguages);

  const [providerData, setProviderData] = useState({
    ...initialData,
    // languages: storageLanguages,
    languages: stateLanguages,
    preferredLanguages: statePreferredLanguages,
    selectedLanguages: storageSelectedLanguages,
    englishLanguage: storageEnglishLanguage,
  });

  const onSuccess = (response) => {
    const { data: languages } = response;
    const selectedLanguagesIds = providerData?.selectedLanguages?.map((language) => language.id);
    storage.setLanguagesList(languages);
    setStateLanguages(languages);
    setProviderData((prevState) => ({
      ...prevState,
      languages: languages.filter((language) => !includes(selectedLanguagesIds, language.id)),
      englishLanguage: getEnglishLanguage(languages),
    }));
  };
  const onPreferredLanguagesRequestSuccess = (response) => {
    const { data: languages } = response;
    const preferredLanguages = languages.map((lng) => ({ id: lng.slug, name: lng.name }));
    setStatePreferredLanguages(preferredLanguages);
    setProviderData((prevState) => ({
      ...prevState,
      preferredLanguages,
      englishLanguage: getEnglishLanguage(languages),
    }));
  };

  const onFail = (error) => {
    storage.clearLanguagesList();
    setProviderData({ ...initialData, error: error.message });
  };

  useEffect(() => {
    // if (!storageLanguages) {
    const params = { filter: { is_active: true }, pagination: { page: 1, perPage: 1000 }, sort: { field: 'name', order: 'ASC' } };
    const preferredLanguagesParams = { pagination: { page: 1, perPage: 1000 }, sort: { field: 'name', order: 'ASC' } };
    dataProvider(GET_LIST, 'languages', params).then(onSuccess, onFail);
    dataProvider(GET_LIST, 'languages', preferredLanguagesParams).then(onPreferredLanguagesRequestSuccess, onFail);
    // }
  }, []);

  const setSelectedLanguages = (selectedLanguages) => {
    storage.setSelectedLanguagesList(selectedLanguages);
    setProviderData({
      ...providerData,
      selectedLanguages,
    });
  };

  const dataValue = useMemo(() => ({ ...providerData, setSelectedLanguages }), [providerData]);
  return <LanguagesProviderContext.Provider value={dataValue} {...props} />;
};

export const useLanguagesProvider = () => useContext(LanguagesProviderContext);

export default LanguagesProvider;
