import React from 'react';

import { Show, List, Filter, TextInput, Datagrid, TextField, SimpleShowLayout } from 'react-admin';
import { Breadcrumbs } from '../components/Breadcrumbs';

const OrdersFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="query" alwaysOn />
  </Filter>
);

export const OrdersShow = (props) => (
  <Show {...props} title={<Breadcrumbs {...props} />}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="profileType.name" />
      <TextField source="description" />
    </SimpleShowLayout>
  </Show>
);

export const OrdersList = (props) => (
  <List exporter={false} title={<Breadcrumbs {...props} />} filters={<OrdersFilter />} bulkActionButtons={false} {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="status" />
      <TextField source="creator.user.name" />
      <TextField source="totalPriceInclVat.amount" />
    </Datagrid>
  </List>
);

export default OrdersList;
