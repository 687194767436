export const ARTIST_TYPE_ID = 1;
export const AGENT_TYPE_ID = 2;
export const AOS_TYPE_ID = 3;
export const CASTING_TYPE_ID = 6;
export const ACADEMIA_TYPE_ID = 7;
export const JOURNALIST_TYPE_ID = 8;

export const PROFILE_NOTIFICATIONS = [
  { id: 'image', name: 'Image' },
  { id: 'video', name: 'Video' },
  { id: 'review', name: 'Review' },
  { id: 'performance', name: 'Performance' },
];
