import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableHead, TableRow, TableCell, Paper } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    margin: '0 20px',
  },
  section: {
    display: 'flex',
  },
  linkSection: {
    width: '100%',
  },
  otherSection: {
    width: '100%',
  },
  otherLinksCard: {
    minHeight: 200,
    padding: '10px 0',

    '& ul': {
      margin: 0,
      paddingInlineStart: 25,
    },

    '& li': {
      marginBottom: 10,
    },
  },
}));

export default function DocumentationLinks() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.section}>
        <div className={classes.linkSection}>
          <h2>Links</h2>
          <Table component={Paper}>
            <TableHead>
              <TableRow>
                <TableCell> </TableCell>
                <TableCell>Production</TableCell>
                <TableCell>Staging</TableCell>
                <TableCell>Quality</TableCell>
                <TableCell>Dev</TableCell>
                <TableCell>Circle CI</TableCell>
                <TableCell>Github</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Operabase</TableCell>
                <TableCell>
                  <a href="https://www.operabase.com" target="_blank" rel="noopener noreferrer">
                    operabase.com
                  </a>
                </TableCell>
                <TableCell>
                  <a href="https://frontbase.operabase.net" target="_blank" rel="noopener noreferrer">
                    frontbase.operabase.net
                  </a>
                </TableCell>
                <TableCell>
                  <a href="https://quality-frontbase.operabase.net" target="_blank" rel="noopener noreferrer">
                    quality-frontbase.operabase.net
                  </a>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <a href="https://app.circleci.com/pipelines/github/truelinked/frontbase-truelinked-com" target="_blank" rel="noopener noreferrer">
                    Frontbase
                  </a>
                </TableCell>
                <TableCell>
                  <a href="https://github.com/truelinked/frontbase-truelinked-com" target="_blank" rel="noopener noreferrer">
                    Frontbase
                  </a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Admin</TableCell>
                <TableCell>
                  <a href="https://admin.truelinked.net" target="_blank" rel="noopener noreferrer">
                    admin.truelinked.net
                  </a>
                </TableCell>
                <TableCell>
                  <a href="https://staging-admin.truelinked.net" target="_blank" rel="noopener noreferrer">
                    staging-admin.truelinked.net
                  </a>
                </TableCell>
                <TableCell>
                  <a href="https://quality-admin.truelinked.net" target="_blank" rel="noopener noreferrer">
                    quality-admin.truelinked.net/
                  </a>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <a href="https://app.circleci.com/pipelines/github/truelinked/admin-truelinked-com" target="_blank" rel="noopener noreferrer">
                    Admin
                  </a>
                </TableCell>
                <TableCell>
                  <a href="https://github.com/truelinked/admin-truelinked-com" target="_blank" rel="noopener noreferrer">
                    Admin
                  </a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>API</TableCell>
                <TableCell>
                  <a href="https://api.truelinked.com/v1" target="_blank" rel="noopener noreferrer">
                    api.truelinked.com/v1
                  </a>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <a href="https://quality-api.truelinked.net/v1" target="_blank" rel="noopener noreferrer">
                    quality-api.truelinked.net/v1
                  </a>
                </TableCell>
                <TableCell>
                  <a href="https://dev-api.truelinked.net/v1" target="_blank" rel="noopener noreferrer">
                    dev-api.truelinked.net/v1
                  </a>
                </TableCell>
                <TableCell>
                  <a href="https://app.circleci.com/pipelines/github/truelinked/api-truelinked-com" target="_blank" rel="noopener noreferrer">
                    API
                  </a>
                </TableCell>
                <TableCell>
                  <a href="https://github.com/truelinked/api-truelinked-com" target="_blank" rel="noopener noreferrer">
                    API
                  </a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Libretto</TableCell>
                <TableCell>
                  <a href="https://libretto.truelinked.net/" target="_blank" rel="noopener noreferrer">
                    libretto.truelinked.net/
                  </a>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <a href="https://quality-libretto.truelinked.net/" target="_blank" rel="noopener noreferrer">
                    quality-libretto.truelinked.net/
                  </a>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <a href="https://app.circleci.com/pipelines/github/truelinked/libretto" target="_blank" rel="noopener noreferrer">
                    Libretto
                  </a>
                </TableCell>
                <TableCell>
                  <a href="https://github.com/truelinked/libretto" target="_blank" rel="noopener noreferrer">
                    Libretto
                  </a>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>
      <div className={classes.otherSection}>
        <h2>Others</h2>
        <Paper className={classes.otherLinksCard}>
          <ul>
            <li>
              <a href="https://app.lokalise.com/project/825163985f4cbcc806caa9.39177393/?view=multi" target="_blank" rel="noopener noreferrer">
                Lokalise
              </a>
            </li>
            <li>
              <a href="https://app.bugsnag.com/organizations/arts-consolidated-aps/stability-center" target="_blank" rel="noopener noreferrer">
                Bugsnag
              </a>
            </li>
            <li>
              <a href="https://quality-api.truelinked.net/" target="_blank" rel="noopener noreferrer">
                API Documentation
              </a>
            </li>
            <li>
              <a href="https://truelinked.atlassian.net/jira/software/c/projects/FB/boards/8" target="_blank" rel="noopener noreferrer">
                JIRA Frontbase board
              </a>
            </li>
            <li>
              <a href="https://truelinked.atlassian.net/jira/software/c/projects/FB/boards/10" target="_blank" rel="noopener noreferrer">
                JIRA Bugtracker board
              </a>
            </li>
            <li>Cloudinary</li>
            <li>Cloudflare</li>
          </ul>
        </Paper>
      </div>
    </div>
  );
}
