import React from 'react';
import classNames from 'classnames';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { DoubleArrowSharp } from '@material-ui/icons';
import ReactPlayer from 'react-player';

const useStyles = makeStyles(() => ({
  label: {
    fontWeight: 'bold',
    marginRight: 8,
  },
}));

const DisplayMediaEntry = (props) => {
  const classes = useStyles();
  const { left, right } = props.change;

  const buildMediaEntry = (val) => {
    switch (val?.mediaType?.slug) {
      case 'image':
        return <img src={val?.file?.urls?.medium} alt="Production" />;

      case 'video':
        return <ReactPlayer className={classes.media} light={val?.thumbnail?.urls?.medium} key={val?.id} url={val.file ? val?.file?.urls?.original : val?.url} width="450px" height="250px" />;

      default:
        return <span>{`Invalid Media Type ${JSON.stringify(val)}`}</span>;
    }
  };

  if (left && right) {
    return (
      <>
        <span className={classNames(classes.element, classes.from)}>{buildMediaEntry(left)}</span>
        <DoubleArrowSharp className={classes.arrow} />
        <span className={classNames(classes.element, classes.to)}>{buildMediaEntry(right)}</span>
      </>
    );
  }

  if (right) {
    return <span className={classNames(classes.element, classes.to)}>{buildMediaEntry(right)}</span>;
  }

  if (left) {
    return <span className={classNames(classes.element, classes.from)}>{buildMediaEntry(left)}</span>;
  }

  return <>unknown</>;
}

const DisplayMedia = (props) => {
  const classes = useStyles();
  const { title, path, select } = props;

  const changes = props.changes.filter((item) => item.path.startsWith(path));

  if (changes.length === 0) {
    return <></>;
  }

  const fields = [];

  changes.forEach((change, index) => {
    if (change.left || change.right) {
      fields.push(<DisplayMediaEntry key={`${index}`} change={change} select={select} />);
    }
  });

  if (fields.length === 0) {
    return <></>;
  }

  return (
    <Typography variant="body2" gutterBottom>
      {title ? <label className={classes.label}>{title}:</label> : <></>}
      {fields}
    </Typography>
  );
};

export default DisplayMedia;
