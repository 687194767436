import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const dateParserCurrent = (value, emptyText = '') => {
  if (value) {
    const date = dayjs(value).format('YYYY-MM-DD HH:mm [(UTC]Z[)]');
    return date;
  }
  return emptyText;
};

export const dateParserUTC = (value, emptyText = '', timeHidden) => {
  if (value) {
    const date = dayjs(value)
      .utc()
      .format(timeHidden ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm [(UTC]Z[)]');
    return date;
  }
  return emptyText;
};

export const getDaysInMonth = (date) => 32 - new Date(date.getFullYear(), date.getMonth(), 32).getDate();

export const getDaysInYear = (date) => 366 - new Date(date.getFullYear(), date.getMonth(), 366).getDate();

export const getHoursFromCurrentTime = (date) => dayjs().diff(dayjs(date), 'hour');

export const getTotalDaysInMonths = (months) => {
  const daysInMonth = new Date();
  const y = daysInMonth.getFullYear();
  const m = daysInMonth.getMonth();

  let total = 0;

  for (let i = 1; i <= months; i += 1) {
    total += new Date(y, m + i, 0).getDate();
  }

  return total;
};

export const dateParserTime = (value) => dayjs(value).utc().format('DD-MM-YYYY HH:mm');
