import React, { useEffect, useState } from 'react';
import { keyBy, map, reduce } from 'lodash';
import { Datagrid, TextField, Pagination, useDataProvider, ListContextProvider, FunctionField, Loading } from 'react-admin';
import { Dialog, DialogContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import EntityLink from './EntityLink';
import { REGISTRATION_PROFILE_TYPES, REGISTRATION_COHORT_TYPES } from '../constants/registration';

const useStyles = makeStyles({
  button: {
    textTransform: 'initial',
  },
  dialog: {
    minWidth: 900,
    height: 540,
    padding: 10,
    '& .MuiDialogContent-root': { padding: 0 },
    '& .MuiDialogContent-root:first-child': { padding: 0 },
  },
  container: {
    marginTop: 10,
  },
  closeIcon: {
    position: 'absolute',
    right: 10,
    top: 10,
    cursor: 'pointer',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
  },
  launchIcon: {
    fontSize: 14,
    marginLeft: 5,
  },
  title: {
    fontWeight: 600,
    padding: 5,
  },
});

export default function ArtistFunnelModal({ open, onClose, chartData = {} }) {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [registrations, setRegistrations] = useState([]);
  const [users, setUsers] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (chartData?.activeLabel) {
      dataProvider
        .getList('users/registrations', {
          filter: {
            created_at_from: chartData?.activeLabel,
            created_at_to: chartData?.activeLabel,
            profile_type_id: 1,
          },
          pagination: { page, perPage },
          sort: {},
        })
        .then((res) => {
          setRegistrations(res);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, page, perPage]);

  useEffect(() => {
    if (registrations?.total) {
      const userIds = map(registrations?.data, 'user.id');
      dataProvider
        .getList('users', {
          filter: {
            id: userIds,
          },
          pagination: { perPage: 1000 },
          sort: {},
        })
        .then((res) => {
          const transformedData = reduce(
            res?.data,
            (acc, item) => {
              acc[item?.id] = item;
              return acc;
            },
            {},
          );
          setUsers(transformedData);
          setIsLoading(false);
        });

      dataProvider
        .getList('users/subscriptions', {
          filter: {
            user_id: userIds,
          },
          pagination: { perPage: 1000 },
          sort: {},
        })
        .then((res) => {
          const transformedData = reduce(
            res?.data,
            (acc, item) => {
              acc[item?.user?.id] = item;
              return acc;
            },
            {},
          );
          setSubscriptions(transformedData);
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrations]);

  const handleClose = () => {
    setPage(1);
    setPerPage(10);
    setRegistrations([]);
    setSubscriptions([]);
    setUsers([]);
    setIsLoading(false);
    onClose();
  };

  const listContext = {
    data: keyBy(registrations?.data, 'id'),
    ids: registrations?.data?.map(({ id }) => id),
    total: registrations.total,
    currentSort: {},
    basePath: 'users/registrations',
    resource: 'users/registrations',
    selectedIds: [],
    hasList: true,
    page,
    setPage,
    perPage,
    setPerPage,
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        className: classes.dialog,
        sx: { padding: 0 },
      }}
    >
      <DialogContent>
        <div className={classes.title}>Artist funnel - {chartData?.activeLabel}</div>
        <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        <div className={classes.container}>
          {isLoading ? (
            <Loading />
          ) : (
            <ListContextProvider value={listContext}>
              <Datagrid>
                <FunctionField label="User" render={(record) => <EntityLink entity={record?.user} entityType="users" />} sortable={false} />
                <FunctionField
                  label="Registration type"
                  render={(record) => (record?.profile_type_id ? REGISTRATION_PROFILE_TYPES.find((t) => t.id === record?.profile_type_id)?.name : REGISTRATION_COHORT_TYPES[record.flow_id])}
                  sortable={false}
                />
                <TextField source="step" label="Step" sortable={false} />
                <FunctionField label="Is verified" render={(record) => (users?.[record?.user?.id]?.isVerified ? 'Yes' : 'No')} sortable={false} />
                <FunctionField label="Subscription" render={(record) => subscriptions?.[record?.user?.id]?.subscription?.description} sortable={false} />
                <FunctionField label="Subscription status" render={(record) => subscriptions?.[record?.user?.id]?.status} sortable={false} />
              </Datagrid>
              <Pagination />
            </ListContextProvider>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
