/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import apiClient from '../../utils/api';

const supportDocumentTypes = {
  supportProductionPage: 'Production page (URL)',
  supportSignedContract: 'Signed contract',
  supportProductionReview: 'Production review',
  supportProductionProgramme: 'Production programme',
};

const ProductionEvidence = (props) => {
  const { productionId, isChangeRequestProduction } = props;
  const [evidence, setEvidence] = useState([]);

  useEffect(() => {
    const document_type = Object.keys(supportDocumentTypes);
    if (productionId) {
      apiClient
        .get(`productions/documents`, {
          params: {
            document_type,
            production_id: productionId,
            page: 1,
            limit: 1000,
          },
        })
        .then((res) => {
          setEvidence(res.data?.data);
        });
    }
  }, [productionId, isChangeRequestProduction]);

  if (evidence.length === 0) {
    return <div>No Evidence</div>;
  }

  return (
    <div>
      <Typography variant="body2" gutterBottom>
        <span style={{ fontWeight: 'bold', float: 'left' }}>Evidence: </span>
      </Typography>

      {evidence.map((document, index) => {
        const entry = [];
        if (index > 0) {
          entry.push(<span>, </span>);
        }
        if (document.file || document.url) {
          if (!document?.url?.startsWith('http')) {
            document.url = 'http://' + document.url;
          }
          entry.push(
            <a href={document?.file?.original || document.url} target="_blank" rel="noopener noreferrer">
              {supportDocumentTypes[document.documentType] || document.documentType}
            </a>,
          );

          if (document?.screenShot?.original) {
            entry.push(
              <a href={document?.screenShot?.original} target="_blank" rel="noopener noreferrer">
                (screenshot)
              </a>,
            );
          }
        } else {
          entry.push(<span>{supportDocumentTypes[document.documentType] || document.documentType}</span>);
        }

        return entry;
      })}
    </div>
  );
};

export default ProductionEvidence;
