import React from 'react';

import {
    Datagrid,
    ReferenceManyField,
  } from 'react-admin';

  const MediaThumbnail = props => {
    const isPrimaryPoster = props?.record?.attributes?.filter(attr => attr.isPrimary && attr.slug === 'poster').length > 0

    if (isPrimaryPoster) {
        return MediaThumbnailField(props)
    } else {
        return (null)
    }
  }

export const MediaThumbnailField = props => {
    const { basePath, label, resource, record } = props
    const size = props.size ?? 'small'
    

    if (record?.mediaType?.id === 4) {
        const productionTagId = record.tags?.filter(t => t?.tagType.slug === 'production')[0]?.tagId

        if (productionTagId) {
            let newProps = {
                basePath,
                label,
                record: {
                    id: productionTagId
                },
                resource,
            }

            return (<ReferenceManyField {...newProps} perPage={1} filter={{ media_type_id: 1, media_tag_type_id: 4 }} sort={{ field: 'createdAt', order: 'DESC' }} label="Media Records" reference="media" target="media_tag_id">
                <Datagrid rowClick="show">
                    <MediaThumbnail size={size} />
                </Datagrid>
            </ReferenceManyField>)
        }
    }

    

    let imageUrl = (record?.thumbnail?.urls ?? record?.file?.urls);
    if (imageUrl) {
        imageUrl = imageUrl[size]
    }

    if (!imageUrl) {
        return <div>N/A</div>   
    }
    

    return <img src={imageUrl} alt="Thumbnail" />
}
