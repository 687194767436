import React, { useState, useEffect } from 'react';
import { ListContextProvider, Title, Datagrid, TextField, Pagination, Loading } from 'react-admin';
import { TextField as MUITextField, MenuItem } from '@material-ui/core';
import { keyBy, sortBy } from 'lodash';
import apiClient from '../utils/api';

import { Breadcrumbs } from '../components/Breadcrumbs';

const inconsistencies_types = ['soft-delete', 'validation', 'general'];
export const DataInconsistenciesList = (props) => {
  const [entities, setEntities] = useState({ core_entities: [], related_entities: [] });
  const [selectedEntity, setSelectedEntity] = useState({ coreEntity: null, relatedEntity: null });
  const [data, setData] = useState({ data: [], total: 0, page: 1 });
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    apiClient.get('system/db_entities').then((res) => {
      setEntities(res?.data?.data?.[0]);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    apiClient.get('system/data_inconsistencies', { params: { page, limit: perPage, core_entity: selectedEntity?.coreEntity, related_entity: selectedEntity?.relatedEntity, type: selectedEntity?.type } }).then((res) => {
      setLoading(false);
      setPage(1);
      setData(res?.data);
    });
  }, [page, perPage, selectedEntity]);

  const total = data?.total;

  const listContext = {
    data: keyBy(data?.data, (item)=> `${item.related_entity}:${item.related_entity_id}:${item.core_entity}:${item.core_entity_id}`),
    ids: data?.data.map((item) => `${item.related_entity}:${item.related_entity_id}:${item.core_entity}:${item.core_entity_id}`),
    total,
    page,
    setPage,
    perPage,
    setPerPage,
    currentSort: { field: 'id', order: 'ASC' },
    basePath: props.basePath,
    resource: props.resource,
    selectedIds: [],
    hasList: true,
    hasShow: true,
  };

  if (loading) return <Loading />;

  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <MUITextField
          select
          variant="filled"
          label="Type"
          value={selectedEntity.type}
          onChange={(e) => setSelectedEntity({ ...selectedEntity, type: e.target.value })}
          style={{ width: 200, marginRight: 15 }}
        >
          {sortBy(inconsistencies_types)?.map((o) => (
            <MenuItem value={o}>{o}</MenuItem>
          ))}
        </MUITextField>
        <MUITextField
          select
          variant="filled"
          label="Core entity"
          value={selectedEntity.coreEntity}
          onChange={(e) => setSelectedEntity({ ...selectedEntity, coreEntity: e.target.value })}
          style={{ width: 200, marginRight: 15 }}
        >
          {sortBy(entities?.core_entities)?.map((o) => (
            <MenuItem value={o}>{o}</MenuItem>
          ))}
        </MUITextField>
        <MUITextField
          select
          variant="filled"
          label="Related entity"
          value={selectedEntity?.relatedEntity}
          onChange={(e) => setSelectedEntity({ ...selectedEntity, relatedEntity: e.target.value })}
          style={{ width: 200 }}
        >
          {sortBy(entities?.related_entities)?.map((o) => (
            <MenuItem value={o}>{o}</MenuItem>
          ))}
        </MUITextField>
      </div>
      <ListContextProvider value={listContext}>
        <Title title={<Breadcrumbs {...props} />} />
        <Datagrid key={props.related_entity+props.related_entity_id+props.core_entity+props.core_entity_id}>
          <TextField source="core_entity" />
          <TextField source="core_entity_id" />
          <TextField source="related_entity" />
          <TextField source="related_entity_id" />
          <TextField source="type" />
        </Datagrid>
        <Pagination {...props} />
      </ListContextProvider>
    </div>
  );
};

export default DataInconsistenciesList;
