import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Confirm, crudUpdateMany } from 'react-admin';

export const validateData = {
  type: 'validate',
  approvedBy: {
    id: 2, // admin
  },
  addedBy: {
    id: 4, // artist
  },
};

const ValidateProductionButton = ({ basePath, crudUpdateMany: updateMany, resource, selectedIds }) => {
  const [dialogIsOpen, setDialogOpen] = useState(false);

  const handleClick = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleConfirm = () => {
    const modifiedIds = selectedIds.map((id) => `${id}/actions`);
    updateMany(resource, modifiedIds, { ...validateData, method: 'POST' }, basePath);
    setDialogOpen(false);
  };

  const title = 'Validate Production';
  const content = `Are you sure you want to mark ${selectedIds.length} production(s) as validated?`;

  return (
    <>
      <Button label={title} onClick={handleClick} />
      <Confirm isOpen={dialogIsOpen} title={title} content={content} onConfirm={handleConfirm} onClose={handleDialogClose} />
    </>
  );
};

export default connect(undefined, { crudUpdateMany })(ValidateProductionButton);
