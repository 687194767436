import React from 'react';
import { TableCell } from '@material-ui/core';
import apiClient from '../../utils/api';
import { cloneDiff } from './utils';
import DisplayText from './DisplayText';
import DisplayTable from './DisplayTable';

const getData = async (productionId) => {
  const data = (await apiClient.get(`productions/${productionId}/reviews`, {
      params: {
        status: 'approved, pending',
        page: 1,
        limit: 100,
      },
    }))?.data?.data;

    return data;
};

export const parseProductionReviews = props => {
  const { production, changeRequest } = props;

  return new Promise(async (resolve, reject) => {
    const a = changeRequest ? await getData(production.id) : [];
    const b = changeRequest ? await getData(changeRequest.revisionId) : await getData(production.id);

    const data = cloneDiff(a,b);

    resolve(data);
  });
}

const ReviewRow = props => {
  return <>
    <TableCell>
      <DisplayText path="" changes={props.changes} select="title" />
    </TableCell>
    <TableCell>
      <DisplayText path="" changes={props.changes} select="reviewedAt" />
    </TableCell>
    <TableCell>
      <DisplayText path="" changes={props.changes} select="signature" />
    </TableCell>
    <TableCell>
      <DisplayText path="" changes={props.changes} select="reviewEntities[0].status.name" />
    </TableCell>
    <TableCell>
      <DisplayText path="" changes={props.changes} select="updatedAt" />
    </TableCell>
  </>
}

export const ProductionReviews = props => {
  return (
    <>
      <DisplayTable
        {...props} 
        changes={props.changes}
        title="Production reviews"
        header={['Title of review', 'Date', 'Reviewed by', 'Status', 'Last modified']}>
        <ReviewRow />
      </DisplayTable>
    </>
  );
};
