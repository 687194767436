import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import orderBy from 'lodash/orderBy';
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField as MUITextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TableHead,
  Table,
  TableRow,
  TableBody,
  TableCell,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import {
  List,
  Filter,
  Datagrid,
  TextField,
  FunctionField,
  useDataProvider,
  SelectInput,
  Title,
  Loading,
  useNotify,
  useMutation,
  TextInput,
  useRedirect,
  ReferenceInput,
  AutocompleteInput,
  DateInput,
} from 'react-admin';
import CustomDateField from '../components/customFields/CustomDateField';
import { Breadcrumbs } from '../components/Breadcrumbs';
import EntityLink from '../components/EntityLink';
import ValidationLockByUser from '../components/ValidationLockByUser';
import { dateParserUTC } from '../utils/dateParser';

const useStyles = makeStyles(() => ({
  actions: {
    marginTop: 20,
    borderBottom: '1px solid #ddd',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
  },
  centerAlign: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  spacingRight: {
    marginRight: 10,
  },
  button: {
    marginLeft: 20,
    textTransform: 'inherit',
    marginBottom: 15,
  },
  dialog: {
    width: 500,
  },
  spacingBottom: {
    marginBottom: 10,
  },
  bold: {
    fontWeight: 'bold',
  },
  table: {
    width: 700,
    marginBottom: 30,
  },
  tableRow: {
    cursor: 'pointer',
  },
  validationStatus: {
    fontSize: 16,
    fontWeight: 'bold',
    border: '1px solid #ff5722',
    padding: 6,
    backgroundColor: '#ff5722',
    color: 'white',
  },
  logContainer: {
    padding: '8px 16px',
  },
  link: {
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  logsAccordion: {
    marginTop: 20,
  },
  logsAccordionDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputField: {
    marginBottom: 10,
  },
}));

const ArtistRenewalsQueueFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Status"
      source="is_completed"
      choices={[
        { id: 'false', name: 'Pending' },
        { id: 'true', name: 'Done' },
      ]}
      alwaysOn
    />
    <TextInput label="Profile Id" source="profile_id" alwaysOn />
    <ReferenceInput label="Locked by" source="locked_by" reference="users" perPage={25} filter={{ group_id: 3 }} filterToQuery={(searchText) => ({ query: searchText })} alwaysOn>
      <AutocompleteInput optionText="email" />
    </ReferenceInput>
    <ReferenceInput label="Task completed by" source="completed_by" reference="users" perPage={25} filter={{ group_id: 3 }} filterToQuery={(searchText) => ({ query: searchText })} alwaysOn>
      <AutocompleteInput optionText="email" />
    </ReferenceInput>
    <DateInput source="task_specific_renewal_due_date_from" label="Renewal Date From" alwaysOn />
    <DateInput source="task_specific_renewal_due_date_to" label="Renewal Date To" alwaysOn />
  </Filter>
);

export const ArtistRenewalsQueueShow = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const [isLoading, setIsLoading] = useState(false);
  const [taskQueueData, setTaskQueueData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [comments, setComments] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [isLogExpanded, setLogsExpanded] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (props?.id) {
      dataProvider.getList('task_queues', { filter: { id: props?.id, task_type: 'artist_renewals' }, pagination: {}, sort: {} }).then(({ data }) => {
        setTaskQueueData(data?.[0]);
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.id]);

  const [handleComplete] = useMutation(
    {
      type: 'create',
      resource: `task_queues/actions`,
      payload: {
        data: {
          actionType: !taskQueueData?.isCompleted ? 'complete' : 'reset',
          id: taskQueueData?.id,
          comments,
        },
      },
    },
    {
      onSuccess: () => {
        notify('Task completed', 'info');
        window.location.reload();
      },
      onFailure: (err) => {
        notify(err?.message, 'error');
      },
    },
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <Title title={<Breadcrumbs {...props} />} />
      <div className={classes.actions}>
        <div className={classes.centerAlign}>
          <Typography variant="caption" className={classes.spacingRight}>
            Status
          </Typography>
          <Typography className={classes.validationStatus}>{taskQueueData?.isCompleted ? 'Done' : 'Pending'}</Typography>
        </div>
        <div className={classes.centerAlign}>
          <div className={classes.centerAlign}>
            <Typography variant="caption" className={classes.spacingRight}>
              Locked by
            </Typography>
            <ValidationLockByUser {...props} data={{ id: taskQueueData?.id }} lockGroupType="task_queue" />
          </div>
          <div>
            <Button variant="contained" size="small" className={classes.button} onClick={() => setIsOpen(true)}>
              {!taskQueueData?.isCompleted ? 'Mark as complete' : 'Reset'}
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.centerAlign} style={{ marginTop: 10 }}>
        <Typography variant="caption" className={classes.spacingRight}>
          Id
        </Typography>
        <Typography variant="caption">{taskQueueData?.id}</Typography>
      </div>
      <div className={classes.spacingBottom}>
        <Typography variant="caption" className={classes.spacingRight}>
          Profile
        </Typography>
        <EntityLink entity={taskQueueData?.profile} entityType="profiles" />
      </div>
      <div className={classes.spacingBottom}>
        <Typography variant="caption" className={classes.spacingRight}>
          Start score
        </Typography>
        <Typography variant="caption">{taskQueueData?.startScore}</Typography>
      </div>
      <div className={classes.spacingBottom}>
        <Typography variant="caption" className={classes.spacingRight}>
          Subscription
        </Typography>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>CreatedAt</TableCell>
              <TableCell>Renewal date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {taskQueueData?.profile?.subscription?.map((sub) => (
              <TableRow className={classes.tableRow} onClick={() => redirect(`/users/subscriptions/${sub?.id}/show`)}>
                <TableCell>{sub?.id}</TableCell>
                <TableCell>{sub?.status}</TableCell>
                <TableCell>{sub?.createdAt}</TableCell>
                <TableCell>{sub?.renewalDate}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className={classes.centerAlign}>
        <Typography variant="caption" className={classes.spacingRight}>
          Task added date
        </Typography>
        <Typography variant="caption">{taskQueueData?.createdAt}</Typography>
      </div>
      <div className={classes.centerAlign}>
        <Typography variant="caption" className={classes.spacingRight}>
          Task updated date
        </Typography>
        <Typography variant="caption">{taskQueueData?.updatedAt}</Typography>
      </div>
      <Accordion
        onChange={(e, isExpanded) => {
          setExpanded(isExpanded);
        }}
        expanded={expanded}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.bold}>
          ARTIST ENRICHMENT TASKS - NEW ARTISTS CHECKLIST/GUIDELINES
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <p>
              <strong>November 2023</strong>
            </p>
            <ul>
              <li>PRIMARY FOCUS of this process is now adding all missing productions to the artist’s profile</li>
              <li>
                We are to do a google search for the artist’s name and voice type/profession and if there are no results in the first 10 search results that we can use to enrich the profile, mark as
                done and move on to the next.
              </li>
              <li>We are also to enrich the profile but we are no longer adding any videos to the artist profile at all</li>
              <li>All photos MUST be from the artist’s professional website OR from the artist’s agency’s website OR from a reputable opera house’s website page for the artist</li>
            </ul>
            <ul>
              <li>Lock self to the task queue</li>
              <li>
                <strong>Update</strong>
              </li>
              <ul>
                <li>
                  Add <strong>all missing productions</strong> from the artist’s season and we need to focus on adding all materials related to performances, ensure to add:
                </li>
                <ul>
                  <li>All production videos connected with the production</li>
                  <li>All production reviews connected with the production</li>
                  <li>All production photos connected with the production</li>
                  <li>All entities related to the production</li>
                </ul>
                <li>The artist’s website</li>
                <ul>
                  <li>
                    <strong>Update Performances</strong>
                  </li>
                  <li>Add links to performance videos that are publicly available </li>
                  <li>Photographs which are listed as ‘press’ images should be added to the artist’s profile photos in the Operabase profile</li>
                </ul>
                <li>Social presence/digital links</li>
                <ul>
                  <li>Be 100% certain that these are correct and publicly visible - if there is even slight confusion, do not add </li>
                </ul>
                <li>In case of a merge, check for artist name’s translations </li>
                <li>Add basic profile image - must be in portrait format (not landscape) </li>
                <ul>
                  <li>Acceptable sources: Artist’s website, Website of reputable company artist works with, website of artist’s agency </li>
                </ul>
                <li>Add cover photo image - ONLY THE ARTIST, no group photos or casual photos - a production photo featuring the artist is ok </li>
                <ul>
                  <li>Source should be from Artist’s website or agency website</li>
                </ul>
                <li>SKIP Profession, citizenship, gender, birthday etc.</li>
                <li>
                  Add biography - this must be from a legitimate source, such as the artist’s website or an AOS website - if there are multiple versions of the biography in different languages on
                  these professional websites you can add them, DO NOT ADD GOOGLE TRANSLATED BIOGRAPHIES
                </li>
                <ul>
                  <li>This MUST be from the artist’s website or the agency website</li>
                </ul>
                <li>Add agency if shown on their website or an AOS website</li>
                <li>If the artist has a professional site, look for all performances listed there and</li>
                <ul>
                  <li>
                    add all missing productions that we do not already have - for example, cross check with the AOS / artist and ensure that we have everything in their schedule - make sure to add
                    these at Artist / AOS level. For adding at the artist level, you might be asked to add evidence. You can add a poster / link that you are referring to as evidence.
                  </li>
                  <li>If the AOS is not already on Operabase and you don’t think it should be added then you can add the production to the artist’s profile if you have sufficient evidence </li>
                  <li>
                    If you do not have evidence for the production being legitimate, then you can do a google search for the details of the production, if this is not showing in the first 10 search
                    results, then you need to email the artist and ask for evidence through the hubspot with the template for requesting artist production evidence
                  </li>
                  <li>If there is evidence for a church or chamber performance that is legitimate but which we would not add an AOS for, then please </li>
                </ul>
                <li>Reviews - only add a section of a positive review or mainly positive review which mentions the artist’s name </li>
              </ul>
            </ul>
            <p>
              IMAGES/VIDEOS ARE NOT TO BE PICKED UP FROM THE WEBSITE OF THE COMPANY OR THE COMPANY’S YOUTUBE CHANNEL - There should be no where in Operabase where there are videos and photographs that
              are not things which the artist agency or company have put forward in the way that they want to present the artist.
            </p>
            <p>Mark the task as completed. Add comments that will be critical to be reviewed during a QC.</p>
          </div>
        </AccordionDetails>
      </Accordion>
      {taskQueueData?.logs?.length ? (
        <Accordion
          onChange={(e, isExpanded) => {
            setLogsExpanded(isExpanded);
          }}
          expanded={isLogExpanded}
          className={classes.logsAccordion}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.bold}>
            Logs
          </AccordionSummary>
          <AccordionDetails className={classes.logsAccordionDetails}>
            {orderBy(taskQueueData?.logs, 'updatedAt', 'desc')?.map((log) => (
              <li key={log.id} className={classes.logContainer}>
                <Typography variant="caption" className={classes.spacingRight}>
                  Task updated by <EntityLink entity={log?.updatedBy} entityType="users" /> {log?.updatedAt}. Comments: {log?.comments}
                </Typography>
              </li>
            ))}
          </AccordionDetails>
        </Accordion>
      ) : null}
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          className: classes.dialog,
        }}
      >
        <DialogTitle>
          <div>
            {!taskQueueData?.isCompleted ? 'Mark as complete' : 'Reset task'}
            <Typography variant="subtitle2">End score {taskQueueData?.endScore}</Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          <MUITextField label="Comments*" variant="filled" color="primary" multiline fullWidth rows={4} value={comments} onChange={(e) => setComments(e.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleComplete} disabled={!comments}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const ArtistRenewalsQueueList = (props) => {
  const dataProvider = useDataProvider();
  const [allValidationLocks, setAllValidationLocks] = useState([]);
  const allData = useSelector((store) => store?.admin?.resources?.['task_queues/artistRenewals'])?.data;
  const allIds = useMemo(() => Object.keys(allData), [allData]);

  useEffect(() => {
    if (allIds.length) {
      dataProvider
        .getList('validations/locks', {
          filter: { lockIdentifier: allIds.join(','), lockGroup: 'task_queue', validation_status: '' },
          pagination: {},
          sort: {},
        })
        .then(({ data }) => {
          setAllValidationLocks(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allIds]);

  const showLockedUser = useCallback(
    (data) => {
      if (allValidationLocks.length) {
        const hasValidationLock = allValidationLocks.find((validationLock) => Number(validationLock.lockIdentifier) === Number(data.id));
        return `${hasValidationLock?.user?.email || 'Not locked'}`;
      }
      return 'Not locked';
    },
    [allValidationLocks],
  );

  return (
    <List
      {...props}
      title={<Breadcrumbs {...props} />}
      filters={<ArtistRenewalsQueueFilter />}
      filter={{ task_type: 'artist_renewals' }}
      filterDefaultValues={{ is_completed: false }}
      bulkActionButtons={false}
      sort={{ field: 'renew_at', order: 'ASC' }}
      exporter={false}
    >
      <Datagrid rowClick="show">
        <TextField source="id" />
        <FunctionField label="Profile" render={(record) => <EntityLink entity={record?.profile} entityType="profiles" />} sortable={false} />
        <FunctionField label="Locked By" render={(record) => showLockedUser(record)} sortable={false} />
        <FunctionField source="Status" render={(record) => (record?.isCompleted ? 'Done' : 'Pending')} sortable={false} />
        <FunctionField
          label="Renewal date"
          render={(record) => {
            const subscription = record?.profile?.subscription?.find((sub) => sub?.status === 'active');
            return subscription?.renewalDate ? dateParserUTC(subscription?.renewalDate, '', true) : '';
          }}
          sortBy="renew_at"
        />
        <CustomDateField label="Task added date" source="createdAt" timeHidden sortBy="created_at" />
        <CustomDateField label="Task updated date" source="updatedAt" timeHidden sortBy="updated_at" />
      </Datagrid>
    </List>
  );
};

export default ArtistRenewalsQueueList;
