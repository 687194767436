import React, { useRef, useState, useEffect } from 'react';

import {
  List,
  Filter,
  TextInput,
  Datagrid,
  TextField,
  ImageField,
  ArrayField,
  FunctionField,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  FormDataConsumer,
  useNotify,
  useRefresh,
  TabbedShowLayoutTabs,
  TabbedShowLayout,
  TopToolbar,
  EditButton,
  Tab,
  ReferenceManyField,
  DateField,
  Pagination,
  SimpleForm,
  useRedirect,
  Create,
  BooleanInput,
  Edit,
  DateInput,
  required,
  ImageInput,
  SimpleShowLayout,
} from 'react-admin';

import { Button, ButtonGroup, Chip, Typography, Divider } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { mapProps } from 'recompose';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { Suspension } from '../components/Suspension';
import { VALIDATION_STATUSES } from '../constants/validations';
import ValidityShow from '../components/ValidityShow';
import { PublicLink } from '../components/PublicLink';
import { DeleteWithConfirmButtonNullable } from '../components/DeleteWithConfirmButtonNullable';
import { ContactEditDialog } from '../components/contacts/ContactEditDialog';
import { AddressEditDialog } from '../components/addresses/AddressEditDialog';
import { MediaThumbnailField } from '../components/MediaThumbnailField';
import { getAccessStatus } from '../utils';
import { SubscriptionsTabContent } from '../components/SubscriptionsTabContent';
import { ProductionsGrid } from './ProductionsModel';
import WorkTypeForm from '../components/WorkTypeForm';
import Notifications from '../components/Notifications';
import CountryAndCityInput from '../inputs/CountryAndCityInput';
import { CreateActions } from '../components/DefaultActions';
import CustomShowLayout from '../components/CustomShowLayout';
import EntityLink from '../components/EntityLink';
import EntityTranslation from '../components/translation/EntityTranslation';

const SEASON_TYPES = [
  {
    id: 'calendar_year',
    name: 'Calendar year YYYY',
  },
  { id: 'school_year', name: 'School year YYYY / YYYY' },
];

const FieldUpdater = ({ formData }) => {
  const { change } = useForm();
  const users = useSelector((store) => store.admin.resources.users?.data);
  const [updatedUserId, setUpdatedUserId] = useState();

  useEffect(() => {
    if (formData?.user?.id !== updatedUserId) {
      const user = { ...users[formData?.user?.id] };
      change('firstName', user.firstName);
      change('lastName', user.lastName);
      change('accessEmail', user.email);
      setUpdatedUserId(user.id);
    }
  }, [change, formData, updatedUserId, users]);
  return null;
};

const useStyles = makeStyles(
  (theme) => ({
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      marginRight: 10,
    },
    organizationLogo: {
      '& img': {
        maxWidth: 30,
        maxHeight: 30,
      },
    },
    toolbar: {
      alignItems: 'center',
    },
    cityFilter: {
      '& #city_id-helper-text span': { display: 'none' },
    },
    email: { maxWidth: 100, wordBreak: 'break-word' },
    table: {
      '& .MuiTableCell-sizeSmall': { padding: 10 },
    },
    label: {
      color: 'rgba(0, 0, 0, 0.54)',
      marginBottom: 10,
      fontWeight: 'bold',
      fontSize: 16,
    },
    overflow: { overflowX: 'scroll' },
  }),
  { name: 'MakeLink' },
);

const OrganizationForm = () => {
  const form = useForm();

  useEffect(() => {
    if (!form.getFieldState('meta_season_type')?.value) {
      const metadata = form.getState()?.values?.metadata;

      if (metadata) {
        const seasonType = metadata.find((item) => item.name === 'season_type');
        const seasonAnnouncementDay = metadata.find((item) => item.name === 'season_announcement_day');
        const seasonAnnouncementMonth = metadata.find((item) => item.name === 'season_announcement_month');

        if (seasonType) {
          form.change('meta_season_type', seasonType.value);
        }

        if (seasonAnnouncementDay) {
          form.change('meta_season_announcement_day', seasonAnnouncementDay.value);
        }

        if (seasonAnnouncementMonth) {
          form.change('meta_season_announcement_month', seasonAnnouncementMonth.value);
        }
      }
    }
  }, [form]);

  return (
    <>
      <TextInput source="name" validate={required()} label="Name" />
      <ReferenceInput validate={required()} label="Organization type" source="organizationType.id" reference="organizations/types" filterToQuery={(searchText) => ({ query: searchText })}>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ImageInput source="logo" label="Logo image" accept="image/*">
        <ImageField source="medium" title="title" />
      </ImageInput>
      <CountryAndCityInput countryField="country" isCountryRequired cityField="city" />
      <SelectInput style={{ width: 235 }} source="meta_season_type" label="Season type" choices={SEASON_TYPES} />
      <SelectInput
        style={{ width: 235, display: 'flex' }}
        source="meta_season_announcement_day"
        label="Season announcement day"
        choices={Array.from({ length: 31 }, (_, index) => ({ id: index + 1, name: index + 1 }))}
      />
      <SelectInput
        style={{ width: 235, display: 'flex' }}
        source="meta_season_announcement_month"
        label="Season announcement month"
        choices={Array.from({ length: 12 }, (_, index) => ({ id: index + 1, name: index + 1 }))}
      />
    </>
  );
};

export const OrganizationEmployeeEdit = (props) => {
  const redirect = useRedirect();
  const employeeId = props?.id;
  const employeeData = useSelector((store) => store.admin.resources?.['organization/employees']?.data?.[employeeId]);

  const transform = (data) => ({
    ...data,
    workEmail: { email: data?.workEmail?.email, privacyAttributes: data?.workEmail?.privacyAttributes || [{ id: 2 }] },
    workPhones: data?.workPhones.map((pn) => ({
      phone: pn?.phone,
      privacyAttributes: pn?.privacyAttributes || [
        {
          id: 2,
        },
      ],
    })),
    ra_target: 'organization_id',
  });

  const onSuccess = () => {
    redirect(`/organizations/${employeeData?.organization?.id}/show/3`);
  };

  return (
    <Edit transform={transform} undoable={false} onSuccess={onSuccess} {...props}>
      <SimpleForm>
        <Typography variant="h6">Edit organization team member</Typography>
        <Divider />
        <TextInput source="role" label="Employee role" />
        <TextInput source="workEmail.email" label="Work email" />
        <TextInput source="workPhones[0].phone" label="Work phone number" />
        <ReferenceInput label="User" source="profile.user.id" reference="users" filterToQuery={(searchText) => ({ query: searchText })}>
          <AutocompleteInput optionText={(user) => `(${user.id}) - ${user.email} - ${user.name}`} />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData }) =>
            formData?.user?.id && (
              <ReferenceInput
                label="Profiles"
                source="profile.id"
                reference="profiles"
                filter={{ access_user_id: formData?.user?.id }}
                filterToQuery={(searchText) => ({ query: searchText, profile_type_id: 3 })}
              >
                <AutocompleteInput optionText={(record) => record?.name || `(empty, #ID: ${record?.id || ''})`} />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>{({ formData }) => <FieldUpdater formData={formData} />}</FormDataConsumer>
        <TextInput source="firstName" label="First name" />
        <TextInput source="lastName" label="Last name" />
        <ReferenceInput
          label="Organization"
          filter={{ limit: 25 }}
          source="organization.id"
          reference="organizations"
          shouldRenderSuggestions={false}
          disabled={!!props.location?.state?.record?.organizations}
          filterToQuery={(searchText) => ({ query: searchText })}
        >
          {props.location?.state?.record?.organization ? <SelectInput optionText="name" /> : <AutocompleteInput optionText="name" />}
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

const OrganizationEmployeeCreateForm = mapProps(({ save, ...other }) => ({
  ...other,
  save: (record) => {
    save({
      ...record,
      has_access: 1,
      ra_target: 'organization_id',
      workEmail: record.work_email && { email: record.work_email, privacyAttributes: [{ id: 1 }] },
      workPhones:
        record.work_phone_number &&
        record.work_phone_number.split(', ').map((pn) => ({
          phone: pn,
          privacyAttributes: [
            {
              id: 1,
            },
          ],
        })),
    });
  },
}))(SimpleForm);

const OrganizationsShowActions = (props) => {
  const classes = useStyles();
  const { basePath, data } = props;

  return (
    <div>
      <Suspension suspension={data?.suspension} type="organizations" />
      <TopToolbar className={classes.toolbar}>
        <PublicLink {...props} />
        <PublicLink edit {...props} />
        <EditButton basePath={basePath} record={data} />
      </TopToolbar>
    </div>
  );
};

export const OrganizationEmployeeCreate = (props) => {
  const redirect = useRedirect();

  const onSuccess = ({ data }) => {
    redirect(`/organizations/${data.organization.id}/show/3`);
  };

  return (
    <Create {...props} onSuccess={onSuccess}>
      <OrganizationEmployeeCreateForm>
        <Typography variant="h6">Add organization team member</Typography>
        <Divider />
        <ReferenceInput label="Profession" source="profession.id" reference="professions" filterToQuery={(searchText) => ({ query: searchText })}>
          <AutocompleteInput optionText={(profession) => `(${profession.id}) - ${profession.name} - ${profession.applicableFor}`} />
        </ReferenceInput>
        <TextInput source="workEmail.email" label="Work email" />
        <TextInput source="workPhones[0].phone" label="Work phone number" />
        <ReferenceInput label="User" source="user.id" reference="users" filterToQuery={(searchText) => ({ query: searchText })}>
          <AutocompleteInput optionText={(user) => `(${user.id}) - ${user.email} - ${user.name}`} />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData }) =>
            formData?.user?.id && (
              <ReferenceInput
                label="Profiles"
                source="profile.id"
                reference="profiles"
                filter={{ access_user_id: formData?.user?.id }}
                filterToQuery={(searchText) => ({ query: searchText, profile_type_id: 3 })}
              >
                <AutocompleteInput optionText={(record) => record?.name || `(empty, #ID: ${record?.id || ''})`} />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>{({ formData }) => <FieldUpdater formData={formData} />}</FormDataConsumer>
        <TextInput source="firstName" label="First name" />
        <TextInput source="lastName" label="Last name" />
        <TextInput source="accessEmail" label="Access email" />
        <DateInput source="associationStartDate" allowEmpty />
        <DateInput source="associationEndDate" allowEmpty />
        <ReferenceInput
          label="Organization"
          filter={{ limit: 25 }}
          source="organization.id"
          reference="organizations"
          shouldRenderSuggestions={false}
          disabled={!!props.location?.state?.record?.organization}
          filterToQuery={(searchText) => ({ query: searchText })}
        >
          {props.location?.state?.record?.organization ? <SelectInput optionText="name" /> : <AutocompleteInput optionText="name" />}
        </ReferenceInput>
        <BooleanInput source="sendMail" label="Send email to user" defaultValue={false} />
      </OrganizationEmployeeCreateForm>
    </Create>
  );
};

export const OrganizationEmployeeCreateButton = ({ record, user }) => (
  <Button
    component={Link}
    to={{
      pathname: '/organizations/employees/create',
      state: {
        record: { ...(record && { organization: { id: record.id } }), ...(user && { user: { id: user.id } }) },
      },
    }}
  >
    Add contact with user
  </Button>
);

const OrganizationsFilters = (props) => {
  const classes = useStyles();
  return (
    <Filter {...props}>
      <TextInput label="Organization ID" source="id" alwaysOn />
      <TextInput label="Search" source="query" alwaysOn />
      <SelectInput label="Validation status" source="validation_status" choices={VALIDATION_STATUSES} alwaysOn />
      <ReferenceInput
        label="Organization Type"
        source="organization_type_id"
        reference="organizations/types"
        filterToQuery={(searchText) => ({ query: searchText })}
        sort={{ field: 'name', order: 'ASC' }}
        alwaysOn
        allowEmpty
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput label="Country" source="country_id" reference="countries" filterToQuery={(searchText) => ({ query: searchText })} sort={{ field: 'name', order: 'ASC' }} alwaysOn allowEmpty>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <div className={classes.cityFilter} alwaysOn>
        <FormDataConsumer source="country_id">
          {({ formData }) => (
            <ReferenceInput
              label="City"
              source="city_id"
              reference="countries/cities"
              filter={{ country_id: formData?.country_id }}
              filterToQuery={(searchText) => ({ query: searchText })}
              sort={{ field: 'name', order: 'ASC' }}
              alwaysOn
              allowEmpty
            >
              <AutocompleteInput
                optionText={(record) => {
                  let name = '';
                  name = record?.name;

                  if (record?.areas?.length) {
                    name = `${name} (${record?.areas?.[0]?.name})`;
                  }

                  if (record?.country?.name) {
                    name = `${name} (${record?.country?.name})`;
                  }

                  return name;
                }}
                matchSuggestion={() => true}
              />
            </ReferenceInput>
          )}
        </FormDataConsumer>
      </div>
      <BooleanInput label="Is Pro" source="is_pro" alwaysOn />
    </Filter>
  );
};

export const OrganizationsShow = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();

  const ref = useRef();
  const [contactEditDialogOpen, setContactEditDialogOpen] = useState(false);
  const [addressEditDialogOpen, setAddressEditDialogOpen] = useState(false);
  const [workTypeDialogOpen, setWorkTypeDialogOpen] = useState(false);

  return (
    <ValidityShow {...props} title={<Breadcrumbs {...props} />} actions={<OrganizationsShowActions />}>
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" {...props} />} className={classes.overflow}>
        <Tab label="Organization Info">
          <CustomShowLayout {...props}>
            {{
              column1: (
                <SimpleShowLayout>
                  <TextField source="name" />
                  <TextField source="organizationType.name" label="Type" />
                  <ImageField label="Logo" source="logo.medium" title="title" />
                  <TextField source="country.name" label="Country" emptyText="Empty" />
                  <TextField source="city.name" label="City" emptyText="Empty" />
                  <TextField source="description" emptyText="Empty" />
                </SimpleShowLayout>
              ),
            }}
          </CustomShowLayout>
        </Tab>
        <Tab label="Organization Translations">
          <EntityTranslation list={{ url: `organizations/${props?.id}/translations` }} update={{ url: `organizations/${props?.id}/translations` }} isUpdate />
        </Tab>
        <Tab label="contacts">
          <Button
            onClick={() => {
              ref.current = { contact: null };
              setContactEditDialogOpen(true);
            }}
          >
            Create contact
          </Button>
          <ReferenceManyField
            sort={{ field: 'createdAt', order: 'DESC' }}
            label="Organization contacts"
            reference="organizations/contacts"
            target="organization_id"
            perPage={10}
            pagination={<Pagination />}
            filter={{ show_hidden: true, category: ['organization', 'box_office', 'employee'] }}
          >
            <Datagrid rowClick={false}>
              <TextField source="id" sortable={false} />
              <TextField source="category" label="Category" sortable={false} />
              <TextField source="contactType.name" label="Type" sortable={false} />
              <TextField source="name" label="Name" emptyText="N/A" sortable={false} />
              <TextField source="value" label="Value" sortable={false} />
              <FunctionField label="Privacy attributes" render={(record) => (record?.privacyAttributes ? record?.privacyAttributes.map((item) => <Chip label={item?.slug} />) : null)} />
              <DateField source="created_at" showTime sortable={false} />
              <DateField source="updated_at" showTime sortable={false} />
              <FunctionField
                label="Actions"
                render={(record) => (
                  <ButtonGroup>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={() => {
                        ref.current = { contact: record };
                        setContactEditDialogOpen(true);
                      }}
                    >
                      Edit
                    </Button>
                  </ButtonGroup>
                )}
              />
              <DeleteWithConfirmButtonNullable redirect={false} />
            </Datagrid>
          </ReferenceManyField>
          <ContactEditDialog
            open={contactEditDialogOpen}
            contact={ref.current?.contact}
            service={`organizations/${props.id}/contacts`}
            onSuccess={() => {
              notify('Contact updated', 'info');
              setContactEditDialogOpen(false);
              refresh();
            }}
            onCancel={() => {
              setContactEditDialogOpen(false);
            }}
            onFailure={(err) => {
              notify(err.response?.data?.message?.message || err.message, 'error');
              setContactEditDialogOpen(false);
            }}
          />
        </Tab>

        <Tab label="addresses">
          <Button
            onClick={() => {
              ref.current = { address: null };
              setAddressEditDialogOpen(true);
            }}
          >
            Create Address
          </Button>
          <ReferenceManyField
            sort={{ field: 'createdAt', order: 'DESC' }}
            label="Organization addresses"
            reference="organizations/addresses"
            target="organization_id"
            perPage={10}
            pagination={<Pagination />}
            filter={{ show_hidden: true }}
          >
            <Datagrid rowClick={false}>
              <TextField source="id" sortable={false} />
              <TextField source="address" label="Address" emptyText="N/A" sortable={false} />
              <TextField source="isPrimary" label="Is Primary" sortable={false} />
              <TextField source="lat" sortable={false} />
              <TextField source="long" sortable={false} />
              <TextField source="placeId" label="Place Id" sortable={false} />
              <FunctionField
                label="Privacy attributes"
                render={(record) => (record?.privacyAttributes?.length ? record?.privacyAttributes?.map((privacyAttribute) => <Chip label={privacyAttribute?.slug} />) : null)}
              />
              <DateField source="createdAt" showTime sortable={false} />
              <DateField source="updatedAt" showTime sortable={false} />
              <FunctionField
                label="Actions"
                render={(record) => (
                  <ButtonGroup>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={() => {
                        ref.current = { address: record };
                        setAddressEditDialogOpen(true);
                      }}
                    >
                      Edit
                    </Button>
                  </ButtonGroup>
                )}
              />
              <DeleteWithConfirmButtonNullable redirect={false} />
            </Datagrid>
          </ReferenceManyField>
          <AddressEditDialog
            open={addressEditDialogOpen}
            address={ref.current?.address}
            service={`organizations/${props.id}/addresses`}
            onSuccess={() => {
              notify('Address updated', 'info');
              setAddressEditDialogOpen(false);
              refresh();
            }}
            onCancel={() => {
              setAddressEditDialogOpen(false);
            }}
            onFailure={(err) => {
              notify(err.response?.data?.message?.message || err.message, 'error');
              setAddressEditDialogOpen(false);
            }}
          />
        </Tab>

        <Tab label="media">
          <ReferenceManyField filter={{ media_tag_type_id: 12 }} sort={{ field: 'createdAt', order: 'DESC' }} label="Media Records" reference="media" target="media_tag_id">
            <Datagrid rowClick="show">
              <TextField source="id" sortable={false} />
              <TextField source="mediaType.name" label="Type" sortable={false} />
              <MediaThumbnailField label="Image" />
              <TextField source="name" label="Title" sortable={false} />
              <TextField source="description" label="Description" sortable={false} />
              <DateField source="createdAt" showTime />
              <TextField source="status.name" label="Status Name" sortable={false} />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="management & staff">
          <OrganizationEmployeeCreateButton />

          <ReferenceManyField label="Employees" reference="organizations/employees" target="organization_id" pagination={<Pagination />} filter={{ validity: 0, validation_status: [] }}>
            <Datagrid className={classes.table}>
              <TextField source="id" sortBy="id" />

              <FunctionField
                label="User / Profile"
                render={({ profile }) => (
                  <>
                    <EntityLink entity={profile?.user} entityType="users" />
                    <EntityLink entity={profile} entityType="profiles" />
                  </>
                )}
              />

              <FunctionField
                label="Contact name"
                render={(contact) => (
                  <div>
                    {contact?.firstName} {contact?.lastName}
                  </div>
                )}
              />

              <TextField className={classes.email} label="Access email" source="accessEmail" />

              <FunctionField className={classes.contactDetails} label="Page access status" sortBy="has_access" render={(employee) => getAccessStatus(employee)} />

              <FunctionField
                label="Contact Profession"
                render={(employee) => {
                  return <EntityLink entity={employee?.profession} entityType="professions" />;
                }}
              />

              <FunctionField
                className={classes.contactDetails}
                label="Contact details"
                render={(employee) =>
                  `${employee?.workEmail?.email || '[No contact email]'} | 
                   ${employee.workPhones?.length > 0 ? employee.workPhones.map((item) => item?.phone).join(' | ') : '[No contact phones]'}`
                }
              />

              <EditButton />

              <DeleteWithConfirmButtonNullable label="Remove from contacts" redirect={`/organizations/${props.id}/show/1`} />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Organization Subscriptions">
          <ReferenceManyField reference="users/subscriptions" target="organization_id" perPage={10} pagination={<Pagination />}>
            <SubscriptionsTabContent />
          </ReferenceManyField>
        </Tab>

        <Tab label="Performances">
          <div className={classes.label}>Published</div>
          <ReferenceManyField
            source="id"
            reference="productions"
            link="show"
            target="organization_id"
            perPage={10}
            pagination={<Pagination />}
            className={classes.tabOverview}
            filter={{ as_edit: true }}
            sort={{ field: 'year', order: 'DESC' }}
          >
            <ProductionsGrid {...props} hideMask />
          </ReferenceManyField>
          <div className={classes.label} style={{ marginTop: 30 }}>
            Drafts
          </div>
          <ReferenceManyField
            source="id"
            reference="productions"
            link="show"
            target="organization_id"
            perPage={10}
            pagination={<Pagination />}
            className={classes.tabOverview}
            filter={{ as_edit: true, published: false }}
            sort={{ field: 'year', order: 'DESC' }}
          >
            <ProductionsGrid {...props} hideMask />
          </ReferenceManyField>
        </Tab>

        <Tab label="Work types">
          <Button
            onClick={() => {
              ref.current = { workType: null };
              setWorkTypeDialogOpen(true);
            }}
          >
            Add work type
          </Button>
          <ArrayField source="workTypes" label="Works types" sortable={false} {...props}>
            <Datagrid rowClick={(path, resource, record) => `/workTypes/${record?.id}/show`}>
              <TextField source="name" sortable={false} />
              <TextField source="isPrimary" sortable={false} />
              <TextField source="validationStatus.name" label="Validation Status" sortable={false} />
              <FunctionField
                label="Action"
                render={(record) => (
                  <ButtonGroup>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        ref.current = { workType: record };
                        setWorkTypeDialogOpen(true);
                      }}
                    >
                      Edit
                    </Button>
                  </ButtonGroup>
                )}
              />
            </Datagrid>
          </ArrayField>
          <WorkTypeForm forEntity={7} entityId={props?.id} open={workTypeDialogOpen} onClose={() => setWorkTypeDialogOpen(false)} formData={ref.current?.workType} />
        </Tab>

        <Tab label="Notifications">
          <Notifications entityType="organizations" />
        </Tab>
      </TabbedShowLayout>
    </ValidityShow>
  );
};

export const OrganizationsList = (props) => {
  const classes = useStyles();
  return (
    <List exporter={false} title={<Breadcrumbs {...props} />} filters={<OrganizationsFilters />} sort={{ field: 'production_count', order: 'DESC' }} bulkActionButtons={false} {...props}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <ImageField source="logo.small" label="Logo" className={classes.organizationLogo} />
        <TextField source="name" label="Organization Name" sortable={false} />
        <TextField source="organizationType.name" label="Type" />
        <TextField source="country.name" label="Country" />
        <TextField source="city.name" label="City" sortable={false} />
        <TextField source="stats.productionCount" label="Production count" sortBy="production_count" />
        <TextField label="Page visits" source="stats.pageViews" sortBy="page_visits" sortable={false} />
        <TextField label="Validation status" source="validationStatus.name" sortable={false} />
        <FunctionField label="Suspension" render={(record) => <Suspension suspension={record.suspension} type="organizations" list />} />
        <TextField source="createdAt" sortBy="created_at" />
      </Datagrid>
    </List>
  );
};

const transformOrgForm = (data) => {
  const metadata = [
    ...(data?.meta_season_type ? [{ name: 'season_type', value: data?.meta_season_type }] : []),
    ...(data?.meta_season_announcement_day ? [{ name: 'season_announcement_day', value: data?.meta_season_announcement_day }] : []),
    ...(data?.meta_season_announcement_month ? [{ name: 'season_announcement_month', value: data?.meta_season_announcement_month }] : []),
  ];

  return {
    ...data,
    metadata,
  };
};

export const OrganizationCreate = (props) => {
  const transform = (data) => transformOrgForm(data);

  return (
    <Create {...props} transform={transform} title="Create Organization" actions={<CreateActions />}>
      <SimpleForm submitOnEnter={false}>
        <OrganizationForm />
      </SimpleForm>
    </Create>
  );
};

export const OrganizationEdit = (props) => {
  const transform = (data) => transformOrgForm(data);

  return (
    <Edit {...props} title={<Breadcrumbs {...props} />} transform={transform}>
      <SimpleForm submitOnEnter={false}>
        <OrganizationForm />
      </SimpleForm>
    </Edit>
  );
};

export default OrganizationsList;
