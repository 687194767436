import { isNaN, reduce, mergeWith, add, map, orderBy } from 'lodash';
import * as dayjs from 'dayjs';
import { REGISTRATION_PROFILE_TYPES, REGISTRATION_COHORT_TYPES } from '../constants/registration';

const weekOfYear = require('dayjs/plugin/weekOfYear');
dayjs.extend(weekOfYear);

const addProperty = (a, b) => {
  const result = mergeWith(a, b, add);

  if (isNaN(result)) {
    return;
  }

  // eslint-disable-next-line consistent-return
  return result;
};

const calculateCumulativeSum = (data) => {
  const cumulativeData = [];

  data.forEach((item, index) => {
    const cumulativeItem = { name: item.name, value: {} };

    Object.keys(item).forEach((key) => {
      // eslint-disable-next-line no-prototype-builtins
      if (item.hasOwnProperty(key) && typeof item[key] === 'object') {
        cumulativeItem[key] = {};
        Object.keys(item[key]).forEach((subKey) => {
          // eslint-disable-next-line no-prototype-builtins
          if (item[key].hasOwnProperty(subKey) && typeof item[key][subKey] === 'number') {
            const cumulativeValue = index > 0 ? cumulativeData[index - 1][key][subKey] + item[key][subKey] : item[key][subKey];
            cumulativeItem[key][subKey] = cumulativeValue;
          }
        });
      }
    });

    cumulativeData.push(cumulativeItem);
  });

  return cumulativeData;
};

export const getChartData = ({ data, frequency, isCumulative }) => {
  let chartData = [];

  if (frequency === 'daily') {
    chartData = orderBy(
      map(data, (value, key) => ({ name: key, ...value })),
      'name',
      'asc',
    );
  }

  if (frequency === 'monthly') {
    const groupData = {};
    const sortedData = orderBy(
      map(data, (value, key) => ({ name: key, value })),
      'name',
      'asc',
    );
    const dates = map(sortedData, 'name');
    const values = map(sortedData, 'value');

    dates.forEach((d, i) => {
      const v = values[i];
      const monthYear = d.slice(0, 7);
      // eslint-disable-next-line no-prototype-builtins
      if (groupData.hasOwnProperty(monthYear)) {
        groupData[monthYear].push(v);
      } else {
        groupData[monthYear] = [v];
      }
    });

    const monthDates = Object.keys(groupData);
    const monthValues = Object.values(groupData);
    const result = map(monthValues, (v) => reduce(v, (prev, curr) => mergeWith(prev, curr, addProperty)));

    chartData = monthDates.map((d, i) => ({ name: d, ...result[i] }));
  }

  if (frequency === 'weekly') {
    const groupData = {};
    const sortedData = orderBy(
      map(data, (value, key) => ({ name: key, value })),
      'name',
      'asc',
    );
    const dates = map(sortedData, 'name');
    const values = map(sortedData, 'value');

    dates.forEach((d, i) => {
      const v = values[i];
      const weekYear = `${d.slice(0, 4)}-${dayjs(d).week()}`;
      // eslint-disable-next-line no-prototype-builtins
      if (groupData.hasOwnProperty(weekYear)) {
        groupData[weekYear].push(v);
      } else {
        groupData[weekYear] = [v];
      }
    });

    const weekDates = Object.keys(groupData);
    const weekValues = Object.values(groupData);
    const result = map(weekValues, (v) => reduce(v, (prev, curr) => mergeWith(prev, curr, addProperty)));

    chartData = weekDates.map((d, i) => ({ name: d, ...result[i] }));
  }

  if (isCumulative) return calculateCumulativeSum(chartData);

  return chartData;
};

export const getUserMetrics = () => {
  const registrationMetrics = [
    {
      title: 'Total Registrations',
      dataKeys: [{ key: 'metrics.new_registrations', name: 'Total users in registration process' }],
    },
    {
      title: 'New registrations',
      dataKeys: [{ key: 'metrics.total_new_registration_records', name: 'New registration' }],
    },
    {
      title: 'New registrations with cohort',
      dataKeys: [
        { key: 'new_registration_cohort.new_audience', name: 'New Audience' },
        { key: 'new_registration_cohort.new_industry_professional', name: 'New Industry professional' },
        { key: 'new_registration_cohort.new_journalist', name: 'New Journalist' },
        { key: 'new_registration_cohort.new_academia', name: 'New Academia' },
        { key: 'new_registration_cohort.new_no_cohort', name: 'New No cohort' },
      ],
    },
    {
      title: 'New registrations with profession',
      dataKeys: [
        { key: 'new_registration_profession.new_on_stage_artist', name: 'New On stage artist' },
        { key: 'new_registration_profession.new_off_stage_artist', name: 'New Off stage artist' },
        { key: 'new_registration_profession.new_instrumentalist', name: 'New Instrumentalist' },
        { key: 'new_registration_profession.new_aos', name: 'New AOS' },
        { key: 'new_registration_profession.new_agency', name: 'New Agency' },
      ],
    },
    {
      title: 'Total Users in registrations with cohort',
      dataKeys: [
        { key: 'registration_cohort.audience', name: 'Audience' },
        { key: 'registration_cohort.industry_professional', name: 'Industry professional' },
        { key: 'registration_cohort.journalist', name: 'Journalist' },
        { key: 'registration_cohort.academia', name: 'Academia' },
        { key: 'registration_cohort.no_cohort', name: 'No cohort' },
      ],
    },
    {
      title: 'Total Users in registrations with profession',
      dataKeys: [
        { key: 'registration_profession.on_stage_artist', name: 'On stage artist' },
        { key: 'registration_profession.off_stage_artist', name: 'Off stage artist' },
        { key: 'registration_profession.instrumentalist', name: 'Instrumentalist' },
        { key: 'registration_profession.aos', name: 'AOS' },
        { key: 'registration_profession.agency', name: 'Agency' },
      ],
    },
    {
      title: 'Registration by location',
      composeChart: true,
      dynamicKeys: true,
      keyPrefix: 'registration_location',
      lines: [
        { key: 'registration_location.Sample', name: 'Sample' },
      ],
    },
    {
      title: 'New registrations with steps',
      composeChart: true,
      lines: [
        // { key: 'registration_steps.1', name: 'New registration' },
        { key: 'registration_steps.1_3', name: 'Create new profile' },
        { key: 'registration_steps.2', name: 'Cohort form' },
        { key: 'registration_steps.3', name: 'Create profile' },
        { key: 'registration_steps.3_2', name: 'Profile match' },
        { key: 'registration_steps.3_3', name: 'Digital presence' },
        { key: 'registration_steps.3_4', name: 'User production' },
        { key: 'registration_steps.4', name: 'Employment type' },
        { key: 'registration_steps.5', name: 'Organization' },
        // { key: 'registration_steps.6_1', name: 'Organization performance' },
        // { key: 'registration_steps.6_2', name: 'Organization work type' },
        // { key: 'registration_steps.6_3', name: 'Organization role' },
        // { key: 'registration_steps.6_4', name: 'Organization preference' },
        { key: 'registration_steps.8', name: 'Subscription' },
        { key: 'registration_steps.9', name: 'Congratulation' },
        // { key: 'registration_steps.10', name: 'Finished' },
      ],
    },
  ];

  const subscriptionMetrics = [
    {
      title: 'New users in trial',
      dataKeys: [{ key: 'metrics.new_trial', name: 'In trial' }],
    },
    {
      title: 'New users in trial with cohort',
      dataKeys: [
        { key: 'new_users_in_trial.artist', name: 'Artist' },
        { key: 'new_users_in_trial.aos', name: 'AOS' },
        { key: 'new_users_in_trial.agency', name: 'Agency' },
        { key: 'new_users_in_trial.other', name: 'Other' },
      ],
    },
    {
      title: 'New users paid',
      dataKeys: [{ key: 'metrics.new_paid', name: 'Paid' }],
    },
    {
      title: 'New users paid with cohort',
      dataKeys: [
        { key: 'new_users_in_active.artist', name: 'Artist' },
        { key: 'new_users_in_active.aos', name: 'AOS' },
        { key: 'new_users_in_active.agency', name: 'Agency' },
        { key: 'new_users_in_active.other', name: 'Other' },
      ],
    },
    {
      title: 'Cancelled subscriptions',
      dataKeys: [{ key: 'metrics.cancelled', name: 'Cancelled' }],
    },
    {
      title: 'Cancelled subscriptions with cohort',
      dataKeys: [
        { key: 'cancelled_subscriptions.artist', name: 'Artist' },
        { key: 'cancelled_subscriptions.aos', name: 'AOS' },
        { key: 'cancelled_subscriptions.agency', name: 'Agency' },
        { key: 'cancelled_subscriptions.other', name: 'Other' },
      ],
    },
    {
      title: 'Cancelled subscriptions with source',
      dataKeys: [
        { key: 'cancelled_subscription_source.registration', name: 'Registration' },
        { key: 'cancelled_subscription_source.admin', name: 'Admin' },
        { key: 'cancelled_subscription_source.customer_support', name: 'Customer support' },
        { key: 'cancelled_subscription_source.manual', name: 'Manual Subscription' },
        { key: 'cancelled_subscription_source.offer', name: 'Offer' },
        { key: 'cancelled_subscription_source.referral', name: 'Referral' },
      ],
    },
    {
      title: 'Artist trial expiry',
      dataKeys: [{ key: 'metrics.trial_expired', name: 'Trial expiry' }],
    },
    {
      title: 'Artist trial expiry with source',
      dataKeys: [
        { key: 'artist_trial_expiry.registration', name: 'Registration' },
        { key: 'artist_trial_expiry.admin', name: 'Admin' },
        { key: 'artist_trial_expiry.customer', name: 'Customer' },
        { key: 'artist_trial_expiry.manual', name: 'Manual Subscription' },
        { key: 'artist_trial_expiry.referral', name: 'Referral' },
        { key: 'artist_trial_expiry.other', name: 'Other' },
      ],
    },
  ];

  const artistFunnelMetrics = [
    {
      title: 'Artist funnel',
      composeChart: true,
      lines: [
        { key: 'artist_metrics.registrations', name: 'Registrations' },
        { key: 'artist_metrics.new_freemium', name: 'New Freemium' },
        { key: 'artist_metrics.new_in_trial', name: 'New In Trial' },
        { key: 'artist_metrics.verification_accepted', name: 'Verification Accepted' },
        { key: 'artist_metrics.artist_freemium_registration', name: 'Freemium from registretaion' },
        { key: 'artist_metrics.artist_freemium_agency_roster', name: 'Freemium from agency roaster' },
      ],
    },
    {
      title: 'Trial to paid conversion',
      composeChart: true,
      lines: [
        { key: 'artist_conversion.first_activation', name: 'First activation' },
        { key: 'artist_metrics_30_days.in_trial_30_days', name: 'New trials' },
      ],
    },
    {
      title: 'Freemium to premium',
      composeChart: true,
      lines: [{ key: 'artist_metrics.freemium_to_premium', name: 'Freemium to premium' }],
    },
    {
      title: 'In Trial 30 days',
      dataKeys: [{ key: 'artist_cumulative.in_trial', name: 'In Trial 30 days' }],
      composeChart: true,
      lines: [{ key: 'artist_cumulative.active', name: 'Active' }],
      isMultiAxis: true,
    },
    {
      title: 'Reactivation',
      dataKeys: [{ key: 'artist_conversion.reactivation', name: 'Reactivation' }],
    },
    {
      title: 'Cancellation',
      dataKeys: [{ key: 'artist_churn.cancellation', name: 'Cancellation' }],
    },
    {
      title: 'Trial expiry',
      dataKeys: [{ key: 'artist_churn.trial_expiry', name: 'Trial expiry' }],
    },
    {
      title: 'Artist Access Cumulative',
      composeChart: true,
      dataKeys: [{ key: 'artist_access_metrics.total_artist_access_cumulative', name: 'Artist Access' }],
    },
    {
      title: 'Artist Access Daily',
      composeChart: true,
      dataKeys: [{ key: 'artist_access_metrics.total_artist_access_daily', name: 'Artist Access Daily' }],
    },
  ];

  const artistMetrics = [
    {
      title: 'New registrations',
      dataKeys: [{ key: 'only_artist.new_registrations', name: 'New registrations' }],
    },
    {
      title: 'New trials subscriptions',
      dataKeys: [{ key: 'only_artist.new_trial', name: 'New trials' }],
    },
    {
      title: 'New paid subscriptions',
      dataKeys: [{ key: 'new_users_in_active.artist', name: 'New paid' }],
    },
    {
      title: 'Cancelled subscriptions',
      dataKeys: [{ key: 'only_artist.cancelled', name: 'Cancelled' }],
    },
    {
      title: 'Registration to Freemium',
      dataKeys: [{ key: 'only_artist.registration_to_freemium', name: 'Registration to Freemium' }],
    },
    {
      title: 'Registration to Premium',
      dataKeys: [{ key: 'only_artist.registration_to_premium', name: 'Registration to Premium' }],
    },
    {
      title: 'Freemium to Premium',
      dataKeys: [{ key: 'only_artist.freemium_to_premium', name: 'Freemium to Premium' }],
    },
    {
      title: 'Trial to Paid',
      dataKeys: [{ key: 'only_artist.trial_to_paid', name: 'Trial to Paid' }],
    },
    {
      title: 'Verification Done',
      dataKeys: [{ key: 'only_artist.verification_done', name: 'Verification Done' }],
    },
    {
      title: 'Verification Failed',
      dataKeys: [{ key: 'only_artist.verification_failed', name: 'Verification Failed' }],
    },
    {
      title: 'Verification Pending',
      dataKeys: [{ key: 'only_artist.verification_pending', name: 'Verification Pending' }],
    },
    {
      title: 'In trial subscription with source',
      dataKeys: [
        { key: 'only_artist.artist_intrial_reg_metrics', name: 'Registration' },
        { key: 'only_artist.artist_intrial_man_metrics', name: 'Manual' },
        { key: 'only_artist.artist_intrial_admin_metrics', name: 'Admin' },
        { key: 'only_artist.artist_intrial_offer_metrics', name: 'Offer' },
        { key: 'only_artist.artist_intrial_ref_metrics', name: 'Referral' },
        { key: 'only_artist.artist_intrial_cus_metrics', name: 'Customer' },
        { key: 'only_artist.artist_intrial_other_metrics', name: 'Other' },
      ],
    },
    {
      title: 'Paid subscription with source',
      dataKeys: [
        { key: 'only_artist.artist_paid_reg_metrics', name: 'Registration' },
        { key: 'only_artist.artist_paid_man_metrics', name: 'Manual' },
        { key: 'only_artist.artist_paid_admin_metrics', name: 'Admin' },
        { key: 'only_artist.artist_paid_offer_metrics', name: 'Offer' },
        { key: 'only_artist.artist_paid_ref_metrics', name: 'Referral' },
        { key: 'only_artist.artist_paid_cus_metrics', name: 'Customer' },
        { key: 'only_artist.artist_paid_other_metrics', name: 'Other' },
      ],
    },
  ];

  const aosFunnelMetrics = [
    {
      title: 'AOS funnel',
      composeChart: true,
      lines: [
        { key: 'aos_metrics.registrations', name: 'Registrations' },
        { key: 'aos_metrics.new_subscriptions', name: 'New subscriptions' },
        { key: 'aos_metrics.in_trial_subscriptions', name: 'New In Trial' },
      ],
    },
    {
      title: 'Total employee having access',
      composeChart: true,
      lines: [{ key: 'aos_metrics.total_employees_having_access', name: 'Total employee having access' }],
    },
  ];

  const agencyFunnelMetrics = [
    {
      title: 'Agency funnel',
      composeChart: true,
      lines: [
        { key: 'agency_metrics.registrations', name: 'Registrations' },
        { key: 'agency_metrics.new_subscriptions', name: 'New subscriptions' },
        { key: 'agency_metrics.in_trial_subscriptions', name: 'New In Trial' },
      ],
    },
    {
      title: 'Total agent having access',
      composeChart: true,
      lines: [{ key: 'agency_metrics.total_agents_having_access', name: 'Total agents having access' }],
    },
  ];

  const academiaFunnelMetrics = [
    {
      title: 'Academia, Journalist and Casting funnel',
      composeChart: true,
      lines: [
        { key: 'academia_journalist_casting_metrics.registrations', name: 'Registrations' },
        { key: 'academia_journalist_casting_metrics.new_subscriptions', name: 'New subscriptions' },
        { key: 'academia_journalist_casting_metrics.in_trial_subscriptions', name: 'New In Trial' },
      ],
    },
  ];

  return {
    registrationMetrics,
    subscriptionMetrics,
    artistMetrics,
    artistFunnelMetrics,
    aosFunnelMetrics,
    agencyFunnelMetrics,
    academiaFunnelMetrics
  };
};

export const getChartConfig = (chartData) => {
  let filter = {};
  let resource = '';
  let hideDialog = true;
  let dataFields = [];
  const activePayload = chartData?.activePayload;
  const dataKey = activePayload?.[0]?.dataKey;
  const title = `${chartData?.title} - ${chartData?.activeLabel}`;

  const registrationsKeys = ['metrics.new_registrations', 'registrations.artist', 'registrations.aos', 'registrations.agency', 'registrations.other', 'registrations.no_cohort'];
  const trialKeys = ['metrics.new_trial', 'new_users_in_trial.artist', 'new_users_in_trial.aos', 'new_users_in_trial.agency', 'new_users_in_trial.other'];
  const paidKeys = ['metrics.new_paid', 'new_users_in_active.artist', 'new_users_in_active.aos', 'new_users_in_active.agency', 'new_users_in_active.other'];
  const cancelledKeys = ['metrics.cancelled', 'cancelled_subscriptions.artist', 'cancelled_subscriptions.aos', 'cancelled_subscriptions.agency', 'cancelled_subscriptions.other'];
  const cancelledWithSourceKeys = [
    'cancelled_subscription_source.registration',
    'cancelled_subscription_source.admin',
    'cancelled_subscription_source.customer_support',
    'cancelled_subscription_source.manual',
    'cancelled_subscription_source.offer',
    'cancelled_subscription_source.referral',
  ];
  const artistRegistrationKeys = ['only_artist.new_registrations'];
  const subscriptionStatusMapping = {
    active: paidKeys.includes(dataKey),
    cancelled: cancelledKeys.includes(dataKey),
    in_trial: trialKeys.includes(dataKey),
  };

  if (registrationsKeys.includes(dataKey) || artistRegistrationKeys.includes(dataKey)) {
    hideDialog = false;
    resource = 'users/registrations';
    filter = {
      created_at_from: chartData?.activeLabel,
      created_at_to: chartData?.activeLabel,
      ...(artistRegistrationKeys.includes(dataKey) ? { profile_type_id: 1 } : {}),
    };
    dataFields = [
      { key: 'email', label: 'Email' },
      {
        render: (record) => (record?.profile_type_id ? REGISTRATION_PROFILE_TYPES.find((t) => t.id === record?.profile_type_id)?.name : REGISTRATION_COHORT_TYPES[record.flow_id]),
        label: 'Registration type',
        isFunctionField: true,
      },
      { key: 'step', label: 'Step' },
    ];
  }

  if (paidKeys.includes(dataKey) || cancelledKeys.includes(dataKey) || trialKeys.includes(dataKey)) {
    const subscriptionStatus = Object.entries(subscriptionStatusMapping).find((key) => key[1] === true)[0];
    hideDialog = false;
    resource = 'users/subscriptions';
    filter = {
      status: subscriptionStatus,
      ...(paidKeys.includes(dataKey) || trialKeys.includes(dataKey) ? { subscription_start_date_from: chartData?.activeLabel, subscription_start_date_to: chartData?.activeLabel } : {}),
      ...(cancelledKeys.includes(dataKey) ? { cancelled_at_from: chartData?.activeLabel, cancelled_at_to: chartData?.activeLabel } : {}),
    };
    dataFields = [
      { key: 'user.name', label: 'User' },
      { key: 'subscription.description', label: 'Subscription' },
      { key: 'profile.profileType.name', label: 'Profile type' },
    ];
  }

  if (cancelledWithSourceKeys.includes(dataKey)) {
    hideDialog = false;
    resource = 'users/subscriptions';
    filter = {
      status: 'cancelled',
      cancelled_at_from: chartData?.activeLabel,
      cancelled_at_to: chartData?.activeLabel,
    };
    dataFields = [
      { key: 'user.name', label: 'User' },
      { key: 'subscription.description', label: 'Subscription' },
      { key: 'profile.profileType.name', label: 'Profile type' },
      { key: 'source', label: 'Source' },
    ];
  }

  return { title, filter, resource, hideDialog, dataFields };
};
