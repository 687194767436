import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import orderBy from 'lodash/orderBy';
import omitBy from 'lodash/omitBy';
import { Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, TextField as MUITextField, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import { List, Filter, Datagrid, TextField, FunctionField, useDataProvider, SelectInput, Title, Loading, useNotify, useMutation, ReferenceInput, AutocompleteInput } from 'react-admin';
import CustomDateField from '../components/customFields/CustomDateField';
import { Breadcrumbs } from '../components/Breadcrumbs';
import EntityLink from '../components/EntityLink';
import ValidationLockByUser from '../components/ValidationLockByUser';

const useStyles = makeStyles(() => ({
  actions: {
    marginTop: 20,
    borderBottom: '1px solid #ddd',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
  },
  centerAlign: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  spacingRight: {
    marginRight: 10,
  },
  spacingTop: {
    marginTop: 10,
  },
  button: {
    marginLeft: 20,
    textTransform: 'inherit',
    marginBottom: 15,
  },
  dialog: {
    width: 500,
  },
  spacingBottom: {
    marginBottom: 10,
  },
  bold: {
    fontWeight: 'bold',
  },
  table: {
    width: 500,
    marginBottom: 30,
  },
  tableRow: {
    cursor: 'pointer',
  },
  validationStatus: {
    fontSize: 16,
    fontWeight: 'bold',
    border: '1px solid #ff5722',
    padding: 6,
    backgroundColor: '#ff5722',
    color: 'white',
  },
  logContainer: {
    padding: '8px 16px',
  },
  link: {
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  logsAccordion: {
    marginTop: 20,
  },
  logsAccordionDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputField: {
    marginBottom: 10,
  },
}));

const getSocialLinks = (data) => {
  const socialPlatforms = {
    email: null,
    instagram: 'instagram',
    facebook: 'facebook',
    linkedin: 'linkedin',
    website: 'website',
    other: 'other',
  };

  const links = Object.entries(data || {}).reduce((acc, [key, value]) => {
    acc.push({
      linkType: socialPlatforms[key] === null ? 'email' : 'social',
      value,
      socialType: socialPlatforms[key],
    });

    return acc;
  }, []);

  return omitBy(links, (link) => !link.value);
};

const ArtistWithoutProfileAccessQueueFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      label="Status"
      source="is_completed"
      choices={[
        { id: 'false', name: 'Pending' },
        { id: 'true', name: 'Done' },
      ]}
      alwaysOn
    />
    <ReferenceInput label="Locked by" source="locked_by" reference="users" perPage={25} filter={{ group_id: 3 }} filterToQuery={(searchText) => ({ query: searchText })} alwaysOn>
      <AutocompleteInput optionText="email" />
    </ReferenceInput>
    <ReferenceInput label="Task completed by" source="completed_by" reference="users" perPage={25} filter={{ group_id: 3 }} filterToQuery={(searchText) => ({ query: searchText })} alwaysOn>
      <AutocompleteInput optionText="email" />
    </ReferenceInput>
  </Filter>
);

export const ArtistWithoutProfileAccessQueueShow = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [isLoading, setIsLoading] = useState(false);
  const [taskQueueData, setTaskQueueData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [comments, setComments] = useState('');
  const [isLogExpanded, setLogsExpanded] = useState(false);
  const [formData, setFormData] = useState({ email: '', instagram: '', facebook: '', linkedin: '', website: '', other: '' });

  useEffect(() => {
    setIsLoading(true);
    if (props?.id) {
      dataProvider.getList('task_queues', { filter: { id: props?.id, task_type: 'missing_profile_access' }, pagination: {}, sort: {} }).then(({ data }) => {
        setTaskQueueData(data?.[0]);
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.id]);

  const [handleComplete] = useMutation(
    {
      type: 'create',
      resource: `task_queues/actions`,
      payload: {
        data: {
          actionType: !taskQueueData?.isCompleted ? 'complete' : 'reset',
          id: taskQueueData?.id,
          comments,
          links: getSocialLinks(formData),
        },
      },
    },
    {
      onSuccess: () => {
        notify('Task completed', 'info');
        window.location.reload();
      },
      onFailure: (err) => {
        notify(err?.message, 'error');
      },
    },
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <Title title={<Breadcrumbs {...props} />} />
      <div className={classes.actions}>
        <div className={classes.centerAlign}>
          <Typography variant="caption" className={classes.spacingRight}>
            Status
          </Typography>
          <Typography className={classes.validationStatus}>{taskQueueData?.isCompleted ? 'Done' : 'Pending'}</Typography>
        </div>
        <div className={classes.centerAlign}>
          <div className={classes.centerAlign}>
            <Typography variant="caption" className={classes.spacingRight}>
              Locked by
            </Typography>
            <ValidationLockByUser {...props} data={{ id: taskQueueData?.id }} lockGroupType="task_queue" />
          </div>
          <div>
            <Button variant="contained" size="small" className={classes.button} onClick={() => setIsOpen(true)}>
              {!taskQueueData?.isCompleted ? 'Mark as complete' : 'Reset'}
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.centerAlign} style={{ marginTop: 10 }}>
        <Typography variant="caption" className={classes.spacingRight}>
          Id
        </Typography>
        <Typography variant="caption">{taskQueueData?.id}</Typography>
      </div>
      <div className={classes.spacingBottom}>
        <Typography variant="caption" className={classes.spacingRight}>
          Profile
        </Typography>
        <EntityLink entity={taskQueueData?.profile} entityType="profiles" />
      </div>
      <div className={classes.spacingBottom}>
        <Typography variant="caption" className={classes.spacingRight}>
          Start score
        </Typography>
        <Typography variant="caption">{taskQueueData?.startScore}</Typography>
      </div>
      <div className={classes.centerAlign}>
        <Typography variant="caption" className={classes.spacingRight}>
          Task added date
        </Typography>
        <Typography variant="caption">{taskQueueData?.createdAt}</Typography>
      </div>
      <div className={classes.centerAlign}>
        <Typography variant="caption" className={classes.spacingRight}>
          Task updated date
        </Typography>
        <Typography variant="caption">{taskQueueData?.updatedAt}</Typography>
      </div>
      {taskQueueData?.logs?.length ? (
        <Accordion
          onChange={(e, isExpanded) => {
            setLogsExpanded(isExpanded);
          }}
          expanded={isLogExpanded}
          className={classes.logsAccordion}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.bold}>
            Logs
          </AccordionSummary>
          <AccordionDetails className={classes.logsAccordionDetails}>
            {orderBy(taskQueueData?.logs, 'updatedAt', 'desc')?.map((log) => (
              <li key={log.id} className={classes.logContainer}>
                <Typography variant="caption" className={classes.spacingRight}>
                  Task updated by <EntityLink entity={log?.updatedBy} entityType="users" /> {log?.updatedAt}. Comments: {log?.comments}
                </Typography>
              </li>
            ))}
          </AccordionDetails>
        </Accordion>
      ) : null}
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          className: classes.dialog,
        }}
      >
        <DialogTitle>
          <div>
            {!taskQueueData?.isCompleted ? 'Mark as complete' : 'Reset task'}
            <Typography variant="subtitle2">End score {taskQueueData?.endScore}</Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          <MUITextField
            className={classes.inputField}
            label="Email"
            variant="filled"
            color="primary"
            multiline
            fullWidth
            value={formData?.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            size="small"
          />
          <MUITextField
            className={classes.inputField}
            label="Instagram"
            variant="filled"
            color="primary"
            multiline
            fullWidth
            value={formData?.instagram}
            onChange={(e) => setFormData({ ...formData, instagram: e.target.value })}
            size="small"
          />
          <MUITextField
            className={classes.inputField}
            label="Facebook"
            variant="filled"
            color="primary"
            multiline
            fullWidth
            value={formData?.facebook}
            onChange={(e) => setFormData({ ...formData, facebook: e.target.value })}
            size="small"
          />
          <MUITextField
            className={classes.inputField}
            label="Linkedin"
            variant="filled"
            color="primary"
            multiline
            fullWidth
            value={formData?.linkedin}
            onChange={(e) => setFormData({ ...formData, linkedin: e.target.value })}
            size="small"
          />
          <MUITextField
            className={classes.inputField}
            label="Website"
            variant="filled"
            color="primary"
            multiline
            fullWidth
            value={formData?.website}
            onChange={(e) => setFormData({ ...formData, website: e.target.value })}
            size="small"
          />
          <MUITextField
            className={classes.inputField}
            label="Other"
            variant="filled"
            color="primary"
            multiline
            fullWidth
            value={formData?.other}
            onChange={(e) => setFormData({ ...formData, other: e.target.value })}
            size="small"
          />
          <MUITextField size="small" label="Comments*" variant="filled" color="primary" multiline fullWidth rows={2} value={comments} onChange={(e) => setComments(e.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleComplete} disabled={!comments}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const ArtistWithoutProfileAccessQueueList = (props) => {
  const dataProvider = useDataProvider();
  const [allValidationLocks, setAllValidationLocks] = useState([]);
  const allData = useSelector((store) => store?.admin?.resources?.['task_queues/artistsWithoutProfileAccess'])?.data;
  const allIds = useMemo(() => Object.keys(allData), [allData]);

  useEffect(() => {
    if (allIds.length) {
      dataProvider
        .getList('validations/locks', {
          filter: { lockIdentifier: allIds.join(','), lockGroup: 'task_queue', validation_status: '' },
          pagination: {},
          sort: {},
        })
        .then(({ data }) => {
          setAllValidationLocks(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allIds]);

  const showLockedUser = useCallback(
    (data) => {
      if (allValidationLocks.length) {
        const hasValidationLock = allValidationLocks.find((validationLock) => Number(validationLock.lockIdentifier) === Number(data.id));
        return `${hasValidationLock?.user?.email || 'Not locked'}`;
      }
      return 'Not locked';
    },
    [allValidationLocks],
  );

  return (
    <List
      {...props}
      title={<Breadcrumbs {...props} />}
      filters={<ArtistWithoutProfileAccessQueueFilter />}
      filter={{ task_type: 'missing_profile_access' }}
      filterDefaultValues={{ is_completed: false }}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      exporter={false}
    >
      <Datagrid rowClick="show">
        <TextField source="id" />
        <FunctionField label="Profile" render={(record) => <EntityLink entity={record?.profile} entityType="profiles" />} sortable={false} />
        <FunctionField label="Locked By" render={(record) => showLockedUser(record)} sortable={false} />
        <FunctionField source="Status" render={(record) => (record?.isCompleted ? 'Done' : 'Pending')} sortable={false} />
        <CustomDateField label="Task added date" source="createdAt" timeHidden sortBy="created_at" />
        <CustomDateField label="Task updated date" source="updatedAt" timeHidden sortBy="updated_at" />
      </Datagrid>
    </List>
  );
};

export default ArtistWithoutProfileAccessQueueList;
