import { reduce, has, orderBy } from 'lodash';
import { VALIDATION_DASHBOARD_CONFIG } from '../constants/validations';

export const getValidationDashboardConfig = (data) =>
  orderBy(
    reduce(
      data,
      (entities, entity) => {
        if (has(entity, 'validation_pending')) {
          // eslint-disable-next-line no-param-reassign
          entities = [
            ...entities,
            {
              ...VALIDATION_DASHBOARD_CONFIG[entity?.id],
              validations: entity?.validation_pending,
              id: entity?.id,
            },
          ];
        }

        if (has(entity, 'redmask_request_pending')) {
          // eslint-disable-next-line no-param-reassign
          entities = [
            ...entities,
            {
              ...VALIDATION_DASHBOARD_CONFIG.redmask_request_pending,
              validations: entity?.redmask_request_pending,
              id: 'redmask_request_pending',
            },
          ];
        }

        if (has(entity, 'change_request_pending')) {
          // eslint-disable-next-line no-param-reassign
          entities = [
            ...entities,
            {
              ...VALIDATION_DASHBOARD_CONFIG.change_request_pending,
              validations: entity?.change_request_pending,
              id: 'change_request_pending',
            },
          ];
        }

        return entities;
      },
      [],
    ),
    'order',
  );
