import React, { cloneElement } from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { EntityStats } from './EntityStats';

const useStyles = makeStyles({
  showLayout: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',

    '@media (max-width: 600px)': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  showLayoutTwoColumn: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 14px',
    borderLeft: '1px solid #CCC',

    '&:first-child': {
      paddingLeft: 0,
      paddingTop: 0,
      borderLeft: 'none',
    },

    '&:last-child': {
      paddingRight: 0,
    },

    '@media (max-width: 600px)': {
      padding: '0',
      borderLeft: 'none',
      '& .MuiCardContent-root': {
        padding: '0 !important',
      },
    },
  },
  entityStats: {
    position: 'unset',
  },
});

const CustomShowLayout = ({ children, isStats = true, ...props }) => {
  const classes = useStyles();

  const renderColumn = (column) => (
    <div className={classes.column} {...props}>
      {cloneElement(column, { ...props })}
    </div>
  );

  return (
    <div className={classnames(classes.showLayout, { [classes.showLayoutTwoColumn]: !children?.column2 || (!isStats && !children?.column3) })}>
      {children?.column1 && renderColumn(children?.column1)}
      {children?.column2 && renderColumn(children?.column2)}
      {isStats ? (
        <div className={classes.column}>
          <EntityStats {...props} className={classes.entityStats} />
        </div>
      ) : (
        children?.column3 && renderColumn(children?.column3)
      )}
    </div>
  );
};

export default CustomShowLayout;
