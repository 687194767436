/* eslint-disable react/jsx-boolean-value */
import React, { useState, useEffect, useCallback } from 'react';
import {
  Show,
  ShowView,
  ShowContextProvider,
  ReferenceManyField,
  ChipField,
  FunctionField,
  BooleanInput,
  List,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  Filter,
  Edit,
  EditButton,
  SingleFieldList,
  ReferenceField,
  TextInput,
  Datagrid,
  TextField,
  ReferenceInput,
  ArrayField,
  SelectInput,
  Tab,
  Toolbar,
  SaveButton,
  Create,
  DeleteButton,
  DeleteWithConfirmButton,
  SimpleForm,
  SimpleShowLayout,
  Pagination,
  AutocompleteInput,
  BooleanField,
  useMutation,
  useShowController,
  Confirm,
  useDataProvider,
  TabbedForm,
  FormTab,
  ArrayInput,
  SimpleFormIterator,
  DateField,
  useRefresh,
  useNotify,
  useRedirect,
  SelectField,
  ListContextProvider,
  FormDataConsumer,
  Loading,
} from 'react-admin';
import {
  withStyles,
  Tab as MUITab,
  Tabs as MUITabs,
  AppBar as MUIAppBar,
  Typography,
  Divider,
  Button,
  ButtonGroup,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogContent,
  Avatar,
  Paper,
  TextField as MUITextField,
  DialogActions,
  DialogTitle,
  MenuItem,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIPagination from '@material-ui/lab/Pagination';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import lodash from 'lodash';
import { Check as CheckIcon, VerifiedUser as VerifiedUserIcon } from '@material-ui/icons';
import * as dayjs from 'dayjs';
import ProfileAgencyLink from '../components/profileAgencyLink';
import ProfileOrganizationLink from '../components/ProfileOrgLink';
// eslint-disable-next-line import/no-cycle
import { renderProfile } from './UserSubcriptionsModel';
import { AgencyAgentCreateButton } from './AgenciesModel';
import { OrganizationEmployeeCreateButton } from './OrganizationsModal';
import { AGENT_TYPE_ID, AOS_TYPE_ID, ARTIST_TYPE_ID } from '../constants/profiles';
import CustomDateField from '../components/customFields/CustomDateField';
import { InvoicesDataGrid } from './InvoicesModel';
import { UnbilledChargesDataGrid } from './UnbilledChargesModel';
import { CreditNotesDataGrid } from './CreditNotesModel';
import { CreateCouponCodeButton } from './CouponsModel';
import { SanitizedGrid as Grid } from '../components/SanitizedGrid';
import { SUBSCRIPTION_STATUS, productCurrency } from '../constants/subscription';
import { paymentWalletsDescriptions } from '../utils/users';
import useLanguageQuery from '../utils/useLanguageQuery';
import { SubscriptionsTabContent } from '../components/SubscriptionsTabContent';
import { Breadcrumbs } from '../components/Breadcrumbs';
import ButtonWithConfirmation from '../components/ButtonWithConfirmation';
import apiClient from '../utils/api';
import { WALLET_TYPES } from '../constants/users';
import PermissionsView from '../components/PermissionsView';
import axios from 'axios';

// const BLITZER_API_URL = "https://dev-blitzer-event-api.truelinked.net/"
// const BLITZER_SECRET = 'secret'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: '1rem',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
  table: {
    margin: 10,
    maxWidth: 400,
  },
  customPermission: {
    margin: '10px 0',
  },
  scrollable: { overflowX: 'auto' },
  tableRow: { cursor: 'pointer' },
  add: {
    cursor: 'pointer',
    display: 'inline-flex',
    minWidth: 'max-content',
  },
  suggestionsContainerOpen: {
    zIndex: 2100,
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
  },
  trialBtn: {
    display: 'inline-block',
    marginLeft: 20,
  },
  trialContainer: {
    margin: '10px 0',
  },
  active: {
    color: '#ff5722',
  },
  button: { fontSize: 11 },
  infoSection: {
    display: 'flex',
    borderBottom: '1px solid #e1e1e1',
    padding: 10,
    alignItems: 'center',
  },
  avatar: {
    width: 30,
    height: 30,
  },
  spacingLeft: {
    marginLeft: 10,
  },
  headerSection: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 250,
    width: 250,
  },
  bold: {
    fontWeight: 'bold',
  },
  referralTabData: {
    marginTop: 40,
  },
  pagination: { margin: '20px 0 40px 0' },
  timeline: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  timelineItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    width: 100,
  },
  timelineDot: {
    display: 'flex',
    alignSelf: 'baseline',
    border: '1px solid #E8E8E8',
    padding: 4,
    borderRadius: '50%',
    color: '#FFFFFF',
    backgroundColor: '#209248',
    fontSize: 15,
  },
  timelineSeparator: {
    width: 76,
    border: '1px solid #209248',
  },
  timelineDashedSeparator: {
    width: 72,
    border: '1px dashed #979797',
    marginTop: 1,
  },
  timelineContentLeft: {
    position: 'relative',
    right: 40,
    top: 5,
    fontSize: 12,
    color: '#5F5F5F',
    wordBreak: 'break-word',
    width: 200,
  },
  timelineContentRight: {
    position: 'relative',
    left: 45,
    top: 5,
    fontSize: 12,
    color: '#5F5F5F',
  },
  timelineNumberDot: {
    borderRadius: '50%',
    width: 24,
    height: 24,
    padding: 3,
    backgroundColor: '#FFFFFF',
    border: '1px solid #E8E8E8',
    color: '#979797',
    display: 'flex',
    justifyContent: 'center',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 200,
    wordBreak: 'break-word',
  },
  paper: {
    margin: '30px 30px 30px 0',
    padding: 15,
    width: '43%',
  },
  titleText: {
    fontSize: 14,
    lineHeight: 2,
    fontWeight: 500,
  },
  caption: {
    fontSize: 13,
    fontWeight: 400,
  },
  tabSection: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dialog: { width: 400 },
  arrayInput: {
    '& section': {
      position: 'relative',
      display: 'grid',
      gridTemplateColumns: 'auto auto auto',
      flex: 'none',
    },
    '& section > div': {
      '& .MuiInputBase-root': {
        width: 220,
      },
    },
    '& li:last-child': { height: 50 },
    '& li>span': { position: 'absolute', right: 0 },
  },
  arrayInputRightSection: {
    position: 'absolute',
    top: 0,
    width: 0,
    right: 15,

    '& label': { width: 250 },
  },
  buttonWrapper: {
    '& button': {
      textTransform: 'inherit',
      background: '#e0e0e0',
      color: 'black',

      '&:hover': {
        background: '#e0e0e0',
      },
    },
  },
  filterSection: {
    display: 'flex',
    flexDirection: 'row',
  },
  selectSection: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
}));

const MAIL_STATUS_UNSUBSCRIBED = 6;
const MAIL_STATUS_RESUBSCRIBED = 7;

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const CreateUserProfileButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: '/profiles/create',
      state: {
        record: { user: { id: record.id } },
        userRestricted: true,
      },
    }}
  >
    Create profile
  </Button>
);

const CreateUserSubscriptionButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: '/users/subscriptions/create',
      state: { record: { user: { id: record.id } } },
    }}
  >
    Create Subscription
  </Button>
);

const UserTrialStatusControl = ({ record }) => {
  const { trialExhausted: isTrialExhausted } = record || {};
  const classes = useStyles();
  const [switchTrial, { loading }] = useMutation({
    type: 'update',
    resource: 'users',
    payload: { id: record?.id, data: { trialExhausted: !isTrialExhausted } },
  });

  if (typeof isTrialExhausted !== 'boolean') return <div>{`[ERROR] Unable to fetch trial status of USERID:${record?.id}`}</div>;

  return (
    <details className={classes.trialContainer}>
      <summary>Trial status</summary>
      {isTrialExhausted && <span>User trial is exhausted. User will see paid options.</span>}
      {!isTrialExhausted && (
        <span>
          User trial is <b>NOT</b> exhausted. User will see trial options if they are available.
        </span>
      )}
      <ButtonWithConfirmation
        color={!isTrialExhausted ? 'primary' : 'secondary'}
        disabled={loading}
        content={isTrialExhausted ? 'Resetting trial will allow user to get trial subscription' : 'Exhausting trial will make it so user can no longer get trial subscriptions'}
        title={isTrialExhausted ? 'Confirm reset trial' : 'Confirm exhaust trial'}
        variant="contained"
        label={isTrialExhausted ? 'RESET TRIAL' : 'EXHAUST TRIAL'}
        handleConfirm={switchTrial}
        classButtonWrapper={classes.trialBtn}
      />
    </details>
  );
};

const CreateUserGroupButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: '/users/groups/create',
      state: { record: { user: { id: record.id } } },
    }}
  >
    Create Permission
  </Button>
);

const SendWelcomeEmailButton = ({ record }) => {
  const refresh = useRefresh();
  const notify = useNotify();

  const onSuccess = () => {
    notify('Sent welcome email');
    refresh();
  };
  const onFailure = (err) => {
    notify(err.message, 'error');
    refresh();
  };
  const [welcome] = useMutation({ type: 'create', resource: 'users/welcome', payload: { user_id: record?.id } }, { onSuccess, onFailure });
  return <Button onClick={welcome} disabled={!record?.welcomeEmailRequired}>{`Send welcome email${record?.passwordSetupRequired ? ' (with "set up password" link)' : ''}`}</Button>;
};

const CustomToolbar = withStyles(toolbarStyles)((props) => (
  <Toolbar {...props}>
    <ButtonGroup variant="text">
      <SaveButton {...props} />
      <SendWelcomeEmailButton {...props} />
    </ButtonGroup>
    <DeleteButton undoable={false} />
  </Toolbar>
));

export const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="User ID" source="id" alwaysOn />
    <TextInput label="Search" source="query" alwaysOn />
    <ReferenceInput label="User Group" source="group_id" reference="groups" alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const EmptyMessageArrayField = (props) => {
  const items = lodash.get(props.record, props.source);
  if (items && items.length > 0) {
    const updatedProps = { ...props };
    delete updatedProps.emptyText;
    return <ArrayField {...updatedProps}>{updatedProps.children}</ArrayField>;
  }
  return <TextField {...props} record={null} />;
};

EmptyMessageArrayField.defaultProps = {
  addLabel: true,
  emptyText: '(Empty)',
};

const TabPanel = (props) => {
  const { children, value, index } = props;

  return value === index && children;
};

const RequestsTabs = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <MUIAppBar position="static" className={classes.root}>
        <MUITabs value={value} onChange={handleChange} scrollButtons="on" variant="scrollable" indicatorColor="primary">
          <MUITab label="Profile accesses" value={0} />
          <MUITab label="Profiles access requests" value={1} />
          <MUITab label="Own profiles" value={2} />
          <MUITab label="Agencies" value={3} />
          <MUITab label="Agencies access requests" value={4} />
          <MUITab label="Organizations" value={5} />
        </MUITabs>
        <TabPanel value={value} index={0}>
          <CreateProfileAccessButton record={{ id: props.record.id }} />
          <ReferenceManyField {...props} label="Profiles" reference="profiles/profileaccesses" target="user_id" pagination={<Pagination />} perPage={10}>
            <Datagrid link={false}>
              <TextField label="Profile ID" source="profile.id" />
              <ReferenceField label="Profile name" source="profile.id" reference="profiles" link="show">
                <TextField source="name" emptyText="Empty" />
              </ReferenceField>
              <ReferenceField label="Profile type" source="profile.id" reference="profiles" link="show">
                <TextField source="profileType.name" />
              </ReferenceField>
              <DeleteButton label="Remove Access" />
            </Datagrid>
          </ReferenceManyField>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CreateUserProfileButton record={{ id: props.record.id }} />
          <ReferenceManyField {...props} label="Profiles" reference="profiles" target="user_id" pagination={<Pagination />} perPage={10}>
            <Datagrid rowClick="show">
              <TextField source="id" label="Profile ID" />
              <TextField source="name" label="Profile name" />
              <TextField source="profileType.name" label="Profile type" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ReferenceManyField {...props} reference="profiles/profileaccessrequests" filter={{ user_id: props.record.id }} target="user_id">
            <Datagrid rowClick={(id, path, record) => `/profiles/profileaccessrequests/${record?.id}/show`}>
              <TextField source="sender.user.id" label="Sender user ID" />
              <TextField source="sender.user.name" label="Sender user name" />
              <TextField source="sender.email" label="Sender email" />
              <TextField source="sender.timezone" label="Sender timezone" />
              <TextField source="sender.preferred_time" label="Sender preferred time" />
              <TextField source="profile.name" label="Profile name" />
              <TextField source="status" label="Status" />
              <ProfileAccessRequestsActions />
              <DeleteWithConfirmButton {...props} undoable={false} redirect={false} />
            </Datagrid>
          </ReferenceManyField>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <AgencyAgentCreateButton user={props.record} />
          <ReferenceManyField {...props} reference="agencies/agents" target="user_id" filter={{ all: true }}>
            <Datagrid rowClick={(id, path, record) => `/agencies/${record?.agency?.id}/show`}>
              <TextField source="agency.id" label="Agency ID" />
              <TextField source="agency.name" label="Agency name" />
              <TextField source="status" label="Status" />
              <TextField source="workEmail" label="Work email" />
              <DeleteWithConfirmButton {...props} undoable={false} redirect={false} />
            </Datagrid>
          </ReferenceManyField>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <ReferenceManyField {...props} reference="agencies/access_requests" target="user_id">
            <Datagrid rowClick="show">
              <TextField source="agency.id" label="Agency ID" />
              <TextField source="agency.name" label="Agency name" />
              <TextField source="sender.profile.name" label="Profile name" />
              <TextField source="text" label="Text" />
              <BooleanField source="status" label="Status" />
              <AgencyAccessActions />
              <DeleteWithConfirmButton {...props} undoable={false} redirect={false} />
            </Datagrid>
          </ReferenceManyField>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <OrganizationEmployeeCreateButton user={props.record} />
          <ReferenceManyField {...props} reference="organizations/employees" target="user_id">
            <Datagrid rowClick={(id, path, record) => `/organizations/${record?.organization?.id}/show`}>
              <TextField source="organization.id" label="Organization ID" />
              <TextField source="organization.name" label="Organization name" />
              <TextField source="profile.name" label="Employee name" />
              <TextField source="role" label="Employee role" />
              <TextField source="work_email" label="Employee work email" />
              <TextField source="work_phone_number" label="Employee work phone number" />
              <DeleteWithConfirmButton {...props} undoable={false} redirect={false} />
            </Datagrid>
          </ReferenceManyField>
        </TabPanel>
      </MUIAppBar>
    </div>
  );
};

const CreateProfileAccessButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: '/profiles/profileaccesses/create',
      state: { record: { user: { id: record.id }, userRestricted: true } },
    }}
  >
    Create profile access
  </Button>
);

const GiftSubscriptionTab = (props) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [profiles, setProfiles] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const userId = props?.record?.id;
  const [tab, setTab] = useState(0);
  const [tabData, setTabData] = useState({});
  const [artists, setArtists] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const PAGE_LIMIT = 5;
  const totalPages = Math.ceil(tabData?.total / PAGE_LIMIT);

  const paymentMethod = paymentMethods.some(
    (item) => [WALLET_TYPES.card, WALLET_TYPES.applePay, WALLET_TYPES.googlePay, WALLET_TYPES.sepaDirectDebit, WALLET_TYPES.paypal].includes(item.wallet_type) && item.status === 'valid',
  );

  useEffect(() => {
    dataProvider
      .getList('users/paymentmethods', {
        pagination: { perPage: 100 },
        sort: { field: 'id' },
        filter: { user_id: userId },
      })
      .then((res) => {
        setPaymentMethods(res?.data);
      });

    dataProvider
      .getList(`users/${userId}/subscriptions`, {
        pagination: { perPage: 100 },
        sort: {},
        filter: {},
      })
      .then((res) => {
        setUserSubscriptions(res?.data);
      });
  }, [dataProvider, userId]);

  useEffect(() => {
    dataProvider.getList('profiles', { filter: { access_user_id: userId, profile_type_id: 1 }, pagination: { perPage: 100 }, sort: {} }).then(({ data: dataProfiles }) => {
      if (dataProfiles) {
        const filterProfiles = dataProfiles.filter(
          (p) => p?.validationStatus?.slug === 'approved' && p?.profileType?.id === ARTIST_TYPE_ID && paymentMethod && !lodash.isEmpty(userSubscriptions?.filter((sub) => sub?.profile?.id === p?.id)),
        );
        setProfiles(filterProfiles);
        if (filterProfiles.length) {
          setSelectedProfile(filterProfiles[0]);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod, userSubscriptions]);

  useEffect(() => {
    if (selectedProfile?.id && tab === 0) {
      if (!artists.length) {
        setIsLoading(true);
        apiClient
          .get(`users/${userId}/referrals`, {
            params: {
              profile_id: selectedProfile?.id,
            },
          })
          .then((res) => {
            setIsLoading(false);
            const mergeArtistWithProductions = lodash.values(
              lodash.reduce(
                lodash.get(res, 'data.data', []),
                (result, artist) => {
                  if (!result[artist?.profile?.id]) {
                    return {
                      ...result,
                      [artist?.profile?.id]: { profile: artist?.profile, productions: [artist?.production] },
                    };
                  }

                  return {
                    ...result,
                    [artist?.profile?.id]: {
                      ...result[artist?.profile?.id],
                      productions: [...result[artist?.profile?.id].productions, artist?.production],
                    },
                  };
                },
                {},
              ),
            );

            setArtists(mergeArtistWithProductions);
            const displayArtists = lodash.slice(mergeArtistWithProductions, (currentPage - 1) * PAGE_LIMIT, currentPage * PAGE_LIMIT);
            setTabData({
              ...res.data,
              data: displayArtists,
              total: lodash.size(mergeArtistWithProductions),
            });
          });
      } else {
        setIsLoading(false);
        const displayArtists = lodash.slice(artists, (currentPage - 1) * PAGE_LIMIT, currentPage * PAGE_LIMIT);
        setTabData({
          ...tabData,
          data: displayArtists,
          total: lodash.size(artists),
        });
      }
    }

    if (selectedProfile?.id && tab === 1) {
      setIsLoading(true);
      dataProvider.getList(`users/${userId}/referralStatus`, { filter: { profile_id: selectedProfile?.id }, pagination: { perPage: PAGE_LIMIT, page: currentPage }, sort: {} }).then((res) => {
        setIsLoading(false);
        setTabData(res);
        setArtists([]);
      });
    }

    if (selectedProfile?.id && tab === 2) {
      setIsLoading(true);
      apiClient.get(`users/${userId}/rewards`).then((res) => {
        setIsLoading(false);
        setTabData(res?.data);
        setArtists([]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProvider, selectedProfile, tab, userId, currentPage]);

  const handlePagination = (e, page) => {
    if (page !== currentPage) {
      setCurrentPage(page);
      setIsLoading(true);
    }
  };

  return (
    <div>
      <MUITabs
        value={tab}
        onChange={(e, value) => {
          setTab(value);
          setCurrentPage(1);
        }}
        indicatorColor="primary"
      >
        <MUITab value={0} label="List of artists" />
        <MUITab value={1} label="Track gift status" />
        <MUITab value={2} label="Reward balance" />
      </MUITabs>
      <TabPanel value={tab} index={0}>
        <div>
          {selectedProfile.id && (
            <ProfilesFilter
              classes={classes}
              selectedProfile={selectedProfile}
              options={profiles}
              onChange={(profile) => {
                setIsLoading(true);
                setSelectedProfile(profile);
                setArtists([]);
              }}
            />
          )}
          {!isLoading ? (
            <div className={classes.referralTabData}>
              {lodash.map(lodash.get(tabData, 'data', []), (artist, index) => (
                <div key={index} className={classes.infoSection}>
                  <div className={classes.headerSection}>
                    <Avatar className={classes.avatar} name={lodash.get(artist, 'profile.name', '')} avatarURL={artist?.profile?.image?.small || artist?.profile?.image} />
                    <div className={classes.spacingLeft}>
                      <Typography variant="subtitle2" component="div">
                        {lodash.get(artist, 'profile.name', '')}
                      </Typography>
                      <Typography variant="body2" component="div">
                        {lodash.get(artist, 'profile.professions.[0].profession.name', '')}
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <Typography variant="caption" component="div">
                      Performed together in
                    </Typography>
                    <Typography variant="caption" className={classes.bold} component="div">
                      {lodash.map(lodash.get(artist, 'productions', []), (production) => lodash.get(production, 'name', '')).join(', ')}
                    </Typography>
                  </div>
                </div>
              ))}
              {tabData?.data?.length ? <MUIPagination className={classes.pagination} page={currentPage} count={totalPages} size="small" onChange={handlePagination} /> : null}
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <div>
          {selectedProfile.id && (
            <ProfilesFilter
              classes={classes}
              selectedProfile={selectedProfile}
              options={profiles}
              onChange={(profile) => {
                setIsLoading(true);
                setSelectedProfile(profile);
              }}
            />
          )}
          {!isLoading ? (
            <div className={classes.referralTabData}>
              {lodash.map(lodash.get(tabData, 'data', []), (sub, index) => (
                <div className={classes.infoSection} key={index} style={{ justifyContent: 'space-between', padding: 16 }}>
                  <div style={{ width: '65%', display: 'flex', justifyContent: 'space-between' }}>
                    <div className={classes.headerSection}>
                      <Avatar name={lodash.get(sub, 'receiver.profile.name', '')} avatarURL={sub?.receiver?.image?.small || sub?.receiver?.image} />
                      <div className={classes.spacingLeft}>
                        {sub?.receiver?.profile ? (
                          <Typography variant="subtitle2" component="div">
                            {lodash.get(sub, 'receiver.profile.name', '')}
                          </Typography>
                        ) : (
                          <Typography variant="subtitle2" component="div" gutterBottom>
                            Artist profile not set up
                          </Typography>
                        )}
                        <Typography variant="caption">
                          {lodash.get(sub, 'receiver.profile.professions.[0].profession.id', '') === 1
                            ? lodash.get(sub, 'receiver.profile.professions.[0].professionQualification.name', '')
                            : lodash.get(sub, 'receiver.profile.professions.[0].profession.name', '')}{' '}
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.timeline}>
                      <div className={classes.timelineItem}>
                        <div className={classes.row}>
                          <CheckIcon className={classes.timelineDot} />
                          <hr className={classes.timelineSeparator} />
                        </div>
                        <div className={classes.timelineContentLeft}>
                          Gift sent {sub?.email}
                          <span style={{ display: 'block' }}>({sub?.initialInviteDate})</span>
                        </div>
                      </div>
                      <div className={classes.timelineItem}>
                        <div className={classes.row}>
                          {sub?.referralStatus === 'gift-accepted' ? (
                            <>
                              <hr className={classes.timelineSeparator} />
                              <CheckIcon className={classes.timelineDot} />
                            </>
                          ) : (
                            <>
                              <hr className={classes.timelineDashedSeparator} />
                              <div className={classes.timelineNumberDot}>2</div>
                            </>
                          )}
                        </div>
                        <div className={classes.timelineContentRight}>
                          Gift accepted
                          {sub?.giftAcceptedDate && <span style={{ display: 'block' }}>({sub?.giftAcceptedDate})</span>}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    {sub?.referralStatus === 'gift-expired' && (
                      <Typography variant="body2" component="div">
                        Gift expired
                      </Typography>
                    )}
                    {sub?.referralStatus === 'gift-accepted' && (
                      <Typography variant="body2" component="div">
                        <span role="img" aria-label="">
                          🎉
                        </span>{' '}
                        €10 earned
                      </Typography>
                    )}
                  </div>
                </div>
              ))}
              {tabData?.data?.length ? <MUIPagination className={classes.pagination} page={currentPage} count={totalPages} size="small" onChange={handlePagination} /> : null}
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <div>
          {!isLoading ? (
            <div>
              <div className={classes.tabSection}>
                {lodash.map(lodash.get(tabData, 'data', []), (reward, index) => (
                  <Paper key={index} className={classes.paper}>
                    <div className={classes.titleText}>
                      Wallet balance:{' '}
                      <span className={classes.caption}>
                        {reward?.walletBalance}
                        {productCurrency(reward?.currency?.currencyCode)}
                      </span>
                    </div>
                    <div className={classes.titleText}>
                      Reward type: <span className={classes.caption}>{reward?.rewardType}</span>
                    </div>
                    <div className={classes.titleText}>
                      Reward amount:{' '}
                      <span className={classes.caption}>
                        {reward?.amount}
                        {productCurrency(reward?.currency?.currencyCode)}
                      </span>
                    </div>
                    <div className={classes.titleText}>
                      Action: <span className={classes.caption}>{reward?.action}</span>
                    </div>
                    <div className={classes.titleText}>
                      Update date: <span className={classes.caption}>{reward?.updatedAt}</span>
                    </div>
                  </Paper>
                ))}
              </div>
              {tabData?.data?.length ? <MUIPagination className={classes.pagination} page={currentPage} count={totalPages} size="small" onChange={handlePagination} /> : null}
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </TabPanel>
    </div>
  );
};

const ProfileDropdown = (props) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [activeProfileId, setActiveProfileId] = useState(null);
  const userId = props?.id;
  const [profiles, setProfiles] = useState([]);
  const [registrations, setRegistrations] = useState([]);
  const [profileIdsWithAccess, setProfileIdsWithAccess] = useState([]);
  const [showAllProfile, setShowAllProfile] = useState(false);

  const getActiveProfileId = () => {
    apiClient.get(`users/${userId}/settings`).then((res) => {
      const profileId = res?.data?.data.find((p) => p.identifier === 'active_profile_id')?.value;
      setActiveProfileId(profileId);
    });
  };

  const getRegistrations = () => {
    dataProvider
      .getList('users/registrations', {
        filter: { user_id: userId },
        pagination: { perPage: 1000 },
        sort: {},
      })
      .then(({ data }) => {
        const registrationList = data.filter((p) => !p?.is_hidden);
        setRegistrations(registrationList);
      });
  };

  const getProfiles = () => {
    dataProvider
      .getList('profiles', {
        filter: { access_user_id: userId },
        pagination: { perPage: 1000 },
        sort: {},
      })
      .then(({ data }) => {
        setProfiles(data);
      });
  };

  const getProfileAccessRequests = () => {
    dataProvider
      .getList('profiles/profileaccessrequests', {
        filter: { user_id: userId },
        pagination: { perPage: 1000 },
        sort: {},
      })
      .then(({ data }) => {
        const profileAccessRequestIds = data.filter((p) => p?.status === 'pending').map((p) => p?.profile?.id);
        setProfileIdsWithAccess(profileAccessRequestIds);
      });
  };

  const getProfilesWithAccessRequests = () => {
    dataProvider
      .getList('profiles', {
        filter: { id: profileIdsWithAccess },
        pagination: { perPage: 1000 },
        sort: {},
      })
      .then(({ data }) => {
        setProfiles((prevState) => [...prevState, ...data]);
      });
  };

  useEffect(() => {
    getActiveProfileId();
    getRegistrations();
    getProfiles();
    getProfileAccessRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (profileIdsWithAccess.length) {
      getProfilesWithAccessRequests();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileIdsWithAccess]);

  const getName = (p) => {
    const registration = registrations.find((reg) => reg?.profile?.id === p?.id || reg?.id === p?.id);
    const profileTypeId = lodash.get(p, 'profileType.id') || parseInt(lodash.get(p, 'type'), 0);

    switch (profileTypeId) {
      case 1:
        return p?.name || '';
      case 2:
        return lodash.get(p, 'agencies[0].name', '') || registration?.agency_name || '';
      case 3:
        return lodash.get(p, 'organizations[0].organization.name', '') || registration?.organization?.name || '';
      default:
        return p?.name;
    }
  };

  if (!profiles.length) return null;

  return (
    <div>
      <div className={classes.label}>
        Profile dropdown{' '}
        {profiles.length > 3 && (
          <Button variant="text" color="primary" className={classes.button} onClick={() => setShowAllProfile(!showAllProfile)}>
            {showAllProfile ? 'Hide' : 'View'} all
          </Button>
        )}
      </div>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {showAllProfile
              ? profiles.map((p) => (
                  <TableRow key={p.id}>
                    <TableCell>
                      {p.id} {p.id === parseInt(activeProfileId, 0) && <span className={classes.active}>(active)</span>}
                    </TableCell>
                    <TableCell>{getName(p)}</TableCell>
                    <TableCell style={{ width: 100 }}>{p?.profileType?.name}</TableCell>
                  </TableRow>
                ))
              : lodash.take(profiles, 3).map((p) => (
                  <TableRow key={p.id}>
                    <TableCell>
                      {p.id} {p.id === parseInt(activeProfileId, 0) && <span className={classes.active}>(active)</span>}
                    </TableCell>
                    <TableCell>{getName(p)}</TableCell>
                    <TableCell style={{ width: 100 }}>{p?.profileType?.name}</TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const UserSettings = (props) => {
  const userSettingIds = ['active_profile_id', 'phone', 'is_gift_notified', 'consolidated_invoicing', 'email_notifications'];
  const dataGridProps = {
    ...props,
    ids: userSettingIds,
  };

  return (
    <ListContextProvider
      value={{
        ...dataGridProps,
      }}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="value" />
      </Datagrid>
    </ListContextProvider>
  );
};

const IdentityVerificationStatus = ({ record }) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const handleVerifyUser = () => {
    dataProvider
      .create(`users/${record?.id}/actions`, {
        data: {
          action: 'update_verification',
          isVerified: 'yes',
        },
      })
      .then(() => {
        window.location.reload();
      });
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ marginRight: 10 }}>{`${record?.isVerified}`}</span>
      {!record?.isVerified && (
        <ButtonWithConfirmation
          classButtonWrapper={classes.buttonWrapper}
          content="Do you want to verify this user?"
          title="Mark as verified"
          variant="contained"
          label="Mark as verified"
          handleConfirm={handleVerifyUser}
        />
      )}
    </div>
  );
};

const SuspiciousStatus = ({ record }) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const handleSuspiciousUser = () => {
    dataProvider
      .create(`users/${record?.id}/actions`, {
        data: {
          action: 'mark_suspicious',
          suspicious: record?.isSuspicious ? 'no' : 'yes',
        },
      })
      .then(() => {
        window.location.reload();
      });
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ marginRight: 10 }}>{`${record?.isSuspicious}`}</span>
      <ButtonWithConfirmation
        classButtonWrapper={classes.buttonWrapper}
        content={record?.isSuspicious ? 'Do you want to mark this user as not suspicious?' : 'Do you want to mark this user as suspicious?'}
        title={record?.isSuspicious ? 'Mark as not suspicious' : 'Mark as suspicious'}
        variant="contained"
        label={record?.isSuspicious ? 'Mark as not suspicious' : 'Mark as suspicious'}
        handleConfirm={handleSuspiciousUser}
      />
    </div>
  );
};

const Fingerprint = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState([]);
  // const [usage, setUsage] = useState([]);
  const [metadata, setMetadata] = useState({});
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const defaultVisitorId = props?.user?.fingerprints?.find((f) => f && f !== 'undefined');
  const [visitorId, setVisitorId] = useState(defaultVisitorId);
  const [tab, setTab] = useState(0);
  const searchParams = new URLSearchParams(history.location.search);
  const defaultDateRange = { from_date: searchParams?.get('from_date'), to_date: searchParams?.get('to_date') };
  const [selectedDateRange, setSelectedDateRange] = useState({
    from: defaultDateRange?.from_date || dayjs().add(-5, 'day').format('YYYY-MM-DD'),
    to: defaultDateRange?.to_date || dayjs().format('YYYY-MM-DD'),
  });

  // useEffect(() => {
  //   if (visitorId) {
  //     setLoading(true);
  //     apiClient
  //       .get('fingerprints', {
  //         params: { visitor_id: visitorId, page, limit: perPage },
  //       })
  //       .then((res) => {
  //         setData(res.data);
  //         setLoading(false);
  //       });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [page, perPage, visitorId]);

  useEffect(() => {
    if (visitorId) {
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_BLITZER_API_URL}events`, {
          params: {
            fromDate: selectedDateRange.from,
            toDate: selectedDateRange.to,
            visitorId,
            page,
            limit: perPage,
          },
          headers: {
            'x-access-key': `${process.env.REACT_APP_BLITZER_API_SECRET}`,
          },
        })
        .then((res) => {
          setData(res?.data);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, visitorId, selectedDateRange]);

  // useEffect(() => {
  //   if (visitorId) {
  //     setLoading(true);
  //     apiClient
  //       .get('fingerprints/stats', {
  //         params: {
  //           visitor_id: visitorId,
  //           from_date: selectedDateRange.from,
  //           to_date: selectedDateRange.to,
  //         },
  //       })
  //       .then((res) => {
  //         setUsage(res.data);
  //         setLoading(false);
  //       });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [visitorId, selectedDateRange]);

  useEffect(() => {
    if (visitorId) {
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_BLITZER_API_URL}cache/${visitorId}`, {
          headers: {
            'x-access-key': `${process.env.REACT_APP_BLITZER_API_SECRET}`,
          },
        })
        .then((res) => {
          setMetadata(res?.data?.visitorData);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitorId]);

  const listContextEvents = {
    data: lodash.keyBy(data.events, (item) => `${item._id}`),
    ids: data?.events?.map((item) => `${item._id}`),
    total: data?.total || 0,
    basePath: props.basePath,
    resource: props.resource,
    selectedIds: [],
    currentSort: {},
    hasList: true,
    page,
    setPage,
    perPage,
    setPerPage,
  };

  // const listContextMetadata = {
  //   data: lodash.keyBy(usage?.stats, (item) => `${item?.visitorId}-${item?.pageSubType}-${item?.pageType}-${item?.section}`),
  //   ids: usage?.stats?.map((item) => `${item?.visitorId}-${item?.pageSubType}-${item?.pageType}-${item?.section}`),
  //   total: usage?.stats?.length || 0,
  //   basePath: props.basePath,
  //   resource: props.resource,
  //   selectedIds: [],
  //   currentSort: {},
  //   hasList: true,
  //   page,
  //   setPage,
  //   perPage,
  //   setPerPage,
  // };

  const showContext = {
    id: metadata?.visitorId,
    data: metadata,
    record: metadata,
    loading,
    basePath: props.basePath,
    resource: props.resource,
    hasShow: true,
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <div>
        <FormControl className={classes.filterSection} size="small">
          <MUITextField value={visitorId} select onChange={(e) => setVisitorId(e?.target?.value)} label="Visitor ID" variant="filled" size="small" style={{ minWidth: 150 }}>
            {[...new Set(props?.user?.fingerprints)]
              .reverse()
              .map((option) => {
                if (option && option !== 'undefined' && option !== 'null') {
                  return (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  );
                }
                return [];
              })
              ?.filter(Boolean)}
          </MUITextField>
        </FormControl>
        <MUITabs
          value={tab}
          onChange={(e, value) => {
            setTab(value);
          }}
          indicatorColor="primary"
        >
          <MUITab value={0} label="Fingerprint Events" />
          <MUITab value={1} label="Fingerprint Metadata" />
          {/* <MUITab value={2} label="Fingerprint Usage" /> */}
        </MUITabs>
        <TabPanel value={tab} index={0}>
          <FormControl className={classes.selectSection} size="small">
            <MUITextField
              disabled={loading}
              value={selectedDateRange.from}
              onChange={(e) => setSelectedDateRange({ ...selectedDateRange, from: e?.target?.value })}
              type="date"
              label="From"
              variant="filled"
              size="small"
            />
            <MUITextField
              className={classes.spacingLeft}
              value={selectedDateRange.to}
              onChange={(e) => setSelectedDateRange({ ...selectedDateRange, to: e?.target?.value })}
              type="date"
              label="To"
              variant="filled"
              size="small"
              disabled={loading}
            />
          </FormControl>
          <ListContextProvider value={listContextEvents}>
            <Datagrid>
              <TextField source="_id" sortable={false} />
              <TextField source="pageCategory" sortable={false} />
              <TextField source="pageType" sortable={false} />
              <TextField source="city" sortable={false} />
              <TextField source="country" sortable={false} />
              <TextField source="entityType" sortable={false} />
              <TextField source="entityId" sortable={false} />
              <TextField source="weightage" sortable={false} />
            </Datagrid>
            <Pagination {...props} />
          </ListContextProvider>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          {metadata && Object.keys(metadata).length ? (
            <ShowContextProvider value={showContext}>
              <ShowView {...showContext} {...props} title={<Breadcrumbs {...props} />}>
                <SimpleShowLayout>
                  <TextField source="visitorId" />
                  <TextField source="metaData.ipAddress" label="IP" />
                  <TextField source="metaData.country" label="Country" />
                  <TextField source="metaData.city" label="City" />
                  <TextField source="metaData.continent" label="Continent" />
                  <TextField source="metaData.browserName" label="Browser" />
                  <TextField source="metaData.device" label="Device" />
                  <TextField source="metaData.os" label="OS" />
                  <TextField source="metaData.requestId" label="Request Id" />
                  <TextField source="metaData.userAgent" label="User Agent" />
                  <hr></hr>
                  <TextField source="type" label="Wall Type" />
                  <TextField source="duration" label="Duration" />
                  <TextField source="isActive" label="Is Active" />
                  <TextField source="eventType" label="Event Type" />
                  <TextField source="eventCount" label="Event Count" />
                  <TextField source="totalEventCount" label="Total Event COunt" />
                  <TextField source="ignoredCount" label="Ignore Count" />
                  <TextField source="pageViewCount" label="Pages Viewed Count" />
                  <TextField source="sessionId" label="Current Session Id" />
                  <TextField source="createdAt" label="Created At" />
                </SimpleShowLayout>
              </ShowView>
            </ShowContextProvider>
          ) : (
            <p>No metadata found</p>
          )}
        </TabPanel>
        {/* <TabPanel value={tab} index={2}>
          <FormControl className={classes.selectSection} size="small">
            <MUITextField
              disabled={loading}
              value={selectedDateRange.from}
              onChange={(e) => setSelectedDateRange({ ...selectedDateRange, from: e?.target?.value })}
              type="date"
              label="From"
              variant="filled"
              size="small"
            />
            <MUITextField
              className={classes.spacingLeft}
              value={selectedDateRange.to}
              onChange={(e) => setSelectedDateRange({ ...selectedDateRange, to: e?.target?.value })}
              type="date"
              label="To"
              variant="filled"
              size="small"
              disabled={loading}
            />
          </FormControl>
          <ListContextProvider value={listContextMetadata}>
            <Datagrid>
              <TextField source="visitorId" label="VisitorId" sortable={false} />
              <TextField source="pageType" sortable={false} />
              <TextField source="pageSubType" sortable={false} />
              <TextField source="section" sortable={false} />
              <TextField source="weightage" sortable={false} />
              <TextField source="count" sortable={false} />
            </Datagrid>
            <Pagination {...props} />
          </ListContextProvider>
        </TabPanel> */}
      </div>
    </div>
  );
};

export const UserShow = (props) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const languages = useLanguageQuery();
  const [mutate] = useMutation();
  const notify = useNotify();
  const redirect = useRedirect();
  const { record: user } = useShowController(props);
  const [resetConfirmOpen, setResetConfirmOpen] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState({});
  const [agent, setAgent] = useState({});
  const [employee, setEmployee] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const userId = props?.id;
  const [sendResetPassword] = useMutation({
    type: 'create',
    resource: 'auth/forgotPassword',
    payload: { data: { email: user?.email, url: `${process.env.REACT_APP_OPERABASE_URL}/login/reset` } },
  });
  const [isSaving, setSaving] = useState(false);

  const refresh = useRefresh();
  const settings = useSelector((store) => store.admin.resources['users/settings']?.data);
  const consolidatedInvoicing = settings.consolidated_invoicing?.value !== undefined && JSON.parse(settings.consolidated_invoicing?.value);
  const profiles = useSelector((store) => store.admin.resources.profiles?.data);
  const userProfiles = lodash.filter(profiles, (p) => p?.user?.id === parseInt(userId, 0));
  const profilesCount = lodash.size(userProfiles);

  const [sendEnableConsolidatedInvoicing] = useMutation(
    {
      type: 'update',
      resource: `users/${user?.id}/settings`,
      payload: {
        id: 'consolidated_invoicing',
        data: { user_id: user?.id, value: !consolidatedInvoicing },
      },
    },
    {
      onSuccess: () => refresh(),
    },
  );
  const [consolidateConfirmOpen, setConsolidateConfirmOpen] = useState(false);

  const handleProfileWithAccess = (id, status) => {
    dataProvider
      .update('profiles/profileaccessrequests', { id, data: { status } })
      .then(() => refresh())
      .catch((err) => {
        notify(err.message, 'error');
      });
  };

  const redirectToValidations = (filter) => redirect(`/validations?filter=${JSON.stringify(filter)}`);

  const createEmployee = useCallback(
    async (values) => {
      setSaving(true);
      if (selectedProfile?.profileType?.id === AGENT_TYPE_ID) {
        await mutate(
          {
            type: 'create',
            resource: 'agencies/agents',
            payload: { data: values },
          },
          {
            onSuccess: () => {
              redirectToValidations({ entity_type: 'agents' });
              setSaving(false);
            },
          },
        );
      } else {
        await mutate(
          {
            type: 'create',
            resource: `organizations/${values?.organization?.id}/employees`,
            payload: {
              data: {
                ...values,
                has_access: 1,
                workEmail: values?.workEmail && { email: values?.workEmail, privacyAttributes: [{ id: 1 }] },
                workPhones:
                  values?.work_phone_number &&
                  values?.work_phone_number.split(', ').map((pn) => ({
                    phone: pn,
                    privacyAttributes: [
                      {
                        id: 1,
                      },
                    ],
                  })),
              },
            },
          },
          {
            onSuccess: () => {
              setSaving(false);
              redirectToValidations({ entity_type: 'organizationEmployees' });
            },
          },
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutate, selectedProfile],
  );

  const handleModel = (e, profile) => {
    e.preventDefault();
    setIsModelOpen(true);
    setSelectedProfile(profile);
  };

  const [isOpenEmailPreferences, setOpenEmailPreferences] = useState(false);
  const [emailPreferences, setEmailPreferences] = useState({});
  const [reason, setReason] = useState(null);
  const handleMailSubscribe = (data) =>
    mutate(
      {
        type: 'update',
        resource: `users/${user?.id}/emailPreferences`,
        payload: {
          id: data?.id,
          data: {
            emailType: {
              id: data?.emailType?.id,
            },
            emailGroup: {
              id: data?.emailGroup?.id,
            },
            isSubscribed: !data?.isSubscribed,
            reason,
          },
        },
      },
      {
        onSuccess: () => {
          refresh();
          setOpenEmailPreferences(false);
          setReason(null);
          setEmailPreferences({});
        },
      },
    );

  return (
    <Show title={<Breadcrumbs {...props} />} {...props}>
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="on" />} className={classes.scrollable}>
        <Tab label="User Info">
          <TextField label="User ID" source="id" />
          <FunctionField
            render={(record) => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{record.email} </span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://${
                    window?.location?.hostname === 'admin.truelinked.net' ? 'truelinked' : 'truelinked-test'
                  }.chargebee.com/customers?view_code=all&Customers.search=${encodeURIComponent(record.email)}`}
                  title={`Search ${record.email} in Chargebee`}
                  style={{ margin: 'auto 10px' }}
                >
                  <svg height={24} width={24} viewBox="0 0 202 202" fill="#ff7a59" xmlns="http://www.w3.org/2000/svg">
                    <g fillRule="evenodd">
                      <path d="M170.6 143.8c-1.9-1.3-4.4-1.2-6.3.1-1.9 1.3-2.7 3.8-2 6 .7 2.2 2.7 3.8 5 3.9 2.3.1 4.4-1.2 5.3-3.3 1-2.4.1-5.2-2-6.7-2.5-1.6 2.5 1.7 0 0z" />
                      <path d="M100.7 194.7c1.2 0 2.5 0 3.7-.1 2.5-.1 5-.3 7.4-.6 2-.2-4.1.5 0 0 2.5-.3 4.5 2 4 4.5-.3 1.1-1.1 2-2.1 2.5-.7.3-1.4.3-2.1.4-.8.1-1.7.2-2.5.2-6.6.5-13.2.4-19.8-.3-6-.7-11.8-1.8-17.6-3.6-5.6-1.7-11-3.9-16.2-6.6-5.3-2.7-10.2-5.9-14.9-9.5-5.4-4-10.3-8.6-14.7-13.7-4.4-5-8.3-10.5-11.6-16.2-3.4-5.9-6.2-12.1-8.3-18.6-2.2-6.6-3.6-13.4-4.4-20.3-.8-7-.8-14.1-.1-21.1.7-6.8 2.1-13.7 4.3-20.3 2.1-6.5 4.8-12.7 8.2-18.7 3.3-5.9 7.3-11.4 11.7-16.5 4.4-5 9.3-9.6 14.6-13.7 5.4-4.1 11.2-7.7 17.2-10.6 6.2-3 12.7-5.3 19.4-7C83.7 3.2 90.7 2.3 97.7 2c7-.2 14.1.3 21 1.5 6.7 1.2 13.3 3.1 19.7 5.6 6.3 2.5 12.3 5.7 17.9 9.4 5.6 3.7 10.8 8 15.6 12.8 2 2 3.8 4 5.1 6.6 1.1 2.2 1.7 4.7 1.9 7.1.3 5-1.4 10.1-4.8 13.8-3.9 4.4-9.8 6.8-15.6 6.4-2.8-.2-5.6-1-8.1-2.4-1.3-.7-2.5-1.6-3.5-2.6-1.2-1.1-2.3-2.4-3.5-3.5-4.9-4.6-10.5-8.3-16.6-11.1-6.3-2.8-13-4.6-19.8-5.2-7-.6-14.1-.1-20.9 1.6-6.6 1.7-12.9 4.5-18.7 8.2-5.6 3.7-10.6 8.3-14.7 13.5-4.2 5.3-7.5 11.3-9.8 17.7-2.3 6.5-3.5 13.4-3.5 20.3 0 7 1.1 13.9 3.4 20.5 2.2 6.4 5.5 12.3 9.6 17.6 4.1 5.3 9.1 9.9 14.7 13.6 5.7 3.8 12 6.6 18.7 8.3 6.8 1.8 13.9 2.3 20.9 1.7 6.8-.6 13.5-2.3 19.8-5.1.7-.3 1.3-.7 2-.8.6-.1 1.2-.1 1.8.1 1.2.4 2.1 1.3 2.4 2.5.3 1.1.1 2.4-.7 3.3-.4.5-1 .8-1.6 1.1-.8.3-1.5.7-2.3 1-6.4 2.7-13.1 4.4-20 5-7 .7-14.1.3-20.9-1.1.9 1 1.7 2.1 2.5 3.2s1.6 2.3 2.3 3.4c.1.2.4.6.4.6h.3c.4 0 .7.1 1.1.1.7.1 1.4.1 2.1.2 2.9.2 5.8.2 8.7.1 5.7-.3 11.4-1.1 16.9-2.7 5.3-1.5 10.5-3.6 15.4-6.2 4.8-2.6 9.3-5.6 13.4-9.2l3-2.7c.9-.8 1.6-1.8 2.7-2.3 1.9-1 4.2-.7 5.8.6 1.7 1.3 2.4 3.5 1.9 5.6-.3 1.1-.8 2-1.6 2.7-1 .9-1.9 1.9-2.9 2.8-4 3.6-8.3 6.9-12.9 9.7-4.7 2.9-9.6 5.3-14.8 7.2-5.3 2-10.7 3.4-16.3 4.3-5.7.9-11.5 1.3-17.3 1.1.5 1.3.9 2.6 1.3 3.9.2.6.3 1.2.5 1.8 0 .2.2.7.2.7l.8.4z" />
                      <path d="M156.5 176.3c-5.6 4.2-11.7 7.8-18.1 10.6-3.2 1.4-6.5 2.6-9.9 3.7-1.7.5-3.4 1-5.1 1.4-.7.2-1.5.3-2.2.7-.6.3-1 .8-1.3 1.4-.7 1.3-.5 2.8.4 3.9 1 1.3 2.5 1.5 4 1.2 3.4-.8 6.8-1.8 10.1-3 6.6-2.3 13-5.4 19-9.1 1.5-.9 3-1.9 4.4-2.9.7-.5 1.5-1 2.2-1.5.6-.5 1.3-.9 1.8-1.6.8-1.1.8-2.7.1-3.9-.7-1.2-2.1-1.9-3.4-1.8-.8.3-1.5.5-2 .9-10.3 7.7 1.6-1.2 0 0zM168.6 165.5c-.9 1-1.9 2-2.9 3-.8.8-1.7 1.5-2.1 2.6-.6 2.1.8 4.3 2.9 4.6 1.3.2 2.4-.3 3.3-1.1 1-.9 2-1.9 2.9-2.9.8-.8 1.7-1.6 2-2.8.2-.9.1-2-.4-2.8-1.2-2-4.1-2.3-5.7-.6-1.3 1.4 1.3-1.4 0 0zM145.8 144.6c-2.3 2.4-4.7 4.6-7.3 6.6-.7.5-1.5 1.1-2.2 1.6-.6.4-1.1 1-1.4 1.7-.6 1.4-.2 3.1 1 4.1 1.4 1.2 3.2 1.1 4.7 0 1.4-1 2.8-2 4.1-3 1.2-1 2.4-2.1 3.6-3.2.7-.6 1.3-1.3 2-2 .6-.6 1.2-1.2 1.6-2 .6-1.6 0-3.5-1.5-4.4-1.5-.9-3.4-.6-4.6.6-3.5 3.7 1.4-1.5 0 0zM101.1 78.4c-4.9 0-9.7 1.5-13.7 4.4-3.9 2.8-6.9 6.7-8.5 11.2-1.7 4.6-1.9 9.8-.5 14.6 1.3 4.6 4 8.7 7.7 11.7 3.7 3.1 8.4 5 13.2 5.3 4.9.4 9.9-.8 14.1-3.4 4.1-2.5 7.3-6.1 9.3-10.5 2-4.5 2.6-9.6 1.6-14.5-.9-4.7-3.4-9.1-6.8-12.4-3.5-3.3-8-5.5-12.7-6.3-1.3 0-2.5-.1-3.7-.1z" />
                    </g>
                  </svg>
                </a>{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://app.hubspot.com/contacts/${window?.location?.hostname === 'admin.truelinked.net' ? '6012578' : '20261276'}/objects/0-1/views/all/list?query=${encodeURIComponent(
                    record.email,
                  )}`}
                  title={`Search ${record.email} in Hubspot`}
                >
                  <svg viewBox="0 0 242.04 257" height={24} fill="#ff7a59">
                    <path d="M162.79,101.25V82a14.84,14.84,0,0,0,8.56-13.38v-.45a14.87,14.87,0,0,0-14.83-14.83h-.45a14.87,14.87,0,0,0-14.83,14.83v.45A14.84,14.84,0,0,0,149.8,82v19.29a42.06,42.06,0,0,0-20,8.8L76.86,68.86a16.89,16.89,0,1,0-7.91,10.29L121,119.67a42.18,42.18,0,0,0,.65,47.56l-15.84,15.84a13.6,13.6,0,0,0-3.93-.64,13.75,13.75,0,1,0,13.75,13.75,13.56,13.56,0,0,0-.64-3.93l15.67-15.67a42.25,42.25,0,1,0,32.1-75.33m-6.49,63.42A21.68,21.68,0,1,1,178,143a21.68,21.68,0,0,1-21.68,21.68"></path>
                  </svg>
                </a>
              </div>
            )}
            label="Email"
          />
          <FunctionField label="Identity verification" render={(record) => (record.isVerified ? <VerifiedUserIcon /> : <span>No</span>)} />
          <TextField label="Name" source="name" />
          <TextField label="First name" source="firstName" />
          <TextField label="Last name" source="lastName" />
          <SelectField choices={languages} source="preferredLanguage" label="Prefered language" emptyText="(Not specified)" />

          <ReferenceManyField label="Profiles Created" reference="profiles" target="user_id">
            <SingleFieldList linkType="show">
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceManyField>

          <ReferenceManyField
            label={
              <div>
                Profile access{' '}
                {profilesCount > 3 ? (
                  // eslint-disable-next-line no-return-assign
                  <Button variant="text" color="primary" onClick={() => (window.location.href = `#/users/${userId}/show/5`)}>
                    View all profile accesses
                  </Button>
                ) : null}
              </div>
            }
            target="user_id"
            reference="profiles/profileaccesses"
            perPage={3}
          >
            <Datagrid>
              <TextField label="Id" source="profile.id" />

              <ReferenceField label="Profile" source="profile.id" reference="profiles" link="show">
                <TextField label="Name" source="name" emptyText="Empty" />
              </ReferenceField>

              <ReferenceField label="Profile type" source="profile.id" reference="profiles" link="show">
                <TextField label="Profile Type" source="profileType.name" />
              </ReferenceField>

              <ReferenceField label="Agencies" source="profile.id" reference="profiles" link={false}>
                <FunctionField
                  label="Agencies"
                  render={(profile) => {
                    if (!profile?.agencies?.length && profile?.profileType?.id === AGENT_TYPE_ID) {
                      return (
                        <Typography className={classes.add} variant="subtitle2" onClick={(e) => handleModel(e, profile)}>
                          + Agent
                        </Typography>
                      );
                    }

                    return profile?.agencies.map((agency) => (
                      <ProfileAgencyLink
                        showChip
                        isDelete={profile?.profileType?.id === AGENT_TYPE_ID}
                        record={agency}
                        onDelete={() => {
                          dataProvider
                            .getList('agencies/agents', { filter: { all: true, user_id: userId, profile_id: profile?.id, agency_id: agency?.id }, pagination: {}, sort: {} })
                            .then(({ data }) => {
                              setAgent(lodash.head(data));
                              setIsDelete(true);
                              setSelectedProfile(profile);
                              setEmployee({});
                            });
                        }}
                      />
                    ));
                  }}
                />
              </ReferenceField>
              <ReferenceField label="Organizations" source="profile.id" reference="profiles" link={false}>
                <FunctionField
                  label="Organizations"
                  render={(profile) => {
                    if (!profile?.organizations?.length && profile?.profileType?.id === AOS_TYPE_ID) {
                      return (
                        <Typography
                          className={classes.add}
                          variant="subtitle2"
                          onClick={(e) => {
                            handleModel(e, profile);
                          }}
                        >
                          + Employee
                        </Typography>
                      );
                    }

                    return profile?.organizations?.map((organization) => (
                      <ProfileOrganizationLink
                        showChip
                        isDelete={profile?.profileType?.id === AOS_TYPE_ID}
                        record={organization}
                        onDelete={() => {
                          dataProvider
                            .getList('organizations/employees', {
                              filter: { all: true, user_id: userId, profile_id: profile?.id, id: organization?.id, validation_status: '' },
                              pagination: {},
                              sort: {},
                            })
                            .then(({ data }) => {
                              setEmployee(lodash.head(data));
                              setIsDelete(true);
                              setSelectedProfile(profile);
                              setAgent({});
                            });
                        }}
                      />
                    ));
                  }}
                />
              </ReferenceField>
            </Datagrid>
          </ReferenceManyField>

          <ReferenceManyField label="Pending Profile Access" reference="profiles/profileaccessrequests" target="user_id" pagination={<Pagination />}>
            <Datagrid rowClick="show">
              <TextField source="profile.id" label="Profile Id" />
              <TextField source="profile.name" label="Profile name" />
              <CustomDateField source="created_at" timeHidden />
              <TextField source="status" label="Status" />
              <FunctionField
                label="Actions"
                render={(p) => (
                  <Button
                    color="secondary"
                    variant="contained"
                    size="small"
                    disabled={p?.status !== 'pending'}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleProfileWithAccess(p.id, 'accepted');
                    }}
                  >
                    Accept
                  </Button>
                )}
              />
              <FunctionField
                render={(p) => (
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    disabled={p?.status !== 'pending'}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleProfileWithAccess(p.id, 'rejected');
                    }}
                  >
                    Reject
                  </Button>
                )}
              />
            </Datagrid>
          </ReferenceManyField>

          <ProfileDropdown {...props} />

          <ReferenceManyField label="Subscriptions" reference="users/subscriptions" target="user_id" filter={{ user_id: props.id }}>
            <div>
              <Datagrid rowClick="show/3">
                <TextField source="subscription.product.name" label="Name" />
                <TextField source="status" label="Status" />
              </Datagrid>
            </div>
          </ReferenceManyField>

          <ReferenceManyField label="Invoices" reference="invoices" target="user_id" filter={{ user_id: props.id, limit: 1 }} perPage={1}>
            <div>
              <Datagrid rowClick="show/4">
                <TextField source="number" />
                <TextField source="status" />
                <FunctionField label="Amount" render={(invoice) => `€${parseFloat(invoice.totalPriceInclVat.amount).toFixed(2)}`} />
                <DateField source="issueDate" />
              </Datagrid>
            </div>
          </ReferenceManyField>

          <ReferenceManyField label="Auth logs" reference="auth/logs" perPage={3} filter={{ sort: '-timestamp', user_id: props.id }} target="user_id">
            <Datagrid rowClick="show/9">
              <CustomDateField source="timestamp" />
              <TextField source="status" />
            </Datagrid>
          </ReferenceManyField>

          <CustomDateField source="createdAt" label="User created at:" />

          <CreateUserProfileButton record={{ id: props.id }} />
          <CreateProfileAccessButton record={{ user: { id: props.id } }} />
          <SendWelcomeEmailButton />
          <Dialog onClose={() => setIsModelOpen(false)} open={isModelOpen}>
            <DialogContent>
              <SimpleForm save={createEmployee} saving={isSaving}>
                {selectedProfile?.profileType?.id === AGENT_TYPE_ID && <Typography variant="h6">Add agency team member</Typography>}
                {selectedProfile?.profileType?.id === AOS_TYPE_ID && <Typography variant="h6">Add organization team member</Typography>}
                <ReferenceInput label="User" source="user.id" reference="users" filter={{ id: userId }} defaultValue={userId}>
                  <SelectInput optionText={(userInfo) => `(${userInfo.id}) - ${userInfo.email} - ${userInfo.name}`} />
                </ReferenceInput>
                {selectedProfile?.profileType?.id === AOS_TYPE_ID && <TextInput source="role" label="Employee role" />}
                <TextInput source="workEmail" label="Work email" />
                {selectedProfile?.profileType?.id === AOS_TYPE_ID && <TextInput source="work_phone_number" label="Work phone number" />}
                <ReferenceInput
                  label="Profiles"
                  source={selectedProfile?.profileType?.id === AGENT_TYPE_ID ? 'agent.profile.id' : 'profile.id'}
                  reference="profiles"
                  filter={{ access_user_id: userId, id: selectedProfile?.id }}
                  defaultValue={selectedProfile?.id}
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
                {selectedProfile?.profileType?.id === AGENT_TYPE_ID && (
                  <ReferenceInput label="Agency" filter={{ limit: 25 }} source="agency.id" reference="agencies" shouldRenderSuggestions={false} filterToQuery={(searchText) => ({ query: searchText })}>
                    <AutocompleteInput
                      optionText="name"
                      options={{
                        suggestionsContainerProps: {
                          className: classes.suggestionsContainerOpen,
                        },
                      }}
                    />
                  </ReferenceInput>
                )}
                {selectedProfile?.profileType?.id === AOS_TYPE_ID && <TextInput source="firstName" label="First name" defaultValue={user?.firstName} />}
                {selectedProfile?.profileType?.id === AOS_TYPE_ID && <TextInput source="lastName" label="Last name" defaultValue={user?.lastName} />}
                {selectedProfile?.profileType?.id === AOS_TYPE_ID && <TextInput source="accessEmail" label="Access email" defaultValue={user?.email} />}
                {selectedProfile?.profileType?.id === AOS_TYPE_ID && (
                  <ReferenceInput
                    label="Organization"
                    filter={{ limit: 25 }}
                    source="organization.id"
                    reference="organizations"
                    shouldRenderSuggestions={false}
                    filterToQuery={(searchText) => ({ query: searchText })}
                  >
                    <AutocompleteInput
                      options={{
                        suggestionsContainerProps: {
                          className: classes.suggestionsContainerOpen,
                        },
                      }}
                      optionText="name"
                    />
                  </ReferenceInput>
                )}
                {selectedProfile?.profileType?.id === AGENT_TYPE_ID && (
                  <SelectInput
                    source="status"
                    defaultValue="agent"
                    choices={[
                      { id: 'agent', name: 'Agent' },
                      { id: 'admin', name: 'Admin' },
                    ]}
                  />
                )}
                {selectedProfile?.profileType?.id === AOS_TYPE_ID && <BooleanInput source="sendMail" label="Send email to user" defaultValue={false} />}
              </SimpleForm>
            </DialogContent>
          </Dialog>
          <Confirm
            isOpen={isDelete}
            title={
              !lodash.isEmpty(agent)
                ? `Delete agent #${agent?.agency?.name} #${agent?.id}`
                : selectedProfile?.profileType?.id === AOS_TYPE_ID
                ? `Delete employee #${employee?.organization?.name} #${employee?.id}`
                : ''
            }
            content="Are you sure you want to delete this item?"
            onConfirm={() => {
              if (!lodash.isEmpty(agent)) {
                dataProvider.delete('agencies/agents', { id: agent?.id }).then(() => {
                  setIsDelete(false);
                  refresh();
                });
              } else {
                dataProvider.delete('organizations/employees', { id: employee?.id }).then(() => {
                  setIsDelete(false);
                  refresh();
                });
              }
            }}
            onClose={() => setIsDelete(false)}
          />
        </Tab>

        <Tab label="CueTV">
          <ReferenceManyField reference="cuetv/subscriptions" target="user_id">
            <Datagrid>
              <FunctionField label="Subscription id" render={(subscription) => <Link to={`/cuetv/subscriptions/${subscription.id}/show`}>#{subscription.id}</Link>} />
              <FunctionField
                label="Customer id"
                render={(subscription) => (subscription.cuetvCustomer ? <Link to={`/cuetv/customers/all/${subscription.cuetvCustomer.id}/show`}>#{subscription.cuetvCustomer.id}</Link> : 'Empty')}
              />

              <FunctionField label="CueTV subscription" render={() => 'Yes'} />
              <TextField label="Country" source="cuetvCustomer.country_reference" />
              <TextField label="Subscription start date" source="subscription_start_date" emptyText="Empty / Unknown" />
              <TextField label="Subscription end date" source="subscription_renew_date" emptyText="Empty / Unknown" />
              <FunctionField
                label="Subscription source"
                render={(subscription) => {
                  if (!subscription.cuetvCustomer || !subscription.cuetvCustomer.last_action_type) {
                    return 'Empty / Unknown';
                  }
                  switch (subscription.cuetvCustomer.last_action_type) {
                    case 'gift':
                      return 'Gifted';
                    case 'fulfillment':
                      return 'Purchased';
                    default:
                      return `Other: "${subscription.cuetvCustomer.last_action_type}"`;
                  }
                }}
              />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Billing Info">
          <TextField source="invoiceNotes" emptyText="N/A" />
          <ArrayField source="billingAddresses">
            <Datagrid>
              <TextField label="ID" source="id" />
              <TextField source="firstName" />
              <TextField source="lastName" />
              <TextField source="email" />
              <BooleanField label="Default" source="isDefault" />
              <TextField label="Country" source="country.name" />
              <TextField label="Zip" source="zipcode" />
              <TextField source="companyName" />
              <TextField label="VAT number" source="cvrNumber" />
            </Datagrid>
          </ArrayField>
        </Tab>

        <Tab label="Subscriptions">
          <CreateUserSubscriptionButton />
          <ReferenceManyField label="Subscriptions" reference="users/subscriptions" target="user_id" perPage={10} sort={{ field: 'subscriptionType' }} pagination={<Pagination />}>
            <SubscriptionsTabContent />
          </ReferenceManyField>
          <CreateCouponCodeButton entityType="user" />
          <ReferenceManyField label="Coupon codes" reference="coupons/codes" target="user_id" pagination={<Pagination />}>
            <Datagrid>
              <TextField source="id" />
              <TextField source="code" />
              <TextField source="status" />
              <TextField source="email" />
              <FunctionField label="Coupon set" render={(item) => (item?.couponSet ? <Link to={`/coupons/sets/${item.couponSet.id}/show/1`}>{item.couponSet.name}</Link> : 'Unassigned')} />
              <FunctionField
                label="Subscription"
                render={(item) =>
                  item?.userSubscription ? <Link to={`/users/subscriptions/${item.userSubscription.id}/show`}>{item.userSubscription?.subscription?.description}</Link> : 'Unassigned'
                }
              />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Payment">
          <ReferenceManyField label="Payment Methods" reference="users/paymentmethods" target="user_id">
            <Datagrid rowClick="show">
              <TextField source="id" label="ID" />
              <FunctionField label="Method Type" render={paymentWalletsDescriptions} />
              <TextField source="payment_method_reference" label="Value" />
              <TextField source="status" label="Status" />
              <BooleanField source="is_default" label="Is default method" />
              <CustomDateField label="Added at" source="created_at" timeHidden />
              <FunctionField label="Expiration" render={(pm) => (pm.expiration_month || pm.expiration_year ? `${pm.expiration_month} / ${pm.expiration_year}` : 'NA')} />
              <FunctionField label="Remove" render={(pm) => (pm ? <DeleteWithConfirmButton record={pm} undoable={false} redirect={false} /> : null)} />
            </Datagrid>
          </ReferenceManyField>

          <ReferenceManyField label="Unbilled charges" reference="users/subscriptions/unbilledCharges" pagination={<Pagination />} perPage={10} target="user_id">
            <UnbilledChargesDataGrid />
          </ReferenceManyField>

          <ReferenceManyField label="Invoices" reference="invoices" pagination={<Pagination />} target="user_id" perPage={10}>
            <InvoicesDataGrid />
          </ReferenceManyField>

          <ReferenceManyField label="Credit notes" reference="invoices/creditNotes" pagination={<Pagination />} perPage={10} target="user_id">
            <CreditNotesDataGrid />
          </ReferenceManyField>
        </Tab>

        <Tab label="Profile access">
          <RequestsTabs />
        </Tab>

        <Tab label="Settings">
          {settings && (
            <Button variant="outlined" onClick={() => setConsolidateConfirmOpen(true)}>
              {consolidatedInvoicing ? 'Disable' : 'Enable'} consolidated invoicing
            </Button>
          )}
          <br />
          <Button variant="outlined" onClick={() => setResetConfirmOpen(true)}>
            Send reset password email
          </Button>
          <Confirm
            isOpen={!!resetConfirmOpen}
            content={`An email with instructions will be sent to ${user?.email}. User will be required to follow instructions on the email.`}
            onClose={() => setResetConfirmOpen(false)}
            onConfirm={() => {
              sendResetPassword();
              setResetConfirmOpen(false);
            }}
            title="Confirm email submission"
          />
          <Confirm
            isOpen={!!consolidateConfirmOpen}
            content={`User ${user?.email} will ${settings.consolidated_invoicing?.value ? 'stop' : 'start'} getting consolidated invoices.`}
            onClose={() => setConsolidateConfirmOpen(false)}
            onConfirm={() => {
              sendEnableConsolidatedInvoicing();
              setConsolidateConfirmOpen(false);
            }}
            title="Confirm email submission"
          />
          <ReferenceManyField reference="users/settings" target="user_id">
            <UserSettings />
          </ReferenceManyField>
          <UserTrialStatusControl />
        </Tab>

        <Tab label="Permissions">
          <CreateUserGroupButton />
          <FunctionField label="Identity verification" render={(record) => <IdentityVerificationStatus record={record} />} />
          <FunctionField label="Suspicious" render={(record) => <SuspiciousStatus record={record} />} />
          <ReferenceManyField label="User Groups" reference="users/groups" target="user_id">
            <Datagrid rowClick="show">
              <TextField source="id" label="ID" />
              <TextField source="group.name" label="Name" />
              <DeleteWithConfirmButton undoable={false} redirect={false} {...props} />
            </Datagrid>
          </ReferenceManyField>

          <UserCustomPermissionsList {...props} />

          <ReferenceManyField label="Permissions" reference="users/permissions" target="user_id">
            <Datagrid>
              <TextField source="id" label="id" />
              <TextField source="name" label="Name" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Gift Subscription">
          <GiftSubscriptionTab />
        </Tab>

        <Tab label="Mails">
          <ReferenceManyField label="User mails" reference="mails" source="email" target="email" perPage={10} pagination={<Pagination />}>
            <Datagrid rowClick="show">
              <TextField source="id" label="ID" />
              <TextField source="subject" label="Subject" />
              <TextField source="createdAt" label="Date sent" />
              <TextField source="latestLog.status.name" label="Latest status" emptyText="(Unknown)" />
              <TextField source="latestLog.createdAt" label="Latest status date" emptyText="(Unknown)" />
            </Datagrid>
          </ReferenceManyField>
          <ReferenceManyField
            label="User mail subscription history"
            reference="mails/logs"
            target="user_id"
            filter={{ status_id: [MAIL_STATUS_UNSUBSCRIBED, MAIL_STATUS_RESUBSCRIBED] }}
            perPage={10}
            pagination={<Pagination />}
          >
            <Datagrid>
              <TextField source="id" />
              <TextField source="createdAt" label="Date" />
              <TextField source="status.name" label="Status" emptyText="(Unknown)" />
              <TextField source="client_data" label="Client metadata" emptyText="No metadata" />
            </Datagrid>
          </ReferenceManyField>
          <ReferenceManyField label="Email preferences" reference="users/emailPreferences" source="id" target="user_id" perPage={25} pagination={<Pagination />}>
            <Datagrid>
              <TextField source="id" label="ID" sortable={false} />
              <TextField source="emailGroup.name" label="Email group" sortable={false} />
              <TextField source="emailType.name" label="Email type" sortable={false} />
              <TextField source="isSubscribed" name="Is subscribed" />
              <FunctionField
                label="Action"
                render={(record) => (
                  <Button
                    style={{ textTransform: 'capitalize' }}
                    size="small"
                    variant="contained"
                    onClick={() => {
                      setOpenEmailPreferences(true);
                      setEmailPreferences(record);
                    }}
                  >
                    {record?.isSubscribed ? 'Unsubscribe' : 'Subscribe'}
                  </Button>
                )}
              />
            </Datagrid>
          </ReferenceManyField>
          <Dialog
            PaperProps={{
              className: classes.dialog,
            }}
            open={isOpenEmailPreferences}
            onClose={() => setOpenEmailPreferences(false)}
          >
            <DialogContent>
              <DialogTitle style={{ padding: 0, paddingBottom: 10 }}>Update email preference</DialogTitle>
              <MUITextField label="Reason" fullWidth type="text" value={reason} onChange={(e) => setReason(e.target.value)} />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenEmailPreferences(false)}>Close</Button>
              <Button onClick={() => handleMailSubscribe(emailPreferences)} color="primary">
                {emailPreferences?.isSubscribed ? 'Unsubscribe' : 'Subscribe'}
              </Button>
            </DialogActions>
          </Dialog>
        </Tab>

        <Tab label="Log">
          <ReferenceManyField
            label="User Authorization"
            reference="activities"
            filter={{ activity_type: ['user_created', 'user_auth', 'forgot_password'] }}
            target="user_id"
            perPage={10}
            pagination={<Pagination />}
          >
            <Datagrid>
              <TextField source="id" label="ID" />s
              <CustomDateField source="createdAt" label="Timestamp" />
              <TextField source="metaInfo.status" label="Status" />
              <TextField source="metaInfo.email" label="Email" />
              <TextField source="metaInfo.application" label="Application" emptyText="V2" />
              <TextField source="metaInfo.ip" label="IP Address" emptyText="::" />
              <TextField source="activityType" label="Type" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Fingerprint">
          <Fingerprint {...props} user={user} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const UserEdit = (props) => {
  const languages = useLanguageQuery();
  const classes = useStyles();

  return (
    <Edit {...props} title={<Breadcrumbs {...props} />}>
      <TabbedForm redirect="show" toolbar={<CustomToolbar />}>
        <FormTab label="summary">
          <Grid container wrap="wrap" style={{ width: '100%' }}>
            <Grid item xs={3}>
              <TextInput disabled label="Id" source="id" editable="false" />
            </Grid>
            <Grid item xs={3}>
              <TextInput disabled label="Email" source="email" editable="false" />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={3}>
              <TextInput disabled label="Name" source="name" editable="false" />
            </Grid>
            <Grid item xs={3}>
              <TextInput label="First name" source="firstName" />
            </Grid>
            <Grid item xs={3}>
              <TextInput label="Last name" source="lastName" />
            </Grid>
            <Grid item xs={3}>
              <TextInput label="Phone Number" source="phone.number" />
            </Grid>
            <Grid item xs={3}>
              <ReferenceInput label="Payment methods" source="paymentMethod.id" reference="users/paymentMethods" filterToQuery={(searchText) => ({ query: searchText })}>
                <SelectInput
                  optionText={(record) => `${record?.paymentMethodType?.name} (${record?.wallet_type}): ${record.payment_method_reference} ${record.expiration_month}/${record.expiration_year}`}
                />
              </ReferenceInput>
            </Grid>
            <Grid item xs={3}>
              <SelectInput choices={languages} source="preferredLanguage" label="Prefered language" emptyText="Empty" />
            </Grid>
          </Grid>
        </FormTab>

        <FormTab label="Billing">
          <TextInput source="invoiceNotes" resettable={true} />
          <ArrayInput source="billingAddresses" className={classes.arrayInput}>
            <SimpleFormIterator>
              <TextInput disabled={true} label="ID" source="id" />
              <TextInput resettable={true} label="First name" source="firstName" />
              <TextInput resettable={true} label="Last name" source="lastName" />
              <TextInput resettable={true} label="Email" source="email" />
              <TextInput resettable={true} label="Phone" source="phone" />
              <ReferenceInput
                allowEmpty={true}
                label="Country"
                source="country.id"
                reference="countries"
                filterToQuery={(searchText) => ({ query: searchText })}
                sort={{ field: 'name', order: 'ASC' }}
              >
                <AutocompleteInput resettable={true} optionText="name" />
              </ReferenceInput>
              <TextInput label="State" source="state" resettable={true} />
              <FormDataConsumer>
                {({ getSource, scopedFormData }) => (
                  <ReferenceInput
                    allowEmpty={true}
                    label="City"
                    source={getSource('city.id')}
                    reference="countries/cities"
                    filterToQuery={(searchText) => ({ query: searchText })}
                    sort={{ field: 'name', order: 'ASC' }}
                    filter={{ country_id: scopedFormData?.country?.id }}
                    style={{ width: 255 }}
                  >
                    <AutocompleteInput
                      resettable={true}
                      optionText={(option) => `${option.name} ${option.areas ? `(${option.areas.map((area) => area.name).join(', ')})` : ''}`}
                      matchSuggestion={() => true}
                    />
                  </ReferenceInput>
                )}
              </FormDataConsumer>
              <TextInput resettable={true} label="Zip" source="zipcode" />
              <TextInput resettable={true} label="Address line 1" source="address" />
              <TextInput resettable={true} label="Address line 2" source="address2" />
              <TextInput resettable={true} label="Address line 3" source="address3" />
              <TextInput resettable={true} label="Key/Label" source="label" />
              <TextInput resettable={true} label="Organization name" source="companyName" />
              <TextInput resettable={true} label="CVR/VAT number" source="cvrNumber" />
              <BooleanInput label="Default" source="isDefault" className={classes.arrayInputRightSection} />
              <BooleanInput label="Send billing emails" source="sendBillingEmails" className={classes.arrayInputRightSection} style={{ top: 50 }} />
              <BooleanInput label="Send subscription emails" source="sendAccountEmails" className={classes.arrayInputRightSection} style={{ top: 100 }} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const UserCreateForm = ({ save, ...other }) =>
  SimpleForm({
    ...other,
    save: (record) =>
      save({
        password: null,
        ...record,
      }),
  });

export const UserCreate = (props) => (
  <Create {...props} toolbar={<CustomToolbar />}>
    <UserCreateForm redirect="show">
      <TextInput label="Email" source="email" required />
      <TextInput label="First name" source="firstName" required />
      <TextInput label="Last name" source="lastName" required />
      <TextInput label="Phone Number" source="phone.number" />
      <TextInput label="Address" source="billing.address" />
      <TextInput label="Zip code" source="billing.zipCode" />
      <TextInput label="City" source="billing.city" />
      <TextInput label="Country" source="billing.country.name" />
      <BooleanInput label={`Send welcome email (with "set new password" link)`} source="welcomeEmail" />
    </UserCreateForm>
  </Create>
);

export const UserList = (props) => (
  <List exporter={false} title={<Breadcrumbs {...props} />} filters={<UserFilter />} bulkActionButtons={false} {...props} sort={{ field: 'id', order: 'DESC' }} perPage={25}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <CustomDateField source="createdAt" sortable={false} />
      <TextField source="name" />
      <TextField source="email" />
      <TextField source="phone.number" />
      <TextField source="preferredLanguage" sortable={false} />
      <CustomDateField source="createdAt" sortable={false} />
      <CustomDateField source="updatedAt" sortable={false} />
    </Datagrid>
  </List>
);

export const UserGroupShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField label="User" source="user.id" reference="users" link="show">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="group.name" />
    </SimpleShowLayout>
  </Show>
);

export const UserGroupsCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput label="Users" source="user.id" reference="users" filterToQuery={(searchText) => ({ query: searchText })}>
        <SelectInput optionText="email" />
      </ReferenceInput>

      <ReferenceInput label="Group" filter={{ limit: 25 }} source="group.id" reference="groups" shouldRenderSuggestions={false} filterToQuery={(searchText) => ({ query: searchText })}>
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export const AgencyAccessActions = ({ record }) => {
  const id = record?.id;
  const type = 'update';
  const resource = 'agencies/access_requests';
  const [accept, { loading: acceptLoading }] = useMutation({ type, resource, payload: { id, data: { status: true } } });
  const [reject, { loading: rejectLoading }] = useMutation({ type, resource, payload: { id, data: { status: false } } });
  const disabled = acceptLoading || rejectLoading || record?.status !== null;

  return (
    <ButtonGroup>
      <Button variant="contained" onClick={accept} disabled={disabled}>
        Accept
      </Button>

      <Button variant="contained" onClick={reject} disabled={disabled}>
        Reject
      </Button>
    </ButtonGroup>
  );
};

export const ProfileAccessRequestsActions = ({ record }) => {
  const id = record?.id;
  const type = 'update';
  const resource = 'profiles/profileAccessRequests';
  const [accept, { loading: acceptLoading }] = useMutation({ type, resource, payload: { id, data: { status: 'accepted' } } });
  const [reject, { loading: rejectLoading }] = useMutation({ type, resource, payload: { id, data: { status: 'rejected' } } });
  const disabled = acceptLoading || rejectLoading || record?.status !== 'pending';

  return (
    <ButtonGroup>
      <Button variant="contained" onClick={accept} disabled={disabled}>
        Accept
      </Button>

      <Button variant="contained" onClick={reject} disabled={disabled}>
        Reject
      </Button>
    </ButtonGroup>
  );
};

export const ProfileAccessCreate = (props) => {
  const userRestricted = props.userRestricted || props.location?.state?.record.userRestricted;
  return (
    <Create {...props}>
      <SimpleForm redirect={`/users/${props.location?.state?.record.user.id}/show`}>
        <Typography variant="h6">Add access to profile</Typography>
        <Divider />

        <ReferenceInput label="User" source="user.id" reference="users" disabled={userRestricted} filterToQuery={(searchText) => ({ query: searchText })}>
          {!userRestricted ? (
            <AutocompleteInput optionText={(user) => `(${user.id}) - ${user.email} - ${user.name}`} />
          ) : (
            <SelectInput optionText={(user) => `(${user.id}) - ${user.email} - ${user.name}`} />
          )}
        </ReferenceInput>

        <ReferenceInput label="Profiles" source="profile.id" reference="profiles" filterToQuery={(searchText) => ({ query: searchText })}>
          <AutocompleteInput optionText={renderProfile} />
        </ReferenceInput>
        <BooleanInput source="sendEmail" label="Send email to user" defaultValue={false} />
      </SimpleForm>
    </Create>
  );
};

export const UserPermissionShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField label="User" source="user.id" reference="users" link="show">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="group.name" />
    </SimpleShowLayout>
  </Show>
);

export const UserCustomPermissionsList = (props) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [profileSubscriptions, setProfileSubscriptions] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState({});
  const [profilePermissions, setProfilePermissions] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const userId = props.id;

  useEffect(() => {
    dataProvider.getList('profiles/profileaccesses', { filter: { user_id: userId }, pagination: { perPage: 1000 }, sort: {} }).then(({ data }) => {
      const profilesIds = data.map((p) => p?.profile?.id).filter(Boolean);

      if (!profilesIds?.length) {
        setIsLoading(false);
      }

      dataProvider.getMany('profiles', { ids: profilesIds, pagination: { perPage: 1000 }, sort: {} }).then(({ data: dataProfiles }) => {
        if (dataProfiles) {
          setProfiles(dataProfiles);
          if (dataProfiles.length) {
            setSelectedProfile(dataProfiles[0]);
            setIsLoading(false);
          }
        }
      });
    });

    apiClient.get(`users/${userId}/permissions`).then(({ data }) => setUserPermissions(data?.data));
  }, [dataProvider, userId]);

  useEffect(() => {
    if (selectedProfile?.id) {
      const isOrganizationEmployee = selectedProfile?.organization?.[0]?.status === 'employee';
      const isAgent = selectedProfile?.agencies?.[0]?.status === 'agent';

      const filter = {
        ...(isOrganizationEmployee && { organization_id: selectedProfile?.organizations?.[0]?.organization?.id }),
        ...(isAgent && { agency_id: selectedProfile?.agencies?.[0]?.id }),
        ...(!isAgent && !isOrganizationEmployee && { profile_id: selectedProfile.id }),
      };

      dataProvider
        .getList('users/subscriptions', { filter, pagination: {}, sort: {} })
        .then(({ data }) =>
          setProfileSubscriptions(data.filter((s) => [SUBSCRIPTION_STATUS.ACTIVE, SUBSCRIPTION_STATUS.IN_TRIAL, SUBSCRIPTION_STATUS.NON_RENEWING, SUBSCRIPTION_STATUS.CANCELLED].includes(s?.status))),
        );

      apiClient.get(`profiles/${selectedProfile?.id}/permissions`).then(({ data }) => setProfilePermissions(data?.data));
    }
  }, [dataProvider, selectedProfile]);

  if ((!profiles || !profiles.length) && !isLoading) {
    return <p>No profiles</p>;
  }

  return (
    <div className={classes.customPermission}>
      <ProfilesFilter
        classes={classes}
        selectedProfile={selectedProfile}
        options={profiles}
        onChange={(profile) => {
          setSelectedProfile(profile);
        }}
      />
      {!isLoading ? (
        <PermissionsView profileSubscriptions={profileSubscriptions} selectedProfile={selectedProfile} userPermissions={userPermissions} profilePermissions={profilePermissions} />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

const ProfilesFilter = ({ selectedProfile, onChange, options }) => {
  const renderOption = (option) => {
    let renderText = `${option.name} (${option.id})`;

    switch (option?.profileType?.id) {
      case AGENT_TYPE_ID:
        renderText = option.agencies.length ? `[${option?.agencies[0]?.name}] ${option.name} (${option.id})` : renderText;

        return renderText;
      case AOS_TYPE_ID:
        if (option?.organizations?.length) {
          return `[${option?.organizations?.[0]?.organization?.name}] ${option.name} (${option?.organizations?.[0]?.organization?.id})`;
        }

        renderText = option?.organizations?.length ? `[${option?.organizations?.[0]?.organization?.name}] ${option.name} (${option.id})` : renderText;

        return renderText;
      default:
        return renderText;
    }
  };

  return (
    <FormControl variant="filled">
      <Autocomplete
        style={{ width: 400 }}
        options={options}
        autoComplete
        value={selectedProfile}
        onChange={(event, profile) => onChange(profile)}
        renderInput={(params) => <MUITextField {...params} fullWidth variant="filled" label="Search profile" />}
        renderOption={(option) => renderOption(option)}
        getOptionLabel={(option) => renderOption(option)}
      />
    </FormControl>
  );
};

export default UserList;
