export const TASK_QUEUES = [
  'task_queues/artistFreeTrials',
  'task_queues/artistRenewals',
  'task_queues/missingSeason',
  'task_queues/missingProducer',
  'task_queues/missingCanonical',
  'task_queues/multipleCast',
  'task_queues/artistsWithoutProfileAccess',
  'task_queues/concertVarious',
  'task_queues/magazine_export',
  'task_queues/data_qc',
  'task_queues/missing_program',
  'task_queues/wrong_profession',
  'task_queues/agency_artist_enrichment',
  'task_queues/external_reference_validations',
];
