import React from 'react';
import { List, Filter, SelectInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { DynamicGridFields } from './MetricDBViewModel';
import { ENTITY_DUPLICATES_VIEWS } from '../constants/validations';

const EntityDuplicatesFilter = (props) => {
  return (
    <Filter {...props}>
      <SelectInput source="view_id" choices={ENTITY_DUPLICATES_VIEWS} alwaysOn />
      <ReferenceInput label="Profession" source="profession_ids" reference="professions" resettable filterToQuery={(searchText) => ({ query: searchText })} perPage={50} alwaysOn>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput label="Locked by" source="locked_by" reference="users" perPage={25} resettable filter={{ group_id: 3 }} filterToQuery={(searchText) => ({ query: searchText })} alwaysOn>
        <AutocompleteInput optionText="email" />
      </ReferenceInput>
    </Filter>
  );
};

export const EntityDuplicatesList = (props) => (
  <List {...props} title={<Breadcrumbs {...props} />} perPage={10} exporter={false} filters={<EntityDuplicatesFilter />} filterDefaultValues={{ view_id: ENTITY_DUPLICATES_VIEWS?.[0]?.id }}>
    <DynamicGridFields {...props} isLock doNotMerge />
  </List>
);

export default EntityDuplicatesList;
