import React, { useState } from 'react';
import { Button, Confirm } from 'react-admin';

const ButtonWithConfirmation = ({
  label,
  resource,
  handleConfirm,
  onClick,
  content,
  title,
  classButtonWrapper,
  confirmDisabled,
  disabled,
  ...rest
}) => {
  const [dialogIsOpen, setDialogOpen] = useState(false);

  const handleClick = () => {
    if (!disabled) {
      onClick();
      setDialogOpen(true);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleConfirmClick = () => {
    handleConfirm();
    setDialogOpen(false);
  };

  return (
    <div onClick={handleClick} className={classButtonWrapper}>
      <Button {...rest} onClick={handleClick} label={label} disabled={disabled} />
      <Confirm
        isOpen={dialogIsOpen}
        title={title}
        content={content}
        onConfirm={handleConfirmClick}
        onClose={handleDialogClose}
        loading={confirmDisabled}
      />
    </div>
  );
};

ButtonWithConfirmation.defaultProps = {
  onClick: () => {},
};

export default ButtonWithConfirmation;
