import { get } from 'lodash';

const roundNumber = (num) => {
  if (num === null || Number.isNaN(num)) {
    return 'N/A';
  }
  if (Math.abs(num % 1) > 0) {
    return parseFloat(num).toFixed(2);
  }
  return parseFloat(num);
};

export const roundDiff = (num) => {
  if (num < 0) return roundNumber(num);
  if (num > 0) return '+'.concat(roundNumber(num));

  return '';
};

export const getMaxValue = (data, dataKeys) => {
  let maxValue = -Infinity;

  data.forEach((item) => {
    let rowSum = 0;
    dataKeys.forEach((keyObj) => {
      const { key } = keyObj;
      rowSum += get(item, key);
    });

    if (rowSum > maxValue) {
      maxValue = rowSum;
    }
  });

  return maxValue;
};
