import React from 'react';

import { ProductionInformation, parseProductionInformation } from './ProductionInformation';
import { ProductionPosterTrailer, parseProductionPosterTrailer } from './ProductionPosterTrailer';
import { ProductionTickets, parseProductionTickets } from './ProductionTickets';
import { ProductionDates, parseProductionDates } from './ProductionDates';
import { ProductionCast, parseProductionCast } from './ProductionCast';
import { ProductionImages, parseProductionImages } from './ProductionImages';
import { ProductionVideos, parseProductionVideos } from './ProductionVideos';
import { ProductionReviews, parseProductionReviews } from './ProductionReviews';
import { ProductionSynopsis, parseProductionSynopsis } from './ProductionSynopsis';
import { ProductionBooklet, parseProductionBooklet } from './ProductionBooklet';

import { PRODUCTION_VALIDATION_SECTIONS_IDS } from '../../constants/validations';

export const parseProductionSection = (sectionKey, props) => {
  switch (sectionKey) {
    case PRODUCTION_VALIDATION_SECTIONS_IDS.PRODUCTION_INFORMATION:
      return parseProductionInformation(props);
    case PRODUCTION_VALIDATION_SECTIONS_IDS.PRODUCTION_POSTER:
      return parseProductionPosterTrailer(props);
    case PRODUCTION_VALIDATION_SECTIONS_IDS.TICKETS_SALE_DATES:
      return parseProductionTickets(props);
    case PRODUCTION_VALIDATION_SECTIONS_IDS.DATES_TIME:
      return parseProductionDates(props);
    case PRODUCTION_VALIDATION_SECTIONS_IDS.CAST_CREW:
      return parseProductionCast(props);
    case PRODUCTION_VALIDATION_SECTIONS_IDS.PRODUCTION_VIDEOS:
      return parseProductionVideos(props);
    case PRODUCTION_VALIDATION_SECTIONS_IDS.PRODUCTION_IMAGES:
      return parseProductionImages(props);
    case PRODUCTION_VALIDATION_SECTIONS_IDS.REVIEWS:
      return parseProductionReviews(props);
    case PRODUCTION_VALIDATION_SECTIONS_IDS.PRODUCTION_SYNOPSIS:
      return parseProductionSynopsis(props);
    case PRODUCTION_VALIDATION_SECTIONS_IDS.PRODUCTION_BOOKLET:
      return parseProductionBooklet(props);
    default:
      return <div>Invalid Section</div>;
  }
};

export const getProductionSection = (section) => {
  switch (section) {
    case PRODUCTION_VALIDATION_SECTIONS_IDS.PRODUCTION_INFORMATION:
      return ProductionInformation;
    case PRODUCTION_VALIDATION_SECTIONS_IDS.PRODUCTION_POSTER:
      return ProductionPosterTrailer;
    case PRODUCTION_VALIDATION_SECTIONS_IDS.TICKETS_SALE_DATES:
      return ProductionTickets;
    case PRODUCTION_VALIDATION_SECTIONS_IDS.DATES_TIME:
      return ProductionDates;
    case PRODUCTION_VALIDATION_SECTIONS_IDS.CAST_CREW:
      return ProductionCast;
    case PRODUCTION_VALIDATION_SECTIONS_IDS.PRODUCTION_VIDEOS:
      return ProductionVideos;
    case PRODUCTION_VALIDATION_SECTIONS_IDS.PRODUCTION_IMAGES:
      return ProductionImages;
    case PRODUCTION_VALIDATION_SECTIONS_IDS.REVIEWS:
      return ProductionReviews;
    case PRODUCTION_VALIDATION_SECTIONS_IDS.PRODUCTION_SYNOPSIS:
      return ProductionSynopsis;
    case PRODUCTION_VALIDATION_SECTIONS_IDS.PRODUCTION_BOOKLET:
      return ProductionBooklet;
    default:
      return <div>Invalid Section</div>;
  }
};
