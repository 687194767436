import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { EditButton, useDataProvider, List, Show, SimpleShowLayout, SimpleForm, TextInput, DateField, Edit, Datagrid, TextField, FunctionField, Link } from 'react-admin';
import { Breadcrumbs } from '../components/Breadcrumbs';
import CustomDateField from '../components/customFields/CustomDateField';
import storage from '../utils/storage';
import ValidationLockByUser from '../components/ValidationLockByUser';

export const CurationEdit = (props) => {
  const userId = storage.getUserId();

  const transform = (data) => ({
    ...data,
    curated_by: userId,
  });

  return (
    <Edit title="Mark as Curated" {...props} transform={transform}>
      <SimpleForm submitOnEnter={false}>
        <TextInput source="curator_comments" />
      </SimpleForm>
    </Edit>
  );
};

export const CurationActions = (props) => {
  const { basePath, data } = props;

  return (
    <div style={{ display: 'flex', justifyContent: 'end', marginBottom: 10, gap: 20 }}>
      <ValidationLockByUser {...props} data={{ id: props?.data?.id }} lockGroupType="curation-list" />
      <EditButton basePath={basePath} record={data} />
    </div>
  );
};

export const CurationShow = (props) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Show actions={<CurationActions />} {...props}>
      <SimpleShowLayout>
        <TextField source="id" label="ID" />
        <FunctionField
          label="Profile ID"
          render={(record) => (
            <Link onClick={(e) => e.stopPropagation()} to={`/profiles/${record?.whitelistedEntity?.entity_id}/show`}>
              {record?.whitelistedEntity?.entity_id}
            </Link>
          )}
        />
        <FunctionField
          label="Curated by"
          render={(record) => (
            <Link onClick={(e) => e.stopPropagation()} to={`/users/${record?.curated_by}/show`}>
              {record?.curated_by}
            </Link>
          )}
        />
        <FunctionField
          label="Requested by"
          render={(record) => (
            <Link onClick={(e) => e.stopPropagation()} to={`/users/${record?.requested_by}/show`}>
              {record?.requested_by}
            </Link>
          )}
        />
        <TextField source="is_curated" label="Is curated" />
        <TextField source="curator_comments" />
        <TextField source="request_reason" />
        <DateField source="updated_at" label="Task last updated" showTime />
        <DateField source="created_at" showTime />
        <DateField source="deleted_at" showTime />
        <Accordion
          onChange={(e, isExpanded) => {
            setExpanded(isExpanded);
          }}
          expanded={expanded}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontWeight: 'bold' }}>
            Guidelines for Whitelist Queue
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <ul>
                <li>Check / find upcoming performances publicly available. Trailer / reviews</li>
                <ul>
                  <li>Check for other work types such as Ballet, concert, etc.</li>
                </ul>
                <li>Check / find for past performances publicly available sources such as artist website and organisation website</li>
                <ul>
                  <li>Check Trailer / reviews</li>
                </ul>
                <li>Add profile photograph - Only from Artist website or Agency website</li>
                <li>Add organisations (WL or Non WL) that have not been added before based on performances listed for the artists</li>
              </ul>
            </div>
          </AccordionDetails>
        </Accordion>
      </SimpleShowLayout>
    </Show>
  );
};

export const CurationList = (props) => {
  const dataProvider = useDataProvider();
  const [allValidationLocks, setAllValidationLocks] = useState([]);
  const allData = useSelector((store) => store.admin.resources.users)?.data;
  const allIds = useMemo(() => Object.keys(allData), [allData]);

  useEffect(() => {
    if (allIds.length) {
      dataProvider
        .getList('validations/locks', {
          filter: { lockIdentifier: allIds.join(','), lockGroup: 'curation-list', validation_status: '' },
          pagination: {},
          sort: {},
        })
        .then(({ data }) => {
          setAllValidationLocks(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allIds]);

  const showLockedUser = useCallback(
    (data) => {
      if (allValidationLocks.length) {
        const hasValidationLock = allValidationLocks.find((validationLock) => Number(validationLock.lockIdentifier) === Number(data.id));
        return `${hasValidationLock?.user?.email || 'Not locked'}`;
      }
      return 'Not locked';
    },
    [allValidationLocks],
  );

  return (
    <List exporter={false} title={<Breadcrumbs {...props} />} bulkActionButtons={false} sort={{ field: 'updated_at', order: 'DESC' }} {...props}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <FunctionField
          label="Profile ID"
          render={(record) => (
            <Link onClick={(e) => e.stopPropagation()} to={`/profiles/${record?.whitelistedEntity?.entity_id}/show`}>
              {record?.whitelistedEntity?.entity_id}
            </Link>
          )}
        />
        <FunctionField
          label="Requested by"
          render={(record) => (
            <Link onClick={(e) => e.stopPropagation()} to={`/users/${record?.requested_by}/show`}>
              {record?.requested_by}
            </Link>
          )}
        />
        <FunctionField label="Locked By" render={(record) => showLockedUser(record)} sortable={false} />
        <CustomDateField source="updated_at" label="Task last updated" sortable={false} />
      </Datagrid>
    </List>
  );
};

export default CurationList;
