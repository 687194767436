import { size, head } from 'lodash';

export const MERGE_ENTITIES = {
  organizations: {
    id: 'organizations',
    name: 'Organizations',
    reference: 'organizations',
    pageURL: 'organizations',
    pageId: (entity) => entity?.id,
    renderOption: (entity) => `(${entity.id}) - ${entity.name} (${entity?.organizationType?.slug})`,
  },
  venues: {
    id: 'venues',
    name: 'Venue',
    reference: 'venues',
    pageURL: 'venues',
    pageId: (entity) => entity?.id,
    renderOption: (entity) => `(${entity.id}) - ${entity.name}`,
  },
  agencies: {
    id: 'agencies',
    name: 'Agency',
    reference: 'agencies',
    pageURL: 'agencies',
    pageId: (entity) => entity?.id,
    renderOption: (entity) => `(${entity.id}) - ${entity.name}`,
  },
  works: {
    id: 'works',
    name: 'Work',
    reference: 'works',
    pageURL: 'works',
    pageId: (entity) => entity?.id,
    renderOption: (entity) => `(${entity.id}) - ${entity.name}`,
  },
  workRoles: {
    id: 'workRoles',
    name: 'Work Role',
    reference: 'works/roles',
    pageURL: `works/roles`,
    pageId: (entity) => entity?.work?.id,
    renderOption: (entity) => `(${entity.id}) - ${entity.name}`,
  },
  instruments: {
    id: 'instruments',
    name: 'Instruments',
    reference: 'instruments',
    pageURL: 'instruments',
    pageId: (entity) => entity?.id,
    renderOption: (entity) => `(${entity.id}) - ${entity.name}`,
  },
  instrumentRoles: {
    id: 'instrumentRoles',
    name: 'Instruments Role',
    reference: 'instruments/roles',
    pageURL: 'instruments/roles',
    pageId: (entity) => entity?.id,
    renderOption: (entity) => `(${entity.id}) - ${entity.name}`,
  },
  profiles: {
    id: 'profiles',
    name: 'Profile',
    reference: 'profiles',
    pageURL: 'profiles',
    pageId: (entity) => entity?.id,
    renderOption: (entity) => {
      const profession = size(entity?.professions) ? `(${head(entity?.professions)?.profession?.name})` : '';

      return `(${entity.id}) - ${entity.name} (${entity?.profileType?.slug})${profession}`;
    },
  },
  cities: {
    id: 'cities',
    name: 'City',
    reference: 'countries/cities',
    pageURL: 'countries/cities',
    pageId: (entity) => entity?.id,
    renderOption: (entity) => {
      const country = entity?.country?.id ? `(${entity?.country?.name})` : '';

      return `(${entity.id}) - ${entity.name} ${country}`;
    },
  },
  productions: {
    id: 'productions',
    name: 'Production',
    reference: 'productions',
    pageURL: 'productions',
    pageId: (entity) => entity?.id,
    renderOption: (entity) => `(${entity.id}) - ${entity.name}`,
  },
  venueStages: {
    id: 'venueStages',
    name: 'Venue Stage',
    reference: 'venues/stages',
    pageURL: 'venues',
    pageId: (entity) => entity?.venue?.id,
    renderOption: (entity) => {
      const venue = entity?.venue?.id ? `(Venue ${entity?.venue?.id}: ${entity?.venue?.name})` : '';

      return `(${entity.id}) - ${entity.name} ${venue}`;
    },
  },
  competitions: {
    id: 'competitions',
    name: 'Competitions',
    reference: 'competitions',
    pageURL: 'competitions',
    pageId: (entity) => entity?.id,
    renderOption: (entity) => `(${entity.id}) - ${entity.name}`,
  },
  workTypes: {
    id: 'workTypes',
    name: 'Work types',
    reference: 'works/types',
    pageURL: 'workTypes',
    pageId: (entity) => entity?.id,
    renderOption: (entity) => `(${entity.id}) - ${entity.name}`,
  },
  stagingTypes: {
    id: 'stagingTypes',
    name: 'Staging types',
    reference: 'works/stagingTypes',
    pageURL: 'stagingTypes',
    pageId: (entity) => entity?.id,
    renderOption: (entity) => `(${entity.id}) - ${entity.name}`,
  },
  professions: {
    id: 'professions',
    name: 'Professions',
    reference: 'professions',
    pageURL: 'professions',
    pageId: (entity) => entity?.id,
    renderOption: (entity) => `(${entity.id}) - ${entity.name}`,
  },
  organizationTypes: {
    id: 'organizationTypes',
    name: 'Organization types',
    reference: 'organizations/types',
    pageURL: 'organizationTypes',
    pageId: (entity) => entity?.id,
    renderOption: (entity) => `(${entity.id}) - ${entity.name}`,
  },
};

export const MERGE_ALLOWED_ENTITIES = [
  MERGE_ENTITIES.venues,
  MERGE_ENTITIES.agencies,
  MERGE_ENTITIES.works,
  MERGE_ENTITIES.workRoles,
  MERGE_ENTITIES.instruments,
  MERGE_ENTITIES.instrumentRoles,
  MERGE_ENTITIES.profiles,
  MERGE_ENTITIES.cities,
  MERGE_ENTITIES.productions,
  MERGE_ENTITIES.venueStages,
  MERGE_ENTITIES.competitions,
  MERGE_ENTITIES.workTypes,
  MERGE_ENTITIES.stagingTypes,
  MERGE_ENTITIES.organizations,
  MERGE_ENTITIES.professions,
  MERGE_ENTITIES.organizationTypes,
];

export const NON_OPERATIONAL_ENTITIES = ['agencies', 'organizations'];

export const WHITELIST_ENTITIES_STATUS = [
  {
    id: 'true',
    name: 'Whitelisted',
  },
  {
    id: 'false',
    name: 'Not whitelisted',
  },
];

export const WHITELIST_ENTITIES = [
  {
    id: 'artist',
    name: 'Artist',
  },
  {
    id: 'organization',
    name: 'Organization',
  },
];

export const WHITELIST_ENTITY_TYPES = {
  profiles: 'artist',
  organizations: 'Organization',
};

export const ENTITIES = {
  PROFILE: 1,
  PRODUCTIONS: 4,
  WORK: 8,
  ORGANIZATION: 12,
};

export const FOR_ENTITY = {
  ARTIST: 4,
};
