import React, { useState } from 'react';
import { Loading, useGetList } from 'react-admin';
import { startCase, toLower } from 'lodash';
import { makeStyles, Grid, Card, CardContent, Typography, FormControl, Select, MenuItem } from '@material-ui/core';
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons';
import configuration from './configuration';
import { DAYS_FILTER } from '../../constants/dashboard';

const styles = () => ({
  title: {},
  profitArrow: {
    fill: '#77CC77',
  },
  profitArrowDanger: {
    fill: '#FF7777',
  },
  entryPositive: {
    padding: 8,
    border: '1px solid transparent',
    borderRadius: 8,
    marginBottom: 8,
  },
  entryNegative: {
    padding: 8,
    background: '#FFDDDD',
    border: '1px solid #FF7777',
    borderRadius: 8,
    marginBottom: 8,
    color: 'red',
  },
  entryMissing: {
    padding: 8,
    background: '#F0F0F0',
    border: '1px solid #DEDEDE',
    borderRadius: 8,
    marginBottom: 8,
  },
  clickableEntry: { cursor: 'pointer' },
  filters: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row-reverse',
    margin: '10px',
  },
  select: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: { marginRight: 10 },
  labelNegative: {
    fontWeight: 600,
    color: 'red',
  },
});

const KEY_VS_MODULE_LINKS = {
  profile: '#/profiles',
  agency: '#/agencies',
  organization: '#/organizations',
  production: '#/productions',
  media_image: '#/media?filter={"media_type_id"%3A"1"}',
  media_video: '#/media?filter={"media_type_id"%3A"2"}',
  media_lsvod: '#/media?filter={"media_type_id"%3A"4"}',
  venue: '#/venues',
  work: '#/works',
  country: '#/countries',
  city: '#/countries/cities',
};

const StatWidget = (props) => {
  const { data, config, day, statsData } = props;
  const classes = makeStyles(styles)();
  const { key, label, sections } = config;
  const title = label || startCase(toLower(key));

  const roundNumber = (num) => {
    if (num === null || Number.isNaN(num)) {
      return 'N/A';
    }
    if (Math.abs(num % 1) > 0) {
      return parseFloat(num).toFixed(2);
    }
    return parseFloat(num);
  };

  const roundDiff = (num) => {
    if (num < 0) return roundNumber(num);
    if (num > 0) return '+'.concat(roundNumber(num));

    return '';
  };

  const entries = sections.map((sectionConfig) => {
    const { key: sectionKey, label: sectionLabel, kind, dbViewName, dataKey } = sectionConfig;
    const hasData = data && dataKey ? statsData?.[dataKey]?.[sectionKey] : data?.[sectionKey];

    let link;
    if (sectionKey === 'total' && KEY_VS_MODULE_LINKS[key]) link = KEY_VS_MODULE_LINKS[key];
    else if (dbViewName) link = `#/metrics/dbviews?filter={"view_id":"${dbViewName}"}`;
    else if (['quality', 'duplicates', 'soundslike'].includes(sectionKey)) link = `#/metrics/dbviews?filter={"view_id":"v_${key}_${sectionKey}"}`;

    const entry = {
      hasData,
      label: sectionLabel || startCase(toLower(sectionKey)),
      today: hasData ? hasData.today : 0,
      yesterday: hasData ? hasData.days_ago_01 : 0,
      diff: hasData ? hasData[day] : 0,
      kind: kind || 'positive',
      link,
    };
    entry.diff = entry.today - entry.diff;

    return entry;
  });

  const Arrow = ({ diff, kind }) => {
    if (kind === 'negative') {
      if (diff < 0) {
        return <ArrowDropDown className={classes.profitArrow} />;
      }
      if (diff > 0) {
        return <ArrowDropUp className={classes.profitArrowDanger} />;
      }
    } else {
      if (diff < 0) {
        return <ArrowDropDown className={classes.profitArrowDanger} />;
      }
      if (diff > 0) {
        return <ArrowDropUp className={classes.profitArrow} />;
      }
    }
    return null;
  };

  return (
    <Grid item xs={6} sm={3} md={2}>
      <Card variant="outlined">
        <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
            {title}
          </Typography>

          {entries.map((entry) => {
            if (!entry.hasData) {
              return (
                <Grid key={title.concat(entry.label)} container className={classes.entryMissing}>
                  <Typography variant="body2">
                    <strong>{entry.label}</strong> data missing
                  </Typography>
                  <br />
                  <br />
                </Grid>
              );
            }
            return (
              <Grid
                key={title.concat(entry.label)}
                container
                className={`${(entry.kind === 'positive' && parseInt(entry.diff, 10) >= 0) || (entry.kind === 'negative' && entry.today === 0) ? classes.entryPositive : classes.entryNegative} ${
                  entry.link ? classes.clickableEntry : ''
                }`}
                onClick={() => {
                  if (entry.link) window.location.href = entry.link;
                }}
              >
                <Grid container alignItems="center">
                  <Typography variant="h6">{roundNumber(entry.today)}</Typography>
                  <Arrow diff={parseInt(entry.diff, 10)} kind={entry.kind} />
                  {parseInt(entry.diff, 10) ? (
                    <Typography variant="caption" style={{ marginTop: -8 }}>
                      {roundDiff(entry.diff)}
                    </Typography>
                  ) : null}
                </Grid>
                <Typography
                  variant="body2"
                  style={{ marginTop: -8 }}
                  className={`${(entry.kind === 'positive' && parseInt(entry.diff, 10) >= 0) || (entry.kind === 'negative' && entry.today === 0) ? classes.labelPositive : classes.labelNegative}`}
                >
                  {entry.label}
                </Typography>
              </Grid>
            );
          })}
        </CardContent>
      </Card>
    </Grid>
  );
};

const DashboardWidgetMetrics = () => {
  const classes = makeStyles(styles)();
  const [selectedDayFilter, setSelectedDayFilter] = useState(DAYS_FILTER[0]);
  const { data, loading } = useGetList('metrics/full_metrics', { page: 1, perPage: 10 }, {});

  if (loading) {
    return <Loading />;
  }

  return (
    <Grid container spacing={2} direction="row" justify="flex-start" alignItems="flex-start">
      <div className={classes.filters}>
        <FormControl className={classes.select}>
          <div className={classes.label}>Compare with: </div>
          <Select value={selectedDayFilter} label={selectedDayFilter.name} onChange={(e) => setSelectedDayFilter(e.target.value)}>
            {DAYS_FILTER.map((filter) => (
              <MenuItem key={filter.key} value={filter}>
                {filter.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      {configuration.map((widgetConfig) => {
        const statData = data[widgetConfig.key];
        return <StatWidget statsData={data} day={selectedDayFilter.key} title={widgetConfig.label} key={widgetConfig.key} data={statData} config={widgetConfig} />;
      })}
    </Grid>
  );
};

export default DashboardWidgetMetrics;
