import React, { useState, useEffect } from 'react';
import keyBy from 'lodash/keyBy';
import { ListContextProvider, Datagrid, Loading, TextField } from 'react-admin';
import axios from 'axios';

const FingerprintDynamicCount = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     setLoading(true)
//     axios.get(`${process.env.REACT_APP_BLITZER_API_URL}fingerprint/dynamicUseThreshold`, {
//       headers: {
//         'Content-Type': 'application/json',
//         'x-access-key': `${process.env.REACT_APP_BLITZER_API_SECRET}`
//       }
//     }).then((res) => {
//       setData(res.data?.calculation);
//       setLoading(false)
//     });
//   }, []);

  useEffect(() => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_BLITZER_API_URL}fingerprint/dynamicPageCount`, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-key': `${process.env.REACT_APP_BLITZER_API_SECRET}`
      }
    }).then((res) => {
      setData(res.data?.data);
      setLoading(false);
    });
  }, []);


  const listContext = {
    data: keyBy(data, (item) => `${item?._id}`),
    ids: data?.map((item) => `${item?._id}`),
    total: data?.total || 0,
    hasList: true,
    basePath: props.basePath,
    resource: props.resource,
    currentSort: {},
    selectedIds: [],
  };

  return (
    <>
      {!loading ? (
        <ListContextProvider value={listContext}>
          <Datagrid>
            <TextField source="_id" label="ID" sortable={false} />
            <TextField source="softWallPageViewCount" label="Threshold" sortable={false} />
            <TextField source="isActive" label="Is Active" sortable={false} />
            <TextField source="createdAt" label="Created At" sortable={false} />
            <TextField source="calculation" label="CalculationId" sortable={false} />
          </Datagrid>
        </ListContextProvider>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default FingerprintDynamicCount;
