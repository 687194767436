import React, { useState, useMemo } from 'react';
import { ResponsiveContainer, CartesianGrid, ComposedChart, Line, Bar, XAxis, YAxis, Tooltip, Legend, LabelList } from 'recharts';
import { get, reduce } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import CustomTooltip from './CustomTooltip';
import colors from '../../constants/colors';
import { getMaxValue } from '../../utils/dashboard';

const useStyles = makeStyles(() => ({
  barChart: {
    '& .recharts-legend-item': {
      cursor: 'pointer',
      fontSize: 14,
    },
    '& .recharts-bar-rectangle': {
      cursor: 'pointer',
    },
    '& .recharts-label-list': {
      fontSize: 12,
    },
  },
}));

export default function ComposeChart({ title, dataKeys = [], lines = [], data, dataColors, hideCount, onClick, height, isMultiAxis, showLineLabel, xAxisDataKey, ...props }) {
  const classes = useStyles();
  const [chartProps, setChartProps] = useState(
    [...dataKeys, ...lines]?.reduce((result, dataKey) => {
      // eslint-disable-next-line no-param-reassign
      result[dataKey.key] = false;
      return result;
    }),
  );
  const leftMargin = useMemo(() => {
    const keys = [...dataKeys, ...lines];
    const maxValue = keys ? getMaxValue(data, keys) : 0;
    switch (true) {
      case maxValue > 100000:
        return 0;
      case maxValue > 1000:
        return -10;
      default:
        return -30;
    }
  }, [data, dataKeys, lines]);
  const rightMargin = useMemo(() => {
    const maxValue = reduce(
      data,
      (acc, item) => {
        const value = get(item, lines[0]?.key);
        return value > acc ? value : acc;
      },
      0,
    );
    switch (true) {
      case maxValue > 100000:
        return 0;
      case maxValue > 1000:
        return -10;
      default:
        return -30;
    }
  }, [data, lines]);

  const onClickBar = (chartData) => {
    setChartProps({
      ...chartProps,
      [chartData?.dataKey]: !chartProps[chartData?.dataKey],
    });
  };

  return (
    <div>
      {title && <h3>{title}</h3>}
      <ResponsiveContainer width="100%" height={height ?? 270} className={classes.barChart}>
        <ComposedChart stackOffset="sign" data={data} margin={{ top: 5, left: leftMargin, right: rightMargin, ...props.margin }} onClick={onClick}>
          <CartesianGrid />
          <XAxis dataKey={xAxisDataKey || 'name'} tick={{ fontSize: '12px', width: 300, height: 150, wordBreak: 'break-word' }} />
          <YAxis tick={{ fontSize: '12px', width: 300, height: 150, wordBreak: 'break-word' }} />
          {isMultiAxis && <YAxis yAxisId="right" orientation="right" tick={{ fontSize: '12px', width: 300, height: 150, wordBreak: 'break-word' }} />}
          <Tooltip content={<CustomTooltip />} cursor={{ fill: 'transparent' }} />
          <Legend iconSize="10" onClick={onClickBar} />
          {dataKeys?.map((dataKey, index) => (
            <Bar name={dataKey.name} dataKey={dataKey.key} fill={dataColors?.[dataKey.name] || colors[index]} key={index} stackId="name" hide={chartProps[dataKey.key]}>
              {index === dataKeys.length - 1 && !hideCount ? <LabelList position="top" valueAccessor={(entry) => (entry?.value[1] > 0 ? entry?.value[1] : null)} /> : null}
            </Bar>
          ))}
          {lines?.map((line, index) => (
            <Line
              {...(isMultiAxis ? { yAxisId: 'right' } : {})}
              key={line?.name}
              type="linear"
              name={line?.name}
              dataKey={line?.key}
              strokeWidth={2}
              dot={{ r: 2 }}
              fill={line?.fill || colors[index]}
              stroke={[line?.stroke || colors[index]]}
              hide={chartProps[line.key]}
            >
              {showLineLabel && <LabelList position="top" valueAccessor={(entry) => (entry?.value > 0 ? entry?.value : null)} />}
            </Line>
          ))}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}
