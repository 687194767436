import React from 'react';
import { DeleteWithConfirmButton } from 'react-admin';

export const DeleteWithConfirmButtonNullable = ({ record, ...props }) => {
  const getLabel = () => {
    if (record.has_access) {
      return 'Remove access';
    }
    return 'delete';
  };

  return record && record.id ? <DeleteWithConfirmButton record={record} {...props} label={getLabel()} /> : null;
};
