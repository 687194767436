import React, { useCallback, useEffect, useState } from 'react';

import {
  Show,
  List,
  TabbedShowLayout,
  Filter,
  TextInput,
  Datagrid,
  TextField,
  DateField,
  Create,
  Edit,
  SimpleForm,
  ReferenceInput,
  ReferenceManyField,
  Tab,
  Toolbar,
  SaveButton,
  TopToolbar,
  AutocompleteInput,
  DeleteButton,
  FormDataConsumer,
  TabbedForm,
  FormTab,
  useUpdate,
  useNotify,
  useRefresh,
  FunctionField,
  Pagination,
  UPDATE,
  required,
  useRedirect,
  SelectInput,
  useCreate,
  GET_LIST,
} from 'react-admin';
import { Button, withStyles } from '@material-ui/core';
import get from 'lodash/get';
import { Link, useRouteMatch } from 'react-router-dom';
import ProfessionInput from '../inputs/ProfessionInput';
import { searchToObject } from '../utils/location';
import dataProvider from '../components/dataProviderComponent';
import { ProductionsGrid } from './ProductionsModel';
import { getComposerNamesFromCreators } from '../utils/composer';
import apiClient from '../utils/api';
import { useLanguagesProvider } from '../utils/useLanguageProvider';
import EntityTranslation from '../components/translation/EntityTranslation';
import { PROFESSION_INSTRUMENTALIST } from '../constants/profession';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const EditToolbar = withStyles(toolbarStyles)((props) => {
  const [update] = useUpdate('works/roles');
  const notify = useNotify();
  const redirectTo = useRedirect();
  const id = props?.record.id;
  const workId = props?.record?.work?.id;

  const handleSave = useCallback(
    (values) => {
      const payload = { name: values?.name, original_name: values?.original_name };
      if (values?.workRoleType?.id === 1) {
        payload.workRoleType = { id: 1 };
        payload.workRoleProfessions = values?.workRoleProfessions?.map((roleProfession, index) => ({
          id: roleProfession?.id,
          profession: roleProfession?.profession,
          isPrimary: index === values?.primaryProfession,
        }));
      } else {
        payload.workRoleType = { id: 3 };
        payload.instrumentRole = { id: values?.instrumentRole?.id };
      }
      update(
        {
          payload: {
            id,
            data: payload,
          },
        },
        {
          onSuccess: () => {
            notify('ra.notification.updated', 'info', {
              smart_count: 1,
            });
            redirectTo(`/works/${workId}/1`);
          },
          onFailure: (err) => {
            notify(err?.message, 'error');
          },
        },
      );
    },
    [update, id, notify, redirectTo, workId],
  );

  return (
    <Toolbar {...props}>
      <SaveButton onSave={handleSave} />
      <DeleteButton undoable={false} redirect={`/works/${props?.record?.work?.id}/1`} />
    </Toolbar>
  );
});

const WorkRolesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Work ID" source="id" alwaysOn />
    <TextInput label="Search" source="query" alwaysOn />
  </Filter>
);

const WorkRolesShowName = ({ record }) => <span>WorkRoles: {record ? `${record.name} ` : ''}</span>;

export const WorkRolesShow = (props) => {
  const [roleDetails, setRoleDetails] = useState(undefined);
  useEffect(() => {
    apiClient.get(`works/roles/${props?.id}`).then((res) => setRoleDetails(res?.data));
  }, [props]);

  return (
    <Show {...props} title={<WorkRolesShowName />}>
      <TabbedShowLayout>
        <Tab label="Work Info">
          <TextField source="name" />
          <TextField source="original_name" label="Canonical Name" />
          <TextField source="workRoleType.name" label="Work Role Type" />
          <TextField source="profession.name" label="Profession" />
          <FunctionField
            render={(record) => record?.workRoleProfessions?.map(({ profession, isPrimary = false }) => `${profession?.name} ${isPrimary ? '(Primary)' : ''}`)?.join(', ')}
            label="Work Professions"
          />
        </Tab>
        <Tab label="Work Role Translations">
          <EntityTranslation list={{ url: `works/roles/${props?.id}/translations` }} update={{ url: `works/roles/translations` }} isRoles />
        </Tab>
        <Tab label="Productions">
          <ReferenceManyField
            label="List of productions where work role is used"
            reference="productions"
            filter={{ as_edit: true, work_role_id: props?.id, published: 'both' }}
            sort={{ field: 'year', order: 'DESC' }}
            perPage={25}
            pagination={<Pagination />}
          >
            <ProductionsGrid {...props} />
          </ReferenceManyField>
        </Tab>
        <Tab label="Musical Works">
          <ReferenceManyField
            label="List of musical works where work role is used"
            reference="works"
            filter={{ as_edit: true, id: roleDetails?.work?.id }}
            {...props}
            perPage={25}
            pagination={<Pagination />}
          >
            <Datagrid rowClick="show" {...props}>
              <TextField source="id" />
              <FunctionField
                render={(record) => {
                  const composerNames = getComposerNamesFromCreators(record?.creators);
                  return composerNames;
                }}
                label="Composers"
              />
              <TextField source="name" />
              <TextField source="original_name" />
              <TextField source="workType.name" label="Work type" />
              <TextField source="stats.productionCount" sortBy="production_count" label="Production count" />
              <TextField label="Validation status" source="validationStatus.name" />
              <DateField source="createdAt" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const redirect = (basePath, id, data) => `/works/${data.work.id}`;

const WorkButton = ({ workId }) => (
  <Button
    component={Link}
    to={{
      pathname: `/works/${workId}`,
    }}
    variant="outlined"
    color="primary"
  >
    Back to Work
  </Button>
);

const CreateActions = ({ workId }) => <TopToolbar>{workId && <WorkButton workId={workId} />}</TopToolbar>;

const EditActions = ({ data }) => {
  const workId = data?.work?.id;
  return <CreateActions workId={workId} />;
};

const TABS = {
  INFO: 'info',
  TRANSLATIONS: 'translation',
  PRODUCTIONS: 'productions',
  MUSICAL_WORK: 'musical_work',
};

const useSaveHandlers = () => {
  const notify = useNotify();
  const refresh = useRefresh();

  const onFailure = (error) => {
    notify(`${error.message}`, 'warning');
    refresh();
  };

  const onSuccess = () => {
    notify('ra.notification.updated', 'info', {
      smart_count: 1,
    });
    refresh();
  };

  return { onFailure, onSuccess };
};

const updateWorkRoleTranslation = (payload) => {
  const { roleDetails, selectedLanguages, data, lang } = payload;
  const selLang = selectedLanguages?.find((record) => record?.id === +lang);
  dataProvider(UPDATE, `works/${roleDetails?.work?.id}/roles`, {
    id: roleDetails?.id,
    data,
    query: {
      strict: true,
      lang: selLang?.slug,
    },
  });
};

const SaveTranslations = ({ tab, roleDetails, ...props }) => {
  const { initial } = props;
  const instrumentId = initial.id;
  const [update] = useUpdate('instruments', instrumentId);
  const { onSuccess, onFailure } = useSaveHandlers();
  const { selectedLanguages } = useLanguagesProvider();

  const handleSave = useCallback(
    (values) => {
      const { __versions: newVersions, ...valuesData } = values;
      const filteredVersions = Object.keys(newVersions)?.filter((lang) => newVersions[lang]?.name);

      if (tab === TABS.TRANSLATIONS) {
        Promise.all(
          filteredVersions?.map((lang) => {
            const record = newVersions[lang];
            return updateWorkRoleTranslation({
              roleDetails,
              selectedLanguages,
              lang,
              data: {
                name: record?.name,
                variants: record?.variants,
              },
            });
          }),
        ).then(onSuccess, onFailure);
      } else {
        update(
          {
            payload: {
              data: valuesData,
            },
          },
          {
            onSuccess,
            onFailure,
          },
        );
      }
    },
    [tab, onSuccess, onFailure, roleDetails, selectedLanguages, update],
  );
  // set onSave props instead of handleSubmitWithRedirect
  return <SaveButton {...props} onSave={handleSave} />;
};

const CustomEditToolbar = ({ tab, roleDetails, ...props }) => {
  const [record] = useState(props.record);

  if (tab === TABS.INFO) {
    return <EditToolbar {...props} />;
  }

  if (tab === TABS.TRANSLATIONS) {
    return (
      <Toolbar {...props}>
        <SaveTranslations initial={record} tab={tab} roleDetails={roleDetails} />
      </Toolbar>
    );
  }

  return null;
};

export const WorkRolesEdit = (props) => {
  let initialTab = TABS.INFO;
  const match = useRouteMatch('/works/roles/:id/:tab');
  if (match && match.params) {
    initialTab = Object.values(TABS)[match.params.tab];
  }

  const [tab, setTab] = useState(initialTab);
  const [roleDetails, setRoleDetails] = useState(undefined);
  useEffect(() => {
    apiClient.get(`works/roles/${props?.id}`).then((res) => setRoleDetails(res?.data));
  }, [props]);

  return (
    <Edit {...props} actions={<EditActions />}>
      <TabbedForm toolbar={<CustomEditToolbar {...props} roleDetails={roleDetails} tab={tab} />} redirect={redirect}>
        <FormTab label="Work Role Info" onClick={() => setTab(TABS.INFO)}>
          <FormDataConsumer>
            {({ formData }) => (
              <>
                {formData?.workRoleType?.id === 3 && <div style={{ padding: '5px', background: '#ff000073' }}>Note: Name of the role will be selected "Instrument Role" name</div>}
                <div>
                  <TextInput source="name" label="Name" disabled={formData?.workRoleType?.id === 3} />
                </div>
                <div>
                  <TextInput source="original_name" label="Canonical Name" disabled={formData?.workRoleType?.id === 3} />
                </div>
              </>
            )}
          </FormDataConsumer>
          <ReferenceInput label="Role type" source="workRoleType.id" reference="works/roles/types" filterToQuery={(searchText) => ({ query: searchText })}>
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
          <FormDataConsumer>
            {({ formData }) =>
              formData?.workRoleType?.id === 3 && (
                <>
                  <ReferenceInput label="Instrument Roles" source="instrumentRole.id" reference="instruments/roles" filterToQuery={(searchText) => ({ query: searchText })}>
                    <AutocompleteInput optionText="name" validate={required()} />
                  </ReferenceInput>
                </>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData }) =>
              formData?.workRoleType?.id === 1 && (
                <>
                  <ProfessionInput />
                </>
              )
            }
          </FormDataConsumer>
        </FormTab>
        <FormTab label="Work Role Translations" onClick={() => setTab(TABS.TRANSLATIONS)}>
          <EntityTranslation list={{ url: `works/roles/${props?.id}/translations` }} update={{ url: `works/roles/translations` }} isRoles />
        </FormTab>
        <FormTab label="Productions" onClick={() => setTab(TABS.PRODUCTIONS)}>
          <ReferenceManyField
            label="List of productions where work role is used"
            reference="productions"
            filter={{ as_edit: true, work_role_id: props?.id, published: 'both' }}
            sort={{ field: 'year', order: 'DESC' }}
            perPage={25}
            pagination={<Pagination />}
          >
            <ProductionsGrid {...props} />
          </ReferenceManyField>
        </FormTab>
        <FormTab label="Musical work" onClick={() => setTab(TABS.MUSICAL_WORK)}>
          <ReferenceManyField
            label="List of musical works where work role is used"
            reference="works"
            filter={{ as_edit: true, id: roleDetails?.work?.id }}
            {...props}
            perPage={25}
            pagination={<Pagination />}
          >
            <Datagrid rowClick="show" {...props}>
              <TextField source="id" />
              <FunctionField
                render={(record) => {
                  const composerNames = getComposerNamesFromCreators(record?.creators);
                  return composerNames;
                }}
                label="Composers"
              />
              <TextField source="name" />
              <TextField source="original_name" />
              <TextField source="workType.name" label="Work type" />
              <TextField source="stats.productionCount" sortBy="production_count" label="Production count" />
              <TextField label="Validation status" source="validationStatus.name" />
              <DateField source="createdAt" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const WorkRolesCreate = (props) => {
  const search = searchToObject(get(props, 'location.search'));
  const notify = useNotify();
  const source = JSON.parse(search?.source);
  const work = source?.work;
  const workId = source?.work?.id;
  const [workDetails, setWorkDetails] = useState(null);
  const [isAddNewInstrument, setisAddNewInstrument] = useState(false);
  const [newInstrument, setNewInstrument] = useState();
  const [create] = useCreate('instruments');

  useEffect(() => {
    apiClient.get(`works/${workId}`).then((res) => setWorkDetails(res?.data));
  }, [workId]);

  const transformCreateRole = (data) => ({
    ...data,
    is_standard: true,
    workRoleProfessions: data?.workRoleProfessions?.map((profession, index) => ({ ...profession, isPrimary: index === data?.primaryProfession })),
  });

  const handleInstrumentCreate = (v) => {
    create(
      {
        payload: { data: v },
      },
      {
        onSuccess: ({ data }) => {
          setisAddNewInstrument(false);
          setNewInstrument(data);
          notify(`${data?.name} instrument created successfully`, 'info');
          dataProvider(GET_LIST, 'instruments/roles', { pagination: {}, filter: { instrument_id: data?.instrument?.id }, sort: {} });
        },
        onFailure: (err) => notify(err?.message, 'warning'),
      },
    );
  };

  return (
    <Create title={`Create role for ${work?.id}`} {...props} actions={<CreateActions workId={workId} />} transform={transformCreateRole}>
      <SimpleForm
        redirect={`/works/${work?.id}/1?roleTypeId=${+search?.roleTypeId}`}
        {...props}
        initialValues={{
          workRoleType: { id: +search?.roleTypeId },
          language: { id: +search?.roleTypeId === 3 ? 22 : workDetails?.language?.id },
          instrument: { id: newInstrument?.id },
          primaryProfession: 0,
        }}
      >
        <>
          <ReferenceInput label="Role type" source="workRoleType.id" reference="works/roles/types" filterToQuery={(searchText) => ({ query: searchText })}>
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
          <div>
            <ReferenceInput label="Language" source="language.id" reference="languages" filter={{ limit: 9999 }} sort={{ field: 'name', order: 'ASC' }}>
              <SelectInput optionText="name" disabled={+search?.roleTypeId === 3} />
            </ReferenceInput>
          </div>
          <FormDataConsumer>
            {({ formData }) =>
              formData?.workRoleType?.id === 1 && (
                <>
                  <TextInput source="name" label="Role Name" />
                  <ProfessionInput />
                </>
              )
            }
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData }) =>
              formData?.workRoleType?.id === 3 && (
                <>
                  <div style={{ display: 'flex', width: '400px' }}>
                    <ReferenceInput label="Instruments" source="instrument.id" reference="instruments" filterToQuery={(searchText) => ({ query: searchText })}>
                      <AutocompleteInput optionText="name" />
                    </ReferenceInput>
                    <Button onClick={() => setisAddNewInstrument(true)}>+ Create new</Button>
                  </div>
                  {isAddNewInstrument && (
                    <div style={{ width: '400px', border: '2px solid grey', padding: '5px' }}>
                      <SimpleForm
                        save={handleInstrumentCreate}
                        toolbar={
                          <Toolbar {...props}>
                            <SaveButton label="Save" onSave={(data) => handleInstrumentCreate(data)} />
                            <Button
                              onClick={() => {
                                setisAddNewInstrument(false);
                                setNewInstrument('');
                              }}
                            >
                              Cancel
                            </Button>
                          </Toolbar>
                        }
                      >
                        <TextInput source="name" />
                        <ReferenceInput
                          label="Profession"
                          source="profession.id"
                          reference="professions"
                          filterToQuery={(searchText) => ({ query: searchText })}
                          filter={{ parent_profession: PROFESSION_INSTRUMENTALIST }}
                          sort={{ field: 'profile_count', order: 'DESC' }}
                          alwaysOn
                        >
                          <AutocompleteInput optionText="name" />
                        </ReferenceInput>
                      </SimpleForm>
                    </div>
                  )}
                  <ReferenceInput
                    label="Instrument Roles"
                    source="instrumentRole.id"
                    reference="instruments/roles"
                    filter={{ instrument_id: formData?.instrument?.id }}
                    filterToQuery={(searchText) => ({ query: searchText })}
                    validate={required('Instrument role is required')}
                  >
                    <AutocompleteInput optionText="name" />
                  </ReferenceInput>
                </>
              )
            }
          </FormDataConsumer>
        </>
      </SimpleForm>
    </Create>
  );
};

export const WorkRolesList = (props) => (
  <List exporter={false} {...props} filters={<WorkRolesFilter />} bulkActionButtons={false} sort={{ field: 'name', order: 'ASC' }}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="workType.name" />
    </Datagrid>
  </List>
);

export default WorkRolesList;
