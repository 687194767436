import React from 'react';
import { get, set } from 'lodash';
import { Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  label: {
    fontWeight: 'bold',
    marginRight: 8,
    display: 'inline-block',
    verticalAlign: 'top',
  },
  container: {
    display: 'inline-block',
  },
  table: {
    fontWeight: 'bold',
    marginRight: 8,
    marginBottom: 10,
    border: '1px solid #AAA',
    background: '#FFF',
    '& td': {
      padding: '0 8px',
      fontSize: 14,
    },
  },
  tableHeader: {
    padding: '0 8px',
    background: '#EEE',
    fontSize: 14,
  },
  from: {
    border: `1px solid #AA0000`,
    background: '#FFAAAA',
    color: '#440000',
  },
  to: {
    border: `1px solid #00AA00`,
    background: '#AAFFAA',
    color: '#004400',
  },
}));

const genProps = (id, oldProps, changes) => {
  const key = `table-row-${id}`;
  const newProps = { ...oldProps, key, parentPath: oldProps.path, changes: [changes] };
  delete newProps.headerBackground;
  delete newProps.headerColor;
  return newProps;
};

const genChildren = (props) => {
  const list = Array.isArray(props.children) ? props.children : [props.children];
  const children = list.map((child) => (child ? React.cloneElement(child, props) : null));
  return children;
};

const DisplayTable = (props) => {
  const classes = useStyles();
  const { title, path, header } = props;
  const { headerColor, headerBackground } = props;

  let { changes } = props;

  if (!changes) {
    return <div>{`${title}`} Missing Change Records</div>;
  }

  if (path) {
    changes = props.changes
      .filter((item) => {
        return item.path.startsWith(path);
      })
      .map((item) => {
        const t = item;
        t.left = typeof t.left === 'string' ? get(set({}, item.path, t.left), path)[0] : t.left;
        t.right = typeof t.right === 'string' ? get(set({}, item.path, t.right), path)[0] : t.right;
        return t;
      });
  }

  if (changes?.length === 0) {
    return <></>;
  }

  const titleStyle = { padding: '2px' };
  if (titleStyle) {
    titleStyle.color = headerColor;
  }

  if (headerBackground) {
    titleStyle.borderRadius = '8px';
    titleStyle.padding = '8px';
    titleStyle.backgroundColor = headerBackground;
  }

  return (
    <Typography variant="body2" gutterBottom>
      <label className={classes.label} style={titleStyle}>
        {title ? `${title}:` : ''}
      </label>
      <div className={classes.container}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {header &&
                header.map((thTitle, index) => (
                  <TableCell className={classes.tableHeader} key={index}>
                    {thTitle}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {changes.map((change, id) => {
              const { left, right } = change;
              const rows = [];
              let newProps = null;
              let newChildren = null;


              if (change.action === 'DELETED' && left) {
                newProps = genProps(id.toString().concat('-deleted'), props, change);
                newChildren = genChildren(newProps);

                rows.push(
                  <TableRow className={classNames(classes.element, classes.from)} {...newProps}>
                    {newChildren}
                  </TableRow>,
                );
              }

              if (change.action === 'ADDED' && right) {
                newProps = genProps(id.toString().concat('-added'), props, change);
                newChildren = genChildren(newProps);

                rows.push(
                  <TableRow className={classNames(classes.element, classes.to)} {...newProps}>
                    {newChildren}
                  </TableRow>,
                );
              }

              if (change.action === 'UPDATED') {
                newProps = genProps(id.toString().concat('-updated'), props, change);
                newChildren = genChildren(newProps);

                rows.push(<TableRow {...newProps}>{newChildren}</TableRow>);
              }

              return rows;
            })}
          </TableBody>
        </Table>
      </div>
    </Typography>
  );
};

export default DisplayTable;
