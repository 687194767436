import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-admin';

const useStyles = makeStyles(
  (theme) => ({
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      marginRight: 10,
    },
  }),
  { name: 'MakeLink' },
);

const ProfileLink = ({ record }) => {
  const classes = useStyles();
  const { name } = record;

  return (
    <Link to={`/profiles/${record.id}/show`} className={classes.link}>
      {name}
    </Link>
  );
};

export default ProfileLink;
