/* eslint-disable func-names */
import React, { useRef, useEffect } from 'react';
import { TextField } from '@material-ui/core';

export default function GooglePlacesAutocomplete({ defaultValue, onChange }) {
  const inputEl = useRef();
  let autocomplete;

  if (typeof window !== 'undefined') {
    window.initMap = function () {
      if (inputEl.current) {
        initAutocomplete(inputEl.current);
      }
    };
  }

  useEffect(() => {
    if (!window.google?.maps?.places) {
      const head = document.getElementsByTagName('head').item(0);
      const script = document.createElement('script');
      script.setAttribute('src', `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=places&language=en&callback=initMap`);
      head.appendChild(script);
    } else if (typeof window !== 'undefined') {
      window.initMap();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.initMap();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputEl.current]);

  const fillInAddress = () => {
    const place = autocomplete.getPlace();
    const address = inputEl?.current?.value;
    onChange({ place, address });
  };

  const initAutocomplete = (input) => {
    if (typeof window !== 'undefined' && window.google) {
      autocomplete = new window.google.maps.places.Autocomplete(input, {
        types: ['address'],
        fields: ['address_component', 'geometry', 'place_id'],
      });
      autocomplete.addListener('place_changed', fillInAddress);
    }
  };

  return <TextField fullWidth inputRef={inputEl} variant="filled" placeholder="Enter address" size="small" label="Address" defaultValue={defaultValue} />;
}
