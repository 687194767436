export default [
  {
    key: 'profile',
    label: 'Artists',
    sections: [
      { key: 'quality', label: 'Quality' },
      { key: 'total', label: 'Total' },
      { key: 'duplicates', label: 'Duplicates', kind: 'negative' },
      // { key: 'soundslike', label: 'Sounds Like', kind: 'negative' },
      { key: 'agency_managed', label: 'Has Agency', kind: 'positive' },
    ],
  },
  {
    key: 'agency',
    label: 'Agencies',
    sections: [
      { key: 'quality', label: 'Quality', kind: 'positive' },
      { key: 'total', label: 'Total', kind: 'positive' },
      { key: 'duplicates', label: 'Duplicates', kind: 'negative' },
      // { key: 'soundslike', label: 'Sounds Like', kind: 'negative' },
    ],
  },
  {
    key: 'organization',
    label: 'Organizations',
    sections: [
      { key: 'quality', label: 'Quality' },
      { key: 'total', label: 'Total' },
      { key: 'duplicates', label: 'Duplicates', kind: 'negative' },
      // { key: 'soundslike', label: 'Sounds Like', kind: 'negative' },
    ],
  },
  {
    key: 'production',
    label: 'Productions',
    sections: [
      { key: 'quality', label: 'Quality' },
      { key: 'total', label: 'Total' },
      { key: 'duplicates', label: 'Duplicates', kind: 'negative' },
      { key: 'concert_production', label: 'Concert works', kind: 'negative', dbViewName: 'v_concert_productions' },
    ],
  },
  {
    key: 'media_image',
    label: 'Images',
    sections: [
      { key: 'quality', label: 'Quality' },
      { key: 'total', label: 'Total' },
      { key: 'posters', label: 'Poster size wrong', kind: 'negative', dbViewName: 'v_production_posters', dataKey: 'production' },
    ],
  },
  {
    key: 'media_video',
    label: 'Videos',
    sections: [
      { key: 'quality', label: 'Quality' },
      { key: 'total', label: 'Total' },
    ],
  },
  {
    key: 'media_lsvod',
    label: 'LS & VOD',
    sections: [
      { key: 'quality', label: 'Quality' },
      { key: 'total', label: 'Total' },
    ],
  },
  {
    key: 'venue',
    label: 'Venues',
    sections: [
      { key: 'quality', label: 'Quality' },
      { key: 'total', label: 'Total' },
      { key: 'duplicates', label: 'Duplicates', kind: 'negative' },
      // { key: 'soundslike', label: 'Sounds Like', kind: 'negative' },
    ],
  },
  {
    key: 'work',
    label: 'Works',
    sections: [
      { key: 'quality', label: 'Quality' },
      { key: 'total', label: 'Total' },
      { key: 'duplicates', label: 'Duplicates', kind: 'negative' },
      // { key: 'soundslike', label: 'Sounds Like', kind: 'negative' },
    ],
  },
  {
    key: 'workrole',
    label: 'Work Roles',
    sections: [
      { key: 'quality', label: 'Quality' },
      { key: 'total', label: 'Total' },
      { key: 'duplicates', label: 'Duplicates', kind: 'negative' },
      // { key: 'soundslike', label: 'Sounds Like', kind: 'negative' },
    ],
  },
  {
    key: 'country',
    label: 'Countries',
    sections: [
      { key: 'quality', label: 'Quality' },
      { key: 'total', label: 'Total' },
      { key: 'duplicates', label: 'Duplicates', kind: 'negative' },
      // { key: 'soundslike', label: 'Sounds Like', kind: 'negative' },
    ],
  },
  {
    key: 'city',
    label: 'City',
    sections: [
      { key: 'quality', label: 'Quality' },
      { key: 'total', label: 'Total' },
      { key: 'duplicates', label: 'Duplicates', kind: 'negative' },
      // { key: 'soundslike', label: 'Sounds Like', kind: 'negative' },
    ],
  },
];
