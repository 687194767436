import React, { useEffect, useState } from 'react';
import { useGetList } from 'react-admin';
import { Grid, Card, CardContent, Typography, makeStyles, FormControl, Select, MenuItem } from '@material-ui/core';
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons';
import { map } from 'lodash';
import { VALIDATION_STATUSES_IDS } from '../../constants/validations';
import { DAYS_FILTER } from '../../constants/dashboard';
import { getValidationDashboardConfig } from '../../utils/validations';
import { roundDiff } from '../../utils/dashboard';

const useStyles = makeStyles(() => ({
  entityInfo: { marginLeft: 10 },
  card: {
    cursor: 'pointer',
    height: 130,
  },
  filters: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row-reverse',
    margin: '0 10px',
  },
  select: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: { marginRight: 10 },
  profitArrow: {
    fill: '#77CC77',
    width: '1.5em',
    height: '1.5em',
  },
  profitArrowDanger: {
    fill: '#FF7777',
    width: '1.5em',
    height: '1.5em',
  },
  title: { textTransform: 'capitalize' },
}));

const ValidationDashboard = () => {
  const classes = useStyles();
  const [selectedDayFilter, setSelectedDayFilter] = useState(DAYS_FILTER[0]);
  const { data, loading } = useGetList('metrics/full_metrics', { page: 1, perPage: 10 }, {});
  const validationData = getValidationDashboardConfig(data);

  useEffect(() => {
    document.title = 'Validation dashboard';
  }, []);

  if (loading) {
    return 'Loading validation dashboard';
  }

  const Arrow = ({ diff }) => {
    if (diff < 0) {
      return <ArrowDropDown className={classes.profitArrow} />;
    }
    if (diff > 0) {
      return <ArrowDropUp className={classes.profitArrowDanger} />;
    }

    return null;
  };

  return (
    <div>
      <Grid container spacing={2} direction="row" justify="flex-start" alignItems="flex-start">
        <Grid item xs={12} sm={12} md={12}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h4" align="center">
                Validation Dashboard
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <div className={classes.filters}>
          <FormControl className={classes.select}>
            <div className={classes.label}>Compare with: </div>
            <Select value={selectedDayFilter} label={selectedDayFilter.name} onChange={(e) => setSelectedDayFilter(e.target.value)}>
              {DAYS_FILTER.map((filter) => (
                <MenuItem key={filter.key} value={filter}>
                  {filter.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {map(validationData, (entity) => {
          const diff = entity?.validations?.[selectedDayFilter.key] ? entity?.validations?.today - entity?.validations?.[selectedDayFilter.key] : 0;
          return (
            <Grid item xs={6} sm={3} md={2} key={entity?.id}>
              <Card
                className={classes.card}
                variant="outlined"
                onClick={() => {
                  if (entity?.id === 'change_request_pending') {
                    window.location.href = '#/validations/change-requests';
                  } else if (entity?.id === 'redmask_request_pending') {
                    window.location.href = '#/validations/redmask-requests';
                  } else if (entity?.id === 'production') {
                    window.location.href = '#/validations/production-validations';
                  } else {
                    window.location.href = `#/validations?filter=${JSON.stringify({ entity_type: entity?.label, status_id: VALIDATION_STATUSES_IDS.PENDING })}`;
                  }
                }}
              >
                <CardContent>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    {entity?.label}
                  </Typography>
                  <Grid container className={classes.entityInfo}>
                    <Grid container alignItems="center" wrap="nowrap">
                      <Typography variant="h6">{entity?.validations?.today}</Typography>
                      <Arrow diff={diff} />
                      <Typography variant="caption" style={{ marginTop: -8 }}>
                        {parseInt(diff, 10) ? (
                          <Typography variant="caption" style={{ marginTop: -8 }}>
                            {roundDiff(diff)}
                          </Typography>
                        ) : null}
                      </Typography>
                    </Grid>
                    <Typography variant="body2" style={{ marginTop: -8 }}>
                      Total
                    </Typography>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default ValidationDashboard;
