import React, { useState, useEffect } from 'react';
import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, DialogTitle, DialogContent, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
// import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
// import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import axios from 'axios';
import apiClient from '../../../utils/api';

// const BLITZER_API_URL = "https://dev-blitzer-event-api.truelinked.net/"
// const BLITZER_SECRET = 'secret'

const useStyles = makeStyles({
  dialog: {
    minWidth: '90%',
  },
  dialogTitle: {
    paddingBottom: 0,
  },
  table: {
    marginTop: '20px',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#f4f4f4',
    },
  },
  tableCell: {
    fontSize: 14,
  },
});
const CALCULATION_FIELDS = [
  { name: '_id', label: 'ID' },
  { name: 'type', label: 'Type' },
  { name: 'isActive', label: 'Is Active' },
  { name: 'parameter', label: 'Parameter' },
  { name: 'createdAt', label: 'Created At' },
  { name: 'hardWallThreshold', label: 'HW Threshold' },
  { name: 'softWallThreshold', label: 'SW Threshold' },
  { name: 'parameterType', label: 'Parameter Type' },
  { name: 'softWallPageViewCount', label: 'Dynamic Page View Count' },
  { name: 'dynamicUseThreshold', label: 'Dynamic Soft Wall Count' },
  { name: 'softWallIgnoreCount', label: 'Soft Wall Event Count' },
];

const MAIN_FIELDS = [
  { name: '_id', label: 'ID' },
  { name: 'type', label: 'Type' },
  { name: 'hardLimit', label: 'Hard Limit' },
  { name: 'isActive', label: 'Is Active' },
  { name: 'calculatedAt', label: 'Calculated At' },
  { name: 'hardWallVisitorConversion', label: 'Hard Wall Conversion' },
  { name: 'softWallVisitorConversion', label: 'Soft Wall Conversion' },
];

const FingerprintActivityDialog = ({ open, onClose, row }) => {
  const { calculation, visitors } = row;
  const classes = useStyles();

  const handleButtonClick = (action, visitors) => {
    apiClient.post('fingerprint/update_cache_content', {
      action,
      visitors,
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        className: classes.dialog,
      }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Grid item>Conversions</Grid>
          <Grid item>
            <Button onClick={onClose} color="primary">
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <TableContainer className={classes.table}>
          <Table component={Paper}>
            <TableBody>
              {CALCULATION_FIELDS.map((field) => (
                <TableRow key={field.name} className={classes.tableRow}>
                  <TableCell className={classes.tableCell}>{field.label}</TableCell>
                  <TableCell className={classes.tableCell}>{calculation[field.name]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TableContainer className={classes.table}>
              <Table component={Paper}>
                <TableHead>
                  <TableRow>
                    <TableCell>Visitors id</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Duration</TableCell>
                    <TableCell>Weightage</TableCell>
                    <TableCell>PageViewCount</TableCell>
                    <TableCell>ConversionType</TableCell>
                    <TableCell>Action: Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {visitors.map((visitor, index) => (
                    <TableRow key={index} className={classes.tableRow}>
                      <TableCell className={classes.tableCell}>{visitor.visitorId}</TableCell>
                      <TableCell className={classes.tableCell}>{visitor.type}</TableCell>
                      <TableCell className={classes.tableCell}>{visitor.category}</TableCell>
                      <TableCell className={classes.tableCell}>{visitor.weightage}</TableCell>
                      <TableCell className={classes.tableCell}>{visitor.pageViewCount}</TableCell>
                      <TableCell className={classes.tableCell}>{visitor.conversionType || '-'}</TableCell>
                      {/* <TableCell className={classes.tableCell}>
                        <Tooltip title="Push to Soft Wall">
                          <IconButton onClick={() => handleButtonClick('push_to_soft_wall', [visitor])}>
                            <ArrowDownwardIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell> */}
                      <TableCell className={classes.tableCell}>
                        <Tooltip title="Delete">
                          <IconButton onClick={() => handleButtonClick('delete', [visitor])}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {/* <Grid item xs={6}>
            <TableContainer className={classes.table}>
              <Table component={Paper}>
                <TableHead>
                  <TableRow>
                    <TableCell>Soft Wall Visitors id</TableCell>
                    <TableCell>Duration</TableCell>
                    <TableCell>Action: Push to Hard Wall</TableCell>
                    <TableCell>Action: Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {soft_wall_visitors.map((visitor, index) => (
                    <TableRow key={index} className={classes.tableRow}>
                      <TableCell className={classes.tableCell}>{visitor.visitorId}</TableCell>
                      <TableCell className={classes.tableCell}>{visitor.category}</TableCell>
                      <TableCell className={classes.tableCell}>
                        <Tooltip title="Push to Hard Wall">
                          <IconButton onClick={() => handleButtonClick('push_to_hard_wall', [visitor])}>
                            <ArrowUpwardIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Tooltip title="Delete">
                          <IconButton onClick={() => handleButtonClick('delete', [visitor])}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid> */}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const FingerprintActivity = () => {
  const [data, setData] = useState([]);
  const [dataRefresh, setDataRefresh] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BLITZER_API_URL}fingerprint/activity`, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-key': `${process.env.REACT_APP_BLITZER_API_SECRET}`
        }
      }
    ).then((res) => {
      setData(res?.data?.activities);
      setDataRefresh(false);
    });
  }, [dataRefresh]);

  const handleDialogOpen = (row) => {
    setSelectedRow(row);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const renderTableData = () =>
    data.map((row) => (
      <TableRow key={row.id}>
        {MAIN_FIELDS.map((field) => (
          <TableCell key={field.name}>{row[field.name]}</TableCell>
        ))}
        <TableCell>
          <Button size="small" onClick={() => handleDialogOpen(row)} variant="contained" style={{ textTransform: 'inherit' }}>
            View more
          </Button>
        </TableCell>
      </TableRow>
    ));

  return (
    <>
      <TableContainer>
        <Table component={Paper}>
          <TableHead>
            <TableRow>
              {MAIN_FIELDS.map((field) => (
                <TableCell key={field.name}>{field.label}</TableCell>
              ))}
              <TableCell>View more</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderTableData()}</TableBody>
        </Table>
      </TableContainer>
      {selectedRow && <FingerprintActivityDialog open={openDialog} onClose={handleDialogClose} row={selectedRow} />}
    </>
  );
};

export default FingerprintActivity;
