import React from "react";

import {
  Show,
  List,
  Filter,
  TextInput,
  Datagrid,
  TextField,
  SimpleShowLayout,
  FunctionField,
  DateField
} from "react-admin";

const UserTransactionsFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="query" alwaysOn />
  </Filter>
);

export const UserTransactionsShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="userPaymentCard.card_type" label="Card type" />
      <TextField source="userPaymentCard.card_number" label="Card number"/>
      <TextField source="reference_number" label="Reference number" />
      <FunctionField label="Amount" render={(transaction) => '€' + parseFloat(transaction?.amount?.amount).toFixed(2)} />
      <TextField source="payment_date" />
      <TextField source="captured_at" />
    </SimpleShowLayout>
  </Show>
);

export const UserTransactionsList = props => (
  <List exporter={false} filters={<UserTransactionsFilter />} bulkActionButtons={false} {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="userPaymentCard.card_type" label="Card type" />
      <TextField source="userPaymentCard.card_number" label="Card number"/>
      <TextField source="reference_number" label="Reference number" />
      <FunctionField label="Amount" render={(transaction) => '€' + parseFloat(transaction?.amount?.amount).toFixed(2)} />
      <DateField source="payment_date" />
      <TextField source="captured_at" />
    </Datagrid>
  </List>
);

export default UserTransactionsList;
