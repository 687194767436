import React from 'react';
import { List, Filter, TextInput, Datagrid, TextField, SimpleShowLayout, Show, DateField } from 'react-admin';
import { Breadcrumbs } from '../components/Breadcrumbs';
import CustomDateField from '../components/customFields/CustomDateField';

const OrganizationDepartmentsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="query" alwaysOn />
    <TextInput label="Department ID" source="id" alwaysOn />
  </Filter>
);

export const OrganizationDepartmentsShow = (props) => (
  <Show {...props} title={<Breadcrumbs {...props} />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" sortable={false} />
      <DateField source="created_at" showTime />
      <DateField source="updated_at" showTime />
    </SimpleShowLayout>
  </Show>
);

export const OrganizationDepartmentsList = (props) => (
  <List exporter={false} {...props} title={<Breadcrumbs {...props} />} filters={<OrganizationDepartmentsFilter />} bulkActionButtons={false}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" sortable={false} />
      <CustomDateField source="created_at" showTime />
      <CustomDateField source="updated_at" showTime />
    </Datagrid>
  </List>
);

export default OrganizationDepartmentsList;
