import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import DashboardMetrics from './DashboardMetrics';
import DashboardWidgetMetrics from './DashboardWidgetMetrics';

export const Dashboard = () => {
  useEffect(() => {
    document.title = 'Dashboard';
  }, []);

  return (
    <div>
      <DashboardWidgetMetrics />
      <br />
      <Grid container spacing={2} direction="row" justify="flex-start" alignItems="flex-start">
        <DashboardMetrics />
      </Grid>
    </div>
  );
};
