import React from 'react';
import { EditButton, ListButton, TopToolbar } from 'react-admin';

export const ShowActions = ({ basePath, data }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
    <ListButton basePath={basePath} />
  </TopToolbar>
);

export const CreateActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
);

export const ShowTitle = ({ record, field = 'name' }) => <span>{record ? `${record[field]}` : ''}</span>;
