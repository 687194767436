import React from 'react';
import compose from 'recompose/compose';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  listItem: {},
  listItemText: {},
  listItemIcon: {
    paddingLeft: 20,
    position: 'absolute',
    right: '-20px',
  },
  sidebarIsOpen: {
    paddingLeft: 0,
    transition: 'padding-left 512ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
  sidebarIsClosed: {
    paddingLeft: 0,
    transition: 'padding-left 512ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
};

const SubMenu = ({ handleToggle, sidebarIsOpen, isOpen, name, classes, children }) => (
  <>
    <ListItem dense button onClick={handleToggle} className={classes.listItem}>
      <ListItemText primary={isOpen ? name : ''} secondary={isOpen ? '' : name} className={classes.listItemText} />
      <ListItemIcon className={classes.listItemIcon}>{isOpen ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
    </ListItem>
    <Collapse in={isOpen} timeout="auto" unmountOnExit>
      <List exporter={false} dense component="div" disablePadding className={sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed}>
        {children}
      </List>
      <Divider />
    </Collapse>
  </>
);

const enhance = compose(withStyles(styles));

export default enhance(SubMenu);
