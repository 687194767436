import React, { useEffect, useState } from 'react';
import * as dayjs from 'dayjs';
import { keyBy } from 'lodash';
import { Datagrid, TextField, Pagination, ListContextProvider, FunctionField, Loading } from 'react-admin';
import { Dialog, DialogContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import apiClient from '../../../utils/api';
// import blitzerApiClient from '../../../utils/blitzerApi';
import EntityLink from '../../EntityLink';

const useStyles = makeStyles({
  button: {
    textTransform: 'initial',
  },
  dialog: {
    minWidth: 900,
    height: 540,
    padding: 10,
    '& .MuiDialogContent-root': { padding: 0 },
    '& .MuiDialogContent-root:first-child': { padding: 0 },
  },
  container: {
    marginTop: 10,
  },
  closeIcon: {
    position: 'absolute',
    right: 10,
    top: 10,
    cursor: 'pointer',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
  },
  launchIcon: {
    fontSize: 14,
    marginLeft: 5,
  },
  title: {
    fontWeight: 600,
    padding: 5,
  },
});

export default function FingerprintDataModal({ open, onClose, chartData = {} }) {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const { title, filter, resource } = chartData;

  useEffect(() => {
    setIsLoading(true);
    console.log('chartData', chartData);
    if (chartData?.activeLabel) {
      apiClient
        .get(resource, { params: { ...filter, page, limit: perPage, from_date: chartData?.activeLabel, to_date: dayjs(chartData?.activeLabel).add(1, 'day').format('YYYY-MM-DD') } })
        .then((res) => {
          setData(res?.data);
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, page, perPage]);

  let listContext = {};
  if (chartData?.resource === 'fingerprints') {
    listContext = {
      data: keyBy(data?.data, 'visitorId'),
      ids: data?.data?.map(({ visitorId }) => visitorId),
      total: data.total,
      currentSort: {},
      basePath: resource,
      resource,
      selectedIds: [],
      hasList: true,
      page,
      setPage,
      perPage,
      setPerPage,
    };
  } else if (chartData?.resource === 'insights' && !chartData.title.includes('Casting')) {
    listContext = {
      data: keyBy(data?.data, 'user.id'),
      ids: data?.data?.map(({ user }) => user.id),
      total: data.total,
      currentSort: {},
      basePath: resource,
      resource,
      selectedIds: [],
      hasList: true,
      page,
      setPage,
      perPage,
      setPerPage,
    };
  } else if (chartData?.resource === 'insights' && chartData.title.includes('Casting')) {
    listContext = {
      data: keyBy(data?.data, 'profile.id'),
      ids: data?.data?.map(({ profile }) => profile.id),
      total: data.total,
      currentSort: {},
      basePath: resource,
      resource,
      selectedIds: [],
      hasList: true,
      page,
      setPage,
      perPage,
      setPerPage,
    };
  }

  const handleClose = () => {
    setPage(1);
    setPerPage(10);
    setData([]);
    setIsLoading(false);
    onClose();
  };

  if (chartData?.resource === 'insights' && !chartData.title.includes('Casting')) {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          className: classes.dialog,
          sx: { padding: 0 },
        }}
      >
        <DialogContent>
          <div className={classes.title}>
            {title} {chartData?.activeLabel}
          </div>
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
          <div className={classes.container}>
            {isLoading ? (
              <Loading />
            ) : (
              <ListContextProvider value={listContext}>
                <Datagrid>
                  <TextField source="metricName" label="Metric Name" />
                  <FunctionField
                    label="User"
                    render={(record) => {
                      if (!record?.user) return null;
  
                      return <EntityLink entityType="users" entity={record?.user} />;
                    }}
                  />
                  <TextField source="total" label="total" />
                  <TextField source="createdAt" label="createdAt" />
                </Datagrid>
                <Pagination />
              </ListContextProvider>
            )}
          </div>
        </DialogContent>
      </Dialog>
    );
  } else if (chartData?.resource === 'insights' && chartData.title.includes('Casting')) {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          className: classes.dialog,
          sx: { padding: 0 },
        }}
      >
        <DialogContent>
          <div className={classes.title}>
            {title} {chartData?.activeLabel}
          </div>
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
          <div className={classes.container}>
            {isLoading ? (
              <Loading />
            ) : (
              <ListContextProvider value={listContext}>
                <Datagrid>
                  <TextField source="metricName" label="Metric Name" />
                  <FunctionField
                    label="Profile"
                    render={(record) => {
                      if (!record?.profile) return null;
  
                      return <EntityLink entityType="profiles" entity={record?.profile} />;
                    }}
                  />
                  <TextField source="total" label="total" />
                  <TextField source="createdAt" label="createdAt" />
                </Datagrid>
                <Pagination />
              </ListContextProvider>
            )}
          </div>
        </DialogContent>
      </Dialog>
    );
  } else {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          className: classes.dialog,
          sx: { padding: 0 },
        }}
      >
        <DialogContent>
          <div className={classes.title}>
            {title} {chartData?.activeLabel}
          </div>
          <CloseIcon className={classes.closeIcon} onClick={handleClose} />
          <div className={classes.container}>
            {isLoading ? (
              <Loading />
            ) : (
              <ListContextProvider value={listContext}>
                <Datagrid>
                  <TextField source="conversionType" label="Conversion type" />
                  <FunctionField
                    label="User"
                    render={(record) => {
                      if (!record?.user) return null;
  
                      return <EntityLink entityType="users" entity={record?.user} />;
                    }}
                  />
                  <TextField source="visitorId" label="visitorId" />
                  <TextField source="createdAt" label="createdAt" />
                </Datagrid>
                <Pagination />
              </ListContextProvider>
            )}
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
