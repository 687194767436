import React from 'react';
import { TableCell, Grid } from '@material-ui/core';
import apiClient from '../../utils/api';
import { cloneDiff } from './utils';
import DisplayTable from './DisplayTable';
import DisplayMedia from './DisplayMedia';

const getData = async (productionId) => {
  const images = (await apiClient.get(`productions/${productionId}/media`, {
    params: {
      media_attribute_slug: 'poster',
      media_attribute_type_id: 12,
      media_type_id: 1,
      privacy_attribute_id: '1,2,3,4,5,6,7,8',
      show_invalid: true,
      page: 1,
      limit: 1000,
    },
  }))?.data?.data;

  const videos = (await apiClient.get(`productions/${productionId}/media`, {
    params: {
      media_attribute_slug: 'trailer',
      media_attribute_type_id: 11,
      media_type_id: 2,
      privacy_attribute_id: '1,2,3,4,5,6,7,8',
      show_invalid: true,
      page: 1,
      limit: 1000,
    },
  }))?.data?.data;

  return {
    images, videos,
  }
}

export const parseProductionPosterTrailer = (props) => {
  const { production, changeRequest } = props;

  return new Promise(async (resolve, reject) => {
    const a = changeRequest ? await getData(production.id) : { images: [], videos: [] };
    const b = changeRequest ? await getData(changeRequest.revisionId) : await getData(production.id);

    const data = {
      images: cloneDiff(a.images, b.images).filter((i) => !isNaN(parseInt(i.path))),
      videos: cloneDiff(a.videos, b.videos).filter((i) => !isNaN(parseInt(i.path))),
    };

    resolve(data);
  });
};

export const MediaRow = (props) => {
  return (
    <>
      <TableCell>
        <DisplayMedia path="" changes={props.changes} />
      </TableCell>
    </>
  );
};

export const ProductionPosterTrailer = (props) => (
  <>
    <Grid container>
      <Grid item xs={12}>
        <DisplayTable {...props} changes={props.changes.images} title="Production Posters">
          <MediaRow />
        </DisplayTable>
      </Grid>

      <Grid item xs={12}>
        <DisplayTable {...props} changes={props.changes.videos} title="Production video trailer">
          <MediaRow />
        </DisplayTable>
      </Grid>
    </Grid>
  </>
);
