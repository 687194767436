import React, { useEffect, useState } from 'react';
import { head, find } from 'lodash';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { Button, useDataProvider, useNotify } from 'react-admin';
import { getEntityPublicLink, getEntityPublicEditLink } from '../utils/entities';

const linkStyles = {
  viewLink: {
    color: '#3333FF',
    textDecoration: 'underline',
    margin: '4px 8px 0px 0px',
  },
  editLink: {
    color: '#FFFFFF',
    background: '#3333FF',
    padding: '6px 12px',
    marginRight: '8px',
    textDecoration: 'none',
    '& span': {
      padding: '0px 4px',
    },
    '&:hover': {
      background: '#3333AA',
      color: '#CCC',
    },
  },
};

export const PublicLink = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const classes = makeStyles(linkStyles)();
  const ref = props.reference ?? props.resource;
  const isEdit = props.edit === true;
  const settings = useSelector((store) => store.admin.resources[ref])?.props;
  const [forEntity, setForEntity] = useState(null);

  const settingsName = settings.name;
  const needForEntity = settingsName === 'productions' && isEdit;
  const [forEntityType, setForEntityType] = useState(null);
  const [forEntityId, setForEntityId] = useState(null);

  useEffect(() => {
    if (props?.changeRequest) {
      setForEntityType(4);
      setForEntityId(props?.changeRequest?.actor?.profile?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.changeRequest]);

  useEffect(() => {
    if (props?.data?.id) {
      if (needForEntity && props?.data?.forEntityId === null) {
        dataProvider
          .getList(`productions`, {
            filter: { is_edit: true, id: props?.data?.id },
            pagination: {},
            sort: {},
          })
          .then((res) => {
            const contributions = head(res?.data)?.contributions?.filter((item) => item?.profile?.id);
            const profile = find(contributions, (contribution) => Number(contribution?.profile?.id));
            setForEntity(profile?.profile);
            setForEntityId(profile?.profile?.id);
            setForEntityType(4);
          });
      } else {
        setForEntityId(props?.data?.forEntityId);
        setForEntityType(props?.data?.forEntity);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.data?.id]);

  useEffect(() => {
    switch (forEntityType) {
      case 4: // artist
        dataProvider
          .getOne(`profiles`, { id: forEntityId })
          .then((data) => setForEntity(data.data))
          .catch((err) => {
            console.error(err);
            notify(`Error loading profile for public link: ${err}`, {
              autoHideDuration: 30000,
              type: 'error',
            });
          });
        break;

      case 7: // organization
        dataProvider
          .getOne(`organizations`, { id: forEntityId })
          .then((data) => setForEntity(data.data))
          .catch((err) => {
            notify(`Error loading organization for public link: ${err}`, {
              autoHideDuration: 30000,
              type: 'error',
            });
            console.error(err);
          });
        break;

      default:
        console.error(`Unknown forEntityType: ${forEntityType}`);
        break;
    }
  }, [dataProvider, forEntityType, forEntityId, notify]);

  if (forEntity?.error) {
    return <Button type="button" color="primary" label={forEntity?.error} className={isEdit ? classes.editLink : classes.viewLink} />;
  }

  if (needForEntity && forEntity === null) {
    return <Button type="button" color="primary" label="Loading" className={isEdit ? classes.editLink : classes.viewLink} />;
  }

  const url = isEdit
    ? getEntityPublicEditLink(props?.changeRequest ? props?.changeRequest : props?.data, settingsName, {
        forEntityId,
        forEntityType,
        forEntity,
      })
    : getEntityPublicLink(props?.data, settingsName);

  if (!url) {
    return <span>No url for {settings.name}</span>;
  }

  let label = props.label ?? settings?.options?.label ?? settings.name;

  if (!props.label) {
    label = isEdit ? 'Edit on Operabase' : 'View on Operabase';
  }

  return (
    <a color="primary" rel="noopener noreferrer nofollow" target="_blank" className={isEdit ? classes.editLink : classes.viewLink} href={url}>
      {label}
    </a>
  );
};
