import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { useNotify, useDataProvider } from 'react-admin';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import storage from '../utils/storage';

export default function ShareProfileRegistrationLink(props) {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [isEmptySubscription, setIsEmptySubscription] = useState(false);
  const userId = storage.getUserId();
  const { id, record } = props;

  useEffect(() => {
    dataProvider.getList('users/subscriptions', { filter: { profile_id: id }, pagination: {}, sort: {} }).then((res) => {
      setIsEmptySubscription(!res?.data?.length);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  const getShareProfileToken = () => {
    dataProvider.create('users/giftTokens', { data: { user: { id: userId }, profile: { id } } }).then((res) => {
      const LinkToCopy = `${process.env.REACT_APP_OPERABASE_URL}/auth/int?gift_user_token=${res?.data?.token}`;
      const input = document.createElement('input');
      input.select();
      input.setSelectionRange(0, 999999999);
      navigator.clipboard.writeText(LinkToCopy);
      notify('Link copied');
    });
  };

  return (
    !record?.isHidden &&
    isEmptySubscription && (
      <Button color="grey" variant="contained" size="small" onClick={getShareProfileToken} style={{ textTransform: 'inherit', marginRight: 10 }}>
        <FileCopyIcon style={{ fontSize: '1rem', marginRight: 3 }} /> Copy registration link
      </Button>
    )
  );
}
