import React from 'react';

import {
  Datagrid,
  FunctionField,
  List,
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  ReferenceManyField,
  Link,
  Pagination,
  Create,
  TextInput,
  SimpleForm,
  SimpleShowLayout,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  DeleteWithConfirmButton,
  EditButton,
  Edit,
  Toolbar,
  FormDataConsumer,
  DateInput,
  TopToolbar,
  CreateButton,
  ExportButton,
  Filter,
  BooleanField,
  SaveButton,
  BooleanInput,
} from 'react-admin';
import { useForm } from 'react-final-form';
import { Button, withStyles } from '@material-ui/core';
import CustomDateField from '../components/customFields/CustomDateField';
import { Breadcrumbs } from '../components/Breadcrumbs';
import useUserPermissions from '../utils/useUserPermissions';


const COUPON_MODES = [
  { id: 'direct', name: 'Directly assign coupon' },
  { id: 'code', name: 'Coupon code' },
];

const COUPON_DISCOUNT_TYPES = [
  { name: 'Fixed amount', id: 'fixed_amount' },
  { name: 'Percentage', id: 'percentage' },
];

const COUPON_APPLY_ON = [
  { name: 'Invoice amount', id: 'invoice_amount' },
  { name: 'Each specified item', id: 'each_specified_item' },
];

const COUPON_DURATION_TYPE = [
  { name: 'One time', id: 'one_time' },
  { name: 'Forever', id: 'forever' },
  { name: 'Limited period', id: 'limited_period' },
];

const COUPON_STATUSES = [
  { name: 'Active', id: 'active' },
  { name: 'Expired', id: 'expired' },
  { name: 'Archived', id: 'archived' },
  { name: 'Deleted', id: 'deleted' },
];
const COUPON_IS_PUBLIC_STATUSES = [
  { name: 'Public', id: true },
  { name: 'Private', id: false },
];

const COUPON_CODE_STATUSES = [
  { name: 'Active', id: 'active' },
  { name: 'Archived', id: 'archived' },
];

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const getCouponPeriodValue = (coupon) => {
  switch (coupon.duration_type) {
    case 'one_time':
      return 'One time';
    case 'forever':
      return 'Forever';
    case 'limited_period':
      return `${coupon.period} ${coupon.period_unit}`;
    default:
      return 'N/A';
  }
};

const CouponListActions = ({ createDisabled, ...props }) => (
  <TopToolbar>
    <CreateButton {...props} disabled={createDisabled} />
    <ExportButton {...props} />
  </TopToolbar>
);

const CouponsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="query" alwaysOn />
    <SelectInput label="Coupon status" source="statuses" choices={COUPON_STATUSES} alwaysOn />
    <SelectInput label="Public coupon?" source="is_public" choices={COUPON_IS_PUBLIC_STATUSES} alwaysOn />
  </Filter>
);

export const CouponsList = (props) => {
  const { permissions } = useUserPermissions();
  const createDisabled = !permissions || !permissions.includes('coupons.create.all');

  return (
    <List
      {...props}
      title={<Breadcrumbs {...props} />}
      bulkActionButtons={false}
      filters={<CouponsFilter />}
      actions={permissions ? <CouponListActions createDisabled={createDisabled} /> : null}
      filterDefaultValues={{ statuses: 'active' }}
      exporter={false}
    >
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false} />
        <TextField label="External (coupon code)" source="external_id" sortable={false} />
        <TextField source="name" sortable={false} />
        <FunctionField label="Discount amount" render={(item) => (item.discount_type === 'percentage' ? `${item.discount_percentage} %` : `€${parseFloat(item.discount_amount / 100).toFixed(2)}`)} />

        <FunctionField label="Period" render={getCouponPeriodValue} />

        <CustomDateField source="valid_till" sortable={false} emptyText="Forever" />

        <FunctionField label="Redemptions" render={(item) => `${item.redemptions} of ${item.max_redemptions || '∞'}`} />

        <DeleteWithConfirmButton />
      </Datagrid>
    </List>
  );
};

const CustomToolbar = withStyles(toolbarStyles)((props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
));

export const CouponsEdit = (props) => (
  <Edit {...props} title={<Breadcrumbs {...props} />}>
    <SimpleForm toolbar={<CustomToolbar />} redirect="show">
      <BooleanInput source="is_public" />
    </SimpleForm>
  </Edit>
);

export const CouponsShow = (props) => (
  <Show {...props} title={<Breadcrumbs {...props} />}>
    <TabbedShowLayout>
      <Tab label="Base info">
        <TextField source="id" />
        <TextField source="external_id" label="External (coupon code)" />
        <TextField source="name" />
        <TextField source="slug" />
        <BooleanField source="is_public" label="Public coupon" />
        <FunctionField label="Discount amount" render={(item) => (item.discount_type === 'percentage' ? `${item.discount_percentage} %` : `€${parseFloat(item.discount_amount / 100).toFixed(2)}`)} />
        <br />
        <FunctionField label="Period" render={getCouponPeriodValue} />
        <TextField source="duration_type" />
        <CustomDateField source="valid_till" emptyText="Forever" />
        <br />
        <FunctionField label="Redemptions" render={(item) => `${item.redemptions} of ${item.max_redemptions || '∞'}`} />
        <TextField source="status" />
        <TextField source="apply_on" />
        <TextField source="invoice_name" emptyText="Empty" />
        <TextField source="invoice_notes" emptyText="Empty" />
        <TextField source="plan_constraint" label="Constraint type" emptyText="Empty" />
        <FunctionField render={(coupon) => coupon?.product_constraints?.map((pc) => pc?.name).join(', ')} label="Product constrains " />
        <FunctionField render={(coupon) => coupon?.addon_constraints?.map((pc) => pc?.name).join(', ')} label="Addon constrains " />
        <TextField source="resource_version" />
      </Tab>
      <Tab label="Coupon sets">
        <CreateCouponSetButton />
        <ReferenceManyField label="Coupon sets" reference="coupons/sets" target="coupon_id">
          <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="external_id" />
            <TextField source="name" />
            <TextField source="slug" />
            <DeleteWithConfirmButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const CouponsCreate = (props) => {
  const { permissions } = useUserPermissions();
  const createDisabled = !permissions || !permissions.includes('coupons.create.all');
  if (createDisabled) {
    return null;
  }

  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="id" disabled />
        <TextInput source="external_id" disabled />
        <TextInput source="name" />
        <br />
        <SelectInput choices={COUPON_DISCOUNT_TYPES} source="discount_type" defaultValue="percentage" />
        <SelectInput choices={COUPON_DURATION_TYPE} source="duration_type" defaultValue="one_time" />
        <SelectInput choices={COUPON_STATUSES} source="status" defaultValue="active" disabled />
        <SelectInput choices={COUPON_APPLY_ON} source="apply_on" defaultValue="invoice_amount" disabled />
        <br />
        <FormDataConsumer>{({ formData }) => <TextInput source="discount_amount" disabled={formData.discount_type === 'percentage'} />}</FormDataConsumer>
        <FormDataConsumer>{({ formData }) => <TextInput source="discount_percentage" disabled={formData.discount_type === 'fixed_amount'} />}</FormDataConsumer>
        <br />

        <TextInput source="invoice_name" emptyText="Empty" />
        <TextInput source="invoice_notes" emptyText="Empty" />
        <DateInput source="valid_till" />
        <TextInput source="max_redemptions" defaultValue="1" />
        <TextInput source="resource_version" />
      </SimpleForm>
    </Create>
  );
};

const CreateCouponSetButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: '/coupons/sets/create',
      state: {
        record: { coupon: { id: record.id } },
        userRestricted: true,
      },
    }}
  >
    Create coupon set
  </Button>
);

export const CouponSetsCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <TextInput source="coupon.id" disabled />
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);

export const CouponSetsShow = (props) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Base info">
        <TextField source="id" />
        <FunctionField label="Coupon" render={(item) => (item?.coupon ? <Link to={`/coupons/${item?.coupon?.id}/show/1`}>{item?.coupon?.name}</Link> : 'Not found')} />
        <TextField source="external_id" />
        <TextField source="name" />
        <TextField source="slug" />
      </Tab>
      <Tab label="Codes">
        <CreateCouponCodeButton entityType="couponSet" />
        <ReferenceManyField label="Coupon codes" reference="coupons/codes" target="coupon_set_id" pagination={<Pagination />}>
          <Datagrid>
            <TextField source="id" />
            <TextField source="code" />
            <TextField source="status" />
            <TextField source="email" />
            <FunctionField
              label="User"
              render={(item) =>
                item?.user ? (
                  <Link to={`/users/${item.user.id}/show/3`}>
                    {item.user.name} ({item.user.email})
                  </Link>
                ) : (
                  'Unassigned'
                )
              }
            />
            <FunctionField
              label="Subscription"
              render={(item) => (item?.userSubscription ? <Link to={`/users/subscriptions/${item.userSubscription.id}/show`}>{item.userSubscription?.subscription?.description}</Link> : 'Unassigned')}
            />
            <EditButton label="Update" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const CouponCodesEdit = (props) => (
  <Edit {...props}>
    <SimpleForm redirect="show">
      <ReferenceInput label="User" source="user.id" reference="users" filterToQuery={(searchText) => ({ query: searchText })}>
        <AutocompleteInput optionText={(user) => (user ? `(${user.id}) - ${user.email} - ${user.name}` : '(Empty)')} />
      </ReferenceInput>
      <SelectInput choices={COUPON_CODE_STATUSES} source="status" />
    </SimpleForm>
  </Edit>
);

export const CouponCodesShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="code" />
      <TextField source="status" />
      <TextField source="email" />
      <FunctionField label="Coupon set" render={(item) => (item?.couponSet ? <Link to={`/coupons/sets/${item.couponSet?.id}/show`}>{item.couponSet?.name}</Link> : 'Unassigned')} />
      <FunctionField
        label="User"
        render={(item) =>
          item?.user ? (
            <Link to={`/users/${item.user.id}/show/3`}>
              {item.user.name} ({item.user.email})
            </Link>
          ) : (
            'Unassigned'
          )
        }
      />
      <FunctionField
        label="Subscription"
        render={(item) => (item?.userSubscription ? <Link to={`/users/subscriptions/${item.userSubscription.id}/show`}>{item.userSubscription?.subscription?.description}</Link> : 'Unassigned')}
      />
    </SimpleShowLayout>
  </Show>
);

export const CreateCouponCodeButton = ({ record, entityType }) => (
  <Button
    component={Link}
    to={{
      pathname: '/coupons/codes/create',
      state: {
        record: { [entityType]: { id: record.id } },
        userRestricted: true,
      },
    }}
  >
    Create coupon code
  </Button>
);

export const CouponCodesCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="show" toolbar={<Toolbar alwaysEnableSaveButton />}>
      <ReferenceInput label="Coupon set" source="couponSet.id" reference="coupons/sets" filterToQuery={(searchText) => ({ query: searchText })}>
        <AutocompleteInput optionText={(couponSet) => (couponSet ? `(${couponSet.id}) - ${couponSet.name}` : '(Empty)')} />
      </ReferenceInput>

      <ReferenceInput label="User" source="user.id" reference="users" filterToQuery={(searchText) => ({ query: searchText })}>
        <AutocompleteInput optionText={(user) => (user ? `(${user.id}) - ${user.email} - ${user.name}` : '(Empty)')} />
      </ReferenceInput>
      <TextInput source="email" required={false} />
      <TextInput source="code" placeholder="Leave empty to auto-generate" />
    </SimpleForm>
  </Create>
);

export const SelectCouponMode = (props) => {
  const form = useForm();
  return (
    <SelectInput
      {...props}
      label="Add coupon"
      source="coupon_mode"
      choices={COUPON_MODES}
      defaultValue="code"
      inputProps={{
        onChange: (
          ...[
            ,
            {
              props: { value },
            },
          ]
        ) => form.change(value === 'direct' ? 'couponCode.code' : 'coupon.id', null),
      }}
    />
  );
};

export default CouponsList;
