export const TAG_SLUGS = {
  ON_STAGE_ARTIST: 'on-stage-artist',
  INSTRUMENTALIST: 'instrumentalist',
  OFF_STAGE_ARTIST: 'off-stage-artist',
  ORGANIZATIONS: 'organizations',
  AGENTS: 'agents',
};

export const PROFESSION_SINGER = 'singer';
export const PROFESSION_INSTRUMENTALIST = 'instrumentalist';

export const PARENT_PROFESSIONS = [PROFESSION_SINGER, PROFESSION_INSTRUMENTALIST];
