import React, { useState } from 'react';
import {
  List,
  Filter,
  TextInput,
  Datagrid,
  TextField,
  Tab,
  TabbedShowLayout,
  ReferenceField,
  ReferenceManyField,
  Create,
  SimpleForm,
  required,
  ImageField,
  ImageInput,
  AutocompleteInput,
  SelectInput,
  ReferenceInput,
  FormDataConsumer,
  DateField,
  Pagination,
  useRefresh,
  Edit,
  BooleanInput,
  FunctionField,
  TopToolbar,
  EditButton,
  TabbedShowLayoutTabs,
  SimpleShowLayout,
} from 'react-admin';
import { Button, Typography, Divider, TextField as MUITextInput } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { urlValidation } from '../constants/validation';
import { CreateActions } from '../components/DefaultActions';
import { DeleteWithConfirmButtonNullable } from '../components/DeleteWithConfirmButtonNullable';
import ValidityShow from '../components/ValidityShow';
import UserLink from '../components/UserLink';
import { VALIDATION_STATUSES } from '../constants/validations';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { PublicLink } from '../components/PublicLink';
import { SubscriptionsTabContent } from '../components/SubscriptionsTabContent';
import { Suspension } from '../components/Suspension';
import CustomShowLayout from '../components/CustomShowLayout';

const useStyles = makeStyles(() => ({
  agencyLogo: {
    '& img': {
      maxWidth: 30,
      maxHeight: 30,
    },
  },
  toolbar: {
    alignItems: 'center',
  },
}));

const AgenciesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Agency ID" source="id" alwaysOn />
    <TextInput label="Search" source="query" alwaysOn />
    <SelectInput label="Validation status" source="validation_status" choices={VALIDATION_STATUSES} alwaysOn />
    <ReferenceInput label="Country" source="country_id" reference="countries" filterToQuery={(searchText) => ({ query: searchText })} sort={{ field: 'name', order: 'ASC' }} alwaysOn allowEmpty>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const DebouncedInput = (props) => {
  const [timer, setTimer] = useState();
  return (
    <MUITextInput
      onChange={(event) => {
        clearTimeout(timer);
        setTimer(
          setTimeout(() => {
            if (props.onChange) {
              props.onChange(event);
            }
          }, 300),
        );
      }}
      variant="filled"
      label={props.label}
      style={props.style}
    />
  );
};

const AgencyRoster = (props) => {
  const [profileId, setProfileId] = useState([]);
  const [query, setQuery] = useState([]);

  return (
    <>
      <br />
      <DebouncedInput onChange={(event) => setProfileId(event.target.value)} label="Profile ID" style={{ marginRight: '1rem' }} />
      <DebouncedInput onChange={(event) => setQuery(event.target.value)} label="Query" />

      <ReferenceManyField {...props} filter={{ artist_profile_id: profileId, query }} label="Roster" reference="agencies/artists" target="agency_id" pagination={<Pagination />}>
        <Datagrid>
          <ReferenceField label="Id" source="artist.profile.id" reference="profiles" link="show">
            <TextField source="id" />
          </ReferenceField>

          <ReferenceField label="Name" source="artist.profile.id" reference="profiles" link="show">
            <TextField source="name" emptyText="Empty" />
          </ReferenceField>

          <ReferenceField label="Profession" source="artist.profile.id" reference="profiles" link="show">
            <TextField source="profession.name" emptyText="Empty" />
          </ReferenceField>

          <DeleteWithConfirmButtonNullable />
        </Datagrid>
      </ReferenceManyField>
    </>
  );
};

const AgencyShowActions = (props) => {
  const classes = useStyles();
  const { basePath, data } = props;

  return (
    <div>
      <Suspension suspension={data?.suspension} type="agencies" />
      <TopToolbar className={classes.toolbar}>
        <PublicLink {...props} />
        <PublicLink edit {...props} />
        <EditButton basePath={basePath} record={data} />
      </TopToolbar>
    </div>
  );
};

export const AgenciesShow = (props) => (
  <ValidityShow {...props} title={<Breadcrumbs {...props} />} actions={<AgencyShowActions />}>
    <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" {...props} />}>
      <Tab label="Agency Info">
        <CustomShowLayout {...props}>
          {{
            column1: (
              <SimpleShowLayout>
                <TextField source="name" />
                <ImageField label="Logo" source="image.medium" title="title" />
                <TextField source="has_admin" />
                <TextField source="summary" emptyText="(empty)" />
                <TextField source="is_hidden" />
                <AgencyAgentCreateButton />
                <ReferenceManyField label="Agents" reference="agencies/agents" target="agency_id" filter={{ all: true }} pagination={<Pagination />}>
                  <Datagrid>
                    <ReferenceField label="Id" source="agent.profile.id" reference="profiles" link="show">
                      <TextField source="id" />
                    </ReferenceField>
                    <ReferenceField label="Name" source="agent.profile.id" reference="profiles" link="show">
                      <TextField source="name" />
                    </ReferenceField>
                    <TextField source="status" emptyText="(empty)" />
                    <DateField source="createdAt" />
                    <DateField source="updatedAt" />
                    <DeleteWithConfirmButtonNullable redirect={`/agencies/${props.id}/show`} />
                  </Datagrid>
                </ReferenceManyField>
              </SimpleShowLayout>
            ),
          }}
        </CustomShowLayout>
      </Tab>

      <Tab label="Agency Offices">
        <ReferenceManyField label="Offices" reference="agencies/offices" target="agency_id">
          <Datagrid>
            <TextField source="id" />
            <TextField source="address" component="pre" />
            <TextField source="web" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>

      <Tab label="Agency Subscriptions">
        <ReferenceManyField label="Subscriptions" reference="users/subscriptions" target="agency_id" perPage={10} pagination={<Pagination />}>
          <SubscriptionsTabContent />
        </ReferenceManyField>
      </Tab>

      <Tab label="Agency Roster">
        <AgencyArtistCreate targetResource="agencies/artists" />
        <AgencyRoster />
      </Tab>
    </TabbedShowLayout>
  </ValidityShow>
);

export const AgencyAgentCreateButton = ({ record, user }) => (
  <Button
    component={Link}
    to={{
      pathname: '/agencies/agents/create',
      state: {
        record: { ...(record && { agency: { id: record.id } }), ...(user && { user: { id: user.id } }) },
      },
    }}
  >
    Add agents
  </Button>
);

export const AgentCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect={(baseUrl, id, record) => `/agencies/${record.agency.id}/show`}>
      <Typography variant="h6">Add agency team member</Typography>
      <Divider />
      <ReferenceInput label="User" source="user.id" reference="users" filterToQuery={(searchText) => ({ query: searchText })}>
        <AutocompleteInput optionText={(user) => `(${user.id}) - ${user.email} - ${user.name}`} />
      </ReferenceInput>

      <TextInput source="workEmail" label="Work email" />
      <FormDataConsumer>
        {({ formData }) =>
          formData?.user?.id && (
            <ReferenceInput
              label="Profiles"
              source="agent.profile.id"
              reference="profiles"
              filter={{ access_user_id: formData?.user?.id }}
              filterToQuery={(searchText) => ({ query: searchText, profile_type_id: 2 })}
            >
              <AutocompleteInput optionText={(record) => record?.name || `(empty, #ID: ${record?.id || ''})`} />
            </ReferenceInput>
          )
        }
      </FormDataConsumer>

      <ReferenceInput
        label="Agency"
        filter={{ limit: 25 }}
        source="agency.id"
        reference="agencies"
        disabled={!!props.location?.state?.record?.agency}
        shouldRenderSuggestions={false}
        filterToQuery={(searchText) => ({ query: searchText })}
      >
        {props.location?.state?.record?.agency ? <SelectInput optionText="name" /> : <AutocompleteInput optionText="name" />}
      </ReferenceInput>

      <SelectInput
        source="status"
        defaultValue="agent"
        choices={[
          { id: 'agent', name: 'Agent' },
          { id: 'admin', name: 'Admin' },
        ]}
      />
    </SimpleForm>
  </Create>
);

export const AgencyArtistCreate = (props) => {
  const doRefresh = useRefresh();
  return (
    <Create {...props} resource={props.targetResource}>
      <SimpleForm redirect={doRefresh}>
        <ReferenceInput label="Agency" filter={{ limit: 1, id: props.record?.id }} source="agency.id" reference="agencies" shouldRenderSuggestions={false} defaultValue={props.record?.id} disabled>
          <SelectInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput label="Agent profile" source="agent.id" reference="agencies/agents" filter={{ agency_id: props.record?.id }} filterToQuery={(searchText) => ({ query: searchText })}>
          <AutocompleteInput optionText={(agentData) => agentData?.agent?.profile?.name || agentData?.agent?.name || ''} />
        </ReferenceInput>

        <ReferenceInput label="Artist profile" source="artist.profile.id" reference="profiles" filter={{ profile_type_id: 1 }} filterToQuery={(searchText) => ({ query: searchText })}>
          <AutocompleteInput optionText={(profile) => `(${profile.id}) - ${profile?.profession?.name || ''} - ${profile.name}`} />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export const AgenciesList = (props) => {
  const classes = useStyles();

  return (
    <List exporter={false} title={<Breadcrumbs {...props} />} filters={<AgenciesFilter />} sort={{ field: 'id', order: 'DESC' }} bulkActionButtons={false} {...props} perPage={25}>
      <Datagrid rowClick="show">
        <TextField label="ID" source="id" />
        <ImageField source="image.small" label="Logo" className={classes.agencyLogo} />
        <TextField label="Agency Name" source="name" />
        <TextField label="Validation status" source="validationStatus.name" />
        <FunctionField label="Created by" render={(record) => <UserLink record={record?.createdBy} />} />
        <FunctionField label="Suspension" render={(record) => <Suspension suspension={record.suspension} type="agencies" list />} />
        <FunctionField label="Validated by(latest)" render={(record) => <UserLink record={record?.lastValidationBy} />} />
      </Datagrid>
    </List>
  );
};

export const AgencyCreate = (props) => (
  <Create title="Create agency" actions={<CreateActions />} {...props}>
    <SimpleForm submitOnEnter={false}>
      <TextInput source="name" validate={required()} label="Name" />
      <ImageInput source="image" label="Logo image" accept="image/*">
        <ImageField source="file" title="title" />
      </ImageInput>
      <ReferenceInput label="Country" source="country.id" reference="countries" filterToQuery={(searchText) => ({ query: searchText, limit: 300 })} sort={{ field: 'name', order: 'ASC' }}>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <div>
        <TextInput source="web" validate={urlValidation} label="Website" />
        <TextInput source="summary" label="Summary" />
      </div>
    </SimpleForm>
  </Create>
);

export const AgencyEdit = (props) => (
  <Edit title={<Breadcrumbs {...props} />} {...props}>
    <SimpleForm submitOnEnter={false}>
      <TextInput source="name" validate={required()} label="Name" />
      <ImageInput source="image" label="Logo image" accept="image/*">
        <ImageField source="medium" title="title" />
      </ImageInput>
      <ReferenceInput label="Country" source="country.id" reference="countries" filterToQuery={(searchText) => ({ query: searchText, limit: 300 })} sort={{ field: 'name', order: 'ASC' }}>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <div>
        <TextInput source="web" validate={urlValidation} label="Website" />
        <TextInput source="summary" label="Summary" />
      </div>
      <BooleanInput source="is_hidden" label="Is hidden" />
    </SimpleForm>
  </Edit>
);

export default AgenciesList;
