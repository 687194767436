export const REGISTRATION_LINK_TEMPLATE = (id) => `${process.env.REACT_APP_OPERABASE_URL}/register?id=${id}`;

export const REGISTRATION_STEPS = [
  { id: '1', name: '1.1 (New registration starts)' },
  { id: '1.1', name: '1.1 (Update user first|last name)' },
  { id: '1.2', name: '1.2 (Validate email)' },
  { id: '1.2.1', name: '1.2.1 (Revalidate email)' },
  { id: '1.3', name: '1.3 (Create profile for existing user)' },
  { id: '1.4', name: '1.4 (Create referral account)' },
  { id: '2', name: '2 (Cohort selection)' },
  { id: '3', name: '3 (Create profile form)' },
  { id: '3.2', name: '3.2 (Profile match)' },
  { id: '3.3', name: '3.3 (Digital presence)' },
  { id: '3.4', name: '3.4 (Add performance)' },
  { id: '4', name: '4 (Employment type)' },
  { id: '5', name: '5 (Organization search or create new)' },
  { id: '6.1', name: '6.1 (Organization performance form)' },
  { id: '6.2', name: '5 (Organization work type form)' },
  { id: '6.3', name: '5 (Organization role form)' },
  { id: '6.4', name: '5 (Tool preference form)' },
  { id: '8', name: '8 (Subscription)' },
  { id: '9', name: '9 (Congratulation)' },
  { id: '10', name: '10 (Finished)' },
];

export const REGISTRATION_PROFILE_TYPES = [
  {
    id: 1,
    name: 'Artist',
  },
  {
    id: 2,
    name: 'Agents',
  },
  {
    id: 3,
    name: 'Arts Organisation Professional',
  },
  {
    id: 4,
    name: 'Audience',
  },
  {
    id: 6,
    name: 'Casting',
  },
  {
    id: 7,
    name: 'Academics',
  },
  {
    id: 8,
    name: 'Journalist & Media',
  },
];

export const REGISTRATION_CONTACT = [
  { id: 'SMS', name: 'SMS' },
  { id: 'Whatsapp', name: 'Whatsapp' },
  { id: 'Email', name: 'Email' },
  { id: 'Phone Call', name: 'Phone Call' },
];

export const REGISTRATION_TIMES = [
  { name: '1am - 2am', id: '1am - 2am' },
  { name: '2am - 3am', id: '2am - 3am' },
  { name: '3am - 4am', id: '3am - 4am' },
  { name: '4am - 5am', id: '4am - 5am' },
  { name: '5am - 6am', id: '5am - 6am' },
  { name: '6am - 7am', id: '6am - 7am' },
  { name: '7am - 8am', id: '7am - 8am' },
  { name: '8am - 9am', id: '8am - 9am' },
  { name: '9am - 10am', id: '9am - 10am' },
  { name: '10am - 11am', id: '10am - 11am' },
  { name: '11am - 12am', id: '11am - 12am' },
  { name: '12am - 1pm', id: '12am - 1pm' },
  { name: '1pm - 2pm', id: '1pm - 2pm' },
  { name: '2pm - 3pm', id: '2pm - 3pm' },
  { name: '3pm - 4pm', id: '3pm - 4pm' },
  { name: '4pm - 5pm', id: '4pm - 5pm' },
  { name: '5pm - 6pm', id: '5pm - 6pm' },
  { name: '6pm - 7pm', id: '6pm - 7pm' },
  { name: '7pm - 8pm', id: '7pm - 8pm' },
  { name: '8pm - 9pm', id: '8pm - 9pm' },
  { name: '9pm - 10pm', id: '9pm - 10pm' },
  { name: '10pm - 11pm', id: '10pm - 2pm' },
  { name: '11pm - 12pm', id: '11pm - 2pm' },
  { name: '12pm - 1am', id: '12pm - 1am' },
];

export const REGISTRATION_COHORT_TYPES = {
  1: 'Audience',
  2: 'Industry professional',
  3: 'Journalist and media',
  4: 'Academics',
};
