import React from 'react';
import { Box, TableCell } from '@material-ui/core';
import DisplayTable from './DisplayTable';
import DisplayText from './DisplayText';
import { cloneDiff } from './utils';
import apiClient from '../../utils/api';

const DATE_MODES = ['stage', 'digital-ls', 'digital-vod'];

const getData = async (productionId) => {
  const data = {};

  for (const mode of DATE_MODES) {
    data[mode] = (
      await apiClient.get(`productions/${productionId}/productionPerformances`, {
        params: {
          as_edit: true,
          mode,
          page: 1,
          limit: 100,
        },
      })
    )?.data?.data;
  }

  return data;
};

export const parseProductionDates = (props) => {
  const { production, changeRequest } = props;

  return new Promise(async (resolve, reject) => {
    const a = changeRequest ? await getData(production.id) : { stage: [], 'digital-ls': [], 'digital-vod': [] };
    const b = changeRequest ? await getData(changeRequest.revisionId) : await getData(production.id);

    const data = {};

    for (const mode of DATE_MODES) {
      data[mode] = cloneDiff(a[mode], b[mode]);
    }

    resolve(data);
  });
};

const StageRow = (props) => (
  <>
    <TableCell>
      <DisplayText path="" changes={props.changes} select="startDate" />
    </TableCell>
    <TableCell>
      <DisplayText path="" changes={props.changes} select="startTime" />
    </TableCell>
    <TableCell>
      <DisplayText path="" changes={props.changes} select="venue.name" />
    </TableCell>
    <TableCell>
      <DisplayText path="" changes={props.changes} select="hostedBy.name" />
    </TableCell>
    <TableCell>
      <DisplayText path="" changes={props.changes} select="soldOut" />
    </TableCell>
  </>
);

const DigitalLS = (props) => {
  const { changes } = props;

  return (
    <>
      <TableCell>
        <DisplayText path="" changes={changes} select="startDate" />
      </TableCell>
      <TableCell>
        <DisplayText path="" changes={changes} select="timezone" />
        <DisplayText path="" changes={changes} select="startTime" />
      </TableCell>
      <TableCell>
        <DisplayText path="" changes={changes} select="attributes[0].url" />
      </TableCell>
      <TableCell>
        <DisplayText path="" changes={changes} select="attributes[0].paymentType" />
      </TableCell>
      <TableCell>
        <DisplayText path="" changes={changes} select="attributes[0].availabilityRegions.[0].name" />
      </TableCell>
      <TableCell>
        <DisplayText path="" changes={changes} select="recordingDate.startDate" />
        <DisplayText path="" changes={changes} select="venue.name" />
        <DisplayText path="" changes={changes} select="venueStage.name" />
      </TableCell>
    </>
  );
};

const DigitalVod = (props) => {
  const { changes } = props;

  return (
    <>
      <TableCell>
        <DisplayText path="" changes={changes} select="startDate" />
        <DisplayText path="" changes={changes} select="endDate" />
      </TableCell>
      <TableCell>
        <DisplayText path="" changes={changes} select="timezone" />
        <DisplayText path="" changes={changes} select="startTime" />
      </TableCell>
      <TableCell>
        <DisplayText path="" changes={changes} select="attributes[0].url" />
      </TableCell>
      <TableCell>
        <DisplayText path="" changes={changes} select="attributes[0].paymentType?" />
      </TableCell>
      <TableCell>
        <DisplayText path="" changes={changes} select="attributes[0].availabilityRegions[0].name" />
      </TableCell>
      <TableCell>
        <DisplayText path="" changes={changes} select="recordingDate.startDate" />
        <DisplayText path="" changes={changes} select="venue.name" />
        <DisplayText path="" changes={changes} select="venueStage.name" />
      </TableCell>
    </>
  );
};

export const ProductionDates = (props) => (
  <Box container="true">
    {DATE_MODES.map((mode, i) => {
      const productionDates = props.changes[mode];
      if (productionDates.length === 0) {
        return <Box key={`dateMode${i}`}></Box>;
      }
      switch (i) {
        case 0:
          return (
            <Box key={`dateMode${i}`}>
              <DisplayTable
                title="Physical performance dates"
                header={['Date', 'Time', 'Venue', 'Hosted by', 'Mark as sold out']}
                changes={productionDates}
                headerColor="#FFF"
                headerBackground="rgb(0, 103, 197)"
              >
                <StageRow />
              </DisplayTable>
            </Box>
          );

        case 1:
          return (
            <Box key={`dateMode${i}`}>
              <DisplayTable
                title="Livestream dates"
                header={['Date', 'TIME AND TIME ZONE', 'LIVESTREAM, TICKET, WEBSITE LINK', 'TYPE', 'AVAILABLE TO', 'RECORDING DATE AND VENUE']}
                headerColor="#FFF"
                headerBackground="rgb(39, 174, 96)"
                changes={productionDates}
              >
                <DigitalLS />
              </DisplayTable>
            </Box>
          );

        case 2:
          return (
            <Box key={`dateMode${i}`}>
              <DisplayTable
                title="Full performance video or VOD dates"
                header={['AVAILABILITY', 'TIME AND TIME ZONE', 'VIDEO/SUBSCRIPTION PURCHASE PAGE/WEBSITE', 'TYPE', 'AVAILABLE TO', 'RECORDING DATE AND VENUE']}
                changes={productionDates}
                headerColor="#FFF"
                headerBackground="rgb(242, 201, 76)"
              >
                <DigitalVod />
              </DisplayTable>
            </Box>
          );
        default:
          return null;
      }
    })}
  </Box>
);
