import { head, filter, join, map } from 'lodash';

export const getComposerFromCreators = (creators = []) => {
  const composers = filter(creators, (creator) => creator?.profession?.slug === 'composer');

  return head(composers);
};

export const getComposerNamesFromCreators = (creators = []) => {
  const composers = filter(creators, (creator) => creator?.profession?.slug === 'composer');

  return join(map(composers, 'profile.name'), ' ,');
};

export const getComposersFromCreators = (creators = []) => {
  const composers = filter(creators, (creator) => creator?.profession?.slug === 'composer');

  return composers;
};

export const getLibrettistsCFromCreators = (creators = []) => {
  const composers = filter(creators, (creator) => creator?.profession?.slug === 'librettist');
  return composers;
};
