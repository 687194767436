import React, { useState, useEffect } from 'react';

import {
  Show,
  List,
  Filter,
  ReferenceField,
  TextInput,
  ArrayField,
  Datagrid,
  TextField,
  SimpleShowLayout,
  ReferenceManyField,
  SelectInput,
  Create,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  AutocompleteInput,
  DateInput,
  SimpleForm,
  TopToolbar,
  UrlField,
  FunctionField,
  useUpdate,
  useNotify,
  Confirm,
  FormDataConsumer,
  useRedirect,
  useDataProvider,
} from 'react-admin';

import {
  Button,
  ButtonGroup,
  FormControl as MUIFormControl,
  InputLabel as MUIInputLabel,
  Select as MUISelect,
  MenuItem as MUIMenuItem,
  TextField as MUITextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel as MUIFormLabel,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { isEmpty, head } from 'lodash';
import { InvoicesListColors } from '../constants/stylingConstants';
import CustomDateField from '../components/customFields/CustomDateField';
import { SelectCouponMode } from './CouponsModel';
import FormConfirmationDialog from '../components/FormConfirmationDialog';
import DateRange from '../components/DateRange';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { INVOICE_STATUSES, REFUND_APPROVAL_EMAILS } from '../constants/finance';
import UserLink from '../components/UserLink';
import storage from '../utils/storage';
import { paymentMethodShortDescription } from '../utils/users';

const InvoicesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="query" alwaysOn />
    <SelectInput label="Invoice status" source="statuses" choices={INVOICE_STATUSES} alwaysOn />
    <DateRange startDateSource="issue_date_from" endDateSource="issue_date_to" label="Issue date" alwaysOn />
    <DateRange startDateSource="due_date_from" endDateSource="due_date_to" label="Payment expected date" alwaysOn />
  </Filter>
);

export const InvoicesShow = (props) => (
  <Show {...props} title={<Breadcrumbs {...props} />} actions={<InvoiceActions />}>
    <SimpleShowLayout>
      <TextField source="number" />
      <CustomDateField source="issueDate" />
      <TextField source="status" />
      <TextField source="externalNumber" />
      <ReferenceField source="creator.user.id" reference="users" link="show" label="Creator user" emptyText="(Empty)">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="buyer.profile.id" reference="profiles" link="show" label="Buyer profile" emptyText="(Empty)">
        <TextField source="name" />
      </ReferenceField>
      <UrlField source="file.original" target="_blank" label="PDF link" />
      <TextField source="totalPriceInclVat.amount" label="Total price including VAT" />
      <TextField source="totalPriceExclVat.amount" label="Total price excluding VAT" />
      <TextField source="totalVatAmount.amount" label="Total VAT" />
      <CustomDateField source="dueDate" />

      <ArrayField source="lines">
        <Datagrid>
          <TextField source="id" />
          <TextField source="quantity" />
          <TextField source="product.name" />
        </Datagrid>
      </ArrayField>

      <ReferenceManyField label="Credit notes" reference="invoices/creditNotes" target="invoice_id">
        <Datagrid
          rowClick="show"
          rowStyle={(record) => ({
            backgroundColor: InvoicesListColors[record?.status] || InvoicesListColors['fbadmin-default'],
          })}
        >
          <TextField source="number" />
          <TextField source="status" />
          <TextField source="external_number" />
          <FunctionField label="Amount" render={(invoice) => `€${parseFloat(invoice?.total_amount?.amount).toFixed(2)}`} />
          <FunctionField label="VAT amount" render={(invoice) => `€${parseFloat(invoice?.total_amount_incl_vat?.amount - invoice?.total_amount?.amount).toFixed(2)}`} />
        </Datagrid>
      </ReferenceManyField>

      <ReferenceManyField label="Transactions" reference="users/payments" target="invoice_id">
        <Datagrid
          rowClick="show"
          rowStyle={(record) => ({
            backgroundColor: InvoicesListColors[record?.status] || InvoicesListColors['fbadmin-default'],
          })}
        >
          <TextField source="id" />
          <FunctionField
            label="Payment method"
            render={(invoice) =>
              invoice?.userPaymentMethod ? paymentMethodShortDescription(invoice?.userPaymentMethod) : `(Deleted) ${invoice?.payment_method_type} ${invoice?.payment_method_number}`
            }
          />
          <TextField source="reference_number" label="Reference number" />
          <FunctionField label="Amount" render={(transaction) => `€${parseFloat(transaction?.amount?.amount).toFixed(2)}`} />
          <CustomDateField source="payment_date" />
          <TextField source="status" />
        </Datagrid>
      </ReferenceManyField>

      <RefundList {...props} />

      <ArrayField source="notes">
        <Datagrid>
          <TextField source="entity_type" />
          <TextField source="note" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

const CollectPaymentDialog = (props) => (
  <FormConfirmationDialog {...props}>
    <MUITextField autoFocus margin="dense" name="comment" label="Comment" variant="outlined" multiline fullWidth />
    <MUITextField autoFocus margin="dense" name="amount" label="Amount" variant="outlined" defaultValue={props.invoiceAmount || null} multiline fullWidth />
  </FormConfirmationDialog>
);

const MarkAsPaidDialog = (props) => (
  <FormConfirmationDialog {...props}>
    <MUITextField autoFocus margin="dense" name="reference_number" label="# Reference number" fullWidth />
    <MUIFormControl fullWidth>
      <MUIInputLabel id="payment_method_label">Payment method</MUIInputLabel>
      <MUISelect autoFocus margin="dense" name="payment_method" labelId="payment_method_label" defaultValue="" fullWidth>
        <MUIMenuItem value="">
          <em>None</em>
        </MUIMenuItem>
        <MUIMenuItem value="cash">Cash</MUIMenuItem>
        <MUIMenuItem value="check">Check</MUIMenuItem>
        <MUIMenuItem value="bank_transfer">Bank transfer</MUIMenuItem>
        <MUIMenuItem value="other">Other</MUIMenuItem>
      </MUISelect>
    </MUIFormControl>
    <MUITextField autoFocus margin="dense" name="comment" label="Comment" variant="outlined" multiline fullWidth />
  </FormConfirmationDialog>
);

const invoiceConfirmActions = {
  cancel: {
    title: 'Close',
    action: 'write_off',
    content: 'Do you really want to close (write-off) an invoice?',
  },
  request_payment: {
    title: 'Request payment',
    action: 'request_payment',
    content: 'Do you really want to request payment for invoice? It will trigger email to a customer.',
  },
  collect_payment: {
    title: 'Collect payment',
    action: 'collect_payment',
    content: 'Do you really want to collect payment for invoice? It will trigger immediate charge if credit card available.',
  },

  mark_paid: {
    title: 'Mark as paid',
    action: 'mark_paid',
    content: 'Invoice will be marked as paid with provided payment method & reference number',
  },
};

export const InvoicesDataGrid = ({ data }) => {
  const [{ confirmAction, invoiceId }, setConfirmAction] = useState({ confirmAction: null, invoiceId: null });
  const [update, { loading }] = useUpdate('invoices');
  const notify = useNotify();

  const handleInvoiceAction = (action, invoice) => (e) => {
    setConfirmAction({ confirmAction: action, invoiceId: invoice?.id });
    e.stopPropagation();
  };
  const resetInvoiceAction = () => setConfirmAction({ confirmAction: null });
  const sendInvoiceAction = (confirmAction, invoiceId) => (paymentData) => {
    update(
      {
        payload: {
          id: invoiceId,
          data: {
            status: invoiceConfirmActions[confirmAction].action,
            paymentData,
          },
          previousData: data[invoiceId],
        },
      },
      {
        onSuccess: () => notify('Action completed', 'info'),
        onFailure: (err) => notify(err.message, 'error'),
      },
    );
    resetInvoiceAction();
  };

  return (
    <>
      <MarkAsPaidDialog
        title={confirmAction ? invoiceConfirmActions[confirmAction].title : ''}
        content={confirmAction ? invoiceConfirmActions[confirmAction].content : ''}
        open={confirmAction === 'mark_paid'}
        onCancel={resetInvoiceAction}
        onConfirm={sendInvoiceAction(confirmAction, invoiceId)}
      />
      <CollectPaymentDialog
        title={confirmAction ? invoiceConfirmActions[confirmAction].title : ''}
        content={confirmAction ? invoiceConfirmActions[confirmAction].content : ''}
        invoiceAmount={data && data[invoiceId]?.totalPriceInclVat.amount}
        open={confirmAction === 'collect_payment'}
        onCancel={resetInvoiceAction}
        onConfirm={sendInvoiceAction(confirmAction, invoiceId)}
      />
      <Datagrid
        rowClick="show"
        rowStyle={(record) => ({
          backgroundColor: InvoicesListColors[record?.status] || InvoicesListColors['fbadmin-default'],
        })}
      >
        <TextField source="externalNumber" />
        <TextField source="status" />
        <FunctionField label="Amount" render={(invoice) => `€${parseFloat(invoice.totalPriceInclVat.amount).toFixed(2)}`} />
        <FunctionField label="Credit note number" render={(invoice) => (!isEmpty(invoice?.creditNotes) && invoice?.creditNotes?.[0]?.status !== 'voided' ? invoice?.creditNotes?.[0]?.number : 'NA')} />
        <CustomDateField source="issueDate" timeHidden />
        <CustomDateField label="Payment expected date" source="dueDate" timeHidden />
        <FunctionField
          label="Actions"
          render={(invoice) => (
            <>
              <ButtonGroup variant="text" disabled={loading || !['posted', 'payment_due', 'non_paid', 'processing-nonpaid', 'booked-dunning', 'booked-nonpaid'].includes(invoice.status)}>
                <Button color="primary" onClick={handleInvoiceAction('mark_paid', invoice)} disabled={!!loading || invoice?.status.match(/draft-\w*/) || invoice?.status.match(/\w*-(paid|cancelled)/)}>
                  Mark as paid
                </Button>

                <Button color="primary" onClick={handleInvoiceAction('collect_payment', invoice)}>
                  Collect payment
                </Button>
              </ButtonGroup>
            </>
          )}
        />
      </Datagrid>
    </>
  );
};

export const InvoicesList = (props) => (
  <List exporter={false} title={<Breadcrumbs {...props} />} filters={<InvoicesFilter />} perPage={25} bulkActionButtons={false} {...props} sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid
      rowClick="show"
      rowStyle={(record) => ({
        backgroundColor: InvoicesListColors[record?.status] || InvoicesListColors['fbadmin-default'],
      })}
    >
      <TextField source="id" />
      <TextField source="number" />
      <TextField source="status" />
      <TextField source="externalNumber" />
      <TextField source="creator.user.name" label="Creator user name" />
      <TextField source="buyer.profile.name" label="Buyer profile name" />
      <TextField source="totalPriceInclVat.amount" label="Total price including VAT" />
      <TextField source="totalVatAmount.amount" label="VAT amount" />
    </Datagrid>
  </List>
);

export const InvoiceActions = (props) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [{ confirmAction, invoiceId }, setConfirmAction] = useState({ confirmAction: null, invoiceId: null });
  const [update, { loading }] = useUpdate('invoices');
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const [refundOption, setRefundOption] = useState('');
  const [refundComment, setRefundComment] = useState('');
  const [payment, setPayment] = useState({});
  const { data } = props;

  const handleInvoiceAction = (action, invoice) => (e) => {
    setConfirmAction({ confirmAction: action, invoiceId: invoice?.id });
    e.stopPropagation();
  };
  const resetInvoiceAction = () => setConfirmAction({ confirmAction: null });
  const sendInvoiceAction = (confirmAction, invoiceId) => (paymentData) => {
    const onSuccess = () => notify('Action completed', 'info');
    const onFailure = (err) => notify(err.message, 'error');
    update(
      {
        payload: {
          id: invoiceId,
          data: {
            status: invoiceConfirmActions[confirmAction].action,
            paymentData,
          },
          previousData: data[invoiceId],
        },
      },
      { onSuccess, onFailure },
    );
    resetInvoiceAction();
  };

  const handleRefundModalClose = () => {
    setIsRefundModalOpen(false);
    setRefundOption('');
    setRefundComment('');
  };
  const handleChangeRefundOption = (e) => setRefundOption(e.target.value);
  const handleSubmitRefund = () => {
    dataProvider
      .create(`invoices/${data.id}/refundrequests`, {
        data: {
          refund_type: refundOption,
          comments: refundComment,
          reason_code: 'other',
        },
      })
      .then(() => {
        handleRefundModalClose();
        window.location.reload();
      })
      .catch((err) => {
        notify(err.message, 'error');
      });
  };

  useEffect(() => {
    if (data?.id) {
      dataProvider.getList('users/payments', { filter: { invoice_id: data?.id }, pagination: {}, sort: {} }).then((res) => {
        const activePayment = head(res?.data.filter((p) => p.status === 'success'));
        setPayment(activePayment);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProvider, data]);

  if (!data || !data.id) {
    return null;
  }

  return (
    <TopToolbar>
      <Button color="primary" onClick={() => setIsRefundModalOpen(true)} disabled={data.isCreditNote || isEmpty(payment)}>
        Create Refund
      </Button>
      <Confirm
        isOpen={!!(confirmAction && confirmAction !== 'mark_paid')}
        title={confirmAction ? invoiceConfirmActions[confirmAction].title : ''}
        content={confirmAction ? invoiceConfirmActions[confirmAction].content : ''}
        onClose={resetInvoiceAction}
        onConfirm={sendInvoiceAction(confirmAction, invoiceId).bind(null, null)}
      />

      <ButtonGroup variant="text" disabled={loading}>
        {/* <Button color="primary" disabled={loading || ['booked-paid', 'paid'].includes(data.status)} onClick={handleInvoiceAction('cancel', data)}> */}
        <Button color="primary" disabled onClick={handleInvoiceAction('cancel', data)}>
          Close invoice
        </Button>
      </ButtonGroup>
      {/** Refund dialog */}
      <Dialog open={isRefundModalOpen} onClose={handleRefundModalClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Create Refund</DialogTitle>
        <DialogContent>
          <MUIFormControl component="fieldset">
            <MUIFormLabel component="legend">Choose refund type*</MUIFormLabel>
            <RadioGroup row aria-label="Payment method" name="row-radio-buttons-group">
              <FormControlLabel
                value="PAYMENT_METHOD"
                control={<Radio disabled={payment?.userPaymentMethod?.wallet_type !== 'card'} checked={refundOption === 'PAYMENT_METHOD'} onChange={handleChangeRefundOption} />}
                label="Refund to card"
              />
              <FormControlLabel value="BANK_TRANSFER" control={<Radio checked={refundOption === 'BANK_TRANSFER'} onChange={handleChangeRefundOption} />} label="Refund via bank transfer " />
            </RadioGroup>
            <MUITextField label="Comments*" variant="filled" color="primary" multiline rows={4} value={refundComment} onChange={(e) => setRefundComment(e.target.value)} focused />
          </MUIFormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRefundModalClose}>Cancel</Button>
          <Button style={{ backgroundColor: 'red', color: 'white' }} variant="contained" onClick={handleSubmitRefund} disabled={!refundComment || !refundOption} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </TopToolbar>
  );
};

export const InvoicesCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="number" />
      <TextInput source="externalNumber" />
      <TextInput source="status" defaultValue="draft" disabled />

      <ReferenceInput label="Buyer user" source="buyer.user.id" reference="users" filterToQuery={(searchText) => ({ query: searchText })} sort={{ field: 'email', order: 'ASC' }}>
        <AutocompleteInput source="buyer.user" optionText={(user) => `(${user.id}) - ${user.email} - ${user.name}`} matchSuggestion={() => true} />
      </ReferenceInput>

      <ReferenceInput
        label="Buyer profile"
        source="buyer.profile.id"
        reference="profiles"
        filterToQuery={(searchText) => ({
          query: searchText,
          tier: 'D',
        })}
        sort={{ field: 'relevance', order: 'DESC' }}
      >
        <AutocompleteInput optionText={(profile) => `(${profile.id}) - ${profile.name} - ${profile?.profession?.name}`} matchSuggestion={() => true} />
      </ReferenceInput>

      <TextInput source="totalPriceInclVat.amount" label="Total price including VAT" />
      <TextInput source="totalPriceExclVat.amount" label="Total price excluding VAT" />
      <TextInput source="totalVatAmount.amount" label="Total VAT" />
      <DateInput source="dueDate" />

      <ArrayInput source="lines">
        <SimpleFormIterator>
          <TextInput source="quantity" label="Quantity" />
          <ReferenceInput label="Product" source="product.id" reference="products" filterToQuery={(searchText) => ({ query: searchText })}>
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput source="vatAmount.amount" label="VAT amount" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);

export const InvoicesCreateOneTimeCharge = (props) => {
  const redirect = useRedirect();
  return (
    <Create
      {...props}
      onSuccess={({ data }) => {
        redirect(`/invoices/${data.id}/show`);
      }}
    >
      <SimpleForm>
        <ReferenceInput label="User" source="buyer.user.id" reference="users" filterToQuery={(searchText) => ({ query: searchText })}>
          <AutocompleteInput optionText={(user) => `(${user.id}) - ${user.email} - ${user.name}`} />
        </ReferenceInput>

        <TextInput source="oneTimeData.amount" label="One-time charge amount" />

        <ReferenceInput label="Non-recurring addon" source="oneTimeData.addon.id" reference="addons" filterToQuery={(searchText) => ({ query: searchText })} sort={{ field: 'name', order: 'ASC' }}>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>

        <SelectCouponMode />
        <FormDataConsumer>
          {({ formData }) =>
            formData.coupon_mode === 'direct' ? (
              <ReferenceInput label="Coupon" source="oneTimeData.coupon.id" reference="coupons" filterToQuery={(searchText) => ({ query: searchText, duration_type: 'one_time' })}>
                <AutocompleteInput optionText="name" />
              </ReferenceInput>
            ) : null
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData }) =>
            formData?.buyer?.user?.id ? (
              <ReferenceInput
                label="Payment method"
                source="id"
                reference="users/cards"
                filter={{ user_id: formData?.buyer?.user?.id, is_default: true }}
                filterToQuery={(searchText) => ({ query: searchText })}
              >
                <DefaultCard disabled={!formData?.buyer?.user?.id} />
              </ReferenceInput>
            ) : null
          }
        </FormDataConsumer>

        <DateInput source="oneTimeData.dateFrom" />
        <DateInput source="oneTimeData.dateTo" />

        <FormDataConsumer>
          {({ formData }) => (
            <ReferenceInput
              label="Subscription"
              source="userSubscription.id"
              reference="users/subscriptions"
              filter={{ user_id: formData?.buyer?.user?.id }}
              filterToQuery={(searchText) => ({ query: searchText })}
            >
              <SelectInput optionText={(userSubscription) => `(${userSubscription.id}) - ${userSubscription.subscription.description} - ${userSubscription.paymentType}`} />
            </ReferenceInput>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

const DefaultCard = (props) => {
  const defaultCard = !props.disabled && props.choices && props.choices.find((card) => card.isDefault);
  return <SelectInput {...props} disabled optionText={(card) => (card ? `${card.cardType} ${card.number}` : null)} defaultValue={defaultCard?.id} value={defaultCard?.id} />;
};

const RefundList = (props) => {
  const notify = useNotify();
  const invoiceId = props?.record?.id;
  const dataProvider = useDataProvider();
  const [refunds, setRefunds] = useState([]);
  const isUpdateRefundAllowed = REFUND_APPROVAL_EMAILS.includes(storage.getUser());

  const handleUpdateRefund = ({ refund, isApprove }) => {
    dataProvider
      .update(`invoices/${refund?.invoice?.id}/refundrequests`, {
        id: refund?.id,
        data: { status: isApprove ? 'APPROVE' : 'REJECT' },
      })
      .then(() => {
        if (isApprove) {
          notify('Refund approved successfully', 'success');
        } else {
          notify('Refund rejected successfully', 'success');
        }

        window.location.reload();
      })
      .catch((err) => {
        notify(err.message, 'error');
      });
  };

  useEffect(() => {
    if (invoiceId) {
      dataProvider.getList(`/invoices/${invoiceId}/refundrequests`, { filter: {}, pagination: {}, sort: {} }).then(({ data }) => setRefunds(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProvider]);

  if (refunds.length) {
    return (
      <div>
        <h6 style={{ color: 'rgba(0, 0, 0, 0.54)', fontWeight: 500 }}>Refunds</h6>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Refund type</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Comments</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {refunds.map((r) => (
                <TableRow key={r.id}>
                  <TableCell scope="row">{r?.id}</TableCell>
                  <TableCell scope="row">{r.status}</TableCell>
                  <TableCell scope="row">{r.refund_type}</TableCell>
                  <TableCell scope="row">€{parseFloat(r.refund_amount?.amount).toFixed(2)}</TableCell>
                  <TableCell scope="row">
                    <UserLink record={r?.user} />
                  </TableCell>
                  <TableCell scope="row">{r.comments}</TableCell>
                  <TableCell scope="row">
                    <Button
                      disabled={['REJECT', 'APPROVE'].includes(r?.status) || !isUpdateRefundAllowed}
                      style={{ marginRight: 10 }}
                      size="small"
                      onClick={() => handleUpdateRefund({ refund: r, isApprove: false })}
                    >
                      Reject
                    </Button>
                    <Button
                      disabled={['REJECT', 'APPROVE'].includes(r?.status) || !isUpdateRefundAllowed}
                      size="small"
                      color="primary"
                      variant="contained"
                      onClick={() => handleUpdateRefund({ refund: r, isApprove: true })}
                    >
                      Approve
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  return null;
};

export default InvoicesList;
