/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Datagrid, ReferenceManyField, TextField, Pagination, UrlField, Button, Confirm, useMutation, useNotify, FunctionField } from 'react-admin';

import { VALIDATION_STATUSES_IDS } from '../constants/validations';
import EntityLink from './EntityLink';

const useStyles = makeStyles(() => ({
  button: {
    textTransform: 'inherit',
    margin: '15px 0px',
  },
}));

const ExternalReferencesTab = (props) => {
  const { entityId, entityType, link, displayEntity, displayUrl } = props;
  const classes = useStyles();
  const notify = useNotify();
  const [isLoading, setIsLoading] = useState(false);

  const UpdateValidationStatusButton = ({ record, validationStatusId }) => {
    const [dialogIsOpen, setDialogOpen] = useState(false);
    const [mutate] = useMutation();
    const applyAction = () =>
      mutate(
        {
          type: 'update',
          resource: 'system/entity_external_references',
          payload: {
            id: record.id,
            data: {
              validationStatus: {
                id: validationStatusId,
              },
            },
          },
        },
        {
          onSuccess: () => {
            notify('Validation Status updated', 'info');
            setIsLoading(false);

            if (props.onCLose) {
              props.onCLose();
            }
          },
          onFailure: (err) => {
            setIsLoading(false);
            notify(err?.message, 'error');
          },
        },
      );

    const title = 'Confirmation';
    const content = `The external reference on approval might be used to enrich the information on the entity. Once reference is approved/rejected for entity it can't be reverted. Are you sure of the action?`;
    const handleDialogClose = () => {
      setDialogOpen(false);
    };

    const handleConfirm = () => {
      applyAction();
      setDialogOpen(false);
    };

    const handleClick = () => {
      setDialogOpen(true);
    };

    return (
      <div>
        <Button
          className={classes.button}
          variant="contained"
          label={validationStatusId === VALIDATION_STATUSES_IDS.VALID ? 'Approve' : 'Reject'}
          onClick={handleClick}
          disabled={isLoading || [VALIDATION_STATUSES_IDS.VALID, VALIDATION_STATUSES_IDS.INVALID].includes(record?.validationStatus?.id)}
        />
        <Confirm isOpen={dialogIsOpen} title={title} content={content} onConfirm={handleConfirm} onClose={handleDialogClose} />
      </div>
    );
  };

  return (
    <ReferenceManyField
      {...props}
      target="reference_id"
      reference="system/entity_external_references"
      pagination={<Pagination />}
      label="Refs"
      filter={{ entity_id: entityId || null, entity_type: entityType || null, link: link || null }}
    >
      <Datagrid>
        <TextField source="id" />
        {displayEntity ? (
          <FunctionField
            label="Entity"
            render={(record) => (
              // <PopupComponent visible={1} url="google.com"/>
              <EntityLink entity={record?.entityDetails} entityType={`${record?.entityType?.slug}s`} />
            )}
          ></FunctionField>
        ) : null}
        {displayUrl ? <UrlField source="url" /> : null}
        <TextField source="validationStatus.name" label="Validation Status" />
        <TextField source="validatedByUser.email" label="Validated User" />

        {/* <EntityLink entity='entityDetails' entityType='entityType.slug' /> */}
        <UpdateValidationStatusButton validationStatusId={VALIDATION_STATUSES_IDS.VALID} />
        <UpdateValidationStatusButton validationStatusId={VALIDATION_STATUSES_IDS.INVALID} />
      </Datagrid>
    </ReferenceManyField>
  );
};

export default ExternalReferencesTab;
