import React from 'react';
import { Show, List, Filter, TextInput, Datagrid, TextField, FunctionField, SimpleShowLayout } from 'react-admin';
import { Breadcrumbs } from '../components/Breadcrumbs';

const ProductsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="query" alwaysOn />
  </Filter>
);

export const ProductsShow = (props) => (
  <Show {...props} title={<Breadcrumbs {...props} />}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="productType" />
      <TextField source="paymentType" />
      <TextField source="vat.amount" label="priceInclVat.currency" />
      <TextField source="priceExclVat.amount" label="priceInclVat.currency" />
      <TextField source="priceInclVat.amount" label="priceInclVat.currency" />
      <TextField source="subscription.name" label="subscription type" />
    </SimpleShowLayout>
  </Show>
);

export const ProductsList = (props) => (
  <List exporter={false} title={<Breadcrumbs {...props} />} filters={<ProductsFilter />} bulkActionButtons={false} {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="slug" label="SKU" />
      <TextField source="name" />
      <TextField source="paymentType" />
      <FunctionField label="Price" render={(product) => `${product.priceInclVat.currency} ${product.priceInclVat.amount}`} />
    </Datagrid>
  </List>
);

export default ProductsList;
