import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { get, filter, isNumber } from 'lodash';
import apiClient from '../utils/api';
import EntityLink from './EntityLink';

const RelatedEntitiesValidation = ({ entityId, entityType, hideLabel }) => {
  const [entitiesValidation, setEntitiesValidation] = useState([]);

  useEffect(() => {
    apiClient.get('data/related_entities', { params: { entity_type: entityType, entity_id: entityId } }).then((res) => {
      const validations = filter(get(res, 'data.data', []), (validation) => isNumber(validation?.pending_validation_id));
      setEntitiesValidation(validations);
    });
  }, [entityId, entityType]);

  if (!entitiesValidation.length) return null;

  return (
    <div>
      <Typography variant="body2" gutterBottom>
        {!hideLabel && <span style={{ fontWeight: 'bold' }}>Related entities validation: </span>}
        {entitiesValidation.map((validation) => (
          <EntityLink entityType="validations" entity={{ ...validation?.entity, entity_type: validation?.entity_type, id: validation?.pending_validation_id }} />
        ))}
      </Typography>
    </div>
  );
};

export default RelatedEntitiesValidation;
