export const MEDIA_TYPES = [
  {
    id: '1',
    name: 'Image',
  },
  {
    id: '2',
    name: 'Video',
  },
  {
    id: '3',
    name: 'Audio',
  },
  {
    id: '4',
    name: 'LS & VOD',
  },
];

export const MEDIA_STATUSES = [
  {
    id: '1',
    name: 'Processing',
  },
  {
    id: '2',
    name: 'Completed',
  },
  {
    id: '3',
    name: 'Error',
  },
  {
    id: '4',
    name: 'Not Available',
  },
];

export const MEDIA_TAG_TYPES = [
  {
    id: '1',
    name: 'Profiles',
  },
  {
    id: '2',
    name: 'organizations',
  },
  {
    id: '3',
    name: 'Venues',
  },
  {
    id: '4',
    name: 'Productions',
  },
  {
    id: '5',
    name: 'Composer',
  },
  {
    id: '6',
    name: 'Date',
  },
  {
    id: '8',
    name: 'Work',
  },
  {
    id: '9',
    name: 'Cast',
  },
];
