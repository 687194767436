/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import {
  List,
  ArrayField,
  SingleFieldList,
  ReferenceField,
  Datagrid,
  ChipField,
  TextField,
  FunctionField,
  ReferenceManyField,
  Filter,
  SelectInput,
  TextInput,
  ReferenceInput
} from 'react-admin';
import CustomDateTimeInput from '../components/customFields/CustomDateTimeInput';
import { PROFILE_TYPES } from '../constants/profileTypes';
import useUserPermissions from '../utils/useUserPermissions';

export const SUBSCRIPTION_PAYMENT_TYPES = [
  { id: 'free', name: 'Free' },
  { id: 'paid', name: 'Paid' },
  { id: 'gifted', name: 'Gifted' },
  { id: 'expired', name: 'Expired' }
]

export const SUBSCRIPTION_BILLING_TERMS = [
  { id: 'monthly', name: 'Monthly' },
  { id: 'yearly', name: 'Yearly' }
]

export const INVOICE_PAYMENT_STATUSES = [
  { id: 'failed', name: 'Failed' },
  { id: 'pendingretry', name: 'Pending retry' },
  { id: 'declined', name: 'Declined' },
  { id: 'pendingresponse', name: 'Pending response' },
  { id: 'pendingpayment', name: 'Pending payment' },
  { id: 'cancelled', name: 'Cancelled' },
  { id: 'paid', name: 'Paid' },
  { id: 'nonpaid', name: 'Nonpaid' },
  { id: 'dunning', name: 'Dunning' }
]

export const SUBSCRIPTION_STATUSES = [
  { id: 'future', name: 'Future' },
  { id: 'in_trial', name: 'In trial' },
  { id: 'active', name: 'Active' },
  { id: 'non_renewing', name: 'Non-renewing' },
  { id: 'paused', name: 'Paused' },
  { id: 'cancelled', name: 'Cancelled' }
];

export const UserStatsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="User ID" source="id" alwaysOn />
    <TextInput label="Search" source="query" alwaysOn />
    <SelectInput label="Profile Type" source="profile_type_id" choices={PROFILE_TYPES} optionText="name" optionValue="id" alwaysOn />
    <SelectInput label="Subscription type" source="subscription_payment_type" choices={SUBSCRIPTION_PAYMENT_TYPES} optionText="name" optionValue="id" alwaysOn />
    <ReferenceInput label="Subscription plan" source="subscription_id" reference="subscriptions" alwaysOn allowEmpty >
      <SelectInput alwaysOn allowEmpty optionText="description" />
    </ReferenceInput>
    <SelectInput label="Billing term" source="billing_term" choices={SUBSCRIPTION_BILLING_TERMS} optionText="name" optionValue="id" alwaysOn />
    <SelectInput label="Subscription status" source="user_subscription_status" choices={SUBSCRIPTION_STATUSES} optionText="name" optionValue="id" alwaysOn />
    <SelectInput label="Invoice payment status" source="invoice_payment_status" choices={INVOICE_PAYMENT_STATUSES} optionText="name" optionValue="id" alwaysOn />

    <CustomDateTimeInput label="Invoice issue date (from)" source="invoice_issue_date_from" alwaysOn />
    <CustomDateTimeInput label="Invoice issue date (to)" source="invoice_issue_date_to" alwaysOn />
    <CustomDateTimeInput label="Payment date (from)" source="payment_date_from" alwaysOn />
    <CustomDateTimeInput label="Payment date (to)" source="payment_date_to" alwaysOn />
  </Filter>
);


export const UserStatsList = (props) => {
  const { isAdmin, permissions } = useUserPermissions();
  return isAdmin && permissions && permissions.includes('userStats.get.all') ? (
    <List exporter={false} filters={<UserStatsFilter />} bulkActionButtons={false} {...props} sort={{ field: 'id', order: 'DESC' }} perPage={10}>
      <Datagrid>
        <ReferenceField label="User ID" source="id" reference="users" link="show">
          <TextField source="id" />
        </ReferenceField>

        <TextField source="email" />

        <ReferenceManyField label="Profiles Created" reference="profiles" target="access_user_id">
          <SingleFieldList linkType="show">
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceManyField>

        <ArrayField source="profileTypes" label="Cohort">
          <SingleFieldList linkType={false}>
            <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>

        <FunctionField
          label="Subscription renewed"
          render={(user) => user.subscription && user.subscription.isRenewedSubscription != null ? user.subscription.isRenewed ? 'renewed' : 'new' : 'no subscription found'} />
        <FunctionField
          label="Subscription renewed (user invoices)"
          render={(user) => user.subscription && user.subscription.isRenewedUser != null ? user.subscription.isRenewed ? 'renewed' : 'new' : 'no subscription found'} />


        <FunctionField label="Invoice amount" render={(user) => user.invoice ? `Last: ${user.invoice?.amount?.amount} Total: ${user.invoice?.totalAmount?.amount}` : null} />
        <TextField source="invoice.issueDate" label="Last invoice issue date" />
        <TextField source="invoice.paymentDate" label="Last payment date" />
        <ReferenceField label="Payment method" source="id" reference="users" link={false}>
          <TextField source="paymentMethod.name" />
        </ReferenceField>
        <TextField source="invoice.invoiceStatus" label="Last invoice status" />
        <TextField source="invoice.paymentStatus" label="Last invoice payment status" />
      </Datagrid>
    </List>
  ) : null;
};

export default UserStatsList;
