import React from 'react';
import { Table, TableBody, TableHead, TableRow, TableCell, Paper } from '@material-ui/core';

export default function UserMetricsTable({ data }) {
  return (
    <div style={{ marginTop: 10 }}>
      <Table component={Paper}>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold' }}>Metrics</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Total registrations</TableCell>
            <TableCell>{data?.metrics?.new_registrations}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Total unique registrations</TableCell>
            <TableCell>{data?.metrics?.new_registration_records}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>New trial subscriptions(All cohort)</TableCell>
            <TableCell>{data?.metrics?.new_trial}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>New paid users(All cohort)</TableCell>
            <TableCell>{data?.metrics?.new_paid}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Cancelled subscriptions</TableCell>
            <TableCell>{data?.metrics?.cancelled}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Trial expired</TableCell>
            <TableCell>{data?.metrics?.trial_expired}</TableCell>
          </TableRow>
          <TableRow style={{ backgroundColor: '#0000000a' }}>
            <TableCell style={{ fontWeight: 'bold' }}>Registration - Cohort</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Industry Professional</TableCell>
            <TableCell>{data?.registration_cohort?.industry_professional}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Audience</TableCell>
            <TableCell>{data?.registration_cohort?.audience}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Academia</TableCell>
            <TableCell>{data?.registration_cohort?.academia}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Journalist</TableCell>
            <TableCell>{data?.registration_cohort?.journalist}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>No cohort</TableCell>
            <TableCell>{data?.registration_cohort?.no_cohort}</TableCell>
          </TableRow>
          <TableRow style={{ backgroundColor: '#0000000a' }}>
            <TableCell style={{ fontWeight: 'bold' }}>Registration - Profession</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>On stage artist</TableCell>
            <TableCell>{data?.registration_profession?.on_stage_artist}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Off stage artist</TableCell>
            <TableCell>{data?.registration_profession?.off_stage_artist}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Instrumentalist</TableCell>
            <TableCell>{data?.registration_profession?.instrumentalist}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>AOS</TableCell>
            <TableCell>{data?.registration_profession?.aos}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Agency</TableCell>
            <TableCell>{data?.registration_profession?.agency}</TableCell>
          </TableRow>
          <TableRow style={{ backgroundColor: '#0000000a' }}>
            <TableCell style={{ fontWeight: 'bold' }}>New users - In trial</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Artist</TableCell>
            <TableCell>{data?.new_users_in_trial?.artist}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>AOS</TableCell>
            <TableCell>{data?.new_users_in_trial?.aos}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Agency</TableCell>
            <TableCell>{data?.new_users_in_trial?.agency}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Other</TableCell>
            <TableCell>{data?.new_users_in_trial?.casting}</TableCell>
          </TableRow>
          <TableRow style={{ backgroundColor: '#0000000a' }}>
            <TableCell style={{ fontWeight: 'bold' }}>New users - Paid</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Artist</TableCell>
            <TableCell>{data?.new_users_in_active?.artist}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>AOS</TableCell>
            <TableCell>{data?.new_users_in_active?.aos}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Agency</TableCell>
            <TableCell>{data?.new_users_in_active?.agency}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Other</TableCell>
            <TableCell>{data?.new_users_in_active?.casting}</TableCell>
          </TableRow>
          <TableRow style={{ backgroundColor: '#0000000a' }}>
            <TableCell style={{ fontWeight: 'bold' }}>Cancelled subscriptions(Only paid cancellations)</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Artist</TableCell>
            <TableCell>{data?.cancelled_subscriptions?.artist}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>AOS</TableCell>
            <TableCell>{data?.cancelled_subscriptions?.aos}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Agency</TableCell>
            <TableCell>{data?.cancelled_subscriptions?.agency}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Other</TableCell>
            <TableCell>{data?.cancelled_subscriptions?.casting}</TableCell>
          </TableRow>
          <TableRow style={{ backgroundColor: '#0000000a' }}>
            <TableCell style={{ fontWeight: 'bold' }}>Cancelled subscription with source</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Registration</TableCell>
            <TableCell>{data?.cancelled_subscription_source?.registration}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Manual</TableCell>
            <TableCell>{data?.cancelled_subscription_source?.manual}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Referral</TableCell>
            <TableCell>{data?.cancelled_subscription_source?.referral}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Admin</TableCell>
            <TableCell>{data?.cancelled_subscription_source?.admin}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Customer</TableCell>
            <TableCell>{data?.cancelled_subscription_source?.customer}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Other</TableCell>
            <TableCell>{data?.cancelled_subscription_source?.other}</TableCell>
          </TableRow>
          <TableRow style={{ backgroundColor: '#0000000a' }}>
            <TableCell style={{ fontWeight: 'bold' }}>Artist trial expiry with source</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Registration</TableCell>
            <TableCell>{data?.artist_trial_expiry?.registration}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Manual</TableCell>
            <TableCell>{data?.artist_trial_expiry?.manual}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Referral</TableCell>
            <TableCell>{data?.artist_trial_expiry?.referral}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Admin</TableCell>
            <TableCell>{data?.artist_trial_expiry?.admin}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Customer</TableCell>
            <TableCell>{data?.artist_trial_expiry?.customer}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Other</TableCell>
            <TableCell>{data?.artist_trial_expiry?.other}</TableCell>
          </TableRow>
          <TableRow style={{ backgroundColor: '#0000000a' }}>
            <TableCell style={{ fontWeight: 'bold' }}>Cohort wise conversion - Paid</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Artist</TableCell>
            <TableCell>{data?.cohort_conversion_paid?.artist?.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>AOS</TableCell>
            <TableCell>{data?.cohort_conversion_paid?.aos?.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Agency</TableCell>
            <TableCell>{data?.cohort_conversion_paid?.agency?.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow style={{ backgroundColor: '#0000000a' }}>
            <TableCell style={{ fontWeight: 'bold' }}>Cohort wise conversion - Trial</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Artist</TableCell>
            <TableCell>{data?.cohort_conversion_trial?.artist?.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>AOS</TableCell>
            <TableCell>{data?.cohort_conversion_trial?.aos?.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Agency</TableCell>
            <TableCell>{data?.cohort_conversion_trial?.agency?.toFixed(2)}</TableCell>
          </TableRow>
          <TableRow style={{ backgroundColor: '#0000000a' }}>
            <TableCell style={{ fontWeight: 'bold' }}>Only artist</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>New registrations</TableCell>
            <TableCell>{data?.only_artist?.new_registrations}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>New trial subscriptions</TableCell>
            <TableCell>{data?.only_artist?.new_trial}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>New paid users</TableCell>
            <TableCell>{data?.only_artist?.new_paid}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Cancelled subscriptions</TableCell>
            <TableCell>{data?.only_artist?.cancelled}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Registration to freemium</TableCell>
            <TableCell>{data?.only_artist?.registration_to_freemium}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Registration to premium</TableCell>
            <TableCell>{data?.only_artist?.registration_to_premium}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Trial to paid</TableCell>
            <TableCell>{data?.only_artist?.trial_to_paid}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Freemium to premium</TableCell>
            <TableCell>{data?.only_artist?.freemium_to_premium}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Verification failed</TableCell>
            <TableCell>{data?.only_artist?.verification_failed}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Verification pending</TableCell>
            <TableCell>{data?.only_artist?.verification_pending}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Verification done</TableCell>
            <TableCell>{data?.only_artist?.verification_done}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>User with artist access</TableCell>
            <TableCell>{data?.only_artist?.user_artist_access}</TableCell>
          </TableRow>
          <TableRow style={{ backgroundColor: '#0000000a' }}>
            <TableCell style={{ fontWeight: 'bold' }}>In trial(Only artist) subscription with source</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Registration</TableCell>
            <TableCell>{data?.only_artist?.artist_intrial_reg_metrics}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Manual</TableCell>
            <TableCell>{data?.only_artist?.artist_intrial_man_metrics}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Referral</TableCell>
            <TableCell>{data?.only_artist?.artist_intrial_ref_metrics}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Offer</TableCell>
            <TableCell>{data?.only_artist?.artist_intrial_offer_metrics}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Admin</TableCell>
            <TableCell>{data?.only_artist?.artist_intrial_admin_metrics}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Customer</TableCell>
            <TableCell>{data?.only_artist?.artist_intrial_cus_metrics}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Other</TableCell>
            <TableCell>{data?.only_artist?.artist_intrial_other_metrics}</TableCell>
          </TableRow>
          <TableRow style={{ backgroundColor: '#0000000a' }}>
            <TableCell style={{ fontWeight: 'bold' }}>Paid(Only artist) subscription with source</TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Registration</TableCell>
            <TableCell>{data?.only_artist?.artist_paid_reg_metrics}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Manual</TableCell>
            <TableCell>{data?.only_artist?.artist_paid_man_metrics}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Referral</TableCell>
            <TableCell>{data?.only_artist?.artist_paid_ref_metrics}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Offer</TableCell>
            <TableCell>{data?.only_artist?.artist_paid_offer_metrics}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Admin</TableCell>
            <TableCell>{data?.only_artist?.artist_paid_admin_metrics}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Customer</TableCell>
            <TableCell>{data?.only_artist?.artist_paid_cus_metrics}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Other</TableCell>
            <TableCell>{data?.only_artist?.artist_paid_other_metrics}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  )
}
