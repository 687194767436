import React, { useState, useEffect } from 'react';
import { Loading } from 'react-admin';
import { makeStyles, FormControl, Table, TableHead, TableRow, TableBody, TableCell, Typography, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import apiClient from '../utils/api';

const styles = () => ({
  filterSection: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
    columnGap: '10px',
  },
  tableTitle: {
    marginLeft: '10px',
    fontWeight: 'bold',
  },
  table: {
    marginTop: 10,
    width: '60%',
    padding: '20px 16px 50px 16px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    background: 'white',
    margin: 10,
    borderRadius: 4,
    flexDirection: 'column',
  },
  metric: {
    display: 'flex',
  },
  metric__label: {
    flex: 1,
    padding: '8px 0',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  metric__value: {
    flex: 1,
    padding: '8px 0',
  },
  tableCell: {
    width: '60%',
    textAlign: 'center', 
    borderLeft: '1px solid rgba(224, 224, 224, 1)', 
    padding: '0px 8px !important',
  },
});

export const EmailMetricsModelShow = () => {
  const classes = makeStyles(styles)();
  const history = useHistory();

  const searchParams = new URLSearchParams(history.location.search);
  const defaultDateRange = { from_date: searchParams?.get('from_date'), to_date: searchParams?.get('to_date') };

  const [isLoading, setIsLoading] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState({
    from_date: defaultDateRange?.from_date || dayjs().add(-1, 'month').format('YYYY-MM-DD'),
    to_date: defaultDateRange?.to_date || dayjs().format('YYYY-MM-DD'),
  });

  const [metrics, setMetrics] = useState([]);

  const getUserEmailMetrics = () => {
    setIsLoading(true);
    apiClient.get('stats/metrics/campaigns', { params: { from_date: selectedDateRange?.from_date, to_date: selectedDateRange?.to_date } }).then((res) => {
      const metricsData = res?.data?.data;
      setMetrics(metricsData);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    document.title = 'Email metrics';
  }, []);

  useEffect(() => {
    getUserEmailMetrics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUserEmailMetrics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange?.from_date, selectedDateRange?.to_date]);

  useEffect(() => {
    searchParams.set('from_date', selectedDateRange?.from_date);
    searchParams.set('to_date', selectedDateRange?.to_date);
    const newSearch = searchParams.toString();
    history.push({ search: newSearch });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange]);

  return (
    <div>
      {!isLoading ? (
        <div>
          <FormControl className={classes.filterSection} size="small">
            <TextField
              value={selectedDateRange.from_date}
              onChange={(e) => {
                setSelectedDateRange({ ...selectedDateRange, from_date: e?.target?.value });
              }}
              type="date"
              label="From"
              variant="filled"
              size="small"
            />
            <TextField
              className={classes.spacingLeft}
              value={selectedDateRange.to_date}
              onChange={(e) => {
                setSelectedDateRange({ ...selectedDateRange, to_date: e?.target?.value });
              }}
              type="date"
              label="To"
              variant="filled"
              size="small"
            />
          </FormControl>
          {metrics?.map((metric, index) => (
            <div>
              <Typography className={classes.tableTitle}>{metric?.name}</Typography>
              <Table key={`${metric?.name}_${index}`} className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold', width: '40%' }}>Type</TableCell>
                    <TableCell style={{ fontWeight: 'bold', width: '60%', textAlign: 'center' }}>Stats</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {metric?.campaigns?.map((campaign) => {
                    const stats = Object.keys(campaign?.stats);
                    return stats?.map((key, pointer) => (
                      <TableRow key={`${key}_${pointer}`}>
                        {pointer === 0 && (
                          <TableCell rowspan={stats?.length} style={{ width: '40%' }}>
                            {campaign?.name}
                          </TableCell>
                        )}
                        <TableCell className={classes.tableCell}>
                          <div className={classes.metric}>
                            <div className={classes.metric__label}>{key}</div>
                            <div className={classes.metric__value}>{campaign?.stats?.[key]}</div>
                          </div>
                        </TableCell>
                      </TableRow>
                    ));
                  })}
                </TableBody>
              </Table>
            </div>
          ))}
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default EmailMetricsModelShow;
