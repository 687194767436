/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import * as dayjs from 'dayjs';
import {
  List,
  Link,
  ArrayField,
  Show,
  Datagrid,
  TextField,
  TextInput,
  FunctionField,
  SimpleForm,
  Create,
  SimpleShowLayout,
  ReferenceInput,
  AutocompleteInput,
  Filter,
  SelectInput,
  required,
  FormDataConsumer,
  DateField,
  TopToolbar,
  CreateButton,
  DateInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import EntityLink from '../components/EntityLink';
import CustomDateField from '../components/customFields/CustomDateField';
import InternalNotes from '../components/InternalNotes';
import useUserPermissions from '../utils/useUserPermissions';

const STATUSES = [
  { id: 'pending', name: 'Pending' },
  { id: 'done', name: 'Done' },
  { id: 'error', name: 'Error' },
];

const PUBLISHED_STATUSES = [
  { id: 'yes', name: 'Yes' },
  { id: 'no', name: 'No' },
];

const FOR_ENTITIES = [
  { id: 7, name: 'Organization' },
  { id: 4, name: 'Artist' },
];

const useStyles = makeStyles({
  input: {
    width: 260,
  },
  createButton: {
    margin: 0,
  },
});

export const ScrapeRequestActions = (props) => (
  <div style={{ display: 'flex', justifyContent: 'end', marginBottom: 10, gap: 20 }}>
    <InternalNotes entityId={props?.data?.id} entityType="scrape_requests" />
  </div>
);

export const ScrapeRequestShow = (props) => (
  <Show {...props} actions={<ScrapeRequestActions />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="status" />
      <TextField source="entity_type" />
      <FunctionField label="Created Entity" render={(record) => (record.entity_id ? <Link to={`/scrape-productions/${record.entity_id}/show`}>{record.entity_id}</Link> : null)} />
      <FunctionField label="Request by user" render={(record) => <EntityLink entity={record?.requestedByUser} entityType="users" />} sortable={false} />
      <FunctionField
        render={(record) => {
          const forEntity = FOR_ENTITIES?.filter((entity) => entity.id === record?.meta_info?.forEntity);

          return forEntity?.[0]?.name || '';
        }}
        label="ForEntity"
      />
      <FunctionField
        render={(record) => {
          const forEntity = record?.meta_info?.forEntity;

          return forEntity === 7 ? (
            <Link to={`/organizations/${record?.meta_info?.forEntityId}/show`}>{record?.meta_info?.forEntityId}</Link>
          ) : (
            <Link to={`/profiles/${record?.meta_info?.forEntityId}/show`}>{record?.meta_info?.forEntityId}</Link>
          );
        }}
        label="ForEntity"
      />
      <TextField source="entity_type" />
      <FunctionField
        label="URL"
        render={(record) => (
          <a href={record?.url} target="_blank" rel="noopener noreferrer">
            {record?.url}
          </a>
        )}
      />
      <DateField source="completedAt" showTime />
      <ArrayField source="logs">
        <Datagrid>
          <TextField source="task_name" />
          <TextField source="status" />
          <TextField source="started_at" />
          <TextField source="ended_at" />
          <FunctionField
            label="Time taken(In min)"
            render={(record) => {
              const startedAt = dayjs(record.started_at);
              const endedAt = dayjs(record.ended_at);

              if (!record.started_at || !record.ended_at) return null;

              const duration = endedAt.diff(startedAt, 'minutes');
              return duration;
            }}
          />
          <FunctionField
            label="message"
            render={(record) => (
              <pre
                style={{
                  maxHeight: 300,
                  overflow: 'scroll',
                  padding: 10,
                  maxWidth: '38vw',
                  ...(record?.message && { border: '1px solid #ECE9E9' }),
                }}
              >
                <code>{record?.message}</code>
              </pre>
            )}
          />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

export const ScrapeRequestCreate = (props) => {
  const classes = useStyles();
  const { isAdmin } = useUserPermissions();
  const transform = (data) => ({
    url: data.url,
    requestedByProfile: {
      id: data.id,
    },
    meta_info: {
      forEntity: data?.meta_info?.forEntity,
      forEntityId: data?.meta_info?.forEntityId,
    },
    entity_type: 'productions',
  });

  const filterToQuery = (searchText) => {
    const isNumeric = /^-?\d+\.?\d*$/.test(searchText);
    if (isNumeric) {
      return { id: searchText };
    }
    return { query: searchText };
  };

  if (!isAdmin) return null;

  return (
    <Create transform={transform} title="Create Scrape request" {...props}>
      <SimpleForm submitOnEnter={false}>
        <TextInput source="url" validate={required()} />
        <SelectInput source="meta_info.forEntity" choices={FOR_ENTITIES} label="Entity" validate={required()} />
        <FormDataConsumer source="meta_info.forEntityId">
          {({ formData }) =>
            formData?.meta_info?.forEntity === 7 ? (
              <ReferenceInput
                label="Production Owner"
                source="meta_info.forEntityId"
                reference="organizations"
                filterToQuery={filterToQuery}
                validate={required()}
                disabled={!formData?.meta_info?.forEntity}
                className={classes.input}
              >
                <AutocompleteInput optionText="name" />
              </ReferenceInput>
            ) : (
              <ReferenceInput
                label="Production Owner"
                source="meta_info.forEntityId"
                reference="profiles"
                filterToQuery={filterToQuery}
                validate={required()}
                disabled={!formData?.meta_info?.forEntity}
                className={classes.input}
              >
                <AutocompleteInput optionText="name" />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

const ScrapeRequestFilter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label="Scrape request ID" alwaysOn />
    <TextInput source="entity_id" label="Created Production ID" alwaysOn />
    <ReferenceInput label="Requested by user" source="requested_by_user_id" reference="users" perPage={25} filter={{ group_id: 3 }} filterToQuery={(searchText) => ({ query: searchText })} alwaysOn>
      <AutocompleteInput optionText="email" />
    </ReferenceInput>
    <SelectInput source="status" choices={STATUSES} alwaysOn />
    <SelectInput style={{ width: 200 }} source="production_published" choices={PUBLISHED_STATUSES} alwaysOn />
    <TextInput source="query" label="URL" alwaysOn />
    <DateInput label="Created from" source="date_from" alwaysOn />
    <DateInput label="Created to" source="date_to" alwaysOn />
  </Filter>
);

const ScrapeRequestURL = ({ url }) => {
  const MAX_URL_LENGTH = 40;
  const [isReadMore, setIsReadMore] = useState(false);

  if (url?.length > MAX_URL_LENGTH) {
    return (
      <div>
        <a href={url} target="_blank" onClick={(e) => e.stopPropagation()} rel="noopener noreferrer">
          {isReadMore ? url : `${url?.substring(0, MAX_URL_LENGTH)}...`}
        </a>
        <span
          onClick={(e) => {
            e.stopPropagation();
            setIsReadMore(!isReadMore);
          }}
          style={{ marginLeft: 5, textDecoration: 'underline', cursor: 'pointer' }}
        >
          {isReadMore ? 'read less' : 'read more'}
        </span>
      </div>
    );
  }

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {url}
    </a>
  );
};

const CustomScrapeRequestActions = (props) => {
  const classes = useStyles();
  const { isAdmin } = useUserPermissions();
  return <TopToolbar {...props}>{isAdmin && <CreateButton {...props} className={classes.createButton} />}</TopToolbar>;
};

export const ScrapeRequestList = (props) => (
  <List {...props} bulkActionButtons={false} sort={{ field: 'id', order: 'DESC' }} exporter={false} filters={<ScrapeRequestFilter />} actions={<CustomScrapeRequestActions />}>
    <Datagrid rowClick="show">
      <TextField source="id" sortable={false} />
      <TextField source="entity_type" sortable={false} />
      <FunctionField
        label="Created Entity"
        render={(record) =>
          record.entity_id ? (
            <Link to={`/scrape-productions/${record.entity_id}/show`} onClick={(e) => e.stopPropagation()}>
              {record.entity_id}
            </Link>
          ) : null
        }
      />
      <FunctionField label="Is Published" render={(record) => (record?.production?.published_at ? 'Yes' : 'No')} sortable={false} />
      <FunctionField label="Request by user" render={(record) => <EntityLink entity={record?.requestedByUser} entityType="users" />} sortable={false} />
      <FunctionField label="URL" render={(record) => <ScrapeRequestURL url={record.url} />} sortable={false} />
      <CustomDateField source="createdAt" sortable={false} />
      <FunctionField label="Time taken(Mins)" render={(record) => (record?.completedAt ? dayjs(record?.completedAt).diff(dayjs(record?.createdAt), 'minute') : null)} sortable={false} />
      <TextField source="status" sortable={false} />
    </Datagrid>
  </List>
);

export default ScrapeRequestList;
