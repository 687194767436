/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextInput,
  List,
  Filter,
  TextField,
  ReferenceInput,
  SelectInput,
  Datagrid,
  ReferenceManyField,
  SingleFieldList,
  Link,
  Loading,
  useRedirect,
  useMutation,
  useNotify,
  useDataProvider,
} from 'react-admin';
import { Launch, MergeType } from '@material-ui/icons';
import { Paper, TableCell, TableBody, TableRow, TableHead, TableContainer, Table, Button, Dialog, DialogContent, DialogActions, DialogTitle, FormControlLabel, Checkbox } from '@material-ui/core';

import { startCase, map } from 'lodash';
import { Breadcrumbs } from '../components/Breadcrumbs';
import EntityLink from '../components/EntityLink';
import ValidationLockByUser from '../components/ValidationLockByUser';
import { getEntityName } from '../utils/entities';

const useStyles = makeStyles(
  (theme) => ({
    multiOpen: {
      float: 'right',
      color: theme.palette.primary.main,
      textDecoration: 'none',
      margin: '-8px 0px 0px 8px',
      padding: '8px 8px 4px 8px',
      background: '#EFEFEF',
      borderRadius: 4,
    },
    score: {
      color: '#0000FF',
      backgroundColor: '#000099',
    },
    tableHeaderRotated: {
      height: '200px',
      width: '20px',
      paddingBottom: '10px',
      '& span': {
        width: '200px',
        display: 'block',
        transform: 'rotate(-90deg)',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        height: '200px',
      },
    },
    datapointLabel: {
      whiteSpace: 'nowrap',
    },
    cellBorder: {
      borderRight: '1px solid #ddd',
    },
    entity: { marginRight: 15 },
    btn: {
      textTransform: 'inherit',
      minWidth: 'max-content',
      marginLeft: 10,
    },
    tableActions: {
      minWidth: 215,
    },
    dialogTitle: {
      padding: '16px 24px 0',
    },
    dialogSubTitle: {
      marginTop: 0,
    },
    checkboxLabel: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      '& svg': {
        margin: '5px 10px 0',
        color: theme.palette.primary.main,
        fontSize: 16,
      },
    },
    mergeSection: {
      display: 'flex',
    },
    mergeSectionTitle: {
      fontSize: 14,
      fontWeight: 'bold',
    },
    mergeRestriction: {
      padding: '12px 0',
    },
  }),
  { name: 'MakeLink' },
);

const MetricDBViewFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Select View" source="view_id" reference="metrics/dbviews" alwaysOn perPage={50}>
      <SelectInput optionText="id" />
    </ReferenceInput>
    <TextInput label="Search View" source="query" alwaysOn />
  </Filter>
);

const COLOR_GRADIENT = ['#FC6C85', '#FC94A1', '#FFCCCB', '#CDFFCC', '#B0F5AB', '#90EF90'];

const REFERENCES = [
  { id: 'profileids', reference: 'profiles' },
  { id: 'agencyids', reference: 'agencies' },
  { id: 'organizationids', reference: 'organizations' },
  { id: 'productionids', reference: 'productions' },
  { id: 'venueids', reference: 'venues' },
  { id: 'workids', reference: 'works' },
  { id: 'workroleids', reference: 'works/roles' },
  { id: 'countryids', reference: 'countries' },
  { id: 'cityids', reference: 'countries/cities' },
  { id: 'userids', reference: 'users' },
  { id: 'mediaid', reference: 'media' },
  { id: 'mediaids', reference: 'media', filter: { show_invalid: true } },
  { id: 'organizationids', reference: 'organizations' },
  { id: 'professionids', reference: 'professions' },
  { id: 'instrumentids', reference: 'instruments' },
  { id: 'instrumentroleids', reference: 'instrument/roles' },
];

const spliceColumn = (columnName) => {
  const col = {
    id: columnName,
    label: columnName,
    reference: null,
    score: false,
    filter: {},
  };
  REFERENCES.forEach((ref) => {
    if (!columnName) return;
    if ((columnName ?? '').toLowerCase().endsWith(`_${ref.id}`)) {
      col.reference = ref.reference;
      col.label = col.label.replace(`_${ref.id}`, '');
      col.filter = ref.filter;
    }
  });
  if ((columnName ?? '').toLowerCase().endsWith(`_score`)) {
    col.score = true;
    col.label = col.label.replace(`_score`, '');
  }
  col.label = startCase(col.label);
  return col;
};

const Entity = (props) => {
  const classes = useStyles();
  const isShowLibrettoLink = ['organization_organizationids'].includes(props?.column?.id);

  return (
    <div className={classes.entity}>
      <EntityLink entity={props.record} entityType={props.resource} />
      {isShowLibrettoLink && (
        <a
          onClick={(e) => e.stopPropagation()}
          href={`${process.env.REACT_APP_LIBRETTO_URL}/lock/${props?.column?.reference}/${props?.record?.id}?source=admin&source_info=${props?.sourceInfo}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          Lock
        </a>
      )}
    </div>
  );
};

const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const OpenMultiple = (props) => {
  const classes = useStyles();
  const { data, reference, duplicateIdsKey } = props;
  if (!Array.isArray(data) || data?.length <= 1 || !duplicateIdsKey) {
    return null;
  }
  const urls = data?.map((id) => `/#/${reference}/${id}/show`);

  return (
    <Link
      type="button"
      className={classes.multiOpen}
      onClick={async (e) => {
        e.preventDefault();
        if (!window.confirm(`Are you sure you want to open ${urls.length} tabs?`)) {
          return;
        }
        for (const id in urls) {
          const url = urls[id];
          await timeout(100);
          window.open(url, '_blank');
        }
      }}
    >
      <Launch />
    </Link>
  );
};

const OpenMerge = (props) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { data, reference, duplicateIdsKey } = props;

  const [isOpen, setOpen] = useState(false);
  const [entities, setEntities] = useState([]);
  const [mergeRestrictions, setMergeRestrictions] = useState([]);
  const record = useSelector((state) => state?.admin?.resources?.[reference]?.data);

  const ids = [...(data || [])];
  ids.sort((a, b) => a - b);
  const targetId = ids.shift(1);
  const sourceId = ids.join(',');
  const url = `/#/validations/merge/create?entity_type=${reference}&source_id=${sourceId}&target_id=${targetId}`;

  useEffect(() => {
    if (isOpen && ids?.length) {
      dataProvider
        .getList('validations/merge/restrictions', { filter: { entity_type: reference, entity_id: ids }, pagination: { limit: 100 }, sort: {} })
        .then((res) => {
          setMergeRestrictions(res?.data);
        })
        .catch((err) => {
          notify(err?.message, 'warning');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  if (!Array.isArray(data) || data?.length <= 1 || !duplicateIdsKey) return null;

  return (
    <>
      <a
        href={url}
        className={classes.multiOpen}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => {
          if (data?.length > 2) {
            e.preventDefault();
            setOpen(true);
            setEntities([]);
          }
        }}
      >
        <MergeType />
      </a>
      <Dialog
        open={isOpen}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: { padding: 0 },
        }}
      >
        <DialogTitle className={classes.dialogTitle}>Merge entities</DialogTitle>
        <DialogContent style={{ width: 600 }}>
          <p className={classes.dialogSubTitle}>Select two entities you want to merge</p>
          <div className={classes.mergeSection}>
            <div style={{ width: '50%' }}>
              <p className={classes.mergeSectionTitle}>Duplicate Ids</p>
              {data?.map((id) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={entities?.includes(id)}
                      onChange={(e) => {
                        if (e?.target?.checked) {
                          setEntities([...entities, id]);
                        } else {
                          setEntities(entities?.filter((entity) => entity !== id));
                        }
                      }}
                      color="primary"
                    />
                  }
                  label={
                    <span className={classes.checkboxLabel}>
                      {getEntityName(record?.[id], reference)} ({id})
                      <a href={`/#/${reference}/${id}/show`} target="_blank" rel="noopener noreferrer">
                        <Launch />
                      </a>
                    </span>
                  }
                />
              ))}
            </div>
            {mergeRestrictions?.length > 0 && (
              <div style={{ width: '50%' }}>
                <p className={classes.mergeSectionTitle}>Merge restrictions Ids</p>
                {map(mergeRestrictions, (restriction) => (
                  <div className={classes.mergeRestriction}>
                    <span className={classes.checkboxLabel}>
                      {restriction?.entity_1?.id} - {restriction?.entity_2?.id}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            disabled={entities?.length > 2 || entities?.length !== 2}
            variant="contained"
            color="primary"
            onClick={() => {
              window.open(`/#/validations/merge/create?entity_type=${reference}&source_id=${entities?.[0]}&target_id=${entities?.[1]}`, '_blank');
            }}
          >
            Merge
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const DoNotMerge = (props) => {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [entities, setEntities] = useState([]);
  const notify = useNotify();
  const { data, duplicateIdsKey, column } = props;
  const record = useSelector((state) => state?.admin?.resources?.[column?.reference]?.data);

  const [handleDoNotMerge] = useMutation(
    {
      type: 'create',
      resource: 'validations/merge/restrictions',
      payload: {
        data: {
          entity_type: column?.reference,
          entity_1: { id: entities?.[0] },
          entity_2: { id: entities?.[1] },
        },
      },
    },
    {
      onSuccess: () => {
        notify('Updated', 'info');
        setLoading(false);
        setOpen(false);
      },
      onFailure: (err) => {
        notify(err?.message, 'error');
      },
    },
  );

  if (!props?.doNotMerge) return null;

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
          setEntities([]);
          setLoading(false);
        }}
        className={classes.btn}
        variant="contained"
        size="small"
      >
        Do not merge
      </Button>
      <Dialog
        open={isOpen}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: { padding: 0 },
        }}
      >
        <DialogTitle className={classes.dialogTitle}>Mark as Do not merge</DialogTitle>
        <DialogContent style={{ width: 400 }}>
          <p className={classes.dialogSubTitle}>Select two entities you want to mark as do not merge</p>
          {data?.[duplicateIdsKey]?.map((id) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={entities?.includes(id)}
                  onChange={(e) => {
                    if (e?.target?.checked) {
                      setEntities([...entities, id]);
                    } else {
                      setEntities(entities?.filter((entity) => entity !== id));
                    }
                  }}
                  color="primary"
                />
              }
              label={
                <span className={classes.checkboxLabel}>
                  {getEntityName(record?.[id], column?.reference)} ({id})
                  <a href={`/#/${column?.reference}/${id}/show`} target="_blank" rel="noopener noreferrer">
                    <Launch />
                  </a>
                </span>
              }
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            disabled={loading || entities?.length > 2 || entities?.length !== 2}
            variant="contained"
            color="primary"
            onClick={() => {
              setLoading(true);
              handleDoNotMerge();
            }}
          >
            {!loading ? 'Confirm' : 'Loading...'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const DynamicGridCell = (props) => {
  const { data, column, filterValues } = props;
  let value = Array.isArray(data[column.id]) ? data[column.id]?.join(', ') : data[column.id];

  if (value && column.reference) {
    return (
      <TableCell>
        <ReferenceManyField {...props} record={data} page={1} sort={{}} perPage={value.length} reference={column.reference} source={column.id} target="id" filter={column.filter} allowEmpty>
          <SingleFieldList>
            <Entity column={column} sourceInfo={filterValues?.view_id} />
          </SingleFieldList>
        </ReferenceManyField>
      </TableCell>
    );
  }

  if (value === null) {
    value = 'NULL';
  }

  if (column.score) {
    return (
      <TableCell
        style={{
          textAlign: 'center',
          backgroundColor: COLOR_GRADIENT[Math.floor((COLOR_GRADIENT.length - 1) * value)],
        }}
      >
        {value}
      </TableCell>
    );
  }

  return <TableCell style={{ whiteSpace: 'nowrap' }}>{value}</TableCell>;
};

const ColumnRowNumberCell = (props) => {
  const { data } = props;

  const id = data.id.split('-').pop();

  return <TableCell style={{ whiteSpace: 'nowrap', verticalAlign: 'top' }}>{id}</TableCell>;
};

export const DynamicGridFields = (props) => {
  const redirect = useRedirect();
  const classes = useStyles();

  const { data, ids, loading } = props;

  if (loading) {
    return <Loading />;
  }

  if (ids.length === 0 || !data[ids[0]]) {
    return null;
  }

  if (ids[0].indexOf('-') < 0) {
    return (
      <Datagrid
        rowClick={(view_id) => {
          const obj = { view_id };
          redirect(`/metrics/dbviews?filter=${JSON.stringify(obj)}`);
        }}
      >
        <TextField source="id" sortable={false} />
        <TextField source="created_at" sortable={false} />
        <TextField source="is_active" sortable={false} />
      </Datagrid>
    );
  }

  const columnNames = Object.getOwnPropertyNames(data[ids[0]]);
  const columns = columnNames.filter((c) => c !== 'id').map((c) => spliceColumn(c));
  const duplicateIdsKey = columnNames?.find((col) => col?.includes('duplicate_'));
  const column = columns?.find((col) => col?.id === duplicateIdsKey);
  const rows = ids.map((id) => data[id]);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell component="th"></TableCell>
            {columns.map((col) => (
              <TableCell component="th">{col.label}</TableCell>
            ))}
            <TableCell component="th">Actions</TableCell>
            {props?.isLock && <TableCell component="th">Locked By</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <ColumnRowNumberCell {...props} data={row} />
              {columns.map((col) => (
                <DynamicGridCell {...props} data={row} column={col} />
              ))}
              {
                <TableCell className={classes.tableActions}>
                  <OpenMultiple {...props} data={row?.[duplicateIdsKey]} reference={column?.reference} duplicateIdsKey={duplicateIdsKey} />
                  <OpenMerge {...props} data={row?.[duplicateIdsKey]} reference={column?.reference} duplicateIdsKey={duplicateIdsKey} />
                  <DoNotMerge {...props} data={row} duplicateIdsKey={duplicateIdsKey} column={column} />
                </TableCell>
              }
              {props?.isLock && (
                <TableCell>
                  <ValidationLockByUser {...props} data={{ id: row.id }} lockGroupType="duplicates-entity-queue" />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const MetricDBViewList = (props) => (
  <List {...props} title={<Breadcrumbs {...props} />} perPage={10} exporter={false} filters={<MetricDBViewFilter />}>
    <DynamicGridFields {...props} />
  </List>
);
