export const DAYS_FILTER = [
  {
    key: 'days_ago_01',
    name: 'Yesterday',
  },
  {
    key: 'days_ago_07',
    name: '7 Days ago',
  },
  {
    key: 'days_ago_14',
    name: '14 Days ago',
  },
  {
    key: 'days_ago_30',
    name: '30 Days ago',
  },
];
