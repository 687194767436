const LANGUAGES = 'languages';
const SELECTED_LANGUAGES = 'selected_languages';
const REFRESH_TOKEN = 'refreshToken';
const TOKEN = 'token';
const USER = 'user';
const NEXT_PATH = 'next_path';
const USER_PERMISSIONS = 'permissions';
const USER_ID = 'user_id';

const setField = (storage, key, value) => {
  try {
    storage.setItem(key, value);
    // eslint-disable-next-line no-empty
  } catch (e) {}
};

const getField = (storage, key) => storage.getItem(key);
const removeField = (storage, key) => {
  try {
    storage.removeItem(key);
    // eslint-disable-next-line no-empty
  } catch (e) {}
};

const setFieldToLocalStorage = (key, value) => {
  setField(localStorage, key, value);
};

const getFieldFromLocalStorage = (key) => getField(localStorage, key);

const removeFieldFromLocalStorage = (key) => {
  removeField(localStorage, key);
};

const setFieldToSessionStorage = (key, value) => {
  setField(sessionStorage, key, value);
};

const getFieldFromSessionStorage = (key) => getField(sessionStorage, key);

const removeFieldFromSessionStorage = (key) => {
  removeField(sessionStorage, key);
};

const setToken = (token) => setFieldToLocalStorage(TOKEN, token);
const getToken = () => getFieldFromLocalStorage(TOKEN);
const clearToken = () => removeFieldFromLocalStorage(TOKEN);

const setRefreshToken = (token) => setFieldToLocalStorage(REFRESH_TOKEN, token);
const getRefreshToken = () => getFieldFromLocalStorage(REFRESH_TOKEN);
const clearRefreshToken = () => removeFieldFromLocalStorage(REFRESH_TOKEN);

const setLanguagesList = (languages) => setFieldToLocalStorage(LANGUAGES, JSON.stringify(languages));
const getLanguagesList = () => JSON.parse(getFieldFromLocalStorage(LANGUAGES));
const clearLanguagesList = () => removeFieldFromLocalStorage(LANGUAGES);

const setSelectedLanguagesList = (languages) => setFieldToLocalStorage(SELECTED_LANGUAGES, JSON.stringify(languages));
const getSelectedLanguagesList = () => JSON.parse(getFieldFromLocalStorage(SELECTED_LANGUAGES));
const clearSelectedLanguagesList = () => removeFieldFromLocalStorage(SELECTED_LANGUAGES);

const setUser = (user) => {
  setFieldToLocalStorage(USER, user);
};
const getUser = () => getFieldFromLocalStorage(USER);
const setUserId = (userId) => {
  setFieldToLocalStorage(USER_ID, userId);
};
const getUserId = () => getFieldFromLocalStorage(USER_ID);
const clearUser = () => {
  removeFieldFromLocalStorage(USER);
};

const setNextPath = (path) => {
  setFieldToSessionStorage(NEXT_PATH, path);
};
const getNextPath = () => getFieldFromSessionStorage(NEXT_PATH);
const clearNextPath = () => removeFieldFromSessionStorage(NEXT_PATH);

const setUserPermissions = (permissions) => {
  setFieldToLocalStorage(USER_PERMISSIONS, JSON.stringify(permissions));
};

const getUserPermissions = () => {
  return JSON.parse(getFieldFromLocalStorage(USER_PERMISSIONS));
};

const clearOnLogout = () => {
  const selectedLanguages = getSelectedLanguagesList();
  localStorage.clear();
  if (selectedLanguages) {
    setSelectedLanguagesList(selectedLanguages);
  }
};

export default {
  clearOnLogout,
  setToken,
  getToken,
  clearToken,
  setRefreshToken,
  getRefreshToken,
  clearRefreshToken,
  setLanguagesList,
  getLanguagesList,
  clearLanguagesList,
  setSelectedLanguagesList,
  getSelectedLanguagesList,
  clearSelectedLanguagesList,
  setUser,
  getUser,
  clearUser,
  setUserPermissions,
  getUserPermissions,
  setNextPath,
  getNextPath,
  clearNextPath,
  getUserId,
  setUserId,
};
