/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useMemo } from 'react';
import { get, map, isEmpty, keyBy } from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { Tooltip, Typography, Button, Accordion, AccordionSummary, AccordionDetails, FormGroup, FormControlLabel, Checkbox, TextField as TextFieldMUI, MenuItem } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ListContextProvider, Confirm, useDataProvider, Error, Loading, Pagination, Datagrid, TextField, Title, FunctionField, useNotify, DateField, ReferenceField } from 'react-admin';
import Autocomplete from '@material-ui/lab/Autocomplete';
import EntityLink from '../components/EntityLink';
import ProductionEvidence from '../components/productionValidation/ProductionEvidence';
import { Breadcrumbs } from '../components/Breadcrumbs';
// import RelatedEntitiesValidation from '../components/RelatedEntitiesValidation';
import { UserName } from './ProfilesModel';
import { getHoursFromCurrentTime, dateParserUTC } from '../utils/dateParser';
import { PRODUCTION_VALIDATION_SECTIONS } from '../constants/validations';
import { PRODUCTION_VALIDATION_CHECKLIST, VALIDATION_STATUSES, CHANGE_REQUEST_VALIDATION_STATUSES, PRODUCTION_VALIDATION_STATUSES } from '../constants/production';
import { getEntityName } from '../utils/entities';
import { getProductionSection, parseProductionSection } from '../components/productionValidation/ValidationPanels';
import { PublicLink } from '../components/PublicLink';
import ValidationLockByUser from '../components/ValidationLockByUser';
import { useDebounce } from '../utils/common';
import InternalNotes from '../components/InternalNotes';
import { WHITELIST_ENTITIES } from '../constants/entities';
import {
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from '@material-ui/core';

const VALIDATION_TYPES = {
  PRODUCTION_VALIDATION: 'Production Validation',
  CHANGE_REQUEST: 'Change Request',
  REDMASK_REQUEST: 'Redmask Request',
};

const VALIDATION_QUEUE = {
  [VALIDATION_TYPES.PRODUCTION_VALIDATION]: 'production_validation_queue',
  [VALIDATION_TYPES.REDMASK_REQUEST]: 'red_mask_queue',
};

const useStyles = makeStyles(() => ({
  root: {
    padding: 10,
  },
  header: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  linkSection: {
    margin: '10px 0',
  },
  link: {
    textDecoration: 'none',
    marginRight: 10,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: 40,
    flexDirection: 'row-reverse',
  },
  dialog: {
    width: 400,
  },
  footerActions: {
    float: 'right',
    padding: '15px 0',
  },
  bold: { fontWeight: 'bold' },
  spaceLeft: { marginLeft: 10 },
  spaceTop: { marginTop: 20 },
  spaceBottom: { marginBottom: 10 },
  accordion: {
    background: '#FAFAFA',
    borderRadius: 8,
    border: '1px solid #777',
    margin: '10px 0',
  },
  accordionTitle: {
    color: '#ff5722',
    fontSize: 15,
    fontWeight: 'bold',
  },
  openAccordion: {
    background: '#F0F0F0',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottom: '1px solid #777',
    height: 30,
    minHeight: 'auto !important',
  },
  media: { margin: '0 10px' },
  internalNotes: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  tooltipButton: {
    display: 'flex',
  },
  tooltip: {
    backgroundColor: 'black',
    color: 'white',
    maxWidth: 500,
    '& a': {
      color: '#0072E5',
    },
  },
  tooltipList: {
    paddingLeft: 20,
    fontSize: 13,
  },
  dataGrid: {
    overflowX: 'scroll',
    width: '82vw',
  },
}));

export const ProductionValdationGrid = ({ hasList, hasEdit, hasShow, ...props }) => {
  const dataProvider = useDataProvider();
  const classes = useStyles();

  const [productions, setProductions] = useState();
  const [changeRequests, setChangeRequests] = useState();
  const [redmaskRequests, setRedmaskRequests] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const isRedMaskView = props?.resource === 'validations/redmask-requests';
  const isChangeRequestView = props?.resource === 'validations/change-requests';
  const isProductionValidation = props.resource === 'validations/production-validations';
  const lockGroupType = (isProductionValidation && 'production-validations') || (isRedMaskView && 'redmask-requests') || (isChangeRequestView && 'change-requests');
  const defaultSort = isChangeRequestView ? { field: 'createdAt', order: 'DESC' } : { field: 'created_at', order: 'DESC' };
  const [sort, setSort] = useState(defaultSort);

  const validationsStatuses = isChangeRequestView ? CHANGE_REQUEST_VALIDATION_STATUSES : VALIDATION_STATUSES;
  const urlSearchParams = new URLSearchParams(props.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const [selectedProfileId, setSelectedProfileId] = useState(params?.profile_id);
  const [selectedProductionId, setSelectedProductionId] = useState(params?.production_id);
  const [selectedValidationStatusId, setSelectedValidationStatusId] = useState(params?.status_id || validationsStatuses[0]?.id);
  const [selectedWhiteListedEntityId, setSelectedWhiteListedEntityId] = useState(params?.whitelisted_entity_id || 'any');
  const [lastValidatedAt, setLastValidatedAt] = useState({ from: '', to: '' });
  const [lastValidatedByUser, setLastValidatedByUser] = useState('');
  const [employees, setEmployees] = useState([]);
  const debouncedSelectedProfileId = useDebounce(selectedProfileId, 500);
  const debouncedSelectedProductionId = useDebounce(selectedProductionId, 500);

  const [allValidationLocks, setAllValidationLocks] = useState([]);

  const data = useMemo(() => {
    const prepData = (page, perPage, productions = [], changeRequests = [], redmaskRequests = []) => {
      let data = [];

      if (isRedMaskView) {
        data = redmaskRequests.map((prod) => {
          const validationType = VALIDATION_TYPES.REDMASK_REQUEST;
          const pendingTime = prod.validation_requested_at;
          return { ...prod, validationType, pendingTime };
        });
      } else if (isChangeRequestView) {
        data = changeRequests.map((cr) => {
          const validationType = VALIDATION_TYPES.CHANGE_REQUEST;
          const pendingTime = cr.submittedAt;
          return { ...cr, validationType, pendingTime };
        });
      } else {
        data = productions.map((prod) => {
          const validationType = VALIDATION_TYPES.PRODUCTION_VALIDATION;
          const pendingTime = prod.validation_requested_at;
          return { ...prod, validationType, pendingTime };
        });
      }

      data = data.filter(Boolean).map((entry) => {
        const pendingTimeHours = entry.pendingTime ? getHoursFromCurrentTime(entry.pendingTime) : null;
        return { ...entry, pendingTimeHours };
      });

      return data;
    };
    return prepData(page, perPage, productions?.data, changeRequests?.data, redmaskRequests?.data);
  }, [page, perPage, productions, changeRequests, redmaskRequests, isChangeRequestView, isRedMaskView]);
  const total = productions?.total || changeRequests?.total || redmaskRequests?.total;

  useEffect(() => {
    if (data?.length) {
      const allIds = isChangeRequestView ? data.map((production) => production.revisionId) : data.map((production) => production.id);
      dataProvider
        .getList('validations/locks', {
          filter: { lockIdentifier: allIds.join(','), lockGroup: lockGroupType, validation_status: '' },
          pagination: {},
          sort: {},
        })
        .then(({ data }) => {
          setAllValidationLocks(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setLoading(true);

    /// Only on escalated view
    if (isRedMaskView) {
      if (selectedValidationStatusId === PRODUCTION_VALIDATION_STATUSES.PENDING && (lastValidatedAt?.from || lastValidatedAt?.to || lastValidatedByUser?.id)) {
        setSelectedValidationStatusId(map(validationsStatuses, 'id')); // Set to all - because above filters are not applicable for pending
        return;
      }
      dataProvider
        .getList('productions', {
          pagination: { perPage, page },
          sort,
          filter: {
            added_by: 'artist',
            validation_reason: 'red_mask_queue',
            individual_id: debouncedSelectedProfileId,
            id: debouncedSelectedProductionId,
            ...(
              selectedValidationStatusId === PRODUCTION_VALIDATION_STATUSES.PENDING
              ? { has_pending_redmask_requests: true }
              : { validation_status_id: selectedValidationStatusId }
            ),
            last_validated_at_from: lastValidatedAt?.from,
            last_validated_at_to: lastValidatedAt?.to,
            last_validated_by_user_id: lastValidatedByUser?.id,
          },
        })
        .then(setRedmaskRequests)
        .catch(setError);
    } else if (isChangeRequestView) {
      dataProvider
        .getList('change_requests', {
          pagination: { perPage, page },
          sort,
          filter: {
            entity_type_id: 4,
            ...(selectedValidationStatusId !== 'all' ? { status: selectedValidationStatusId } : {}),
            profile_id: debouncedSelectedProfileId,
            entity_id: debouncedSelectedProductionId,
            last_validated_at_from: lastValidatedAt?.from,
            last_validated_at_to: lastValidatedAt?.to,
            last_validated_by_user_id: lastValidatedByUser?.id,
          },
        })
        .then(setChangeRequests)
        .catch(setError);
    } else {
      dataProvider
        .getList('productions', {
          pagination: { perPage, page },
          sort,
          filter: {
            ...(selectedValidationStatusId !== 'all' ? { validation_status_id: selectedValidationStatusId } : {}),
            individual_id: debouncedSelectedProfileId,
            id: debouncedSelectedProductionId,
            validation_status: '',
            has_whitelisted_entity: selectedWhiteListedEntityId,
            last_validated_at_from: lastValidatedAt?.from,
            last_validated_at_to: lastValidatedAt?.to,
            last_validated_by_user_id: lastValidatedByUser?.id,
          },
        })
        .then(setProductions)
        .catch(setError);
    }
  }, [
    page,
    isRedMaskView,
    isChangeRequestView,
    dataProvider,
    debouncedSelectedProfileId,
    sort,
    selectedValidationStatusId,
    debouncedSelectedProductionId,
    selectedWhiteListedEntityId,
    perPage,
    validationsStatuses,
    lastValidatedAt,
    lastValidatedByUser,
  ]);

  const getEmployees = (input) => {
    dataProvider.getList('users', { filter: { group_id: 3, query: input }, pagination: {}, sort: {} }).then((res) => {
      setEmployees(res?.data);
    });
  };

  useEffect(() => {
    getEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    if (isRedMaskView && redmaskRequests) {
      return setLoading(false);
    }

    if (isChangeRequestView && changeRequests) {
      return setLoading(false);
    }

    if (!isRedMaskView && !isChangeRequestView && productions) {
      return setLoading(false);
    }
  }

  if (loading) return <Loading />;
  if (error) return <Error />;

  const listContext = {
    data: keyBy(data, 'id'),
    ids: data.map(({ id }) => id),
    total,
    page,
    setPage,
    perPage,
    setPerPage,
    currentSort: sort,
    basePath: props.basePath,
    resource: props.resource,
    selectedIds: [],
    hasList: true,
    hasShow: true,
    setSort: (field, order) => {
      setSort({ field, order });
    },
    sort,
  };

  const rowClick = (id, basePath, row) => {
    basePath = `/#${basePath}`;
    const revisionId = row?.revisionId;
    const changeRequestId = row?.id;
    const productionId = revisionId ? revisionId.split('__')[0] : row?.id;
    const path = `${basePath}/${productionId}/show${revisionId ? `?revisionId=${revisionId}&changeRequestId=${changeRequestId}` : ''}`;
    window.location.href = path;
  };

  const showLockedUser = (data) => {
    if (allValidationLocks.length) {
      const hasValidationLock = allValidationLocks.find((validationLock) =>
        isChangeRequestView ? validationLock.lockIdentifier === data.revisionId : Number(validationLock.lockIdentifier) === Number(data.id),
      );
      return `${hasValidationLock?.user?.email || 'Not Locked'}`;
    }
    return 'Not Locked';
  };

  return (
    <div>
      <div style={{ margin: '10px 10px 0 0' }}>
        <TextFieldMUI
          style={{ marginLeft: 10, marginBottom: 10, width: 170 }}
          label="Validation status"
          variant="filled"
          select
          size="small"
          value={selectedValidationStatusId}
          onChange={(e) => setSelectedValidationStatusId(e?.target?.value)}
        >
          <MenuItem key={map(validationsStatuses, 'id')} value={map(validationsStatuses, 'id')}>
            All
          </MenuItem>
          {validationsStatuses.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.label}
            </MenuItem>
          ))}
        </TextFieldMUI>
        <TextFieldMUI
          size="small"
          style={{ marginLeft: 10, marginBottom: 10, width: 170 }}
          label="Production ID"
          variant="filled"
          value={selectedProductionId}
          onChange={(e) => setSelectedProductionId(e?.target?.value)}
        />
        <TextFieldMUI
          size="small"
          style={{ marginLeft: 10, marginBottom: 10, width: 170 }}
          label="Profile ID"
          variant="filled"
          value={selectedProfileId}
          onChange={(e) => setSelectedProfileId(e?.target?.value)}
        />
        {!isRedMaskView && !isChangeRequestView && (
          <TextFieldMUI
            style={{ marginLeft: 10, marginBottom: 10, width: 170 }}
            label="Whitelisted entity"
            variant="filled"
            select
            size="small"
            value={selectedWhiteListedEntityId}
            onChange={(e) => setSelectedWhiteListedEntityId(e?.target?.value)}
          >
            <MenuItem key="any" value="any">
              All
            </MenuItem>
            {WHITELIST_ENTITIES.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextFieldMUI>
        )}
        <Autocomplete
          style={{ display: 'inline' }}
          options={employees}
          autoComplete
          value={setLastValidatedByUser?.id}
          onChange={(event, entity) => {
            setLastValidatedByUser(entity);
          }}
          onInputChange={(event, input) => {
            getEmployees(input);
          }}
          renderInput={(inputParams) => <TextFieldMUI {...inputParams} style={{ marginLeft: 10, marginBottom: 10, width: 270 }} size="small" variant="filled" label="Who validated last" />}
          renderOption={(option) => option?.email}
          getOptionLabel={(option) => option?.email}
        />
        <TextFieldMUI
          style={{ marginLeft: 10, marginBottom: 10, width: 210 }}
          label="When validated last from"
          variant="filled"
          size="small"
          value={lastValidatedAt?.form}
          type="date"
          onChange={(e) => setLastValidatedAt({ ...lastValidatedAt, from: e?.target?.value })}
        />
        <TextFieldMUI
          style={{ marginLeft: 10, marginBottom: 10, width: 210 }}
          label="When validated last to"
          variant="filled"
          size="small"
          value={lastValidatedAt?.to}
          type="date"
          onChange={(e) => setLastValidatedAt({ ...lastValidatedAt, to: e?.target?.value })}
        />
        {isRedMaskView && (
          <Tooltip
            interactive
            arrow
            classes={{ tooltip: classes.tooltip }}
            title={
              <ol className={classes.tooltipList}>
                <li>
                  <b>Only this queue needs to be validated.</b> The Red Mask queue under the “Validation“ tab displays Red Mask requests by artists for a non-validated (and non-whitelisted)
                  productions.
                </li>
                <li>
                  If you need to view any past validated Red Masks, you can visit <a href="/#/productions/redMaskRequests">this link</a> Note: You DON’T need to validate this queue. It is only a
                  source of record.
                </li>
              </ol>
            }
          >
            <Button className={classes.tooltipButton}>► Hover here for more info About Red mask request validation queue:</Button>
          </Tooltip>
        )}
      </div>
      <div className={classes.dataGrid}>
        <ListContextProvider value={listContext}>
          <Title title={<Breadcrumbs {...props} />} />
          <Datagrid rowClick={rowClick} key={props.id}>
            <TextField source="id" sortable={false} />
            <FunctionField label="Name" render={renderName} sortable={false} />
            <FunctionField label="Entities" render={renderEntities} sortable={false} />
            <FunctionField label="Locked By" render={(record) => showLockedUser(record)} sortable={false} />
            {isChangeRequestView && <FunctionField label="Created By" render={(record) => <EntityLink entityType="profiles" entity={record?.actor?.profile} />} sortable={false} />}
            <TextField label="Status" source={isChangeRequestView ? 'status' : 'validationStatus.name'} sortable={false} />
            <FunctionField label="Pending Time" render={renderPendingTimeHours} source={defaultSort?.field} />
            <DateField label="When validated last" source="lastValidatedAt" sortBy="last_validated_at" />
            <ReferenceField link="show" source="lastValidatedByUser.id" reference="users" label="Who validated last" sortable={false}>
              <UserName />
            </ReferenceField>
          </Datagrid>
          <Pagination {...props} />
        </ListContextProvider>
      </div>
    </div>
  );
};

const renderName = (row) => {
  if (row.production) {
    return getEntityName(row.production, 'production');
  }

  return getEntityName(row, 'production');
};

const renderPendingTimeHours = (row) => {
  let days = Math.floor(row.pendingTimeHours / 24);
  days = days > 0 ? `${days}d` : '';
  let hours = row.pendingTimeHours % 24;
  hours = hours > 0 ? (hours = `${hours}h`) : '';

  if (days === 0 && hours === 0) {
    return '';
  }

  return `${days} ${hours}`;
};

const renderEntities = (row) => {
  const list = [];

  const production = row.production || row;
  const organizations = production?.contributions?.filter((contribution) => contribution?.organization?.id);

  if (organizations?.length) {
    organizations.forEach((organization) => {
      list.push(<EntityLink key={`c${organization.id}`} entity={organization?.organization} entityType="organizations" />);
    });
  }

  if (production.productionWorks) {
    production.productionWorks.forEach((work) => {
      list.push(<EntityLink key={`w${work?.work?.id}`} entity={work?.work} entityType="works" />);
    });
  }

  return list;
};

export const ProductionValidationList = (props) => <ProductionValdationGrid {...props} />;

const createInitialChangeState = (productionId, revisionId) =>
  PRODUCTION_VALIDATION_SECTIONS.reduce(
    (obj, item) => ({
      ...obj,
      [item.key]: null,
    }),
    {
      production: null,
      productionId,
      revisionId,
    },
  );

const isChangedStateLoaded = (state) => {
  let isLoaded = true;

  PRODUCTION_VALIDATION_SECTIONS.forEach((item) => {
    if (isLoaded && !state[item.key]) {
      isLoaded = false;
    }
  });

  return isLoaded;
};

const ProductionSection = (props) => {
  const classes = useStyles();
  const { index, section } = props;
  const [expanded, setExpanded] = useState(true);
  const changes = props.changeState[section.key] || {};

  const hasChanges = Object.keys(changes).reduce((obj, item) => obj || !isEmpty(changes[item]), false);

  if (!hasChanges) {
    return null;
  }

  // prepare the props
  const childProps = { ...props, changes };

  // Find the section handler
  const SectionComponent = getProductionSection(section.key);

  return (
    <Accordion
      key={section?.key}
      onChange={(e, isExpanded) => {
        setExpanded(isExpanded);
      }}
      expanded={expanded}
      className={classes.accordion}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.openAccordion}>
        <Typography className={classes.accordionTitle}>
          {index + 1}. {section?.name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <SectionComponent {...childProps} />
      </AccordionDetails>
    </Accordion>
  );
};

/*
  production={changeState.production}
  changeRequest={changeState.changeRequest}
  isChangeRequestView={isChangeRequestView}
  isProductionValidation={isProductionValidation}
  isRedMaskView={isRedMaskView}
            */
const ProductionActor = (props) => {
  const dataProvider = useDataProvider();
  const [actor, setActor] = useState([]);

  if (props.isChangeRequestView) {
    return (
      <>
        <span style={{ fontWeight: 'bold' }}>Submitted on: </span>
        <EntityLink entityType="profiles" entity={props?.changeRequest?.actor?.profile} />
      </>
    );
  }

  if (props.isProductionValidation || props.isRedMaskView) {
    useEffect(() => {
      if (props?.production?.forEntityId) {
        dataProvider.getOne(`profiles`, { id: props.production.forEntityId }).then((res) => {
          setActor(res?.data);
        });
      }
    }, [props.production.id, props.isProductionValidation, dataProvider, props.production.forEntityId]);
    if (actor) {
      return (
        <>
          <span style={{ fontWeight: 'bold' }}>Submitted on: </span>
          <EntityLink entityType="profiles" entity={actor} />
        </>
      );
    }
  }

  return <></>;
};

export const ProductionValidationShow = (props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  // const [production, setProduction] = useState({});
  const [selectedAction, setSelectedAction] = useState('');
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [otherReason, setOtherReason] = useState('');
  // const [changeRequest, setChangeRequest] = useState();

  const basePath = `/#${props.basePath}`;
  const isProductionValidation = props.resource === 'validations/production-validations';
  const isRedMaskView = props.resource === 'validations/redmask-requests';
  const isChangeRequestView = props.resource === 'validations/change-requests';
  const lockGroupType = (isProductionValidation && 'production-validations') || (isRedMaskView && 'redmask-requests') || (isChangeRequestView && 'change-requests');
  const urlSearchParams = new URLSearchParams(props.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const productionId = props?.match?.params?.id;
  const revisionId = params?.revisionId || null;
  const changeRequestId = params?.changeRequestId || null;
  const [changeState, setChangeState] = useState(createInitialChangeState(productionId, revisionId));
  const organizations = changeState?.production?.contributions?.filter((contribution) => contribution?.organization?.id);
  let validationTypeName = null;
  let validationTypeAction = null;
  if (isProductionValidation) {
    validationTypeName = VALIDATION_TYPES.PRODUCTION_VALIDATION;
    validationTypeAction = 'Validate Production';
  }
  if (isRedMaskView) {
    validationTypeName = VALIDATION_TYPES.REDMASK_REQUEST;
    validationTypeAction = 'Approve Red Mask';
  }
  if (isChangeRequestView) {
    validationTypeName = VALIDATION_TYPES.CHANGE_REQUEST;
    validationTypeAction = `Done with Change Requst`;
  }

  const productionOrganizations = map(get(changeState, 'production.organization', []), (organization) => ({ ...organization, type: 'organizations' }));
  const hostedBy = [...productionOrganizations].filter((entity) => entity.partnerType === 'host');

  if (!revisionId && !productionId) {
    throw new Error('Missing RevisionId & ProductionId');
  }

  useEffect(() => {
    setLoading(true);

    let loadingState = { ...changeState };

    // process the data, and update the states
    const processData = () => {
      PRODUCTION_VALIDATION_SECTIONS.forEach((section) => {
        parseProductionSection(section.key, loadingState)
          .then((data) => {
            loadingState = { ...loadingState, [section.key]: data };

            if (isChangedStateLoaded(loadingState)) {
              setChangeState(loadingState);
              setLoading(false);
            }
          })
          .catch(setError);
      });
    };

    // Is Change Request
    if (revisionId) {
      // Load master production
      dataProvider
        .getOne('productions', { id: productionId })
        .then(({ data }) => {
          const production = data;

          // Load the production revision
          dataProvider
            .getOne('productions', { id: revisionId })
            .then(({ data }) => {
              const productionRevision = data;
              const id = parseInt(changeRequestId);
              // Get the change request for reference
              dataProvider
                .getOne('change_requests', { id })
                .then(({ data }) => {
                  const changeRequest = data;

                  loadingState = { ...loadingState, production, productionRevision, changeRequest };

                  processData();
                })
                .catch(setError);
            })
            .catch(setError);
        })
        .catch(setError);
    } else {
      // Load the master production
      dataProvider
        .getOne('productions', { id: productionId, query: { require_pending_related_entities: true } })
        .then(({ data }) => {
          const production = data;

          loadingState = { ...loadingState, production, productionRevision: null, changeRequest: null };

          processData();
        })
        .catch(setError);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProvider, productionId, revisionId]);

  if (error) {
    notify(`Error : ${error}`, {
      autoHideDuration: 30000,
      type: 'error',
    });
    return <Error title={error.message || 'Error'} error={error} />;
  }

  if (loading) return <Loading />;

  const handleAction = (actionType) => setSelectedAction(actionType);

  const submitAction = (action, reasons) => {
    const apiPath = changeRequestId ? `change_requests/${changeRequestId}/actions` : `productions/${productionId}/actions`;

    dataProvider
      .create(apiPath, {
        data: {
          ...(revisionId ? { action } : { type: action }),
          comments: reasons,
          ...(!revisionId && {
            approvedBy: { id: 2 },
            addedBy: { id: changeState.production?.addedBy?.id },
            ...(isRedMaskView ? { validation_queue: VALIDATION_QUEUE[VALIDATION_TYPES.REDMASK_REQUEST] } : { validation_queue: VALIDATION_QUEUE[VALIDATION_TYPES.PRODUCTION_VALIDATION] }),
          }),
        },
      })
      .then(() => {
        window.location.href = basePath;
      })
      .catch((err) => {
        notify(`Error submitting action: ${err}`, {
          autoHideDuration: 30000,
          type: 'error',
        });
      });
  };

  const renderChecklist = () => (
    <FormGroup>
      {get(PRODUCTION_VALIDATION_CHECKLIST, selectedAction, []).map((c, index) => (
        <FormControlLabel
          key={index}
          control={
            <Checkbox
              onChange={(e) => {
                if (e?.target?.checked) {
                  setSelectedReasons([...selectedReasons, c]);
                } else {
                  setSelectedReasons(selectedReasons.filter((r) => r !== c));
                }
              }}
            />
          }
          label={c}
        />
      ))}
      <TextFieldMUI value={otherReason} fullWidth label="Other (text box for reason)" onChange={(e) => setOtherReason(e?.target?.value)} />
    </FormGroup>
  );

  return (
    <div className={classes.root}>
      <Title title={<Breadcrumbs {...props} />} />

      <div className={classes.header}>
        <Typography variant="body2" gutterBottom>
          <span className={classes.bold}>Validation status: </span>
          {changeState?.production?.validationStatus?.name}
        </Typography>
        <Typography variant="body2" gutterBottom>
          <span className={classes.bold}>{validationTypeName}: </span>
          <EntityLink entity={changeState.production} entityType="productions" />
        </Typography>
        <div>
          <Typography variant="body2" gutterBottom>
            <ProductionActor
              production={changeState.production}
              changeRequest={changeState.changeRequest}
              isChangeRequestView={isChangeRequestView}
              isProductionValidation={isProductionValidation}
              isRedMaskView={isRedMaskView}
            />
          </Typography>
        </div>
        <div className={classes.actions} style={{ maxWidth: '25%' }}>
          <div className={classes.internalNotes}>
            <InternalNotes entityId={props.id} entityType="productions" />
          </div>
          <Typography variant="body2" gutterBottom>
            <span className={classes.bold}>Pending Time(hours): </span> {getHoursFromCurrentTime(changeState.production?.createdAt)}
            <br />
            <PublicLink {...props} resource="productions" data={changeState.production} label="View production" />
            <br />
            <br />
            <PublicLink edit {...props} resource="productions" data={changeState.production} label="Edit production" />
            <br />
            <div className={classes.spaceTop}>
              <ValidationLockByUser {...props} data={{ id: isChangeRequestView ? revisionId : props.id }} lockGroupType={lockGroupType} />
            </div>
            <br />
            {revisionId ? <PublicLink edit {...props} resource="productions" data={changeState.revision} changeRequest={changeState.changeRequest} label="Edit change request" /> : <></>}
          </Typography>
        </div>
      </div>

      <div>
        <Typography variant="body2" gutterBottom style={{ maxWidth: '75%' }}>
          {changeState?.production?.productionWorks?.length > 0 ? <span className={classes.bold}>Works: </span> : <></>}
          {map(changeState?.production?.productionWorks, (work) => (
            <EntityLink key={`w${work.id}`} entity={work?.work} entityType="works" />
          ))}
        </Typography>
        <Typography variant="body2" gutterBottom style={{ maxWidth: '75%' }}>
          {organizations?.length > 0 ? <span className={classes.bold}>Organizations: </span> : <></>}
          {map(organizations, (organization) => (
            <EntityLink key={`w${organization.id}`} entity={organization?.organization} entityType="organizations" />
          ))}
        </Typography>
        {hostedBy.length > 0 && (
          <Typography variant="body2" gutterBottom>
            <span className={classes.bold}>Hosted by: </span>
            {map(hostedBy, (host) => (
              <EntityLink key={`h${host.id}`} entity={host} entityType={host.type} />
            ))}
          </Typography>
        )}
        <div className={classes.actions} style={{ maxWidth: '30', marginTop: -40 }}></div>
      </div>

      <div>
        <Typography variant="body2" gutterBottom>
          <span className={classes.bold}>Created on: </span> {dateParserUTC(get(changeState.production, 'createdAt', ''), '', false)}
        </Typography>
        <Typography variant="body2" gutterBottom>
          <span className={classes.bold}>Last updated on: </span> {dateParserUTC(get(changeState.production, 'updatedAt', ''), '', false)}
        </Typography>
      </div>

      <div className={classes.spaceTop} style={{ width: '75%' }}>
        {changeState?.production?.redMaskRequests?.length && (
          <Typography variant="body2" gutterBottom>
            <span className={classes.bold}>Red mask requests: </span>
            {map(changeState?.production?.redMaskRequests, (redmaskRequest) => (
              <EntityLink key={redmaskRequest?.id} entity={redmaskRequest?.requestedByProfile} entityType="profiles" />
            ))}
          </Typography>
        )}
      </div>

      <div className={classes.spaceTop}>
        <ProductionEvidence productionId={productionId} isChangeRequestProduction={false} />
      </div>

      <div className={classes.spaceTop}>
        {changeState.production?.comments?.length > 0 &&
          changeState.production?.comments?.map((comment, index) => (
            <Typography variant="body2" gutterBottom>
              {index === 0 && <span className={classes.bold}>Comments: </span>}
              <pre>{comment?.comment}</pre>
            </Typography>
          ))}
      </div>

      {/* <div className={classes.spaceTop}>
        <RelatedEntitiesValidation entityId={productionId} entityType="productions" />
      </div> */}

      <div className={classes.spaceTop}>
        <Typography variant="body2" gutterBottom>
          <span className={classes.bold}>Related entities that are pending for validation:</span>
        </Typography>
        {changeState.production?.pendingRelatedEntities?.length ? (
          <Typography variant="body2" gutterBottom>
            <span style={{ color: 'red', fontWeight: 'bold' }}>Important: </span>
            <span style={{ color: 'blue', backgroundColor: 'yellow', padding: 5 }}>
            {"When you approve this production, Your name will be attached to the APPROVAL of all the following entities. So please make sure you've checked them all."}
            </span>
            <div style={{ maxHeight: 300, overflow: 'auto' }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>EntityType</TableCell>
                      <TableCell>Entity</TableCell>
                      <TableCell>Parent Entity</TableCell>
                      <TableCell>Validation</TableCell>
                      <TableCell>CreatedAt</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {changeState.production?.pendingRelatedEntities?.map((row) => (
                      <TableRow key={`${row.entityType}-${row.entityId}`}>
                        <TableCell>{row.entityType}</TableCell>
                        <TableCell>
                          {row.entityType === 'media' ? (
                            <EntityLink newTab entityType={row.entityType} entity={{id: row.entityId, title: row.name}} />
                          ) : (
                            row.entityType === 'productions' ? (
                              <EntityLink newTab entityType={row.entityType} entity={changeState.production} />
                            ) : (
                              <EntityLink newTab entityType={row.entityType} entity={{id: row.entityId, name: row.name}} />
                            )
                          )}
                        </TableCell>
                        <TableCell>
                          {row.parentEntityType && row.parentEntityId ? (
                            <>
                            {row.parentEntityType}{" -> "}
                            <EntityLink newTab entityType={row.parentEntityType} entity={{id: row.parentEntityId, name: row.parentEntityName}} />
                            </>
                          ) : "-"}
                        </TableCell>
                        <TableCell>{"PENDING"}</TableCell>
                        <TableCell>{row.createdAt}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Typography>
        ) : (
          <Typography variant="caption">No pending items</Typography>
        )}
      </div>

      <br />

      <div>
        <Typography variant="body2" gutterBottom>
          <span className={classes.bold}>Production Sections:</span>
        </Typography>
        {PRODUCTION_VALIDATION_SECTIONS.map((section, index) => (
          <ProductionSection changeState={changeState} index={index} section={section} />
        ))}

        <div className={classes.footerActions}>
          <Button className={classes.spaceLeft} variant="contained" color="secondary" onClick={() => handleAction(revisionId ? 'approve' : 'validate')}>
            {validationTypeAction}
          </Button>
          <Button className={classes.spaceLeft} color="primary" variant="contained" onClick={() => handleAction('reject')}>
            Reject
          </Button>
        </div>
      </div>
      <Confirm
        isOpen={!isEmpty(selectedAction)}
        title="Select reason(At Least one reason to be checked & Multiple choice POSSIBLE)"
        content={renderChecklist()}
        onConfirm={() => {
          const reasons = [...selectedReasons.map((reason) => ({ comment: reason })), ...(otherReason && [{ comment: otherReason }])];
          submitAction(selectedAction, reasons);
          setSelectedReasons([]);
          setOtherReason('');
          setSelectedAction('');
        }}
        onClose={() => {
          setSelectedReasons([]);
          setOtherReason('');
          setSelectedAction('');
        }}
        loading={!selectedReasons.length && !otherReason}
      />
    </div>
  );
};

export default ProductionValidationList;
