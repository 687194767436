import React from 'react';
import {
  AccountBalance,
  CallMerge,
  CardGiftcard,
  CardMembership,
  Room,
  CloudDownload,
  Description,
  EuroSymbol,
  HomeWork,
  Landscape,
  Language,
  LocalOffer,
  LocalShipping,
  Lock,
  Mail,
  NaturePeople,
  Person,
  Receipt,
  RecordVoiceOver,
  StarHalf,
  SupervisorAccount,
  PermMedia,
  Tv,
  Search,
  PlaylistAddCheck,
  DoneAll,
  Storage,
  Stars,
  Transform,
  Notifications,
  CallToAction,
  Redeem,
  Dashboard,
  AssignmentTurnedIn,
  TrackChanges,
  GroupWork,
  Link as LinkIcon,
  Poll,
  Feedback,
  Business,
  ListAlt,
  InsertDriveFile,
  FileCopy,
  Loyalty,
  Cached,
  Note,
  TableChart,
  Fingerprint,
  Group,
  PermContactCalendar,
  Toc,
  AccountTree,
  MusicNote,
  School, 
  WorkOff,
  Straighten,
  SchoolRounded,
} from '@material-ui/icons';
import { Eye } from 'mdi-material-ui';

const RedMask = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0H18V18H0z" />
      <path
        fill="#BF0000"
        fillRule="nonzero"
        d="M14.58 2.25H4.17c-.231 0-.42.197-.42.439v7.18c0 3.24 2.525 5.881 5.625 5.881 1.506 0 2.915-.614 3.977-1.724.99-1.035 1.572-2.403 1.64-3.872.004-.097.008-.19.008-.286v-7.18c0-.24-.189-.438-.42-.438zM6.662 5.715h.826c.349 0 .63.294.63.658 0 .364-.281.658-.63.658h-.826c-.344 0-.63-.294-.63-.658 0-.364.286-.658.63-.658zm2.714 7.338c-1.745 0-3.167-1.505-3.167-3.356 0-.364.281-.658.63-.658.347 0 .628.294.628.658 0 1.123.856 2.04 1.909 2.04 1.049 0 1.904-.917 1.904-2.04 0-.364.281-.658.63-.658.348 0 .629.294.629.658 0 1.851-1.418 3.356-3.163 3.356zm2.71-6.022h-.827c-.348 0-.629-.294-.629-.658 0-.364.281-.658.63-.658h.826c.348 0 .629.294.629.658 0 .364-.281.658-.63.658z"
      />
    </g>
  </svg>
);

const Ads = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" style={{ fill: 'grey' }}>
    <path d="M468-240q-96-5-162-74t-66-166q0-100 70-170t170-70q97 0 166 66t74 162l-84-25q-13-54-56-88.5T480-640q-66 0-113 47t-47 113q0 57 34.5 100t88.5 56l25 84Zm48 158q-9 2-18 2h-18q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480v18q0 9-2 18l-78-24v-12q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93h12l24 78Zm305 22L650-231 600-80 480-480l400 120-151 50 171 171-79 79Z" />
  </svg>
);

export const getEntityName = (entity, type, showType = false) => {
  if (!entity) {
    return 'N/A';
  }

  switch (type) {
    case 'user':
    case 'users':
    case 'cuetv/customers/all':
    case 'cuetv/customers/operabase':
    case 'users/cuetv/customers':
    case 'users/registrations':
    case 'users/supportrequests': {
      return entity.email ?? entity.name;
    }
    
    case 'media': {
      let name = entity.title ?? entity.description;
      if (!name) {
        name = entity?.mediaType?.name ?? 'media';
      }
      return name;
    }

    case 'profile':
    case 'profiles': {
      const profileType = showType && entity?.profileType ? ` {${entity?.profileType?.name}}` : '';
      return (entity.firstName ? `${entity.firstName} ${entity.lastName}` : entity.name) + profileType;
    }

    case 'production':
    case 'productions': {
      if (entity.name) {
        return entity.name;
      }

      if (!entity.productionWorks || entity.productionWorks.length === 0) {
        return `Production {#${entity.id}}`;
      }

      return entity.productionWorks.map((w) => w?.work?.name).join(', ');
    }

    case 'review':
    case 'reviews': {
      return entity.title ?? entity.house ?? 'empty';
    }

    case 'works':
    case 'works/roles': {
      return `${entity.original_name ?? entity.name}`;
    }

    case 'invoices':
    case 'invoices/creditNotes': {
      return entity.number;
    }

    case 'users/subscriptions/unbilledCharges': {
      return entity.id;
    }

    case 'cuetv/subscriptions': {
      return entity.cuetvCustomer.email;
    }

    case 'mails': {
      return entity.recipientEmail;
    }

    case 'users/subscriptions': {
      return entity.user.email;
    }

    case 'profiles/profileaccessrequests': {
      return entity.sender.user.name;
    }

    case 'validations': {
      return `${entity.entity_type}: ${entity?.name}`;
    }

    case 'domains': {
      return `${entity?.domain}`;
    }

    case 'organizations': {
      const orgType = showType && entity?.organizationType ? ` {${entity?.organizationType?.name}}` : '';
      return `${entity?.name}${orgType}`;
    }

    default:
      return entity.name;
  }
};

export const getEntityPublicEditLink = (entity, type, args) => {
  if (!entity) {
    return null;
  }

  switch (type) {
    case 'production':
    case 'productions':
    case 'scrape-productions':
      const { forEntity, forEntityType } = args;
      if (!forEntity) {
        return 'missing forEntity';
      }
      let changeReqId = null;
      let entityId = entity.id;
      if (entity.revisionId) {
        changeReqId = entity.id;
        entityId = entity.revisionId;
      }
      let editUrl = `/edit/modify-production/en?production_id=${entityId}`;
      if (changeReqId) {
        editUrl += `&changeReqId=${changeReqId}`;
      }

      switch (forEntityType) {
        case 3: // organization
        case 6:
        case 7:
          return `${getEntityPublicLink(forEntity, 'organizations')}${editUrl}`;
        case 4: // artist
          return `${getEntityPublicLink(forEntity, 'profiles')}${editUrl}`;

        default:
          return `Invalid forEntity type: ${forEntity}`;
      }

    case 'profile':
    case 'profiles':
      return `${getEntityPublicLink(entity, type)}/edit/`;

    case 'agency':
    case 'agencies':
      return `${getEntityPublicLink(entity, type)}/agency_modif`;
    case 'organizations':
      return `${getEntityPublicLink(entity, type)}/edit/`;

    default:
      return null;
  }
};

export const getEntityPublicLink = (entity, type) => {
  if (!entity) {
    return null;
  }
  const url = `${process.env.REACT_APP_OPERABASE_URL}/`;

  switch (type) {
    case 'production':
    case 'productions':
    case 'scrape-productions':
      return `${url}productions/${entity.slug}-${entity.id}`;

    case 'profile':
    case 'profiles':
      return `${url}${entity.slug}-a${entity.id}`;

    case 'venue':
    case 'venues':
      return `${url}venues/${entity?.country?.slug}/${entity.slug}-${entity.id}`;

    case 'work':
    case 'works':
      return `${url}works/${entity.slug}-${entity.id}`;

    case 'agency':
    case 'agencies':
      return `${url}managers/${entity.slug}-${entity.id}`;

    case 'validations/production-validations':
      return `${url}productions/${entity.slug}-${entity.parentId ?? entity.id}`;

    case 'competition':
    case 'competitions':
      return `${url}competitions/${entity.slug}-${entity.id}`;

    case 'organizations':
      return `${url}${entity.slug}-o${entity.id}`;

    case 'professions':
      return `${url}artists/${entity.slug}/`;

    default:
      return null;
  }
};

export const getEntityIcon = (type) => {
  switch (type) {
    case 'productions':
    case 'productions/castandcrew':
    case 'scrape-productions':
      return <HomeWork />;
    case 'media':
      return <PermMedia />;
    case 'reviews':
      return <StarHalf />;
    case 'venues':
      return <Room />;
    case 'competitions':
      return <NaturePeople />;
    case 'works':
      return <Description />;
    case 'countries':
      return <Landscape />;
    case 'countries/cities':
      return <Landscape />;
    case 'countries/cities/altnames':
      return <Landscape />;
    case 'languages':
      return <Language />;
    case 'validations/merge':
    case 'users/mergecustomer':
    case 'metrics/entity-merge-metrics':
      return <CallMerge />;
    case 'validations/convert':
      return <Transform />;
    case 'export':
    case 'task_queues/magazine_export':
      return <CloudDownload />;
    case 'task_queues/agency_artist_enrichment':
      return <SupervisorAccount />;

    case 'profiles':
      return <RecordVoiceOver />;
    case 'agencies':
      return <SupervisorAccount />;
    case 'organizations':
      return <AccountBalance />;

    case 'cuetv/customers/all':
      return <RecordVoiceOver />;
    case 'cuetv/customers/operabase':
      return <SupervisorAccount />;
    case 'users/cuetv/customers':
      return <Tv />;
    case 'cuetv/subscriptions':
      return <AccountBalance />;

    case 'products':
      return <CardGiftcard />;
    case 'orders':
      return <LocalShipping />;
    case 'coupons':
      return <LocalOffer />;
    case 'addons':
      return <LocalOffer />;
    case 'invoices':
      return <EuroSymbol />;
    case 'invoices/creditNotes':
      return <EuroSymbol />;

    case 'users/subscriptions/unbilledCharges':
      return <EuroSymbol />;
    case 'users':
      return <Person />;
    case 'users/stats':
      return <Person />;
    case 'auth/logs':
      return <Lock />;
    case 'mails':
      return <Mail />;
    case 'users/subscriptions':
      return <CardMembership />;
    case 'users/registrations':
      return <Receipt />;
    case 'profiles/claims':
      return <CardMembership />;
    case 'profiles/profileaccessrequests':
      return <Receipt />;
    case 'users/supportrequests':
      return <Mail />;

    case 'search':
      return <Search />;

    case 'metrics/dbviews':
      return <Storage />;
    case 'metrics/datapoint-metrics':
      return <Stars />;
    case 'metrics/entity-validation-metrics':
      return <PlaylistAddCheck />;
    case 'metrics/production-action-metrics':
      return <CallToAction />;
    case 'metrics/email-metrics':
      return <Mail />;
    case 'metrics/pro-artist-metrics':
      return <RecordVoiceOver />;
    case 'metrics/notification-metrics':
      return <Notifications />;
    case 'metrics/gift-subscription-metrics':
      return <Redeem />;
    case 'metrics/casting-tool-usage':
      return <Business />;

    case 'validations':
      return <PlaylistAddCheck />;
    case 'validations/production-validations':
      return <DoneAll />;
    case 'validations/redmask-requests':
      return <RedMask />;
    case 'validations/change-requests':
      return <TrackChanges />;
    case 'validations/dashboard':
    case 'data/dashboard':
    case 'users/dashboard':
      return <Dashboard />;
    case 'validations/performances':
      return <AssignmentTurnedIn />;
    case 'professions/translations':
    case 'workTypes/translations':
    case 'organizationTypes/translations':
    case 'stagingTypes/translations':
    case 'system/data_inconsistencies':
      return <AssignmentTurnedIn />;
    case 'payout/export':
      return <CloudDownload />;
    case 'workTypes':
    case 'organizationTypes':
    case 'stagingTypes':
      return <GroupWork />;
    case 'domains':
      return <LinkIcon />;
    case 'redmaskrequests':
      return <RedMask />;
    case 'metrics/users':
      return <Poll />;
    case 'testimonials':
      return <Feedback />;
    case 'metrics/fingerprint':
      return <Fingerprint />;
    case 'curationlist':
      return <ListAlt />;
    case 'whitelist/requests':
      return <InsertDriveFile />;
    case 'validations/duplicates-entities':
      return <FileCopy />;
    case 'professions':
      return <School />;
    case 'task_queues/artistFreeTrials':
      return <Loyalty />;
    case 'task_queues/artistRenewals':
      return <Cached />;
    case 'task_queues/missingSeason':
    case 'task_queues/missing_program':
      return <Note />;
    case 'task_queues/data_qc':
      return <Eye />;
    case 'task_queues/missingProducer':
      return <Person />;
    case 'metrics/task-queue-metrics':
      return <TableChart />;
    case 'task_queues/missingCanonical':
      return <FileCopy />;
    case 'task_queues/multipleCast':
      return <Group />;
    case 'task_queues/artistsWithoutProfileAccess':
      return <PermContactCalendar />;
    case 'metrics/scrape-requests':
      return <Toc />;
    case 'organizations/departments':
      return <AccountTree />;
    case 'task_queues/concertVarious':
      return <Description />;
    case 'metrics/artist-ads-metrics':
      return <Ads />;
    case 'instruments':
      return <Straighten />;
    case 'instruments/roles':
      return <MusicNote />;
    case 'task_queues/wrong_profession':
      return <WorkOff />;
    case 'task_queues/external_reference_validations':
      return <SchoolRounded />;
    default:
      return null;
  }
};
