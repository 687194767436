import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-admin';

const useStyles = makeStyles(
  (theme) => ({
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      marginRight: 10,
    },
  }),
  { name: 'MakeLink' },
);

const UserLink = ({ record }) => {
  const classes = useStyles();

  if (!record) return '';

  return (
    <Link to={`/users/${record.id}/show`} className={classes.link} onClick={(e) => e.stopPropagation()}>
      {record?.first_name} {record?.last_name}
    </Link>
  );
};

export default UserLink;
