/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import {
  List,
  ImageField,
  TabbedShowLayout,
  Filter,
  TextInput,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  Tab,
  Toolbar,
  ArrayField,
  SingleFieldList,
  SaveButton,
  DeleteButton,
  DateInput,
  ReferenceManyField,
  FunctionField,
  Button,
  Confirm,
  useMutation,
  TopToolbar,
  useNotify,
  AutocompleteInput,
  DateField,
  ReferenceField,
  Pagination,
  useDataProvider,
  ListContextProvider,
  Loading,
  SimpleShowLayout,
} from 'react-admin';
import { useSelector } from 'react-redux';
import RichTextInput from 'ra-input-rich-text';
import { Link, Chip, makeStyles, withStyles, Button as MUIButton, TextField as MUITextField, MenuItem } from '@material-ui/core';
import lodash from 'lodash';
import { ValidateProductionButton } from '../components/bulkActionButtons';
import { VALIDATION_STATUSES_IDS } from '../constants/validations';
import CustomDateField from '../components/customFields/CustomDateField';
import { MediaThumbnailField } from '../components/MediaThumbnailField';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { PublicLink } from '../components/PublicLink';
import EntityLink from '../components/EntityLink';
import { Shadowy } from '../components/Shadowy';
import ValidityShow from '../components/ValidityShow';
import RelatedEntitiesValidation from '../components/RelatedEntitiesValidation';
import CustomShowLayout from '../components/CustomShowLayout';
import { PRODUCTION_VALIDATION_STATUSES } from '../constants/production';
import apiClient from '../utils/api';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  redMaskToolbarButton: {
    marginLeft: '0.3rem',
  },
  siteLink: {
    color: '#3333FF',
    textDecoration: 'underline',
  },
  banner: {
    textAlign: 'center',
    backgroundColor: '#c9e0f2',
    width: '100%',
    padding: '5px 0',
    fontWeight: 'bold',
  },
};

const useStyles = makeStyles(() => ({
  input: {
    width: 200,
    marginRight: 10,
  },
  dataGrid: {
    overflowX: 'scroll',
    width: '80vw',
  },
}));
const CustomToolbar = withStyles(toolbarStyles)((props) => (
  <Toolbar {...props}>
    <SaveButton />
    <DeleteButton undoable={false} />
  </Toolbar>
));

const validationStatusOptions = [
  { id: VALIDATION_STATUSES_IDS.PENDING, name: 'Pending' },
  { id: VALIDATION_STATUSES_IDS.VALID, name: 'Validated' },
  { id: VALIDATION_STATUSES_IDS.INVALID, name: 'Rejected' },
];

const actionTypes = {
  VIEW: 'view',
  PUBLISH: 'publish',
  VALIDATE: 'validate',
  REJECT: 'reject',
  REQUEST_VALIDATION: 'request_validation',
};

const supportDocumentTypes = {
  supportProductionPage: 'Production page (URL)',
  supportSignedContract: 'Signed contract',
  supportProductionReview: 'Production review',
  supportProductionProgramme: 'Production programme',
};

const SEASONS = lodash
  .range(1996, new Date().getFullYear() + 5)
  .map((y) => ({
    id: y,
    name: `${y}-${y + 1}`,
  }))
  .reverse();

const YEARS = lodash
  .range(1996, new Date().getFullYear() + 5)
  .map((y) => ({
    id: y,
    name: y.toString(),
  }))
  .reverse();

export const ProductionsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ID" source="id" alwaysOn />
    <TextInput label="Custom title" source="name" alwaysOn />
    <SelectInput label="Season" source="season_id" choices={SEASONS} optionText="name" optionValue="id" alwaysOn />
    <SelectInput label="Year" source="year_id" choices={YEARS} optionText="name" optionValue="id" alwaysOn />

    <ReferenceInput label="Organization" source="organization_id" reference="organizations" filterToQuery={(searchText) => ({ query: searchText })} alwaysOn allowEmpty>
      <AutocompleteInput optionText={(organization) => (organization?.name ? `(${organization.id}) - ${organization.name}` : 'All')} matchSuggestion={() => true} />
    </ReferenceInput>

    <ReferenceInput label="Profile" source="individual_id" reference="profiles" filterToQuery={(searchText) => ({ query: searchText })} alwaysOn allowEmpty>
      <AutocompleteInput optionText={(profile) => (profile?.name ? `(${profile.id}) - ${profile.name}` : 'All')} matchSuggestion={() => true} />
    </ReferenceInput>

    <ReferenceInput label="Venue" source="venue_id" reference="venues" filterToQuery={(searchText) => ({ query: searchText })} alwaysOn allowEmpty>
      <AutocompleteInput optionText={(venue) => (venue && venue.name ? `(${venue.id}) - ${venue.name}` : 'All')} matchSuggestion={() => true} />
    </ReferenceInput>

    <ReferenceInput label="Composer" source="work_creator_id" reference="profiles" filterToQuery={(searchText) => ({ query: searchText })} alwaysOn allowEmpty>
      <AutocompleteInput optionText={(profile) => (profile && profile.name ? `(${profile.id}) - ${profile.name}` : 'All')} matchSuggestion={() => true} />
    </ReferenceInput>

    <ReferenceInput label="Work" source="work_id" reference="works" filterToQuery={(searchText) => ({ query: searchText })} alwaysOn allowEmpty>
      <AutocompleteInput optionText={(work) => (work && work.original_name ? `(${work.id}) - ${work.original_name}` : 'All')} matchSuggestion={() => true} />
    </ReferenceInput>

    <ReferenceInput label="City" source="city_id" reference="countries/cities" filterToQuery={(searchText) => ({ query: searchText })} sort={{ field: 'name', order: 'ASC' }} alwaysOn allowEmpty>
      <AutocompleteInput
        optionText={(record) => {
          let name = '';
          name = record?.name;

          if (record?.areas?.length) {
            name = `${name} (${record?.areas?.[0]?.name})`;
          }

          if (record?.country?.name) {
            name = `${name} (${record?.country?.name})`;
          }

          return name;
        }}
        matchSuggestion={() => true}
      />
    </ReferenceInput>
    <DateInput source="date_from" allowEmpty />
    <DateInput source="date_to" allowEmpty />
    <SelectInput source="validation_status_id" label="Validation status" choices={validationStatusOptions} alwaysOn allowEmpty />
  </Filter>
);

const ProductionActionButton = (props) => {
  const notify = useNotify();
  const [dialogIsOpen, setDialogOpen] = useState(false);
  const [runProductionAction] = useMutation(
    {
      type: 'create',
      resource: 'productions/actions',
      payload: { data: { production: { id: props.data?.id }, type: props.action, addedBy: props.data?.addedBy, approvedBy: { id: 2 } } },
    },
    {
      onSuccess: () => notify('Status updated', 'info'),
      onFailure: (err) => notify(err.response?.data?.message?.message || err.message, 'error'),
    },
  );

  const handleClick = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);
  const handleConfirm = () => {
    runProductionAction();
    setDialogOpen(false);
  };
  const classes = makeStyles(toolbarStyles)();

  return (
    <>
      <Button label={props.label || props.action} onClick={handleClick} disabled={props.disabled} variant="outlined" color="default" className={classes.redMaskToolbarButton} />
      <Confirm isOpen={dialogIsOpen} title={`Confirm ${props.action}`} content={`Are you sure you want to ${props.action} production?`} onConfirm={handleConfirm} onClose={handleDialogClose} />
    </>
  );
};

const ProductionActionsToolbar = (props) => {
  const classes = makeStyles(toolbarStyles)();
  const approvedBy = props?.data?.approvedBy?.slug;
  const addedBy = props?.data?.addedBy?.slug;
  const hasRedMask = ['organization', 'admin'].includes(approvedBy) || ['organization', 'admin'].includes(addedBy);
  const published = !!props.data?.published_at;
  const isScrapeRequestProduction = props.resource === 'scrape-productions';

  return (
    <div>
      {isScrapeRequestProduction && <div className={classes.banner}>Created from scrape request</div>}
      <TopToolbar {...props}>
        <PublicLink {...props} />
        <PublicLink edit {...props} />
        <ProductionActionButton {...props} action={actionTypes.REQUEST_VALIDATION} disabled={hasRedMask} />
        <ProductionActionButton {...props} action={actionTypes.VALIDATE} label="Approve" disabled={hasRedMask} />
        <ProductionActionButton {...props} action={actionTypes.REJECT} disabled={hasRedMask} />
        <ProductionActionButton {...props} action={actionTypes.PUBLISH} disabled={published} />
      </TopToolbar>
    </div>
  );
};

export const Entity = (props) => <EntityLink entity={props.record} entityType={props.entityType} />;

export const OwnerName = ({ record }) => {
  const { name } = record;
  return <Chip label={name} />;
};

export const ProductionsShow = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const productionData = useSelector((store) => store.admin.resources.productions.data?.[props?.id]);
  const getReference = () => {
    switch (productionData?.forEntity) {
      case 7:
        return 'organizations';
      case 4:
        return 'profiles';
      default:
        return null;
    }
  };
  const reference = getReference();

  const revokeRedMask = (record) => {
    const redMaskRequestId = record?.id;
    dataProvider
      .update(`productions/redMaskRequests`, { id: redMaskRequestId, data: { validationStatus: { id: PRODUCTION_VALIDATION_STATUSES.REJECTED } } })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        notify(`Error revoking red mask: ${err}`, {
          autoHideDuration: 30000,
          type: 'error',
        });
      });
  };

  return (
    <ValidityShow {...props} title={<Breadcrumbs {...props} />} actions={<ProductionActionsToolbar />} disableEditValidation>
      <TabbedShowLayout>
        <Tab label="Production Info">
          <CustomShowLayout {...props} isStats>
            {{
              column1: (
                <SimpleShowLayout>
                  <TextField source="id" />
                  <TextField source="addedBy.name" emptyText="(Empty)" label="Added by" />
                  {reference && (
                    <ReferenceField label="Production owner" source="forEntityId" reference={reference} link="show">
                      <OwnerName />
                    </ReferenceField>
                  )}

                  <Shadowy {...props}>
                    <FunctionField
                      label="Date"
                      render={(production) => {
                        let d = production.minDate ?? '';
                        if (d && d.length > 1) {
                          d += ' - ';
                        }
                        d += production.maxDate ?? '';
                        return <span style={{ whiteSpace: 'nowrap' }}>{d}</span>;
                      }}
                    />
                  </Shadowy>

                  <FunctionField label="Entity Validation Entities" render={(record) => <RelatedEntitiesValidation entityId={record?.id} entityType="productions" hideLabel />} />

                  <FunctionField
                    label="Custom title"
                    render={(production) => {
                      const name = production?.name;
                      const works = production?.productionWorks || [];
                      const workName = works.map((work) => work?.work?.name).join(', ');

                      if (!name && !workName) return '(No custom title)';

                      return <div>{name || workName}</div>;
                    }}
                  />

                  <ArrayField source="productionWorks" label="Works">
                    <SingleFieldList linkType={false}>
                      <FunctionField
                        render={(record) => (
                          <div style={{ marginRight: 10 }}>
                            <EntityLink entity={record?.work} entityType="works" />
                            <EntityLink entity={record?.work?.workType} entityType="workTypes" />
                          </div>
                        )}
                      />
                    </SingleFieldList>
                  </ArrayField>

                  <Shadowy {...props}>
                    <ImageField source="poster.file.urls.medium" label="Poster" emptyText="Empty" addLabel={false} />
                  </Shadowy>
                </SimpleShowLayout>
              ),
              column2: (
                <SimpleShowLayout>
                  <FunctionField
                    label="Producer"
                    render={(production) => {
                      const producer = production?.contributions?.find((contrib) => contrib?.contributionType === 'producer');
                      if (producer) {
                        if (producer?.organization) {
                          return <EntityLink entity={producer.organization} entityType="organizations" />;
                        }
                        if (producer?.profile) {
                          return <EntityLink entity={producer.profile} entityType="profiles" />;
                        }
                      }
                    }}
                  />

                  <FunctionField
                    label="Co-producers"
                    render={(production) => {
                      const contributions = production?.contributions?.filter((contrib) => contrib?.contributionType === 'co-producer');
                      const list = [];
                      if (contributions?.length) {
                        contributions.forEach((contrib) => {
                          if (contrib.organization) {
                            list.push(<EntityLink entity={contrib.organization} entityType="organizations" />);
                          }
                          if (contrib.profile) {
                            list.push(<EntityLink entity={contrib.profile} entityType="profiles" />);
                          }
                        });
                      }
                      return list;
                    }}
                  />

                  <ArrayField source="venues" label="Venues">
                    <SingleFieldList>
                      <Entity entityType="venues" />
                    </SingleFieldList>
                  </ArrayField>

                  <ArrayField source="language" label="Languages">
                    <SingleFieldList>
                      <Entity entityType="languages" />
                    </SingleFieldList>
                  </ArrayField>

                  <TextField source="website" label="Website" />

                  <ReferenceManyField label="Validation documents" reference="productions/documents" target="production_id" filter={{ document_type: Object.keys(supportDocumentTypes) }}>
                    <Datagrid>
                      <TextField source="id" sortable={false} />
                      <FunctionField
                        label="Artist production edit page"
                        render={(document) =>
                          document.profile ? (
                            <Link href={`${process.env.REACT_APP_OPERABASE_URL}/artists/${document.profile.slug}-${document.profile.id}/edit/validation/en?production_id=${document.production.id}`}>
                              {document.profile.name}
                            </Link>
                          ) : null
                        }
                      />
                      <FunctionField
                        label="Document"
                        render={(document) =>
                          document.file || document.url ? (
                            <Link href={document?.file?.original || document.url} target="_blank">
                              {supportDocumentTypes[document.documentType] || document.documentType}
                            </Link>
                          ) : (
                            supportDocumentTypes[document.documentType] || document.documentType
                          )
                        }
                      />
                    </Datagrid>
                  </ReferenceManyField>

                  <FunctionField
                    label="Production info"
                    render={(record) => {
                      const isVerified = record?.validationStatus?.slug === 'approved';

                      return isVerified ? 'Verified' : 'Unverified';
                    }}
                  />
                  <TextField source="approvedBy.slug" emptyText="(Empty)" label="Approved by" />

                  <CustomDateField source="createdAt" />
                  <CustomDateField source="updatedAt" />
                </SimpleShowLayout>
              ),
            }}
          </CustomShowLayout>
        </Tab>

        <Tab label="performances">
          <ReferenceManyField label="Production Performances" reference="productions/productionPerformances" target="production_id" sort={{ field: 'start_date,start_time', order: 'ASC' }}>
            <Datagrid rowClick="show">
              <TextField source="id" sortable={false} />
              <TextField source="startDate" label="Date" sortable={false} />
              <TextField source="startTime" label="Time" sortable={false} />
              <TextField source="mode" label="Mode" sortable={false} />
              <FunctionField
                render={(production) =>
                  [
                    production.isArchived ? 'Archived' : null,
                    production.isCancelled ? 'Cancelled' : null,
                    production.isMatinee ? 'Matinee' : null,
                    production.isPractiseRun ? 'Practive Run' : null,
                    production.isPremiere ? 'Premiere' : null,
                    production.isRescheduled ? 'Reschedule' : null,
                    production.isTour ? 'Tour' : null,
                    production.isWorldPremiere ? 'WP' : null,
                  ]
                    .filter((tag) => tag)
                    .map((tag) => <Chip label={tag}></Chip>)
                }
              />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="media">
          <ReferenceManyField filter={{ media_tag_type_id: 4 }} sort={{ field: 'createdAt', order: 'DESC' }} label="Media Records" reference="media" target="media_tag_id">
            <Datagrid rowClick="show">
              <TextField source="id" />
              <TextField source="mediaType.name" label="Type" sortable={false} />
              <FunctionField
                label="Attribute"
                render={(media) => {
                  if (media.attributes.filter((a) => a.isPrimary && a.slug === 'poster').length > 0) {
                    return <Chip label="Poster"></Chip>;
                  }
                  return <></>;
                }}
              />
              <MediaThumbnailField label="Image" />
              <TextField source="name" label="Title" sortable={false} />
              <TextField source="description" label="Description" sortable={false} />
              <DateField source="createdAt" showTime />
              <TextField source="status.name" label="Status Name" sortable={false} />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Contributions">
          <Contributions {...props} />
        </Tab>

        <Tab label="Tickets">
          <ReferenceManyField label="Tickets" reference="productions/tickets" target="production_id" pagination={<Pagination />} perPage={10}>
            <Datagrid>
              <TextField source="id" label="ID" sortable={false} />
              <DateField source="bookingStartDate" label="Booking start date" sortable={false} />
              <TextField source="bookingStartTime" label="Booking start time" sortable={false} />
              <TextField source="currency.currency" label="Currency" sortable={false} />
              <TextField source="minTicketPrice" label="Min ticket price" sortable={false} />
              <TextField source="maxTicketPrice" label="Max ticket price" sortable={false} />
              <TextField source="website" label="Website" sortable={false} />
              <TextField source="boxOfficeWebsite" label="Box office website" sortable={false} />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Logs">
          <ProductionLogs {...props} />
        </Tab>
        <Tab label="Red masks requests">
          <ArrayField source="redMaskRequests" label="Red mask requests" sortable={false}>
            <Datagrid>
              <TextField source="id" />
              <FunctionField label="Profile" render={(record) => <EntityLink entityType="profiles" entity={record?.requestedByProfile} />} />
              <FunctionField label="User" render={(record) => <EntityLink entityType="users" entity={record?.requestedByUser} />} />
              <DateField source="created_at" showTime />
              <TextField label="Validation Status" source="validationStatus.name" />
              <FunctionField
                label="Actions"
                render={(record) => {
                  if (record?.validationStatus?.id === VALIDATION_STATUSES_IDS.VALID) {
                    return (
                      <MUIButton variant="contained" size="small" onClick={() => revokeRedMask(record)}>
                        Revoke red mask
                      </MUIButton>
                    );
                  }

                  return null;
                }}
              />
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </ValidityShow>
  );
};

const ProductionLogs = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const [logs, setLogs] = useState();
  const [loading, setLoading] = useState(true);
  const [actorType, setActorType] = useState();
  const [entityCreatorType, setEntityCreatorType] = useState();
  const [actionType, setActionType] = useState();

  useEffect(() => {
    setLoading(true);
    apiClient
      .get(`productions/${props?.id}/events`, { params: { actor_type: actorType, entity_creator_type: entityCreatorType, action_type: actionType } })
      .then((res) => {
        setLogs(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        notify(err?.response?.data?.message, 'warning');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actorType, entityCreatorType, actionType, props?.id]);

  const listContext = {
    data: lodash.keyBy(logs?.data, (log) => log?.actionId),
    ids: logs?.data?.map((log) => log.actionId),
    total: logs?.length || 0,
    basePath: props.basePath,
    resource: props.resource,
    selectedIds: [],
    currentSort: {},
    hasList: true,
  };

  return (
    <>
      <MUITextField label="Actor type" value={actorType} defaultValue={actorType} onChange={(event) => setActorType(event.target.value)} variant="filled" size="small" select className={classes.input}>
        <MenuItem value="">All</MenuItem>
        <MenuItem value="user">User</MenuItem>
        <MenuItem value="command">Command</MenuItem>
      </MUITextField>
      <MUITextField
        label="Entity Creator Type"
        value={actorType}
        defaultValue={entityCreatorType}
        onChange={(event) => setEntityCreatorType(event.target.value)}
        variant="filled"
        size="small"
        select
        className={classes.input}
      >
        <MenuItem value="">All</MenuItem>
        <MenuItem value="user">User</MenuItem>
        <MenuItem value="command">Command</MenuItem>
      </MUITextField>
      <MUITextField
        label="Action Type"
        value={actorType}
        defaultValue={actionType}
        onChange={(event) => setActionType(event.target.value)}
        variant="filled"
        size="small"
        select
        className={classes.input}
      >
        <MenuItem value="">All</MenuItem>
        <MenuItem value="create">Create</MenuItem>
        <MenuItem value="update">Update</MenuItem>
        <MenuItem value="delete">Delete</MenuItem>
      </MUITextField>
      <p>Logs</p>
      {!loading ? (
        <ListContextProvider value={listContext}>
          <div className={classes.dataGrid}>
            <Datagrid>
              <TextField source="actionTimestamp" label="Time" sortable={false} />
              <TextField source="entityType" label="Entity Type" sortable={false} />
              <TextField source="entityId" label="Entity Id" showTime sortable={false} />
              <TextField source="actionType" label="Action type" sortable={false} />
              <FunctionField label="Original Creator" render={(record) => record?.actor?.category === 'user' && <EntityLink entityType="users" entity={record?.actor?.details} />} />
              <TextField source="actor.category" label="Actor Command" sortable={false} />
              <TextField source="propertiesAffected" label="Properties Changed" sortable={false} />
            </Datagrid>
          </div>
        </ListContextProvider>
      ) : (
        <Loading />
      )}
    </>
  );
};

export const ProductionsEdit = (props) => (
  <Edit {...props} title={<Breadcrumbs {...props} />} toolbar={<CustomToolbar />}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="profileType.name" label="profile type" />
      <TextInput source="summary" />
      <RichTextInput source="description" />
      <ReferenceInput label="user" source="user.id" reference="users">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

const ProductionsBulkActionButtons = (props) => (
  <>
    <ValidateProductionButton {...props} />
  </>
);

export const ProductionsGrid = (props) => (
  <Datagrid rowClick="show" {...props}>
    <TextField source="id" />
    <DateField source="updatedAt" label="LastUpdated" showTime />

    {!props?.hideMask && (
      <FunctionField
        label="Mask"
        render={(production) => <img src={production?.redMaskStatus === 'approved' ? `${process.env.PUBLIC_URL}/icons/redMask.svg` : `${process.env.PUBLIC_URL}/icons/greyMask.svg`} alt="" />}
      />
    )}

    <ImageField source="poster.file.urls.small" label="Poster" />

    <FunctionField
      label="Name | Works"
      render={(production) => {
        const list = [];
        list.push(<span style={{ display: 'block', whiteSpace: 'nowrap' }}>{production.name ?? '(No custom name)'}</span>);
        if (production?.productionWorks?.length) {
          production.productionWorks.forEach((work) => {
            list.push(<EntityLink entity={work?.work} entityType="works" />);
          });
        }
        return list;
      }}
    />

    <FunctionField
      label="Date"
      render={(production) => {
        let d = production.minDate ?? '';
        if (d && d.length > 1) {
          d += ' - ';
        }
        d += production.maxDate ?? '';
        return <span style={{ whiteSpace: 'nowrap' }}>{d}</span>;
      }}
    />

    <FunctionField
      label="Producer | Co-Producer | Venues"
      render={(production) => {
        const contributions = production?.contributions?.filter((contrib) => ['producer', 'co-producer'].includes(contrib?.contributionType));
        const list = [];
        if (contributions?.length) {
          contributions.forEach((contrib) => {
            if (contrib.organization) {
              list.push(<EntityLink entity={contrib.organization} entityType="organizations" />);
            }
            if (contrib.profile) {
              list.push(<EntityLink entity={contrib.profile} entityType="profiles" />);
            }
          });
        }
        if (production?.venues?.length) {
          production.venues.forEach((venue) => {
            list.push(<EntityLink entity={venue} entityType="venues" />);
          });
        }
        return list;
      }}
    />

    <TextField source="validationStatus.name" label="Validation status" />
  </Datagrid>
);

export const ProductionsList = (props) => (
  <List
    {...props}
    title={<Breadcrumbs {...props} />}
    filters={<ProductionsFilter />}
    bulkActionButtons={<ProductionsBulkActionButtons />}
    sort={{ field: 'updated_at', order: 'DESC' }}
    perPage={25}
    exporter={false}
    filterDefaultValues={{ work_creator_profession: 'composer' }}
  >
    <ProductionsGrid {...props} hideMask />
  </List>
);

const Contributions = (props) => {
  const dataProvider = useDataProvider();
  const [contributions, setContributions] = useState([]);

  useEffect(() => {
    dataProvider
      .getList(`productions/${props?.id}/contributions`, {
        filter: {},
        pagination: {},
        sort: {},
      })
      .then((res) => {
        const allContributions = res?.data || [];
        setContributions([...allContributions]);
      });
  }, [dataProvider, props.id]);

  const listContext = {
    data: lodash.keyBy(contributions, 'id'),
    ids: contributions.map(({ id }) => id),
    total: contributions.length,
    currentSort: {},
    basePath: props.basePath,
    resource: props.resource,
    selectedIds: [],
    hasList: true,
  };

  if (!contributions.length) return <div style={{ margin: '24px 0', fontSize: 14 }}>No results found</div>;

  return (
    <ListContextProvider value={listContext}>
      <Datagrid>
        <TextField source="id" label="ID" sortable={false} />
        <FunctionField render={(record) => (record?.profile ? <EntityLink entityType="profiles" entity={record?.profile} /> : null)} label="Profile" />
        <FunctionField render={(record) => (record?.organization ? <EntityLink entityType="organizations" entity={record?.organization} /> : null)} label="Organization" />
        <TextField source="contributionType" label="Contribution Type" sortable={false} />
        <TextField source="profession.name" label="Profession" sortable={false} />
        <FunctionField source="productionWork.work.id" label="Work" sortable={false} render={(record) => <EntityLink entity={record?.productionWork?.work} entityType="works" />} />
        <TextField source="workRole.original_name" label="Work role" sortable={false} />
        <TextField source="workRoleReference" label="Work role reference" sortable={false} />
        <FunctionField
          render={(record) => {
            if (record?.workRoleReference) {
              return record?.workRoleType?.name;
            }

            return record?.workRole?.workRoleType?.name;
          }}
          label="Work role type"
        />
      </Datagrid>
    </ListContextProvider>
  );
};

export default ProductionsList;
