import React, { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import { startCase, map, size, orderBy } from 'lodash';
import cn from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { DB_VIEWS_DATA_QUALITY_CONFIG } from '../constants/dbviews';
import EntityDuplicates from './EntityDuplicates';

const useStyles = makeStyles(() => ({
  infoCard: {
    position: 'absolute',
    top: 270,
    right: 30,
    borderRadius: 8,
    fontSize: 14,
    '& ul': {
      listStyle: 'none',
      margin: '8px 0',
      padding: 0,
    },
    '& label': {
      marginRight: 8,
      fontWeight: 'bold',
    },
    '& h4': {
      margin: 0,
      padding: 0,
      textAlign: 'center',
    },
  },
  entityStats: {
    background: '#FFFAAA',
    padding: 8,
  },
  entityQuality: {
    background: '#FFFAAA',
    padding: 8,
    marginTop: 20,
  },
  entityDuplicates: { marginTop: 20 },
  qualityItem: {
    display: 'flex',
    alignItems: 'center',
  },
  checkIcon: {
    fontSize: 18,
    color: 'green',
  },
  closeIcon: {
    fontSize: 16,
    color: 'red',
  },
  borderTop: {
    borderTop: '1px dotted',
    marginTop: 5,
    paddingTop: 5,
  },
}));

export const EntityStats = (props) => {
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const entity = props.record || {};
  const [isStatsLoading, setStatsLoading] = useState(false);
  const [isQualityLoading, setQualityLoading] = useState(true);
  const [stats, setStats] = useState([]);
  const [quality, setQuality] = useState(null);
  const dataQualityConfig = DB_VIEWS_DATA_QUALITY_CONFIG?.[props?.resource];

  const getStats = (data) => {
    const entries = [];
    entries.push({
      dataKey: 'id',
      key: 'id',
      value: entity.id,
    });
    entries.push({
      dataKey: 'created',
      key: 'Created',
      value: entity.createdAt || entity.created_at,
    });
    entries.push({
      dataKey: '',
      key: '',
      value: '',
    });

    function parseObj(p, obj) {
      Object.keys(obj)
        .sort()
        .forEach((key) => {
          if (Array.isArray(obj[key]) || obj[key] === null) {
            return;
          }
          if (typeof obj[key] === 'object') {
            parseObj(key, obj[key]);
            return;
          }
          entries.push({
            dataKey: key,
            key: (p ? `${startCase(p)} ` : '') + startCase(key).concat(':'),
            // eslint-disable-next-line no-nested-ternary
            value: typeof obj[key] === 'boolean' ? (obj[key] ? 'true' : 'false') : obj[key],
          });
        });
    }

    if (!data) return null;

    parseObj('', data);

    const productionKeys = ['id', 'created', '', 'allPerformances', 'publishedProductionCount', 'approvedProductions', 'greyMaskProductionCount', 'futurePerformanceCount', 'futureProductionCount'];
    return orderBy(
      entries,
      (entry) => {
        const index = productionKeys.indexOf(entry.dataKey);
        return index === -1 ? 100 : index;
      },
      'asc',
    );
  };

  useEffect(() => {
    setQualityLoading(true);

    if (!props.hideStats) {
      setStatsLoading(true);
      dataProvider
        .getList(props.resource, {
          filter: { as_edit: true, id: entity.id },
          pagination: { perPage: 1, page: 1 },
          sort: { field: 'id', order: 'DESC' },
        })
        .then((res) => {
          const data = getStats(res.data[0]?.stats);
          setStatsLoading(false);
          setStats(data);
        });
    }

    if (dataQualityConfig) {
      dataProvider
        .getList('metrics/dbviews', {
          filter: { view_id: dataQualityConfig?.viewId, [dataQualityConfig?.dataKey]: entity?.id },
          pagination: { perPage: 10, page: 1 },
          sort: { field: 'id', order: 'DESC' },
        })
        .then((res) => {
          setQualityLoading(false);
          let columns = {};
          const dataQualityResponse = res?.data?.[0] || [];

          map(dataQualityResponse, (value, key) => {
            if (key.toLowerCase().endsWith(`_score`)) {
              const dataKey = startCase(key.replace(`_score`, ''));
              columns = {
                ...columns,
                [dataKey]: value,
              };
            }
          });
          setQuality(columns);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProvider, dataQualityConfig, entity.id, props.resource]);

  return (
    <div className={cn(classes.infoCard, props?.className)}>
      {!props.hideStats && <Stats data={stats} isLoading={isStatsLoading} />}
      <Quality data={quality} isLoading={isQualityLoading} />
      {!props.hideDuplicates && (
        <div className={classes.entityDuplicates}>
          <EntityDuplicates {...props} />
        </div>
      )}
    </div>
  );
};

const Stats = ({ data, isLoading }) => {
  const classes = useStyles();

  if (isLoading) {
    return (
      <div className={classes.entityStats}>
        <h4>Statistics</h4>
        <div>Loading</div>
      </div>
    );
  }

  if (!size(data)) return null;

  return (
    <div className={classes.entityStats}>
      <h4>Statistics</h4>
      <ul>
        {data.map((entry) => (
          <li>
            <label>{entry.key}</label>
            <span>{entry.value}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Quality = ({ data, isLoading }) => {
  const classes = useStyles();

  if (isLoading) {
    return (
      <div className={classes.entityQuality}>
        <h4>Quality</h4>
        <div>Loading</div>
      </div>
    );
  }

  if (!size(data)) return null;

  const renderValue = (key, value) => {
    if (key === 'Score') return <b>{value}%</b>;

    return value ? <CheckIcon className={classes.checkIcon} /> : <CloseIcon className={classes.closeIcon} />;
  };

  return (
    <div className={classes.entityQuality}>
      <h4>Quality</h4>
      <ul>
        {map(data, (value, key) => (
          <li className={cn(classes.qualityItem, { [classes.borderTop]: key === 'Score' })}>
            <label>{key}:</label>
            {renderValue(key, value)}
          </li>
        ))}
      </ul>
    </div>
  );
};
