import React, { Children, cloneElement } from 'react';
import { Grid } from "@material-ui/core";

export const SanitizedGrid = ({ source, children, basePath, ...otherProps }) => {
  return <Grid {...otherProps}>{Children.map(children, (child) => {
    let fullSource = source;
    if (source && child.props.source) {
      fullSource = `${source}.${child.props.source}`;
    }
    else if (child.props.source) {
      fullSource = child.props.source;
    }

    return cloneElement(child, { source: fullSource });
  })}</Grid>
}
