import React from 'react';

import {
  List,
  Filter,
  TextInput,
  Datagrid,
  TextField,
  Tab,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  DateField,
  BooleanField,
  ReferenceField,
  Create,
  ImageField,
  SelectInput,
  ArrayField,
  Edit,
  UrlField,
  TopToolbar,
  EditButton,
} from 'react-admin';

import ValidityShow from '../components/ValidityShow';
import { CreateActions } from '../components/DefaultActions';
import { MEDIA_TYPES, MEDIA_STATUSES, MEDIA_TAG_TYPES } from '../constants/mediaTypes';
import { MediaThumbnailField } from '../components/MediaThumbnailField';
import { Breadcrumbs } from '../components/Breadcrumbs';
import EntityLink from '../components/EntityLink';

const nullableBoolean = [
  { id: 'true', name: 'Yes' },
  { id: 'false', name: 'No' },
  { id: 'null', name: 'Both' },
];

const MediaFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Media ID" source="id" alwaysOn />
    <TextInput label="Search" source="query" alwaysOn />
    <SelectInput label="Media type" source="media_type_id" choices={MEDIA_TYPES} alwaysOn />
    <SelectInput label="Media status" source="media_status_id" choices={MEDIA_STATUSES} alwaysOn />
    <SelectInput label="Media Tag Type" source="media_tag_type_id" choices={MEDIA_TAG_TYPES} alwaysOn />
    <SelectInput label="Has Posters" source="has_posters" choices={nullableBoolean} alwaysOn />
    <SelectInput label="Has Trailers" source="has_trailers" choices={nullableBoolean} alwaysOn />
    <TextInput label="Media Tag ID" source="media_tag_id" alwaysOn />
  </Filter>
);

export const ReferenceMediaEntity = (props) => {
  const { record } = props;
  let reference = null;
  switch (record?.tagType.slug) {
    case 'organization':
      reference = 'organizations';
      break;
    case 'production':
      reference = 'productions';
      break;
    case 'profile':
      reference = 'profiles';
      break;
    case 'venue':
      reference = 'venues';
      break;
    case 'work':
      reference = 'works';
      break;
    default:
      return <div>N/A</div>;
  }
  return (
    <ReferenceField link="show" source="tagId" reference={reference} {...props}>
      <Entity />
    </ReferenceField>
  );
};

export const Entity = (props) => <EntityLink entity={props.record} entityType={props.resource} />;

export const MediaShowActions = (props) => {
  const { basePath, data } = props;
  return (
    <TopToolbar>
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );
};

export const MediaShow = (props) => (
  <ValidityShow {...props} title={<Breadcrumbs {...props} />} actions={<MediaShowActions />} hideValidity>
    <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" {...props} />}>
      <Tab label="Media Info">
        <MediaThumbnailField label="Image" size="medium" />

        <TextField source="dataSource.name" label="Data Source" emptyText="Missing" />
        <TextField source="mediaType.name" label="Media Type" emptyText="Missing" />
        <BooleanField source="isStreamable" />
        <UrlField source="url" label="Url" emptyText="Empty" />
        <TextField source="title" emptyText="Empty" />
        <TextField source="description" emptyText="Empty" />

        <ArrayField source="tags">
          <Datagrid>
            <TextField source="id" label="Id" />
            <TextField source="tagType.name" label="Type" />
            <TextField source="tagId" label="Entity ID" />
            <ReferenceMediaEntity label="Entity" />

            <TextField source="availabilityTypes" />
          </Datagrid>
        </ArrayField>

        <DateField source="createdAt" showTime />
        <TextField source="status.name" label="Status Name" sortable={false} />
        <TextField source="errorCode" label="Error Code" sortable={false} />
      </Tab>

      <Tab label="Media Attributes">
        <ArrayField source="attributes">
          <Datagrid>
            <TextField source="id" />
            <BooleanField source="isPrimary" />
            <TextField source="attributeType.name" label="Type" />
            <TextField source="availabilityTypes" />
            <TextField source="name" />
          </Datagrid>
        </ArrayField>
      </Tab>

      <Tab label="Media Image Variants">
        <ImageField source="file.urls.small" label="Image Small" emptyText="Missing" />
        <ImageField source="file.urls.medium" label="Image Medium" emptyText="Missing" />
        <ImageField source="file.urls.large" label="Image Large" emptyText="Missing" />

        <ImageField source="thumbnail.urls.small" label="Thumbnail Small" emptyText="Missing" />
        <ImageField source="thumbnail.urls.medium" label="Thumbnail Medium" emptyText="Missing" />
        <ImageField source="thumbnail.urls.large" label="Thumbnail Large" emptyText="Missing" />
      </Tab>
    </TabbedShowLayout>
  </ValidityShow>
);

export const MediaList = (props) => (
  <List
    {...props}
    title={<Breadcrumbs {...props} />}
    perPage={25}
    exporter={false}
    filters={<MediaFilter />}
    filter={{ show_invalid: true }}
    sort={{ field: 'createdAt', order: 'DESC' }}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="show">
      <TextField source="id" sortable={false} />
      <DateField source="createdAt" showTime sortBy="createdAt" />
      <TextField source="mediaType.name" label="Type" sortable={false} />
      <MediaThumbnailField label="Image" />
      <TextField source="status.name" label="Status Name" sortable={false} />
      <TextField source="title" label="Title" sortable={false} />
      <TextField source="dataSource.name" label="Source" sortable={false} />
      <TextField source="createdBy.name" label="By" sortable={false} />
    </Datagrid>
  </List>
);

export const MediaCreate = (props) => <Create title="Create Media" actions={<CreateActions />} {...props}></Create>;

export const MediaEdit = (props) => <Edit title="Edit Media" {...props}></Edit>;

export default MediaList;
