import React from 'react';
import { List, Filter, SelectInput, TextInput, Datagrid, TextField, Create, SimpleForm, SimpleShowLayout, Edit, DateField } from 'react-admin';
import { InputLabel } from '@material-ui/core';
import { Breadcrumbs } from '../components/Breadcrumbs';
import ValidityShow from '../components/ValidityShow';
import { VALIDATION_STATUSES } from '../constants/validations';
import EntityTranslation from '../components/translation/EntityTranslation';

const OrganizationTypesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="query" alwaysOn />
    <SelectInput label="Validation status" source="validation_status" choices={VALIDATION_STATUSES} alwaysOn />
  </Filter>
);

export const OrganizationTypesShow = (props) => (
  <ValidityShow {...props} title={<Breadcrumbs {...props} />}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="namePlural" label="Plural name" />
      <InputLabel>Translation</InputLabel>
      <EntityTranslation list={{ url: `organizations/types/${props?.id}/translations` }} update={{ url: `organizations/types/${props?.id}/translations` }} />
    </SimpleShowLayout>
  </ValidityShow>
);

export const OrganizationTypesList = (props) => (
  <List exporter={false} title={<Breadcrumbs {...props} />} filters={<OrganizationTypesFilter />} bulkActionButtons={false} {...props} perPage={25}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" sortable={false} />
      <TextField source="namePlural" label="Plural name" />
      <TextField source="validationStatus.name" label="Validation Status" sortable={false} />
      <DateField source="createdAt" showTime sortable={false} />
      <DateField source="updatedAt" showTime sortable={false} />
    </Datagrid>
  </List>
);

export const OrganizationTypesCreate = (props) => (
  <Create title="Create organization type" {...props}>
    <SimpleForm submitOnEnter={false}>
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);

export const OrganizationTypesEdit = (props) => (
  <Edit title="Edit organization type" {...props}>
    <SimpleForm submitOnEnter={false}>
      <TextInput source="name" />s
    </SimpleForm>
  </Edit>
);

export default OrganizationTypesList;
