import React, { useState } from 'react';
import { List, Filter, SelectInput, TextInput, Datagrid, TextField, Create, SimpleForm, SimpleShowLayout, Edit, FunctionField, ReferenceManyField, Pagination } from 'react-admin';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Breadcrumbs } from '../components/Breadcrumbs';
import ValidityShow from '../components/ValidityShow';
import { ProductionsGrid } from './ProductionsModel';
import { VALIDATION_STATUSES } from '../constants/validations';
import EntityTranslation from '../components/translation/EntityTranslation';

const useStyles = makeStyles({
  dialog: { width: '70vw', maxWidth: '70vw' },
});

const StagingTypeListFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="query" alwaysOn />
    <SelectInput label="Validation status" source="validation_status" choices={VALIDATION_STATUSES} alwaysOn />
  </Filter>
);

export const StagingTypesShow = (props) => (
  <ValidityShow {...props} title={<Breadcrumbs {...props} />}>
    <SimpleShowLayout>
      <TextField source="name" />
      <hr />
      <Typography variant="subtitle1">Work type translation</Typography>
      <EntityTranslation list={{ url: `works/stagingTypes/${props?.id}/translations` }} update={{ url: `works/stagingTypes/${props?.id}/translations` }} />
    </SimpleShowLayout>
  </ValidityShow>
);

export const StagingTypesList = (props) => {
  const classes = useStyles();
  const [selectedStagingType, setSelectedStagingType] = useState(null);
  const [isProductionModalOpen, setIsProductionModalOpen] = useState(false);

  return (
    <>
      <List exporter={false} title={<Breadcrumbs {...props} />} filterDefaultValues={{ validation_status: 'approved' }} filters={<StagingTypeListFilter />} bulkActionButtons={false} {...props} perPage={25}>
        <Datagrid rowClick="show">
          <TextField source="id" />
          <TextField source="name" sortable={false} />
          <TextField source="validationStatus.name" label="Validation Status" sortable={false} />
          <FunctionField
            label="Actions"
            render={(record) => (
              <Button
                style={{ textTransform: 'inherit' }}
                size="small"
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedStagingType(record);
                  setIsProductionModalOpen(true);
                }}
              >
                View productions
              </Button>
            )}
          />
        </Datagrid>
      </List>
      <Dialog
        PaperProps={{
          className: classes.dialog,
        }}
        open={isProductionModalOpen}
        onClose={() => setIsProductionModalOpen(false)}
      >
        <DialogTitle style={{ padding: '16px 24px 8px' }}>Productions associated with {selectedStagingType?.name}</DialogTitle>
        <DialogContent>
          <ReferenceManyField
            {...props}
            label="Productions"
            reference="productions"
            sort={{ field: 'updated_at', order: 'DESC' }}
            pagination={<Pagination />}
            perPage={10}
            filter={{ staging_type_id: selectedStagingType?.id, published: 'both' }}
          >
            <ProductionsGrid {...props} hideMask />
          </ReferenceManyField>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsProductionModalOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const StagingTypesCreate = (props) => (
  <Create title="Create staging type" {...props}>
    <SimpleForm submitOnEnter={false}>
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);

export const StagingTypesEdit = (props) => (
  <Edit title="Edit staging type" {...props}>
    <SimpleForm submitOnEnter={false}>
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);

export default StagingTypesList;
