import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, TextField as MUITextField, Button } from '@material-ui/core';
import apiClient from '../../../utils/api';


const useStyles = makeStyles({
  selectSection: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  spacingLeft: {
    marginLeft: 10,
  },
});

const FingerprintUsers = (props) => {
  const [toClearVisitor, setToClearVisitor] = useState('');
  // const [toGetVisitor, setToGetVisitor] = useState('');
  const classes = useStyles();

  const clearVisitor = async (endpoint) => {
    try {
      apiClient.get(`${endpoint}?key=${toClearVisitor}`);
    } catch (error) {
      // Handle error
      console.error('Error Clearing the Visitor:', error);
    }
  };

  const addVisitor = async (endpoint, type = 'soft_wall') => {
    try {
      apiClient.post(endpoint, {
        visitor_id: toClearVisitor,
        data: {
          visitorId: toClearVisitor,
          type,
          isActive: 'yes',
          createdAt: new Date().toISOString()
        },
      });
    } catch (error) {
      // Handle error
      console.error('Error Adding the Visitor:', error);
    }
  }

  const addToForcedLogin = async => {
    let endpoint = 'fingerprints/actions';
    try {
      apiClient.post(endpoint, {
        visitorId: toClearVisitor,
        action: 'store_force_login_data',
      });
    } catch (error) {
      // Handle error
      console.error('Error Adding Force Login Visitor:', error);
    }
  }

  const removeFromForcedLogin = async => {
    let endpoint = 'fingerprints/actions';
    try {
      apiClient.post(endpoint, {
        visitorId: toClearVisitor,
        action: 'remove_force_login_data',
      });
    } catch (error) {
      // Handle error
      console.error('Error Removing Forced login:', error);
    }
  }

  return (
    <>
      <FormControl className={classes.selectSection} size="small">
        <MUITextField
            className={classes.spacingLeft}
            value={toClearVisitor}
            onChange={(e) => setToClearVisitor(e?.target?.value)}
            type="text"
            label="visitor ID"
            variant="filled"
            size="small"
        />
        <br></br> 
      </FormControl>
      <FormControl className={classes.selectSection} size="small">
        <Button type="button" onClick={() => clearVisitor('fingerprints/cache_clear')} variant="contained" size="small" style={{ marginLeft: 10, textTransform: 'inherit' }}>
          Clear
        </Button>
        <Button type="button" onClick={() => addVisitor('fingerprints/cache_clear')} variant="contained" size="small" style={{ marginLeft: 10, textTransform: 'inherit' }}>
          Add To Soft Wall
        </Button>
        <Button type="button" onClick={() => addVisitor('fingerprints/cache_clear', 'hard_wall')} variant="contained" size="small" style={{ marginLeft: 10, textTransform: 'inherit' }}>
          Add To Hard Wall
        </Button>
        <Button type="button" onClick={() => addToForcedLogin()} variant="contained" size="small" style={{ marginLeft: 10, textTransform: 'inherit' }}>
          Exempt Forced Login
        </Button>
        <Button type="button" onClick={() => removeFromForcedLogin()} variant="contained" size="small" style={{ marginLeft: 10, textTransform: 'inherit' }}>
          Add to Forced Login
        </Button>
      </FormControl>
      <hr></hr>
    </>
  );
};

export default FingerprintUsers;
