import React, { useState, useEffect } from 'react';
import * as dayjs from 'dayjs';
import classNames from 'classnames';
import { useNotify, Loading } from 'react-admin';
import { get, map, orderBy, uniq, flatMap, values, keys, capitalize, replace, reduce, remove, includes } from 'lodash';
import { useHistory } from 'react-router-dom';
import { makeStyles, FormControl, TextField, MenuItem } from '@material-ui/core';
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons';
import ComposeChart from '../charts/ComposeChart';
import apiClient from '../../utils/api';

const styles = () => ({
  dashboardContainer: {
    width: '100%',
  },
  loaderSection: {
    width: '100%',
    textAlign: 'center',
    margin: '20px 0',
  },
  filterSection: {
    display: 'flex',
    flexDirection: 'row',
  },
  spacingLeft: { marginLeft: 10, minWidth: 140 },
  charts: {
    display: 'flex',
    flexWrap: 'wrap',
    '&>div': {
      width: 500,
      minWidth: 300,
      minHeight: 250,
      maxHeight: 350,
      margin: '10px',
      backgroundColor: '#fff',
      borderRadius: 4,
      boxShadow: '0px 1px 22px -12px #607d8b',
      padding: '0 20px',
      resize: 'both',
      overflow: 'hidden',
    },
  },
  chartsFullWidth: {
    '&>div': {
      width: '100%',
    },
  },
  chartHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& h4, & p': {
      margin: 0,
    },
  },
  arrowPositive: {
    fontSize: 48,
    fill: '#77CC77',
  },
  totalSection: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 'bold',
    color: 'rgb(29, 155, 240)',
  },
  caption: {
    fontSize: 14,
    color: '#333',
    fontWeight: 'normal',
    marginLeft: -6,
    marginTop: -12,
  },
});

const DATA_COLORS = {
  Approved: '#4CAF50',
  Rejected: '#FF4560',
  'Approved hidden': '#F9CE1D',
  Freemium: '#F9CE1D',
  Premium: '#4CAF50',
  Churned: '#FF4560',
};

const DURATIONS = [
  { label: 'Last 7 days', value: 'last_7_days', fromDate: dayjs().add(-6, 'day').format('YYYY-MM-DD'), toDate: dayjs().format('YYYY-MM-DD') },
  { label: 'Last 30 days', value: 'last_30_days', fromDate: dayjs().add(-30, 'day').format('YYYY-MM-DD'), toDate: dayjs().format('YYYY-MM-DD') },
  { label: 'Last 3 months', value: 'last_3_months', fromDate: dayjs().add(-90, 'day').format('YYYY-MM-DD'), toDate: dayjs().format('YYYY-MM-DD') },
  { label: 'Last 6 months', value: 'last_6_months', fromDate: dayjs().add(-180, 'day').format('YYYY-MM-DD'), toDate: dayjs().format('YYYY-MM-DD') },
  { label: 'Last 12 months', value: 'last_12_months', fromDate: dayjs().add(-365, 'day').format('YYYY-MM-DD'), toDate: dayjs().format('YYYY-MM-DD') },
];

const formatKey = (key) => {
  let formattedKey = key.replace('_metrics', '');
  formattedKey = formattedKey.replace(/_/g, ' ');

  return formattedKey;
};

const getDataKeys = ({ uniqueKeys, chartData }) => {
  const data = chartData?.[0] || {};

  const result = reduce(
    uniqueKeys,
    (acc, key) => {
      const value = data[key];
      const childKeys = remove(keys(value), (childKey) => !includes(['pending', 'processing', 'total'], childKey));
      return {
        ...acc,
        [key]: {
          dataKey: key,
          totalLabel: ['artist_subscription_metrics', 'organization_type_metrics']?.includes(key) ? 'Total' : 'Pending',
          kind: ['artist_subscription_metrics', 'organization_type_metrics']?.includes(key) ? 'positive' : 'negative',
          name: capitalize(formatKey(key)),
          children: childKeys?.map((childKey) => ({ key: `${key}.${childKey}`, name: capitalize(replace(childKey, /_/g, ' ')) })),
        },
      };
    },
    {},
  );

  return result;
};

const DashboardMetrics = () => {
  const classes = makeStyles(styles)();
  const history = useHistory();
  const notify = useNotify();

  const searchParams = new URLSearchParams(history.location.search);
  const defaultDuration = DURATIONS.find((duration) => duration.value === searchParams?.get('duration'));
  const [duration, setDuration] = useState(defaultDuration || DURATIONS[0]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [dataKeys, setDataKeys] = useState([]);

  useEffect(() => {
    setLoading(true);
    apiClient
      .get('metrics/dashboard-metrics', { params: { from_date: duration?.fromDate, to_date: duration?.toDate } })
      .then((res) => {
        setLoading(false);
        const chartData = orderBy(
          map(res?.data, (value, key) => ({ name: key, ...value })),
          'name',
          'asc',
        );
        const uniqueKeys = uniq(flatMap(values(res?.data), keys));
        const updatedData = chartData?.map((item) => {
          const updatedItem = { ...item };
          return {
            ...updatedItem,
            artist_subscription_metrics: {
              ...updatedItem?.artist_subscription_metrics,
              churned: updatedItem?.artist_subscription_metrics?.churned * -1,
            },
          };
        });
        setData(updatedData);
        const keysWithChildren = getDataKeys({ uniqueKeys, chartData });
        setDataKeys(keysWithChildren);
      })
      .catch((err) => {
        setLoading(false);
        notify(err?.response?.data?.message, 'warning');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration]);

  useEffect(() => {
    searchParams.set('duration', duration?.value);
    const newSearch = searchParams.toString();
    history.push({ search: newSearch });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration]);

  if (loading) {
    return (
      <div className={classes.loaderSection}>
        <Loading />
      </div>
    );
  }

  return (
    <div className={classes.dashboardContainer}>
      <div className={classes.filterSection}>
        <FormControl className={classes.filterSection} size="small">
          <TextField className={classes.spacingLeft} value={duration} select onChange={(e) => setDuration(e?.target?.value)} label="Duration" variant="filled" size="small">
            {DURATIONS.map((option) => (
              <MenuItem key={option.value} value={option}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </div>
      <div className={classNames(classes.charts, { [classes.chartsFullWidth]: duration?.value !== 'last_7_days' })}>
        {map(dataKeys, (key) => {
          const total = data?.[data?.length - 1]?.[key?.dataKey]?.total;
          const childKeys = map(key?.children, 'key');
          const totalDisplayOnChart = reduce(
            childKeys,
            (acc, childKey) => {
              const count = reduce(data, (a, item) => a + get(item, childKey), 0);
              return acc + count;
            },
            0,
          );

          return (
            <div key={key?.dataKey}>
              <div className={classes.chartHeader}>
                <h4>{key?.name}</h4>
                {total > 0 ? (
                  <p className={classes.totalSection}>
                    {key?.totalLabel}: {total} {key?.kind === 'positive' ? <ArrowDropUp className={classes.arrowPositive} /> : <ArrowDropDown className={classes.arrowPositive} />}
                    <div className={classes.caption}>
                      {key?.kind === 'positive' ? '+' : '-'}
                      {totalDisplayOnChart}
                    </div>
                  </p>
                ) : null}
              </div>
              <ComposeChart
                data={data}
                dataKeys={key?.children}
                lines={[
                  {
                    name: 'Total',
                    key: `${key?.dataKey}.total`,
                    fill: '#ff7300',
                    stroke: '#ff7300',
                  },
                ]}
                dataColors={DATA_COLORS}
                margin={{ bottom: 10 }}
                isMultiAxis
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DashboardMetrics;
