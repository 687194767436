import React, { useState, useEffect } from 'react';
import * as dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, DialogContent, Dialog } from '@material-ui/core';
import { Loading, useNotify, ListContextProvider, Datagrid, FunctionField, Pagination, TextField } from 'react-admin';
import { capitalize, join, split, omit, uniq, map, filter, keyBy } from 'lodash';
import classNames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import ComposeChart from './charts/ComposeChart';

import apiClient from '../utils/api';
import EntityLink from './EntityLink';

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
const advanced = require('dayjs/plugin/advancedFormat');

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',

    '& h4': {
      marginTop: 10,
    },

    '&>div': {
      width: 500,
      minWidth: 300,
      minHeight: 250,
      maxHeight: 350,
      margin: 10,
      backgroundColor: '#fff',
      borderRadius: 4,
      boxShadow: '0px 1px 22px -12px #607d8b',
      padding: '0 20px',
      resize: 'both',
      overflow: 'hidden',
    },
  },
  chartsFullWidth: {
    '&>div': {
      width: '100%',
    },
  },
  chartHeader: {
    display: 'flex',
    alignItems: 'center',

    '& h4': {
      margin: 0,
    },
  },
  chartHeaderFilter: {
    marginLeft: 10,
    marginTop: 10,
  },
  button: {
    textTransform: 'initial',
  },
  dialog: {
    minWidth: 1200,
    height: 540,
    padding: 10,
    '& .MuiDialogContent-root': { padding: 0 },
    '& .MuiDialogContent-root:first-child': { padding: 0 },
  },
  closeIcon: {
    position: 'absolute',
    right: 10,
    top: 10,
    cursor: 'pointer',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
  },
  launchIcon: {
    fontSize: 14,
    marginLeft: 5,
  },
  title: {
    fontWeight: 600,
    padding: 5,
  },
  lowContribution: {
    backgroundColor: '#f44336',
    color: 'white',
    fontWeight: 800,
    lineHeight: 4,
  },
  mediumContribution: {
    backgroundColor: '#ffeb3b',
    fontWeight: 800,
    lineHeight: 4,
  },
  highContribution: {
    backgroundColor: '#4caf50',
    fontWeight: 800,
    lineHeight: 4,
  },
}));

const ScrapeRequestDataModal = ({ open, onClose, chartData = {}, ...props }) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const title = 'Productions';
  // const [activeChartData, setActiveChartData] = useState({});

  const handleClose = () => {
    setPage(1);
    setPerPage(10);
    setData([]);
    setIsLoading(false);
    onClose();
  };

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('clicked date:', chartData.activeLabel);
    // dayjs(chartData?.activeLabel).add(1, 'day').format('YYYY-MM-DD')
    if (open) {
      setIsLoading(true);
      apiClient
        .get('metrics/scrape-request-datapoints', {
          params: { page, limit: perPage, from_date: chartData?.activeLabel, to_date: chartData?.activeLabel, aggregate_by: 'entity_id' },
        })
        .then((res) => {
          setData(res);
          setIsLoading(false);
        });
    }
  }, [chartData, page, perPage, open]);

  const listContext = {
    data: keyBy(data?.data, 'entity_id'),
    ids: data?.data?.map(({ entity_id }) => entity_id),
    total: data?.data?.length,
    currentSort: {},
    basePath: props?.basePath,
    resource: props?.resource,
    selectedIds: [],
    hasList: true,
    page: 1,
    setPage,
    perPage: data?.data?.length,
    setPerPage,
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        className: classes.dialog,
        sx: { padding: 0 },
      }}
    >
      <DialogContent>
        <div className={classes.title}>
          {title} {chartData?.activeLabel}
        </div>
        <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        <div className={classes.container}>
          {isLoading ? (
            <Loading />
          ) : (
            <ListContextProvider value={listContext}>
              <Datagrid>
                <TextField source="entity_id" label="Production Id" />
                <FunctionField label="Production" render={(record) => <EntityLink entityType="productions" entity={{ id: record.entity_id }} />} />
                <TextField source="llm_added_datapoints" label="LLM Added Datapoints" />
                <TextField source="llm_accurate_datapoints" label="LLM Accurate Datapoints" />
                <TextField source="llm_added_manual_modified_datapoints" label="LLM Modified Datapoints" />
                <TextField source="user_added_datapoints" label="User Added Datapoints" />
                <TextField source="llm_contribution" label="LLM Contribution" />
                <FunctionField
                  label="Contribution Level"
                  render={(record) => {
                    const value = record?.llm_contribution;
                    if (value < 30) {
                      return <span className={classes.lowContribution}>Low</span>;
                    }
                    if (value && value >= 30 && value < 50) {
                      return <span className={classes.mediumContribution}>Medium</span>;
                    }
                    if (value && value >= 50) {
                      return <span className={classes.highContribution}>High</span>;
                    }
                    return null;
                  }}
                ></FunctionField>
              </Datagrid>
              <Pagination />
            </ListContextProvider>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

const ScrapeRequests = ({ data, ...props }) => {
  const [open, setOpen] = useState(false);
  const [activeChartData, setActiveChartData] = useState({});

  const handleBarClick = (chartData) => {
    setOpen(true);
    setActiveChartData(chartData);
  };

  const dataKeys = Object.keys(omit(data?.[0], ['creation_date', 'llm_contribution']) || {});
  const lines = dataKeys?.map((key) => ({ key, name: capitalize(key) }));

  if (!data?.length) return null;

  return (
    <>
      <div>
        <div>
          <h4>LLM contribution</h4>
          <ComposeChart
            data={data}
            onClick={(cData) => handleBarClick(cData)}
            xAxisDataKey="creation_date"
            lines={[
              {
                key: 'llm_contribution',
                name: 'LLM contribution',
              },
            ]}
            margin={{ bottom: 10 }}
          />
        </div>
      </div>
      <div>
        <h4>Scrape requests</h4>
        <ComposeChart data={data} onClick={(cData) => handleBarClick(cData)} xAxisDataKey="creation_date" lines={lines} margin={{ bottom: 10 }} />
        <ScrapeRequestDataModal {...props} open={open} onClose={() => setOpen(false)} chartData={activeChartData} />
      </div>
    </>
  );
};

const ScrapeEntities = ({ data }) => {
  const classes = useStyles();
  const dataKeys = Object.keys(omit(data?.[0], ['creation_date', 'llm_contribution']) || {});
  const entities = uniq(map(data, 'entity_type'));

  const [selectedEntity, setSelectedEntity] = useState('productionContributions');
  const lines = dataKeys?.map((key) => ({ key, name: capitalize(join(split(key, '_'), ' ')) }));
  const chartData = filter(data, (entity) => entity?.entity_type === selectedEntity);

  if (!data?.length) return null;

  return (
    <>
      <div>
        <div className={classes.chartHeader}>
          <h4>Scrape requests entities</h4>
          <TextField
            defaultValue={entities?.[0]}
            className={classes.chartHeaderFilter}
            value={selectedEntity}
            select
            onChange={(e) => setSelectedEntity(e?.target?.value)}
            label="Entity type"
            variant="filled"
            size="small"
          >
            {entities?.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <ComposeChart data={chartData} xAxisDataKey="creation_date" lines={lines} margin={{ bottom: 10 }} />
      </div>
      <div>
        <h4>{selectedEntity} LLM contribution</h4>
        <ComposeChart
          data={chartData}
          xAxisDataKey="creation_date"
          lines={[
            {
              key: 'llm_contribution',
              name: 'LLM contribution',
            },
          ]}
          margin={{ bottom: 10 }}
        />
      </div>
    </>
  );
};

const LLMCounts = ({ data }) => {
  const dataKeys = Object.keys(omit(data?.[0], 'creation_date') || {});
  const lines = dataKeys?.map((key) => ({ key, name: capitalize(join(split(key, '_'), ' ')) }));

  if (!data?.length) return null;

  return (
    <div>
      <h4>LLM counts</h4>
      <ComposeChart data={data} xAxisDataKey="creation_date" lines={lines} margin={{ bottom: 10 }} />
    </div>
  );
};

const ScrapeMetricsChart = ({ isChart, selectedDateRange, ...props }) => {
  const classes = useStyles();
  const notify = useNotify();
  const [isLLmCountsLoading, setIsLLmCountsLoading] = useState(false);
  const [entitiesLoading, setEntitiesLoading] = useState(false);
  const [scrapeRequestLoading, setScrapeRequestLoading] = useState([]);
  const [scrapeRequests, setScrapeRequests] = useState([]);
  const [scrapeRequestEntities, setScrapeRequestEntities] = useState([]);
  const [llmCounts, setLLMCounts] = useState([]);

  const daysDiff = dayjs(selectedDateRange?.to).diff(dayjs(selectedDateRange?.from), 'day');

  const getChartData = () => {
    setIsLLmCountsLoading(true);
    setEntitiesLoading(true);
    setScrapeRequestLoading(true);
    apiClient
      .get('metrics/scrape-request-datapoints', { params: { from_date: selectedDateRange.from, to_date: selectedDateRange.to } })
      .then((res) => {
        setScrapeRequests(res?.data);
        setScrapeRequestLoading(false);
      })
      .catch((err) => {
        notify(err?.message, 'warning');
        setScrapeRequests([]);
        setScrapeRequestLoading(false);
      });

    apiClient
      .get('metrics/scrape-request-datapoints', { params: { from_date: selectedDateRange.from, to_date: selectedDateRange.to, aggregate_by: 'entities' } })
      .then((res) => {
        setEntitiesLoading(false);
        setScrapeRequestEntities(res?.data);
      })
      .catch((err) => {
        notify(err?.message, 'warning');
        setScrapeRequestEntities([]);
        setEntitiesLoading(false);
      });

    apiClient
      .get('metrics/scrape-request-counts', { params: { from_date: selectedDateRange.from, to_date: selectedDateRange.to } })
      .then((res) => {
        setLLMCounts(res?.data);
        setIsLLmCountsLoading(false);
      })
      .catch((err) => {
        notify(err?.message, 'warning');
        setLLMCounts([]);
        setIsLLmCountsLoading(false);
      });
  };

  useEffect(() => {
    if (isChart) {
      getChartData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChart, selectedDateRange]);

  if (isLLmCountsLoading || scrapeRequestLoading || entitiesLoading) return <Loading />;

  return (
    <div className={classNames(classes.container, { [classes.chartsFullWidth]: daysDiff > 7 })}>
      <ScrapeRequests {...props} data={scrapeRequests} />
      <ScrapeEntities {...props} data={scrapeRequestEntities} />
      <LLMCounts data={llmCounts} />
    </div>
  );
};

export default ScrapeMetricsChart;
