import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as dayjs from 'dayjs';
import keyBy from 'lodash/keyBy';
import { ListContextProvider, Datagrid, Loading, TextField, Pagination } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, TextField as MUITextField, MenuItem } from '@material-ui/core';
import axios from 'axios';

// const BLITZER_API_URL = "https://dev-blitzer-event-api.truelinked.net/"
// const BLITZER_SECRET = 'secret'

const useStyles = makeStyles({
  selectSection: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  spacingLeft: {
    marginLeft: 10,
  },
  select: {
    marginLeft: 10,
    width: 140,
  },
});

const EVENT_TYPES = [
  { id: '', name: 'All' },
  { id: 'pageView', name: 'Page View' },
  { id: 'impression', name: 'Impression' },
  { id: 'click', name: 'Click' },
  { id: 'search', name: 'Search' },
];

const ENTITY_TYPES = [
    { id: '', name: 'All' },
    { id: 'artist', name: 'Artist' },
    { id: 'organization', name: 'Organization' },
    { id: 'manager', name: 'Manager' },
    { id: 'competition', name: 'Competition' },
  ];

const PAGE_CATEGORIES = [
    { id: '', name: 'All' },
    { id: 'listing', name: 'Listing' },
    { id: 'landing', name: 'Landing' },
    { id: 'stub', name: 'Stub' },
]

const PAGE_TYPES = [
    { id: '', name: 'All' },
    { id: 'home', name: 'Home' },
    { id: 'artist', name: 'Artist' },
    { id: 'organization', name: 'Organization' },
    { id: 'manager', name: 'Manager' },
    { id: 'competition', name: 'Competition' },
];

const InsightEvents = (props) => {
  const [data, setData] = useState({});
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [eventType, setEventType] = useState('');
  const [entityType, setEntityType] = useState('');
  const [pageCategory, setPageCategory] = useState('');
  const [pageType, setPageType] = useState('');
  const searchParams = new URLSearchParams(history.location.search);
  const defaultDateRange = { fromDate: searchParams?.get('fromDate'), toDate: searchParams?.get('toDate') };
  const [selectedDateRange, setSelectedDateRange] = useState({
    fromDate: defaultDateRange?.fromDate || dayjs().add(-1, 'day').format('YYYY-MM-DD'),
    toDate: defaultDateRange?.toDate || dayjs().add(1, 'day').format('YYYY-MM-DD'),
  });
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_BLITZER_API_URL}events`, {
      params: {
        fromDate: selectedDateRange.fromDate,
        toDate: selectedDateRange.toDate,
        limit: perPage,
        page,
        eventType,
        entityType,
        pageCategory,
        pageType
      },
      headers: {
        'Content-Type': 'application/json',
        'x-access-key': `${process.env.REACT_APP_BLITZER_API_SECRET}`
      }
    }).then((res) => {
      setData(res?.data);
      setLoading(false);
    });
  }, [selectedDateRange, page, perPage, eventType, entityType, pageCategory, pageType]);

  useEffect(() => {
    searchParams.set('fromDate', selectedDateRange?.fromDate);
    searchParams.set('toDate', selectedDateRange?.toDate);
    searchParams.set('eventType', eventType);
    searchParams.set('entityType', entityType);
    searchParams.set('pageCategory', pageCategory);
    searchParams.set('pageType', pageType);
    const newSearch = searchParams.toString();
    history.push({ search: newSearch });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange, eventType, entityType, pageCategory, pageType]);

  const listContext = {
    // data: keyBy(data, (item) => `${item?.visitorId}-${item?.entityType}-${item?.entityId}-${item?.pageCategory}-${item?.pageType}`),
    data: keyBy(data?.events, (item) => `${item?._id}`),
    // ids: data?.map((item) => `${item?.visitorId}-${item?.entityType}-${item?.entityId}-${item?.pageCategory}-${item?.pageType}`),
    ids: data?.events?.map((item) => `${item?._id}`),
    total: data?.total || 0,
    basePath: props.basePath,
    resource: props.resource,
    selectedIds: [],
    currentSort: {},
    hasList: true,
    page,
    setPage,
    perPage,
    setPerPage,
  };

  console.log(listContext)

  return (
    <>
      <FormControl className={classes.selectSection} size="small">
        <MUITextField
          disabled={loading}
          value={selectedDateRange.fromDate}
          onChange={(e) => setSelectedDateRange({ ...selectedDateRange, fromDate: e?.target?.value })}
          type="date"
          label="From"
          variant="filled"
          size="small"
        />
        <MUITextField
          className={classes.spacingLeft}
          value={selectedDateRange.toDate}
          onChange={(e) => setSelectedDateRange({ ...selectedDateRange, toDate: e?.target?.value })}
          type="date"
          label="To"
          variant="filled"
          size="small"
          disabled={loading}
        />
        <MUITextField 
            select disabled={loading} 
            className={classes.select} 
            value={eventType} 
            onChange={(e) => setEventType(e?.target?.value)} 
            label="Event Type" 
            variant="filled" 
            size="small"
        >
        {EVENT_TYPES.map((item) => (
          <MenuItem value={item.id}>{item.name}</MenuItem>
        ))}
       </MUITextField>
        <MUITextField 
            select disabled={loading} 
            className={classes.select} 
            value={entityType} 
            onChange={(e) => setEntityType(e?.target?.value)} 
            label="Entity Type" 
            variant="filled" 
            size="small"
        >
          {ENTITY_TYPES.map((item) => (
            <MenuItem value={item.id}>{item.name}</MenuItem>
          ))}
        </MUITextField>
        <MUITextField 
            select disabled={loading} 
            className={classes.select} 
            value={pageCategory} 
            onChange={(e) => setPageCategory(e?.target?.value)} 
            label="Page Category" 
            variant="filled" 
            size="small"
        >
          {PAGE_CATEGORIES.map((item) => (
            <MenuItem value={item.id}>{item.name}</MenuItem>
          ))}
        </MUITextField>
        <MUITextField 
            select disabled={loading} 
            className={classes.select} 
            value={pageType} 
            onChange={(e) => setPageType(e?.target?.value)} 
            label="Page Type" 
            variant="filled" 
            size="small"
        >
          {PAGE_TYPES.map((item) => (
            <MenuItem value={item.id}>{item.name}</MenuItem>
          ))}
        </MUITextField>
      </FormControl>
      {!loading ? (
        <ListContextProvider value={listContext}>
          <Datagrid>
            <TextField source="visitorId" label="Visitor ID" sortable={false} />
            <TextField source="eventType" label="Event type" sortable={false} />
            <TextField source="pageCategory" label="Page Category" sortable={false} />
            <TextField source="pageType" label="Page Type" sortable={false} />
            <TextField source="pageTab" label="Page Tab" sortable={false} />
            <TextField source="entityType" label="Entity Type" sortable={false} />
            <TextField source="entityId" label="Entity Id" sortable={false} />
            <TextField source="pageEntityType" label="Page Entity Type" sortable={false} />
            <TextField source="pageEntityId" label="Page Entity Id" sortable={false} />
            <TextField source="userActiveProfileId" label="Active Profile" sortable={false} />
            <TextField source="weightage" label="Weightage" sortable={false} />
            <TextField source="timestamp" label="Timestamp" sortable={true} />
          </Datagrid>
          <Pagination {...props} />
        </ListContextProvider>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default InsightEvents;
