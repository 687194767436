import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import InsightEvents from '../components/insights/InsightEvents';

// import useUserPermissions from '../utils/useUserPermissions';

const TabComponent = (props) => {
  const history = useHistory();
//   const { permissions } = useUserPermissions();
  const searchParams = new URLSearchParams(history.location.search);
  const defaultTab = parseInt(searchParams.get('tab'), 0);
  const [tabValue, setTabValue] = useState(defaultTab || 0);

//   const hasFingerPrintPermission = permissions?.length && permissions.includes('applications.frontbase.fingerprint');

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    searchParams.set('tab', tabValue);
    const newSearch = searchParams.toString();
    history.push({ search: newSearch });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue]);

//   if (!hasFingerPrintPermission) {
//     return <ErrorModel />;
//   }

  return (
    <div>
      <Tabs value={tabValue} variant="scrollable" indicatorColor="primary" onChange={handleTabChange} component={Paper} style={{ marginBottom: 10 }}>
        <Tab label="Insight Events" />
        {/* <Tab label="FingerPrint Activity" />
        <Tab label="FingerPrint Users" />
        <Tab label="FingerPrint Activity logs" />
        <Tab label="FingerPrint Top N Users" /> */}
      </Tabs>
      {tabValue === 0 && <InsightEvents {...props} />}
      {/* {tabValue === 1 && <FingerprintActivity />}
      {tabValue === 2 && <FingerprintUsers {...props} />}
      {tabValue === 3 && <FingerprintActivityLogs {...props} />}
      {tabValue === 4 && <FingerprintTopNUsers {...props} />} */}
    </div>
  );
};

export default TabComponent;
