export const MAIL_TYPES = [
  { id: 'profile', name: 'Profile', dataKey: 'artist' },
  { id: 'company', name: 'Company', dataKey: 'company' },
  { id: 'agency', name: 'Agency', dataKey: 'agency' },
];

export const DATA_POINT_METRICS_GROUP = [
  {
    id: 'production',
    name: 'Production',
  },
  {
    id: 'profile',
    name: 'Profile',
  },
  {
    id: 'admin',
    name: 'Admin',
  },
];

export const TASK_QUEUE_METRICS_TYPE = [
  { id: 'user', name: 'User' },
  { id: 'common', name: 'Common' },
];
