import React, { useCallback, useState } from 'react';
import {
  Edit,
  List,
  Filter,
  TextInput,
  Datagrid,
  TextField,
  SimpleShowLayout,
  SimpleForm,
  Toolbar,
  SaveButton,
  Create,
  SelectInput,
  FunctionField,
  ReferenceField,
  TabbedShowLayout,
  Tab,
  TabbedShowLayoutTabs,
  useNotify,
  TopToolbar,
  EditButton,
  ReferenceInput,
  AutocompleteInput,
  ArrayField,
  SingleFieldList,
  TabbedForm,
  FormTab,
  ReferenceManyField,
  Pagination,
  useUpdate,
  useRefresh,
  CREATE,
  DateField,
} from 'react-admin';
import { Alert } from '@material-ui/lab';
import { useRouteMatch } from 'react-router-dom';
import { Chip } from '@material-ui/core';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { VALIDATION_STATUSES } from '../constants/validations';
import ValidityShow from '../components/ValidityShow';
import CustomShowLayout from '../components/CustomShowLayout';
import EntityLink from '../components/EntityLink';
import dataProvider from '../components/dataProviderComponent';
import { ProductionsGrid } from './ProductionsModel';
import { getComposerNamesFromCreators } from '../utils/composer';
import { renderProfessionList } from './ProfessionsModel';
import EntityTranslation from '../components/translation/EntityTranslation';

const TABS = {
  INFO: 'info',
  MUSICAL_WORK: 'musicalWork',
  PRODUCTIONS: 'productions',
  PROFILES: 'profiles',
  TRANSLATIONS: 'translation',
};

const InstrumentRolesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="query" alwaysOn />
    <TextInput label="InstrumentRoles ID" source="id" alwaysOn />
    <ReferenceInput
      label="Instrument"
      source="instrument_id"
      reference="instruments"
      filter={{}}
      filterToQuery={(searchText) => ({ query: searchText })}
      sort={{ field: 'name', order: 'DESC' }}
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <SelectInput label="Validation status" source="validation_status" choices={VALIDATION_STATUSES} alwaysOn />
  </Filter>
);

export const InstrumentRolesShow = (props) => (
  <ValidityShow {...props} title={<Breadcrumbs {...props} />} actions={<InstrumentRolesShowActions />}>
    <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" {...props} />}>
      <Tab label="Instrument Role Info">
        <CustomShowLayout {...props} isStats>
          {{
            column1: (
              <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="name" />

                <ReferenceField link="show" label="Instrument" reference="instruments" source="instrument.id" sortable={false}>
                  <FunctionField emptyText="Unknown" render={(record) => <EntityLink entity={record} entityType="instruments" />} />
                </ReferenceField>

                <ReferenceField link="show" label="User" reference="users" source="user_id" sortable={false}>
                  <FunctionField emptyText="Unknown" render={(record) => <EntityLink entity={record} entityType="users" />} />
                </ReferenceField>
              </SimpleShowLayout>
            ),
            column2: (
              <SimpleShowLayout>
                <ReferenceManyField label="Siblings" reference="instruments/roles" link="show" target="instrument_id" source="instrument.id" perPage={5}>
                  <Datagrid rowClick={(id, path, record) => `/instruments/roles/${record?.id}/show`}>
                    <FunctionField emptyText="Unknown" render={(record) => <EntityLink entity={record} entityType="instruments/roles" />} />
                  </Datagrid>
                </ReferenceManyField>
              </SimpleShowLayout>
            ),
          }}
        </CustomShowLayout>
      </Tab>
      <Tab label="Instrument Role Translations">
        <EntityTranslation list={{ url: `instruments/roles/${props?.id}/translations` }} update={{ url: `instruments/roles/${props?.id}/translations` }} />
      </Tab>
      <Tab label="Siblings">
        <ReferenceManyField
          label="List of instrument roles associated sibling"
          reference="instruments/roles"
          link="show"
          target="instrument_id"
          source="instrument.id"
          perPage={25}
          pagination={<Pagination />}
        >
          <Datagrid rowClick={(id, path, record) => `/instruments/${record?.id}/show`}>
            <FunctionField emptyText="Unknown" render={(record) => <EntityLink entity={record} entityType="instruments" />} />
            <ArrayField source="name_variants">
              <SingleFieldList linkType={false}>
                <FunctionField render={(record) => <Chip label={`${record}`} />} />
              </SingleFieldList>
            </ArrayField>
            <TextField source="validationStatus.name" sortable={false} />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Musical Works">
        <ReferenceManyField
          label="List of musical works where instrument role is used"
          reference="works"
          filter={{ as_edit: true, instrument_role_id: props?.id }}
          {...props}
          perPage={25}
          pagination={<Pagination />}
        >
          <Datagrid rowClick="show" {...props}>
            <TextField source="id" />
            <FunctionField
              render={(record) => {
                const composerNames = getComposerNamesFromCreators(record?.creators);
                return composerNames;
              }}
              label="Composers"
            />
            <TextField source="name" />
            <TextField source="original_name" />
            <TextField source="workType.name" label="Work type" />
            <TextField source="stats.productionCount" sortBy="production_count" label="Production count" />
            <TextField label="Validation status" source="validationStatus.name" />
            <DateField source="createdAt" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Productions">
        <ReferenceManyField
          label="List of productions where instrument role is used"
          reference="productions"
          filter={{ as_edit: true, instrument_role_id: props?.id, published: 'both' }}
          sort={{ field: 'year', order: 'DESC' }}
          perPage={25}
          pagination={<Pagination />}
        >
          <ProductionsGrid {...props} />
        </ReferenceManyField>
      </Tab>
      <Tab label="Profiles">
        <ReferenceManyField
          label="List of profiles where instrument role is used"
          reference="profiles"
          filter={{ as_edit: true, db: true, instrument_role_id: props?.id }}
          perPage={25}
          pagination={<Pagination />}
        >
          <Datagrid rowClick={(id, path, record) => `/profiles/${record?.id}/show`}>
            <TextField source="id" />
            <TextField source="name" sortable={false} />
            <FunctionField label="Professions" render={(record) => renderProfessionList(record?.professions)} />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </ValidityShow>
);

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const InstrumentRolesCreate = (props) => (
  <Create title={<Breadcrumbs {...props} />} {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <TextInput source="name" />
      <ReferenceInput label="Instrument" source="instrument.id" reference="instruments" filterToQuery={(searchText) => ({ query: searchText })} alwaysOn>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

const useSaveHandlers = () => {
  const notify = useNotify();
  const refresh = useRefresh();

  const onFailure = (error) => {
    notify(`${error.message}`, 'warning');
    refresh();
  };

  const onSuccess = () => {
    notify('ra.notification.updated', 'info', {
      smart_count: 1,
    });
    refresh();
  };

  return { onFailure, onSuccess };
};

const createInstrumentsRolesTranslation = (data) =>
  dataProvider(CREATE, `instruments/roles/${data?.instrumentRole?.id}/translations`, {
    data,
  });

const SaveTranslations = ({ tab, ...props }) => {
  const { initial } = props;
  const instrumentRoleId = initial.id;
  const [update] = useUpdate('instruments/roles', instrumentRoleId);
  const { onSuccess, onFailure } = useSaveHandlers();

  const handleSave = useCallback(
    (values) => {
      const { __versions: newVersions, ...valuesData } = values;

      if (tab === TABS.TRANSLATIONS) {
        const filteredVersions = Object.keys(newVersions)?.filter((lang) => newVersions[lang]?.name);
        Promise.all(
          filteredVersions?.map((lang) => {
            const record = newVersions[lang];
            return createInstrumentsRolesTranslation({
              name: record?.name,
              instrumentRole: {
                id: instrumentRoleId,
              },
              language: { id: parseInt(lang, 10) },
              name_variants: record?.name_variants,
            });
          }),
        ).then(onSuccess, onFailure);
      } else {
        update(
          {
            payload: {
              data: valuesData,
            },
          },
          {
            onSuccess,
            onFailure,
          },
        );
      }
    },
    [tab, onSuccess, onFailure, update, instrumentRoleId],
  );
  // set onSave props instead of handleSubmitWithRedirect
  return <SaveButton {...props} onSave={handleSave} />;
};

const CustomEditToolbar = ({ tab, ...props }) => {
  const [record] = useState(props.record);
  return (
    <Toolbar {...props}>
      <SaveTranslations initial={record} tab={tab} />
    </Toolbar>
  );
};

export const InstrumentRolesEdit = (props) => {
  let initialTab = TABS.INFO;
  const match = useRouteMatch('/instruments/roles/:id/:tab');
  if (match && match.params) {
    initialTab = Object.values(TABS)[match.params.tab];
  }
  const [tab, setTab] = useState(initialTab);

  return (
    <Edit {...props} title={<Breadcrumbs {...props} />}>
      <TabbedForm toolbar={<CustomEditToolbar {...props} tab={tab} />}>
        <FormTab label="Instrument Role Info" onClick={() => setTab(TABS.INFO)}>
          <Alert variant="filled" severity="info" style={{ backgroundColor: 'grey', width: '90%' }}>
            To see updates made to this entity on Operabase associated productions/profiles will require overnight indexing.
          </Alert>
          <TextInput source="name" />

          <ReferenceInput
            label="Instrument"
            source="instrument.id"
            target="id"
            reference="instruments"
            filterToQuery={(searchText) => ({ query: searchText })}
            filter={{}}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <AutocompleteInput optionText={(record) => `(${record?.id}) ${record?.name}`} />
          </ReferenceInput>
        </FormTab>
        <FormTab label="Musical Works" onClick={() => setTab(TABS.MUSICAL_WORK)}>
          <ReferenceManyField
            label="List of musical works where instrument role is used"
            reference="works"
            filter={{ as_edit: true, instrument_role_id: props?.id }}
            {...props}
            perPage={25}
            pagination={<Pagination />}
          >
            <Datagrid rowClick="show" {...props}>
              <TextField source="id" />
              <FunctionField
                render={(record) => {
                  const composerNames = getComposerNamesFromCreators(record?.creators);
                  return composerNames;
                }}
                label="Composers"
              />
              <TextField source="name" />
              <TextField source="original_name" />
              <TextField source="workType.name" label="Work type" />
              <TextField source="stats.productionCount" sortBy="production_count" label="Production count" />
              <TextField label="Validation status" source="validationStatus.name" />
              <DateField source="createdAt" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="Productions" onClick={() => setTab(TABS.PRODUCTIONS)}>
          <ReferenceManyField
            label="List of productions where instrument role is used"
            reference="productions"
            filter={{ as_edit: true, instrument_role_id: props?.id, published: 'both' }}
            sort={{ field: 'year', order: 'DESC' }}
            perPage={25}
            pagination={<Pagination />}
          >
            <ProductionsGrid {...props} />
          </ReferenceManyField>
        </FormTab>
        <FormTab label="Instrument Role Translations" onClick={() => setTab(TABS.TRANSLATIONS)}>
          <Alert variant="filled" severity="info" style={{ backgroundColor: 'grey', width: '90%' }}>
            To see updates made to this entity on Operabase associated productions/profiles will require overnight indexing.
          </Alert>
          <EntityTranslation list={{ url: `instruments/roles/${props?.id}/translations` }} update={{ url: `instruments/roles/${props?.id}/translations` }} />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const InstrumentRolesShowActions = (props) => {
  const { basePath, data } = props;

  return (
    <TopToolbar style={{ alignItems: 'center' }}>
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );
};

export const InstrumentRolesList = (props) => (
  <List exporter={false} {...props} title={<Breadcrumbs {...props} />} filters={<InstrumentRolesFilter />} bulkActionButtons={false} perPage={25}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" sortable={false} />
      <ArrayField label="Variants" source="name_variants">
        <SingleFieldList linkType={false}>
          <FunctionField render={(record) => <Chip label={`${record}`} />} />
        </SingleFieldList>
      </ArrayField>
      <ReferenceField link="show" label="Instrument" reference="instruments" source="instrument.id" sortable={false}>
        <FunctionField emptyText="Unknown" render={(record) => <EntityLink entity={record} entityType="instruments" />} />
      </ReferenceField>
      <TextField source="validationStatus.name" sortable={false} />
    </Datagrid>
  </List>
);

export default InstrumentRolesList;
