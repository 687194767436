import React, { useState, useEffect } from 'react';
import { map } from 'lodash';
import { Table, TableCell, TableBody, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import apiClient from '../utils/api';

const useStyles = makeStyles(() => ({
  notification: {
    textTransform: 'capitalize',
  },
  notificationTable: { width: '50%' },
}));

const Notifications = (props) => {
  const classes = useStyles();
  const entityId = props?.record?.id;
  const entityType = props?.entityType;
  const [notifications, setNotifications] = useState({});

  useEffect(() => {
    apiClient.get(`${entityType}/notifications/${entityId}/`, {}).then((res) => {
      setNotifications(res?.data);
    });
  }, [entityId, entityType]);

  return (
    <Table className={classes.notificationTable}>
      <TableHead>
        <TableRow>
          {map(notifications, (count, type) => (
            <TableCell className={classes.notification}>{type}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          {map(notifications, (count) => (
            <TableCell>{count}</TableCell>
          ))}
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default Notifications;