import React, { useState, useEffect } from 'react';
import { find } from 'lodash';
import { FormControl as MUIFormControl, MenuItem as MUIMenuItem, TextField as MUITextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useMutation } from 'react-admin';

import FormConfirmationDialog from '../FormConfirmationDialog';

const CONTACT_TYPES = {
  PHONE: {
    id: 0,
    name: 'Phone number',
    category: 'organization',
  },
  EMAIL: {
    id: 1,
    name: 'Email',
    category: 'organization',
  },
  SNS: {
    id: 2,
    name: 'SNS',
    category: 'organization',
  },
  WEBSITE: {
    id: 3,
    name: 'Website',
    category: 'organization',
  },
  BOX_OFFICE_EMAIL: {
    id: 3,
    name: 'Email',
    category: 'box_office',
  },
  BOX_OFFICE_PHONE: {
    id: 0,
    name: 'Phone',
    category: 'box_office',
  },
  BOX_OFFICE_WEBSITE: {
    id: 0,
    name: 'Website',
    category: 'box_office',
  },
  ADDRESS: {
    id: 4,
    name: 'Address',
    category: 'organization',
  },
  OTHER: {
    id: 5,
    name: 'Other',
    category: 'organization',
  },
  WORK_EMAIL: {
    id: 1,
    name: 'Email',
    category: 'employee',
  },
  WORK_PHONE: {
    id: 0,
    name: 'Phone',
    category: 'employee',
  },
};

const SOCIAL_NETWORKS = {
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  LINKEDIN: 'linkedin',
  YOUTUBE: 'youtube',
  INSTAGRAM: 'instagram',
  VK: 'vk',
  VIMEO: 'vimeo',
  WIKIPEDIA: 'wikipedia',
};

const useStyles = makeStyles(() => ({
  contactEditDialog: {
    width: '30rem',
  },
  contactInput: {
    marginBottom: '0.5rem',
  },
  spacingBottom: { marginBottom: 10 },
}));

export const ContactEditDialog = (props) => {
  const { service, contact } = props;

  const classes = useStyles();
  const [contactData, setContactData] = useState(null);
  const [mutateContact, { loading: loadingMutateContact }] = useMutation();

  useEffect(() => {
    const defaultContactType = find(CONTACT_TYPES, (contactType) => contactType?.id === contact?.contactType?.id && contactType?.category === contact?.category);
    setContactData({ ...contact, contactType: defaultContactType || CONTACT_TYPES.PHONE, category: defaultContactType?.category || CONTACT_TYPES.PHONE?.category });
  }, [contact]);

  if (!props.service) {
    return null;
  }

  const setContact = () => {
    if (!contactData) {
      if (props.onCancel) {
        props.onCancel();
      }
      return null;
    }

    mutateContact(
      {
        type: contact ? 'update' : 'create',
        resource: service,
        payload: {
          ...(contact ? { id: contact.id } : {}),
          data: {
            contactType: { id: contactData?.contactType?.id },
            name: contactData?.contactType?.id === CONTACT_TYPES.SNS?.id ? contactData?.name : contactData?.contactType?.name,
            category: contactData?.category,
            value: contactData.value,
          },
        },
      },
      {
        onSuccess: props.onSuccess,
        onFailure: props.onFailure,
      },
    );
  };

  return (
    <FormConfirmationDialog open={props.open} onCancel={props.onCancel} onConfirm={setContact} title="Edit contact">
      <MUIFormControl className={classes.contactEditDialog}>
        <MUITextField autoFocus disabled margin="dense" name="id" label="ID" variant="filled" defaultValue={contactData?.id} fullWidth className={classes.contactInput} />
        <MUITextField
          select
          label="Contact type"
          disabled={loadingMutateContact}
          defaultValue={contactData?.contactType || CONTACT_TYPES.PHONE}
          value={contactData?.contactType}
          onChange={(event) => {
            setContactData({ ...contactData, contactType: event?.target?.value, category: event?.target?.value?.category });
          }}
          variant="filled"
          size="small"
          className={classes.spacingBottom}
        >
          <MUIMenuItem value={CONTACT_TYPES.PHONE}>Phone number</MUIMenuItem>
          <MUIMenuItem value={CONTACT_TYPES.EMAIL}>Email</MUIMenuItem>
          <MUIMenuItem value={CONTACT_TYPES.WEBSITE}>Website</MUIMenuItem>
          <MUIMenuItem value={CONTACT_TYPES.SNS}>SNS</MUIMenuItem>
          <MUIMenuItem value={CONTACT_TYPES.BOX_OFFICE_EMAIL}>Box Office Email</MUIMenuItem>
          <MUIMenuItem value={CONTACT_TYPES.BOX_OFFICE_PHONE}>Box Office Phone</MUIMenuItem>
          <MUIMenuItem value={CONTACT_TYPES.BOX_OFFICE_WEBSITE}>Box Office Website</MUIMenuItem>
          <MUIMenuItem value={CONTACT_TYPES.ADDRESS}>Address</MUIMenuItem>
          <MUIMenuItem value={CONTACT_TYPES.WORK_EMAIL}>Work Email</MUIMenuItem>
          <MUIMenuItem value={CONTACT_TYPES.WORK_PHONE}>Work Phone</MUIMenuItem>
          <MUIMenuItem value={CONTACT_TYPES.OTHER}>Other</MUIMenuItem>
        </MUITextField>
        <MUITextField
          select
          label="Social network type(SNS)"
          disabled={contactData?.contactType?.id !== CONTACT_TYPES.SNS?.id || loadingMutateContact}
          defaultValue={contactData?.contactType?.id === CONTACT_TYPES.SNS?.id ? contactData?.name : null}
          value={contactData?.contactType?.id === CONTACT_TYPES.SNS?.id ? contactData?.name : null}
          onChange={(event) => {
            setContactData({ ...contactData, name: event?.target?.value });
          }}
          variant="filled"
          size="small"
          className={classes.spacingBottom}
        >
          <MUIMenuItem value={null}>
            <em>None</em>
          </MUIMenuItem>
          <MUIMenuItem value={SOCIAL_NETWORKS.FACEBOOK}>Facebook</MUIMenuItem>
          <MUIMenuItem value={SOCIAL_NETWORKS.INSTAGRAM}>Instagram</MUIMenuItem>
          <MUIMenuItem value={SOCIAL_NETWORKS.LINKEDIN}>LinkedIn</MUIMenuItem>
          <MUIMenuItem value={SOCIAL_NETWORKS.TWITTER}>Twitter</MUIMenuItem>
          <MUIMenuItem value={SOCIAL_NETWORKS.VIMEO}>Vimeo</MUIMenuItem>
          <MUIMenuItem value={SOCIAL_NETWORKS.VK}>VK</MUIMenuItem>
          <MUIMenuItem value={SOCIAL_NETWORKS.YOUTUBE}>YouTube</MUIMenuItem>
          <MUIMenuItem value={SOCIAL_NETWORKS.WIKIPEDIA}>Wikipedia</MUIMenuItem>
        </MUITextField>
        <MUITextField
          autoFocus
          margin="dense"
          name="value"
          label="Value"
          variant="filled"
          defaultValue={contactData?.value}
          value={contactData?.value}
          onChange={(event) => setContactData({ ...contactData, value: event.target.value })}
          multiline
          fullWidth
          disabled={props.loadingMutateContact}
        />
      </MUIFormControl>
    </FormConfirmationDialog>
  );
};
