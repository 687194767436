import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { useLocation } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';

import App from './App';
import LanguagesProvider from './utils/useLanguageProvider';

import './index.css';

require('dotenv').config();

if (process.env.REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn: 'https://0907339c6f9bb2fffe09da6dac83de44@sentry.operabase.com/7',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
      }),
    ],
    tracesSampleRate: 0.1,
  });
}

ReactDOM.render(
  <ErrorBoundary>
    <LanguagesProvider>
      <App />
    </LanguagesProvider>
  </ErrorBoundary>,
  document.getElementById('root'),
);
