import axios from 'axios';

const blitzerApiUrl = process.env.REACT_APP_BLITZER_API_URL;
const blitzerSecret = process.env.REACT_APP_BLITZER_API_SECRET;

const blitzerApiClient = axios.create({
  baseURL: blitzerApiUrl,
  validateStatus: (status) => status >= 200 && status < 300,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
});

blitzerApiClient.interceptors.request.use(
    async (config) => {
      // eslint-disable-next-line no-param-reassign
      if (blitzerSecret) config.headers['x-access-key'] = `${blitzerSecret}`;
      return config;
    },
    (error) => Promise.reject(error),
  );

blitzerApiClient.interceptors.response.use(
  async (response) => { console.log(response); return response },
  (error) => console.error('Error:', error)
);

export default blitzerApiClient;
