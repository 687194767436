import React, { useState, useCallback } from 'react';
import {
  Edit,
  List,
  Filter,
  TextInput,
  Datagrid,
  TextField,
  SimpleShowLayout,
  SimpleForm,
  Toolbar,
  SaveButton,
  Create,
  SelectInput,
  FunctionField,
  ReferenceField,
  TabbedShowLayout,
  Tab,
  TabbedShowLayoutTabs,
  TopToolbar,
  EditButton,
  ReferenceInput,
  AutocompleteInput,
  ArrayField,
  SingleFieldList,
  TabbedForm,
  FormTab,
  ReferenceManyField,
  Pagination,
  useUpdate,
  useNotify,
  useRefresh,
  DateField,
} from 'react-admin';
import { useRouteMatch } from 'react-router-dom';
import { Chip } from '@material-ui/core';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { VALIDATION_STATUSES } from '../constants/validations';
import ValidityShow from '../components/ValidityShow';
import CustomShowLayout from '../components/CustomShowLayout';
import EntityLink from '../components/EntityLink';

import { PROFESSION_INSTRUMENTALIST } from '../constants/profession';
import { ProductionsGrid } from './ProductionsModel';
import { getComposerNamesFromCreators } from '../utils/composer';
import { renderProfessionList } from './ProfessionsModel';
import EntityTranslation from '../components/translation/EntityTranslation';

const TABS = {
  INFO: 'info',
  TRANSLATIONS: 'translation',
};

const InstrumentsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="query" alwaysOn />
    <TextInput label="Instrument ID" source="id" alwaysOn />
    <ReferenceInput
      label="Profession"
      source="profession_id"
      reference="professions"
      filter={{ parent_profession: PROFESSION_INSTRUMENTALIST }}
      filterToQuery={(searchText) => ({ query: searchText })}
      sort={{ field: 'profile_count', order: 'DESC' }}
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <SelectInput label="Validation status" source="validation_status" choices={VALIDATION_STATUSES} alwaysOn />
  </Filter>
);

export const InstrumentsShow = (props) => (
  <ValidityShow {...props} title={<Breadcrumbs {...props} />} actions={<InstrumentsShowActions />}>
    <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" {...props} />}>
      <Tab label="Instrument Info">
        <CustomShowLayout {...props} isStats>
          {{
            column1: (
              <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="name" />

                <ReferenceField link="show" label="Instrumentalist Profession" reference="professions" source="profession.id" sortable={false}>
                  <FunctionField emptyText="Unknown" render={(record) => <EntityLink entity={record} entityType="professions" />} />
                </ReferenceField>

                <ReferenceField link="show" label="User" reference="users" source="user_id" sortable={false}>
                  <FunctionField emptyText="Unknown" render={(record) => <EntityLink entity={record} entityType="users" />} />
                </ReferenceField>
              </SimpleShowLayout>
            ),
            column2: (
              <SimpleShowLayout>
                <ReferenceManyField label="Instrument Roles" reference="instruments/roles" link="show" target="instrument_id" source="id" perPage={5}>
                  <Datagrid rowClick={(id, path, record) => `/instruments/${record?.id}/show`}>
                    <FunctionField emptyText="Unknown" render={(record) => <EntityLink entity={record} entityType="instruments/roles" />} />
                  </Datagrid>
                </ReferenceManyField>
              </SimpleShowLayout>
            ),
          }}
        </CustomShowLayout>
      </Tab>
      <Tab label="Instrument Translations">
        <EntityTranslation list={{ url: `instruments/${props?.id}/translations` }} update={{ url: `instruments/${props?.id}/translations` }} />
      </Tab>
      <Tab label="Instrument Roles">
        <ReferenceManyField label="List of instruments roles" reference="instruments/roles" link="show" target="instrument_id" perPage={25} pagination={<Pagination />}>
          <Datagrid rowClick={(id, path, record) => `/instruments/roles/${record?.id}/show`}>
            <TextField source="id" sortable={false} />
            <TextField source="name" />
            <TextField source="validationStatus.name" sortable={false} />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Instrument Siblings">
        <ReferenceManyField
          label="List of instrumentroles associated sibling"
          reference="instruments"
          link="show"
          target="profession_id"
          source="profession.id"
          perPage={25}
          pagination={<Pagination />}
        >
          <Datagrid rowClick={(id, path, record) => `/instruments/${record?.id}/show`}>
            <FunctionField emptyText="Unknown" render={(record) => <EntityLink entity={record} entityType="instruments" />} />
            <ArrayField source="name_variants">
              <SingleFieldList linkType={false}>
                <FunctionField render={(record) => <Chip label={`${record}`} />} />
              </SingleFieldList>
            </ArrayField>
            <TextField source="validationStatus.name" sortable={false} />
          </Datagrid>
        </ReferenceManyField>
      </Tab>{' '}
      <Tab label="Musical Works">
        <ReferenceManyField
          label="List of musical works where instrument is used"
          reference="works"
          filter={{ as_edit: true, instrument_id: props?.id }}
          {...props}
          perPage={25}
          pagination={<Pagination />}
        >
          <Datagrid rowClick="show" {...props}>
            <TextField source="id" />
            <FunctionField
              render={(record) => {
                const composerNames = getComposerNamesFromCreators(record?.creators);
                return composerNames;
              }}
              label="Composers"
            />
            <TextField source="name" />
            <TextField source="original_name" />
            <TextField source="workType.name" label="Work type" />
            <TextField source="stats.productionCount" sortBy="production_count" label="Production count" />
            <TextField label="Validation status" source="validationStatus.name" />
            <DateField source="createdAt" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Productions">
        <ReferenceManyField
          label="List of productions where instrument is used"
          reference="productions"
          filter={{ as_edit: true, instrument_id: props?.id, published: 'both' }}
          sort={{ field: 'year', order: 'DESC' }}
          perPage={25}
          pagination={<Pagination />}
        >
          <ProductionsGrid {...props} />
        </ReferenceManyField>
      </Tab>
      <Tab label="Profiles">
        <ReferenceManyField label="List of profiles where instrument is used" reference="profiles" filter={{ db: true, instrument_id: props?.id }} perPage={25} pagination={<Pagination />}>
          <Datagrid rowClick={(id, path, record) => `/profiles/${record?.id}/show`}>
            <TextField source="id" />
            <TextField source="name" sortable={false} />
            <FunctionField label="Professions" render={(record) => renderProfessionList(record?.professions)} />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Musical Works">
        <ReferenceManyField
          label="List of musical works where instrument is used"
          reference="works"
          filter={{ as_edit: true, instrument_id: props?.id }}
          {...props}
          perPage={25}
          pagination={<Pagination />}
        >
          <Datagrid rowClick="show" {...props}>
            <TextField source="id" />
            <FunctionField
              render={(record) => {
                const composerNames = getComposerNamesFromCreators(record?.creators);
                return composerNames;
              }}
              label="Composers"
            />
            <TextField source="name" />
            <TextField source="original_name" />
            <TextField source="workType.name" label="Work type" />
            <TextField source="stats.productionCount" sortBy="production_count" label="Production count" />
            <TextField label="Validation status" source="validationStatus.name" />
            <DateField source="createdAt" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Productions">
        <ReferenceManyField
          label="List of productions where instrument is used"
          reference="productions"
          filter={{ as_edit: true, instrument_id: props?.id, published: 'both' }}
          sort={{ field: 'year', order: 'DESC' }}
          perPage={25}
          pagination={<Pagination />}
        >
          <ProductionsGrid {...props} />
        </ReferenceManyField>
      </Tab>
      <Tab label="Profiles">
        <ReferenceManyField label="List of profiles where instrument is used" reference="profiles" filter={{ db: true, instrument_id: props?.id }} perPage={25} pagination={<Pagination />}>
          <Datagrid rowClick={(id, path, record) => `/profiles/${record?.id}/show`}>
            <TextField source="id" />
            <TextField source="name" sortable={false} />
            <FunctionField label="Professions" render={(record) => renderProfessionList(record?.professions)} />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </ValidityShow>
);

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const InstrumentsCreate = (props) => (
  <Create title={<Breadcrumbs {...props} />} {...props}>
    <SimpleForm toolbar={<CustomToolbar />}>
      <TextInput source="name" />
      <ReferenceInput
        label="Profession"
        source="profession.id"
        reference="professions"
        filterToQuery={(searchText) => ({ query: searchText })}
        filter={{ parent_profession: PROFESSION_INSTRUMENTALIST }}
        sort={{ field: 'profile_count', order: 'DESC' }}
        alwaysOn
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

const useSaveHandlers = () => {
  const notify = useNotify();
  const refresh = useRefresh();

  const onFailure = (error) => {
    notify(`${error.message}`, 'warning');
    refresh();
  };

  const onSuccess = () => {
    notify('ra.notification.updated', 'info', {
      smart_count: 1,
    });
    refresh();
  };

  return { onFailure, onSuccess };
};

const SaveInfo = ({ tab, ...props }) => {
  const { initial } = props;
  const instrumentId = initial.id;
  const [update] = useUpdate('instruments', instrumentId);
  const { onSuccess, onFailure } = useSaveHandlers();

  const handleSave = useCallback(
    (values) => {
      const { __versions: newVersions, ...valuesData } = values;
      update(
        {
          payload: {
            data: valuesData,
          },
        },
        {
          onSuccess,
          onFailure,
        },
      );
    },
    [onSuccess, onFailure, update],
  );
  // set onSave props instead of handleSubmitWithRedirect
  return <SaveButton {...props} onSave={handleSave} />;
};

const CustomEditToolbar = ({ tab, ...props }) => {
  const [record] = useState(props.record);
  return (
    <Toolbar {...props}>
      <SaveInfo initial={record} tab={tab} />
    </Toolbar>
  );
};

export const InstrumentsEdit = (props) => {
  let initialTab = TABS.INFO;
  const match = useRouteMatch('/instruments/:id/:tab');
  if (match && match.params) {
    initialTab = Object.values(TABS)[match.params.tab];
  }
  const [tab, setTab] = useState(initialTab);

  return (
    <Edit {...props} title={<Breadcrumbs {...props} />}>
      <TabbedForm toolbar={<CustomEditToolbar {...props} tab={tab} />}>
        <FormTab label="Instrument Info" onClick={() => setTab(TABS.INFO)}>
          <TextInput source="name" />
          <ReferenceInput
            label="Profession"
            source="profession.id"
            reference="professions"
            filterToQuery={(searchText) => ({ query: searchText })}
            filter={{ parent_profession: PROFESSION_INSTRUMENTALIST }}
            sort={{ field: 'profile_count', order: 'DESC' }}
            alwaysOn
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
        </FormTab>
        <FormTab label="Instrument Translations" onClick={() => setTab(TABS.TRANSLATIONS)}>
          <EntityTranslation list={{ url: `instruments/${props?.id}/translations` }} update={{ url: `instruments/${props?.id}/translations` }} />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const InstrumentsShowActions = (props) => {
  const { basePath, data } = props;

  return (
    <TopToolbar style={{ alignItems: 'center' }}>
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );
};

export const InstrumentsList = (props) => (
  <List exporter={false} {...props} title={<Breadcrumbs {...props} />} filters={<InstrumentsFilter />} bulkActionButtons={false} perPage={25}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" sortable={false} />
      <ArrayField source="name_variants">
        <SingleFieldList linkType={false}>
          <FunctionField render={(record) => <Chip label={`${record}`} />} />
        </SingleFieldList>
      </ArrayField>
      <ReferenceField link="show" label="Profession" reference="professions" source="profession.id" sortable={false}>
        <FunctionField emptyText="Unknown" render={(record) => <EntityLink entity={record} entityType="professions" />} />
      </ReferenceField>
      <TextField source="validationStatus.name" sortable={false} />
    </Datagrid>
  </List>
);

export default InstrumentsList;
