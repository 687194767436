import React from 'react';
import { Modal, Fade, makeStyles, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  modalRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: '90%',
    padding: 28,
    maxHeight: '95vh',
    borderRadius: 8,
    backgroundColor: '#fff',
    textAlign: 'center',
    '&:focus': {
      outline: 'none',
    },
  },
  loader: {
    minWidth: 100,
  },
  spacingTop: {
    marginTop: 15,
  }
}));

const InProgressModal = ({ isOpen, message }) => {
  const classes = useStyles();

  return (
    <Modal open={isOpen} className={classes.modalRoot} disableEnforceFocus>
      <Fade in={isOpen}>
        <div className={classes.paper}>
          <CircularProgress />
          <div className={classes.spacingTop}>{message || ''}</div>
        </div>
      </Fade>
    </Modal>
  );
};

export default InProgressModal;
