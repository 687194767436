export const withExtractFormData = (handler) => (e) => {
  e.preventDefault();
  const formData = new FormData(e.target);
  const data = Array.from(formData.keys()).reduce((prev, next) => {
    prev[next] = formData.get(next);
    return prev;
  }, {});
  if (!handler) {
    return data;
  }
  return handler(data);
};
