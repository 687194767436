import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-admin';
import { Chip } from '@material-ui/core';

const useStyles = makeStyles(
  (theme) => ({
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      marginRight: 10,
    },
    chip: {
      cursor: 'pointer',
      margin: '10px 0',
    },
  }),
  { name: 'MakeLink' },
);

const ProfileOrganizationLink = ({ record, showChip, isDelete, onDelete }) => {
  const classes = useStyles();

  if (!record?.organization) return null;

  const { name } = record.organization;

  const handleDelete = (e) => {
    e.preventDefault();
    onDelete();
  };

  return (
    <Link to={`/organizations/${record.organization.id}/show`} className={classes.link}>
      {showChip ? <Chip className={classes.chip} label={name} {...(isDelete ? { onDelete: handleDelete } : {})} /> : name}
    </Link>
  );
};

export default ProfileOrganizationLink;
