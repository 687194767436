import React, { useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  List,
  ReferenceField,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  Filter,
  TextInput,
  Datagrid,
  TextField,
  FunctionField,
  Create,
  Edit,
  ReferenceInput,
  SelectInput,
  Tab,
  ReferenceManyField,
  DeleteWithConfirmButton,
  SingleFieldList,
  ImageField,
  Toolbar,
  SaveButton,
  DeleteButton,
  ArrayField,
  useQueryWithStore,
  SimpleShowLayout,
  SelectField,
  TopToolbar,
  EditButton,
  AutocompleteInput,
  TabbedForm,
  FormTab,
  SimpleForm,
  DateField,
  BooleanInput,
  Pagination,
  CheckboxGroupInput,
  FormDataConsumer,
  ListContextProvider,
  Link,
  Loading,
} from 'react-admin';
// import axios from 'axios';
import * as dayjs from 'dayjs';
import { take, size, drop, orderBy, keyBy } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles, Chip, Typography, Divider, Tooltip, FormControl, TextField as MUITextField, Table, TableBody, TableHead, TableRow, TableCell, Paper, MenuItem } from '@material-ui/core';
import { PROFILE_TYPES } from '../constants/profileTypes';
import { REGISTRATION_LINK_TEMPLATE, REGISTRATION_PROFILE_TYPES, REGISTRATION_COHORT_TYPES } from '../constants/registration';
import RegistrationLink from '../components/registrationLink';
// eslint-disable-next-line import/no-cycle
import { ProfileAccessRequestsActions } from './UsersModel';
import { InvoicesListColors } from '../constants/stylingConstants';
import { GENDER_TYPES } from '../constants/genderTypes';
import CountryAndCityInput from '../inputs/CountryAndCityInput';
import CustomDateField from '../components/customFields/CustomDateField';
import ValidityShow from '../components/ValidityShow';
import { VALIDATION_STATUSES } from '../constants/validations';
import { MediaThumbnailField } from '../components/MediaThumbnailField';
import { REVIEW_ENTITIES } from '../constants/reviews';
import { AOS_TYPE_ID, ARTIST_TYPE_ID, PROFILE_NOTIFICATIONS } from '../constants/profiles';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { PublicLink } from '../components/PublicLink';
import useLanguageQuery from '../utils/useLanguageQuery';
import { SubscriptionsTabContent } from '../components/SubscriptionsTabContent';
import { ProductionsGrid } from './ProductionsModel';
import Notifications from '../components/Notifications';
import apiClient from '../utils/api';
import { ENTITIES, FOR_ENTITY, WHITELIST_ENTITIES_STATUS } from '../constants/entities';
import { TAG_SLUGS } from '../constants/profession';
import useUserPermissions from '../utils/useUserPermissions';
import CustomShowLayout from '../components/CustomShowLayout';
import EntityLink from '../components/EntityLink';
import ExternalReferencesTab from '../components/ExternalReferences';
import EntityTranslation from '../components/translation/EntityTranslation';

// const BLITZER_API_URL = "https://dev-blitzer-event-api.truelinked.net/"
// const BLITZER_SECRET = 'secret'

const useStyles = makeStyles(() => ({
  registrationBlock: {
    padding: 0,
  },
  infoTitle: {
    width: 120,
  },
  withMargin: {
    marginTop: 5,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10,
  },
  spacing: {
    marginRight: 10,
  },
  link: {
    color: 'blue',
    cursor: 'pointer',
  },
  profileImage: {
    '& img': {
      maxWidth: 50,
      maxHeight: 50,
    },
  },
  addIcon: {
    marginLeft: 5,
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    borderRadius: '50%',
    padding: 4,
    fontSize: 12,
  },
  tabOverview: {
    overflow: 'auto',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginBottom: 10,
    fontWeight: 'bold',
    fontSize: 16,
  },
  overflow: { overflowX: 'scroll' },
  selectSection: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  spacingLeft: {
    marginLeft: 10,
  },
}));

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const IS_PRO = [
  { id: '', name: '' },
  { id: 'true', name: 'Pro' },
  { id: 'false', name: 'Non Pro' },
];

const CustomToolbar = withStyles(toolbarStyles)((props) => (
  <Toolbar {...props}>
    <SaveButton />
    <DeleteButton undoable={false} />
  </Toolbar>
));

const ProfilesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Profile ID" source="id" alwaysOn />
    <TextInput label="Search" source="query" alwaysOn />
    <SelectInput label="Profile Type" source="profile_type_id" choices={PROFILE_TYPES} optionText="name" optionValue="id" alwaysOn />
    <SelectInput label="Is Pro" source="is_pro" choices={IS_PRO} alwaysOn />
    <ReferenceInput
      label="Profession"
      source="profession_id"
      reference="professions"
      filterToQuery={(searchText) => ({
        query: searchText,
      })}
      sort={{ field: 'profile_count', order: 'DESC' }}
      alwaysOn
    >
      <AutocompleteInput matchSuggestion={() => true} optionText="name" />
    </ReferenceInput>
    <SelectInput label="Is whitelisted" source="is_whitelisted" choices={WHITELIST_ENTITIES_STATUS} alwaysOn />
    <SelectInput label="Validation status" source="validation_status" choices={VALIDATION_STATUSES} alwaysOn />
    <CheckboxGroupInput source="has_notifications" label="Notifications" choices={PROFILE_NOTIFICATIONS} alwaysOn />
  </Filter>
);

export const UserName = ({ record }) => {
  const name = record.name ?? record.email;
  return <Chip label={name} />;
};

const RegistrationAdapter = (props) => {
  const { record } = props;
  const classes = useStyles();
  const languages = useLanguageQuery();
  const { data } = useQueryWithStore({
    type: 'getList',
    resource: 'users/registrations',
    payload: {
      filter: { user_id: record?.user?.id },
      pagination: { page: 1, perPage: 1000 },
      sort: {},
    },
  });

  const registration = useMemo(() => (data || []).find((reg) => reg?.profile?.id === record?.id), [data, record]);

  if (!registration) return <h2>No registration info</h2>;
  return (
    <SimpleShowLayout record={registration} className={classes.registrationBlock}>
      <FunctionField label="Registration" render={() => registration && <RegistrationLink record={registration} />} />
      <FunctionField label="Completed profile sections" render={() => record?.completedSections.map((section, index) => <div key={`cs-${index}`}>{section}</div>)} />
      <CustomDateField source="createdAt" />
      <CustomDateField source="updatedAt" />
      <TextField source="firstname" label="First Name" />
      <TextField source="lastname" label="Last Name" />
      <TextField source="email" label="E-mail" />
      <TextField source="profession.name" label="Profession" />
      {record?.profileType?.id === 2 && <FunctionField render={() => <a href={`#/agencies/${registration?.agency?.id}/show`}>{registration?.agency?.name}</a>} label="Agency" />}
      {record?.profileType?.id === 3 && <FunctionField render={() => <a href={`#/organizations/${registration?.organization?.id}/show`}>{registration?.organization?.name}</a>} label="Organization" />}

      <FunctionField
        label="Registration type"
        render={(reg) => <div>{reg?.profile_type_id ? REGISTRATION_PROFILE_TYPES.find((t) => t.id === reg?.profile_type_id)?.name : REGISTRATION_COHORT_TYPES[reg.flow_id]}</div>}
      />
      <FunctionField label="Registration link" render={(rec) => REGISTRATION_LINK_TEMPLATE(rec?.id)} />
      <TextField source="step" label="Step" />
      <TextField source="plan" label="Subscription plan" />
      <TextField source="communication" label="Prefered mode of communication" />
      <SelectField choices={languages} source="language" label="Prefered language" />
      <TextField source="time" label="Prefered time" />
      <TextField source="phone_number" label="Phone number" />
      <TextField source="whatsapp" label="Whatsapp" />
      <TextField source="location" label="Location" />
      <TextField source="job_title" label="Title" />
      <TextField source="profile_description" label="Description" />
      <TextField source="magazine_name" label="Magazine name" />
      <TextField source="university_name" label="University name" />
      <TextField source="website_url" label="Website url" />
      <TextField source="note" label="Note" />
    </SimpleShowLayout>
  );
};

const RequestsTab = (props) => (
  <ReferenceManyField {...props} reference="profiles/profileaccessrequests" filter={{ profile_id: props.record.id }}>
    <Datagrid rowClick={(id, path, record) => `/profiles/profileaccessrequests/${record?.id}/show`}>
      <TextField source="sender.user.id" label="Sender user ID" />
      <TextField source="sender.user.name" label="Sender user name" />
      <TextField source="sender.email" label="Sender email" />
      <TextField source="sender.timezone" label="Sender timezone" />
      <TextField source="sender.preferred_time" label="Sender preferred time" />
      <TextField source="profile.name" label="Profile name" />
      <TextField source="status" label="Status" />
      <ProfileAccessRequestsActions />
      <DeleteWithConfirmButton {...props} undoable={false} redirect={false} />
    </Datagrid>
  </ReferenceManyField>
);

const ShowAndHideLogs = (props) => {
  const profileId = props?.record?.id;
  const [showHideLogs, setShowHideLogs] = useState([]);

  useEffect(() => {
    if (profileId) {
      apiClient.get('data/show-hide-logs', { params: { profile_id: profileId } }).then((res) => {
        const mediaLogs = res?.data?.media;
        const productionsLogs = res?.data?.productions;
        const reviewsLogs = res?.data?.reviews;
        const allLogs = orderBy([...mediaLogs, ...productionsLogs, ...reviewsLogs], 'updated_at', 'desc');
        setShowHideLogs(allLogs);
      });
    }
  }, [profileId]);

  const listContext = {
    data: keyBy(showHideLogs, 'id'),
    ids: showHideLogs.map(({ id }) => id),
    total: showHideLogs.length,
    currentSort: {},
    basePath: props.basePath,
    resource: props.resource,
    selectedIds: [],
    hasList: true,
  };

  return (
    <ListContextProvider value={listContext}>
      <Datagrid>
        <TextField source="id" />
        <FunctionField label="Action" render={(record) => (record?.acknowledged ? 'Show' : 'Hide')} />
        <CustomDateField source="updated_at" />
        <FunctionField label="Performed by(User)" render={(record) => <Link to={`/users/${record.action_user_id}/show`}>{record.action_user_id}</Link>} />
        <TextField source="action_user_type" label="User group" />
        <FunctionField
          label="Updated"
          render={(record) => {
            if (record?.media_id) {
              return <Link to={`/media/${record.media_id}/show`}>Media: {record.media_id}</Link>;
            }

            if (record?.production_id) {
              return <Link to={`/productions/${record.production_id}/show`}>Production: {record.production_id}</Link>;
            }

            if (record?.review_id) {
              return `Review: ${record.review_id}`;
            }
          }}
        />
      </Datagrid>
    </ListContextProvider>
  );
};

const Insights = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [dataColumns, setDataColumns] = useState([]);

  const insightTypes = [
    {
      label: 'Pageview',
      value: 'pageviews_timeseries',
    },
    {
      label: 'Profile Impressions',
      value: 'impressions_timeseries',
    },
    {
      label: 'Search Impressions',
      value: 'search_appearances_timeseries',
    },
  ];
  const searchParams = new URLSearchParams(history.location.search);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(searchParams?.get('metric') || insightTypes[0].value);

  const defaultDateRange = { from_date: searchParams?.get('from_date'), to_date: searchParams?.get('to_date') };
  const [selectedDateRange, setSelectedDateRange] = useState({
    from: defaultDateRange?.from_date || dayjs().add(-6, 'day').format('YYYY-MM-DD'),
    to: defaultDateRange?.to_date || dayjs().add(1, 'day').format('YYYY-MM-DD'),
  });

  useEffect(() => {
    searchParams.set('from_date', selectedDateRange?.from);
    searchParams.set('to_date', selectedDateRange?.to);
    searchParams.set('metric', tab);
    const newSearch = searchParams.toString();

    history.push({ search: newSearch });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange, tab]);

  useEffect(() => {
    setLoading(true);
    // axios
    //   .get(`${process.env.REACT_APP_BLITZER_API_URL}dbviews/${tab}`, {
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'x-access-key': `${process.env.REACT_APP_BLITZER_API_SECRET}`,
    //     },
    //     params: {
    //       dateStart: selectedDateRange.from,
    //       dateEnd: selectedDateRange.to,
    //       entityId: props?.id,
    //       limit: 100,
    //     },
    //   })
    //   .then((res) => {
    //     setData(res?.data?.data);
    //     setDataColumns(Object.keys(res?.data?.data?.[0] || []));
    //     setLoading(false);
    //   });
    apiClient
      .get(`profiles/${props?.id}/stats`, {
        params: {
          from: selectedDateRange.from,
          to: selectedDateRange.to,
          category: 'insights',
          aggregate: 'month',
          metric: tab,
        },
      })
      .then((res) => {
        setData(res?.data?.data[0]?.stats?.timeseries);
        setDataColumns(Object.keys(res?.data?.data[0]?.stats?.timeseries?.[0] || []));
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.id, selectedDateRange, tab]);

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <FormControl className={classes.selectSection} size="small">
        <MUITextField
          disabled={loading}
          value={selectedDateRange.from}
          onChange={(e) => setSelectedDateRange({ ...selectedDateRange, from: e?.target?.value })}
          type="date"
          label="From"
          variant="filled"
          size="small"
        />
        <MUITextField
          className={classes.spacingLeft}
          value={selectedDateRange.to}
          onChange={(e) => setSelectedDateRange({ ...selectedDateRange, to: e?.target?.value })}
          type="date"
          label="To"
          variant="filled"
          size="small"
          disabled={loading}
        />
        <MUITextField className={classes.spacingLeft} value={tab} select onChange={(e) => setTab(e?.target?.value)} label="Insight Type" variant="filled" size="small">
          {insightTypes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </MUITextField>
      </FormControl>
      {dataColumns ? (
        <Table component={Paper}>
          <TableHead>
            <TableRow>
              {dataColumns.map((headingName, idx) => (
                <TableCell key={idx}>{headingName} </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((value, index) => (
              <TableRow key={index}>
                {dataColumns.map((headingName, subIndex) => (
                  <TableCell key={subIndex}>{value[headingName]} </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <div>No Data</div>
      )}
    </>
  );
};

export const renderProfile = (profile) => {
  const profileType = profile?.profileType?.name ? ` - ${profile?.profileType?.name}` : '';
  const profileName = profile.name ? `${profile.name}` : 'Profile without name';
  const professionName = profile?.profession?.name ? ` - ${profile?.profession?.name}` : '';

  return `(${profile.id}${profileType})  - ${profileName}${professionName}`;
};

export const ProfilesShowActions = (props) => {
  const { basePath, data } = props;

  return (
    <TopToolbar style={{ alignItems: 'center' }}>
      <PublicLink {...props} />
      <PublicLink edit {...props} />
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );
};

const vitalStatus = (profile) => {
  const birthYear = profile.personal?.birth?.year;
  const birthCountry = profile.personal?.birth?.country?.name;
  const birthCity = profile.personal?.birth?.city?.name;
  const deathYear = profile.personal?.death?.year;
  const deathCountry = profile.personal?.death?.country?.name;
  const deathCity = profile.personal?.death?.city?.name;

  let data = [
    birthYear ? `Born ${birthYear}` : '',
    birthYear && (birthCity || birthCountry) ? `in ${birthCity || ''} ${birthCountry || ''}` : '',
    birthYear && deathYear ? ',' : '',
    deathYear ? `Died ${deathYear}` : '',
    deathYear && (deathCity || deathCountry) ? `in ${deathCity || ''} ${deathCountry || ''}` : '',
  ].filter((status) => status);

  if (data.length === 0) {
    data = ['Unknown'];
  }

  return data.join(' ');
};

export const renderProfileName = (record) => {
  let name = `${record.firstName} ${record.lastName}`.trim();
  if (name !== record.name) {
    name += ` (${record.name})`;
  }
  return name;
};

const renderProfession = (record) => {
  let label = '';
  if (record.isPrimary) {
    label += `primary`;
  }
  if (record.isHidden) {
    if (label !== '') label += ', ';
    label += `hidden`;
  }
  if (label !== '') label = `(${label})`;
  return (
    <span style={{ marginRight: '10px' }}>
      <EntityLink entity={record.profession} entityType="professions" />
      <span>{label}</span>
    </span>
  );
};

export const ProfilesShow = (props) => {
  const classes = useStyles();
  const { isAdmin } = useUserPermissions();

  return (
    <ValidityShow {...props} title={<Breadcrumbs {...props} />} actions={<ProfilesShowActions />} showAllValidations>
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" {...props} />} className={classes.overflow}>
        <Tab label="profile info">
          <CustomShowLayout {...props} isStats>
            {{
              column1: (
                <SimpleShowLayout>
                  <ArrayField source="professions">
                    <SingleFieldList linkType={false}>
                      <FunctionField render={renderProfession} {...props} />
                    </SingleFieldList>
                  </ArrayField>

                  <FunctionField label="Vital Status" render={vitalStatus} />

                  <TextField label="Short name" source="shortName" />

                  <FunctionField
                    label="Alias"
                    emptyText="None"
                    render={(record) => {
                      const aliases = record.alias?.split(',') || [];
                      if (aliases.length === 0) {
                        return 'None';
                      }
                      return aliases.join(', ');
                    }}
                  />

                  <TextField label="Stage name" source="stageName" emptyText="Empty" />

                  <TextField source="isGoodCast" label="is Good Cast" emptyText="Empty" />

                  <FunctionField
                    label="Is Searchable"
                    render={(profile) => {
                      if (profile.validationStatus?.slug === 'approved' && (profile.stats.publishedProductionCount > 0 || Object.keys(profile.stats.creationCount).length > 0)) {
                        return 'Yes';
                      }
                      return 'No';
                    }}
                  />

                  <ImageField source="image.small" label="Picture" emptyText="Empty" />

                  <TextField source="gender" label="Gender" emptyText="Empty" />

                  <ReferenceField source="user.id" reference="users" link="show" label="Created by user" emptyText="Empty">
                    <FunctionField emptyText="Unknown" render={(record) => <EntityLink entity={record} entityType="users" />} />
                  </ReferenceField>

                  <TextField label="Created At" source="createdAt" />
                </SimpleShowLayout>
              ),
              column2: (
                <SimpleShowLayout>
                  <ArrayField label="External References" emptyText="Empty">
                    <ExternalReferencesTab entityType={ENTITIES.PROFILE} entityId={props?.id} displayUrl={1} />
                  </ArrayField>
                  <TextField label="Last Data Activity At" source="lastActivityDate" />

                  <ReferenceManyField label="Users with access" target="profile_id" reference="profiles/profileaccesses" emptyText="none">
                    <SingleFieldList linkType={false}>
                      <ReferenceField source="user.id" reference="users" link="show" label="Created by user" emptyText="Empty">
                        <FunctionField emptyText="Unknown" render={(record) => <EntityLink entity={record} entityType="users" />} />
                      </ReferenceField>
                    </SingleFieldList>
                  </ReferenceManyField>

                  <ReferenceManyField label="Subscriptions" reference="users/subscriptions" target="profile_id">
                    <SingleFieldList linkType={false}>
                      <FunctionField render={(item) => item.subscription?.product?.internalName || item.subscription?.product?.name} />
                    </SingleFieldList>
                  </ReferenceManyField>

                  <ArrayField label="Organizations" source="organizations" emptyText="Empty">
                    <SingleFieldList>
                      <FunctionField label="Entity" render={(record) => <EntityLink entity={record.organization} entityType="organizations" showType="true" />} />
                    </SingleFieldList>
                  </ArrayField>

                  <ArrayField label="Agencies" source="agencies" emptyText="Empty">
                    <SingleFieldList linkType={false}>
                      <FunctionField label="Entity" render={(record) => <EntityLink entity={record} entityType="agencies" />} />
                    </SingleFieldList>
                  </ArrayField>
                </SimpleShowLayout>
              ),
            }}
          </CustomShowLayout>
        </Tab>
        <Tab label="Profile Translation">
          <EntityTranslation list={{ url: `profiles/${props?.id}/translations` }} update={{ url: `profiles/${props?.id}/translations` }} />
        </Tab>
        <Tab label="personal">
          <CustomShowLayout {...props} isStats={false}>
            {{
              column1: (
                <SimpleShowLayout>
                  <Typography variant="p">Birth</Typography>
                  <TextField source="personal.birth.year" label="Birth year" emptyText="-" />
                  <TextField source="personal.birth.month" label="Birth month" emptyText="-" />
                  <TextField source="personal.birth.day" label="Birth day" emptyText="-" />
                  <TextField source="personal.birth.country.name" label="Birth country" emptyText="-" />
                  <TextField source="personal.birth.city.name" label="Birth city" emptyText="-" />
                </SimpleShowLayout>
              ),
              column2: (
                <SimpleShowLayout>
                  <Typography variant="p">Death</Typography>
                  <TextField source="personal.death.year" label="Death year" emptyText="-" />
                  <TextField source="personal.death.month" label="Death month" emptyText="-" />
                  <TextField source="personal.death.day" label="Death day" emptyText="-" />
                  <TextField source="personal.death.country.name" label="Death country" emptyText="-" />
                  <TextField source="personal.death.city.name" label="Death city" emptyText="-" />
                </SimpleShowLayout>
              ),
            }}
          </CustomShowLayout>
        </Tab>
        <Tab label="media">
          <ReferenceManyField filter={{ media_tag_type_id: 1 }} sort={{ field: 'createdAt', order: 'DESC' }} label="Media Records" reference="media" target="media_tag_id">
            <Datagrid rowClick="show">
              <TextField source="id" sortable={false} />
              <TextField source="mediaType.name" label="Type" sortable={false} />
              <MediaThumbnailField label="Image" />
              <TextField source="name" label="Title" sortable={false} />
              <TextField source="description" label="Description" sortable={false} />
              <DateField source="createdAt" showTime />
              <TextField source="status.name" label="Status Name" sortable={false} />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="reviews">
          <ReferenceManyField filter={{ viewing_entity_type: REVIEW_ENTITIES.PROFILE }} label="Review Records" reference="reviews" target="viewing_entity_id">
            <Datagrid rowClick="show">
              <TextField source="id" />
              <TextField source="house" emptyText="Empty" />
              <TextField label="Author" source="signature" />
              <DateField label="Created" source="createdAt" />
              <DateField label="Reviewed" source="reviewedAt" />
              <TextField source="content" emptyText="Empty" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Registration">
          <ReferenceField source="user.id" reference="users" link="show" label="Created by user" emptyText="Empty">
            <TextField source="name" />
          </ReferenceField>
          <RegistrationAdapter />
        </Tab>

        <Tab label="Performances">
          <div className={classes.label}>Published</div>
          <ReferenceManyField
            source="id"
            reference="productions"
            link="show"
            target="individual_id"
            perPage={10}
            pagination={<Pagination />}
            className={classes.tabOverview}
            filter={{ as_edit: true }}
            sort={{ field: 'year', order: 'DESC' }}
          >
            <ProductionsGrid {...props} />
          </ReferenceManyField>
          <div className={classes.label} style={{ marginTop: 30 }}>
            Drafts
          </div>
          <ReferenceManyField
            source="id"
            reference="productions"
            link="show"
            target="individual_id"
            perPage={10}
            pagination={<Pagination />}
            className={classes.tabOverview}
            filter={{ as_edit: true, published: false, for_entity_id: props?.id, for_entity: FOR_ENTITY.ARTIST }}
            sort={{ field: 'year', order: 'DESC' }}
          >
            <ProductionsGrid {...props} />
          </ReferenceManyField>
        </Tab>

        <Tab label="Works">
          <ReferenceManyField source="id" reference="works" target="creator_profile_id" link="show" label={false} emptyText="Empty" pagination={<Pagination />}>
            <Datagrid rowClick="show">
              <TextField source="id" />
              <TextField source="name" />
              <TextField source="original_name" />
              <TextField source="workType.name" label="Work type" />
              <TextField source="stats.productionCount" label="Production count" />
              <TextField label="Validation status" source="validationStatus.name" />
              <DateField source="createdAt" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Subscriptions">
          <ReferenceManyField label="Subscriptions" reference="users/subscriptions" target="profile_id" perPage={10} pagination={<Pagination />}>
            <SubscriptionsTabContent />
          </ReferenceManyField>
        </Tab>

        <Tab label="Payment">
          <ReferenceManyField label="Invoices" reference="invoices" target="profile_id">
            <Datagrid
              rowClick="show"
              rowStyle={(record) => ({
                backgroundColor: InvoicesListColors[record?.status] || InvoicesListColors['fbadmin-default'],
              })}
            >
              <TextField source="number" />
              <TextField source="status" />
              <TextField source="externalNumber" />
              <FunctionField label="Amount" render={(invoice) => `€${parseFloat(invoice.totalPriceInclVat.amount).toFixed(2)}`} />
              <TextField source="totalVatAmount.amount" label="VAT amount" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Access requests">
          <RequestsTab />
        </Tab>
        <Tab label="Notifications">
          <Notifications entityType="profiles" />
        </Tab>
        <Tab label="Show/Hide Logs">
          <ShowAndHideLogs />
        </Tab>
        <Tab label="External References">
          <ExternalReferencesTab entityType={ENTITIES.PROFILE} entityId={props?.id} displayUrl={1} />
        </Tab>
        {isAdmin && (
          <Tab label="Profile Insights">
            <Insights {...props} />
          </Tab>
        )}
      </TabbedShowLayout>
    </ValidityShow>
  );
};

export const ProfilesCreate = (props) => {
  const createProps = { ...props };
  delete createProps.isConcise;
  return (
    <Create {...createProps} title="New profile" toolbar={<CustomToolbar />}>
      <ProfileEditForm {...props} />
    </Create>
  );
};

export const ProfilesEdit = (props) => (
  <Edit {...props} toolbar={<CustomToolbar />} title={<Breadcrumbs {...props} />}>
    <ProfileEditForm />
  </Edit>
);

export const ProfileEditForm = (props) => {
  const { isConcise } = props;
  const userRestricted = props.userRestricted || props.location?.state?.userRestricted;
  const { title } = props;
  const otherProps = { ...props };
  delete otherProps.title;
  delete otherProps.isConcise;
  delete otherProps.userRestricted;

  const basicInfoEdit = ProfileBasicInfoEdit({ ...props, isConcise, title, userRestricted });
  if (isConcise) {
    return <SimpleForm>{basicInfoEdit}</SimpleForm>;
  }
  return (
    <TabbedForm {...otherProps} redirect="show">
      <FormTab label="Basic info">{basicInfoEdit}</FormTab>
      <FormTab label="Personal">
        <Typography variant="p">Birth</Typography>
        <TextInput source="personal.birth.year" label="Birth year" />
        <TextInput source="personal.birth.month" label="Birth month" />
        <TextInput source="personal.birth.day" label="Birth day" />
        <CountryAndCityInput prefix="personal.birth" />
        <Divider />
        <Typography variant="p">Death</Typography>
        <TextInput source="personal.death.year" label="Death year" />
        <TextInput source="personal.death.month" label="Death month" />
        <TextInput source="personal.death.day" label="Death day" />
        <CountryAndCityInput prefix="personal.death" />
      </FormTab>
    </TabbedForm>
  );
};

const ProfileBasicInfoEdit = ({ title, userRestricted }) => [
  title && <Typography variant="h6">{title}</Typography>,
  title && <Divider />,
  <TextField source="id" readonly />,
  <TextInput source="firstName" />,
  <TextInput source="lastName" />,
  <TextInput source="alias" />,

  <ReferenceInput label="Profile type" source="profileType.id" reference="profiles/types" filterToQuery={(searchText) => ({ query: searchText })}>
    <SelectInput optionText="name" />
  </ReferenceInput>,

  <FormDataConsumer>
    {({ formData }) =>
      [ARTIST_TYPE_ID, AOS_TYPE_ID].includes(formData?.profileType?.id) ? (
        <ReferenceInput
          label="Profession"
          source="professions.[0].profession.id"
          reference="professions"
          resettable
          filterToQuery={(searchText) => ({ query: searchText })}
          filter={{
            limit: 100,
            availability: formData?.profileType?.id === ARTIST_TYPE_ID ? 'create_profile, update_profile' : '',
            tag_slug: formData?.profileType?.id === AOS_TYPE_ID ? TAG_SLUGS.ORGANIZATIONS : '',
          }}
          alwaysOn
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      ) : null
    }
  </FormDataConsumer>,

  <SelectInput label="Gender" source="gender" choices={GENDER_TYPES} alwaysOn />,

  <BooleanInput source="isGoodCast" label="Is Good Cast" />,
  <ReferenceInput label="User" source="user.id" reference="users" disabled={userRestricted} filterToQuery={(searchText) => ({ query: searchText })}>
    {!userRestricted ? (
      <AutocompleteInput optionText={(user) => `(${user.id}) - ${user.email} - ${user.name}`} />
    ) : (
      <SelectInput optionText={(user) => `(${user.id}) - ${user.email} - ${user.name}`} />
    )}
  </ReferenceInput>,
];

export const ProfilesList = (props) => {
  const classes = useStyles();

  return (
    <List
      exporter={false}
      {...props}
      filters={<ProfilesFilter />}
      title={<Breadcrumbs {...props} />}
      sort={{ field: 'published_production_count', order: 'DESC' }}
      bulkActionButtons={false}
      perPage={25}
    >
      <Datagrid rowClick="show">
        <TextField source="id" />
        <ImageField source="image.small" label="image" className={classes.profileImage} />
        <TextField source="profileType.name" label="Profile type" />
        <FunctionField source="name" render={renderProfileName} />
        <TextField source="shortName" label="Short name" sortable={false} />
        <FunctionField
          label="Professions"
          render={(record) => {
            const listProfessions = take(record?.professions, 2) || [];
            const titleProfessions = drop(record?.professions, 2) || [];
            const renderProfessions = (professions) => professions.map((p, index) => `${p.profession.name}${index !== professions.length - 1 ? ', ' : ''}`);

            return (
              <div>
                {renderProfessions(listProfessions)}
                {size(record?.professions) > 2 ? (
                  <Tooltip title={renderProfessions(titleProfessions)} placement="bottom">
                    <span className={classes.addIcon}>+{size(record?.professions) - 2}</span>
                  </Tooltip>
                ) : (
                  ''
                )}
              </div>
            );
          }}
        />
        <TextField label="Published production count" source="stats.publishedProductionCount" sortBy="published_production_count" />
        <TextField label="Validation status" source="validationStatus.name" />
        <TextField label="Is Good Cast" source="isGoodCast" />
        <CustomDateField source="createdAt" />
      </Datagrid>
    </List>
  );
};

export default ProfilesList;
