import React from 'react';

import {
  List,
  Filter,
  TextInput,
  Datagrid,
  TextField,
  Tab,
  TabbedShowLayout,
  Create,
  SimpleForm,
  required,
  ImageField,
  ImageInput,
  email,
  ArrayInput,
  SelectInput,
  SimpleFormIterator,
  Edit,
  DateField,
  ArrayField,
  ReferenceField,
  UrlField,
} from 'react-admin';

import CountryAndCityInput from '../inputs/CountryAndCityInput';
import ValidityShow from '../components/ValidityShow';
import { urlValidation } from '../constants/validation';
import { CreateActions } from '../components/DefaultActions';
import { VALIDATION_STATUSES } from '../constants/validations';
import { Breadcrumbs } from '../components/Breadcrumbs';

const ReviewFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Review ID" source="id" alwaysOn />
    <TextInput label="Search" source="query" alwaysOn />
    <SelectInput label="Validation status" source="validation_status" choices={VALIDATION_STATUSES} alwaysOn />
  </Filter>
);

const ReferenceMediaEntity = (props) => {
  const { record } = props;
  switch (record?.entityType) {
    case 'profiles':
      return (
        <ReferenceField linkType="show" source="entityId" label="Profile" reference="profiles" {...props}>
          <TextField source="name" emptyText="Empty" />
        </ReferenceField>
      );
    case 'works':
      return (
        <ReferenceField linkType="show" source="entityId" label="Work" reference="works" {...props}>
          <TextField source="name" emptyText="Empty" />
        </ReferenceField>
      );
    default:
      return <div>Unsupported Entity Type</div>;
  }
};

export const ReviewShow = (props) => (
  <ValidityShow {...props} title={<Breadcrumbs {...props} />} hideValidity>
    <TabbedShowLayout>
      <Tab label="Review Info">
        <TextField label="Author" source="signature" />
        <DateField label="Created" source="createdAt" />
        <DateField label="Reviewed" source="reviewedAt" />
        <TextField source="house" emptyText="Empty" />
        <TextField source="title" emptyText="Empty" />
        <TextField source="content" emptyText="Empty" />
        <UrlField source="url" emptyText="Empty" />

        <ArrayField source="reviewEntities">
          <Datagrid>
            <TextField source="id" label="Id" />
            <TextField source="tagType" label="Type" />
            <TextField source="status.name" />
            <TextField source="entityType" />

            <ReferenceMediaEntity label="Entity" />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </ValidityShow>
);

export const ReviewList = (props) => (
  <List
    {...props}
    exporter={false}
    filters={<ReviewFilter />}
    title={<Breadcrumbs {...props} />}
    sort={{ field: 'id', order: 'DESC' }}
    bulkActionButtons={false}
    perPage={25}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="house" emptyText="Empty" />
      <TextField label="Author" source="signature" />
      <DateField label="Created" source="createdAt" />
      <DateField label="Reviewed" source="reviewedAt" />
      <TextField source="content" emptyText="Empty" />
    </Datagrid>
  </List>
);

const ReviewForm = () => (
  <>
    <TextInput source="name" validate={required()} label="Name" />
    <ImageInput source="logo" label="Logo image" accept="image/*">
      <ImageField source="medium" title="title" />
    </ImageInput>
    <CountryAndCityInput />
    <TextInput multiline source="address" label="Address" />
    <div>
      <TextInput source="url" validate={urlValidation} label="Website" />
    </div>
    <div>
      <TextInput source="email" validate={email('Wrong email')} label="Email" />
    </div>
    <ArrayInput source="phones">
      <SimpleFormIterator>
        <TextInput label="Phone" />
      </SimpleFormIterator>
    </ArrayInput>
  </>
);

export const ReviewCreate = (props) => (
  <Create title="Create Review" actions={<CreateActions />} {...props}>
    <SimpleForm submitOnEnter={false}>
      <ReviewForm />
    </SimpleForm>
  </Create>
);

export const ReviewEdit = (props) => (
  <Edit title={<Breadcrumbs {...props} />} {...props}>
    <SimpleForm submitOnEnter={false}>
      <ReviewForm />
    </SimpleForm>
  </Edit>
);
export default ReviewList;
