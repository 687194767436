import React from 'react';

import { Show, List, Filter, TextInput, Datagrid, TextField, SimpleShowLayout, FunctionField, BooleanField, DeleteWithConfirmButton } from 'react-admin';
import { paymentWalletsDescriptions } from '../utils/users';
import CustomDateField from '../components/customFields/CustomDateField';

const UserPaymentMethodsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="query" alwaysOn />
  </Filter>
);

export const UserPaymentMethodShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="ID" />
      <FunctionField label="Method Type" render={paymentWalletsDescriptions} />
      <TextField source="payment_method_reference" label="Value" />
      <TextField source="status" label="Status" />
      <BooleanField source="is_default" label="Is default method" />
      <CustomDateField label="Added at" source="created_at" timeHidden />
      <FunctionField label="Expiration" render={(pm) => (pm.expiration_month || pm.expiration_year ? `${pm.expiration_month} / ${pm.expiration_year}` : 'NA')} />
      <FunctionField label="Remove" render={(pm) => (pm ? <DeleteWithConfirmButton record={pm} undoable={false} redirect={false} /> : null)} />
    </SimpleShowLayout>
  </Show>
);

export const UserPaymentMethodsList = (props) => (
  <List exporter={false} filters={<UserPaymentMethodsFilter />} bulkActionButtons={false} {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" label="ID" />
      <FunctionField label="Method Type" render={paymentWalletsDescriptions} />
      <TextField source="payment_method_reference" label="Value" />
      <TextField source="status" label="Status" />
      <BooleanField source="is_default" label="Is default method" />
      <CustomDateField label="Added at" source="created_at" timeHidden />
      <FunctionField label="Expiration" render={(pm) => (pm.expiration_month || pm.expiration_year ? `${pm.expiration_month} / ${pm.expiration_year}` : 'NA')} />
      <FunctionField label="Remove" render={(pm) => (pm ? <DeleteWithConfirmButton record={pm} undoable={false} redirect={false} /> : null)} />
    </Datagrid>
  </List>
);

export default UserPaymentMethodsList;
