import { useEffect, useState } from 'react';
import { usePermissions } from 'react-admin';

export default function useUserPermissions() {
  const { permissions } = usePermissions();

  const defaultUserPermissions = {
    isAdmin: false,
  };
  const [userPermissions, setUserPermission] = useState(defaultUserPermissions);

  useEffect(() => {
    const isArray = Array.isArray(permissions);
    const isApplicationAdmin = isArray && permissions.includes('applications.admin');
    const isFrontbaseAdmin = isArray && permissions.includes('applications.frontbase.admin');

    const newUserPermissions = {
      isAdmin: isApplicationAdmin && isFrontbaseAdmin,
      permissions
    };

    setUserPermission(newUserPermissions);
  }, [permissions]);

  return userPermissions;
}
