import React, { useState, useMemo } from 'react';
import { ResponsiveContainer, CartesianGrid, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, LabelList } from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import CustomTooltip from './CustomTooltip';
import colors from '../../constants/colors';
import { getMaxValue } from '../../utils/dashboard';

const useStyles = makeStyles(() => ({
  barChart: {
    '& .recharts-legend-item': {
      cursor: 'pointer',
      fontSize: 14,
    },
    '& .recharts-bar-rectangle': {
      cursor: 'pointer',
    },
    '& .recharts-label-list': {
      fontSize: 12,
    },
  },
}));

export default function CustomBarChart({ title, dataKeys, data, dataColors, hideCount, onClick, height, ...props }) {
  const classes = useStyles();
  const [barProps, setBarProps] = useState(
    dataKeys.reduce((result, dataKey) => {
      // eslint-disable-next-line no-param-reassign
      result[dataKey.key] = false;
      return result;
    }),
  );
  const leftMargin = useMemo(() => {
    const maxValue = getMaxValue(data, dataKeys);
    switch (true) {
      case maxValue > 100000:
        return 0;
      case maxValue > 1000:
        return -20;
      default:
        return -30;
    }
  }, [data, dataKeys]);

  const onClickBar = (chartData) => {
    chartData.stopPropagation();
    chartData.preventDefault();
    setBarProps({
      ...barProps,
      [chartData.dataKey]: !barProps[chartData.dataKey],
    });
  };

  return (
    <div>
      {title && <h3>{title}</h3>}
      <ResponsiveContainer width="100%" height={height ?? 270} className={classes.barChart}>
        <BarChart stackOffset="sign" data={data} margin={{ top: 5, left: leftMargin, ...props.margin }} onClick={onClick}>
          <CartesianGrid />
          <XAxis dataKey="name" tick={{ fontSize: '12px', width: 300, height: 150, wordBreak: 'break-word' }} />
          <YAxis domain={[(dataMin) => 0 - Math.abs(dataMin), (dataMax) => Math.ceil(dataMax * 1.3)]} tick={{ fontSize: '12px', width: 300, height: 150, wordBreak: 'break-word' }} />
          <Tooltip content={<CustomTooltip />} cursor={{ fill: 'transparent' }} />
          <Legend iconSize="10" onClick={onClickBar} />
          {dataKeys.map((dataKey, index) => (
            <Bar name={dataKey.name} dataKey={dataKey.key} fill={dataColors?.[dataKey.name] || colors[index]} key={index} stackId="name" hide={barProps[dataKey.key]}>
              {index === dataKeys.length - 1 && !hideCount ? <LabelList position="top" valueAccessor={(entry) => (entry?.value[1] > 0 ? entry?.value[1] : null)} /> : null}
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
