import React, { useState } from 'react';
import { useGetList, useGetOne, useNotify, useMutation, useDataProvider } from 'react-admin';
import { Link, Button, Dialog, TextField, LinearProgress, Card, Typography, Grid, makeStyles } from '@material-ui/core';
import { MuiThemeProvider } from 'material-ui/styles';

const useStyles = makeStyles(() => ({
  setGeonameIdCard: {
    padding: '0.5rem',
  },
}));

const CityValidationActions = function ({ entity }) {
  const { data, loading } = useGetList('countries/cities/altnames', { page: 1, perPage: 10 }, { field: 'id' }, { query: entity?.diff?.name });
  const { data: city, loading: loadingCity } = useGetOne('countries/cities', entity?.id);
  const [mutateGeonameId, { loading: loadingMutateGeonameId }] = useMutation();
  const notify = useNotify();
  const classes = useStyles();

  const setGeonameId = (geonameId) =>
    mutateGeonameId(
      {
        type: 'update',
        resource: 'countries/cities',
        payload: { id: city.id, data: { geoname_id: geonameId } },
      },
      {
        onSuccess: () => notify('Status updated', 'info'),
        onFailure: (err) => notify(err.response?.data?.message?.message || err.message, 'error'),
      },
    );

  return (
    <>
      {`${city?.name} (country id: ${city?.country?.id})`}
      <br />
      <br />

      <MuiThemeProvider>
        {loading || loadingCity || loadingMutateGeonameId ? <LinearProgress /> : null}

        {city && (
          <Card className={classes.setGeonameIdCard}>
            <Typography variant="h5" component="h2">
              Assign geoname ID
            </Typography>
            {city.geoname_id && (
              <Link href={`https://www.geonames.org/${city.geoname_id}`} target="_blank">
                Assigned as #{city.geoname_id}
              </Link>
            )}
            {!city.geoname_id && (
              <Typography variant="p" component="p">
                <Link href={`http://www.geonames.org/search.html?q=${city.name}`} target="_blank">
                  geonames db
                </Link>
              </Typography>
            )}
            {data.length &&
              data.map((item) => (
                <Button onClick={() => setGeonameId(item?.geoname_id)}>
                  #{item?.geoname_id} {item?.name}
                </Button>
              ))}
            {!data.length && !city.geoname_id && (
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  const data = new FormData(event.target);
                  let geonameId = data.get('geoname_id_input');
                  if (geonameId) {
                    setGeonameId(geonameId);
                  }
                }}
              >
                <TextField type="number" label="Geoname ID" name="geoname_id_input" />
                <Button type="submit">Set</Button>
              </form>
            )}
            <br />
            <Typography variant="h5" component="h2">
              Or
            </Typography>
            <MergeCity city={city} />
            <br />
          </Card>
        )}

        {!loading && !city && <Typography variant="h5">Deleted or merged</Typography>}
      </MuiThemeProvider>
    </>
  );
};

const MergeCity = (props) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [targetCityId, setTargetCityId] = useState(null);
  const notify = useNotify();
  const handleClick = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleConfirm = () => {
    dataProvider
      .create('validations/merge', { data: { entityType: 'cities', sourceId: props?.city?.id, targetId: targetCityId } })
      .then(() => {
        setIsDialogOpen(false);
        window.location.href = `#/countries/cities/${targetCityId}/show`;
      })
      .catch((err) => {
        notify(err);
      });
  };

  return (
    <>
      <Button onClick={handleClick} variant="outlined" tabIndex={0} onKeyPress={() => {}}>
        Merge into
      </Button>
      <Dialog
        PaperProps={{
          style: { padding: '25px 20px', width: '20rem' },
        }}
        onClose={handleDialogClose}
        aria-labelledby="simple-dialog-title"
        open={isDialogOpen}
      >
        <div>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs={3}>
              <Typography variant="subtitle2" gutterBottom>
                Merge geoname
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body1" gutterBottom>
                {props.city?.name}
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" className={classes.withMargin}>
            <Grid item xs={3}>
              <Typography variant="subtitle2" gutterBottom>
                Into
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField label="DB ID of existing city" onChange={(event) => setTargetCityId(event.target.value)} />
              {targetCityId?.id === props?.city?.id && (
                <Typography variant="body2" gutterBottom color="error">
                  Target city id should not be the same
                </Typography>
              )}
            </Grid>
          </Grid>
          <div className={classes.actions}>
            <Button className={classes.spacing} variant="contained" onClick={handleDialogClose}>
              Cancel
            </Button>
            <Button disabled={!targetCityId || targetCityId?.id === props?.city?.id} variant="contained" color="primary" onClick={handleConfirm}>
              Confirm
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default CityValidationActions;
