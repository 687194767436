import * as React from 'react';
import {
    isValidElement,
    Children,
    cloneElement,
    useCallback
} from 'react';
import PropTypes from 'prop-types';
import {
    Checkbox,
    Table,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import classnames from 'classnames';

import {
    sanitizeListRestProps,
    useListContext,
    useVersion,
    DatagridHeaderCell,
    DatagridLoading,
    DatagridBody,
    useDatagridStyles,
    FieldTitle
} from 'react-admin';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    headerCell: {
        borderLeft: '1px solid grey',
        borderRight: '1px solid grey'
    },
    leftRadius: {
        borderTopLeftRadius: '4px'
    },
    rightRadius: {
        borderTopRightRadius: '4px'
    }
});

/**
 * Datagrid with subheader support
 * Based on react-admin original Datagrid element
 */
const SubHeaderDatagrid = props => {
    const classes = useDatagridStyles(props);
    const subHeaderClasses = useStyles();
    const {
        body = <DatagridBody />,
        children,
        subheaderMappings,
        classes: classesOverride,
        className,
        expand,
        hasBulkActions = false,
        hover,
        isRowSelectable,
        rowClick,
        rowStyle,
        size = 'small',
        ...rest
    } = props;

    const {
        basePath,
        currentSort,
        data,
        ids,
        loaded,
        onSelect,
        onToggleItem,
        resource,
        selectedIds,
        setSort,
        total,
    } = useListContext(props);
    const version = useVersion();

    const updateSort = useCallback(
        event => {
            event.stopPropagation();
            const newField = event.currentTarget.dataset.field;
            const newOrder =
                currentSort.field === newField
                    ? currentSort.order === 'ASC'
                        ? 'DESC'
                        : 'ASC'
                    : event.currentTarget.dataset.order;

            setSort(newField, newOrder);
        },
        [currentSort.field, currentSort.order, setSort]
    );

    const handleSelectAll = useCallback(
        event => {
            if (event.target.checked) {
                const all = ids.concat(
                    selectedIds.filter(id => !ids.includes(id))
                );
                onSelect(
                    isRowSelectable
                        ? all.filter(id => isRowSelectable(data[id]))
                        : all
                );
            } else {
                onSelect([]);
            }
        },
        [data, ids, onSelect, isRowSelectable, selectedIds]
    );

    /**
     * if loaded is false, the list displays for the first time, and the dataProvider hasn't answered yet
     * if loaded is true, the data for the list has at least been returned once by the dataProvider
     * if loaded is undefined, the Datagrid parent doesn't track loading state (e.g. ReferenceArrayField)
     */
    if (loaded === false) {
        return (
            <DatagridLoading
                classes={classes}
                className={className}
                expand={expand}
                hasBulkActions={hasBulkActions}
                nbChildren={React.Children.count(children)}
                size={size}
            />
        );
    }

    /**
     * Once loaded, the data for the list may be empty. Instead of
     * displaying the table header with zero data rows,
     * the datagrid displays nothing in this case.
     */
    if (loaded && (ids.length === 0 || total === 0)) {
        return null;
    }

    const all = isRowSelectable
        ? ids.filter(id => isRowSelectable(data[id]))
        : ids;

    const allSubheaderFields = subheaderMappings ? subheaderMappings.map(item => item.fields).flat() : null;
    const childrenArray = Array.from(Children.map(children, (field, index) =>
        isValidElement(field) ? (
            <DatagridHeaderCell
                className={classnames(classes.headerCell, subHeaderClasses.headerCell)}
                currentSort={currentSort}
                field={field}
                isSorting={
                    currentSort.field ===
                    (field.props.sortBy || field.props.source)
                }
                key={field.props.source || index}
                resource={resource}
                updateSort={updateSort}
            />
        ) : null
    )).filter(Boolean);

    /**
     * After the initial load, if the data for the list isn't empty,
     * and even if the data is refreshing (e.g. after a filter change),
     * the datagrid displays the current data.
     */
    return (
        <Table
            className={classnames(classes.table, className)}
            size={size}
            {...sanitizeListRestProps(rest)}
        >
            <TableHead className={classes.thead}>
                <TableRow
                    className={classnames(classes.row, classes.headerRow)}
                >
                    {subheaderMappings && subheaderMappings.length
                        ? subheaderMappings.map((mapping, mappingIndex) => mapping.fields.map((field, fieldIndex) => (
                            <TableCell
                                className={classnames(
                                    className,
                                    mapping.headerClassName,
                                    mappingIndex === 0 && fieldIndex === 0
                                        ? subHeaderClasses.leftRadius
                                        : mappingIndex === subheaderMappings.length - 1 && fieldIndex === mapping.fields.length - 1
                                            ? subHeaderClasses.rightRadius
                                            : null
                                )}
                                align={mapping.textAlign}
                                variant="head"
                                {...rest}
                            >
                                {fieldIndex === 0
                                    ? <FieldTitle
                                        label={mapping.label}
                                        resource={resource}
                                    />
                                    : null}
                            </TableCell >
                        )))
                        : null
                    }

                </TableRow>
                <TableRow
                    className={classnames(classes.row, classes.headerRow)}
                >
                    {expand && (
                        <TableCell
                            padding="none"
                            className={classnames(
                                classes.headerCell,
                                classes.expandHeader
                            )}
                        />
                    )}
                    {hasBulkActions && (
                        <TableCell
                            padding="checkbox"
                            className={classes.headerCell}
                        >
                            <Checkbox
                                className="select-all"
                                color="primary"
                                checked={
                                    selectedIds.length > 0 &&
                                    all.length > 0 &&
                                    all.every(id => selectedIds.includes(id))
                                }
                                onChange={handleSelectAll}
                            />
                        </TableCell>
                    )}

                    {allSubheaderFields
                        ? childrenArray.map(item => allSubheaderFields.includes(item.props.field.props.source) ? item : null)
                        : childrenArray
                    }
                </TableRow>
            </TableHead>
            {cloneElement(
                body,
                {
                    basePath,
                    className: classes.tbody,
                    classes,
                    expand,
                    rowClick,
                    data,
                    hasBulkActions,
                    hover,
                    ids,
                    onToggleItem,
                    resource,
                    rowStyle,
                    selectedIds,
                    isRowSelectable,
                    version,
                },
                allSubheaderFields
                    ? children.map(item => allSubheaderFields.includes(item.props.source) ? item : null)
                    : children
            )}
        </Table>
    );
};


SubHeaderDatagrid.propTypes = {
    basePath: PropTypes.string,
    body: PropTypes.element,
    children: PropTypes.node.isRequired,
    subheaderMappings: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            textAlign: PropTypes.string,
            headerClassName: PropTypes.string,
            fields: PropTypes.arrayOf(PropTypes.string)
        })
    ),
    classes: PropTypes.object,
    className: PropTypes.string,
    currentSort: PropTypes.shape({
        field: PropTypes.string,
        order: PropTypes.string,
    }),
    data: PropTypes.object,
    expand: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
    hasBulkActions: PropTypes.bool,
    hover: PropTypes.bool,
    ids: PropTypes.arrayOf(PropTypes.any),
    loading: PropTypes.bool,
    onSelect: PropTypes.func,
    onToggleItem: PropTypes.func,
    resource: PropTypes.string,
    rowClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    rowStyle: PropTypes.func,
    selectedIds: PropTypes.arrayOf(PropTypes.any),
    setSort: PropTypes.func,
    total: PropTypes.number,
    version: PropTypes.number,
    isRowSelectable: PropTypes.func,
};

export default SubHeaderDatagrid;
