import React from 'react';
import { TableCell } from '@material-ui/core';
import apiClient from '../../utils/api';
import { cloneDiff } from './utils';
import DisplayTable from './DisplayTable';
import DisplayMedia from './DisplayMedia';


const getData = async (productionId) => {
  const data = (await apiClient.get(`productions/${productionId}/media`, {
    params: {
      media_type_id: 2,
      show_invalid: true,
      page: 1,
      limit: 1000,
    },
  }))?.data?.data;

  return data;
};

export const parseProductionVideos = props => {
  const { production, changeRequest } = props;

  return new Promise(async (resolve, reject) => {
    const a = changeRequest ? await getData(production.id) : [];
    const b = changeRequest ? await getData(changeRequest.revisionId) : await getData(production.id);

    const data = cloneDiff(a, b).filter((i) => !isNaN(parseInt(i.path)));

    resolve(data);
  });
};

export const MediaRow = (props) => {
  return (
    <>
      <TableCell>{props.changes.id}</TableCell>
      <TableCell>
        <DisplayMedia path="" changes={props.changes} />
      </TableCell>
    </>
  );
};

export const ProductionVideos = (props) => {
  return (
    <div>
      <DisplayTable {...props} changes={props.changes}>
          <MediaRow />
        </DisplayTable>
    </div>
  );
}
