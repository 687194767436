import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import { Button } from '@material-ui/core';
import { useDataProvider, useRedirect, ReferenceManyField, SingleFieldList, FunctionField } from 'react-admin';
import { MergeType } from '@material-ui/icons';

import EntityLink from './EntityLink';
import { DB_VIEWS_DUPLICATE_ENTITY_CONFIG } from '../constants/dbviews';

const useStyles = makeStyles(() => ({
  container: {
    background: '#FFFAAA',
    borderRadius: 8,
    padding: 8,
    fontSize: 14,
    '& ul': {
      listStyle: 'none',
      margin: '8px 0',
      padding: 0,
    },
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 0',
  },
}));

export default function EntityDuplicates(props) {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const { record } = props;
  const entity = record?.entity;
  const entityId = entity?.id ?? record?.id;
  const entityType = entity?.entityType ?? props?.resource;
  const duplicateEntityConfig = DB_VIEWS_DUPLICATE_ENTITY_CONFIG?.[entityType];

  const [loading, setLoading] = useState(true);
  const [duplicateIds, setDuplicateIds] = useState([]);

  useEffect(() => {
    setLoading(true);

    if (duplicateEntityConfig) {
      dataProvider
        .getList('metrics/dbviews', {
          filter: { view_id: duplicateEntityConfig?.viewId, [duplicateEntityConfig?.dataKey]: entityId },
          pagination: { perPage: 10, page: 1 },
          sort: { field: 'id', order: 'DESC' },
        })
        .then((res) => {
          setLoading(false);
          const ids = get(res, `data.[0].${duplicateEntityConfig?.dataKey}`, []).filter((id) => id !== entityId);
          setDuplicateIds(ids);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProvider, entityId]);

  if (loading) {
    return (
      <div className={classes.container}>
        <div className={classes.title}>Possible duplicates</div>
        <div>Loading...</div>
      </div>
    );
  }

  if (!duplicateIds.length) return null;

  return (
    <div className={classes.container}>
      <div className={classes.title}>Possible duplicates</div>
      <ul>
        {duplicateIds.map((id) => (
          <li className={classes.listItem}>
            <ReferenceManyField {...props} record={{ id }} page={1} sort={{}} perPage={1} reference={duplicateEntityConfig?.reference} source="id" target="id" allowEmpty>
              <SingleFieldList>
                <FunctionField render={(data) => <EntityLink entityType={entityType} entity={data} showType="true" />} />
              </SingleFieldList>
            </ReferenceManyField>
            <Button onClick={() => redirect(`/validations/merge/create?entity_type=${entityType}&source_id=${entityId}&target_id=${id}`)}>
              <MergeType />
            </Button>
          </li>
        ))}
      </ul>
    </div>
  );
}
