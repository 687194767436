import React from 'react';
import { Button, Dialog as MUIDialog, DialogTitle as MUIDialogTitle, DialogContent as MUIDialogContent, DialogActions as MUIDialogActions, Typography } from '@material-ui/core';

import { Error as ErrorIcon, CheckCircle as CheckCircleIcon } from '@material-ui/icons';
import { withExtractFormData } from '../utils/forms';

const FormConfirmationDialog = (props) => {
  return (
    <MUIDialog open={!!props.open} onClose={props.onCancel}>
      <MUIDialogTitle>{props.title}</MUIDialogTitle>
      <MUIDialogContent>
        {props.content && (
          <Typography variant="body1" component="p">
            {props.content}
          </Typography>
        )}
        <form
          action="/"
          method="POST"
          id="dialog_form"
          onSubmit={withExtractFormData((data) => {
            if (props.onConfirm) {
              props.onConfirm(data);
            }
          })}
        >
          {props.children || ''}
        </form>
      </MUIDialogContent>
      <MUIDialogActions>
        <Button startIcon={<ErrorIcon />} onClick={props.onCancel}>
          Cancel
        </Button>
        <Button color="primary" startIcon={<CheckCircleIcon />} form="dialog_form" type="submit">
          Confirm
        </Button>
      </MUIDialogActions>
    </MUIDialog>
  );
};

export default FormConfirmationDialog;
