import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  useMutation,
  useDataProvider,
  Show,
  List,
  Filter,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Datagrid,
  TextField,
  SimpleShowLayout,
  FunctionField,
  TextInput,
  Confirm,
  ArrayField,
  SingleFieldList,
  UPDATE,
  useNotify,
  useRefresh,
  useUnselectAll,
  DateInput,
  DateField,
  AutocompleteInput,
} from 'react-admin';
import { Button, ButtonGroup, makeStyles, Typography, Paper, FormGroup, FormControlLabel, Checkbox, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import TextFieldMUI from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import { VALIDATION_STATUSES_IDS, ENTITY_VALIDATION_CATEGORY } from '../constants/validations';
import EntityLink from '../components/EntityLink';
import ReferenceFieldWithPlaceholder from '../components/ReferenceFieldWithPlaceholder';
import { UserName } from './ProfilesModel';
import EntityReferenceInfo, { entityErrorHandlers } from '../components/EntityReferenceInfo';
import EntityFrontbaseLink from '../components/EntityFrontbaseLink';
import CustomDateField from '../components/customFields/CustomDateField';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { cloneDiff } from '../components/productionValidation/utils';
import ValidationLockByUser from '../components/ValidationLockByUser';
import EntityDuplicates from '../components/EntityDuplicates';

const useStyles = makeStyles(() => ({
  root: {
    color: '#F44336',
    margin: '0.5rem 0rem',
    padding: '0.5rem',
  },
  entityLink: {
    marginLeft: '0.3rem',
    '&:visited': {
      color: 'unset',
      fontWeight: 'bolder',
    },
  },
  layout: {
    marginTop: 10,
    '& .MuiButtonGroup-root': {
      marginBottom: 10,
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonGroup: {
    display: 'flex',
  },
  lockedWrap: {
    marginLeft: '20px',
    marginBottom: '10px',
  },
  showLayout: {
    '& .MuiFormControl-marginDense': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      padding: '5px 20px',
    },

    '& label': {
      width: 400,
      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
      fontSize: 18,
      fontWeight: 'bold',
    },
  },
  validationStatus: {
    fontSize: 16,
    fontWeight: 'bold',
    border: '1px solid #ff5722',
    padding: 6,
    backgroundColor: '#ff5722',
    color: 'white',
  },
  entityType: {
    textTransform: 'capitalize',
    marginRight: 5,
  },
  action: { textTransform: 'capitalize' },
  tableLayout: {
    '& td:nth-child(2)': { minWidth: 80, wordBreak: 'break-word' },
    '& .MuiTableCell-sizeSmall': { padding: 6 },
  },
  entityDuplicates: {
    position: 'absolute',
    top: 70,
    right: 10,
  },
  entityTitle: {
    fontSize: 14,
  },
  dataGrid: {
    overflowX: 'scroll',
    width: '82vw',
  },
}));

const ValidationsFilters = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Validation status" source="status_id" reference="validations/statuses" alwaysOn>
      <SelectInput emptyText="All" source="status_id" optionText={(item) => item.name || `Empty (id: ${item.id})`} />
    </ReferenceInput>

    <ReferenceInput emptyText="All" label="Entity type(all)" source="entity_type" reference="validations/entities/types" alwaysOn>
      <SelectInput
        optionText={(record) => (
          <div>
            {record?.name} ({record?.count})
          </div>
        )}
      />
    </ReferenceInput>
    <ReferenceInput
      label="Who validated last"
      source="last_validated_by_user_id"
      reference="users"
      perPage={25}
      filter={{ group_id: 3 }}
      filterToQuery={(searchText) => ({ query: searchText })}
      alwaysOn
    >
      <AutocompleteInput optionText="email" />
    </ReferenceInput>
    <DateInput source="last_validated_at_from" label="When validated last from" alwaysOn />
    <DateInput source="last_validated_at_to" label="When validated last to" alwaysOn />
    <TextInput source="entity_id" />
    <TextInput source="id" />
  </Filter>
);

const validationActionDescription = (action) => {
  if (action?.includes('_created')) {
    return `Created (${action})`;
  }
  if (action?.includes('_updated')) {
    return `Updated (${action})`;
  }
  if (action?.includes('_deleted')) {
    return `Deleted (${action})`;
  }

  return action;
};

export const ValidationShow = (props) => {
  const classes = useStyles();

  return (
    <Show className={classes.layout} {...props} actions={<ValidationActions />}>
      <ValidationShowLayout />
    </Show>
  );
};

export const DiffView = ({ prev, curr }) => <pre>{JSON.stringify(cloneDiff(prev || {}, curr || {}), null, 4)}</pre>;

const ValidationShowLayout = (props) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [validation, setValidations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  useEffect(() => {
    dataProvider
      .getList('validations', {
        filter: { status_id: 1, entity_id: props?.record?.entity?.id, entity_type: props?.record?.entity?.entityType },
        pagination: {},
        sort: { field: 'id' },
      })
      .then(({ data }) => {
        setValidations(data.filter((val) => val?.id !== props?.record?.id));
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SimpleShowLayout className={classes.showLayout} {...props}>
        <FunctionField label="Action" render={(record) => <span className={classes.action}>{record?.action?.split('_').join(' ')}</span>} />
        <FunctionField
          label="Entity"
          render={(record) => (
            <div>
              <span className={classes.entityType}>{record?.entity.entityType}</span>
              <EntityLink entity={record?.entity} entityType={record.entity.entityType} />
            </div>
          )}
        />
        <FunctionField label="Operabase link" render={(record) => <EntityFrontbaseLink entity={record?.entity} entityType={record.entity.entityType} />} />
        <ReferenceFieldWithPlaceholder link="show" label="Invoker" emptyText="" source="activity.invoker.user.id" reference="users">
          <FunctionField render={(user) => (user ? <EntityLink entity={user} entityType="users" /> : 'None')} />
        </ReferenceFieldWithPlaceholder>
        <FunctionField
          label={props?.record?.updatedAt ? 'Updated at' : 'Created at'}
          render={(val) => (val?.updatedAt ? <CustomDateField record={val} source="updatedAt" /> : <CustomDateField record={val} source="createdAt" />)}
        />
        <ReferenceFieldWithPlaceholder link="show" source="updatedByUser.id" reference="users" label="Last updated by" emptyText="">
          <FunctionField render={(user) => `${user?.name} (id: ${user?.id})`} />
        </ReferenceFieldWithPlaceholder>
        <ArrayField source="reasons" label="Reasons">
          <SingleFieldList linkType={false}>
            <TextField source="reason" />
          </SingleFieldList>
        </ArrayField>
        <FunctionField
          label="Submitted data"
          render={(record) => (
            <details>
              <DiffView curr={record?.activity?.entityDiff} prev={record?.activity?.previousVersion} />
            </details>
          )}
        />
        <EntityReferenceInfo />
        <div className={classes.entityDuplicates}>
          <EntityDuplicates {...props} />
        </div>
      </SimpleShowLayout>
      {error && <h4 style={{ marginLeft: 15 }}>Error searching for validations: {error?.message}</h4>}
      {loading && <h4 style={{ marginLeft: 15 }}>Searching for similar validations...</h4>}
      {validation.length > 0 && <h4 style={{ marginLeft: 15 }}>Other pending validations ({validation.length}) for the same entity:</h4>}
      {validation.map((v) => (
        <div key={`valid_${v?.id}`} style={{ marginTop: 20, borderTop: '1px solid gray' }}>
          <SimpleShowLayout className={classes.showLayout} {...{ ...props, record: v }}>
            <FunctionField
              label="Validation id"
              render={(record) => (
                <Link style={{ color: '#ff5722' }} to={`/validations/${record.id}/show`} target="_blank">
                  {record.id}
                </Link>
              )}
            />
            <TextField source="status.name" label="Validation status" />
            <FunctionField label="Action" render={(record) => <span className={classes.action}>{record?.action?.split('_').join(' ')}</span>} />
            <FunctionField
              label="Entity"
              render={(record) => (
                <div>
                  <span className={classes.entityType}>{record?.entity.entityType}</span>
                  <EntityLink entity={record?.entity} entityType={record.entity.entityType} />
                </div>
              )}
            />
            <FunctionField label="Operabase link" render={(record) => <EntityFrontbaseLink entity={record?.entity} entityType={record.entity.entityType} />} />
            <ReferenceFieldWithPlaceholder link="show" label="Invoker" emptyText="" source="activity.invoker.user.id" reference="users">
              <FunctionField render={(user) => (user ? <EntityLink entity={user} entityType="users" /> : 'None')} />
            </ReferenceFieldWithPlaceholder>
            <FunctionField
              label={props?.record?.updatedAt ? 'Updated at' : 'Created at'}
              render={(val) => (val?.updatedAt ? <CustomDateField record={val} source="updatedAt" /> : <CustomDateField record={val} source="createdAt" />)}
            />
            <ReferenceFieldWithPlaceholder link="show" source="updatedByUser.id" reference="users" label="Last updated by" emptyText="">
              <FunctionField render={(user) => `${user?.name} (id: ${user?.id})`} />
            </ReferenceFieldWithPlaceholder>
            <ArrayField source="reasons" label="Reasons">
              <SingleFieldList linkType={false}>
                <TextField source="reason" />
              </SingleFieldList>
            </ArrayField>
            <FunctionField
              label="Submitted data"
              render={(record) => (
                <details>
                  <DiffView curr={record?.activity?.entityDiff} prev={record?.activity?.previousVersion} />
                </details>
              )}
            />
          </SimpleShowLayout>
        </div>
      ))}
    </>
  );
};

const ValidateValidationButtons = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll();
  const { selectedIds } = props;

  const validate = (statusId) => {
    Promise.all(
      selectedIds.map(async (id) => {
        const data = await dataProvider(UPDATE, 'validations', { id, data: { status: { id: statusId } } });
        return data;
      }),
    ).then(
      (responses) => {
        Promise.resolve(responses);
        unselectAll(props.resource);
        refresh();
      },
      (error) => {
        refresh();
        notify(error.message);
        unselectAll(props.resource);
        Promise.reject(error);
      },
    );
  };

  return (
    <ButtonGroup>
      <Button onClick={() => validate(VALIDATION_STATUSES_IDS.PENDING)}>Postpone</Button>
      <Button onClick={() => validate(VALIDATION_STATUSES_IDS.PROCESSING)}>Mark as processing</Button>
      <Button onClick={() => validate(VALIDATION_STATUSES_IDS.VALID)}>Approve</Button>
      <Button onClick={() => validate(VALIDATION_STATUSES_IDS.PRIVATE)}>Approve (Hide)</Button>
      <Button onClick={() => validate(VALIDATION_STATUSES_IDS.INVALID)}>Reject</Button>
    </ButtonGroup>
  );
};

const ValidationsBulkActionButtons = (props) => <ValidateValidationButtons {...props} />;

export const ValidationsList = (props) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [allValidationLocks, setAllValidationLocks] = useState([]);
  const allData = useSelector((store) => store.admin.resources.validations)?.data;
  const allIds = useMemo(() => Object.keys(allData), [allData]);

  useEffect(() => {
    if (allIds.length) {
      dataProvider
        .getList('validations/locks', {
          filter: { lockIdentifier: allIds.join(','), lockGroup: 'entity-queue', validation_status: '' },
          pagination: {},
          sort: {},
        })
        .then(({ data }) => {
          setAllValidationLocks(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allIds]);

  const showLockedUser = useCallback(
    (data) => {
      if (allValidationLocks.length) {
        const hasValidationLock = allValidationLocks.find((validationLock) => Number(validationLock.lockIdentifier) === Number(data.id));
        return `${hasValidationLock?.user?.email || 'Not locked'}`;
      }
      return 'Not locked';
    },
    [allValidationLocks],
  );
  return (
    <div className={classes.dataGrid}>
      <List
        title={<Breadcrumbs {...props} />}
        filters={<ValidationsFilters />}
        exporter={false}
        filterDefaultValues={{ status_id: VALIDATION_STATUSES_IDS.PENDING }}
        bulkActionButtons={<ValidationsBulkActionButtons />}
        {...props}
        sort={{ field: 'id', order: 'DESC' }}
      >
        <Datagrid rowClick="show" className={classes.tableLayout}>
          <TextField source="id" />
          <TextField source="entity.entityType" label="Entity Type" sortable={false} />
          <FunctionField label="Action" render={(record) => validationActionDescription(record?.action)} sortable={false} />
          <FunctionField label="Entity" render={(record) => <EntityLink entity={record?.entity} entityType={record.entity.entityType} view="list" />} sortable={false} />
          <TextField label="Status" source="status.name" />
          <ReferenceField link="show" source="activity.invoker.user.id" reference="users" label="Who created" sortable={false}>
            <UserName />
          </ReferenceField>
          <CustomDateField source="createdAt" label="When created" sortable={false} timeHidden />
          <FunctionField label="Locked By" render={(record) => showLockedUser(record)} sortable={false} />
          <DateField source="lastValidatedAt" label="When validated last" timeHidden />
          <ReferenceField link="show" source="lastValidatedByUser.id" reference="users" label="Who validated last" sortable={false}>
            <UserName />
          </ReferenceField>
          <FunctionField label="Last updated by" render={(record) => record?.updateByUser?.id && <EntityLink entityType="users" entity={record?.updateByUser} />} />
        </Datagrid>
      </List>
    </div>
  );
};

export const ValidationActions = (props) => {
  const { data } = props;
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [validationError, setValidationError] = useState();
  const [isChecklistOpen, setIsChecklistOpen] = useState(false);
  const [isRejectConfirmOpen, setIsRejectConfirmOpen] = useState(false);
  const [reasonChecklist, setReasonChecklist] = useState([]);
  const [selectedReasons, setSelectReasons] = useState([]);
  const [otherReason, setOtherReason] = useState('');
  const [tags, setTags] = useState([]);
  const [tag, setTag] = useState('');
  const [profTranslationList, setProfTranslationList] = useState('');
  const [selectedAction, setSelectedAction] = useState('');
  const entity = data?.entity?.entityType;
  const professionEntityList = ['professions'];
  const isProfessionEntity = professionEntityList.includes(entity);
  const onFailure = (err) => {
    let validationErrorData = { message: err.message };
    if (entityErrorHandlers[data?.entity?.entityType]) {
      validationErrorData = entityErrorHandlers[data?.entity?.entityType](err, data) || validationErrorData;
    }
    setValidationError(validationErrorData);
  };

  const [makePending] = useMutation(
    {
      type: 'update',
      resource: 'validations',
      payload: { id: data?.id, data: { status: { id: VALIDATION_STATUSES_IDS.PENDING } } },
    },
    { onFailure },
  );
  const [makeProcessing] = useMutation(
    {
      type: 'update',
      resource: 'validations',
      payload: { id: data?.id, data: { status: { id: VALIDATION_STATUSES_IDS.PROCESSING } } },
    },
    { onFailure },
  );
  const [makeValidPublic] = useMutation(
    {
      type: 'update',
      resource: 'validations',
      payload: {
        id: data?.id,
        data: {
          status: { id: VALIDATION_STATUSES_IDS.VALID },
          reasons: [...selectedReasons.map((r) => ({ reason: r.reason })), ...(otherReason && [{ reason: otherReason }])],
        },
      },
    },
    { onFailure },
  );
  const [makeValidPrivate] = useMutation({
    type: 'update',
    resource: 'validations',
    payload: { id: data?.id, data: { status: { id: VALIDATION_STATUSES_IDS.PRIVATE } } },
  });
  const [makeInvalid] = useMutation(
    {
      type: 'update',
      resource: 'validations',
      payload: {
        id: data?.id,
        data: { status: { id: VALIDATION_STATUSES_IDS.INVALID }, reasons: [...(otherReason && [{ reason: otherReason }])] },
      },
    },
    { onFailure },
  );

  const [updateProfession] = useMutation({
    type: 'update',
    resource: 'professions',
    payload: {
      id: data?.entity?.id,
      data: { tags: [{ id: tag?.id }] },
    },
  });

  const handleConfirm = () => {
    setSelectedAction(VALIDATION_STATUSES_IDS.VALID);
    setIsChecklistOpen(true);
    setTag('');
  };

  const handleApproveHidden = () => {
    setSelectedAction(VALIDATION_STATUSES_IDS.PRIVATE);
    if (isProfessionEntity) {
      setIsChecklistOpen(true);
      setTag('');
    } else {
      makeValidPrivate();
    }
  };

  const handleReject = () => {
    setIsRejectConfirmOpen(true);
  };

  useEffect(() => {
    if (['works', 'worksRoles', 'venues', 'organizations'].includes(entity)) {
      dataProvider.getList(`validations/checklist`, { filter: { entity_type: entity }, pagination: {}, sort: {} }).then(({ data: checklist }) => {
        const filterChecklist = checklist.filter((c) => c.reason !== 'Other');
        setReasonChecklist(filterChecklist);
      });
    }

    if (isProfessionEntity) {
      dataProvider.getList('tags', { filter: { entity_type: entity, category: ENTITY_VALIDATION_CATEGORY[entity], validation_status: '' }, pagination: {}, sort: {} }).then(({ data: tagList }) => {
        setTags(tagList);
      });
      dataProvider
        .getList('professions/translations', { filter: { profession_id: data?.entity?.id }, pagination: {}, sort: {} })
        .then(({ data: professiontransList }) =>
          setProfTranslationList(professiontransList.reduce((result, translation) => `${result}${result?.length > 0 ? ', ' : ''}${translation?.language?.name}: "${translation?.name}"`, '')),
        );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProvider, entity]);

  const renderChecklist = () => (
    <FormGroup>
      {isProfessionEntity && (
        <div style={{ fontSize: 14 }}>
          <div style={{ fontWeight: 'bold' }}>Guidelines for professions in validations:</div>
          <ul style={{ marginTop: 0 }}>
            <li>If you want to change the name of the profession, go to profession translations</li>
            <li>If the profession is a translation, reject the profession and add it to translations in profession translations</li>
            <li>If the profession is rejected, go to the artist profile / management & staff to update the profession</li>
            {isProfessionEntity && (
              <li>
                Add translations and variants at least for EN, FR, DE, ES, IT, RU to be able to mark as Approved.
                <Link target="_blank" style={{ paddingLeft: '10px' }} to={`/professions/${data?.entity?.id}/1`} onClick={(e) => e.stopPropagation()}>
                  ADD TRANSLATION
                </Link>
              </li>
            )}
          </ul>
        </div>
      )}
      {reasonChecklist.map((c) => (
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => {
                if (e?.target?.checked) {
                  setSelectReasons([...selectedReasons, c]);
                } else {
                  setSelectReasons(selectedReasons.filter((r) => r.id !== c?.id));
                }
              }}
            />
          }
          label={c.reason}
        />
      ))}
      {isProfessionEntity && (
        <TextFieldMUI select label="Add tag" value={tag} onChange={(e) => setTag(e?.target?.value)} style={{ marginBottom: 15 }} variant="filled" size="small">
          {tags?.map((option) => (
            <MenuItem key={option.id} value={option}>
              {option.name}
            </MenuItem>
          ))}
        </TextFieldMUI>
      )}
      <TextFieldMUI value={otherReason} fullWidth label="Other (in this case a text box to tell us the reason)" onChange={(e) => setOtherReason(e?.target?.value)} />
    </FormGroup>
  );

  if (!data || !data?.id) {
    return null;
  }

  return (
    <>
      <div className={classes.header}>
        <div className={classes.buttonGroup}>
          <ButtonGroup>
            <Button onClick={makePending} disabled={data.status.id === VALIDATION_STATUSES_IDS.PENDING}>
              Postpone
            </Button>
            <Button onClick={makeProcessing} disabled={data.status.id === VALIDATION_STATUSES_IDS.PROCESSING}>
              Mark as processing
            </Button>
            <Button onClick={() => handleConfirm()} disabled={data.status.id === VALIDATION_STATUSES_IDS.VALID}>
              Approve
            </Button>
            <Button onClick={() => handleApproveHidden()} disabled={data.status.id === VALIDATION_STATUSES_IDS.PRIVATE}>
              Approve (Hide)
            </Button>
            <Button onClick={() => handleReject()} disabled={data.status.id === VALIDATION_STATUSES_IDS.INVALID}>
              Reject
            </Button>
          </ButtonGroup>
          <div className={classes.lockedWrap}>
            <ValidationLockByUser {...props} lockGroupType="entity-queue" />
          </div>
        </div>
        <div className={classes.validationStatus}>Status: {data?.status?.name}</div>
      </div>
      <Confirm
        isOpen={isChecklistOpen}
        title={
          <div>
            <div>Select reason</div>
            {data?.entity?.name && <div className={classes.entityTitle}>Entity: {data?.entity?.name}</div>}
            {profTranslationList?.length > 0 && <div className={classes.entityTitle}>Existing translation: {profTranslationList}</div>}
          </div>
        }
        content={renderChecklist()}
        onConfirm={() => {
          if (selectedAction === VALIDATION_STATUSES_IDS.VALID) {
            makeValidPublic();
          } else if (selectedAction === VALIDATION_STATUSES_IDS.PRIVATE) {
            makeValidPrivate();
          }

          if (isProfessionEntity) {
            updateProfession();
          }
          setIsChecklistOpen(false);
          setSelectReasons([]);
          setOtherReason('');
        }}
        onClose={() => {
          setIsChecklistOpen(false);
          setSelectReasons([]);
          setOtherReason('');
        }}
        loading={isProfessionEntity ? !otherReason || !tag : !selectedReasons.length && !otherReason}
      />
      <Confirm
        isOpen={isRejectConfirmOpen && !isProfessionEntity}
        title="Select reason"
        content={
          <FormGroup>
            <TextFieldMUI value={otherReason} fullWidth label="Tell us the reason" onChange={(e) => setOtherReason(e?.target?.value)} />
          </FormGroup>
        }
        onConfirm={() => {
          makeInvalid();
          setIsRejectConfirmOpen(false);
          setOtherReason('');
        }}
        onClose={() => {
          setIsRejectConfirmOpen(false);
          setOtherReason('');
        }}
        loading={!otherReason}
      />
      <Dialog open={isRejectConfirmOpen && isProfessionEntity}>
        <DialogTitle>Delete or keep as a variant?</DialogTitle>
        <DialogContent dividers>
          <div>Consider if it will be usefull to add this as a varian tto approved profession to avoid future entries by our users?</div>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              makeInvalid();
              setIsRejectConfirmOpen(false);
              setOtherReason('');
            }}
          >
            Reject (delete)
          </Button>
          <Button
            onClick={() => {
              setIsRejectConfirmOpen(false);
              setOtherReason('');
              window.open(`/#/validations/merge/create?entity_type=${data?.entity?.entityType}&source_id=${data?.entity?.id}`, '_blank');
            }}
          >
            Merge (keep as a variant)
          </Button>
        </DialogActions>
      </Dialog>
      {validationError && (
        <Paper className={classes.root} elevation={1}>
          <Typography component="span">{validationError.message || 'Validation error'}</Typography>
          {validationError.entityType && validationError.entityId && (
            <Link className={classes.entityLink} to={`/${validationError.entityType}/${validationError.entityId}/edit`}>
              here
            </Link>
          )}
        </Paper>
      )}
    </>
  );
};

export default ValidationsList;
