import React from 'react';
import { Show, List, TabbedShowLayout, Filter, TextInput, Datagrid, TextField, RichTextField, Tab, ReferenceManyField } from 'react-admin';
import CustomDateField from '../components/customFields/CustomDateField';
import { Breadcrumbs } from '../components/Breadcrumbs';

const MailsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="query" alwaysOn />
  </Filter>
);

export const MailsShow = (props) => (
  <Show {...props} title={<Breadcrumbs {...props} />}>
    <TabbedShowLayout>
      <Tab label="Mail info">
        <TextField source="name" />
        <CustomDateField source="createdAt" />
        <CustomDateField source="updatedAt" />
        <TextField source="type" label="Type" />
        <TextField source="recipientEmail" label="E-mail" />
        <TextField source="subject" label="Subject" />

        <TextField source="latestLog.status.name" label="Latest status" emptyText="(Unknown)" />
        <TextField source="latestLog.client_data" label="Client metadata" emptyText="No metadata" />

        <RichTextField source="content" label="Body" />
      </Tab>
      <Tab label="Log">
        <ReferenceManyField label="Mail logs" reference="mails/logs" target="mail_id">
          <Datagrid>
            <TextField source="id" />
            <TextField source="createdAt" label="Date" />
            <TextField source="status.name" label="Status" emptyText="(Unknown)" />
            <TextField source="client_data" label="Client metadata" emptyText="No metadata" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export const MailsList = (props) => (
  <List exporter={false} {...props} title={<Breadcrumbs {...props} />} filters={<MailsFilter />} bulkActionButtons={false} perPage={25}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="type" label="Type" />
      <TextField source="recipientEmail" label="E-mail" />
      <TextField source="subject" label="Subject" />
      <TextField source="createdAt" label="Date sent" />
      <TextField source="latestLog.status.name" label="Latest status" emptyText="(Unknown)" />
    </Datagrid>
  </List>
);

export default MailsList;
