import React from 'react';
import { TableCell } from '@material-ui/core';
import DisplayText from './DisplayText';
import DisplayBoolean from './DisplayBoolean';
import DisplayTable from './DisplayTable';
import { cloneDiff } from './utils';

export const parseProductionInformation = (props) => {
  const { production, changeRequest } = props;

  return new Promise((resolve) => {
    const a = changeRequest ? production : {};
    const b = changeRequest ? props.productionRevision : production;

    const productionChanges = cloneDiff(applyProperties(a), applyProperties(b));

    resolve(productionChanges);
  });
};

const applyProperties = (production) => {
  const isOrganizationProducer = production?.forEntity === 3;
  let producers = [];

  if (isOrganizationProducer) {
    producers = production?.organizations?.filter((organization) => !organization?.isPartner);
  }

  const coProducers = [].concat(production?.organizations?.filter((organization) => organization?.isPartner) || []);

  return {
    ...production,
    workTypes: production?.works?.map((work) => work.workType),
    productionTypes: production?.works?.map((work) => work.productionType),
    customTitle: production?.name,
    producers,
    coProducers,
  };
};

export const WorkRow = (props) => (
  <>
    <TableCell>
      <DisplayText path="" changes={props.changes} select="work.id" />
    </TableCell>

    <TableCell>
      <DisplayText path="" changes={props.changes} select="work.name" />
    </TableCell>

    <TableCell>
      <DisplayText path="" changes={props.changes} select="work.creators.[0].profile.name" />
    </TableCell>

    <TableCell>
      <DisplayText path="" changes={props.changes} select="stagingType.workType.name" />
    </TableCell>

    <TableCell>
      <DisplayText changes={props.changes} path="" select="stagingType.name" />
    </TableCell>
  </>
);

export const ProductionInformation = (props) => {
  return (
    <div>
      <DisplayText {...props} title="Production types" path="productionTypes" select="name" />
      <DisplayText {...props} title="Work Type" path="workTypes" select="name" />
      <DisplayText {...props} title="Production custom title" path="name" />

      <DisplayTable {...props} title="Musical Work" header={['Id', 'Name', 'Composer', 'Work type', 'Staging type']} path="productionWorks">
        <WorkRow />
      </DisplayTable>

      <DisplayBoolean {...props} title="Cancelled" path="isCancelled" />
      <DisplayBoolean {...props} title="Highlight" path="isHighlight" />
      <DisplayBoolean {...props} title="New Production" path="isNewProduction" />

      <DisplayText {...props} title="organizations" path="organizations" select="name" />

      <DisplayText {...props} title="Produced by" path="producers" select="name" />
      <DisplayText {...props} title="Co-production" path="coProducers" select="name" />
      <DisplayText {...props} title="Languages" path="languages" select="name" />
      <DisplayText {...props} title="Subtitles" path="subtitles" select="name" />
      <DisplayText {...props} title="Duration" path="duration" />
    </div>
  );
};
