import { union, cloneDeep } from 'lodash';
import diff from 'arcdiff';

export const cloneDiff = (production, productionRevision) => {
  const a = cloneDeep(production);
  const b = cloneDeep(productionRevision);

  if (!a) {
    return [];
  }

  const EXCLUDED_KEYS = [
    'id',
    'slug',
    'updatedAt',
    'updated_at',
    'createdAt',
    'created_at',
    'missing',
    'privacy',
    'validationStatus',
    'validation_status_id',
    'validity',
    'approvedBy',
    'addedBy',
    'forEntity',
    'forEntityId',
    'isSEODescriptionHidden',
    'published_at',
    'changes',
    'parentId',
  ];

  Object.keys(a).forEach((key) => {
    if (Array.isArray(a[key])) {
      if (typeof b[key] === 'undefined' || b[key] === null) {
        b[key] = [];
      }

      if (a[key].filter((p) => p?.id).length > 0 || b[key].filter((p) => p?.id).length > 0) {
        const u = union(a[key].map((p) => p?.id).filter(Boolean), b[key].map((p) => p?.id).filter(Boolean));

        a[key] = u.map((id) => {
          return a[key].find((p) => p?.id === id) || null;
        });
        b[key] = u.map((id) => {
          return b[key].find((p) => p?.id === id) || null;
        });
      }
    }
  });

  let d = null;
  try {
    d = diff(a, b, {
      ignore: EXCLUDED_KEYS,
    });
  } catch (ex) {
    d = [];
  }

  if (!d) {
    return [];
  }

  return d;
}