export const INVOICE_STATUSES = [
  { id: 'booked-paid', name: 'booked-paid' },
  { id: 'booked-dunning', name: 'booked-dunning' },
  { id: 'booked-nonpaid', name: 'booked-nonpaid' },
];

export const CREDIT_NOTE_STATUSES = [
  { id: 'booked-paid', name: 'booked-paid' },
  { id: 'booked-nonpaid', name: 'booked-nonpaid' },
  { id: 'booked-declined', name: 'booked-declined' },
];

export const REFUND_APPROVAL_EMAILS = ['tgu@artsconsolidated.com', 'ri@artsconsolidated.com', 'pp@artsconsolidated.com', 'bvs@artsconsolidated.com'];
