import React, { useState, useEffect } from 'react';
import { Loading, Title, useNotify } from 'react-admin';
import cn from 'classnames';
import get from 'lodash/get';
import * as dayjs from 'dayjs';
import { makeStyles, FormControl, TextField, MenuItem, FormControlLabel, Switch } from '@material-ui/core';
// import GetAppIcon from '@material-ui/icons/GetApp';
import { AgGridReact } from 'ag-grid-react';
import { useHistory } from 'react-router-dom';

import ScrapeMetricsChart from './ScrapeMetricsChart';

import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';

import { getScrapeMetrics } from '../utils/metrics';
import { Breadcrumbs } from './Breadcrumbs';
import apiClient from '../utils/api';

const styles = () => ({
  filters: {
    display: 'flex',
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  selectSection: {
    display: 'flex',
    flexDirection: 'row',
  },
  spacingLeft: { marginLeft: 10 },
  tooltip: {
    backgroundColor: 'white',
    padding: '3px 10px',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    borderRadius: 4,
  },
  dataGridContainer: {
    '& .ag-header-group-text': {
      textTransform: 'capitalize',
    },
    '& .ag-header-cell-label': {
      justifyContent: 'center',
      textOverflow: 'clip',
      overflow: 'visible',
      whiteSpace: 'normal',
    },
  },
  button: {
    color: '#ff5722',
  },
  icon: {
    fontSize: 20,
    paddingRight: 5,
  },
  select: {
    marginLeft: 10,
    width: 130,
  },
});

const METRICS_TABS = [
  { label: 'Scrape request', value: 'metrics/scrape-request-datapoints' },
  { label: 'Scrape request with entity', value: 'metrics/scrape-request-datapoints', filters: { aggregate_by: 'entities' } },
  { label: 'LLM counts', value: 'metrics/scrape-request-counts' },
];

const ScrapeMetricsTable = ({ isLoading, columns, rows, onGridReady }) => {
  const classes = makeStyles(styles)();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className={cn('ag-theme-alpine', classes.dataGridContainer)} style={{ height: '83vh', width: '100%' }}>
      <AgGridReact columnDefs={columns} rowData={rows} tooltipShowDelay={0} tooltipHideDelay={2000} onGridReady={onGridReady} gridOptions={{ headerHeight: 80 }} />
    </div>
  );
};

export const ScrapeMetrics = (props) => {
  const classes = makeStyles(styles)();
  const history = useHistory();
  const notify = useNotify();

  const [isLoading, setIsLoading] = useState(false);
  const [gridApi, setGridApi] = useState(null); // eslint-disable-line no-unused-vars
  const [metrics, setMetrics] = useState([]);

  const searchParams = new URLSearchParams(history.location.search);
  const defaultDateRange = { from_date: searchParams?.get('from_date'), to_date: searchParams?.get('to_date') };
  const defaultTab = METRICS_TABS.find((tab) => tab.label === searchParams?.get('tab'));
  const [selectedDateRange, setSelectedDateRange] = useState({
    from: defaultDateRange?.from_date || dayjs().add(-6, 'day').format('YYYY-MM-DD'),
    to: defaultDateRange?.to_date || dayjs().format('YYYY-MM-DD'),
  });
  const [tab, setTab] = useState(defaultTab || METRICS_TABS[0]);
  const defaultIsChart = searchParams?.get('is_chart') === 'true';
  const [isChart, setIsChart] = useState(defaultIsChart);

  const getMetricsDetails = () => {
    setIsLoading(true);
    apiClient
      .get(tab?.value, { params: { from_date: selectedDateRange.from, to_date: selectedDateRange.to, ...get(tab, 'filters', {}) } })
      .then((res) => {
        setIsLoading(false);
        setMetrics(res?.data);
      })
      .catch((err) => {
        setIsLoading(false);
        notify(err?.message, 'warning');
        setMetrics([]);
      });
  };

  useEffect(() => {
    document.title = 'Scrape metrics';
  }, []);

  useEffect(() => {
    searchParams.set('from_date', selectedDateRange?.from);
    searchParams.set('to_date', selectedDateRange?.to);
    searchParams.set('tab', tab?.label);
    searchParams.set('is_chart', isChart);
    const newSearch = searchParams.toString();
    history.push({ search: newSearch });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange, tab, isChart]);

  useEffect(() => {
    if (!isChart) {
      getMetricsDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange, tab, isChart]);

  const { columns, rows } = getScrapeMetrics(metrics);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  // const onDownload = () => {
  //   const params = {
  //     fileName: `scrape_metrics_from_${selectedDateRange.from}_to_${selectedDateRange.to}`,
  //   };
  //   gridApi.exportDataAsCsv(params);
  // };

  return (
    <div>
      <Title title={<Breadcrumbs {...props} />} />
      <div className={classes.filters}>
        <FormControl className={classes.selectSection} size="small">
          {!isChart && (
            <TextField value={tab} select onChange={(e) => setTab(e?.target?.value)} label="Metrics" variant="filled" size="small">
              {METRICS_TABS.map((option) => (
                <MenuItem key={option.value} value={option}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
          <TextField
            className={cn({ [classes.spacingLeft]: !isChart })}
            disabled={isLoading}
            value={selectedDateRange.from}
            onChange={(e) => setSelectedDateRange({ ...selectedDateRange, from: e?.target?.value })}
            type="date"
            label="From"
            variant="filled"
            size="small"
          />
          <TextField
            disabled={isLoading}
            className={classes.spacingLeft}
            value={selectedDateRange.to}
            onChange={(e) => setSelectedDateRange({ ...selectedDateRange, to: e?.target?.value })}
            type="date"
            label="To"
            variant="filled"
            size="small"
          />
          <FormControlLabel className={classes.spacingLeft} control={<Switch checked={isChart} onChange={() => setIsChart(!isChart)} name="Chart" color="primary" />} label="Chart" />
        </FormControl>
        {/* <Button disabled={isLoading} size="small" className={classes.button} onClick={() => onDownload()}>
          <GetAppIcon className={classes.icon} /> Export
        </Button> */}
      </div>
      {isChart ? (
        <ScrapeMetricsChart isChart={isChart} selectedDateRange={selectedDateRange} {...props} />
      ) : (
        <ScrapeMetricsTable isLoading={isLoading} columns={columns} rows={rows} {...props} onGridReady={onGridReady} />
      )}
    </div>
  );
};

export default ScrapeMetrics;
