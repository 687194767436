export default [
  '#008FFB',
  '#00E396',
  '#FEB019',
  '#775DD0',
  '#E2C044',
  '#3F51B5',
  '#03A9F4',
  '#FF9800',
  '#33B2DF',
  '#546E7A',
  '#D4526E',
  '#13D8AA',
  '#A5978B',
  '#4ECDC4',
  '#C7F464',
  '#81D4FA',
  '#546E7A',
  '#FD6A6A',
  '#2B908F',
  '#F9A3A4',
  '#90EE7E',
  '#FA4443',
  '#69D2E7',
  '#D7263D',
  '#1B998B',
  '#2E294E',
  '#F46036',
  '#4CAF50',
  '#F9CE1D',
  '#FF4560',
];
