export const DB_VIEWS_DUPLICATE_ENTITY_CONFIG = {
  organizations: {
    viewId: 'v_organization_duplicates',
    dataKey: 'duplicate_organizationids',
    reference: 'organizations',
  },
  agencies: {
    viewId: 'v_agency_duplicates',
    dataKey: 'duplicate_agencyids',
    reference: 'agencies',
  },
  venues: {
    viewId: 'v_venue_duplicates',
    dataKey: 'duplicate_venueids',
    reference: 'venues',
  },
  works: {
    viewId: 'v_work_duplicates',
    dataKey: 'duplicate_workids',
    reference: 'works',
  },
  profiles: {
    viewId: 'v_profile_duplicates',
    dataKey: 'duplicate_profileids',
    reference: 'profiles',
  },
  productions: {
    viewId: 'v_production_duplicates',
    dataKey: 'duplicate_productionids',
    reference: 'productions',
  },
  professions: {
    viewId: 'v_profession_duplicates',
    dataKey: 'duplicate_professionids',
    reference: 'professions',
  },
  instruments: {
    viewId: 'v_instrument_duplicates',
    dataKey: 'duplicate_instrumentids',
    reference: 'instruments',
  },
  'instruments/roles': {
    viewId: 'v_instrumentRole_duplicates',
    dataKey: 'duplicate_instrumentroleids',
    reference: 'instrument/roles',
  },
};

export const DB_VIEWS_DATA_QUALITY_CONFIG = {
  organizations: {
    viewId: 'v_organization_quality',
    dataKey: 'organization_organizationids',
  },
  profiles: {
    viewId: 'v_profile_quality',
    dataKey: 'profile_profileids',
  },
  venues: {
    viewId: 'v_venue_quality',
    dataKey: 'venue_venueids',
  },
  works: {
    viewId: 'v_work_quality',
    dataKey: 'work_workids',
  },
  productions: {
    viewId: 'v_production_quality',
    dataKey: 'production_productionids',
  },
  agencies: {
    viewId: 'v_agency_quality',
    dataKey: 'agency_agencyids',
  },
  professions: {
    viewId: 'v_profession_quality',
    dataKey: 'profession_professionids',
  },
  instruments: {
    viewId: 'v_instrument_quality',
    dataKey: 'instrument_instrumentids',
  },
  'instruments/roles': {
    viewId: 'v_instrumentrole_quality',
    dataKey: 'instrumentrole_instrumentroleids',
  },
};
