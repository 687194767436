/* eslint-disable react/jsx-boolean-value */
import React, { useEffect, useState } from 'react';
import { ArrayField, Datagrid, EditButton, FunctionField, ReferenceField, SimpleShowLayout, SingleFieldList, TextField, useDataProvider, useMutation, useRefresh } from 'react-admin';
import { Button, Drawer, FormControl, FormControlLabel, Input, MenuItem, Radio, RadioGroup, Select, Tooltip, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import IconImageEye from '@material-ui/icons/RemoveRedEye';
import IconKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Event } from '@material-ui/icons';
import { SubscriptionsListColors } from '../../constants/stylingConstants';
import CustomDateField from '../customFields/CustomDateField';
import { productCurrency, SUBSCRIPTION_STATUS } from '../../constants/subscription';
import { dateParserUTC } from '../../utils/dateParser';
import ButtonWithConfirmation from '../ButtonWithConfirmation';

const useStyles = makeStyles(() => ({
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    '&.MuiButton-root': {
      width: '100%',
      whiteSpace: 'nowrap',
      height: '30px',
      marginTop: '15px',
    },
  },
  buttonStatus: {
    width: '100%',
    height: '30px',
  },
  buttonWrapper: {
    width: '100%',
    marginTop: '15px',
  },
  drawer: {
    minWidth: '250px',
  },
  drawerBlock: { marginTop: '15px', color: '#5d63ff' },
  autoCollectionNotification: { marginTop: '5px', fontSize: '12px;', color: '#ff5722' },
}));

const CANCEL_REASON_CODES = {
  product_unsatisfactory: 'Product Unsatisfactory',
  service_unsatisfactory: 'Service Unsatisfactory',
  order_change: 'Order Change',
  other: 'Other',
};

export const SubscriptionsTabContent = ({ ...props }) => (
  <Datagrid
    {...props}
    rowStyle={(record) => ({
      backgroundColor: SubscriptionsListColors[record?.status === 'in_trial' && !record?.renewAt ? 'cancelled' : record?.status] || SubscriptionsListColors['fbadmin-default'],
    })}
  >
    <FunctionField label="Subscription name" render={(item) => item.subscription?.product?.internalName || item.subscription?.product?.name} />
    <TextField source="status" label="Status" />
    <ReferenceField label="Profile" source="profile.id" reference="profiles" link="show">
      <TextField label="Profile name" source="name" />
    </ReferenceField>

    <FunctionField
      label="Organizations and agencies"
      render={(record) => [...(record?.profile?.organizations?.map((item) => item.name) || []), ...(record?.profile?.agencies?.map((item) => item.name) || [])].join(', ')}
    />

    <CustomDateField source="startAt" timeHidden />
    <FunctionField
      label="Renew at"
      render={(sub) => ([SUBSCRIPTION_STATUS.NON_RENEWING, SUBSCRIPTION_STATUS.CANCELLED].includes(sub?.status) ? dateParserUTC(sub?.cancelledAt, '', true) : dateParserUTC(sub?.renewAt, '', true))}
    />
    <SubscriptionActions />
  </Datagrid>
);

const SubscriptionActions = ({ record }) => {
  const classes = useStyles();
  const id = record?.id;
  const [showPanel, setShowPanel] = useState(false);

  const onShowAdditionalInfo = () => {
    setShowPanel(!showPanel);
  };

  return (
    <div className={classes.buttonsContainer}>
      <EditButton label="Edit" basePath="/users/subscriptions" record={record} />
      <>
        <Button onClick={onShowAdditionalInfo} label="ra.action.show">
          <IconImageEye />
        </Button>
        {record?.hasScheduledChanges && ['active', 'in_trial'].includes(record.status) && (
          <Tooltip title="There are scheduled changes for this subscription">
            <Event style={{ color: 'gray' }} />
          </Tooltip>
        )}
        <Drawer anchor="right" open={showPanel} onClose={onShowAdditionalInfo}>
          <div>
            <Button label="Close" onClick={onShowAdditionalInfo}>
              <IconKeyboardArrowRight />
            </Button>
          </div>
          <SubscriptionDrawerContent id={id} record={record} />
        </Drawer>
        {record?.externalKey && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://${
              window?.location?.hostname === 'admin.truelinked.net' ? 'truelinked' : 'truelinked-test'
            }.chargebee.com/subscriptions?view_code=all&Subscriptions.search=${encodeURIComponent(record?.externalKey)}`}
            style={{ margin: 'auto', display: 'flex', height: 24, textDecoration: 'none', cursor: 'pointer' }}
          >
            <svg height={24} width={24} viewBox="0 0 202 202" fill="#ff7a59" xmlns="http://www.w3.org/2000/svg">
              <g fillRule="evenodd">
                <path d="M170.6 143.8c-1.9-1.3-4.4-1.2-6.3.1-1.9 1.3-2.7 3.8-2 6 .7 2.2 2.7 3.8 5 3.9 2.3.1 4.4-1.2 5.3-3.3 1-2.4.1-5.2-2-6.7-2.5-1.6 2.5 1.7 0 0z" />
                <path d="M100.7 194.7c1.2 0 2.5 0 3.7-.1 2.5-.1 5-.3 7.4-.6 2-.2-4.1.5 0 0 2.5-.3 4.5 2 4 4.5-.3 1.1-1.1 2-2.1 2.5-.7.3-1.4.3-2.1.4-.8.1-1.7.2-2.5.2-6.6.5-13.2.4-19.8-.3-6-.7-11.8-1.8-17.6-3.6-5.6-1.7-11-3.9-16.2-6.6-5.3-2.7-10.2-5.9-14.9-9.5-5.4-4-10.3-8.6-14.7-13.7-4.4-5-8.3-10.5-11.6-16.2-3.4-5.9-6.2-12.1-8.3-18.6-2.2-6.6-3.6-13.4-4.4-20.3-.8-7-.8-14.1-.1-21.1.7-6.8 2.1-13.7 4.3-20.3 2.1-6.5 4.8-12.7 8.2-18.7 3.3-5.9 7.3-11.4 11.7-16.5 4.4-5 9.3-9.6 14.6-13.7 5.4-4.1 11.2-7.7 17.2-10.6 6.2-3 12.7-5.3 19.4-7C83.7 3.2 90.7 2.3 97.7 2c7-.2 14.1.3 21 1.5 6.7 1.2 13.3 3.1 19.7 5.6 6.3 2.5 12.3 5.7 17.9 9.4 5.6 3.7 10.8 8 15.6 12.8 2 2 3.8 4 5.1 6.6 1.1 2.2 1.7 4.7 1.9 7.1.3 5-1.4 10.1-4.8 13.8-3.9 4.4-9.8 6.8-15.6 6.4-2.8-.2-5.6-1-8.1-2.4-1.3-.7-2.5-1.6-3.5-2.6-1.2-1.1-2.3-2.4-3.5-3.5-4.9-4.6-10.5-8.3-16.6-11.1-6.3-2.8-13-4.6-19.8-5.2-7-.6-14.1-.1-20.9 1.6-6.6 1.7-12.9 4.5-18.7 8.2-5.6 3.7-10.6 8.3-14.7 13.5-4.2 5.3-7.5 11.3-9.8 17.7-2.3 6.5-3.5 13.4-3.5 20.3 0 7 1.1 13.9 3.4 20.5 2.2 6.4 5.5 12.3 9.6 17.6 4.1 5.3 9.1 9.9 14.7 13.6 5.7 3.8 12 6.6 18.7 8.3 6.8 1.8 13.9 2.3 20.9 1.7 6.8-.6 13.5-2.3 19.8-5.1.7-.3 1.3-.7 2-.8.6-.1 1.2-.1 1.8.1 1.2.4 2.1 1.3 2.4 2.5.3 1.1.1 2.4-.7 3.3-.4.5-1 .8-1.6 1.1-.8.3-1.5.7-2.3 1-6.4 2.7-13.1 4.4-20 5-7 .7-14.1.3-20.9-1.1.9 1 1.7 2.1 2.5 3.2s1.6 2.3 2.3 3.4c.1.2.4.6.4.6h.3c.4 0 .7.1 1.1.1.7.1 1.4.1 2.1.2 2.9.2 5.8.2 8.7.1 5.7-.3 11.4-1.1 16.9-2.7 5.3-1.5 10.5-3.6 15.4-6.2 4.8-2.6 9.3-5.6 13.4-9.2l3-2.7c.9-.8 1.6-1.8 2.7-2.3 1.9-1 4.2-.7 5.8.6 1.7 1.3 2.4 3.5 1.9 5.6-.3 1.1-.8 2-1.6 2.7-1 .9-1.9 1.9-2.9 2.8-4 3.6-8.3 6.9-12.9 9.7-4.7 2.9-9.6 5.3-14.8 7.2-5.3 2-10.7 3.4-16.3 4.3-5.7.9-11.5 1.3-17.3 1.1.5 1.3.9 2.6 1.3 3.9.2.6.3 1.2.5 1.8 0 .2.2.7.2.7l.8.4z" />
                <path d="M156.5 176.3c-5.6 4.2-11.7 7.8-18.1 10.6-3.2 1.4-6.5 2.6-9.9 3.7-1.7.5-3.4 1-5.1 1.4-.7.2-1.5.3-2.2.7-.6.3-1 .8-1.3 1.4-.7 1.3-.5 2.8.4 3.9 1 1.3 2.5 1.5 4 1.2 3.4-.8 6.8-1.8 10.1-3 6.6-2.3 13-5.4 19-9.1 1.5-.9 3-1.9 4.4-2.9.7-.5 1.5-1 2.2-1.5.6-.5 1.3-.9 1.8-1.6.8-1.1.8-2.7.1-3.9-.7-1.2-2.1-1.9-3.4-1.8-.8.3-1.5.5-2 .9-10.3 7.7 1.6-1.2 0 0zM168.6 165.5c-.9 1-1.9 2-2.9 3-.8.8-1.7 1.5-2.1 2.6-.6 2.1.8 4.3 2.9 4.6 1.3.2 2.4-.3 3.3-1.1 1-.9 2-1.9 2.9-2.9.8-.8 1.7-1.6 2-2.8.2-.9.1-2-.4-2.8-1.2-2-4.1-2.3-5.7-.6-1.3 1.4 1.3-1.4 0 0zM145.8 144.6c-2.3 2.4-4.7 4.6-7.3 6.6-.7.5-1.5 1.1-2.2 1.6-.6.4-1.1 1-1.4 1.7-.6 1.4-.2 3.1 1 4.1 1.4 1.2 3.2 1.1 4.7 0 1.4-1 2.8-2 4.1-3 1.2-1 2.4-2.1 3.6-3.2.7-.6 1.3-1.3 2-2 .6-.6 1.2-1.2 1.6-2 .6-1.6 0-3.5-1.5-4.4-1.5-.9-3.4-.6-4.6.6-3.5 3.7 1.4-1.5 0 0zM101.1 78.4c-4.9 0-9.7 1.5-13.7 4.4-3.9 2.8-6.9 6.7-8.5 11.2-1.7 4.6-1.9 9.8-.5 14.6 1.3 4.6 4 8.7 7.7 11.7 3.7 3.1 8.4 5 13.2 5.3 4.9.4 9.9-.8 14.1-3.4 4.1-2.5 7.3-6.1 9.3-10.5 2-4.5 2.6-9.6 1.6-14.5-.9-4.7-3.4-9.1-6.8-12.4-3.5-3.3-8-5.5-12.7-6.3-1.3 0-2.5-.1-3.7-.1z" />
              </g>
            </svg>
          </a>
        )}
      </>
    </div>
  );
};

const SubscriptionDrawerContent = ({ ...props }) => {
  const classes = useStyles();
  const { record } = props;
  const id = record?.id;
  const resource = 'users/subscriptions';
  const refresh = useRefresh();
  const onSuccess = () => refresh();
  const onFailure = () => refresh();
  const dataProvider = useDataProvider();
  const [product, setProduct] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [trialEndDate, setTrialEndDate] = useState('');
  const [cancelOption, setCancelOption] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [cancelReasonCode, setCancelReasonCode] = useState(null);
  const [cancelReasonDescription, setCancelReasonDescription] = useState(null);
  const [reactivateSubscription, setReactivateSubscription] = useState(null);
  const [priceDetails, setPriceDetails] = useState(null);

  const currentDate = new Date().toISOString();

  const [cancel] = useMutation({ type: 'update', target: 'user_id', resource, payload: { id, data: { status: cancelOption, cancelReasonCode, cancelReasonDescription } } }, { onSuccess, onFailure });

  const [reactivate] = useMutation(
    {
      type: 'update',
      target: 'user_id',
      resource,
      payload: {
        id,
        data: {
          status: subscriptionStatus,
          ...(trialEndDate ? { trialEndAt: new Date(trialEndDate).toISOString(), autoCollection: true } : {}),
          ...(reactivateSubscription === 'invoiceNow' ? { invoiceNow: true } : {}),
        },
      },
    },
    {
      onSuccess,
      onFailure,
    },
  );

  const getPriceEstimate = () => {
    const { user, coupon, addons, subscription } = record;
    dataProvider
      .create('users/subscriptions/estimate', {
        data: {
          user: { id: user.id },
          product: { id: subscription.product.id },
          addons,
          coupon,
        },
      })
      .then((res) => {
        setPriceDetails(res.data);
      });
  };

  useEffect(() => {
    if (record.hasScheduledChanges) {
      dataProvider.getOne('products', { id: record.scheduledChanges.product_id }).then((res) => {
        setProduct(res?.data);
      });
    }

    if (record.autoCollection) {
      dataProvider
        .getList('users/paymentmethods', {
          pagination: { perPage: 1 },
          sort: { field: 'id' },
          filter: { user_id: record?.user?.id },
        })
        .then((res) => {
          setPaymentMethod(res?.data);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeCancelOption = (e) => {
    setCancelOption(e.target.value);
  };

  const onChangeSubOption = (e) => {
    setSubscriptionStatus(e.target.value);
  };

  const onChangeCancelReasonCode = (e) => {
    setCancelReasonCode(e.target.value);
  };

  const onChangeCancelReasonDescription = (e) => {
    setCancelReasonDescription(e.target.value);
  };

  const onChangeReactivateSubscription = (e) => {
    setTrialEndDate(null);
    setReactivateSubscription(e.target.value);
  };

  return (
    <SimpleShowLayout {...props} className={classes.drawer}>
      <div className={classes.drawerBlock}>Subscription information</div>
      <TextField source="id" label="Subscription ID (API)" />
      <TextField source="externalKey" label="Subscription ID (Chargebee)" />
      <FunctionField label="Current product" render={(item) => item.subscription?.product?.internalName || item.subscription?.product?.name} />
      <FunctionField label="User" render={(item) => (item?.user ? <Link to={`/users/${item.user?.id}/show`}>{item.user?.name}</Link> : 'Unassigned')} />
      <FunctionField label="Coupon code" render={(item) => (item?.couponCode ? <Link to={`/coupons/codes/${item.couponCode?.id}/show`}>{item.couponCode.code}</Link> : null)} />
      <FunctionField
        label="Coupon"
        render={(item) =>
          item?.coupon ? (
            <Link to={`/coupons/${item.coupon?.id}/show`}>
              {item.coupon.name} ({item.coupon.discount_type === 'percentage' ? `${item.coupon.discount_percentage} %` : `€${item.coupon.discount_amount / 100}`})
            </Link>
          ) : null
        }
      />
      <ArrayField source="addons" label="Addons">
        <SingleFieldList linkType={false}>
          <FunctionField render={(addon) => <Link to={`/addons/${addon.id}/show`}>{addon.name}</Link>} />
        </SingleFieldList>
      </ArrayField>
      <div className={classes.drawerBlock}>Subscription actions</div>
      {record && [SUBSCRIPTION_STATUS.ACTIVE, SUBSCRIPTION_STATUS.IN_TRIAL].includes(record.status) ? (
        <ButtonWithConfirmation
          content={
            <div>
              <FormControl component="fieldset">
                <RadioGroup row aria-label="cancel subscription" name="row-radio-buttons-group">
                  <FormControlLabel value="cancelled" control={<Radio checked={cancelOption === 'cancelled'} onChange={onChangeCancelOption} />} label="Cancel immediately" />
                  <FormControlLabel
                    value="non_renewing"
                    control={<Radio checked={cancelOption === 'non_renewing'} onChange={onChangeCancelOption} />}
                    label="Cancel at the end of the term (Schedule cancellation)"
                  />
                </RadioGroup>
                <br />
                <Typography>Cancellation reason</Typography>
                <Select onChange={onChangeCancelReasonCode}>
                  {Object.keys(CANCEL_REASON_CODES).map((key) => (
                    <MenuItem value={key}>{CANCEL_REASON_CODES[key]}</MenuItem>
                  ))}
                </Select>
                {cancelReasonCode === 'other' && <Typography>Cancellation reason description</Typography>}
                {cancelReasonCode === 'other' && <Input onChange={onChangeCancelReasonDescription} />}
              </FormControl>
            </div>
          }
          title="Cancel subscription"
          variant="contained"
          label="Cancel"
          handleConfirm={cancel}
          className={classes.buttonStatus}
          classButtonWrapper={classes.buttonWrapper}
          confirmDisabled={!cancelOption}
        />
      ) : (
        <ButtonWithConfirmation
          variant="contained"
          content={
            <div>
              <Typography variant="body1" display="block" gutterBottom>
                Do you really want to reactivate a subscription? If confirmed, the user will be charged for the upcoming billing period with the credit card on file.
              </Typography>
              <FormControl>
                <RadioGroup row aria-label="cancel subscription" name="row-radio-buttons-group">
                  <FormControlLabel
                    value={SUBSCRIPTION_STATUS.ACTIVE}
                    control={<Radio checked={subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE} onChange={onChangeSubOption} />}
                    label={
                      priceDetails
                        ? `Reactivate subscription (${productCurrency(priceDetails?.currentInvoice?.total?.currency)}${priceDetails?.currentInvoice?.total?.amount})`
                        : 'Reactivate subscription'
                    }
                  />
                  <FormControlLabel
                    value={SUBSCRIPTION_STATUS.IN_TRIAL}
                    control={<Radio checked={subscriptionStatus === SUBSCRIPTION_STATUS.IN_TRIAL} onChange={onChangeSubOption} />}
                    label="Reactivate In Trial"
                  />
                </RadioGroup>
              </FormControl>
              {subscriptionStatus === SUBSCRIPTION_STATUS.IN_TRIAL && (
                <>
                  <Typography variant="body2" display="block" gutterBottom>
                    (Specifying an end date here will ensure that the subscription is moved to the “trial” state and a charge is made when the trial ends.)
                  </Typography>
                  <input
                    type="date"
                    value={trialEndDate}
                    onChange={(e) => {
                      setReactivateSubscription(null);
                      setTrialEndDate(e.target.value);
                    }}
                  />
                </>
              )}
              {subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE && (
                <div>
                  <FormControlLabel value="invoiceNow" control={<Radio checked={reactivateSubscription === 'invoiceNow'} onChange={onChangeReactivateSubscription} />} label="Invoice now" />
                  <FormControlLabel
                    value="unbilledCharges"
                    control={<Radio checked={reactivateSubscription === 'unbilledCharges'} onChange={onChangeReactivateSubscription} />}
                    label="Unbilled charges"
                  />
                </div>
              )}
            </div>
          }
          title="Reactivate subscription"
          label="Reactivate"
          handleConfirm={reactivate}
          className={classes.buttonStatus}
          disabled={record.autoCollection && paymentMethod?.length === 0}
          classButtonWrapper={classes.buttonWrapper}
          onClick={getPriceEstimate}
          confirmDisabled={!reactivateSubscription && !trialEndDate}
        />
      )}
      {record.autoCollection && paymentMethod?.length === 0 && <div className={classes.autoCollectionNotification}>TTurn off auto collection to reactivate the subscription</div>}
      <Button
        variant="contained"
        component={Link}
        to={{
          pathname: '/invoices/onetime/create',
          state: { record: { userSubscription: { id: record.id }, buyer: { user: { id: record?.user?.id } } } },
        }}
        className={classes.button}
      >
        One-time charge
      </Button>
      {record.status === 'cancelled' && <div className={classes.drawerBlock}>Cancellation reason</div>}
      {record.status === 'cancelled' && <TextField source="cancelReasonCode" label="Cancellation reason code" />}
      {record.status === 'cancelled' && <TextField source="cancelReasonDescription" label="Cancellation reason description" />}
      {record.cancelledAt && currentDate > record.cancelledAt && <FunctionField label="Cancelled at" render={(item) => <CustomDateField source="cancelledAt" record={item} timeHidden />} />}
      {record.scheduledChanges && <div className={classes.drawerBlock}>Scheduled Changes</div>}
      {record.cancelledAt && currentDate < record.cancelledAt && (
        <FunctionField label="Schedule cancellation date" render={(item) => <CustomDateField source="cancelledAt" record={item} timeHidden />} />
      )}
      {record.scheduledChanges && (
        <FunctionField
          label="Changes Scheduled At"
          render={(item) => (
            <>
              {item.changeOption === 'specific_date' && <CustomDateField source="changesScheduledAt" record={item} timeHidden />}
              {item.changeOption === 'end_of_term' && 'On next renew'}
            </>
          )}
        />
      )}
      {record.scheduledChanges && <FunctionField label="Changed product" render={() => product.internalName} />}
      {record.scheduledChanges && (
        <FunctionField
          label="Plan unit price"
          render={(item) =>
            item.scheduledChanges && (
              <TextField
                source="scheduledChanges.plan_unit_price"
                record={{ ...item, scheduledChanges: { ...item.scheduledChanges, plan_unit_price: item.scheduledChanges?.plan_unit_price / 100 } }}
              />
            )
          }
        />
      )}
    </SimpleShowLayout>
  );
};
