/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { useCallback, useState, useEffect, memo } from 'react';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import {
  ArrayInput,
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Filter,
  FormTab,
  List,
  Pagination as RaPagination,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  Tab,
  TabbedForm,
  TabbedShowLayout,
  TextField,
  TextInput,
  NumberInput,
  Toolbar,
  UPDATE,
  DELETE,
  useNotify,
  useRefresh,
  useUpdate,
  TopToolbar,
  TabbedShowLayoutTabs,
  FunctionField,
  ArrayField,
  useDataProvider,
  useRedirect,
  DeleteButton,
  RadioButtonGroupInput,
  ListContextProvider,
  Confirm,
  SimpleShowLayout,
} from 'react-admin';
import { Alert } from '@material-ui/lab';
import { Button, InputLabel, Tab as MUITab, TextField as MUITextField, Dialog, DialogContent, DialogTitle, MenuItem, Tabs as MUITabs, Typography, Select, FormControl } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link, useRouteMatch } from 'react-router-dom';
import { map, keyBy } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-final-form';

import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import dataProvider from '../components/dataProviderComponent';
import SortableDatagrid from '../components/SortableDatagrid';
import IconField from '../fields/IconField';
import ValidityShow from '../components/ValidityShow';
import { VALIDATION_STATUSES } from '../constants/validations';
import { MediaThumbnailField } from '../components/MediaThumbnailField';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { PublicLink } from '../components/PublicLink';
import EntityLink from '../components/EntityLink';
import CustomShowLayout from '../components/CustomShowLayout';
import { CREATOR_PROFESSIONS } from '../constants/works';
import { getComposerNamesFromCreators, getComposersFromCreators, getLibrettistsCFromCreators } from '../utils/composer';
import { MERGE_ENTITIES, ENTITIES } from '../constants/entities';
import apiClient from '../utils/api';
import ExternalReferencesTab from '../components/ExternalReferences';
import EntityTranslation from '../components/translation/EntityTranslation';
import { searchToObject } from '../utils/location';
import WorkRoleProfessionModal from './WorkRoleProfessionModal';

const PRODUCED_BY = {
  ORGANIZATION: 'organization',
  REFERENCE: 'reference',
};

const useStyles = makeStyles({
  dialog: { width: 500 },
  productionDialog: { minWidth: 900 },
  divider: {
    width: '100%',
    height: 12,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    textAlign: 'center',
    marginTop: 30,
    marginBottom: 10,
  },
  dividerText: {
    fontSize: 20,
    backgroundColor: 'white',
    padding: '0 10px',
  },
  input: {
    width: 200,
    marginBottom: 20,
    marginTop: 10,
    marginRight: 10,
  },
  button: {
    textTransform: 'inherit',
  },
  proceedToMerge: {
    textTransform: 'inherit',
    width: 150,
  },
  entityLink: {
    '& a': {
      whiteSpace: 'pre-line',
    },
  },
  icon: {
    fontSize: 14,
    marginRight: 5,
  },
  centerAlign: {
    display: 'flex',
    alignItems: 'center',
  },
  sectionContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '10px',
    overflow: 'scroll',
  },
  cast: {
    gridColumn: 1,
    gridRow: 1,
    padding: '10px',
    border: '2px solid grey',
  },
  other: {
    gridColumn: 1,
    gridRow: 2,
    padding: '10px',
    border: '2px solid grey',
  },
  crew: {
    gridColumn: 2,
    gridRow: 1,
    padding: '10px',
    border: '2px solid grey',
  },
  instrumentalist: {
    gridColumn: 3,
    gridRow: 1,
    padding: '10px',
    border: '2px solid grey',
  },
  other_instrumentalist: {
    gridColumn: 3,
    gridRow: 2,
    border: '2px solid grey',
    padding: '10px',
  },
  languageSelectDropdown: {
    minWidth: 120,
    maxWidth: 200,
  },
  dialogTitle: {
    display: 'flex',
    columnGap: '20px',
    flexDirection: 'column',
  },
  error: {
    width: 200,
  },
  originalName: {
    minWidth: 120,
    display: 'block',
    // wordWrap: 'break-word',
  },
  professionType: {
    width: 60,
    display: 'block',
    wordWrap: 'break-word',
  },
  profession: {
    width: '100%',
    display: 'block',
    wordWrap: 'break-word',
  },
  productionCount: {
    width: 120,
    display: 'block',
  },
  actions_roles: {
    display: 'block',
    width: 200,
  },
  actions_otherRoles: {
    display: 'block',
    width: 300,
  },
  actions_crew: {
    display: 'block',
    width: 100,
  },
  actions_instrumentalists: {
    display: 'block',
    width: 100,
  },
  actions_otherInstrumentalists: {
    display: 'block',
    width: 100,
  },
  professionSelectDropdown: {
    width: '100%',
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'break-spaces',
    '& .MuiInputLabel-formControl': {
      transform: 'none',
    },
  },
  actionsCTA: {
    minWidth: 'max-content',
    display: 'flex',
    columnGap: '20px',
  },
  modalPagination: {
    width: '250px',
  },
  targetLangContainer: {
    display: 'flex',
    borderTop: '1px solid grey',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 0',
  },
});

const useSaveHandlers = () => {
  const notify = useNotify();
  const refresh = useRefresh();

  const onFailure = (error) => {
    notify(`${error.message}`, 'warning');
    refresh();
  };

  const onSuccess = () => {
    notify('ra.notification.updated', 'info', {
      smart_count: 1,
    });
    refresh();
  };

  return { onFailure, onSuccess };
};

const updateWork = (workId, data, lang) =>
  dataProvider(UPDATE, `works`, {
    id: workId,
    data,
    query: {
      strict: true,
      lang,
    },
  });

const tabs = {
  WORK: 'work',
  WORK_LANG: 'work_by_lang',
  ROLES: 'roles',
  ROLES_LANG: 'roles_by_lang',
  CREATORS: 'creators',
};

const SaveWorkOrRoles = (props) => {
  const { initial, tab } = props;
  const workId = initial.id;
  const [update] = useUpdate('works', workId);
  const { onSuccess, onFailure } = useSaveHandlers();

  const handleSave = useCallback(
    (values) => {
      const { __versions: newVersions, ...valuesData } = values;

      if (tab === tabs.WORK_LANG) {
        Promise.all(
          Object.keys(newVersions).map((lang) => {
            const record = newVersions[lang];
            return updateWork(
              workId,
              {
                name: record.name,
                variants: record?.variants,
              },
              lang,
            );
          }),
        ).then(onSuccess, onFailure);
      } else if (tab === tabs.ROLES_LANG) {
        Promise.all(
          Object.keys(newVersions).map((lang) => {
            const record = newVersions[lang];
            const { roles } = record;
            const rolesMap = roles?.map((role) => ({
              id: role?.id,
              name: role?.name || '',
            }));

            return updateWork(
              workId,
              {
                roles: rolesMap,
              },
              lang,
            );
          }),
        ).then(onSuccess, onFailure);
      } else if (tab === tabs.ROLES) {
        update(
          {
            payload: {
              data: {
                ...valuesData,
                roles: [...valuesData?.allRoles, ...valuesData?.nonStandardizedRoles],
              },
            },
          },
          {
            onSuccess,
            onFailure,
          },
        );
      } else {
        const { composer_ids, ...restData } = valuesData;
        if (restData.premiere) {
          restData.premiere.date = null;
        }
        update(
          {
            payload: {
              data: restData,
            },
          },
          {
            onSuccess,
            onFailure,
          },
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tab, onSuccess, onFailure, workId, update, props?.workType],
  );
  // set onSave props instead of handleSubmitWithRedirect
  return <SaveButton {...props} onSave={handleSave} />;
};

const CustomToolbar = ({ tab, ...props }) => {
  const [record] = useState(props.record);
  return (
    <Toolbar {...props}>
      <SaveWorkOrRoles initial={record} tab={tab} workType={props?.workType} />
      {/* <DeleteButton undoable={false} /> */}
    </Toolbar>
  );
};

const CreateWorkRoleButton = ({ record, roleTab }) => (
  <Button
    component={Link}
    to={{
      pathname: '/works/roles/create',
      search: `?source=${JSON.stringify({ work: { id: record.id, name: record.original_name } })}&roleTypeId=${roleTab}`,
    }}
    variant="outlined"
    color="primary"
  >
    Add Role
  </Button>
);

const WorksFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Work ID" source="id" alwaysOn />
    <TextInput label="Search" source="query" alwaysOn />
    <ReferenceInput
      label="Creator"
      source="creator_profile_id"
      filter={{ profession_id: 5 }}
      sort={{ field: 'creation_count', order: 'DESC' }}
      reference="profiles"
      filterToQuery={(searchText) => ({ query: searchText })}
      alwaysOn
      allowEmpty
    >
      <AutocompleteInput optionText={(profile) => (profile && profile.name ? `(${profile.id}) - ${profile.name}` : 'All')} matchSuggestion={() => true} />
    </ReferenceInput>
    <SelectInput label="Creator profession" source="creator_profession" choices={CREATOR_PROFESSIONS} optionText="name" optionValue="id" alwaysOn />
    <ReferenceInput label="Work type" source="work_type_id" reference="works/types" filterToQuery={(searchText) => ({ query: searchText })} alwaysOn allowEmpty>
      <AutocompleteInput optionText="name" matchSuggestion={() => true} />
    </ReferenceInput>
    <SelectInput label="Validation status" source="validation_status" choices={VALIDATION_STATUSES} alwaysOn />
  </Filter>
);

export const WorksShowActions = (props) => {
  const { basePath, data } = props;
  return (
    <TopToolbar>
      <PublicLink {...props} />
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );
};

const TabPanel = (props) => {
  const { children, value, index } = props;

  return value === index && children;
};

const ProfessionsDropdown = ({ options, selectedOption = [], onChangeHandler, onOpen, onInputChange }) => {
  const classes = useStyles();

  return (
    <Autocomplete
      id="tags-standard"
      className={classes.professionSelectDropdown}
      onOpen={() => onOpen()}
      onInputChange={(event, inputValue) => {
        if (event?.type !== 'click' && inputValue?.length > 2) {
          onInputChange(inputValue);
        }
      }}
      options={options || []}
      getOptionLabel={(option) => option?.name}
      defaultValue={selectedOption || []}
      onChange={(event, newValue) => {
        event.preventDefault();
        event.stopPropagation();
        onChangeHandler(newValue);
      }}
      renderInput={(params) => <MUITextField {...params} variant="standard" placeholder="Profession" />}
    />
  );
};

const SelectDropdown = memo(function SelectDropdown({ errorMsg, selectedOption, options, onChangeHandler, showError, styles, onOpen }) {
  const classes = useStyles();
  return (
    <FormControl className={styles?.root} size="small" error={showError}>
      {showError && (
        <InputLabel id="demo-select-small-label" className={classes.error}>
          {errorMsg}
        </InputLabel>
      )}
      <Select {...(onOpen && { onOpen })} value={selectedOption} onChange={onChangeHandler} autoWidth>
        {options.map((item, index) => (
          <MenuItem key={`${item?.id}-${index}`} value={item}>
            {item?.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});

const ProductionRolesModal = ({ props, workId, sourceWorkRole, onSubmit }) => {
  const classes = useStyles();

  return (
    <div>
      <ReferenceManyField
        {...props}
        reference="works/roles"
        target="work_id"
        filter={{ include_production_count: true, work_role_type_id: sourceWorkRole?.typeId, work_id: workId }}
        sort={{ field: 'roleOrder', order: 'ASC' }}
        pagination={<RaPagination rowsPerPageOptions={[]} />}
      >
        <Datagrid>
          <TextField source="id" sortable={false} label="Id" />
          <TextField source="name" sortable={false} label="Name" />
          <FunctionField source="is_standard" label="Type" sortable={false} render={(record) => <div>{record?.is_standard ? 'Standardised' : 'Unstandartised'}</div>} />
          <FunctionField
            label="Action"
            render={(record) => (
              <div className={classes.actionsCTA}>
                {sourceWorkRole?.id !== record?.id && (
                  <Button className={classes.button} size="small" variant="contained" onClick={(event) => onSubmit(event, sourceWorkRole, record)}>
                    Proceed to merge
                  </Button>
                )}
              </div>
            )}
          />
        </Datagrid>
      </ReferenceManyField>
    </div>
  );
};

const ProductionModal = ({ props, workId, selectedEntity, isProceedToMerge, setProductionModalOpen, setSelectedProduction }) => {
  const classes = useStyles();
  return (
    <ReferenceManyField
      {...props}
      show
      label="Productions"
      reference="productions"
      target="work_id"
      filter={{ work_id: workId, ...(!selectedEntity?.key ? { work_role_id: selectedEntity?.id } : { work_role_reference: selectedEntity?.name }) }}
      pagination={<RaPagination rowsPerPageOptions={[]} />}
      perPage={10}
    >
      <>
        {isProceedToMerge && (
          <div style={{ display: 'flex', alignItems: 'center', columnGap: '10px' }}>
            <Typography variant="div">Are you sure to merge:</Typography>
            <Button
              className={classes.proceedToMerge}
              size="small"
              variant="contained"
              onClick={() => {
                setProductionModalOpen(false);
                setSelectedProduction(true);
              }}
            >
              Proceed to merge
            </Button>
          </div>
        )}
        <Datagrid size="small">
          <TextField source="id" sortable={false} />
          <FunctionField label="Name" render={(record) => (record?.name ? record?.name : record?.productionWorks?.map((w) => w?.work?.name).join(', '))} />
          <FunctionField label="Link" className={classes.entityLink} render={(record) => <EntityLink newTab entity={record} entityType="productions" />} />
        </Datagrid>
      </>
    </ReferenceManyField>
  );
};

const Roles = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const raDataProvider = useDataProvider();

  const [selectedEntity, setSelectedEntity] = useState(undefined);
  const [isProductionModalOpen, setProductionModalOpen] = useState(false);
  const [selectedProduction, setSelectedProduction] = useState(null);
  const [isProceedToMerge, setProceedToMerge] = useState(false);
  const [isUpdateRole, setIsUpdateRole] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [professions, setProfessions] = useState(null);
  const [showLanguageError, setShowLanguageError] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedProfession, setSelectedProfession] = useState(null);
  const [selectedRoleTranslation, setSelectedRoleTranslation] = useState(null);
  const [showWorkRoleProfessionModal, setShowWorkRoleProfessionModal] = useState(false);

  useEffect(() => {
    if (selectedEntity?.id) {
      apiClient.get(`works/roles/${selectedEntity?.id}/translations`).then((res) => {
        setSelectedRoleTranslation(res?.data?.data?.reduce((result, translation) => `${result}${result?.length > 0 ? ', ' : ''}${translation?.language?.name}: "${translation?.name}"`, ''));
      });
    }
  }, [selectedEntity]);
  const workId = props?.record?.id;

  const [updateRole] = useUpdate(`works/${workId}/roles`);

  const handleUpdateRole = () => {
    updateRole(
      {
        payload: {
          id: selectedEntity?.id,
          data: {
            name: selectedEntity?.name,
            is_standard: !selectedEntity?.is_standard,
          },
        },
      },
      {
        onSuccess: () => {
          notify('Updated successfully');
          setIsUpdateRole(false);
          refresh();

          if (props.onCLose) {
            props.onCLose();
          }
        },
        onFailure: (err) => {
          notify(err?.message, 'error');
        },
      },
    );
  };

  useEffect(() => {
    if (isProceedToMerge && !languages?.length) {
      raDataProvider.getList('languages', { filter: {}, pagination: {}, sort: {} }).then(({ data }) => {
        setLanguages([{ id: -1, name: 'Select Language' }, ...data]);
      });
    }
  }, [raDataProvider, isProceedToMerge, languages]);

  const onLanguageChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.target.value !== -1) {
      setSelectedLanguage(event.target.value);
      setShowLanguageError(false);
    }
  };

  const onProfessionSelected = (record, data) => {
    if (data && record?.id) {
      dataProvider(UPDATE, `works/roles`, {
        id: record?.id,
        data: {
          ...data,
          workRoleType: {
            id: record?.workRoleType?.id,
          },
        },
      })
        .then(() => {
          setSelectedProfession([]);
          notify('Updated successfully');
          refresh();
        })
        .catch((error) => {
          notify(error?.message);
        });
    }
  };

  const onFetchCharacterRoles = (query) => {
    setProfessions(null);
    raDataProvider.getList('professions', { filter: { parent_profession_slug: 'singer', limit: 10, validation_status: 'approved', query }, pagination: {}, sort: {} }).then(({ data }) => {
      setProfessions(data);
    });
  };

  const onFetchInstrumentalRole = (query) => {
    setProfessions(null);
    raDataProvider.getList('instruments/roles', { filter: { limit: 10, validation_status: 'approved', query }, pagination: {}, sort: {} }).then(({ data }) => {
      setProfessions(data);
    });
  };

  const handleWorkRoleDelete = (record) => {
    if (record?.id) {
      dataProvider(DELETE, `works/roles`, {
        id: record?.id,
      })
        .then(() => {
          notify('Updated successfully');
          refresh();
        })
        .catch((error) => {
          notify(error?.message);
        });
    }
  };

  const mergeWorkRole = (event, source, target, selectedProfessions, primaryProfession) => {
    event.preventDefault();
    event.stopPropagation();

    const payload = {
      entityType: MERGE_ENTITIES.workRoles.id,
      sourceId: source?.id,
      targetId: target?.id,
      targetLanguageId: selectedLanguage?.id,
      metaInfo: { workRoleProfessions: selectedProfessions?.map((selProfession) => ({ ...selProfession, isPrimary: selProfession?.id === primaryProfession })) },
    };
    setShowLanguageError(false);

    raDataProvider
      .create('validations/merge', { data: payload })
      .then(() => {
        notify('Merged successfully');
        setProceedToMerge(false);
        setSelectedProduction(null);
        refresh();
        setShowWorkRoleProfessionModal(null);
      })
      .catch((error) => {
        notify(error?.message);
        setShowWorkRoleProfessionModal(null);
      });
  };

  const onProceedToMerge = (event, source, target) => {
    event.preventDefault();
    event.stopPropagation();

    if (!selectedLanguage?.id || !selectedLanguage?.id === -1) {
      setShowLanguageError(true);
      return;
    }

    if (source?.workRoleProfessions?.length < 1 && target?.workRoleProfessions?.length < 1) {
      mergeWorkRole(event, source, target);
      return;
    }

    setShowWorkRoleProfessionModal({ isOpen: true, source, target });
  };

  const renderCharacterRoles = () => (
    <div className={classes.cast}>
      <Typography variant="h6">Character Roles</Typography>
      <ReferenceManyField
        key="characterRole"
        {...props}
        reference="works/roles"
        target="work_id"
        sort={{ field: 'roleOrder', order: 'ASC' }}
        pagination={<RaPagination rowsPerPageOptions={[]} />}
        filter={{ include_production_count: true, work_role_type_id: 1, is_standard: true, work_id: workId }}
      >
        <Datagrid>
          <FunctionField
            source="original_name"
            sortable={false}
            className={classes.originalName}
            label="Original Name"
            render={(record) => (
              <Link style={{ width: 120 }} to={`/works/roles/${record?.id}/show`} onClick={(e) => e.stopPropagation()}>
                {record?.original_name}
              </Link>
            )}
          />
          <FunctionField
            className={classes.profession}
            label="Professions"
            render={(record) => (
              <>
                {record?.workRoleProfessions?.length > 0 ? (
                  record?.workRoleProfessions?.map((roleProfession) => (
                    <>
                      <Link style={{ width: 120 }} to={`/professions/${roleProfession?.profession?.id}/show`} onClick={(e) => e.stopPropagation()}>
                        {roleProfession?.profession?.name}
                      </Link>
                      <br />
                    </>
                  ))
                ) : (
                  <ProfessionsDropdown
                    onInputChange={onFetchCharacterRoles}
                    selectedOption={selectedProfession}
                    options={professions}
                    onOpen={onFetchCharacterRoles}
                    onChangeHandler={(value) =>
                      onProfessionSelected(record, {
                        name: record?.name,
                        profession: {
                          id: value?.id,
                        },
                      })
                    }
                  />
                )}
              </>
            )}
          />
          <FunctionField
            className={classes.productionCount}
            source="productionCount"
            label="Production count"
            sortBy="production_count"
            render={(record) =>
              record?.productionCount && (
                <Link
                  to="#"
                  onClick={() => {
                    setSelectedEntity({ ...record, typeId: 1 });
                    setProductionModalOpen(true);
                  }}
                >
                  {record?.productionCount}
                </Link>
              )
            }
          />
          <FunctionField
            label="Actions"
            className={classes.actions_cast}
            render={(record) => (
              <div className={classes.actionsCTA}>
                {record?.workRoleType?.id === 1 && (
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => {
                      setProceedToMerge(true);
                      setSelectedEntity({ ...record, typeId: 1 });
                      setProductionModalOpen(true);
                    }}
                    className={classes.button}
                  >
                    Merge
                  </Button>
                )}
                {!record?.productionCount > 0 && (
                  <Button size="small" variant="contained" onClick={() => handleWorkRoleDelete(record)} className={classes.button}>
                    Delete
                  </Button>
                )}
                {record?.is_standard && (
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => {
                      setIsUpdateRole(true);
                      setSelectedEntity({ ...record, typeId: 1 });
                    }}
                    className={classes.button}
                  >
                    Unstandardize
                  </Button>
                )}
              </div>
            )}
          />
        </Datagrid>
      </ReferenceManyField>
    </div>
  );

  const renderCrew = () => (
    <div className={classes.crew}>
      <Typography variant="h6">Crew</Typography>
      <ReferenceManyField {...props} reference="productions/aggregations" filter={{ aggregation_type: 'profession_id', validation_status: 'approved', availability: 'casting_tool', work_id: workId }}>
        <Datagrid>
          <FunctionField
            source="name"
            sortable={false}
            label="Name"
            render={(record) => (
              <Link style={{ width: 120 }} to={`/professions/${record?.id}/show`} onClick={(e) => e.stopPropagation()}>
                {record?.name}
              </Link>
            )}
          />
          <FunctionField
            className={classes.productionCount}
            source="total"
            label="Production count"
            sortBy="total"
            render={(record) =>
              record?.total && (
                <Link
                  to="#"
                  onClick={() => {
                    setSelectedEntity(record);
                    setProductionModalOpen(true);
                  }}
                >
                  {record?.total}
                </Link>
              )
            }
          />
        </Datagrid>
      </ReferenceManyField>
    </div>
  );

  const renderInstrumentals = () => (
    <div className={classes.instrumentalist}>
      <Typography variant="h6">Instrumentals</Typography>
      <ReferenceManyField
        {...props}
        reference="works/roles"
        target="work_id"
        sort={{ field: 'roleOrder', order: 'ASC' }}
        pagination={<RaPagination rowsPerPageOptions={[]} />}
        filter={{ include_production_count: true, work_role_type_id: 3, is_standard: true, work_id: workId }}
      >
        <Datagrid>
          <FunctionField
            className={classes.profession}
            label="Name"
            render={(record) =>
              record?.instrumentRole?.name ? (
                <Link to={`/instruments/roles/${record?.instrumentRole?.id}/show`} onClick={(e) => e.stopPropagation()}>
                  {record?.instrumentRole?.name}
                </Link>
              ) : (
                <ProfessionsDropdown
                  onInputChange={onFetchInstrumentalRole}
                  selectedOption={selectedProfession}
                  options={professions}
                  onOpen={onFetchInstrumentalRole}
                  onChangeHandler={(value) =>
                    onProfessionSelected(record, {
                      instrumentRole: {
                        id: value?.id,
                      },
                    })
                  }
                />
              )
            }
          />
          <FunctionField
            sortable={false}
            className={classes.originalName}
            label="Type"
            render={(record) => (
              <Link to={`/instruments/${record?.instrumentRole?.instrument?.id}/show`} onClick={(e) => e.stopPropagation()}>
                <div className={classes.centerAlign}>{record?.instrumentRole?.instrument?.name}</div>
              </Link>
            )}
          />
          <FunctionField
            className={classes.profession}
            label="Professions"
            render={(record) => (
              <Link style={{ width: 120 }} to={`/professions/${record?.profession?.id}/show`} onClick={(e) => e.stopPropagation()}>
                {record?.profession?.name}
              </Link>
            )}
          />
          <TextField source="original_name" label="Old Name" />
          <FunctionField
            className={classes.productionCount}
            source="total"
            label="Production count"
            sortable={false}
            render={(record) =>
              record?.productionCount && (
                <Link
                  to="#"
                  onClick={() => {
                    setSelectedEntity({ ...record, typeId: 3 });
                    setProductionModalOpen(true);
                  }}
                >
                  {record?.productionCount}
                </Link>
              )
            }
          />
          <FunctionField
            label="Actions"
            className={classes.actions_cast}
            render={(record) => (
              <div className={classes.actionsCTA}>
                {record?.workRoleType?.id === 3 && (
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => {
                      setProceedToMerge(true);
                      setSelectedEntity({ ...record, typeId: 3 });
                      setProductionModalOpen(true);
                    }}
                    className={classes.button}
                  >
                    Merge
                  </Button>
                )}
                {!record?.productionCount > 0 && (
                  <Button size="small" variant="contained" onClick={() => handleWorkRoleDelete(record)} className={classes.button}>
                    Delete
                  </Button>
                )}
                {record?.is_standard && (
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => {
                      setIsUpdateRole(true);
                      setSelectedEntity({ ...record, typeId: 3 });
                    }}
                    className={classes.button}
                  >
                    Unstandardize
                  </Button>
                )}
              </div>
            )}
          />
        </Datagrid>
      </ReferenceManyField>
    </div>
  );

  const renderOtherCharacterRoles = () => (
    <div className={classes.other}>
      <Typography variant="h6">Other Character Roles</Typography>
      <ReferenceManyField
        key="otherCharacterRole"
        {...props}
        reference="works/roles"
        target="work_id"
        sort={{ field: 'roleOrder', order: 'ASC' }}
        pagination={<RaPagination rowsPerPageOptions={[]} />}
        filter={{ include_production_count: true, work_role_type_id: 1, is_standard: false, work_id: workId }}
      >
        <Datagrid>
          <FunctionField
            source="original_name"
            sortable={false}
            className={classes.originalName}
            label="Original Name"
            render={(record) => (
              <Link style={{ width: 120 }} to={`/works/roles/${record?.id}/show`} onClick={(e) => e.stopPropagation()}>
                {record?.original_name}
              </Link>
            )}
          />
          <FunctionField
            className={classes.profession}
            label="Professions"
            render={(record) => {
              if (record?.workRoleType?.id === 3) {
                return record?.instrumentRole?.instrument?.instrumentalist?.name ? <div>{record?.instrumentRole?.instrument?.instrumentalist?.name}</div> : null;
              }
              return (
                <>
                  {record?.workRoleProfessions?.length > 0 ? (
                    record?.workRoleProfessions?.map((roleProfession) => (
                      <Link style={{ width: 120 }} to={`/professions/${roleProfession?.profession?.id}/show`} onClick={(e) => e.stopPropagation()}>
                        {roleProfession?.profession?.name}
                      </Link>
                    ))
                  ) : (
                    <ProfessionsDropdown
                      onInputChange={onFetchCharacterRoles}
                      selectedOption={selectedProfession}
                      options={professions}
                      onOpen={onFetchCharacterRoles}
                      onChangeHandler={(value) =>
                        onProfessionSelected(record, {
                          name: record?.name,
                          profession: {
                            id: value?.id,
                          },
                        })
                      }
                    />
                  )}
                </>
              );
            }}
          />
          <FunctionField
            className={classes.productionCount}
            label="Production count"
            sortBy="production_count"
            render={(record) =>
              record?.productionCount && (
                <Link
                  to="#"
                  onClick={() => {
                    setSelectedEntity({ ...record, typeId: 1 });
                    setProductionModalOpen(true);
                  }}
                >
                  {record?.productionCount}
                </Link>
              )
            }
          />
          <FunctionField
            label="Actions"
            className={classes.actions_cast}
            render={(record) => (
              <div className={classes.actionsCTA}>
                {record?.workRoleType?.id === 1 && (
                  <>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => {
                        setProceedToMerge(true);
                        setSelectedEntity({ ...record, typeId: 1 });
                        setProductionModalOpen(true);
                      }}
                      className={classes.button}
                    >
                      Merge
                    </Button>
                    {!record?.productionCount > 0 && (
                      <Button size="small" variant="contained" onClick={() => handleWorkRoleDelete(record)} className={classes.button}>
                        Delete
                      </Button>
                    )}
                    {!record?.is_standard && (
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() => {
                          setIsUpdateRole(true);
                          setSelectedEntity({ ...record, typeId: 3 });
                        }}
                        className={classes.button}
                      >
                        Standardize
                      </Button>
                    )}
                  </>
                )}
              </div>
            )}
          />
        </Datagrid>
      </ReferenceManyField>
    </div>
  );

  const renderOtherInstrumentals = () => (
    <div className={classes.other_instrumentalist}>
      <Typography variant="h6">Other Instrumentals</Typography>
      <ReferenceManyField
        key="otherInstrumentals"
        {...props}
        reference="works/roles"
        target="work_id"
        sort={{ field: 'roleOrder', order: 'ASC' }}
        pagination={<RaPagination rowsPerPageOptions={[]} />}
        filter={{ include_production_count: true, work_role_type_id: 3, is_standard: false, work_id: workId }}
      >
        <Datagrid>
          <FunctionField
            className={classes.profession}
            label="Name"
            render={(record) =>
              record?.instrumentRole?.name ? (
                <Link to={`/instruments/roles/${record?.instrumentRole?.id}/show`} onClick={(e) => e.stopPropagation()}>
                  {record?.instrumentRole?.name}
                </Link>
              ) : (
                <ProfessionsDropdown
                  onInputChange={onFetchInstrumentalRole}
                  selectedOption={selectedProfession}
                  options={professions}
                  onOpen={onFetchInstrumentalRole}
                  onChangeHandler={(value) =>
                    onProfessionSelected(record, {
                      instrumentRole: {
                        id: value?.id,
                      },
                    })
                  }
                />
              )
            }
          />
          <FunctionField
            sortable={false}
            className={classes.originalName}
            label="Type"
            render={(record) => (
              <Link to={`/instruments/${record?.instrumentRole?.instrument?.id}/show`} onClick={(e) => e.stopPropagation()}>
                <div className={classes.centerAlign}>{record?.instrumentRole?.instrument?.name}</div>
              </Link>
            )}
          />
          <FunctionField
            className={classes.profession}
            label="Professions"
            render={(record) => {
              return (
                <>
                  {record?.profession?.id ? (
                    <Link style={{ width: 120 }} to={`/professions/${record?.profession?.id}/show`} onClick={(e) => e.stopPropagation()}>
                      {record?.profession?.name}
                    </Link>
                  ) : (
                    <ProfessionsDropdown
                      onInputChange={onFetchCharacterRoles}
                      selectedOption={selectedProfession}
                      options={professions}
                      onOpen={onFetchCharacterRoles}
                      onChangeHandler={(value) =>
                        onProfessionSelected(record, {
                          name: record?.name,
                          profession: {
                            id: value?.id,
                          },
                        })
                      }
                    />
                  )}
                </>
              );
            }}
          />
          <TextField source="original_name" label="Old Name" />
          <FunctionField
            className={classes.productionCount}
            label="Production count"
            sortBy="production_count"
            render={(record) =>
              record?.productionCount && (
                <Link
                  to="#"
                  onClick={() => {
                    setSelectedEntity({ ...record, typeId: 3 });
                    setProductionModalOpen(true);
                  }}
                >
                  {record?.productionCount}
                </Link>
              )
            }
          />
          <FunctionField
            label="Actions"
            className={classes.actions_cast}
            render={(record) => (
              <div className={classes.actionsCTA}>
                {record?.workRoleType?.id === 3 && (
                  <>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => {
                        setProceedToMerge(true);
                        setSelectedEntity({ ...record, typeId: 3 });
                        setProductionModalOpen(true);
                      }}
                      className={classes.button}
                    >
                      Merge
                    </Button>
                    {!record?.productionCount > 0 && (
                      <Button size="small" variant="contained" onClick={() => handleWorkRoleDelete(record)} className={classes.button}>
                        Delete
                      </Button>
                    )}
                    {!record?.is_standard && (
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() => {
                          setIsUpdateRole(true);
                          setSelectedEntity({ ...record, typeId: 3 });
                        }}
                        className={classes.button}
                      >
                        Standardize
                      </Button>
                    )}
                  </>
                )}
              </div>
            )}
          />
        </Datagrid>
      </ReferenceManyField>
    </div>
  );

  return (
    <div>
      <div className={classes.sectionContainer}>
        {renderCharacterRoles()}
        {renderCrew()}
        {renderInstrumentals()}
        {renderOtherCharacterRoles()}
        {renderOtherInstrumentals()}
      </div>
      <Confirm
        isOpen={isUpdateRole}
        title={`${selectedEntity?.is_standard ? 'Unstandardize' : 'Standardize'}  ${selectedEntity?.name}`}
        content={`Are you sure you want to ${selectedEntity?.is_standard ? 'un' : ''}standardize the role`}
        onConfirm={handleUpdateRole}
        onClose={() => {
          setSelectedEntity(null);
          setIsUpdateRole(false);
        }}
      />
      <Dialog
        PaperProps={{
          className: classes.productionDialog,
        }}
        open={isProductionModalOpen || isProceedToMerge}
        onClose={() => {
          setProductionModalOpen(false);
          setProceedToMerge(false);
          setSelectedProduction(null);
          setSelectedLanguage(null);
        }}
      >
        <DialogTitle style={{ padding: '16px 24px 8px' }}>
          <div className={classes.dialogTitle}>
            <Typography variant="h6">{`"${selectedEntity?.name}" (Id:${selectedEntity?.id}) ${selectedRoleTranslation && `(${selectedRoleTranslation})`}`}</Typography>
            <div className={classes.targetLangContainer}>
              {selectedProduction && (
                <>
                  <Typography variant="h6">Search work with work role to find what language it is presented in</Typography>
                  <SelectDropdown
                    styles={{ root: classes.languageSelectDropdown }}
                    errorMsg="Please select language"
                    selectedOption={selectedLanguage || languages?.[0]}
                    options={languages}
                    onChangeHandler={onLanguageChange}
                    showError={showLanguageError}
                  />
                </>
              )}
            </div>
          </div>
        </DialogTitle>
        <DialogContent style={{ padding: '0 24px 8px 24px' }}>
          {isProductionModalOpen && (
            <ProductionModal
              props={props}
              workId={workId}
              selectedEntity={selectedEntity}
              isProceedToMerge={isProceedToMerge}
              setProductionModalOpen={setProductionModalOpen}
              setSelectedProduction={setSelectedProduction}
            />
          )}
          {selectedProduction && <ProductionRolesModal props={props} workId={workId} sourceWorkRole={selectedEntity} selectedProduction={selectedProduction} onSubmit={onProceedToMerge} />}
        </DialogContent>
      </Dialog>
      {showWorkRoleProfessionModal?.isOpen && (
        <WorkRoleProfessionModal
          onSubmit={mergeWorkRole}
          source={showWorkRoleProfessionModal?.source}
          target={showWorkRoleProfessionModal?.target}
          setShowWorkRoleProfessionModal={setShowWorkRoleProfessionModal}
        />
      )}
    </div>
  );
};

export const WorksShow = (props) => (
  <ValidityShow {...props} title={<Breadcrumbs {...props} />} actions={<WorksShowActions />}>
    <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" {...props} />}>
      <Tab label="Work Info">
        <CustomShowLayout {...props} isStats>
          {{
            column1: (
              <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="original_name" label="Original name" />
                <ArrayField source="workTypes" label="Work Types">
                  <Datagrid rowClick={(path, resource, record) => `/workTypes/${record?.workType?.id}/show`}>
                    <TextField source="id" />
                    <TextField source="workType.id" />
                    <TextField source="workType.name" />
                  </Datagrid>
                </ArrayField>
                <ArrayField source="relatedWorkTypes" label="Related Work Types">
                  <Datagrid rowClick={(path, resource, record) => `/workTypes/${record?.id}/show`}>
                    <TextField source="id" />
                    <TextField source="name" />
                  </Datagrid>
                </ArrayField>
                <BooleanField source="is_brief" label="Is brief?" />
                <BooleanField source="is_rare" label="Is rare?" />
                <BooleanField source="is_children" label="Is children?" />
              </SimpleShowLayout>
            ),
            column2: (
              <SimpleShowLayout>
                <ArrayField label="External References" emptyText="Empty">
                  <ExternalReferencesTab entityType={ENTITIES.WORK} entityId={props?.id} displayUrl={1} />
                </ArrayField>
                <TextField source="premiere.date" label="Premiere date" />{' '}
                <FunctionField
                  render={(record) => {
                    let producedBy;
                    if (record?.premiere?.organization) {
                      producedBy = `Organization - ${record?.premiere?.organization?.name}`;
                    }
                    if (record?.premiere?.producerReference) {
                      producedBy = `Reference - ${record.premiere.producerReference}`;
                    }
                    return producedBy;
                  }}
                  label="Produced by"
                />
                <TextField source="premiere.venue.name" label="Premiere venue" />
                <TextField source="workType.name" label="Work Type" />
                <TextField source="meta_info.source" label="Source" />
                <TextField source="meta_info.source_url" label="Source URL" />
                <ReferenceField label="Musical Work Language" source="language.id" reference="languages" link={false}>
                  <TextField source="name" label="Musical Work Language" />
                </ReferenceField>
                <FunctionField
                  render={(record) => {
                    const composers = getComposersFromCreators(record?.creators);
                    return map(composers, (composer) => <EntityLink entity={composer?.profile} entityType="profiles" />);
                  }}
                  label="Composers"
                />
                <FunctionField
                  render={(record) => {
                    const librettists = getLibrettistsCFromCreators(record?.creators);
                    return map(librettists, (librettist) => <EntityLink entity={librettist?.profile} entityType="profiles" />);
                  }}
                  label="Librettist"
                />
                <TextField source="language.name" label="Liberetto Language" />
              </SimpleShowLayout>
            ),
          }}
        </CustomShowLayout>
      </Tab>
      <Tab label="Work Title Translations">
        <EntityTranslation list={{ url: `works/${props?.id}/translations` }} update={{ url: `works/${props?.id}/translations` }} entity={{ name: 'works', id: props?.id }} />
      </Tab>

      <Tab label="media">
        <ReferenceManyField filter={{ media_tag_type_id: 8 }} sort={{ field: 'createdAt', order: 'DESC' }} label="Media Records" reference="media" target="media_tag_id">
          <Datagrid rowClick="show">
            <TextField source="id" sortable={false} />
            <TextField source="mediaType.name" label="Type" sortable={false} />
            <MediaThumbnailField label="Image" />
            <TextField source="name" label="Title" sortable={false} />
            <TextField source="description" label="Description" sortable={false} />
            <DateField source="createdAt" showTime />
            <TextField source="status.name" label="Status Name" sortable={false} />
          </Datagrid>
        </ReferenceManyField>
      </Tab>

      <Tab label="Roles">
        <Roles />
      </Tab>
      <Tab label="Roles Translation">
        <RoleTranslation />
      </Tab>
      <Tab label="Creators">
        <ArrayField source="creators">
          <Datagrid>
            <TextField source="id" />
            <FunctionField label="Profile" render={(entity) => <EntityLink entityType="profiles" entity={entity?.profile} />} />
            <TextField source="profession.name" label="Profession" />
            <TextField source="description" label="Description" />
          </Datagrid>
        </ArrayField>
      </Tab>
      <Tab label="External References">
        <ExternalReferencesTab entityType={ENTITIES.WORK} entityId={props?.id} displayUrl={1} />
      </Tab>
    </TabbedShowLayout>
  </ValidityShow>
);

const useEditStyles = makeStyles({
  variants: {
    '& p': {
      display: 'none',
    },
    '& li': {
      border: 'none',
    },
    '& label': {
      top: -10,
    },
  },
  roles: {
    '& p': {
      display: 'none',
    },
    '& li': {
      overflowY: 'scroll',
    },
  },
  rolesWrapper: {
    width: 260,
    height: 90,
  },
  withVariants: {
    width: 285,
    height: 300,
  },
  dialogLink: {
    opacity: 0.7,
    cursor: 'pointer',
    '&:hover': {
      background: 0,
      opacity: 1,
    },
  },
  addCreatorBtn: {
    minWidth: 150,
    marginLeft: 15,
  },
  rowLayout: {
    display: 'flex',
    alignItems: 'center',
  },
  group: {
    display: 'flex',
    gap: '15px',
    width: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  standardField: {
    width: 256,
  },
  professionContainer: {
    minWidth: 200,
    paddingLeft: 10,
    marginTop: '18px',
    '& input': {
      height: '30px',
    },
  },
});

const RoleTranslation = (props) => {
  const workId = props?.record?.id;
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Alert variant="filled" severity="info" style={{ backgroundColor: 'grey', width: '90%', marginTop: '5px' }}>
        Only standardised roles shown
      </Alert>
      <MUITabs value={value} onChange={handleChange} indicatorColor="primary">
        <MUITab label="Character Roles" />
        <MUITab label="Instrumental Roles" />
      </MUITabs>
      {value === 0 && (
        <EntityTranslation
          list={{ url: `works/roles/translations?work_id=${workId}&is_standard=true&work_role_type_id=1&` }}
          update={{ url: `works/roles/translations` }}
          isRoles
          entity={{ name: 'works', id: workId }}
        />
      )}
      {value === 1 && (
        <EntityTranslation
          list={{ url: `works/roles/translations?work_id=${workId}&is_standard=true&work_role_type_id=3&` }}
          update={{ url: `works/roles/translations` }}
          isRoles
          isInstrumentRole
          entity={{ name: 'works', id: workId }}
        />
      )}
    </>
  );
};

const RolesListEdit = (props) => {
  const workId = props?.record?.id;
  const roleTab = props?.roleTab;
  const setRoleTab = props?.setRoleTab;
  const tabIndex = props?.tabIndex;
  const setTabIndex = props?.setTabIndex;
  const form = useForm();
  const raDataProvider = useDataProvider();
  const [roleTypes, setRoleTypes] = useState([]);
  const allRoles = form.getState()?.values?.allRoles;
  const nonStandardizedRoles = props?.record?.roles?.filter((role) => !role?.is_standard);
  const workRoles = allRoles?.length
    ? allRoles?.filter((role) => role?.workRoleType?.id === roleTab && role?.is_standard)
    : props?.record?.roles?.filter((role) => role?.workRoleType?.id === roleTab && role?.is_standard);
  const listContext = {
    data: keyBy(workRoles, 'id'),
    ids: workRoles?.map(({ id }) => id),
    total: workRoles?.length,
    currentSort: {},
    basePath: props?.basePath,
    resource: props?.resource,
    selectedIds: [],
    hasList: true,
  };

  useEffect(() => {
    raDataProvider.getList('works/roles/types', { filter: {}, pagination: {}, sort: {} }).then(({ data }) => {
      setRoleTypes(data);
      if (!roleTab) {
        setRoleTab(data?.[0]?.id);
      }
      form.change(
        'allRoles',
        props?.record?.roles?.filter((role) => role?.is_standard),
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.record?.roles, raDataProvider, workId]);

  return (
    <div>
      <MUITabs value={roleTab} onChange={(e, newTab) => setRoleTab(newTab)} indicatorColor="primary">
        {roleTypes?.map((roleType, index) => (
          <MUITab onClick={() => setTabIndex(index)} key={roleType?.id} label={roleType?.name} value={roleType?.id} />
        ))}
      </MUITabs>
      <TabPanel value={roleTab} index={roleTab}>
        <ListContextProvider value={listContext}>
          <SortableDatagrid rowClick="show" tabIndex={tabIndex} nonStandardizedRoles={nonStandardizedRoles}>
            <IconField icon={<DragIndicatorIcon fontSize="small" />} ariaLabel="Draggable row" />
            <TextField source="id" sortable={false} />
            <TextField source="original_name" label="Canonical Name" sortable={false} />
            <TextField source="workRoleType.name" label="Role type" sortable={false} />
            <FunctionField label="Role professions" render={(record) => record?.workRoleProfessions?.map((roleProfession) => <div>{roleProfession?.profession?.name}</div>)} />

            <FunctionField label="Edit" render={(record) => <EditButton basePath="/works/roles" record={record} />} />
            {/* <DeleteWithConfirmButton undoable={false} redirect={deleteRoleRedirect} /> */}
          </SortableDatagrid>
        </ListContextProvider>
      </TabPanel>
    </div>
  );
};

export const renderProfile = (profile) => {
  const profileType = profile?.profileType?.name ? ` - ${profile?.profileType?.name}` : '';
  const profileName = profile.name ? `${profile.name}` : 'Profile without name';

  return `(${profile.id}${profileType})  - ${profileName}`;
};

const ProducedByEditBlock = () => {
  const classes = useEditStyles();
  const form = useForm();
  const [producedStatus, setProducedStatus] = useState();

  useEffect(() => {
    const premiere = form.getState().values?.premiere;
    if (premiere?.organization) {
      form.change('producedBy', PRODUCED_BY.ORGANIZATION);
      setProducedStatus(PRODUCED_BY.ORGANIZATION);
    }
    if (premiere?.producerReference) {
      form.change('producedBy', PRODUCED_BY.REFERENCE);
      setProducedStatus(PRODUCED_BY.REFERENCE);
    }
    if (!premiere?.organization && !premiere?.producerReference) {
      form.change('producedBy', PRODUCED_BY.ORGANIZATION);
      setProducedStatus(PRODUCED_BY.ORGANIZATION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value) => {
    setProducedStatus(value);
    form.change('premiere.organization', null);
    form.change('premiere.producerReference', null);
  };

  return (
    <>
      <RadioButtonGroupInput
        source="producedBy"
        className={classes.fullWidth}
        onChange={handleChange}
        choices={[
          { id: PRODUCED_BY.ORGANIZATION, name: 'Organization' },
          { id: PRODUCED_BY.REFERENCE, name: 'Reference' },
        ]}
      />
      {producedStatus === PRODUCED_BY.ORGANIZATION && (
        <ReferenceInput
          label="Organization"
          source="premiere.organization.id"
          reference="organizations"
          filterToQuery={(searchText) => ({ query: searchText })}
          className={classes.standardField}
          allowEmpty
          perPage={25}
          sort={{ field: 'production_count', order: 'DESC' }}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      )}
      {producedStatus === PRODUCED_BY.REFERENCE && <TextInput className={classes.standardField} label="Reference" source="premiere.producerReference" />}
      <ReferenceInput label="Venue" source="premiere.venue.id" reference="venues" filterToQuery={(searchText) => ({ query: searchText })} className={classes.standardField} allowEmpty>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </>
  );
};

export const WorksEdit = (props) => {
  const classes = useEditStyles();
  const redirect = useRedirect();
  const raDataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const [profileOptions, setProfileOptions] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [selectedProfession, setSelectedProfession] = useState();
  const [description, setDescription] = useState(null);
  const match = useRouteMatch('/works/:id/:tab');
  let initialTab = tabs.WORK;
  if (match && match.params) {
    initialTab = Object.values(tabs)[match.params.tab];
  }
  const search = searchToObject(props?.location?.search);
  let roleTypeId;
  if (search?.roleTypeId) {
    roleTypeId = JSON.parse(search?.roleTypeId);
  }
  const [tab, setTab] = useState(initialTab);
  const [roleTab, setRoleTab] = useState(roleTypeId);
  const [tabIndex, setTabIndex] = useState(roleTypeId);

  const getProfiles = (query) => {
    raDataProvider
      .getList('profiles', {
        // eslint-disable-next-line no-restricted-globals
        filter: { query: isNaN(query) ? query : '', ...(!isNaN(query) && query ? { id: [parseInt(query, 0)] } : {}) },
        pagination: { limit: 25, page: 1 },
        sort: { field: 'relevance', order: 'DESC' },
      })
      .then(({ data }) => {
        setProfileOptions(data);
      });
  };

  const addCreator = () => {
    raDataProvider
      .create('works/creators', {
        data: {
          work: { id: props?.id },
          profile: { id: selectedProfile?.id },
          profession: { id: selectedProfession?.id },
          description,
        },
      })
      .then(() => {
        notify(`${selectedProfile?.name} added`);
        setSelectedProfile(null);
        setSelectedProfession(null);
        refresh();
      });
  };

  useEffect(() => {
    getProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Edit {...props} title={<Breadcrumbs {...props} />}>
      <TabbedForm {...(tab !== tabs.CREATORS ? { toolbar: <CustomToolbar {...props} tab={tab} /> } : { toolbar: false })}>
        <FormTab label="Work Info" onClick={() => setTab(tabs.WORK)}>
          <TextField source="id" />

          <TextInput label="Canonical Name" source="original_name" validate={[required()]} />

          <div className={classes.group}>
            <BooleanInput source="is_brief" label="Is brief?" />
            <BooleanInput source="is_rare" label="Is rare?" />
            <BooleanInput source="is_children" label="Is children?" />
          </div>

          <InputLabel>Premiere</InputLabel>
          <div className={classes.group}>
            <NumberInput type="number" source="premiere.year" label="Premiere year" />
            <NumberInput min={1} max={12} type="number" source="premiere.month" label="Premiere month" />
            <NumberInput min={1} max={31} type="number" source="premiere.day" label="Premiere day" />
          </div>

          <ProducedByEditBlock />
          <ArrayInput label="Work types" source="workTypes" validate={required()}>
            <SimpleFormIterator inline>
              <ReferenceInput label="Work types" reference="works/types" source="workType.id" filterToQuery={(searchText) => ({ query: searchText })} validate={required()}>
                <AutocompleteInput optionText="name" />
              </ReferenceInput>
            </SimpleFormIterator>
          </ArrayInput>

          <ReferenceInput label="Language" source="language.id" reference="languages" filter={{ limit: 256 }} sort={{ field: 'name', order: 'ASC' }}>
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput label="Style of music" source="musicStyle.id" reference="works/styles" filterToQuery={(searchText) => ({ query: searchText })}>
            <SelectInput optionText="name" />
          </ReferenceInput>
        </FormTab>

        <FormTab label="Roles" onClick={() => setTab(tabs.ROLES)}>
          <CreateWorkRoleButton {...props} roleTab={roleTab} />
          <RolesListEdit {...props} setRoleTab={setRoleTab} roleTab={roleTab} tabIndex={tabIndex} setTabIndex={setTabIndex} />
        </FormTab>
        <FormTab label="Creators" onClick={() => setTab(tabs.CREATORS)}>
          <div className={classes.rowLayout}>
            <Autocomplete
              style={{ minWidth: 350 }}
              options={profileOptions}
              autoComplete
              value={selectedProfile}
              onChange={(event, profile) => {
                setSelectedProfile(profile);
              }}
              onInputChange={(event, input) => {
                getProfiles(input);
              }}
              renderInput={(params) => <MUITextField {...params} variant="filled" fullWidth label="Creators (Profile ID / Name)" />}
              renderOption={(option) => renderProfile(option)}
              getOptionLabel={(option) => renderProfile(option)}
              filterOptions={(option) => option}
            />
            {!profileOptions.length && !selectedProfile?.id && (
              <Button className={classes.addCreatorBtn} variant="contained" onClick={() => redirect('/profiles/create')}>
                Create profile
              </Button>
            )}
            <div className={classes.professionContainer}>
              <ReferenceInput
                label="Select profession"
                source="profession.id"
                reference="professions"
                filterToQuery={(searchText) => ({ query: searchText, validation_status: 'approved' })}
                sort={{ field: 'profile_count', order: 'DESC' }}
                alwaysOn
              >
                <AutocompleteInput optionText="name" variant="filled" onSelect={(v) => setSelectedProfession(v)} />
              </ReferenceInput>
            </div>
            <div>
              <MUITextField style={{ minWidth: 350 }} variant="filled" className={classes.addCreatorBtn} value={description} onChange={(e) => setDescription(e.target.value)} label="Description" />
            </div>
            <Button disabled={!selectedProfile?.id || !selectedProfession?.id} variant="contained" className={classes.addCreatorBtn} onClick={addCreator}>
              Add creator
            </Button>
          </div>
          <ReferenceManyField {...props} label="Creators" reference="works/creators" target="work_id">
            <Datagrid rowClick="show">
              <TextField source="id" />
              <FunctionField label="Profile" render={(entity) => <EntityLink entityType="profiles" entity={entity?.profile} />} />
              <TextField source="profession.name" label="Profession" />
              <TextField source="description" label="Description" />
              <DeleteButton undoable={false} redirect={`/works/${props?.id}/2`} />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const WorksCreate = (props) => {
  const classes = useEditStyles();

  const transform = (values) => {
    const changeEntity = (entity, typeId) => ({ profession: { id: typeId }, profile: { id: entity.id } });
    return {
      ...values,
      creators: [...(values.composersList.map((composer) => changeEntity(composer, 5)) || []), ...(values?.librettistsList?.map((librettist) => changeEntity(librettist, 16)) || [])],
    };
  };

  return (
    <Create {...props} transform={transform}>
      <SimpleForm>
        <TextInput label="Name" source="name" validate={required()} />

        <div className={classes.group}>
          <BooleanInput source="is_brief" label="Is brief?" />
          <BooleanInput source="is_rare" label="Is rare?" />
          <BooleanInput source="is_children" label="Is children?" />
        </div>

        <InputLabel>Premiere</InputLabel>
        <div className={classes.group}>
          <NumberInput type="number" source="premiere.year" label="Premiere year" />
          <NumberInput min={1} max={12} type="number" source="premiere.month" label="Premiere month" />
          <NumberInput min={1} max={31} type="number" source="premiere.day" label="Premiere day" />
        </div>

        <ProducedByEditBlock />
        <ReferenceInput label="Language" source="language.id" reference="languages" filterToQuery={(searchText) => ({ query: searchText })}>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>

        <ArrayInput label="Work types" source="workTypes" validate={required()}>
          <SimpleFormIterator inline>
            <ReferenceInput label="Work types" reference="works/types" source="workType.id" filterToQuery={(searchText) => ({ query: searchText })} validate={required()}>
              <AutocompleteInput optionText="name" />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>

        <ArrayInput label="Composers" source="composersList" validate={required()}>
          <SimpleFormIterator inline>
            <ReferenceInput label="Composer" reference="profiles" source="id" filterToQuery={(searchText) => ({ query: searchText, profession_id: 5 })} validate={required()}>
              <AutocompleteInput optionText={(composer) => (composer ? `${composer.firstName} ${composer.lastName} (${composer.name})` : '')} matchSuggestion={() => true} />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>

        <ArrayInput label="Librettists" source="librettistsList">
          <SimpleFormIterator inline>
            <ReferenceInput label="Librettist" reference="profiles" source="id" filterToQuery={(searchText) => ({ query: searchText, profession_id: 16 })} validate={required()}>
              <AutocompleteInput optionText={(composer) => (composer ? `${composer.firstName} ${composer.lastName} (${composer.name})` : '')} matchSuggestion={() => true} />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

export const WorksList = (props) => (
  <List
    {...props}
    exporter={false}
    title={<Breadcrumbs {...props} />}
    filters={<WorksFilter />}
    bulkActionButtons={false}
    sort={{ field: 'production_count', order: 'DESC' }}
    perPage={25}
    filterDefaultValues={{ validation_status: 'approved' }}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <FunctionField
        render={(record) => {
          const composerNames = getComposerNamesFromCreators(record?.creators);
          return composerNames;
        }}
        label="Composers"
      />
      <TextField source="name" />
      <TextField source="original_name" />
      <TextField source="workType.name" label="Work type" />
      <TextField source="stats.productionCount" sortBy="production_count" label="Production count" />
      <TextField label="Validation status" source="validationStatus.name" />
      <DateField source="createdAt" />
    </Datagrid>
  </List>
);

export default WorksList;
