import React from 'react';
import { map, get, zipObject } from 'lodash';
import cn from 'classnames';
import { TableContainer, Table, TableCell, TableBody, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CheckCircle as CheckCircleIcon, Clear as ClearIcon } from '@material-ui/icons';
import { getProfilePermissions } from '../utils/permissions';

const useStyles = makeStyles(() => ({
  bold: { fontWeight: 'bold' },
  table: {
    margin: 10,
    maxWidth: 400,
  },
}));

const PermissionsView = (props) => {
  const classes = useStyles();
  const selectedProfile = props?.selectedProfile;
  const profileSubscriptions = props?.profileSubscriptions;
  const profileTypeId = get(selectedProfile, 'profileType.id');

  const userPermissions = get(props, 'userPermissions', []);
  const profilePermissions = get(props, 'profilePermissions', []);
  const allPermissions = [...userPermissions, ...profilePermissions.map((permission) => ({ ...permission, identifier: permission?.value }))];
  const allPermissionsIdentifiers = map(allPermissions, 'identifier');
  const permissions = zipObject(allPermissionsIdentifiers, Array(allPermissionsIdentifiers.length).fill(true));

  const displayPermissions = getProfilePermissions({ profileSubscriptions, profileTypeId, permissions }) || [];

  if (!displayPermissions.length) {
    return null;
  }

  return (
    <TableContainer className={classes.table}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Permission</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {map(displayPermissions, (permission, index) => (
            <TableRow key={index}>
              <TableCell
                component="th"
                scope="row"
                className={cn({
                  [classes.bold]: permission?.isTitle,
                })}
              >
                {permission.name}
              </TableCell>
              {!permission?.hideValue ? <TableCell>{permission?.value() ? <CheckCircleIcon color="secondary" /> : <ClearIcon />}</TableCell> : <TableCell />}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PermissionsView;
