import React from 'react';
import {
  ArrayField,
  ArrayInput,
  AutocompleteInput,
  Create,
  Datagrid,
  Edit,
  email,
  Filter,
  ImageField,
  ImageInput,
  List,
  NumberInput,
  NullableBooleanInput,
  ReferenceInput,
  required,
  SimpleForm,
  SimpleFormIterator,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  FunctionField,
  SelectInput,
  ReferenceManyField,
  DateField,
  TopToolbar,
  EditButton,
  TabbedShowLayoutTabs,
  FormDataConsumer,
  SimpleShowLayout,
} from 'react-admin';
import { useForm } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { urlValidation } from '../constants/validation';
import { LatLngInput } from '../inputs';
import CountryAndCityInput from '../inputs/CountryAndCityInput';
import { CreateActions } from '../components/DefaultActions';
import ValidityShow from '../components/ValidityShow';
import UserLink from '../components/UserLink';
import { VALIDATION_STATUSES } from '../constants/validations';
import { MediaThumbnailField } from '../components/MediaThumbnailField';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { PublicLink } from '../components/PublicLink';
import GooglePlacesAutocomplete from '../components/GooglePlacesAutocomplete';
import CustomShowLayout from '../components/CustomShowLayout';
import EntityTranslation from '../components/translation/EntityTranslation';

const useStyles = makeStyles(() => ({
  cityFilter: {
    '& #city_id-helper-text span': { display: 'none' },
  },
}));

const VenuesFilter = (props) => {
  const classes = useStyles();

  return (
    <Filter {...props}>
      <NumberInput label="Id" source="id" alwaysOn />
      <TextInput label="Search" source="query" alwaysOn />
      <SelectInput label="Validation status" source="validation_status" choices={VALIDATION_STATUSES} alwaysOn />
      <ReferenceInput label="Country" source="country_id" reference="countries" filterToQuery={(searchText) => ({ query: searchText })} sort={{ field: 'name', order: 'ASC' }} alwaysOn allowEmpty>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <div className={classes.cityFilter} alwaysOn>
        <FormDataConsumer source="country_id">
          {({ formData }) => (
            <ReferenceInput
              label="City"
              source="city_id"
              reference="countries/cities"
              filter={{ country_id: formData?.country_id }}
              filterToQuery={(searchText) => ({ query: searchText })}
              sort={{ field: 'name', order: 'ASC' }}
              alwaysOn
              allowEmpty
            >
              <AutocompleteInput
                optionText={(record) => {
                  let name = '';
                  name = record?.name;

                  if (record?.areas?.length) {
                    name = `${name} (${record?.areas?.[0]?.name})`;
                  }

                  if (record?.country?.name) {
                    name = `${name} (${record?.country?.name})`;
                  }

                  return name;
                }}
                matchSuggestion={() => true}
              />
            </ReferenceInput>
          )}
        </FormDataConsumer>
      </div>
      <NullableBooleanInput label="Has Google place" source="has_place_id" alwaysOn />
      <TextInput label="Google Place ID" source="place_id" alwaysOn />
    </Filter>
  );
};

export const VenueShowActions = (props) => {
  const { basePath, data } = props;
  return (
    <TopToolbar>
      <PublicLink {...props} />
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );
};

export const VenuesShow = (props) => (
  <ValidityShow title={<Breadcrumbs {...props} />} actions={<VenueShowActions />} {...props}>
    <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" {...props} />}>
      <Tab label="Venue Info">
        <CustomShowLayout {...props} isStats>
          {{
            column1: (
              <SimpleShowLayout>
                <TextField source="name" />
                <ImageField source="logo.medium" title="title" />
                <ArrayField source="organizations">
                  <Datagrid>
                    <TextField source="id" />
                    <TextField source="name" />
                  </Datagrid>
                </ArrayField>
                <TextField source="country.name" />
                <TextField source="city.name" />
              </SimpleShowLayout>
            ),
            column2: (
              <SimpleShowLayout>
                <ArrayField source="stages">
                  <Datagrid>
                    <TextField source="id" />
                    <TextField source="name" />
                    <TextField source="capacity" />
                  </Datagrid>
                </ArrayField>
                <TextField multiline="true" source="address" />
                <TextField source="lat" />
                <TextField source="lon" />
                <TextField source="placeId" label="Google Place ID" />
                <TextField source="email" />
                <TextField source="url" />
                <TextField source="phone" />
              </SimpleShowLayout>
            ),
          }}
        </CustomShowLayout>
      </Tab>
      <Tab label="Venues Translation">
        <EntityTranslation list={{ url: `venues/${props?.id}/translations` }} update={{ url: `venues/${props?.id}/translations` }} />
      </Tab>
      <Tab label="media">
        <ReferenceManyField filter={{ media_tag_type_id: 3 }} sort={{ field: 'createdAt', order: 'DESC' }} label="Media Records" reference="media" target="media_tag_id">
          <Datagrid rowClick="show">
            <TextField source="id" sortable={false} />
            <TextField source="mediaType.name" label="Type" sortable={false} />
            <MediaThumbnailField label="Image" />
            <TextField source="name" label="Title" sortable={false} />
            <TextField source="description" label="Description" sortable={false} />
            <DateField source="createdAt" showTime />
            <TextField source="status.name" label="Status Name" sortable={false} />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </ValidityShow>
);

export const VenuesList = (props) => (
  <List {...props} exporter={false} filters={<VenuesFilter />} bulkActionButtons={false} sort={{ field: 'production_count', order: 'DESC' }} title={<Breadcrumbs {...props} />} perPage={25}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="country.name" label="Country" sortBy="country" />
      <TextField source="city.name" label="City" sortBy="city" />
      <TextField source="productionCount" sortBy="production_count" />
      <TextField label="Validation status" source="validationStatus.name" sortable={false} />
      <FunctionField label="Created by" render={(record) => <UserLink record={record?.createdBy} />} />
      <FunctionField label="Validated by(latest)" render={(record) => <UserLink record={record?.lastValidationBy} />} />
    </Datagrid>
  </List>
);

const VenueForm = () => {
  const form = useForm();
  const defaultAddress = form?.getState()?.values?.address;

  const onChangeForm = ({ place, address }) => {
    const lat = place?.geometry?.location?.lat();
    const lng = place?.geometry?.location?.lng();
    const placeId = place?.place_id;

    form.change('address', address);
    form.change('lat', lat);
    form.change('lon', lng);
    form.change('placeId', placeId);
  };

  return (
    <>
      <TextInput source="name" validate={required()} label="Name" />
      <ImageInput source="logo" label="Logo image" accept="image/*">
        <ImageField source="medium" title="title" />
      </ImageInput>
      <ArrayInput source="organizations">
        <SimpleFormIterator>
          <ReferenceInput label="Organization" source="id" reference="organizations" filterToQuery={(searchText) => ({ query: searchText })}>
            <AutocompleteInput optionText={(item) => item.name || `Empty (id: ${item.id})`} />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="stages">
        <SimpleFormIterator>
          <TextInput label="Name" source="name" validate={required()} />
          <NumberInput label="Seats amount" source="capacity" step={1} />
        </SimpleFormIterator>
      </ArrayInput>
      <div style={{ marginBottom: 20 }}>
        <GooglePlacesAutocomplete defaultValue={defaultAddress} onChange={(place) => onChangeForm(place)} />
      </div>
      <div>
        <TextInput disabled label="Google Place ID" source="placeId" />
      </div>
      <div>
        <LatLngInput disabled />
      </div>
      <CountryAndCityInput countryField="country" cityField="city" />
      <div>
        <TextInput source="url" validate={urlValidation} label="Website" />
      </div>
      <div>
        <TextInput source="email" validate={email('Wrong email')} label="Email" />
      </div>
      <div>
        <TextInput source="phone" label="Phone" />
      </div>
    </>
  );
};

export const VenueCreate = (props) => (
  <Create title="Create venue" actions={<CreateActions />} {...props}>
    <SimpleForm submitOnEnter={false}>
      <VenueForm />
    </SimpleForm>
  </Create>
);

export const VenueEdit = (props) => (
  <Edit {...props} title={<Breadcrumbs {...props} />}>
    <SimpleForm submitOnEnter={false}>
      <VenueForm />
    </SimpleForm>
  </Edit>
);

export default VenuesList;
