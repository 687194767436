import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { makeStyles } from '@material-ui/core/styles';
import { Loading, useDataProvider, useNotify } from 'react-admin';
import Flag from 'react-world-flags';
import { Box, TextField, Button, Dialog, DialogContent, Tooltip } from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
  headerParentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  cellEditContainer: {
    width: '300px',
    height: '250px',
    display: 'flex',
    flexDirection: 'column',
    spacing: 1,
    '& >div': {
      padding: '5px',
    },
    '&>button': {
      paddingTop: '5px',
    },
  },
  customLineHeight: {
    '& .ag-cell': {
      lineHeight: '20px',
      padding: '5px',
    },
  },
  container: {
    height: '650px',
    width: '100%',
    paddingBottom: '40px',
  },
  valueContainer: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  keyContainer: {
    fontWeight: 600,
    height: '17px',
  },
  dataContainer: { height: '17px' },
  roleNameContainer: { height: '17px', fontStyle: 'italic' },
  roleCellEditContainer: {
    width: '300px',
    height: '400px',
    display: 'flex',
    flexDirection: 'column',
    spacing: 1,
    '& >div': {
      padding: '5px',
    },
    '&>button': {
      paddingTop: '5px',
    },
  },
}));

const getFlagCode = (code) => {
  switch (code) {
    case 'ca':
    case 'et':
    case 'om':
    case 'ga':
      return '';
    case 'en':
      return 'gb';
    case 'sv':
      return 'se';
    default:
      return code;
  }
};

const supportedLangList = [
  'en',
  'de',
  'fr',
  'it',
  'es',
  'ru',
  'eu',
  'bg',
  'ca',
  'zh',
  'cs',
  'da',
  'nl',
  'et',
  'fi',
  'hu',
  'is',
  'ga',
  'ja',
  'ko',
  'lv',
  'lt',
  'mt',
  'el',
  'no',
  'pl',
  'pt',
  'ro',
  'sk',
  'sl',
  'sv',
];

const EntityTranslation = ({ list, update, isRoles, isInstrumentRole, entity }) => {
  const classes = useStyles();
  const dataprovider = useDataProvider();
  const notify = useNotify();
  const [translations, setTranslations] = useState([]);
  const [supportedLanguages, setSupportedLanguages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [canonicalLang, setCanonicalLang] = useState(null);

  const TransVariantsCellRenderer = (props) => (
    <span>
      <div className={classes.roleNameContainer}>{props?.value?.workRole?.name && <div>{props.value.workRole.name}</div>}</div>
      <div>
        <div className={classes.keyContainer}>Translation:</div>
        <Tooltip title={props?.value?.name}>
          <div className={classes.dataContainer}>{props?.value?.name}</div>
        </Tooltip>
      </div>
      <hr />
      <div>
        <div className={classes.keyContainer}>Variants:</div>
        <Tooltip title={props?.value?.variants?.join('; ')}>
          <div className={classes.dataContainer}>{props?.value?.variants?.join('; ')}</div>
        </Tooltip>
      </div>
    </span>
  );

  const fetchTranslation = (url) => {
    dataprovider.getList(url, { pagination: { page: 1, perPage: 9999 }, sort: {} }).then(({ data }) => {
      setIsLoading(false);
      setTranslations(data);
    });
  };

  const handleCellEdit = useCallback(
    (params) => {
      const { translation, variants, language, id, colDef, instrument_role_id, data: workRoleData } = params;
      const workRoleId = workRoleData?.workRoleId;
      setIsLoading(true);

      let updateUrl = update?.url;
      let createUrl = update?.url;

      if (isInstrumentRole) {
        updateUrl = `instruments/roles/${instrument_role_id ?? workRoleId}/translations`;
        createUrl = updateUrl;
      }

      if (workRoleId && !isInstrumentRole) {
        createUrl = `works/roles/${workRoleId}/translations`;
      }

      if (id) {
        dataprovider
          .update(updateUrl, { id, data: { name: translation, variants: variants?.length > 0 ? variants?.split('\n') : [], language: { id: language?.id } } })
          .then(() => {
            notify('Updated successfully');
            fetchTranslation(list?.url);
          })
          .catch((err) => {
            notify(err);
            fetchTranslation(list?.url);
          });

        return;
      }

      dataprovider
        .create(createUrl, {
          data: { name: translation, variants: variants?.split('\n'), language: { id: language?.id ?? colDef?.langCode } },
        })
        .then(() => {
          notify('Updated successfully');
          fetchTranslation(list?.url);
        })
        .catch((err) => {
          notify(err);
          fetchTranslation(list?.url);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataprovider, update, notify, list?.url],
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const translationRes = await dataprovider.getList(list.url, { pagination: { page: 1, perPage: 9999 }, sort: {} });

      let entityDetails;
      let langOrderList = [];
      if (entity?.name) {
        entityDetails = await dataprovider.getOne(entity?.name, { id: entity?.id });
        if (entityDetails?.data?.language?.slug) {
          langOrderList.push(entityDetails?.data?.language?.slug);
          setCanonicalLang(entityDetails?.data?.language);
        }
      } else {
        langOrderList.push('en');
      }

      langOrderList = [...langOrderList, ...supportedLangList?.filter((supportedLang) => !langOrderList.includes(supportedLang))];

      const langRes = await dataprovider.getList('languages', { pagination: { perPage: 9999 }, sort: {} });

      const filteredList = langRes?.data.filter(({ slug }) => langOrderList.includes(slug));
      const orderMap = {};
      langOrderList.forEach((slug, index) => {
        orderMap[slug] = index;
      });
      const sortedData = filteredList.sort((a, b) => orderMap[a.slug] - orderMap[b.slug]);
      setSupportedLanguages(sortedData);
      setTranslations(translationRes?.data);
      setIsLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const TranslationHeader = (data) => (
    <div className={classes.headerParentContainer}>
      {canonicalLang?.id === data?.data?.id && <div>Canonical</div>}
      <div className={classes.headerContainer}>
        <Box width={25} pr={1}>
          <Flag width={25} height={25} code={getFlagCode(data?.data?.slug)} />
        </Box>
        {data?.data?.isActive && (
          <Box>
            <CheckCircle style={{ color: 'green' }} />
          </Box>
        )}
        <Box>{data?.data?.name}</Box>
        <Box>(id: {data?.data?.id})</Box>
      </div>
    </div>
  );

  const TranslationCellEditor = (cellData) => {
    const [translation, setTranslation] = useState(cellData?.value?.name || '');
    const [variants, setVariants] = useState(cellData?.value?.variants?.join('\n') || '');
    const [isOpen, setIsOpen] = useState(true);
    const apiRef = useRef(cellData.api);

    const instrumentId = cellData?.value?.instrument_role_id;

    const handleTranslationChange = (event) => {
      setTranslation(event.target.value);
    };

    const handleVariantsChange = (event) => {
      setVariants(event.target.value);
    };

    return (
      <Dialog
        open={isOpen}
        onClose={() => {
          apiRef.current.stopEditing();
          setIsOpen(false);
        }}
        PaperProps={{
          className: classes.dialog,
          sx: { padding: 0 },
        }}
      >
        <DialogContent>
          <div className={instrumentId ? classes.roleCellEditContainer : classes.cellEditContainer}>
            <TextField label="Translation" value={translation} onChange={handleTranslationChange} />
            <TextField multiline rows={6} label="Variants" value={variants} onChange={handleVariantsChange} />
            {instrumentId && (
              <Alert variant="filled" severity="info" style={{ backgroundColor: 'grey', width: '90%' }}>
                Updating this translation will overwright InstrumentRoleId: {instrumentId} with this value and all Musical Work that are associated to this Instrument Role will share the same
                translation
              </Alert>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setIsOpen(false);
                apiRef.current.stopEditing();
                handleCellEdit({ ...cellData, ...cellData?.value, translation, variants });
              }}
            >
              Save
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  // Transform the data to a single row format
  const transformedDataTranslation = useMemo(() => {
    const rowData = {};
    translations.forEach((item) => {
      rowData[item.language.name] = item;
    });
    return [rowData]; // Return as an array with a single object
  }, [translations]);

  const transformedData = {};

  if (isRoles) {
    translations.forEach((item) => {
      const workRoleId = isInstrumentRole ? item.instrument_role_id : item.workRole.id;
      const languageName = item.language.name;

      if (!transformedData[workRoleId]) {
        transformedData[workRoleId] = { workRoleId: workRoleId.toString(), item };
      }

      transformedData[workRoleId][languageName] = item;
    });
  }

  const columnDefs = supportedLanguages.map((item) => ({
    headerName: item.name,
    field: item.name,
    langCode: item?.id,
    editable: true,
    cellRenderer: 'customCellRenderer',
    headerComponentFramework: 'customHeaderRenderer',
    cellEditorFramework: 'customCellEditorFramework',
    cellRendererParams: (params) => ({
      data: params.data,
    }),
    headerComponentParams: () => ({
      data: item,
    }),
    cellEditorParams: (params) => ({
      data: params.data,
      item,
    }),
  }));

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className={`ag-theme-alpine ${classes.customLineHeight} ${classes.container}`}>
      <Alert variant="filled" severity="info" style={{ backgroundColor: 'grey', width: '90%', marginTop: '5px' }}>
        On different environments not all languages are enabled, see “icon” next to lang flag for enabled languages
      </Alert>
      <AgGridReact
        rowData={isRoles ? Object.values(transformedData) : transformedDataTranslation}
        columnDefs={columnDefs}
        rowHeight={isRoles ? 130 : 110}
        components={{ customCellRenderer: TransVariantsCellRenderer, customHeaderRenderer: TranslationHeader, customCellEditorFramework: TranslationCellEditor }}
      />
    </div>
  );
};

export default EntityTranslation;
