import { ACADEMIA_TYPE_ID, AGENT_TYPE_ID, AOS_TYPE_ID, ARTIST_TYPE_ID, JOURNALIST_TYPE_ID, CASTING_TYPE_ID } from '../constants/profiles';
import { SUBSCRIPTION_STATUS } from '../constants/subscription';

export const getProfilePermissions = ({ profileSubscriptions, profileTypeId, permissions }) => {
  const activeSubscriptions = profileSubscriptions?.filter((sub) => [SUBSCRIPTION_STATUS.ACTIVE, SUBSCRIPTION_STATUS.IN_TRIAL]?.includes(sub?.status));
  const isActiveSubscription = activeSubscriptions?.length > 0;
  const isSubscriptionLinkedToEmail = !!activeSubscriptions?.[0]?.user?.email;

  if (profileTypeId === ARTIST_TYPE_ID) {
    return [
      {
        name: 'Subscription linked to email',
        value: () => isSubscriptionLinkedToEmail,
        isTitle: true,
      },
      {
        name: 'Create / Add information',
        isTitle: true,
        hideValue: true,
      },
      {
        name: 'Profile info',
        value: () => permissions['profiles.update.own'] || permissions['profiles.update.all'],
      },
      {
        name: 'Biography',
        value: () => permissions['profiles.update.own'] || permissions['profiles.update.all'],
      },
      {
        name: 'Add Performances',
        value: () => permissions['productions.create.own'] || permissions['productions.create.all'],
      },
      {
        name: 'Submit change request (Submit proofs)',
        value: () => permissions['productions.update.own'] || permissions['productions.update.all'],
      },
      {
        name: 'Digital presence',
        value: () => permissions['profileContacts.update.own'] || permissions['profileContacts.update.all'],
      },
      {
        name: 'Photos',
        value: () => permissions['media.create.own'] || permissions['media.create.all'],
      },
      {
        name: 'Videos',
        value: () => permissions['media.create.own'] || permissions['media.create.all'],
      },
      {
        name: 'Repertoire (Mark Active, Retired)',
        value: () => permissions['profileRepertoire.update.own'] || permissions['profileRepertoire.update.all'],
      },
      {
        name: 'Reviews',
        value: () => permissions['profileReviews.update.own'] || permissions['profileReviews.update.all'],
      },
      {
        name: 'Agency representation and agency contact information',
        value: () => permissions['profileContacts.update.own'] || permissions['profileContacts.update.all'],
      },
      {
        name: 'Contact information of artist',
        value: () => permissions['profileContacts.update.own'] || permissions['profileContacts.update.all'],
      },
      {
        name: 'Social media profiles of artists',
        value: () => permissions['profileContacts.update.own'] || permissions['profileContacts.update.all'],
      },
      {
        name: 'Future repertoire (Up to 5 ready to debut)',
        value: () => permissions['profileRepertoire.create.own'] || permissions['profileRepertoire.create.all'],
      },
      {
        name: 'Show / hide information',
        isTitle: true,
        hideValue: true,
      },
      {
        name: 'Photos',
        value: () => permissions['media.showHide.own'] || permissions['media.showHide.all'],
      },
      {
        name: 'Videos',
        value: () => permissions['media.showHide.own'] || permissions['media.showHide.all'],
      },
      {
        name: 'Reviews',
        value: () => permissions['reviews.showHide.own'] || permissions['reviews.showHide.all'],
      },
      {
        name: 'Performances',
        value: () => permissions['productions.showHide.own'] || permissions['productions.showHide.all'],
      },
      {
        name: 'Share access',
        value: () => permissions['profiles.shareAccess.own'] || permissions['profiles.shareAccess.all'],
        isTitle: true,
      },
      {
        name: 'Request red mask',
        value: () => permissions['applications.frontbase.redMask'],
        isTitle: true,
      },
    ];
  }

  if (profileTypeId === AGENT_TYPE_ID) {
    return [
      {
        name: 'Subscription linked to email',
        value: () => isSubscriptionLinkedToEmail,
        isTitle: true,
      },
      {
        name: 'Subscription is active',
        value: () => isActiveSubscription,
        isTitle: true,
      },
      {
        name: 'Create / Add information',
        isTitle: true,
        hideValue: true,
      },
      {
        name: 'Edit agency',
        value: () => permissions['agencies.create.own'] || permissions['agencies.create.all'],
      },
      {
        name: 'Add office details',
        value: () => permissions['agencyOffices.create.own'] || permissions['agencyOffices.create.all'],
      },
      {
        name: 'Add agency information',
        value: () => permissions['agencies.create.own'] || permissions['agencies.create.all'],
      },
      {
        name: 'Add artists to roster',
        value: () => permissions['agencyArtists.create.own'] || permissions['agencyArtists.create.all'],
      },
      {
        name: 'Request access to artist profiles',
        value: () => permissions['profiles.requestAccess.all'],
      },
      {
        name: 'Share access',
        value: () => permissions['profiles.shareAccess.own'] || permissions['profiles.shareAccess.all'],
        isTitle: true,
      },
      {
        name: 'Archive access',
        value: () => permissions['data.archive.access'],
        isTitle: true,
      },
      {
        name: 'Casting Tool',
        value: () => permissions['applications.frontbase.castingTool'],
        isTitle: true,
      },
    ];
  }

  if ([AOS_TYPE_ID, ACADEMIA_TYPE_ID, JOURNALIST_TYPE_ID, CASTING_TYPE_ID].includes(profileTypeId)) {
    return [
      {
        name: 'Subscription linked to email',
        value: () => isSubscriptionLinkedToEmail,
        isTitle: true,
      },
      {
        name: 'Subscription is active',
        value: () => isActiveSubscription,
        isTitle: true,
      },
      {
        name: 'Create / Add information',
        isTitle: true,
        hideValue: true,
      },
      {
        name: 'Seasons',
        value: () => permissions['productions.create.own'] || permissions['productions.create.all'],
      },
      {
        name: 'Rentals',
        value: () => permissions['rentals.create.own'] || permissions['rentals.create.all'],
      },
      {
        name: 'Videos',
        value: () => permissions['media.create.own'] || permissions['media.create.all'],
      },
      {
        name: 'Photos',
        value: () => permissions['media.create.own'] || permissions['media.create.all'],
      },
      {
        name: 'Reviews',
        value: () => permissions['reviews.create.own'] || permissions['reviews.create.all'],
      },
      {
        name: 'Organization information',
        value: () => permissions['organizations.create.own'] || permissions['organizations.create.all'],
      },
      {
        name: 'Management & Staff',
        value: () =>
          permissions['companyEmployees.create.own'] ||
          permissions['companyEmployees.create.all'] ||
          permissions['organizationEmployees.create.own'] ||
          permissions['organizationEmployees.create.all'],
      },
      {
        name: 'Contact & box office ',
        value: () =>
          permissions['companyEmployees.create.own'] ||
          permissions['companyEmployees.create.all'] ||
          permissions['organizationEmployees.create.own'] ||
          permissions['organizationEmployees.create.all'],
      },
      {
        name: 'Digital presence',
        value: () =>
          permissions['companyEmployees.create.own'] ||
          permissions['companyEmployees.create.all'] ||
          permissions['organizationEmployees.create.own'] ||
          permissions['organizationEmployees.create.all'],
      },
      {
        name: 'Show / hide information',
        isTitle: true,
        hideValue: true,
      },
      {
        name: 'Photos',
        value: () => permissions['media.showHide.own'] || permissions['media.showHide.all'],
      },
      {
        name: 'Videos',
        value: () => permissions['media.showHide.own'] || permissions['media.showHide.all'],
      },
      {
        name: 'Reviews',
        value: () => permissions['reviews.showHide.own'] || permissions['reviews.showHide.all'],
      },
      {
        name: 'Performances',
        value: () => permissions['productions.showHide.own'] || permissions['productions.showHide.all'],
      },
      {
        name: 'Share access',
        value: () => permissions['profiles.shareAccess.own'] || permissions['profiles.shareAccess.all'],
        isTitle: true,
      },
      {
        name: 'Archive access',
        value: () => permissions['data.archive.access'],
        isTitle: true,
      },
      {
        name: 'Casting Tool',
        value: () => permissions['applications.frontbase.castingTool'],
        isTitle: true,
      },
    ];
  }

  return [];
};
