import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'ra-core';

const useStyles = makeStyles(
  {
    root: {
      display: 'flex',
    },
  },
  {
    name: 'IconField',
  },
);

const IconField = ({ icon, className, ariaLabel }) => {
  const translate = useTranslate();
  const classes = useStyles();
  // const value = get(record, source);

  return (
    <Typography component="span" variant="body2" className={classnames(classes.root, className)}>
      <Tooltip title={translate(ariaLabel, { _: ariaLabel })}>
        <span>{icon}</span>
      </Tooltip>
    </Typography>
  );
};

IconField.propTypes = {
  icon: PropTypes.node,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
};

export default IconField;
