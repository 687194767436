import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, TextField, DialogActions, Switch, FormControlLabel } from '@material-ui/core';
import { useNotify, useDataProvider } from 'react-admin';

import { WHITELIST_ENTITY_TYPES } from '../constants/entities';
import storage from '../utils/storage';

export default function MarkAsWhiteListed(props) {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [isWhiteListed, setIsWhiteListed] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState('');
  const userId = storage.getUserId();
  const { id, resource } = props;

  useEffect(() => {
    dataProvider
      .getList('whitelist/requests', { filter: { entity_id: id, decision_status: 'pending,approved,rejected', entity_type: WHITELIST_ENTITY_TYPES?.[resource] }, pagination: {}, sort: {} })
      .then(({ data }) => {
        setIsWhiteListed(data?.[0]?.new_value);
      });
  }, [dataProvider, id, resource]);

  const handleWhitelisted = () => {
    const payload = {
      new_value: !isWhiteListed,
      requested_by: userId,
      entity_type: WHITELIST_ENTITY_TYPES?.[resource],
      entity_id: id,
      change_reason: reason,
    };
    setLoading(true);

    dataProvider
      .create('whitelist/requests', { data: payload })
      .then(() => {
        notify(!isWhiteListed ? 'Whitelist request submitted' : 'Non whitelist request submitted');
        setOpen(false);
        setLoading(false);
        setReason('');
        setIsWhiteListed(!isWhiteListed);
      })
      .catch((err) => {
        notify(err);
        setLoading(false);
      });
  };

  return (
    <>
      <FormControlLabel control={<Switch checked={isWhiteListed} onChange={() => setOpen(true)} name="Whitelisted" color="primary" />} label="Whitelisted" />
      <Dialog
        open={isOpen}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: { padding: 0 },
        }}
      >
        <DialogContent style={{ width: 400 }}>
          <div style={{ fontWeight: 'bold', marginBottom: 10 }}>{!isWhiteListed ? 'Mark as whitelisted' : 'Mark as non whitelisted'}</div>
          <TextField margin="dense" label="Reason" fullWidth type="text" value={reason} onChange={(e) => setReason(e.target.value)} style={{ marginTop: 0 }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button disabled={!reason || loading} variant="contained" color="primary" onClick={handleWhitelisted}>
            {!loading ? 'Submit' : 'Loading...'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
