import { Button, InputLabel, Dialog, DialogContent, DialogTitle, MenuItem, Typography, Select, FormControl, FormControlLabel, Checkbox, FormGroup } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

function WorkRoleProfessionModal({ setShowWorkRoleProfessionModal, source, target, onSubmit }) {
  const [selectedProfessions, setSelectedProfessions] = useState([]);
  const [selectedProfessionsIds, setSelectedProfessionsIds] = useState([]);
  const [primaryProfession, setPrimaryProfession] = useState();
  const targetProfessionsIds = target?.workRoleProfessions?.map(({ profession }) => profession?.id);
  const uniqueSourceData = source?.workRoleProfessions?.filter(({ profession }) => !targetProfessionsIds?.includes(profession?.id));

  useEffect(() => {
    const pProfession = target?.workRoleProfessions.find((profession) => profession.isPrimary) ?? [];
    setPrimaryProfession(pProfession?.id);
    setSelectedProfessionsIds([pProfession?.id]);
    setSelectedProfessions([pProfession]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleProfessionChange = (profession, e) => {
    const isChecked = e?.target?.checked;

    if (isChecked) {
      setSelectedProfessions((prevState) => [...prevState, profession]);
      setSelectedProfessionsIds((prevState) => [...prevState, profession?.id]);
    } else {
      setSelectedProfessions((prevState) => [...prevState?.filter((item) => item?.id !== profession?.id)]);
      setSelectedProfessionsIds((prevState) => [...prevState?.filter((id) => id !== profession?.id)]);
      if (profession?.id === primaryProfession) {
        setPrimaryProfession(null);
      }
    }
  };

  const handlePrimaryProfessionChange = (event) => {
    setPrimaryProfession(event?.target?.value);
  };

  return (
    <Dialog
      open
      onClose={() => {
        setShowWorkRoleProfessionModal(false);
      }}
    >
      <DialogTitle style={{ padding: '16px 24px 8px', width: 600 }}>
        <Typography variant="h6">Select professions to keep</Typography>
      </DialogTitle>
      <hr />
      <DialogContent style={{ padding: '0 24px 8px 24px' }}>
        <hr />
        <div>Target Professions of role "{target?.name}":</div>
        <FormGroup>
          {target?.workRoleProfessions?.map((profession) => (
            <FormControlLabel
              key={profession.id}
              control={<Checkbox color="primary" checked={selectedProfessionsIds.includes(profession.id)} onChange={(e) => handleProfessionChange(profession, e)} name={profession.profession.name} />}
              label={profession.profession.name}
            />
          ))}
        </FormGroup>
        <hr />
        {uniqueSourceData?.length > 0 && (
          <>
            <div>Source Professions of role "{source?.name}":</div>
            <FormGroup>
              {uniqueSourceData?.map((profession) => (
                <FormControlLabel
                  key={profession.id}
                  control={
                    <Checkbox color="primary" checked={selectedProfessionsIds.includes(profession.id)} onChange={(e) => handleProfessionChange(profession, e)} name={profession.profession.name} />
                  }
                  label={profession.profession.name}
                />
              ))}
            </FormGroup>
            <hr />
          </>
        )}
        {selectedProfessions?.length > 0 && (
          <>
            Select primary profession:
            <FormControl fullWidth margin="normal">
              <InputLabel id="primary-profession-select-label">Select Primary Profession</InputLabel>
              <Select labelId="primary-profession-select-label" value={primaryProfession} onChange={handlePrimaryProfessionChange}>
                {selectedProfessions?.map((profession) => (
                  <MenuItem key={profession?.id} value={profession?.id}>
                    {profession?.profession?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
        <hr />
        <Button size="large" variant="contained" disabled={!primaryProfession} onClick={(event) => onSubmit(event, source, target, selectedProfessions, primaryProfession)}>
          Merge
        </Button>
      </DialogContent>
    </Dialog>
  );
}

export default WorkRoleProfessionModal;
