import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import FingerprintCalculate from '../components/Fingerprint/FingerprintCalculate';
import FingerprintActivity from '../components/Fingerprint/FingerprintActivity';
import FingerprintUsers from '../components/Fingerprint/FingerprintUsers';
import FingerprintActivityLogs from '../components/Fingerprint/FingerprintActivityLogs';
import FingerprintTopNUsers from '../components/Fingerprint/FingerprintTopNUsers';
import FingerprintDynamicCount from '../components/Fingerprint/FingerprintDynamicCount';
import ErrorModel from './ErrorModel';

import useUserPermissions from '../utils/useUserPermissions';

const TabComponent = (props) => {
  const history = useHistory();
  const { permissions } = useUserPermissions();
  const searchParams = new URLSearchParams(history.location.search);
  const defaultTab = parseInt(searchParams.get('tab'), 0);
  const [tabValue, setTabValue] = useState(defaultTab || 0);

  const hasFingerPrintPermission = permissions?.length && permissions.includes('applications.frontbase.fingerprint');

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    searchParams.set('tab', tabValue);
    const newSearch = searchParams.toString();
    history.push({ search: newSearch });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue]);

  if (!hasFingerPrintPermission) {
    return <ErrorModel />;
  }

  return (
    <div>
      <Tabs value={tabValue} variant="scrollable" indicatorColor="primary" onChange={handleTabChange} component={Paper} style={{ marginBottom: 10 }}>
        <Tab label="FingerPrint Calculate" />
        <Tab label="FingerPrint Activity" />
        <Tab label="FingerPrint Dynamic Threshold" />
        <Tab label="FingerPrint Dynamic Page View" />
        <Tab label="FingerPrint Activity logs" />
        <Tab label="FingerPrint Actions" />
      </Tabs>
      {tabValue === 0 && <FingerprintCalculate />}
      {tabValue === 1 && <FingerprintActivity />}
      {tabValue === 2 && <FingerprintTopNUsers {...props} />}
      {tabValue === 3 && <FingerprintDynamicCount {...props} />}
      {tabValue === 4 && <FingerprintActivityLogs {...props} />}
      {tabValue === 5 && <FingerprintUsers {...props} />}
    </div>
  );
};

export default TabComponent;
